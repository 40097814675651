import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { AccessCheck } from "@particles/index"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { VENDOR_ROUTES } from "@routes/vendor"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    VENDORS_LIST_CONTROLS_ACTIVE_TAB,
    VENDORS_LIST_CONTROLS_KEYWORD_SEARCH,
    VENDORS_LIST_CONTROLS_ORDERING,
    VENDORS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { VendorTableViewTabs } from "./VendorTableViewTabs"

export default function VendorTableView() {
    const [createRoute] = useSlugExtractor([VENDOR_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={VENDORS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={VENDORS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={VENDORS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={VENDORS_LIST_CONTROLS_ORDERING}
            listTitle="Vendors"
            objectType="vendor"
            objectIndexName="Vendor"
            objectName="Vendor"
            defaultActivePhase="active"
            objectCreateRoute={createRoute}
            isRowClickable={hasPermission("vendors_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("vendors_create_permission", PERMISSION_LEVEL.FULL)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="active" title="Active" />
                            <TableViewPhase phase="inactive" title="Inactive" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <VendorTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<Vendor>
                    accessorKey="name"
                    header="Vendor"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Vendor>
                    accessorKey="account_id"
                    header="Account ID"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Vendor>
                    accessorKey="billing_address_formatted"
                    header="Billing address"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Vendor>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as VendorLabel[])?.map((label: VendorLabel) => label.name)}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Vendor>
                    accessorKey="payment_terms"
                    header="Terms"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.PaymentTermsCell terms={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="other"
                />
                <AccessCheck entitlements={["entitlement_purchase_orders_enabled"]}>
                    <Table.Column<Vendor>
                        accessorKey="current_purchase_order_count"
                        header="Current POs"
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />}
                        enableSorting={true}
                        enablePinning={true}
                        dataType="numerical"
                    />
                    <Table.Column<Vendor>
                        accessorKey="outstanding_balance"
                        header="Current Balance"
                        size={160}
                        cell={({ getValue }) => (
                            <Table.Cells.NumericalDataCell value={getValue()} ignoreZero={true} type="currency" />
                        )}
                        enableSorting={true}
                        enablePinning={true}
                        dataType="numerical"
                    />
                </AccessCheck>
                <Table.Column<Vendor>
                    accessorKey="website"
                    header="Website"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.LinkCell destination={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Vendor>
                    accessorKey="last_modified_at"
                    header="Last modified"
                    size={176}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
