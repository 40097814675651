import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import BasicSelectField from "@legacy/core/fields/BasicSelectField";
import TagField from "@legacy/core/fields/TagField";
import TagSearchInput from "@legacy/core/inputs/TagSearchInput";
import VendorSearchOrCreateSelect, { vendorToVendorOption } from "@legacy/pricebook/inputs/VendorSearchOrCreateSelect";
import { renderVendorString } from "@legacy/pricebook/utils/utils";
import PurchaseOrderObjectLink from "@legacy/purchase_orders/components/PurchaseOrderObjectLink";
import { AccessCheck } from "@particles/index";
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import LineItemListSelectFieldGroup from "../../core/components/LineItemListSelectFieldGroup";
import AddressAutocompleteFieldGroup from "../../core/fields/AddressAutocompleteFieldGroup";
import AttachmentUploadField from "../../core/fields/AttachmentUploadField";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import { valueIsDefined } from "../../core/utils/utils";
import BillFeedAccordion from "../components/BillFeedAccordion";
import { calculateBillAmounts } from "../utils/utils";

class BillForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            vendorSearchInput: "",
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }


    renderButtons = () => {
        const {
            mode,
            submitting,
            bill,
            purchaseOrder,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateVendorSelection,
            selectedVendor,
            formatCurrencyValue,
            showCustomBillIDField,
            useBillingAddress,
            useShippingAddress,
            foldDataComplete,
            showQuickBooksItemSelect=false,
            showTaxCreateButton=false,
            useTaxes=false,
            hideTaxRates=false,
            netChoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_BILL") {
                    const showDraftButtonAtTheTop = window.CURRENT_USER?.permissions.bills_edit_permission !== PERMISSION_LEVEL.FULL
                        && window.CURRENT_USER?.permissions.bills_create_permission === PERMISSION_LEVEL.RESTRICTED

                    return (
                        <ButtonGroup>
                            {showDraftButtonAtTheTop && (
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Save Draft" handler={event => requestAction("BILL_CREATE_DRAFT")} />
                                </ButtonGroupRow>
                            )}
                            <AccessCheck permissions={{
                                bills_create_permission: PERMISSION_LEVEL.FULL
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Create" handler={event => requestAction("BILL_CREATE")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <AccessCheck permissions={{
                                    bills_view_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    <UniversalButton type="secondary" text="Preview Bill" handler={event => requestAction("BILL_PREVIEW")} />
                                </AccessCheck>
                                {
                                    !showDraftButtonAtTheTop && (
                                        <AccessCheck permissions={{
                                            bills_create_permission: PERMISSION_LEVEL.RESTRICTED
                                        }}>
                                            <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("BILL_CREATE_DRAFT")} />
                                        </AccessCheck>
                                    )
                                }
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_BILL") {
                    if (bill.is_draft) {
                        return (
                            <ButtonGroup>
                                <AccessCheck permissions={{
                                    bills_create_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="primary" text="Create" handler={event => requestAction("BILL_CREATE")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                                <ButtonGroupRow>
                                    <AccessCheck permissions={{
                                        bills_view_permission: PERMISSION_LEVEL.FULL
                                    }}>
                                        <UniversalButton type="secondary" text="Preview Bill" handler={event => requestAction("BILL_PREVIEW")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("BILL_CREATE_DRAFT")} />
                                    <AccessCheck permissions={{
                                        bills_delete_permission: PERMISSION_LEVEL.RESTRICTED
                                    }}>
                                        <UniversalButton type="danger" text="Delete Draft" handler={event => requestAction("BILL_DELETE_DRAFT")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <AccessCheck permissions={{
                                        bills_edit_permission: PERMISSION_LEVEL.FULL
                                    }}>
                                        <UniversalButton type="primary" text="Save" handler={event => requestAction("BILL_UPDATE")} />
                                    </AccessCheck>
                                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("BILL_CANCEL_EDITS")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    render() {
        const {
            mode,
            submitting,
            bill,
            purchaseOrder,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateVendorSelection,
            selectedVendor,
            formatCurrencyValue,
            showCustomBillIDField,
            useBillingAddress,
            useShippingAddress,
            foldDataComplete,
            showQuickBooksItemSelect=false,
            defaultSelectedNet,
            netChoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props
        const {subtotal, total} = calculateBillAmounts(bill)

        const allowVendorDetailsEdit = Object.keys(purchaseOrder).length === 0 && (bill.purchase_order === null || bill.purchase_order === undefined)

        return (
            <Fragment>
                <div id="bill_form_react_wrapper">
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Bill Create/Update">
                            <div className="data-panel__form" aria-label="Bill Create/Update Form">
                                <p className="data-panel__form__caption">Please provide the following information to create your bill:</p>
                                <fieldset>
                                    <legend>Vendor Details</legend>
                                    <SearchCreateSelectField
                                        fieldName="vendor"
                                        fieldLabel="Vendor"
                                        fieldValue={selectedVendor !== null ? renderVendorString(selectedVendor) : null}
                                        inputComponent={
                                            <VendorSearchOrCreateSelect
                                                disabled={!allowVendorDetailsEdit || window.CURRENT_USER?.permissions.vendors_view_permission < PERMISSION_LEVEL.RESTRICTED}
                                                onSelectionChange={selectedOption => updateVendorSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({vendorSearchInput: input})}}
                                                defaultSelected={selectedVendor !== null ? vendorToVendorOption(selectedVendor) : null}
                                                showCreateButton={window.CURRENT_USER?.permissions.vendors_create_permission >= PERMISSION_LEVEL.FULL}
                                                onCreateClick={event => switchToSecondaryForm("ADD_VENDOR", null, {name: this.state.vendorSearchInput})}
                                                listEndpointKwargs={[
                                                    ["is_active", true]
                                                ]}
                                            ></VendorSearchOrCreateSelect>
                                        }
                                        showButton={allowVendorDetailsEdit && selectedVendor !== null && window.CURRENT_USER?.permissions.vendors_edit_permission >= PERMISSION_LEVEL.FULL}
                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Vendor</Fragment>}
                                        buttonAction={event => switchToSecondaryForm("EDIT_VENDOR", selectedVendor, null)}
                                        disabled={!allowVendorDetailsEdit}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                    {
                                        selectedVendor !== null && selectedVendor.notes !== "" && window.CURRENT_USER?.permissions.vendors_view_permission >= PERMISSION_LEVEL.FULL && (
                                            <BasicDisplayField
                                                fieldName="vendor_notes"
                                                fieldLabel="Vendor Notes"
                                                fieldValue={selectedVendor.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                </fieldset>
                                {
                                    selectedVendor !== null && (
                                        <fieldset>
                                            <legend>Basic Details</legend>
                                            {
                                                Object.keys(purchaseOrder).length !== 0 && !(bill.purchase_order === null || bill.purchase_order === undefined)
                                                ?
                                                (
                                                    <AccessCheck entitlements={["entitlement_purchase_orders_enabled"]}>
                                                        <LinkedObjectsDisplayField
                                                            fieldName="purchase_order"
                                                            fieldLabel="Created From Purchase Order"
                                                            objectComponentList={[
                                                                <PurchaseOrderObjectLink
                                                                    key="parent_purchase_order_0"
                                                                    purchaseOrder={purchaseOrder}
                                                                    destination={DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id)}
                                                                    disabled={window.CURRENT_USER?.permissions.purchase_orders_view_permission < PERMISSION_LEVEL.FULL}
                                                                />
                                                            ]}
                                                        ></LinkedObjectsDisplayField>
                                                    </AccessCheck>
                                                )
                                                :
                                                <CharField
                                                    fieldName="external_purchase_order_id"
                                                    fieldLabel="External Purchase Order ID"
                                                    fieldValue={bill.external_purchase_order_id || ""}
                                                    fieldOnChange={external_purchase_order_id => onFormDataChange("external_purchase_order_id", external_purchase_order_id || "")}
                                                    maxLength="15"
                                                    errors={errors}
                                                ></CharField>
                                            }
                                            {
                                                showCustomBillIDField
                                                ?
                                                <CharField
                                                    fieldName="custom_id"
                                                    fieldLabel="Bill ID"
                                                    fieldValue={bill.custom_id || ""}
                                                    fieldOnChange={custom_id => onFormDataChange("custom_id", custom_id || "")}
                                                    maxLength="15"
                                                    errors={errors}
                                                ></CharField>
                                                :
                                                <div id="div_id_custom_id">{errors.custom_id && <Banner type="warning" text={errors.custom_id} extraMargin={true} />}</div>
                                            }
                                            <TagField
                                                fieldName="labels"
                                                fieldLabel="Labels"
                                                inputComponent={
                                                    <TagSearchInput
                                                        defaultSelectedTags={bill.labels?.map(label => label.name) ?? []}
                                                        onSelectionChange={labelNames => onFormDataChange("labels", labelNames ? labelNames.map(labelName => ({"name": labelName})) : [])}
                                                        choicesEndpoint={DjangoUrls["bills:api-bills-labels-list"](window.MARKETPLACE_ENTITY_SLUG)}
                                                        placeholder="Add Labels..."
                                                        noOptionsMessage="No Labels Found"
                                                        isMulti={true}
                                                    ></TagSearchInput>
                                                }
                                                optional={true}
                                                errors={errors}
                                            ></TagField>
                                            <div className="data-panel__form__field" id="div_id_billing_address" aria-label="Billing Address">
                                                <label htmlFor="id_billing_address" className="data-panel__form__field__label in-form">Billing Address</label>
                                                <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                                    <input type="checkbox" className="checkbox-input" name="use_billing_address" id="id_use_billing_address" checked={!useBillingAddress} onChange={event => requestAction("TOGGLE_USE_BILLING_ADDRESS")} />
                                                    <label className="checkbox-label" htmlFor="id_use_billing_address">Same as vendor</label>
                                                </div>
                                                {
                                                    useBillingAddress && (
                                                        <AddressAutocompleteFieldGroup
                                                            key={`bill_billing_address_${selectedVendor.id}`}
                                                            fieldGroupName="billing_address"
                                                            fieldGroupLabel=""
                                                            fieldPrefix="billing_address"
                                                            fieldValues={{
                                                                "billing_address_recipient": selectedVendor.billing_address_recipient || "",
                                                                "billing_address_street": selectedVendor.billing_address_street || "",
                                                                "billing_address_unit": selectedVendor.billing_address_unit || "",
                                                                "billing_address_city": selectedVendor.billing_address_city || "",
                                                                "billing_address_state": selectedVendor.billing_address_state || "",
                                                                "billing_address_postal": selectedVendor.billing_address_postal || "",
                                                                "billing_address_country": selectedVendor.billing_address_country || "",
                                                            }}
                                                            onFormDataChange={onFormDataChange}
                                                            includeRecipient={true}
                                                            errors={errors}
                                                        ></AddressAutocompleteFieldGroup>
                                                    )
                                                }
                                            </div>
                                            <div className="data-panel__form__field" id="div_id_shipping_address" aria-label="Shipping Address">
                                                <label htmlFor="id_shipping_Address" className="data-panel__form__field__label in-form">Shipping Address</label>
                                                <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                                    <input type="checkbox" className="checkbox-input" name="use_shipping_address" id="id_use_shipping_address" checked={!useShippingAddress} onChange={event => requestAction("TOGGLE_USE_SHIPPING_ADDRESS")} />
                                                    <label className="checkbox-label" htmlFor="id_use_shipping_address">Same as service company</label>
                                                </div>
                                                {
                                                    useShippingAddress && (
                                                        <AddressAutocompleteFieldGroup
                                                            key={`bill_shipping_address_${selectedVendor.service_company.id}`}
                                                            fieldGroupName="shipping_address"
                                                            fieldGroupLabel=""
                                                            fieldPrefix="shipping_address"
                                                            fieldValues={{
                                                                "shipping_address_street": window.CURRENT_USER.service_company.physical_address_street || "",
                                                                "shipping_address_unit": window.CURRENT_USER.service_company.physical_address_unit || "",
                                                                "shipping_address_city": window.CURRENT_USER.service_company.physical_address_city || "",
                                                                "shipping_address_state": window.CURRENT_USER.service_company.physical_address_state || "",
                                                                "shipping_address_postal": window.CURRENT_USER.service_company.physical_address_postal || "",
                                                                "shipping_address_country": window.CURRENT_USER.service_company.physical_address_country || "",
                                                            }}
                                                            onFormDataChange={onFormDataChange}
                                                            includeRecipient={false}
                                                            errors={errors}
                                                        ></AddressAutocompleteFieldGroup>
                                                    )
                                                }
                                            </div>
                                            <DateField
                                                fieldName="date_received"
                                                fieldLabel="Received Date"
                                                fieldValue={bill.date_received || ""}
                                                fieldOnChange={date_received => onFormDataChange("date_received", date_received || "")}
                                                errors={errors}
                                            ></DateField>
                                            <DateField
                                                fieldName="date_issued"
                                                fieldLabel="Issue Date"
                                                fieldValue={bill.date_issued || ""}
                                                fieldOnChange={date_issued => onFormDataChange("date_issued", date_issued || "")}
                                                errors={errors}
                                            ></DateField>
                                            <BasicSelectField
                                                key={`terms_${defaultSelectedNet}`}
                                                fieldName="terms"
                                                fieldLabel="Terms"
                                                fieldValue={valueIsDefined(defaultSelectedNet) ? defaultSelectedNet : ""}
                                                fieldOnChange={net => onFormDataChange("net", valueIsDefined(net) ? net : null)}
                                                choices={netChoices}
                                                errors={errors}
                                            ></BasicSelectField>
                                            <DateField
                                                key={`date_due_${bill.date_due}`}
                                                fieldName="date_due"
                                                fieldLabel="Due Date"
                                                fieldValue={bill.date_due || ""}
                                                fieldOnChange={date_due => onFormDataChange("date_due", date_due || "")}
                                                errors={errors}
                                            ></DateField>
                                        </fieldset>
                                    )
                                }
                                {
                                    selectedVendor !== null && foldDataComplete === true && (
                                        <fieldset>
                                            <legend>Charge Details</legend>
                                            <br />
                                            <AccessCheck permissions={{
                                                pricebook_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                            }}>
                                                <AccessCheck permissions={{
                                                    pricebook_view_permission: PERMISSION_LEVEL.FULL,
                                                }}>
                                                    <LineItemListSelectFieldGroup
                                                        object={bill}
                                                        formatCurrencyValue={formatCurrencyValue}
                                                        showQuickBooksItemSelect={showQuickBooksItemSelect}
                                                        useTaxes={false}
                                                        switchToSecondaryForm={switchToSecondaryForm}
                                                        errors={errors}
                                                        nonFieldErrorMessage={errors.line_items || "There's an issue with one or more of the items below. Try coming back to this page and trying again in a bit."}
                                                        isBillLineItem={true}
                                                    ></LineItemListSelectFieldGroup>
                                                </AccessCheck>
                                            </AccessCheck>
                                            {
                                                window.FILE_UPLOADS_ENABLED && (
                                                    <AttachmentUploadField
                                                        fieldName="attachments"
                                                        fieldLabel="Attachments"
                                                        attachments={bill.attachments}
                                                        fileStackAPIKey={fileStackAPIKey}
                                                        fileStackPolicy={fileStackPolicy}
                                                        fileStackSignature={fileStackSignature}
                                                        onUploadDone={(response) => updateAttachments(
                                                            response.filesUploaded.map(file => ({
                                                                "upload_filename": file.filename,
                                                                "upload_id": file.uploadId,
                                                                "upload_handle": file.handle,
                                                                "upload_url": file.url,
                                                                "upload_mimetype": file.mimetype,
                                                                "upload_size": file.size,
                                                                "upload_source": file.source
                                                            }))
                                                        )}
                                                        optional={true}
                                                        optionalText={"if any"}
                                                        errors={errors}
                                                    />
                                                )
                                            }
                                        </fieldset>
                                    )
                                }
                            </div>
                            {
                                selectedVendor !== null && foldDataComplete === true && (
                                    <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                        <hr aria-hidden="true" />
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--total" aria-label="Total">
                                            <div className="data-panel__data-summary__data__label"><span>Total</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyValue(total)}</div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                selectedVendor !== null && foldDataComplete === true && this.renderButtons()
                            }
                        </div>
                    </div>
                </div>
                <AccessCheck permissions={{
                    bills_view_permission: PERMISSION_LEVEL.FULL
                }}>
                    {
                        valueIsDefined(bill.id) && (
                            <div className="accordion-wrapper">
                                <BillFeedAccordion billID={bill.id}/>
                            </div>
                        )
                    }
                </AccessCheck>
            </Fragment>
        )
    }

}

export default BillForm;
