import { PRICEBOOK_ITEM_TYPE_MAP } from "@constants/mappings";
import Spinner from "@legacy/core/components/Spinner";
import MicroModal from "micromodal";
import { Component } from "react";
import deepcopy from "rfdc";
import { LineItemUnitTypes, PriceBookItemTypes } from "../core/utils/enums";
import { getCurrencySymbol, getDefaultTaxable, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import PriceBookItemForm from "./forms/PriceBookItemForm";

const FORM_MODES = {
    ADD_PRICEBOOKITEM: "ADD_PRICEBOOKITEM",
    EDIT_PRICEBOOKITEM: "EDIT_PRICEBOOKITEM",
    REVIEW_PRICEBOOKITEM: "REVIEW_PRICEBOOKITEM",
}

const PRIMARY_FORM_MODES = [
    FORM_MODES.ADD_PRICEBOOKITEM,
    FORM_MODES.EDIT_PRICEBOOKITEM,
    FORM_MODES.REVIEW_PRICEBOOKITEM,
]

const PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP = {
    [PriceBookItemTypes.service]: "Services",
    [PriceBookItemTypes.part]: "Parts+%26+Materials",
    [PriceBookItemTypes.other]: "Miscellaneous",
    [PriceBookItemTypes.discount]: "Discounts",
    [PriceBookItemTypes.tax]: "Tax+Rates",
}

const PRICEBOOK_ITEM_URL_PARAM_TYPE_MAP = {
    "Services": PriceBookItemTypes.service,
    "Parts": PriceBookItemTypes.part,  // The & in Parts & Materials sometimes confuses browsers
    "Parts+%26+Materials": PriceBookItemTypes.part,
    "Parts & Materials": PriceBookItemTypes.part,
    "Miscellaneous": PriceBookItemTypes.other,
    "Discounts": PriceBookItemTypes.discount,
    "Tax+Rates": PriceBookItemTypes.tax,
    "Tax Rates": PriceBookItemTypes.tax,
}

class PriceBookItemCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_PRICEBOOKITEM
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            priceBookItemData: null,
            inventoryLevels: null,

            errors: {
                priceBookItem: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
            useTaxes: window.USE_TAXES,

            pricebook_default_taxable_service: window.PRICEBOOK_DEFAULT_TAXABLE_SERVICE,
            pricebook_default_taxable_part: window.PRICEBOOK_DEFAULT_TAXABLE_PART,
            pricebook_default_taxable_other: window.PRICEBOOK_DEFAULT_TAXABLE_OTHER,

            showQuickBooksRevenueAccountSelect: window.ACCOUNTING_INTEGRATION === 2 && window.QUICKBOOKS_LINE_ITEM_SCHEME === 2,
            showQuickBooksTaxAgencyVendorSelect: window.ACCOUNTING_INTEGRATION === 2,

            lockItemTypeSelection: false,

            returnScroll: 0,

            isInventoryByDefault: false,
        }
    }

    componentDidMount = async () => {
        // If props tell us this is an edit view, grab pricebook data via rest
        if ((this.state.defaultMode === FORM_MODES.EDIT_PRICEBOOKITEM || (this.state.defaultMode === FORM_MODES.REVIEW_PRICEBOOKITEM)) && window.PRICEBOOKITEM_ID) {
            if (this.state.priceBookItemData === null) {
                const priceBookItemEndpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, window.PRICEBOOKITEM_ID)
                const priceBookItemResponse = await fetch(priceBookItemEndpoint)
                const priceBookItem = await priceBookItemResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.priceBookItemData = priceBookItem
                    updatedState.isInventoryByDefault = priceBookItem.track_inventory

                    return updatedState
                })
            }

            if (this.state.inventoryLevels === null) {
                const inventoryLevelsEndpoint = DjangoUrls["inventory:api-inventory-levels-list"](window.MARKETPLACE_ENTITY_SLUG, window.PRICEBOOKITEM_ID)
                const inventoryLevelsResponse = await fetch(inventoryLevelsEndpoint)
                const inventoryLevels = await inventoryLevelsResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.inventoryLevels = inventoryLevels
                    updatedState.priceBookItemData.inventory_levels = inventoryLevels

                    return updatedState
                })
            }
        }
        else {
            const searchParams = new URLSearchParams(document.location.search)

            const type = searchParams.get("type")
            const priceBookItemType = PRICEBOOK_ITEM_URL_PARAM_TYPE_MAP[type]

            this.setState((state, props) => {
                let updatedState = state

                updatedState.priceBookItemData = {
                    "default_unit_type": LineItemUnitTypes.hourly,
                    "is_active": true,
                    "labels": [],
                }

                if(!!type) {
                    updatedState.priceBookItemData.pricebook_item_type = priceBookItemType
                    updatedState.lockItemTypeSelection = true
                }

                return updatedState
            })

            if (this.state.inventoryLevels === null) {
                const inventoryLevelsEndpoint = DjangoUrls["inventory:api-inventory-levels-list"](window.MARKETPLACE_ENTITY_SLUG, 0)  // Fetches empty defaults for each location
                const inventoryLevelsResponse = await fetch(inventoryLevelsEndpoint)
                const inventoryLevels = await inventoryLevelsResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.inventoryLevels = inventoryLevels
                    updatedState.priceBookItemData.inventory_levels = inventoryLevels
                    return updatedState
                })
            }
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    // Crud PriceBook Itme

    createPriceBookItem = async (isDraft) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (priceBookItem) => {
            const successUrl = DjangoUrls["pricebook:pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG) + `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[priceBookItem.pricebook_item_type]}`

            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} "${priceBookItem.description}" created`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["pricebook:pricebookitem-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.priceBookItemData.id),
                },
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} could not be created`
            })
        }

        this.CUDPriceBookItem(endpoint, "POST", onSuccess, onError)
    }

    updatePriceBookItem = async () => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.priceBookItemData.id)
        const successUrl = DjangoUrls["pricebook:pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG) + `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[this.state.priceBookItemData.pricebook_item_type]}`
        let onError

        const onSuccess = (priceBookItem) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} "${priceBookItem.description}" updated`,
                cta: {
                    children: "View",
                    destination: DjangoUrls["pricebook:pricebookitem-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.priceBookItemData.id),
                },
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        if (this.state.mode === "EDIT_PRICEBOOKITEM") {
            document.querySelectorAll("#message_modal_confirm_update_item .modal__close .button").forEach(button => button.style.display = "none")
            document.querySelector("#message_modal_confirm_update_item .modal__close .spinner-centered").style.display = "block"

            onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} could not be created`
                })
                MicroModal.close("message_modal_confirm_update_item")
                document.querySelectorAll("#message_modal_confirm_update_item .modal__close .button").forEach(button => button.style.display = "block")
                document.querySelector("#message_modal_confirm_update_item .modal__close .spinner-centered").style.display = "none"
            }
        }
        else {
            onError = () => {
                this.addToastToQueue({
                    type: "error",
                    size: "md",
                    title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} could not be created`
                })
            }
        }


        this.CUDPriceBookItem(endpoint, "PUT", onSuccess, onError)
    }

    deletePriceBookItem = async () => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.priceBookItemData.id)
        const successUrl = DjangoUrls["pricebook:pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG) + `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[this.state.priceBookItemData.pricebook_item_type]}`

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} deleted`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_confirm_delete_item .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_delete_item .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `${PRICEBOOK_ITEM_TYPE_MAP[this.state.priceBookItemData.pricebook_item_type]} could not be deleted`
            })
            const deleteModal = document.querySelector("#message_modal_confirm_delete_item .modal__close")
            if (deleteModal) {
                deleteModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        this.CUDPriceBookItem(endpoint, "DELETE", onSuccess, onError)
    }

    CUDPriceBookItem = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "priceBookItemData"
        const submittingName = "submittingPriceBookItem"
        const errorDictName = "priceBookItem"

        const dataManipulator = (data, state) => {
            let finalData = deepcopy()(data)
            finalData.confirmed = true

            if (valueIsDefined(finalData.pricebook_item_type) && !valueIsDefined(finalData.default_is_taxable)) {
                finalData.default_is_taxable = getDefaultTaxable(
                    finalData,
                    this.state.pricebook_default_taxable_service,
                    this.state.pricebook_default_taxable_part,
                    this.state.pricebook_default_taxable_other,
                )
            }

            // If this isn't a service charge, remove service-charge-specific data
            if (state.priceBookItemData.pricebook_item_type !== PriceBookItemTypes.service) {
                delete finalData.default_unit_type
                delete finalData.expected_job_duration
            }

            if (!state.priceBookItemData.track_inventory) {
                delete finalData.inventory_levels
            }

            // Set a blank label list if there aren't any selected
            data.labels = data.labels || []

            return finalData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields description, service_company must make a unique set.") {
                errorDict["description"] = "A PriceBook item with this name already exists."
            }
            else if (fieldName === "vendor_part_costs") {
                errorDict["vendor_part_costs"] = "Please correct the vendor cost errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((vendorPartCostError, index) => updatedState.priceBookItemData.vendor_part_costs[index].errors = vendorPartCostError)
                    return updatedState
                })
            }
            else if (fieldName === "inventory_levels") {
                errorDict["inventory_levels"] = "Please correct the inventory level errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((inventoryLevelError, index) => updatedState.priceBookItemData.inventory_levels[index].errors = inventoryLevelError)
                    return updatedState
                })
            }
            else if (fieldName === "labels" && Array.isArray(message)) {
                errorDict["labels"] = "Labels must be fewer than 100 characters."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "PRICEBOOKITEM_CREATE":
                this.createPriceBookItem()
                break
            case "PRICEBOOKITEM_UPDATE":
                if (this.state.mode === "EDIT_PRICEBOOKITEM") {
                    window.updatePriceBookItem = this.updatePriceBookItem
                    document.querySelector("#message_modal_confirm_update_item").style.display = ""
                    window.MicroModal.show("message_modal_confirm_update_item")
                }
                else {
                    this.updatePriceBookItem()
                }
                break
            case "PRICEBOOKITEM_DELETE":
                window.deletePriceBookItem = this.deletePriceBookItem
                document.querySelector("#message_modal_confirm_delete_item").style.display = ""
                window.MicroModal.show("message_modal_confirm_delete_item")
                break
            case "PRICEBOOKITEM_CANCEL_CREATE":
                location.assign(DjangoUrls["pricebook:pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG) + `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[this.state.priceBookItemData.pricebook_item_type]}`)
                break
            case "PRICEBOOKITEM_CANCEL_EDITS":
                location.assign(DjangoUrls["pricebook:pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG) + `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[this.state.priceBookItemData.pricebook_item_type]}`)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.priceBookItemData === null || this.state.inventoryLevels === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <PriceBookItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingPriceBookItem}
                    priceBookItem={this.state.priceBookItemData}
                    errors={this.state.errors.priceBookItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("priceBookItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={undefined}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    showQuickBooksRevenueAccountSelect={this.state.showQuickBooksRevenueAccountSelect}
                    showQuickBooksTaxAgencyVendorSelect={this.state.showQuickBooksTaxAgencyVendorSelect}
                    useTaxes={this.state.useTaxes}
                    lockItemTypeSelection={this.state.lockItemTypeSelection}
                    pricebookDefaultTaxableService={this.state.pricebook_default_taxable_service}
                    pricebookDefaultTaxablePart={this.state.pricebook_default_taxable_part}
                    pricebookDefaultTaxableOther={this.state.pricebook_default_taxable_other}
                    returnScroll={this.state.returnScroll}
                    isInventoryByDefault={this.state.isInventoryByDefault}
                ></PriceBookItemForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default PriceBookItemCreateContainer;
