import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"

import { Button, ListInfoGlyph } from "@atoms"

import useTable from "@organisms/Table/Table.context"
import styles from "@organisms/Table/TableEdgeStates/TableEdgeStates.module.scss"
import TableLoadingState from "@organisms/Table/TableEdgeStates/TableLoadingState/TableLoadingState"

import { CLEAR_SEARCH_EVENT } from "@constants/events"

import { TableNoResultsStateProps } from "./TableNoResultsState.types"

export default function TableNoResultsState(props: TableNoResultsStateProps) {
    const { children } = props

    const { tabContentName } = useTable()

    const isDesktop = useIsDesktop()

    const explanation = `There are no ${tabContentName
        .toLowerCase()
        .replace("all", "")
        .replace("&", "or")} to display.`

    return (
        <>
            {children}
            <div className={styles.base}>
                <TableLoadingState isAnimated={false} isBackground={true} numberOfRows={isDesktop ? 4 : 2} />
                <div className={styles.content}>
                    <div className={styles.copyWrap}>
                        <ListInfoGlyph variant="black" icon={icon({ name: "eye", style: "solid", family: "sharp" })} />
                        <div className={styles.copy}>
                            <h2 className={styles.title}>No results found</h2>
                            <p className={styles.explanation}>{explanation}</p>
                        </div>
                    </div>

                    <Button
                        colorScheme="gray"
                        size="md"
                        variant="outline"
                        onClick={() => {
                            const openChangelog = new CustomEvent(CLEAR_SEARCH_EVENT, {})
                            window.dispatchEvent(openChangelog)
                        }}
                        noDebounce={true}
                    >
                        Clear search
                    </Button>
                </div>
            </div>
        </>
    )
}
