import clsx from "clsx"
import { ReactNode, useState } from "react"
import { createPortal } from "react-dom"

import useTopBar, { TopBarContext } from "./TopBar.context"
import styles from "./TopBar.module.scss"
import { TopBarProps } from "./TopBar.types"
import TopBarBody from "./TopBarBody/TopBarBody"
import TopBarBodyEnd from "./TopBarBodyEnd/TopBarBodyEnd"
import TopBarBodyStart from "./TopBarBodyStart/TopBarBodyStart"
import TopBarCTAButton from "./TopBarCTAButton/TopBarCTAButton"
import TopBarHeader from "./TopBarHeader/TopBarHeader"
import TopBarHeaderEnd from "./TopBarHeader/TopBarHeaderEnd/TopBarHeaderEnd"
import TopBarHeaderStart from "./TopBarHeader/TopBarHeaderStart/TopBarHeaderStart"
import TopBarHorizontalDivider from "./TopBarHorizontalDivider/TopBarHorizontalDivider"
import TopBarPagePresence from "./TopBarPagePresence/TopBarPagePresence"
import TopBarPageSubtitle from "./TopBarPageSubtitle/TopBarPageSubtitle"
import TopBarPageTitle from "./TopBarPageTitle/TopBarPageTitle"
import TopBarSearchButton from "./TopBarSearchButton/TopBarSearchButton"
import TopBarVerticalDivider from "./TopBarVerticalDivider/TopBarVerticalDivider"
import TopBarViewSelector from "./TopBarViewSelector/TopBarViewSelector"

function TopBarProvider(props: TopBarProps) {
    const { children } = props

    const [topBarRef, setTopBarRef] = useState<HTMLDivElement | null>(null)

    return <TopBarContext.Provider value={{ topBarRef, setTopBarRef }}>{children}</TopBarContext.Provider>
}

function TopBar(props: TopBarProps) {
    const { children } = props

    const { setTopBarRef } = useTopBar()

    return (
        <TopBarProvider>
            <div className={clsx(styles.base, "jsTopBar")} data-topbar-portal={true} ref={setTopBarRef}>
                {children}
            </div>
        </TopBarProvider>
    )
}
function TopBarPortal(props: { children: ReactNode }) {
    const { children } = props

    const { topBarRef } = useTopBar()

    if (!topBarRef) {
        return null
    } else {
        return createPortal(children, topBarRef)
    }
}

const Root = TopBar
const PageTitle = TopBarPageTitle
const PageSubtitle = TopBarPageSubtitle
const SearchButton = TopBarSearchButton
const ViewSelector = TopBarViewSelector
const CTAButton = TopBarCTAButton
const Header = TopBarHeader
const HorizontalDivider = TopBarHorizontalDivider
const VerticalDivider = TopBarVerticalDivider
const Portal = TopBarPortal
const PagePresence = TopBarPagePresence
const Body = TopBarBody
const BodyStart = TopBarBodyStart
const BodyEnd = TopBarBodyEnd
const Provider = TopBarProvider

export default {
    Root,
    TopBarHeaderStart,
    TopBarHeaderEnd,
    Header,
    PageTitle,
    PageSubtitle,
    SearchButton,
    ViewSelector,
    CTAButton,
    HorizontalDivider,
    VerticalDivider,
    Portal,
    PagePresence,
    Body,
    BodyStart,
    BodyEnd,
    Provider,
}
