import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Component } from "react";
import deepcopy from "rfdc";
import LineItemForm from "../core/forms/LineItemForm";
import { CustomIDGenerationModes, LineItemUnitTypes, PriceBookItemTypes, ShippingCarriers, ShippingMethods } from "../core/utils/enums";
import { currencyFormatter, getCurrencySymbol, historyHasState, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import { validateLineItem } from "../invoices/utils/utils";
import VendorForm from "../pricebook/forms/VendorForm";
import PurchaseOrderForm from "./forms/PurchaseOrderForm";

dayjs.extend(timezone)

const FORM_MODES = {
    ADD_PURCHASE_ORDER: "ADD_PURCHASE_ORDER",
    EDIT_PURCHASE_ORDER: "EDIT_PURCHASE_ORDER",
    ADD_VENDOR: "ADD_VENDOR",
    EDIT_VENDOR: "EDIT_VENDOR",
    ADD_PART: "ADD_PART",
    EDIT_PART: "EDIT_PART",
}

const FORM_MODE_SUBTITLES = {
    ADD_PURCHASE_ORDER: "Add Purchase Order Details",
    EDIT_PURCHASE_ORDER: "Edit Purchase Order Details",
    ADD_VENDOR: "New Vendor",
    EDIT_VENDOR: "Edit Vendor",
    ADD_PART: "Add Part or Material",
    EDIT_PART: "Edit Part or Material",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_PURCHASE_ORDER: "flex",
    EDIT_PURCHASE_ORDER: "flex",
    ADD_VENDOR: "none",
    EDIT_VENDOR: "none",
    ADD_PART: "none",
    EDIT_PART: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_PURCHASE_ORDER, FORM_MODES.EDIT_PURCHASE_ORDER]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_VENDOR,
    FORM_MODES.EDIT_VENDOR,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
]

const VENDOR_FORM_MODES = [
    FORM_MODES.ADD_VENDOR,
    FORM_MODES.EDIT_VENDOR,
]

const LINE_ITEM_FORM_MODES = [
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_PURCHASE_ORDER: "purchaseOrderData",
    EDIT_PURCHASE_ORDER: "purchaseOrderData",
    ADD_VENDOR: "vendorData",
    EDIT_VENDOR: "vendorData",
    ADD_PART: "lineItemData",
    EDIT_PART: "lineItemData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_PURCHASE_ORDER: "submittingPurchaseOrder",
    EDIT_PURCHASE_ORDER: "submittingPurchaseOrder",
    ADD_VENDOR: "submittingVendor",
    EDIT_VENDOR: "submittingVendor",
    ADD_PART: "submittingLineItem",
    EDIT_PART: "submittingLineItem",
}

const ERROR_NAMES_BY_MODE = {
    ADD_PURCHASE_ORDER: "purchaseOrder",
    EDIT_PURCHASE_ORDER: "purchaseOrder",
    ADD_VENDOR: "vendor",
    EDIT_VENDOR: "vendor",
    ADD_PART: "lineItem",
    EDIT_PART: "lineItem",
}

const LINE_ITEM_TYPES = {
    SERVICE_CHARGES: "service_charges",
    PARTS: "parts",
    OTHER_CHARGES: "other_charges",
    DISCOUNTS: "discounts",
}

const LINE_ITEM_TYPE_MAP = {
    [LINE_ITEM_TYPES.SERVICE_CHARGES]: PriceBookItemTypes.service,
    [LINE_ITEM_TYPES.PARTS]: PriceBookItemTypes.part,
    [LINE_ITEM_TYPES.OTHER_CHARGES]: PriceBookItemTypes.other,
    [LINE_ITEM_TYPES.DISCOUNTS]: PriceBookItemTypes.discount,
}

const LINE_ITEM_TYPE_BY_MODE = {
    ADD_PART: PriceBookItemTypes.part,
    EDIT_PART: PriceBookItemTypes.part,
}


class PurchaseOrderCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_PURCHASE_ORDER
        this.addToastToQueue = this.props.addToastToQueue

        this.shippingCarrierChoices = window.SHIPPING_CARRIER_CHOICES
        this.shippingMethodChoices = window.SHIPPING_METHOD_CHOICES

        this.state = {
            purchaseOrderData: null,
            vendorData: {},
            lineItemData: {},
            deletedLineItemIDs: {},

            attachments: [],

            selectedVendor: null,
            selectedInventoryLocation: null,

            isDraft: false,

            errors: {
                purchaseOrder: {},
                vendor: {},
                lineItem: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            preferredTimezone: window.PREFERRED_TIMEZONE,
            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
            showCustomPurchaseOrderIDField: window.PURCHASE_ORDER_CUSTOM_ID_GENERATION_MODE === CustomIDGenerationModes.manual,

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        // If props tell us this is an edit view, grab purchase order data via rest
        if (this.state.defaultMode === FORM_MODES.EDIT_PURCHASE_ORDER && window.PURCHASE_ORDER_ID) {
            if (this.state.purchaseOrderData === null) {
                const purchaseOrderEndpoint = DjangoUrls["purchase-orders:api-purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, window.PURCHASE_ORDER_ID)
                const purchaseOrderResponse = await fetch(purchaseOrderEndpoint)
                const purchaseOrder = await purchaseOrderResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.purchaseOrderData = purchaseOrder

                    updatedState.selectedVendor = purchaseOrder.vendor
                    updatedState.vendor_id = updatedState.selectedVendor.id
                    updatedState.selectedInventoryLocation = purchaseOrder.inventory_location
                    updatedState.inventory_location_id = updatedState.selectedInventoryLocation.id

                    updatedState.isDraft = purchaseOrder.is_draft

                    // Convert service company to slug
                    updatedState.purchaseOrderData.service_company = purchaseOrder.service_company.slug

                    // Convert vendor to ID
                    updatedState.purchaseOrderData.vendor = purchaseOrder.vendor.id

                    // Convert inventory location to ID
                    updatedState.purchaseOrderData.inventory_location = purchaseOrder.inventory_location.id

                    updatedState.attachments = deepcopy()(purchaseOrder.attachments)
                    updatedState.labels = deepcopy()(purchaseOrder.labels)

                    this.resetpurchaseOrderData(updatedState, state, purchaseOrder)

                    updatedState.purchaseOrderData.service_charges = []
                    updatedState.purchaseOrderData.parts = purchaseOrder.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    updatedState.purchaseOrderData.other_charges = []
                    updatedState.purchaseOrderData.discounts = []

                    return updatedState
                })
            }
        }
        else {
            const localTime = dayjs.tz(undefined, window.PREFERRED_TIMEZONE)
            const localDateString = localTime.format("YYYY-MM-DD")

            this.setState((state, props) => {
                let updatedState = state

                this.resetpurchaseOrderData(updatedState, state, {})

                updatedState.purchaseOrderData.service_company = window.MARKETPLACE_ENTITY_SLUG
                updatedState.purchaseOrderData.date_issued = localDateString

                updatedState.purchaseOrderData.service_charges = []
                updatedState.purchaseOrderData.parts = []
                updatedState.purchaseOrderData.other_charges = []
                updatedState.purchaseOrderData.discounts = []

                updatedState.purchaseOrderData.attachments = []
                updatedState.purchaseOrderData.labels = []

                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                // To help with uniqueness check
                data.originalDescription = data.description
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (LINE_ITEM_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].line_item_type = LINE_ITEM_TYPE_BY_MODE[newFormMode]

                    if (newFormMode === FORM_MODES.ADD_PART) {
                        const inventory_location = state.purchaseOrderData.inventory_location.toString()
                        updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].inventory_location = inventory_location
                        updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].inventory_location_label = window.INVENTORY_LOCATION_CHOICES.find(location => location.value === parseInt(inventory_location)).label
                    }
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    resetpurchaseOrderData = (updatedState, state, purchaseOrderData) => {
        updatedState.purchaseOrderData = purchaseOrderData
        updatedState.purchaseOrderData.is_draft = state.isDraft
    }

    updateVendorSelection = (selectedVendor) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedVendor !== null) {
                updatedState.selectedVendor = selectedVendor

                // Set the selection, set the data
                updatedState.purchaseOrderData.vendor = selectedVendor.id
                updatedState.vendor_id = selectedVendor.id

                this.resetpurchaseOrderData(updatedState, state, state.purchaseOrderData)
            }
            else {
                // Vendor was unset. Unset data
                updatedState.selectedVendor = null
            }

            return updatedState
        })
    }

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.purchaseOrderData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    updateInventoryLocationSelection = (selectedInventoryLocation) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedInventoryLocation !== null) {
                updatedState.selectedInventoryLocation = selectedInventoryLocation

                // Set the selection, set the data
                updatedState.purchaseOrderData.inventory_location = selectedInventoryLocation.id
                updatedState.inventory_location_id = selectedInventoryLocation.id

                this.resetpurchaseOrderData(updatedState, state, state.purchaseOrderData)
            }
            else {
                // Inventory location was unset. Unset data
                updatedState.selectedInventoryLocation = null
            }

            return updatedState
        })
    }

    // Crud Line Items

    addCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.purchaseOrderData, this.state.lineItemData, false, false, false, true, false)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData
                data.line_item_type = LINE_ITEM_TYPE_MAP[lineItemListName]

                // Re-use an existing ID for this description to avoid uniqueness constraint issues
                if (data.description in state.deletedLineItemIDs) {
                    data.id = state.deletedLineItemIDs[data.description]
                }
                else {
                    data.id = null
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                updatedState.purchaseOrderData[lineItemListName].push(data)
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    lineItemEquals = (existing, updated) => {
        // If the object is new, it won't have an id. Check the description instead
        return existing.id !== null ? existing.id === updated.id : existing.description === updated.originalDescription
    }

    updateCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.purchaseOrderData, this.state.lineItemData, false, false, false, true, false)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                const lineItemIndex = updatedState.purchaseOrderData[lineItemListName].findIndex(lineItem => this.lineItemEquals(lineItem, state.lineItemData))
                updatedState.purchaseOrderData[lineItemListName][lineItemIndex] = data
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    deleteCurrentLineItem = (lineItemListName) => {
        this.setState((state, props) => {
            let updatedState = state

            // Save the ID in case this description is going to be reused
            if (state.lineItemData.id) {
                updatedState.deletedLineItemIDs[state.lineItemData.description] = state.lineItemData.id
            }

            updatedState.purchaseOrderData[lineItemListName] = updatedState.purchaseOrderData[lineItemListName].filter(lineItem => !this.lineItemEquals(lineItem, state.lineItemData))
            updatedState.errors.lineItem = {}
            return updatedState
        })
        this.switchToPrimaryForm()
    }

    // Crud Purchase Order

    previewPurchaseOrder = async => {
        // Create a draft and redirect to the preview page
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (purchaseOrder) => {
            const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${purchaseOrder.custom_id || purchaseOrder.id}" draft saved`,
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order draft could not be saved",
            })
        }

        this.CUDPurchaseOrder(endpoint, "POST", onSuccess, onError, true)
    }

    createPurchaseOrder = async (isDraft) => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (purchaseOrder) => {
            let successUrl
            if(window.CURRENT_USER?.permissions.purchase_orders_list_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["dashboard:dashboard"](window.MARKETPLACE_ENTITY_SLUG)
            } else if (isDraft || window.CURRENT_USER?.permissions.purchase_orders_view_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["purchase-orders:purchase-orders-list"](window.MARKETPLACE_ENTITY_SLUG)
            }
            else {
                const param = window.CURRENT_USER?.permissions.purchase_orders_edit_permission === PERMISSION_LEVEL.FULL ? "mode=send-purchase-order" : ""
                successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id) + `?${param}`
            }
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${purchaseOrder.custom_id || purchaseOrder.id}" ${isDraft ? "draft saved" : "created"}`,
                cta:
                    isDraft && window.CURRENT_USER?.permissions.purchase_orders_view_permission >= PERMISSION_LEVEL.FULL ?
                    {
                        children: "View",
                        destination: DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id)
                    }
                    :
                    undefined,
                path: successUrl.split("?")[0],
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Purchase Order ${isDraft ? "draft could not be saved" : "could not be created"}`,
            })
        }

        this.CUDPurchaseOrder(endpoint, "POST", onSuccess, onError, isDraft)
    }

    updatePurchaseOrder = async (resend=false) => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id) + `${resend ? "?mode=send-purchase-order" : ""}`
        const onSuccess = (purchaseOrder) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${purchaseOrder.custom_id || purchaseOrder.id}" updated`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order could not be updated",
            })
        }

        this.CUDPurchaseOrder(endpoint, "PUT", onSuccess, onError, false)
    }

    deleteDraftPurchaseOrder = async () => {
        const endpoint = DjangoUrls["purchase-orders:api-purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id)
        const successUrl = DjangoUrls["purchase-orders:purchase-orders-list"](window.MARKETPLACE_ENTITY_SLUG)
        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Purchase Order "${this.state.purchaseOrderData.custom_id || this.state.purchaseOrderData.id}" draft deleted`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_confirm_delete_draft .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_delete_draft .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Purchase Order draft could not be deleted",
            })
            const deleteDraftModal = document.querySelector("#message_modal_confirm_delete_draft .modal__close")
            if (deleteDraftModal) {
                deleteDraftModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        this.CUDPurchaseOrder(endpoint, "DELETE", onSuccess, onError, false)
    }

    CUDPurchaseOrder = async (endpoint, endpointMethod, onSuccess, onError, isDraft) => {
        const dataName = "purchaseOrderData"
        const submittingName = "submittingPurchaseOrder"
        const errorDictName = "purchaseOrder"

        const dataManipulator = (data, state) => {
            data.line_items = [...data.parts]

            // Convert blank Purchase Order ID value to null
            data.custom_id = data.custom_id || null

            // Set a blank label list if there aren't any selected
            data.labels = data.labels || []

            data.preferred_shipping_carrier = data.preferred_shipping_carrier || null
            data.preferred_shipping_method = data.preferred_shipping_method || null

            if (parseInt(data.preferred_shipping_carrier) !== ShippingCarriers.other) {
                data.preferred_shipping_carrier_other_name = ""
            }

            if (parseInt(data.preferred_shipping_method) !== ShippingMethods.other) {
                data.preferred_shipping_method_other_name = ""
            }

            const cleanData = deepcopy()(data)
            cleanData.is_draft = isDraft  // We don't want this to change the actual object
            return cleanData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, custom_id must make a unique set.") {
                errorDict["custom_id"] = "A purchase order with this ID already exists."
            }
            else if (fieldName === "line_items") {
                errorDict["line_items"] = "Please correct the line item errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state

                    const indexedLineItems = [
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.PARTS],
                    ]
                    message.map((lineItemError, index) => indexedLineItems[index].errors = lineItemError)

                    return updatedState
                })
            }
            else if (fieldName === "labels" && Array.isArray(message)) {
                errorDict["labels"] = "Labels must be fewer than 100 characters."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud Vendor

    createVendor = async () => {
        const endpoint = DjangoUrls["pricebook:api-vendors-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const onSuccess = (vendor) => {
            this.updateVendorSelection(vendor)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${vendor.name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be created",
            })
        }

        this.createUpdateVendor(endpoint, endpointMethod, onSuccess, onError)
    }

    updateVendor = async () => {
        const endpoint = DjangoUrls["pricebook:api-vendors-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedVendor.id)
        const endpointMethod = "PUT"

        const onSuccess = (vendor) => {
            this.updateVendorSelection(vendor)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${vendor.name}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be updated",
            })
        }

        this.createUpdateVendor(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateVendor = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "vendorData"
        const submittingName = "submittingVendor"
        const errorDictName = "vendor"

        const dataManipulator = (data, state) => {
            data.contacts = data.contacts || []

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A vendor with this name already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "PURCHASE_ORDER_PREVIEW":
                this.previewPurchaseOrder()
                break
            case "PURCHASE_ORDER_CREATE":
                this.createPurchaseOrder(false)
                break
            case "PURCHASE_ORDER_CREATE_DRAFT":
                this.createPurchaseOrder(true)
                break
            case "PURCHASE_ORDER_UPDATE":
                this.updatePurchaseOrder(false)
                break
            case "PURCHASE_ORDER_UPDATE_AND_RESEND":
                this.updatePurchaseOrder(true)
                break
            case "PURCHASE_ORDER_DELETE_DRAFT":
                window.deleteDraftPurchaseOrder = this.deleteDraftPurchaseOrder
                document.querySelector("#message_modal_confirm_delete_draft").style.display = ""
                window.MicroModal.show("message_modal_confirm_delete_draft")
                break
            case "PURCHASE_ORDER_CANCEL_EDITS":
                location.assign(DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.purchaseOrderData.id))
                break
            case "VENDOR_CREATE":
                this.createVendor()
                break
            case "VENDOR_UPDATE":
                this.updateVendor()
                break
            case "VENDOR_CANCEL_CREATE":
                this.switchToPrimaryForm()
                break
            case "VENDOR_CANCEL_EDITS":
                this.switchToPrimaryForm()
                break
            case "ADD_PART":
                this.addCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "EDIT_PART":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "DELETE_PART":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    foldDataComplete = () => {
        const dateIssuedValid = Boolean(this.state.purchaseOrderData.date_issued)
        const purchaseOrderIDValid = this.state.showCustomPurchaseOrderIDField === false || (this.state.showCustomPurchaseOrderIDField === true && Boolean(this.state.purchaseOrderData.custom_id))

        return dateIssuedValid && purchaseOrderIDValid
    }

    render() {
        if (this.state.purchaseOrderData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <PurchaseOrderForm
                    mode={this.state.mode}
                    submitting={this.state.submittingPurchaseOrder}
                    purchaseOrder={this.state.purchaseOrderData}
                    errors={this.state.errors.purchaseOrder}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("purchaseOrderData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateVendorSelection={this.updateVendorSelection}
                    updateInventoryLocationSelection={this.updateInventoryLocationSelection}
                    selectedVendor={this.state.selectedVendor}
                    selectedInventoryLocation={this.state.selectedInventoryLocation}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    showCustomPurchaseOrderIDField={this.state.showCustomPurchaseOrderIDField}
                    foldDataComplete={this.foldDataComplete()}
                    shippingCarrierChoices={this.shippingCarrierChoices}
                    shippingMethodChoices={this.shippingMethodChoices}
                    fileStackAPIKey={this.state.fileStackAPIKey}
                    fileStackPolicy={this.state.fileStackPolicy}
                    fileStackSignature={this.state.fileStackSignature}
                    updateAttachments={this.updateAttachments}
                    returnScroll={this.state.returnScroll}
                ></PurchaseOrderForm>
            }
            else if (VENDOR_FORM_MODES.includes(this.state.mode)) {
                return <VendorForm
                    mode={this.state.mode}
                    submitting={this.state.submittingVendor}
                    vendor={this.state.vendorData}
                    errors={this.state.errors.vendor}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("vendorData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={undefined}
                    returnScroll={0}
                ></VendorForm>
            }
            else if (LINE_ITEM_FORM_MODES.includes(this.state.mode)) {
                return <LineItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingLineItem}
                    parent={this.state.purchaseOrderData}
                    lineItem={this.state.lineItemData}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    errors={this.state.errors.lineItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("lineItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                    objectName={"Purchase Order"}
                    preferredTimezone={this.state.preferredTimezone}
                    isPurchaseOrderLineItem={true}
                ></LineItemForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default PurchaseOrderCreateContainer;
