import { JobTimelineScheduler } from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

export const renderHeaderMinutes = (start: Date) => {
    const date = new Date(start)
    let hour = date.getHours()
    const minutes = date.getMinutes()
    let label: string = ""

    if (minutes === 0) {
        // Only label when minutes are zero
        if (hour === 0) {
            label = "Midnight"
        } else if (hour === 12) {
            label = "Noon"
        } else if (hour < 12) {
            label = `${hour} AM`
        } else {
            hour -= 12
            label = `${hour} PM`
        }
    }
    if (label) {
        return `<div class="timeline__header-time-axis-item">${label}</div>`
    } else {
        return `<div class="timeline__header-time-axis-item-empty"></div>`
    }
}

export const renderHeaderCurrentDay = (start: Date) => {
    const originalDate = new Date(start)
    const formattedDate = originalDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
    })

    return `
            <div class="timeline-header">
                <div class="timeline-header__date">${formattedDate}</div>
                <div class="timeline-header__year">${originalDate.getFullYear()}</div>
            </div>
        `
}

export const getScheduler = (child: { up: (schedulerKey: string) => JobTimelineScheduler }): JobTimelineScheduler => {
    return child.up("schedulerpro")
}
