import { Component } from 'react';
import WrapperLinkButton from '../../core/buttons/WrapperLinkButton';
import { convertToKebabCase, formatCurrencyForServiceCompany } from '../../core/utils/utils';
import { calculatePurchaseOrderAmounts } from '../utils/utils';


class PurchaseOrderObjectLink extends Component {

    render() {
        const { purchaseOrder, destination, disabled } = this.props

        const buttonTitle = `Purchase Order ${purchaseOrder.id} [${purchaseOrder.status_label}]`
        const buttonPillClass = `purchase-orders-${convertToKebabCase(purchaseOrder.status_label)}`
        const buttonPillLabel = purchaseOrder.status_label
        const buttonLabel = formatCurrencyForServiceCompany(calculatePurchaseOrderAmounts(purchaseOrder).subtotal, purchaseOrder.service_company)

        return (
            <WrapperLinkButton type="object_link" destination={destination} openNew={true} title={buttonTitle} ariaLabel="View Purchase Order" disabled={disabled}>
                <div className="data-panel__list-select__option__label"><div className={`data-panel__list-select__option__status-pill ${buttonPillClass}`}>{buttonPillLabel}</div><span>{buttonLabel}</span></div><div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperLinkButton>
        )
    }
}

export default PurchaseOrderObjectLink;
