import clsx from "clsx"
import { createContext } from "react"

import styles from "./TabStack.module.scss"
import { TabStackContextValues, TabStackProps } from "./TabStack.types"

export const TabStackContext = createContext<TabStackContextValues | undefined>(undefined)
TabStackContext.displayName = "TabStack"

export default function TabStack(props: TabStackProps) {
    const { children, variant, size } = props

    return (
        <div
            className={clsx(styles.base, styles[`${size}Size`], {
                [styles.attached]: variant === "attached",
                [styles.underlined]: variant === "underlined",
            })}
            role="tablist"
        >
            <TabStackContext.Provider value={{ variant, size }}>{children}</TabStackContext.Provider>
        </div>
    )
}
