import { sendDataToServer, valueIsDefined } from "@legacy/core/utils/utils"
import { Component } from "react"
import deepcopy from "rfdc"

import InlinePriceOverrideDisplay from "./components/InlinePriceOverrideDisplay"
import InlinePriceOverrideForm from "./forms/inlinePriceOverrideForm"

const PAGE_MODES = {
    VIEW_ITEM: "VIEW_ITEM",
    EDIT_ITEM: "EDIT_ITEM",
}

const FORM_DATA_NAMES_BY_MODE = {
    EDIT_ITEM: "itemFormData",
}

class InlinePriceOverrideContainer extends Component {
    constructor(props) {
        super(props)

        let defaultMode = this.props.formMode || PAGE_MODES.VIEW_ITEM
        this.addToastToQueue = this.props.addToastToQueue

        const priceBookOverride = deepcopy()(this.props.priceBookOverride)
        const isNewAddition = priceBookOverride.__new__ === true

        if (isNewAddition) {
            defaultMode = PAGE_MODES.EDIT_ITEM
            delete priceBookOverride.__new__
        }

        this.state = {
            removed: false,
            isNewAddition: isNewAddition,
            itemData: priceBookOverride,
            itemFormData: deepcopy()(priceBookOverride),
            mode: defaultMode,
            errors: {
                overrideErrors: {},
            },
        }
    }

    addOverride = async (type, item) => {
        const dataName = "itemFormData"
        const submittingName = "submitting"
        const errorDictName = "overrideErrors"

        const endpoint =
            DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const onSuccess = (createdOverride) => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.itemData = createdOverride
                updatedState.itemFormData = deepcopy()(createdOverride)
                updatedState.isNewAddition = false
                return updatedState
            })

            this.props.updateOverride(createdOverride)

            this.switchFormMode(PAGE_MODES.VIEW_ITEM)

            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Override created",
            })
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Override could not be created",
            })
        }

        const dataManipulator = (data, state) => {
            const cleanData = deepcopy()(data)
            cleanData.pricebook_item = data.pricebook_item?.id
            return cleanData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields external_client, pricebook_item must make a unique set.") {
                errorDict["pricebook_item"] = "An override for this item already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    deleteOverride = async (item) => {
        const errorDictName = "overrideErrors"

        const endpoint =
            DjangoUrls["pricebook:api-pricebookitem-client-overrides-detail"](
                window.MARKETPLACE_ENTITY_SLUG,
                item.id,
            )

        const onSuccess = () => {
            this.softDeleteItem()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Override Deleted",
            })
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Override Not Deleted",
            })
        }

        await sendDataToServer(this, endpoint, "DELETE", null, null, errorDictName, onSuccess, onError)
    }

    updateOverride = async (item) => {
        const dataName = "itemFormData"
        const submittingName = "submitting"
        const errorDictName = "overrideErrors"

        const endpoint =
        DjangoUrls["pricebook:api-pricebookitem-client-overrides-detail"](
            window.MARKETPLACE_ENTITY_SLUG,
            item.id,
        )
        const endpointMethod = "PUT"

        const onSuccess = (updatedOverride) => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.itemData = updatedOverride
                updatedState.itemFormData = deepcopy()(updatedOverride)
                updatedState.isNewAddition = false
                return updatedState
            })

            this.props.updateOverride(updatedOverride)

            this.switchFormMode(PAGE_MODES.VIEW_ITEM)

            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Override updated",
            })
        }

        const onError = () => {
            this.switchToDisplay()
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Override could not be updated",
            })
        }

        const dataManipulator = (data, state) => {
            const cleanData = deepcopy()(data)
            cleanData.pricebook_item = data.pricebook_item?.id
            return cleanData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields external_client, pricebook_item must make a unique set.") {
                errorDict["priceook_item"] = "An override for this item already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            return updatedState
        })
    }

    switchToDisplay = () => {
        this.setState((state, props) => {
            let updatedState = state
            return updatedState
        })
        this.switchFormMode(PAGE_MODES.VIEW_ITEM)
    }

    switchToPrimaryForm = () => {
        this.switchFormMode(PAGE_MODES.EDIT_ITEM)
    }

    softDeleteItem = (json) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.removed = true
            return updatedState
        })
    }

    validateOverride = (override) => {
        let isValid = true
        let errors = {}

        if (!valueIsDefined(override.pricebook_item) || !valueIsDefined(override.pricebook_item?.id)) {
            isValid = false
            errors.pricebook_item = "Item cannot be blank."
        }

        if (!valueIsDefined(override.price)) {
            isValid = false
            errors.price = "Price cannot be blank."
        }

        return { isValid, errors }
    }

    handleOverride = (action, callback) => {
        const { isValid, errors } = this.validateOverride(this.state.itemFormData)

        if (isValid) {
            this.setState((state) => {
                let updatedState = { ...state }
                delete updatedState.itemFormData.errors
                callback(updatedState.itemData)

                return updatedState
            })
        } else {
            this.setState((state) => {
                let updatedState = { ...state }
                updatedState.itemFormData.errors = errors
                return updatedState
            })
        }
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "ITEM_EDIT":
                this.switchToPrimaryForm()
                break
            case "ITEM_ADD":
                this.handleOverride("ITEM_ADD", (itemData) => this.addOverride(itemData))
                break
            case "ITEM_SAVE":
                this.handleOverride("ITEM_SAVE", (itemData) => this.updateOverride(itemData))
                break
            case "ITEM_DELETE":
                this.deleteOverride(this.state.itemData)
                break
            case "ITEM_CANCEL_ADD":
                this.softDeleteItem()
                this.props.deleteOverride(this.state.itemData)
                break
            case "ITEM_CANCEL_EDITS":
                this.switchToDisplay()
                break

            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render = () => {
        if (!this.state.removed) {
            if (this.state.mode === PAGE_MODES.VIEW_ITEM) {
                return (
                    <InlinePriceOverrideDisplay
                        item={this.state.itemData}
                        requestAction={this.handleActionRequest}
                        formatCurrencyValue={this.props.formatCurrencyValue}
                    />
                )
            } else if (this.state.mode === PAGE_MODES.EDIT_ITEM) {
                return (
                    <InlinePriceOverrideForm
                        item={this.state.itemFormData}
                        isNewAddition={this.state.isNewAddition}
                        pricebookItemType={this.props.pricebookItemType}
                        formatCurrencyValue={this.props.formatCurrencyValue}
                        currencySymbol={this.props.currencySymbol}
                        onFormDataChange={(fieldName, fieldValue) =>
                            this.updateFormData(FORM_DATA_NAMES_BY_MODE[this.state.mode], fieldName, fieldValue)
                        }
                        requestAction={this.handleActionRequest}
                        errors={this.state.errors.overrideErrors}
                    />
                )
            } else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return null
        }
    }
}

export default InlinePriceOverrideContainer
