import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import TagField from "@legacy/core/fields/TagField";
import TagSearchInput from "@legacy/core/inputs/TagSearchInput";
import { AccessCheck } from "@particles/index";
import { Component } from "react";
import deepcopy from "rfdc";
import ContactListEditSelect from "../../clients/components/ContactListEditSelect";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AddressAutocompleteFieldGroup from "../../core/fields/AddressAutocompleteFieldGroup";
import CharField from "../../core/fields/CharField";
import ListSelectField from "../../core/fields/ListSelectField";
import PaymentTermsField from "../../core/fields/PaymentTermsField";
import TextField from "../../core/fields/TextField";


class VendorForm extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            vendor,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_VENDOR") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <AccessCheck permissions={{
                                    vendors_create_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    <UniversalButton type="primary" text="Create" handler={event => requestAction("VENDOR_CREATE")} />
                                </AccessCheck>
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("VENDOR_CANCEL_CREATE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_VENDOR") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <AccessCheck permissions={{
                                    vendors_edit_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    <UniversalButton type="primary" text="Save" handler={event => requestAction("VENDOR_UPDATE")} />
                                </AccessCheck>
                                <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("VENDOR_CANCEL_EDITS")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    toggleUseTerms = () => {
        this.props.onFormDataChange("default_invoice_net", null)
        this.setState({useTerms: !this.state.useTerms})
    }

    render() {
        const {
            mode,
            submitting,
            vendor,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            returnScroll
        } = this.props
        const newContactExistsInList = (vendor.contacts || []).some(contact => contact.__new__ || false)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Vendor Create/Update">
                    <div className="data-panel__form" aria-label="Vendor Create/Update Form">
                        <p className="data-panel__form__caption">Please provide the following information about your vendor:</p>
                        <CharField
                            fieldName="name"
                            fieldLabel="Name"
                            fieldValue={vendor.name || ""}
                            fieldOnChange={name => onFormDataChange("name", name || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        <CharField
                            fieldName="website"
                            fieldLabel="Website"
                            fieldValue={vendor.website || ""}
                            fieldOnChange={website => onFormDataChange("website", website || "")}
                            optional={true}
                            errors={errors}
                        ></CharField>
                        <AddressAutocompleteFieldGroup
                            key="vendor_billing_address"
                            fieldGroupName="billing_address"
                            fieldGroupLabel="Billing Address"
                            fieldPrefix="billing_address"
                            fieldValues={{
                                "billing_address_recipient": vendor.billing_address_recipient || "",
                                "billing_address_street": vendor.billing_address_street || "",
                                "billing_address_unit": vendor.billing_address_unit || "",
                                "billing_address_city": vendor.billing_address_city || "",
                                "billing_address_state": vendor.billing_address_state || "",
                                "billing_address_postal": vendor.billing_address_postal || "",
                                "billing_address_country": vendor.billing_address_country || "",
                            }}
                            onFormDataChange={onFormDataChange}
                            includeRecipient={true}
                            optional={true}
                            errors={errors}
                        ></AddressAutocompleteFieldGroup>
                        <CharField
                            fieldName="account_id"
                            fieldLabel="Account ID"
                            fieldValue={vendor.account_id || ""}
                            fieldOnChange={account_id => onFormDataChange("account_id", account_id || "")}
                            maxLength="30"
                            optional={true}
                            optionalText="if you have one"
                            errors={errors}
                        ></CharField>
                        <TagField
                            fieldName="labels"
                            fieldLabel="Labels"
                            inputComponent={
                                <TagSearchInput
                                    defaultSelectedTags={vendor.labels?.map(label => label.name) ?? []}
                                    onSelectionChange={labelNames => onFormDataChange("labels", labelNames ? labelNames.map(labelName => ({"name": labelName})) : [])}
                                    choicesEndpoint={DjangoUrls["pricebook:api-vendors-labels-list"](window.MARKETPLACE_ENTITY_SLUG)}
                                    placeholder="Add Labels..."
                                    noOptionsMessage="No Labels Found"
                                    isMulti={true}
                                ></TagSearchInput>
                            }
                            optional={true}
                            errors={errors}
                        ></TagField>
                        <PaymentTermsField
                            fieldName="payment_terms"
                            fieldLabel="Payment Terms"
                            fieldValue={vendor.payment_terms !== null ? vendor.payment_terms : ""}
                            fieldOnChange={payment_terms => onFormDataChange("payment_terms", payment_terms || null)}
                            optional={true}
                            errors={errors}
                        ></PaymentTermsField>
                        <TextField
                            fieldName="notes"
                            fieldLabel="Vendor Notes"
                            fieldValue={vendor.notes || ""}
                            fieldOnChange={notes => onFormDataChange("notes", notes || "")}
                            rows={3}
                            placeholder="Any additional details about this vendor. Details about a particular order should not be entered here."
                            optional={true}
                            errors={errors}
                        ></TextField>
                        <ListSelectField
                            fieldName="contacts"
                            fieldLabel="Vendor Contacts"
                            inputComponent={<ContactListEditSelect fieldName="contacts" contacts={vendor.contacts || []} primaryContactId={vendor.primary_contact} updateContacts={contacts => onFormDataChange("contacts", contacts)} />}
                            showButton={!newContactExistsInList}
                            buttonLabel="Add Contact"
                            buttonAction={event => {
                                let newContacts = deepcopy()(vendor.contacts || [])
                                newContacts.push({
                                    "id": null,
                                    "name": "",
                                    "phone": "",
                                    "phone_extension": "",
                                    "email": "",
                                    "primary": false,
                                    "attached_to": "vendor",
                                    "__new__": true
                                })
                                onFormDataChange("contacts", newContacts)
                            }}
                            errors={errors}
                        ></ListSelectField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

}

export default VendorForm;
