import { atom, useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { useEffect, useMemo } from "react"

import { TableViewConfig } from "@organisms/Table/Table.types"
import { migrateConfig } from "@organisms/Table/Table.utils"

import defaultTableViewConfig, { TableViewConfigType } from "@constants/defaultTableViewConfig"

const tableViewStorageKeyAtom = atom<string>("")
const tableViewConfigAtom = atomWithStorage<TableViewConfigType>("objectsView", defaultTableViewConfig, undefined, {
    getOnInit: true,
})

export default function useTableViewConfiguration(
    tableStorageKey: string | undefined,
    defaultTableConfigOverride?: TableViewConfig,
) {
    const [activeTableStorageKey, setActiveTableStorageKey] = useAtom(tableViewStorageKeyAtom)
    const [currentTableViewConfig, setTableViewConfig] = useAtom(tableViewConfigAtom)

    const resolvedStorageKey = tableStorageKey || activeTableStorageKey

    const defaultTableConfig = defaultTableConfigOverride || defaultTableViewConfig.table[resolvedStorageKey]

    const persistedTableConfig = currentTableViewConfig.table[resolvedStorageKey]

    const effectiveTableConfig = useMemo(() => {
        return persistedTableConfig ?? defaultTableConfig
    }, [persistedTableConfig, defaultTableConfig])

    useEffect(() => {
        if (!currentTableViewConfig || !defaultTableViewConfig) {
            return
        }

        const hasChanges = currentTableViewConfig.version !== defaultTableViewConfig.version

        if (hasChanges) {
            const migratedConfig = migrateConfig(currentTableViewConfig, defaultTableViewConfig)
            setTableViewConfig(migratedConfig)
        }
    }, [])

    useEffect(() => {
        if (tableStorageKey) {
            setActiveTableStorageKey(tableStorageKey)
        }
    }, [tableStorageKey])

    const updateCurrentTableViewConfig = (
        key: keyof TableViewConfig,
        updatedValue: TableViewConfig[keyof TableViewConfig],
    ) => {
        setTableViewConfig((prevViews) => ({
            ...prevViews,
            table: {
                ...prevViews.table,
                [activeTableStorageKey]: {
                    ...defaultTableViewConfig.table[activeTableStorageKey],
                    ...(prevViews.table[activeTableStorageKey] as TableViewConfig),
                    [key]: updatedValue,
                },
            },
        }))
    }

    return {
        currentTableViewConfig: effectiveTableConfig,
        updateCurrentTableViewConfig,
    }
}
