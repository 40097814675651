import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import { Component, Fragment } from "react"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import Spinner from "@legacy/core/components/Spinner"
import ButtonGroup from "../../core/buttons/ButtonGroup"
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow"
import UniversalButton from "../../core/buttons/UniversalButton"
import { sendDataToServer } from "../../core/utils/utils"
import QuickBooksDesktopSetupForm from "../forms/QuickBooksDesktopSetupForm"

const ChooseAnAccount = () => (
    <option key="default" value="">
        Choose an Account
    </option>
)

const NoAccounts = () => (
    <option key="default" value="">
        No Accounts Available
    </option>
)

const HelpText = ({ children }) => (
    <p className="data-panel__form__caption data-panel__form__caption--field-inline-after-input">{children}</p>
)

const AccountSelector = ({ accounts, name, value, defaultValue, updateConfigData }) => (
    <div className="data-panel__form__field__input data-panel__form__field__input--inline">
        <select
            name={name}
            id={`id_${name}`}
            value={value}
            defaultValue={defaultValue}
            onChange={(event) => updateConfigData(event.target.name, event.target.value)}
            autoComplete="off"
            disabled={accounts.length === 0 || window.CURRENT_USER.permissions.settings_edit_permission < PERMISSION_LEVEL.FULL}
        >
            {accounts.length === 0 ? (
                <NoAccounts />
            ) : (
                <Fragment>
                    <ChooseAnAccount />
                    {accounts.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </Fragment>
            )}
        </select>
    </div>
)

const AccountsConfig = ({ configuration, updateConfigData }) => {
    const { data, error, isLoading, isError } = useQuery({
        queryKey: ["apideck-accounting:list-accounts"],
        queryFn: async () => await (await fetch(DjangoUrls["apideck-accounting:list-accounts"]())).json(),
        staleTime: 60000,
    })

    if (!data) {
        return <Spinner />
    }

    const revenueAccounts = data.filter((account) => account.type === "revenue")
    const costOfGoodsAccounts = data.filter((account) => account.type === "costs_of_sales" && account.sub_type === "SuppliesMaterialsCogs")
    const inventoryAccounts = data.filter(
        (account) => account.type === "current_asset" && account.sub_type === "Inventory"
    )
    const undepositedFundsAccounts = data.filter(
        (account) => account.type === "current_asset" && account.sub_type === "UndepositedFunds"
    )
    const accountsPayableAccounts = data.filter(
        (account) => account.type === "accounts_payable"
    )
    const expenseAccounts = data.filter(
        (account) => account.type === "expense"
    )

    const defaultConfiguration = window.CURRENT_USER.service_company

    return (
        <Fragment>
            <HelpText>
                Select the account you use to categorize service revenue (income). Roopairs will credit this account
                with each service line item's amount.
            </HelpText>
            <AccountSelector
                name="apideck_service_revenue_account"
                defaultValue={defaultConfiguration.apideck_service_revenue_account}
                value={configuration.apideck_service_revenue_account}
                accounts={revenueAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize part revenue (income). Roopairs will credit this account with
                each part line item's amount.
            </HelpText>
            <AccountSelector
                name="apideck_part_revenue_account"
                defaultValue={defaultConfiguration.apideck_part_revenue_account}
                value={configuration.apideck_part_revenue_account}
                accounts={revenueAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize miscellaneous charge revenue (income). Roopairs will credit
                this account with each miscellaneous line item's amount.
            </HelpText>
            <AccountSelector
                name="apideck_other_revenue_account"
                defaultValue={defaultConfiguration.apideck_other_revenue_account}
                value={configuration.apideck_other_revenue_account}
                accounts={revenueAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize discounts (income). Roopairs will debit this account with each
                discount line item's amount.
            </HelpText>
            <AccountSelector
                name="apideck_discount_revenue_account"
                defaultValue={defaultConfiguration.apideck_discount_revenue_account}
                value={configuration.apideck_discount_revenue_account}
                accounts={revenueAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize inventory purchases (cost of goods sold). Your accounting
                software uses this account when creating parts orders for inventory parts. Select the account you use
                to categorize discounts (income). Roopairs will debit this account with each discount line item's
                amount.
            </HelpText>
            <AccountSelector
                name="apideck_cost_of_goods_account"
                defaultValue={defaultConfiguration.apideck_cost_of_goods_account}
                value={configuration.apideck_cost_of_goods_account}
                accounts={costOfGoodsAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize inventory assets (other current assets). Your accounting
                software will credit this acccount as you account for purchased inventory in QuickBooks.
            </HelpText>
            <AccountSelector
                name="apideck_inventory_asset_account"
                defaultValue={defaultConfiguration.apideck_inventory_asset_account}
                value={configuration.apideck_inventory_asset_account}
                accounts={inventoryAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize invoice payments (other current assets). QuickBooks will
                deposit payments synced by Roopairs into this account.
            </HelpText>
            <AccountSelector
                name="apideck_invoice_payment_account"
                defaultValue={defaultConfiguration.apideck_invoice_payment_account}
                value={configuration.apideck_invoice_payment_account}
                accounts={undepositedFundsAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize accounts payable. QuickBooks will debit this account with
                bill totals when Roopairs syncs bills.
            </HelpText>
            <AccountSelector
                name="apideck_accounts_payable_account"
                defaultValue={defaultConfiguration.apideck_accounts_payable_account}
                value={configuration.apideck_accounts_payable_account}
                accounts={accountsPayableAccounts}
                updateConfigData={updateConfigData}
            />

            <HelpText>
                Select the account you use to categorize bill expenses. QuickBooks will debit this account with
                bill expenses when Roopairs syncs bills.
            </HelpText>
            <AccountSelector
                name="apideck_bill_expense_account"
                defaultValue={defaultConfiguration.apideck_bill_expense_account}
                value={configuration.apideck_bill_expense_account}
                accounts={expenseAccounts}
                updateConfigData={updateConfigData}
            />
        </Fragment>
    )
}

const APIDeckConfig = ({ configuration, updateConfigData }) => {
    return (
        <QueryClientProvider client={new QueryClient()}>
            {
                window.CURRENT_USER.permissions.settings_edit_permission >= PERMISSION_LEVEL.FULL && (
                    <ButtonGroupRow>
                        <a href={DjangoUrls["apideck-vault"]()}>Manage accounting integrations via APIDeck</a>
                    </ButtonGroupRow>
                )
            }
            <AccountsConfig configuration={configuration} updateConfigData={updateConfigData} />
        </QueryClientProvider>
    )
}

class SettingsAccountingConfigPanel extends Component {

    constructor(props) {
        super(props)
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            accountingConfig: null,

            errors: {
                accountingConfig: {},
            },
        }
        window.markForImportQuickbooksDesktopImportedCustomers = this.markForImportQuickbooksDesktopImportedCustomers
        window.confirmNewMatchedQuickbooksDesktopImportedCustomers = this.confirmNewMatchedQuickbooksDesktopImportedCustomers
        window.reprocessQuickbooksDesktopImportedCustomers = this.reprocessQuickbooksDesktopImportedCustomers
        window.resyncQuickbooksDesktopImportedCustomers = this.resyncQuickbooksDesktopImportedCustomers
        window.resyncQuickbooksDesktopAccounts = this.resyncQuickbooksDesktopAccounts
        window.resyncQuickbooksDesktopItems = this.resyncQuickbooksDesktopItems
        window.resyncQuickbooksDesktopTemplates = this.resyncQuickbooksDesktopTemplates
        window.resyncQuickbooksDesktopVendors = this.resyncQuickbooksDesktopVendors
        window.resyncQuickbooksDesktopPaymentMethods = this.resyncQuickbooksDesktopPaymentMethods
    }

    componentDidMount = async () => {
        const endpoint = DjangoUrls["settings-retrieve-accounting-config"]()
        const response = await fetch(endpoint)
        const json = await response.json()

        this.setState((state, props) => {
            let updatedState = state
            updatedState.accountingConfig = json

            return updatedState
        })
    }

    updateConfigData = (fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.accountingConfig[fieldName] = fieldValue

            return updatedState
        })
    }

    saveConfig = async () => {
        const endpoint = DjangoUrls["settings-update-accounting-config"]();
        const endpointMethod = "PUT"

        const dataName = "accountingConfig"
        const submittingName = "submittingAccountingConfig"
        const errorDictName = "accountingConfig"

        const onSuccess = (json) => {
            this.setState((state, props) => {
                let updatedState = state
                updatedState[submittingName] = false
                return updatedState
            })
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: "Accounting integration saved"
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Accounting integration could not be saved"
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    markForImportQuickbooksDesktopImportedCustomers = async () => {
        document.querySelectorAll("#message_modal_confirm_mark_for_import_quickbooks_imported_customers .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_mark_for_import_quickbooks_imported_customers .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-mark-for-import-quickbooks-desktop-imported-customers"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const markForImportModal = document.querySelector("#message_modal_confirm_mark_for_import_quickbooks_imported_customers .modal__close")
            if (markForImportModal) {
                markForImportModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    confirmNewMatchedQuickbooksDesktopImportedCustomers = async () => {
        document.querySelectorAll("#message_modal_confirm_confirm_new_matched_quickbooks_imported_customers .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_confirm_new_matched_quickbooks_imported_customers .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-confirm-new-matched-quickbooks-desktop-imported-customers"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const reprocessModal = document.querySelector("#message_modal_confirm_confirm_new_matched_quickbooks_imported_customers .modal__close")
            if (reprocessModal) {
                reprocessModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    reprocessQuickbooksDesktopImportedCustomers = async () => {
        document.querySelectorAll("#message_modal_confirm_reprocess_quickbooks_imported_customers .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_reprocess_quickbooks_imported_customers .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-reprocess-quickbooks-desktop-imported-customers"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const reprocessModal = document.querySelector("#message_modal_confirm_reprocess_quickbooks_imported_customers .modal__close")
            if (reprocessModal) {
                reprocessModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    resyncQuickbooksDesktopImportedCustomers = async () => {
        document.querySelectorAll("#message_modal_confirm_resync_quickbooks_imported_customers .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_resync_quickbooks_imported_customers .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-resync-quickbooks-desktop-imported-customers"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const resyncModal = document.querySelector("#message_modal_confirm_resync_quickbooks_imported_customers .modal__close")
            if (resyncModal) {
                resyncModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    resyncQuickbooksDesktopAccounts = async () => {
        document.querySelectorAll("#message_modal_confirm_resync_quickbooks_accounts .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_resync_quickbooks_accounts .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-resync-quickbooks-desktop-accounts"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const resyncModal = document.querySelector("#message_modal_confirm_resync_quickbooks_accounts .modal__close")
            if (resyncModal) {
                resyncModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    resyncQuickbooksDesktopItems = async () => {
        document.querySelectorAll("#message_modal_confirm_resync_quickbooks_items .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_resync_quickbooks_items .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-resync-quickbooks-desktop-items"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const resyncModal = document.querySelector("#message_modal_confirm_resync_quickbooks_items .modal__close")
            if (resyncModal) {
                resyncModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    resyncQuickbooksDesktopTemplates = async () => {
        document.querySelectorAll("#message_modal_confirm_resync_quickbooks_templates .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_resync_quickbooks_templates .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-resync-quickbooks-desktop-templates"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const resyncModal = document.querySelector("#message_modal_confirm_resync_quickbooks_templates .modal__close")
            if (resyncModal) {
                resyncModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    resyncQuickbooksDesktopVendors = async () => {
        document.querySelectorAll("#message_modal_confirm_resync_quickbooks_vendors .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_resync_quickbooks_vendors .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-resync-quickbooks-desktop-vendors"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const resyncModal = document.querySelector("#message_modal_confirm_resync_quickbooks_vendors .modal__close")
            if (resyncModal) {
                resyncModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    resyncQuickbooksDesktopPaymentMethods = async () => {
        document.querySelectorAll("#message_modal_confirm_resync_quickbooks_payment_methods .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_resync_quickbooks_payment_methods .modal__close .spinner-centered").style.display = "block"

        const endpoint = DjangoUrls["settings-resync-quickbooks-desktop-payment-methods"]();
        const endpointMethod = "POST"

        var headers = new Headers();
        headers.append("X-CSRFToken", Cookies.get("csrftoken"));
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");

        const request = new Request(
            endpoint,
            {method: endpointMethod, headers: headers, credentials: "same-origin"}
        )
        const response = await fetch(request);

        if (response.ok) {
            location.reload()
        }
        else {
            const resyncModal = document.querySelector("#message_modal_confirm_resync_quickbooks_payment_methods .modal__close")
            if (resyncModal) {
                resyncModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }
    }

    renderButtons = () => {
        if (this.state.submittingAccountingConfig) {
            return <Spinner centered={true} />
        }
        else {
            if (this.state.unexpectedError) {
                return (
                    <div className="data-panel__action-feedback">
                        <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                    </div>
                )
            }
            else if (window.CURRENT_USER.permissions.settings_edit_permission < PERMISSION_LEVEL.FULL) {
                return null
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save Changes" handler={this.saveConfig} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    renderForm = (integration) => {
        const formMap = {
            0: <Fragment></Fragment>,
            1: <APIDeckConfig configuration={this.state.accountingConfig} updateConfigData={this.updateConfigData} />,
            2: <QuickBooksDesktopSetupForm configuration={this.state.accountingConfig} updateConfigData={this.updateConfigData}></QuickBooksDesktopSetupForm>,
        }

        return formMap[integration]
    }

    render() {
        if (this.state.accountingConfig === null) {
            return (
                <div id="settings_accounting">
                    <div className="data-panel-container">
                        <Spinner centered={true} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="settings_accounting">
                    <div className="data-panel-container">
                        <div className="data-panel" aria-label="Accounting Integration">
                            <div className="data-panel__heading" aria-label="Panel Information">
                                <span className="data-panel__heading__title" aria-label="Panel Header">Accounting Integration</span>
                                <hr aria-hidden="true" />
                            </div>

                            <div className="data-panel__form" aria-label="Choose Integration">
                                <div className="data-panel__form__field" id="div_id_accounting_integration" aria-label="Choose Intergration">
                                    <label htmlFor="id_accounting_integration" className="data-panel__form__field__label in-form">Select your accounting software</label>
                                    <div className="data-panel__form__field__input">
                                        <select name="accounting_integration" id="id_accounting_integration" defaultValue={this.state.accountingConfig.accounting_integration} onChange={event => this.updateConfigData(event.target.name, event.target.value)} autoComplete="off"
                                            disabled={window.CURRENT_USER.permissions.settings_edit_permission < PERMISSION_LEVEL.FULL}
                                        >
                                            {this.state.accountingConfig.integration_options.map((integration_option, index) => {
                                                return <option key={index} value={integration_option.value}>{integration_option.label}</option>
                                            })}
                                        </select>
                                    </div>
                                    {this.state.errors.accountingConfig.accounting_integration && <div className="data-panel__form__field__errors" aria-label="Field Errors">{this.state.errors.accountingConfig.accounting_integration}</div>}
                                </div>
                                {this.renderForm(this.state.accountingConfig.accounting_integration)}
                            </div>
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default SettingsAccountingConfigPanel;
