import { ColumnDefResolved, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import { useEffect } from "react"

import getObjectTableViewConfigStorageKey from "@utils/getObjectTableViewConfigStorageKey"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"

import { TableContext } from "./Table.context"
import { TableContextValues, TableProviderProps, TableViewConfig } from "./Table.types"
import useTableColumns from "./hooks/useTableColumns"
import useTableData from "./hooks/useTableData"
import useTableSortingState from "./hooks/useTableSortingState"
import useTableState from "./hooks/useTableState"
import useTableViewConfig from "./hooks/useTableViewConfig"

export default function TableProvider<T = ObjectsData>(props: TableProviderProps) {
    const {
        children,
        activePhaseStorageKey,
        activeTabStorageKey,
        orderingStorageKey,
        searchKeywordsStorageKey,
        listTitle,
        listDataFilters,
        objectType,
        objectTypeVariation,
        objectName,
        objectCreateRoute,
        objectIndexName,
        defaultActivePhase,
        defaultTableConfigOverride,
        isRowClickable,
        isEmptyStateCreateButtonVisible,
    } = props

    const {
        activePhase,
        activeTab,
        searchKeywords,
        filters,
        setActivePhase,
        setActiveTab,
        setSearchKeywords,
        setFilters,
    } = useTableState({
        activePhaseStorageKey,
        activeTabStorageKey,
        searchKeywordsStorageKey,
        defaultActivePhase,
        defaultFilters: listDataFilters,
    })

    const tabContentName = `${activeTab?.title} ${listTitle}`

    const currentObjectsViewKey = getObjectTableViewConfigStorageKey({
        objectType: objectType,
        objectTypeVariation: objectTypeVariation,
        phase: activePhase,
        tab: activeTab?.title ?? "",
    })

    const { currentTableViewConfig, updateCurrentTableViewConfig } = useTableViewConfig(
        currentObjectsViewKey,
        defaultTableConfigOverride,
    )

    const {
        sorting,
        onSortingChange,
        setSortingChanged,
        sortingChanged,
        setCurrentSortToAscending,
        setCurrentSortToDescending,
        onSortingItemSelection,
    } = useTableSortingState<T>({
        activeTab,
        orderingStorageKey,
        updateCurrentTableViewConfig,
    })

    const {
        currentData,
        error,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        isEmpty,
    } = useTableData<T>({
        activeTab,
        tabContentName,
        searchKeywords,
        filters,
        sorting,
    })

    const {
        setColumns,
        tableColumns,
        reachedPinLimit,
        updateColumnVisibility,
        unpinColumn,
        pinColumn,
        updateColumnPinningOrder,
        updateColumnOrder,
        invisibleColumns,
        reachedMinimumOfColumnsVisible,
        pinnedColumns,
        unpinnedColumns,
        columnsNamesList,
        visibleColumns,
        activeSortingColumn,
        activeSorting,
        sortingEnabledColumns,
        activeSortingIcons,
        activeSortingLabels,
    } = useTableColumns<T>({
        currentTableViewConfig: currentTableViewConfig as TableViewConfig,
        updateCurrentTableViewConfig,
    })

    const table = useReactTable({
        columns: tableColumns,
        data: currentData,
        getCoreRowModel: getCoreRowModel(),
        initialState: !currentTableViewConfig
            ? undefined
            : {
                  sorting: currentTableViewConfig?.sorting,
                  columnVisibility: currentTableViewConfig?.columnVisibility,
                  columnPinning: currentTableViewConfig?.columnPinning,
                  columnOrder: currentTableViewConfig?.columnOrder,
              },
        onSortingChange,
        getSortedRowModel: getSortedRowModel(),
        manualSorting: true,
        debugHeaders: true,
        debugColumns: true,
    })

    useEffect(() => {
        if (currentTableViewConfig) {
            const newColumnPinning = [...(currentTableViewConfig.columnPinning?.left ?? [])]

            table.setState((state) => {
                return {
                    ...state,
                    ...currentTableViewConfig,
                    columnPinning: {
                        left: newColumnPinning,
                    },
                }
            })
        }
    }, [currentTableViewConfig])

    const contextValue: TableContextValues<T> = {
        data: currentData,
        columns: tableColumns as ColumnDefResolved<T>[],
        setColumns,
        table,
        hasNextPage,
        activePhase,
        setActivePhase,
        activeTab,
        setActiveTab,
        searchKeywords,
        setSearchKeywords,
        filters,
        setFilters,
        tabContentName,
        error,
        isLoading,
        isError,
        fetchNextPage,
        isFetchingNextPage,
        refetch,
        objectName,
        objectCreateRoute,
        isSearching: !!searchKeywords,
        isEmpty,
        reachedPinLimit,
        updateColumnVisibility,
        unpinColumn,
        pinColumn,
        updateColumnPinningOrder,
        updateColumnOrder,
        setSortingChanged,
        sortingChanged,
        invisibleColumns,
        reachedMinimumOfColumnsVisible,
        pinnedColumns,
        unpinnedColumns,
        columnsNamesList,
        columnsOrder: currentTableViewConfig?.columnOrder ?? [],
        objectType,
        currentTableViewConfig,
        objectIndexName,
        visibleColumns: visibleColumns as ColumnDefResolved<T>[],
        onSortingChange,
        activeSorting,
        setCurrentSortToAscending,
        setCurrentSortToDescending,
        onSortingItemSelection,
        sortingEnabledColumns,
        activeSortingIcons,
        activeSortingLabels,
        activeSortingColumn,
        defaultTableConfigOverride,
        isRowClickable,
        isEmptyStateCreateButtonVisible,
    }

    return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>
}
