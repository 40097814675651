import dayjs from "dayjs"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { AccessCheck } from "@particles"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB,
    PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH,
    PURCHASE_ORDERS_LIST_CONTROLS_ORDERING,
    PURCHASE_ORDERS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { PurchaseOrderTableViewTabs } from "./PurchaseOrderTableViewTabs"

export default function PurchaseOrderTableView() {
    const [createRoute] = useSlugExtractor([PURCHASE_ORDER_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_ORDERING}
            listTitle="Purchase Orders"
            objectType="purchase_order"
            objectCreateRoute={createRoute}
            objectIndexName="PurchaseOrder"
            objectName="Order"
            defaultActivePhase="open"
            isRowClickable={hasPermission("purchase_orders_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission(
                "purchase_orders_create_permission",
                PERMISSION_LEVEL.RESTRICTED,
            )}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <PurchaseOrderTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<PurchaseOrder>
                    accessorKey="id"
                    header="ID"
                    size={144}
                    cell={({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="other"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="vendor_name"
                    header="Vendor"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="state_label"
                    header="State"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="status_label"
                    header="Status"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.StatusCell status={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as PurchaseOrderLabel[])?.map(
                                (label: PurchaseOrderLabel) => label.name,
                            )}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="item_count"
                    header="Items"
                    size={96}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="subtotal"
                    header="Amount"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="shipping"
                    header="Shipping"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="preferred_shipping"
                    header="Shipping Preference"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="tracking_number"
                    header="Tracking Number"
                    size={176}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <AccessCheck
                    entitlements={["entitlement_jobs_enabled"]}
                    permissions={{
                        jobs_list_permission: PERMISSION_LEVEL.RESTRICTED,
                    }}
                >
                    <Table.Column<PurchaseOrder>
                        accessorKey="associated_jobs"
                        header="Associated Jobs"
                        size={176}
                        cell={({ getValue }) => <Table.Cells.BadgesCell badges={getValue()} />}
                        enableSorting={false}
                        enablePinning={false}
                        dataType="other"
                    />
                </AccessCheck>
                <Table.Column<PurchaseOrder>
                    accessorKey="last_status_transition_time"
                    header="Status Changed"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="date_issued"
                    header="Issue Date"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="estimated_arrival_time"
                    header="Est. Arrival"
                    size={144}
                    cell={({ getValue, row }) => {
                        const value = getValue() as string
                        return (
                            <Table.Cells.DateTimeCell
                                value={value}
                                isDangerous={
                                    row.original.state_label === "In Transit" && dayjs(value).isBefore(dayjs())
                                }
                            />
                        )
                    }}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="payment_terms"
                    header="Payment Terms"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.PaymentTermsCell terms={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_sent_at"
                    header="Sent"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_acknowledged_at"
                    header="Acknowledged"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_in_transit_at"
                    header="In Transit"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_delivered_at"
                    header="Delivered"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_stocked_at"
                    header="Stocked"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_paid_at"
                    header="Paid"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_cancelled_at"
                    header="Cancelled"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<PurchaseOrder>
                    accessorKey="last_viewed"
                    header="Seen"
                    size={96}
                    cell={({ getValue }) => <Table.Cells.SeenCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
