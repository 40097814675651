import { TableViewConfigType } from "@constants/defaultTableViewConfig"

import { TableViewConfig } from "./Table.types"

export function migrateConfig(storedConfig: TableViewConfigType, defaultTableConfig: TableViewConfigType) {
    const storedVersion = storedConfig.version ?? "1.0.0"
    const newVersion = defaultTableConfig.version ?? "1.0.0"

    const [storedMajor, storedMinor] = storedVersion.split(".")
    const [defaultMajor, defaultMinor] = newVersion.split(".")

    // If major version differs, reset the stored config (breaking changes)
    if (storedMajor !== defaultMajor) {
        return defaultTableConfig
    }

    const migratedConfig = structuredClone(storedConfig)
    const storedTable = storedConfig.table

    // If minor version differs, apply non-breaking feature additions (adding/removing new columns)
    if (storedMinor !== defaultMinor) {
        Object.keys(storedTable).forEach((key) => {
            const updatedConfig = { ...storedTable[key] }
            const storedConfig = storedTable[key]
            const defaultObjectConfig = defaultTableConfig.table[key]

            // Add columns from defaultConfig that are not in storedConfig
            defaultObjectConfig?.columnOrder?.forEach((columnId) => {
                if (!storedConfig?.columnOrder?.includes(columnId)) {
                    updatedConfig?.columnOrder?.push(columnId)
                }
            })

            // Pin columns from defaultConfig that are not in storedConfig
            defaultObjectConfig?.columnPinning?.left?.forEach((columnId) => {
                const isColumnToPin = !storedConfig?.columnPinning?.left?.includes(columnId)
                const theresSpaceToPinColumn =
                    storedConfig?.columnPinning?.left !== undefined && storedConfig?.columnPinning?.left.length < 3
                if (isColumnToPin && theresSpaceToPinColumn) {
                    updatedConfig?.columnPinning?.left?.push(columnId)
                }
            })

            // Add column visibility config from defaultConfig that are not in storedConfig
            Object.keys(defaultObjectConfig?.columnVisibility ?? {}).forEach((columnId) => {
                if (!(storedConfig?.columnVisibility && columnId in storedConfig.columnVisibility)) {
                    if (!updatedConfig.columnVisibility) {
                        updatedConfig.columnVisibility = {}
                    }
                    updatedConfig.columnVisibility[columnId] = !!defaultObjectConfig?.columnVisibility?.[columnId]
                }
            })

            // Remove columns not in defaultConfig
            updatedConfig.columnOrder = storedConfig?.columnOrder?.filter((columnId) => {
                if (!defaultObjectConfig?.columnOrder?.includes(columnId)) {
                    delete updatedConfig?.columnVisibility?.[columnId]
                    if (!updatedConfig.columnPinning) {
                        updatedConfig.columnPinning = { left: [] }
                    }
                    updatedConfig.columnPinning.left =
                        updatedConfig?.columnPinning?.left?.filter((pinnedColumnId) => pinnedColumnId !== columnId) ??
                        []

                    return false
                }

                return true
            })

            updatedConfig.sorting = storedConfig?.sorting ?? defaultObjectConfig?.sorting

            migratedConfig.table[key] = updatedConfig as TableViewConfig
        })
    }

    return {
        ...migratedConfig,
        version: newVersion,
    }
}
