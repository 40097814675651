import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import ClientMergeContainer from "@legacy/clients/ClientMergeContainer"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { CLIENTS_ROUTES } from "@routes/clients"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

export default function ClientMerge() {
    const isDesktop = useIsDesktop()

    const clientId = window.CLIENT_ID

    const [listRoute, detailsRoute] = useSlugExtractor([CLIENTS_ROUTES.LIST, CLIENTS_ROUTES.DETAILS], clientId)
    const { addToastToQueue } = useToastQueue()
    const { hasPermission } = useHasAccess()

    const listBackDestination = hasPermission("clients_list_permission", PERMISSION_LEVEL.FULL) ? listRoute : undefined
    const detailsBackDestination = hasPermission("clients_view_permission", PERMISSION_LEVEL.FULL)
        ? detailsRoute
        : undefined

    return (
        <AppFrame.Root pageId="ClientMerge">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Clients"
                            icon={icon({ name: "user-circle", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "user-circle", style: "solid", family: "sharp" })}
                            destination={listBackDestination}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Merge Client" backDestination={detailsBackDestination} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <ClientMergeContainer formMode="MERGE_CLIENT" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
