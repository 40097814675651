import { useState } from "react"

import { Button, Checkbox, TabItem } from "@atoms"

import { ResponsiveDialog, TabStack } from "@molecules"
import generateRandomKey from "@utils/generateRandomKey"

export default function PDFDownloadDialog(props) {
    const { isOpen, onOpenChange, objects, onDownload, showToggle } = props

    const [selectedObjects, setSelectedObjects] = useState([objects[0]])
    const [isPublicVersion, setIsPublicVersion] = useState(false)
    const [error, setError] = useState(false)

    const handleToggle = (obj) => {
        setSelectedObjects((prevSelected) =>
            prevSelected.includes(obj) ? prevSelected.filter((item) => item !== obj) : [...prevSelected, obj],
        )
    }

    const handleDownload = () => {
        if (selectedObjects.length === 0) {
            setError(true)
        } else {
            onDownload(selectedObjects, isPublicVersion)
        }
    }

    return (
        <ResponsiveDialog.Root headerTitle="Customize PDF Download" isOpen={isOpen} onOpenChange={onOpenChange}>
            <ResponsiveDialog.Body>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        padding: "var(--size-4)",
                        gap: "var(--size-2)",
                    }}
                >
                    <p>Select which objects you'd like to download:</p>
                    {objects
                        .filter((obj) => obj !== null)
                        .map((obj) => (
                            <Checkbox
                                key={generateRandomKey()}
                                checked={selectedObjects.includes(obj)}
                                onCheckedChange={() => handleToggle(obj)}
                                label={{
                                    children: obj,
                                }}
                                colorScheme="gray"
                                size="md"
                                isControlled={false}
                            />
                        ))}
                    </div>
                    {showToggle && (
                        <div style={{
                            width: "var(--size-full)",
                            padding: "var(--size-2) var(--size-4)",
                        }}>
                            <p>Select which version you'd like to download:</p>
                            <TabStack variant="attached" size="sm">
                                <TabItem
                                    isPressed={!isPublicVersion}
                                    onClick={() => setIsPublicVersion(false)}
                                    noDebounce={true}
                                >
                                    Internal
                                </TabItem>
                                <TabItem
                                    isPressed={isPublicVersion}
                                    onClick={() => setIsPublicVersion(true)}
                                    noDebounce={true}
                                >
                                    Public
                                </TabItem>
                            </TabStack>
                        </div>
                    )}
                    {error && (
                        <span style={{
                            width: "var(--size-full)",
                            padding: "var(--size-2) var(--size-4)",
                        }} className="text-invalid">
                            <strong>Please select at least one object to download.</strong>
                        </span>
                    )}

            </ResponsiveDialog.Body>

            <ResponsiveDialog.Footer>
                <Button
                    colorScheme="gray"
                    variant="solid"
                    size="md"
                    onClick={handleDownload}
                    isFullWidth={true}
                >
                    Download PDF
                </Button>
            </ResponsiveDialog.Footer>
        </ResponsiveDialog.Root>
    )
}
