import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { AccessCheck } from "@particles/index"

import { NavigationItem, NavigationSection } from "@molecules/index"

import { SETTINGS_ROUTES } from "@routes/settings"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

function SettingsNavigation() {
    return (
        <>
            <NavigationSection title="My account">
                <NavigationItem
                    label="Profile"
                    icon={icon({ name: "user", style: "light", family: "sharp" })}
                    iconActive={icon({ name: "user", style: "regular", family: "sharp" })}
                    showUserAvatar={true}
                    path={SETTINGS_ROUTES.profile}
                    onActive={() => null}
                    variant="primary"
                />
                <NavigationItem
                    label="Preferences"
                    icon={icon({ name: "gear", style: "light", family: "sharp" })}
                    iconActive={icon({ name: "gear", style: "regular", family: "sharp" })}
                    showUserAvatar={false}
                    path={SETTINGS_ROUTES.preferences}
                    onActive={() => null}
                    variant="primary"
                />
                <NavigationItem
                    label="Security & access"
                    icon={icon({ name: "key", style: "light", family: "sharp" })}
                    iconActive={icon({ name: "key", style: "regular", family: "sharp" })}
                    showUserAvatar={false}
                    path={SETTINGS_ROUTES.securityAndAccess}
                    onActive={() => null}
                    variant="primary"
                />
                <NavigationItem
                    label="Notifications"
                    icon={icon({ name: "bell", style: "light", family: "sharp" })}
                    iconActive={icon({ name: "bell", style: "regular", family: "sharp" })}
                    showUserAvatar={false}
                    path={SETTINGS_ROUTES.notifications}
                    onActive={() => null}
                    variant="primary"
                />
            </NavigationSection>
            <AccessCheck
                permissions={{
                    settings_view_permission: PERMISSION_LEVEL.RESTRICTED,
                }}
            >
                <NavigationSection title="Workspace">
                    <AccessCheck
                        entitlements={["entitlement_invoices_enabled", "entitlement_estimates_enabled"]}
                        permissions={{
                            settings_view_permission: PERMISSION_LEVEL.FULL,
                        }}
                    >
                        <AccessCheck
                            permissions={{
                                settings_edit_permission: PERMISSION_LEVEL.FULL,
                            }}
                        >
                            <NavigationItem
                                label="Payments"
                                icon={icon({ name: "dollar-circle", style: "light", family: "sharp" })}
                                iconActive={icon({ name: "dollar-circle", style: "regular", family: "sharp" })}
                                showUserAvatar={false}
                                path={SETTINGS_ROUTES.payments}
                                onActive={() => null}
                                variant="primary"
                            />
                        </AccessCheck>
                    </AccessCheck>

                    <AccessCheck
                        permissions={{
                            settings_view_permission: PERMISSION_LEVEL.FULL,
                        }}
                    >
                        <AccessCheck
                            permissions={{
                                settings_edit_permission: PERMISSION_LEVEL.FULL,
                            }}
                        >
                            <NavigationItem
                                label="CompanyCam"
                                icon={icon({ name: "camera-retro", style: "light", family: "sharp" })}
                                iconActive={icon({ name: "camera-retro", style: "regular", family: "sharp" })}
                                showUserAvatar={false}
                                path={SETTINGS_ROUTES.companyCam}
                                onActive={() => null}
                                variant="primary"
                            />
                        </AccessCheck>
                    </AccessCheck>
                    <AccessCheck
                        permissions={{
                            settings_view_permission: PERMISSION_LEVEL.FULL,
                        }}
                    >
                        <NavigationItem
                            label="Accounting"
                            icon={icon({ name: "receipt", style: "light", family: "sharp" })}
                            iconActive={icon({ name: "receipt", style: "regular", family: "sharp" })}
                            showUserAvatar={false}
                            path={SETTINGS_ROUTES.accounting}
                            onActive={() => null}
                            variant="primary"
                        />
                    </AccessCheck>
                    <AccessCheck
                        permissions={{
                            settings_view_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}
                    >
                        <AccessCheck
                            entitlements={["entitlement_jobs_enabled"]}
                            permissions={{
                                jobs_list_permission: PERMISSION_LEVEL.FULL,
                            }}
                        >
                            <NavigationItem
                                label="Availability schedules"
                                icon={icon({ name: "calendar", style: "light", family: "sharp" })}
                                iconActive={icon({ name: "calendar", style: "regular", family: "sharp" })}
                                showUserAvatar={false}
                                path={SETTINGS_ROUTES.availabilitySchedules}
                                onActive={() => null}
                                variant="primary"
                            />
                        </AccessCheck>
                    </AccessCheck>
                </NavigationSection>
            </AccessCheck>
        </>
    )
}

export default SettingsNavigation
