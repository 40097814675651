import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import JobCreateContainer from "@legacy/jobs/JobCreateContainer"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { useJobsBackDestination } from "@pages/Jobs/Jobs.store"

import { JOBS_ROUTES } from "@routes/jobs"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

export default function JobCreate() {
    const isDesktop = useIsDesktop()

    const [listRoute] = useSlugExtractor([JOBS_ROUTES.LIST])
    const { hasPermission } = useHasAccess()

    const cleanedJobsListRoute = useJobsBackDestination(listRoute)

    const backDestination = hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL)
        ? cleanedJobsListRoute
        : undefined

    const { addToastToQueue } = useToastQueue()

    return (
        <AppFrame.Root pageId="JobCreate">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Jobs"
                            icon={icon({ name: "wrench-simple", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "wrench-simple", style: "solid", family: "sharp" })}
                            destination={backDestination}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="New Job" backDestination={backDestination} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <JobCreateContainer
                    formMode="ADD_JOB"
                    addToastToQueue={addToastToQueue}
                    backDestination={backDestination}
                />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
