import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import PDFDownloadDialog from "@legacy/pricebook/components/PDFDownloadDialog";
import PurchaseOrderObjectLink from "@legacy/purchase_orders/components/PurchaseOrderObjectLink";
import { AccessCheck } from "@particles/index";
import dayjs from "dayjs";
import { Component, Fragment } from "react";
import { renderClientString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import Banner from "../../core/components/Banner";
import LineItemListDisplayGroup from "../../core/components/LineItemListDisplayGroup";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import { convertToKebabCase, currencyFormatterForServiceCompany, formatCurrencyForServiceCompany, formatLocalTime, renderInvoiceEstimateDate, valueIsDefined } from "../../core/utils/utils";
import { getBillStatusBarLabel } from "../utils/utils";
import BillFeedAccordion from "./BillFeedAccordion";


class BillDetailsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPDFDialogOpen: false,
        };
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    handlePDFDownload = (selectedObjects, isPublicVersion) => {
        let url = window.PUBLIC_PDF_URL;
        const includeBill = selectedObjects?.includes("Bill") ? "true" : "false";
        const includePurchaseOrder = selectedObjects?.includes("Purchase Order") ? "true" : "false";

        if (selectedObjects?.length === 0 || selectedObjects === undefined && !isPublicVersion) {
            location.assign(url);
        } else {
            url = `${window.PUBLIC_PDF_URL}?include_purchase_order=${includePurchaseOrder}&include_bill=${includeBill}&public_version=${isPublicVersion ? "true" : "false"}`;
            location.assign(url);
        }

        this.setState({ isPDFDialogOpen: false });
    };


    renderButtons = () => {
        const {bill, requestAction, switchToSecondaryForm, submitting, errors} = this.props

        const handlePDFDialog = () => {
            (bill.purchase_order)
            ?
            this.setState({ isPDFDialogOpen: !this.state.isPDFDialogOpen })
            :
            this.handlePDFDownload()
        };

        if (submitting) {
            return (
                <div className="data-panel__action-feedback">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            if (window.USING_PUBLIC_URL === true) {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
            else {
                if (bill.status_label === "Draft") {
                    return (
                        <ButtonGroup>
                            <AccessCheck permissions={{
                                bills_create_permission: PERMISSION_LEVEL.RESTRICTED
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Create" handler={event => requestAction("BILL_CREATE")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                                <AccessCheck permissions={{
                                    bills_create_permission: PERMISSION_LEVEL.RESTRICTED
                                }}>
                                    <UniversalButton type="secondary" text="Edit Draft" handler={event => requestAction("BILL_EDIT")} />
                                </AccessCheck>
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (bill.status_label === "Unsent") {
                    return (
                        <ButtonGroup>
                            <AccessCheck permissions={{
                                bills_edit_permission: PERMISSION_LEVEL.FULL
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Revise Bill" handler={event => requestAction("BILL_EDIT")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <AccessCheck permissions={{
                                bills_delete_permission: PERMISSION_LEVEL.RESTRICTED
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Void Bill" handler={event => requestAction("BILL_VOID")} />
                                </ButtonGroupRow>
                            /</AccessCheck>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (bill.status_label === "Outstanding" || bill.status_label === "Overdue") {
                    return (
                        <ButtonGroup>
                            <AccessCheck permissions={{
                                bills_edit_permission: PERMISSION_LEVEL.FULL
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Mark as Paid" handler={event => requestAction("BILL_PAY")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <AccessCheck permissions={{
                                bills_edit_permission: PERMISSION_LEVEL.FULL
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Revise Bill" handler={event => requestAction("BILL_EDIT")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <AccessCheck permissions={{
                                bills_delete_permission: PERMISSION_LEVEL.RESTRICTED
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Void Bill" handler={event => requestAction("BILL_VOID")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (bill.status_label === "Voided" || bill.status_label === "Paid") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    renderLastViewed = (lastViewed, preferredTimezone) => {
        const { localDateTimeString } = formatLocalTime(lastViewed, preferredTimezone, false, true, false, true)
        return localDateTimeString
    }

    render() {
        const {bill, requestAction, switchToSecondaryForm, submitting, errors} = this.props

        let billNetValue = null
        if (valueIsDefined(bill.date_due) && valueIsDefined(bill.date_issued)) {
            billNetValue = dayjs(bill.date_due).diff(dayjs(bill.date_issued), "day")
        }

        return (
            <Fragment>
                <div id="bill_details_react_wrapper">
                    <div className="data-panel-container">
                        <div className={`data-panel-status-bar bills-${convertToKebabCase(bill.status_label)}`} aria-label="Status"><span>{getBillStatusBarLabel(bill)}{window.USING_PUBLIC_URL === true && " Bill"}</span></div>
                        <div className="data-panel with-status-bar" aria-label="Bill Details">
                            {
                                bill.service_company.logo && (
                                    <div className="data-panel__logo" aria-hidden="true">
                                        <div className="data-panel__logo__frame">
                                            <img src={bill.service_company.logo} alt="Logo" />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="data-panel__form" aria-label="Bill Details">
                                <div className="data-panel__form__field" aria-label="Bill Sender">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Sender</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Sender">
                                        <div className="data-panel__form__field__display-list__object bill-contact-card" aria-label="Sender">
                                            <p>
                                                <strong>{renderClientString(bill.vendor)}</strong><br />
                                                <span>{bill.billing_address_formatted_multiline}</span><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-panel__form__field" aria-label="Bill Recipient">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Recipient (Bill To)</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Recipient (Ship To)">
                                        <div className="data-panel__form__field__display-list__object bill-contact-card" aria-label="Received (Bill From)">
                                            <p>
                                                <strong>{bill.service_company.name}</strong><br />
                                                <span>{bill.shipping_address_formatted_multiline}</span><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    bill.purchase_order !== null && (
                                        <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                                            <LinkedObjectsDisplayField
                                                fieldName="purchase_order"
                                                fieldLabel="Created From Purchase Order"
                                                objectComponentList={[
                                                    <PurchaseOrderObjectLink
                                                        key="parent_purchase_order_0"
                                                        purchaseOrder={bill.purchase_order}
                                                        destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_PURCHASE_ORDER_URL : DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, bill.purchase_order.id)}
                                                        disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.purchase_orders_view_permission < PERMISSION_LEVEL.FULL}
                                                    />
                                                ]}
                                            ></LinkedObjectsDisplayField>
                                        </AccessCheck>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="id"
                                    fieldLabel="Roopairs Bill ID"
                                    fieldValue={bill.custom_id ? bill.custom_id : bill.id}
                                ></BasicDisplayField>
                                {   bill.purchaseOrder === null && bill.external_purchase_order_id &&
                                    <BasicDisplayField
                                        fieldName="external_purchase_order_id"
                                        fieldLabel="External Purchase Order ID"
                                        fieldValue={bill.external_purchase_order_id}
                                    ></BasicDisplayField>
                                }
                                {
                                    !window.USING_PUBLIC_URL && bill.labels.length !==0 && (
                                        <BasicDisplayField
                                            fieldName="labels"
                                            fieldLabel={<Fragment>Labels <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                            fieldValue={bill.labels.map((label, index) => <span key={`label_${index}`} className="data-tag">{label.name}</span>)}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="date_received"
                                    fieldLabel="Date Received"
                                    fieldValue={renderInvoiceEstimateDate(bill.date_received, bill.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="date_issued"
                                    fieldLabel="Date Issued"
                                    fieldValue={renderInvoiceEstimateDate(bill.date_issued, bill.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="date_due"
                                    fieldLabel="Date Due"
                                    fieldValue={renderInvoiceEstimateDate(bill.date_due, bill.service_company.preferred_timezone, bill.status_label === "Overdue")}
                                ></BasicDisplayField>
                                {
                                    bill.status_label === "Paid" && bill.date_paid !== null && (
                                        <BasicDisplayField
                                            fieldName="date_paid"
                                            fieldLabel="Date Paid"
                                            fieldValue={renderInvoiceEstimateDate(bill.date_paid, bill.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    !window.USING_PUBLIC_URL && bill.last_viewed !== null && (
                                        <BasicDisplayField
                                            fieldName="last_viewed"
                                            fieldLabel="Last Seen"
                                            fieldValue={this.renderLastViewed(bill.last_viewed, bill.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    bill.last_status_transition_time !== null && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_time"
                                            fieldLabel={`${["Outstanding", "Overdue"].includes(bill.state_label) ? "Shipped" : bill.state_label} On`}
                                            fieldValue={this.renderLastViewed(bill.last_status_transition_time, bill.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    bill.last_status_transition_message !== "" && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_message"
                                            fieldLabel={`${["Outstanding", "Overdue"].includes(bill.state_label) ? "Shipped" : bill.state_label} Message`}
                                            fieldValue={bill.last_status_transition_message}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="last_modified_at"
                                    fieldLabel="Last Modified"
                                    fieldValue={this.renderLastViewed(bill.last_modified_at, bill.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <LineItemListDisplayGroup
                                    object={bill}
                                    formatCurrencyValue={currencyFormatterForServiceCompany(bill.service_company)}
                                    useTaxes={bill.service_company.use_taxes}
                                    isBillLineItem={true}
                                ></LineItemListDisplayGroup>
                                {
                                    bill.attachments.length !== 0 && bill.service_company.feature_file_uploads_enabled && (
                                        <BasicDisplayField
                                            fieldName="attachments"
                                            fieldLabel="Attachments"
                                            fieldValue={<AttachmentHorizontalDisplay
                                                attachments={bill.attachments}
                                                    />}
                                            >
                                        </BasicDisplayField>)
                                }
                                {
                                    bill.accounting_sync_status !== null && (
                                        <BasicDisplayField
                                            fieldName="accounting_sync_status"
                                            fieldLabel="Accounting Sync Status"
                                            fieldValue={bill.accounting_sync_status.needs_syncing? "Not Synced" : `Synced on ${this.renderLastViewed(bill.accounting_sync_status.last_synced_at, bill.service_company.preferred_timezone)}`}
                                        ></BasicDisplayField>
                                    )
                                }

                            </div>
                            <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--total" aria-label="Total">
                                    <div className="data-panel__data-summary__data__label"><span>Total</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(bill.subtotal, bill.service_company)}</div>
                                </div>
                            </div>
                            {
                                Object.keys(errors).length !== 0 && (
                                    <Banner type="danger" text="This bill has errors and cannot be created. Please edit the draft to fix them." extraMargin={true} />
                                )
                            }
                            {this.renderButtons()}
                            <PDFDownloadDialog
                                isOpen={this.state.isPDFDialogOpen}
                                onOpenChange={(newValue) => this.setState({ isPDFDialogOpen: newValue })}
                                objects={[
                                    'Bill',
                                    bill.purchase_order && 'Purchase Order',
                                ]}
                                onDownload={this.handlePDFDownload}
                                showToggle={bill.service_company.slug === window.CURRENT_USER?.service_company?.slug}
                            />
                        </div>
                    </div>
                </div>
                {!(window.USING_PUBLIC_URL === true) &&
                    <div className="accordion-wrapper">
                        <BillFeedAccordion billID={bill.id} />
                    </div>
                }
                {window.USING_PUBLIC_URL === true &&
                    <div className="powered-by-panel">
                        <span>Powered By</span>
                        <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                    </div>
                }
            </Fragment>
        )
    }

}

export default BillDetailsCard;
