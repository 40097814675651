import { Children, cloneElement, isValidElement, useEffect, useState } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import { Item } from "@molecules/Dropdown/Dropdown.types"
import { Dropdown } from "@molecules/index"

import { BottomSheet } from "@organisms/index"

import { ResponsiveSelectorProps } from "./ResponsiveSelector.types"

export default function ResponsiveSelector(props: ResponsiveSelectorProps) {
    const {
        items,
        trigger,
        headerTitle = "Select an option",
        onSelect = () => null,
        isOpen: controlledIsOpen,
        onOpenChange = () => null,
        triggerClassName,
        menuContentClassName,
        side = "bottom",
        startingPoint = "right",
    } = props

    const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)
    const isDesktop = useIsDesktop()

    useEffect(() => {
        if (typeof controlledIsOpen === "boolean") {
            setIsSelectorOpen(controlledIsOpen)
        }
    }, [controlledIsOpen])

    useEffect(() => {
        if (isSelectorOpen !== undefined) {
            onOpenChange?.(isSelectorOpen)
        }
    }, [isSelectorOpen])

    const closeSelector = () => setIsSelectorOpen(false)
    const openSelector = () => setIsSelectorOpen(true)

    const handleSelect = (item: Item) => {
        onSelect(item)
        closeSelector()
    }

    const childrenWithProps = Children.map(trigger, (child) => {
        if (isValidElement<React.HTMLAttributes<HTMLButtonElement>>(child)) {
            return cloneElement(child, { onClick: openSelector })
        }
        return child
    })

    return (
        <>
            {childrenWithProps && childrenWithProps}
            {!isDesktop ? (
                <BottomSheet.Root isOpen={isSelectorOpen} onClose={closeSelector}>
                    <BottomSheet.Content
                        header={{
                            title: headerTitle,
                            showDivider: true,
                            showDragIndicator: true,
                        }}
                        isVisible={isSelectorOpen}
                        priority="exclusive"
                    >
                        {() => (
                            <>
                                {items.map((item) => (
                                    <BottomSheet.Item
                                        key={item.label}
                                        label={item.label}
                                        icon={item.icon}
                                        onClick={() => handleSelect(item)}
                                        isDisabled={item.isDisabled}
                                        isSelected={item.isActive}
                                    />
                                ))}
                            </>
                        )}
                    </BottomSheet.Content>
                </BottomSheet.Root>
            ) : (
                <Dropdown
                    items={items}
                    isOpen={isSelectorOpen}
                    onClose={closeSelector}
                    onSelect={handleSelect}
                    side={side}
                    startingPoint={startingPoint}
                    triggerClassName={triggerClassName}
                    menuContentClassName={menuContentClassName}
                />
            )}
        </>
    )
}
