import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { INVOICES_ROUTES } from "@routes/invoices"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    INVOICES_LIST_CONTROLS_ACTIVE_TAB,
    INVOICES_LIST_CONTROLS_KEYWORD_SEARCH,
    INVOICES_LIST_CONTROLS_ORDERING,
    INVOICES_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { InvoiceTableViewTabs } from "./InvoiceTableViewTabs"

export default function InvoiceTableView() {
    const [createRoute] = useSlugExtractor([INVOICES_ROUTES.CREATE])

    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={INVOICES_LIST_CONTROLS_PHASE}
            activeTabStorageKey={INVOICES_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={INVOICES_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={INVOICES_LIST_CONTROLS_ORDERING}
            listTitle="Invoices"
            objectType="invoice"
            objectIndexName="Invoice"
            objectName="Invoice"
            defaultActivePhase="open"
            objectCreateRoute={createRoute}
            isRowClickable={hasPermission("invoices_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("invoices_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <InvoiceTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<Invoice>
                    accessorKey="id"
                    header="ID"
                    size={144}
                    cell={({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Invoice>
                    accessorKey="client_name"
                    header="Client"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Invoice>
                    accessorKey="state_label"
                    header="State"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Invoice>
                    accessorKey="status_label"
                    header="Status"
                    size={176}
                    cell={({ getValue, row }) => (
                        <Table.Cells.StatusCell status={getValue()} dateDue={row.original.date_due} />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Invoice>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as InvoiceLabel[])?.map((label: InvoiceLabel) => label.name)}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Invoice>
                    accessorKey="amount_due"
                    header="Amt. Due"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<Invoice>
                    accessorKey="total"
                    header="Total"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<Invoice>
                    accessorKey="service_name"
                    header="Service"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Invoice>
                    accessorKey="service_location_name"
                    header="Location Name"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Invoice>
                    accessorKey="service_location_address"
                    header="Location Address"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Invoice>
                    accessorKey="date_due"
                    header="Due Date"
                    size={144}
                    cell={({ getValue, row }) => (
                        <Table.Cells.DateTimeCell
                            value={getValue()}
                            isDangerous={row.original.state_label === "Overdue"}
                        />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Invoice>
                    accessorKey="last_status_transition_time"
                    header="Status Changed"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Invoice>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Invoice>
                    accessorKey="last_viewed"
                    header="Seen"
                    size={96}
                    cell={({ getValue }) => <Table.Cells.SeenCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Invoice>
                    accessorKey="last_sent_at"
                    header="Sent"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Invoice>
                    accessorKey="last_paid_at"
                    header="Paid"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Invoice>
                    accessorKey="last_voided_at"
                    header="Voided"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Invoice>
                    accessorKey="last_forgiven_at"
                    header="Forgiven"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
