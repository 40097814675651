import { SelectListItem } from "@molecules/Select/SelectListItem/SelectListItem.types"
import { Select } from "@molecules/index"

import BottomSheet from "@organisms/BottomSheet/BottomSheet"

import {
    JobTimelineScheduler,
    TimelineViewTimeFrames,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"
import timelineViewMenuStyles from "@pages/Jobs/JobList/views/JobTimelineView/TimelineViewMenu/TimelineViewMenuBottomSheet/TimelineViewMenuBottomSheet.module.scss"
import useJobTimelineViewBryntumInstances from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewData"
import useJobTimelineViewStates from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewTimeFrameSelector.module.scss"
import { TimelineViewTimeFrameBottomSheetProps } from "./TimelineViewTimeFrameSelector.types"

export const timeFrameOptions: SelectListItem<TimelineViewTimeFrames>[] = [
    {
        value: "day",
        label: "Day",
    },
    {
        value: "threeDays",
        label: "3 days",
    },
    {
        value: "week",
        label: "Week",
    },
    {
        value: "month",
        label: "Month",
    },
]

const timeFrameTypeLabelMap: { [key in TimelineViewTimeFrames]: string } = {
    day: "Day",
    threeDays: "3 days",
    week: "Week",
    month: "Month",
}

const useOnSelect = () => {
    const { setTimeFrameType } = useJobTimelineViewStates()
    const { schedulerPro } = useJobTimelineViewBryntumInstances()
    const { addResourceTimeRangesToScheduler, narrowDateRangeToCenterDate } = useJobTimelineViewData()

    const onSelect = (item: SelectListItem<TimelineViewTimeFrames>) => {
        const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler
        if (schedulerInstance) {
            schedulerInstance.infiniteScroll = false
            schedulerInstance.resourceColumns?.refresh()
        }

        if (item.value === "day") {
            addResourceTimeRangesToScheduler()
        }

        narrowDateRangeToCenterDate()
        setTimeFrameType(item.value)
    }

    return {
        onSelect,
    }
}

function TimelineViewTimeFrameBottomSheet(props: TimelineViewTimeFrameBottomSheetProps) {
    const { timeFrameType } = useJobTimelineViewStates()

    const { onSelect } = useOnSelect()

    return (
        <BottomSheet.Content {...props}>
            {(isTabbable) => (
                <>
                    <div className={timelineViewMenuStyles.content}>
                        {timeFrameOptions.map((item) => (
                            <BottomSheet.Item
                                key={item.value}
                                isTabbable={isTabbable}
                                label={item.label}
                                isSelected={timeFrameType === item.value}
                                onClick={() => onSelect(item)}
                            />
                        ))}
                    </div>
                </>
            )}
        </BottomSheet.Content>
    )
}

function TimelineViewTimeFrameSelect() {
    const { timeFrameType } = useJobTimelineViewStates()

    const { onSelect } = useOnSelect()

    return (
        <div className={styles.selectWidth}>
            <Select.Root>
                <Select.Input
                    text={timeFrameTypeLabelMap[timeFrameType]}
                    placeholder="Placeholder"
                    size="sm"
                    className={styles.selectWidth}
                />
                <Select.List className={styles.selectWidth}>
                    <Select.ListItemStack>
                        {timeFrameOptions.map((item) => (
                            <Select.ListItem
                                key={item.value}
                                item={item}
                                onSelect={() => onSelect(item)}
                                isSelected={timeFrameType === item.value}
                            />
                        ))}
                    </Select.ListItemStack>
                </Select.List>
            </Select.Root>
        </div>
    )
}

export default {
    Select: TimelineViewTimeFrameSelect,
    BottomSheet: TimelineViewTimeFrameBottomSheet,
}
