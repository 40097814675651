import { icon as fontAwesomeIcon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { PopoverClose } from "@radix-ui/react-popover"
import clsx from "clsx"
import { Command } from "cmdk"
import { useMemo, useRef } from "react"

import { ifSpaceOrEnter } from "@utils/keyboard"

import { Icon } from "@atoms"

import styles from "./SelectListItem.module.scss"
import { SelectListItemProps } from "./SelectListItem.types"

export default function SelectListItem<T extends string = string>(props: SelectListItemProps<T>) {
    const { item, isSelected, isDisabled = false, onSelect } = props

    const handleSelect = () => onSelect(item)

    const triggerRef = useRef<HTMLDivElement>(null)

    const desiredIcon = useMemo(() => {
        if (item.iconInReactNode) {
            return item.iconInReactNode
        } else if (item.icon) {
            return (
                <div className={styles.icon}>
                    <Icon icon={item.icon} size={14} />
                </div>
            )
        } else {
            return null
        }
    }, [item.icon, item.iconInReactNode])

    return (
        <Command.Item value={item.value} disabled={isDisabled} onSelect={handleSelect}>
            <PopoverClose asChild={true}>
                <div
                    className={clsx(styles.base, {
                        [styles.disabled]: isDisabled,
                        [styles.selected]: isSelected,
                    })}
                    role="button"
                    onKeyDown={(event) =>
                        ifSpaceOrEnter(event, () => {
                            handleSelect()
                            triggerRef.current?.click()
                        })
                    }
                    tabIndex={0}
                    ref={triggerRef}
                >
                    <div className={styles.iconAndText}>
                        {desiredIcon}
                        <div className={styles.text}>{item.label}</div>
                    </div>
                    {isSelected && (
                        <Icon
                            icon={fontAwesomeIcon({ name: "check", style: "regular", family: "sharp" })}
                            size={14}
                            className={styles.checkmark}
                        />
                    )}
                </div>
            </PopoverClose>
        </Command.Item>
    )
}
