// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnscheduledJobsColumnMessage-base__uT4_S{display:flex;flex-direction:column;gap:var(--size-5);align-items:center;justify-content:center;padding:var(--size-8) var(--size-6);background:var(--white)}@media only screen and (min-width: 64rem){.UnscheduledJobsColumnMessage-base__uT4_S{gap:var(--size-4);align-items:flex-start}}.UnscheduledJobsColumnMessage-borderTop__lH7Qd{border-top:var(--size-0_25) solid var(--gray-3)}.UnscheduledJobsColumnMessage-iconsAndCopy__FMMzh{display:flex;flex-direction:column;gap:var(--size-4);align-items:center;align-self:stretch;justify-content:center}@media only screen and (min-width: 64rem){.UnscheduledJobsColumnMessage-iconsAndCopy__FMMzh{align-items:flex-start}}.UnscheduledJobsColumnMessage-unscheduledIcon__TFo1C{color:var(--gray-8)}.UnscheduledJobsColumnMessage-scheduledIcon__qpPD4{color:var(--tomato-9)}.UnscheduledJobsColumnMessage-onHoldIcon1__bXdsk{color:var(--amber-11)}.UnscheduledJobsColumnMessage-onHoldIcon2__lYJek{color:var(--gray-12)}.UnscheduledJobsColumnMessage-copy__AgCdm{display:flex;flex-direction:column;gap:var(--size-2);align-items:center;align-self:stretch;text-align:center}@media only screen and (min-width: 64rem){.UnscheduledJobsColumnMessage-copy__AgCdm{align-items:flex-start;text-align:left}}.UnscheduledJobsColumnMessage-title__UKEC4{align-self:stretch;color:var(--gray-12);font:var(--font-global-heading-03);font-feature-settings:var(--feature-settings-global-heading-03);letter-spacing:var(--letter-spacing-global-heading-03)}.UnscheduledJobsColumnMessage-description__qw1ZT{align-self:stretch;color:var(--gray-11);font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UnscheduledJobsColumnMessage-base__uT4_S",
	"borderTop": "UnscheduledJobsColumnMessage-borderTop__lH7Qd",
	"iconsAndCopy": "UnscheduledJobsColumnMessage-iconsAndCopy__FMMzh",
	"unscheduledIcon": "UnscheduledJobsColumnMessage-unscheduledIcon__TFo1C",
	"scheduledIcon": "UnscheduledJobsColumnMessage-scheduledIcon__qpPD4",
	"onHoldIcon1": "UnscheduledJobsColumnMessage-onHoldIcon1__bXdsk",
	"onHoldIcon2": "UnscheduledJobsColumnMessage-onHoldIcon2__lYJek",
	"copy": "UnscheduledJobsColumnMessage-copy__AgCdm",
	"title": "UnscheduledJobsColumnMessage-title__UKEC4",
	"description": "UnscheduledJobsColumnMessage-description__qw1ZT"
};
export default ___CSS_LOADER_EXPORT___;
