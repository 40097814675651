import clsx from "clsx"
import { useMemo } from "react"

import { getPriorityIcon, getPriorityLabel } from "@utils/priority"

import { Icon } from "@atoms/index"

import styles from "./PriorityCell.module.scss"
import { PriorityCellProps } from "./PriorityCell.types"

export default function PriorityCell(props: PriorityCellProps) {
    const { variant = "primary", priority } = props

    const priorityRender = useMemo(() => {
        const icon = getPriorityIcon(priority)
        const label = getPriorityLabel(priority)

        return (
            <>
                <Icon icon={icon} size={10} containerClassName={priority === 1 ? styles.warning : ""} />
                <span>{label}</span>
            </>
        )
    }, [priority])

    return (
        <div className={clsx(styles.base, styles[variant])}>
            <div className={styles.content}>{priorityRender}</div>
        </div>
    )
}
