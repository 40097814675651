// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardList-base__yKIe7{display:flex;flex-direction:column;flex-grow:1;margin-bottom:var(--bottom-bar-height);overflow:auto}.CardList-noScroll__qEKTG{overflow:hidden}.CardList-scrollContainer__OcvhL{display:flex;flex-direction:column;align-items:center;width:var(--size-full);margin-bottom:var(--bottom-bar-height);overflow:auto}.CardList-virtualizer__kPlij{position:relative;width:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "CardList-base__yKIe7",
	"noScroll": "CardList-noScroll__qEKTG",
	"scrollContainer": "CardList-scrollContainer__OcvhL",
	"virtualizer": "CardList-virtualizer__kPlij"
};
export default ___CSS_LOADER_EXPORT___;
