import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useNavigationLinks from "@hooks/useNavigationLinks"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"

import { TopBar } from "@organisms"
import ViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider"

import { AppFrame } from "@templates"

import { JOBS_SERIES_LIST_CONTROLS_VIEW } from "@constants/storage"

import JobSeriesTableView from "./views/JobSeriesTableView/JobSeriesTableView"

export default function JobSeriesList() {
    const { user } = useUser()
    const router = useRouter()
    const { jobs: jobsLink } = useNavigationLinks()

    const sublinks = filterUserLinks(jobsLink.subLinks, user)

    return (
        <AppFrame.Root pageId="JobSeriesList">
            <ViewTypesProvider viewTypeKey={JOBS_SERIES_LIST_CONTROLS_VIEW} defaultViewType="table">
                {() => (
                    <>
                        <TopBar.Root>
                            <TopBar.Header>
                                <TopBar.TopBarHeaderStart>
                                    <TopBar.PageTitle
                                        label="Job Series"
                                        icon={icon({ name: "wrench-simple", style: "regular", family: "sharp" })}
                                        iconActive={icon({ name: "wrench-simple", style: "solid", family: "sharp" })}
                                        navigationItems={
                                            sublinks.length > 1
                                                ? sublinks.map((item) => ({
                                                      label: item.label,
                                                      value: item.path,
                                                      isActive: item.label === "Job Series",
                                                  }))
                                                : undefined
                                        }
                                        onNavigationItemSelect={(item) => {
                                            router.push(item.value)
                                        }}
                                    />
                                </TopBar.TopBarHeaderStart>
                                <TopBar.TopBarHeaderEnd>
                                    <TopBar.PagePresence />
                                    <TopBar.ViewSelector viewTypes={["table"]} />
                                </TopBar.TopBarHeaderEnd>
                            </TopBar.Header>
                        </TopBar.Root>
                        <JobSeriesTableView />
                    </>
                )}
            </ViewTypesProvider>
        </AppFrame.Root>
    )
}
