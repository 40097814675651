import Spinner from "@legacy/core/components/Spinner";
import React, { Fragment } from "react";
import Select from "react-select";
import UniversalButton from "../../core/buttons/UniversalButton";
import UniversalLinkButton from "../../core/buttons/UniversalLinkButton";
import { isPaginatedResponse } from "../../core/utils/utils";

function DashboardCardHeader(props) {
    const { title, buttonConfig, filterOptions, filterSelection, handleSelectionChange, showCreateButton } = props

    return (
        <div className="card__header" aria-label="Card Header">
            <div className="card__header__title" aria-label="Card Title">{title}</div>
            {
                filterOptions !== null && (
                    <div className="card__header__filter" aria-label="Card Data Filter Control">
                        <Select
                            isSearchable={false}
                            value={filterSelection}
                            onChange={handleSelectionChange}
                            options={filterOptions}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />
                    </div>
                )
            }
            {
                buttonConfig !== null && buttonConfig.predicate && showCreateButton && (
                    <div className="card__header__button" aria-label="Card Link Button">
                        <UniversalLinkButton
                            type="secondary"
                            text={buttonConfig.text}
                            destination={buttonConfig.url}
                            title={buttonConfig.title}
                        />
                    </div>
                )
            }
        </div>
    )
}

function DashboardCardFooter(props) {
    const { pageSize, currentPage, setCurrentPage, currentPageData } = props

    return (
        <div className="card__footer card__pagination" aria-label="Card Footer: Pagination">
            <div className="card__pagination__page__details" aria-label="Current Page Details">
                {
                    pageSize && `Showing ${1 + ((pageSize * currentPage) - pageSize)} to ${Math.min(currentPageData.count, pageSize * currentPage)} of ${currentPageData.count}`
                }
            </div>
            <div className="card__pagination__page__controls" aria-label="Pagination Controls">
                <UniversalButton
                    type="secondary"
                    text={<Fragment><i className="fa-sharp fa-solid fa-chevron-left" aria-hidden="true" /></Fragment>}
                    handler={event => setCurrentPage(old => Math.max(1, old - 1))}
                    title="Go to Previous Page"
                    immediate={true}
                    disabled={currentPageData.previous === null}
                    extraClasses={currentPageData.previous === null ? "disabled" : ""}
                />
                <UniversalButton
                    type="secondary"
                    text={<Fragment><i className="fa-sharp fa-solid fa-chevron-right" aria-hidden="true" /></Fragment>}
                    handler={event => setCurrentPage(old => currentPageData.next ? old + 1 : old)}
                    title="Go to Next Page"
                    immediate={true}
                    disabled={currentPageData.next === null}
                    extraClasses={currentPageData.next === null ? "disabled" : ""}
                />
            </div>
        </div>
    )
}

function DashboardCardRowStates(props) {
    const { isLoading, isError, errorMessage, isEmpty, emptyIcon, emptyMessage } = props

    if (isLoading) {
        return (
            <div className="card__data card__data--loading" aria-label="Card Data" aria-busy="true">
                <Spinner />
            </div>
        )
    }
    else if (isError) {
        return (
            <div className="card__data" aria-label="Card Data" role="rowgroup">
                <div className="card__data__row info-row" aria-label="Info Row">
                    <div className="card__data__row__content-container">
                        <div className="card__data__row__icon" aria-hidden="true">
                            <div className="icon-circle-container"><i className="fa-sharp fa-light fa-bug" /></div>
                        </div>
                        <div className="card__data__row__body">
                            <div className="info-row__text">{errorMessage}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        if (isEmpty) {
            return (
                <div className="card__data" aria-label="Card Data" role="rowgroup">
                    <div className="card__data__row info-row" aria-label="Info Row">
                        <div className="card__data__row__content-container">
                            <div className="card__data__row__icon" aria-hidden="true">
                                <div className="icon-circle-container"><i className={`fa-sharp fa-light ${emptyIcon}`} /></div>
                            </div>
                            <div className="card__data__row__body">
                                <div className="info-row__text">{emptyMessage}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="card__data" aria-label="Card Data" role="rowgroup">
                    {props.children}
                </div>
            )
        }
    }
}

function DashboardCard(props) {
    const { title, predicate=true, extraClasses, pageSize=null, showCreateButton=true } = props

    const [buttonConfig, setButtonConfig] = React.useState(null)
    const [filterOptions, setFilterOptions] = React.useState(null)
    const [filterSelection, setFilterSelection] = React.useState({"label": "All", "value": ""})
    const [currentPage, setCurrentPage] = React.useState(1)
    const [currentPageData, setCurrentPageData] = React.useState(null)

    const handleSelectionChange = (selectedOption) => {
        setCurrentPageData(null)
        setCurrentPage(1)
        setFilterSelection(selectedOption)
    }

    if (predicate) {
        return (
            <div className="card-wrapper">
                <div className={`card${extraClasses ? ` ${extraClasses}` : ""}`} aria-label={`Card: ${title}`} key={title}>
                    <DashboardCardHeader
                        title={title}
                        buttonConfig={buttonConfig}
                        filterOptions={filterOptions}
                        filterSelection={filterSelection}
                        handleSelectionChange={handleSelectionChange}
                        showCreateButton={showCreateButton}
                    />
                    {React.cloneElement(props.children, {title, pageSize, currentPage, filterSelection, setFilterOptions, setButtonConfig, setCurrentPageData})}
                    {
                        currentPageData && isPaginatedResponse(currentPageData) && <DashboardCardFooter
                            pageSize={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            currentPageData={currentPageData}
                        />
                    }
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

export default DashboardCard
export { DashboardCardRowStates };
