export const LIST_SEARCH_PARAM_TYPE = "type"
export const LIST_SEARCH_PARAM_PHASE = "phase"
export const LIST_SEARCH_PARAM_ACTIVE_TAB = "tab"
export const LIST_SEARCH_PARAM_KEYWORD_SEARCH = "keywords"
export const LIST_SEARCH_PARAM_ORDERING = "ordering"

export const TIMELINE_VIEW_PARAM_VISIBLE_DATE_START = "visible-start-date"
export const TIMELINE_VIEW_PARAM_VISIBLE_DATE_END = "visible-end-date"
export const TIMELINE_VIEW_PARAM_START_DATE = "start-date"
export const TIMELINE_VIEW_PARAM_END_DATE = "end-date"
export const TIMELINE_VIEW_PARAM_SCROLL_POSITION = "scroll-position"

export const PROFILE_BOTTOM_SHEET_OPEN = "bottom-sheet"

export const AUTH_NEXT_PARAM = "next"
export const AUTH_SOCIAL_ERROR_PARAM = "social-error"
