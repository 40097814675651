import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import EstimateCreateContainer from "@legacy/estimates/EstimateCreateContainer"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { ESTIMATES_ROUTES } from "@routes/estimates"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

export default function EstimateEdit() {
    const isDesktop = useIsDesktop()

    const estimateId = window.ESTIMATE_ID
    const isDraft = window.ESTIMATE_IS_DRAFT

    const [listRoute, detailsRoute] = useSlugExtractor([ESTIMATES_ROUTES.LIST, ESTIMATES_ROUTES.DETAILS], estimateId)
    const { addToastToQueue } = useToastQueue()
    const { hasPermission } = useHasAccess()

    const listBackDestination = hasPermission("estimates_list_permission", PERMISSION_LEVEL.FULL)
        ? listRoute
        : undefined
    const detailsBackDestination = hasPermission("estimates_view_permission", PERMISSION_LEVEL.FULL)
        ? detailsRoute
        : undefined

    return (
        <AppFrame.Root pageId="EstimateEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Estimates"
                            icon={icon({ name: "file-lines", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                            destination={listBackDestination}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle
                            label={isDraft ? "New Estimate" : "Edit Estimate"}
                            backDestination={detailsBackDestination}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <EstimateCreateContainer formMode="EDIT_ESTIMATE" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
