import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useRouter from "@hooks/useRouter"

import { Button, ListInfoGlyph } from "@atoms"

import styles from "@organisms/CardList/CardListEdgeStates/CardListEdgeStates.module.scss"
import useTable from "@organisms/Table/Table.context"

export default function CardListEmptyState() {
    const { push } = useRouter()

    const { tabContentName, objectCreateRoute, objectName } = useTable()

    const title = `No ${tabContentName.toLowerCase().replace("all", "")}`

    const explanation = `There are no ${tabContentName
        .toLowerCase()
        .replace("all", "")
        .replace("&", "or")} to display.`

    return (
        <div className={styles.base}>
            <div className={styles.content}>
                <div className={styles.copyWrap}>
                    <ListInfoGlyph
                        variant="black"
                        icon={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                    />
                    <div className={styles.copy}>
                        <h2 className={styles.title}>{title}</h2>
                        <p className={styles.explanation}>{explanation}</p>
                    </div>
                </div>
                <Button
                    colorScheme="gray"
                    size="md"
                    variant="solid"
                    leftIcon={icon({ name: "plus", style: "solid", family: "sharp" })}
                    onClick={() => push(objectCreateRoute)}
                    noDebounce={true}
                >
                    Create {objectName}
                </Button>
            </div>
        </div>
    )
}
