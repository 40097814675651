import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"

import useSlugExtractor from "@hooks/useSlugExtractor"

import AccessCheck from "@particles/AccessCheck/AccessCheck"

import { HOME_ROUTE } from "@routes/home"
import { JOBS_ROUTES } from "@routes/jobs"
import { MENU_ROUTE } from "@routes/menu"
import { SEARCH_ROUTE } from "@routes/search"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import styles from "./BottomBar.module.scss"
import BottomBarCreateButton from "./BottomBarCreateButton/BottomBarCreateButton"
import BottomBarItem from "./BottomBarItem/BottomBarItem"
import BottomBarItemBlank from "./BottomBarItemBlank/BottomBarItemBlank"

const body = document.querySelector("body")

export default function BottomBar() {
    const bottomBarRef = useRef<HTMLDivElement>(null)

    const [homeRoute, jobsListRoute, menuRoute, searchRoute] = useSlugExtractor([
        HOME_ROUTE,
        JOBS_ROUTES.LIST,
        MENU_ROUTE,
        SEARCH_ROUTE,
    ])

    useEffect(() => {
        if (bottomBarRef.current) {
            const bottomBarHeight = bottomBarRef.current.offsetHeight

            const root = document.documentElement
            root.style.setProperty("--bottom-bar-height", `${bottomBarHeight}px`)
        }

        return () => {
            const root = document.documentElement
            root.style.setProperty("--bottom-bar-height", `0`)
        }
    }, [bottomBarRef])

    return (
        body &&
        createPortal(
            <div ref={bottomBarRef} className={styles.base} role="navigation">
                <div className={styles.container}>
                    <BottomBarItem
                        title="Home"
                        path={homeRoute}
                        icon={icon({ name: "house", style: "regular", family: "sharp" })}
                        iconActive={icon({ name: "house", style: "solid", family: "sharp" })}
                    />
                    <AccessCheck
                        entitlements={["entitlement_jobs_enabled"]}
                        permissions={{
                            jobs_list_permission: PERMISSION_LEVEL.FULL,
                        }}
                        placeholder={<BottomBarItemBlank />}
                    >
                        <BottomBarItem
                            title="Jobs"
                            path={jobsListRoute}
                            icon={icon({ name: "wrench-simple", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "wrench-simple", style: "solid", family: "sharp" })}
                        />
                    </AccessCheck>

                    <AccessCheck
                        permissions={{
                            estimates_create_permission: PERMISSION_LEVEL.RESTRICTED,
                            jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
                            invoices_create_permission: PERMISSION_LEVEL.RESTRICTED,
                            purchase_orders_create_permission: PERMISSION_LEVEL.RESTRICTED,
                            vendors_create_permission: PERMISSION_LEVEL.FULL,
                            clients_create_permission: PERMISSION_LEVEL.FULL,
                            pricebook_create_permission: PERMISSION_LEVEL.FULL,
                        }}
                    >
                        <BottomBarCreateButton />
                    </AccessCheck>

                    <AccessCheck
                        permissions={{
                            estimates_list_permission: PERMISSION_LEVEL.FULL,
                            jobs_list_permission: PERMISSION_LEVEL.FULL,
                            invoices_list_permission: PERMISSION_LEVEL.FULL,
                            purchase_orders_list_permission: PERMISSION_LEVEL.FULL,
                            bills_list_permission: PERMISSION_LEVEL.FULL,
                            vendors_list_permission: PERMISSION_LEVEL.FULL,
                            clients_list_permission: PERMISSION_LEVEL.FULL,
                            pricebook_list_permission: PERMISSION_LEVEL.FULL,
                        }}
                        placeholder={<BottomBarItemBlank />}
                    >
                        <BottomBarItem
                            title="Search"
                            path={searchRoute}
                            icon={icon({ name: "magnifying-glass", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "magnifying-glass", style: "solid", family: "sharp" })}
                        />
                    </AccessCheck>
                    <BottomBarItem
                        title="Menu"
                        path={menuRoute}
                        icon={icon({ name: "list-ul", style: "regular", family: "sharp" })}
                        iconActive={icon({ name: "list-ul", style: "solid", family: "sharp" })}
                    />
                </div>
            </div>,
            body,
        )
    )
}
