import { DateHelper, ViewPreset } from "@bryntum/schedulerpro"
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react"
import clsx from "clsx"
import dayjs from "dayjs"
import { useMemo } from "react"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"

import debounce from "@utils/debounce"
import matchesDesktopScreen from "@utils/matchesDesktopScreen"

import {
    Column,
    JobTimelineScheduler,
    TimelineViewPresets,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"
import {
    COMPACT_TIMELINE_ROW_HEIGHT,
    HORIZONTAL_COLLAPSED_TECHNICIANS_BAR_WIDTH,
    HORIZONTAL_EXPANDED_TECHNICIANS_BAR_WIDTH,
    REGULAR_TIMELINE_ROW_HEIGHT,
    VERTICAL_TIMELINE_TECHNICIANS_COLUMN_HEIGHT,
    VERTICAL_TIMELINE_TECHNICIANS_COLUMN_WIDTH,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineViewConfig/JobTimelineViewConfig"
import {
    renderHeaderCurrentDay,
    renderHeaderMinutes,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineViewConfig/JobTimelineViewConfig.utils"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from "@constants/schedulerZoom"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./useJobTimelineViewData"
import useJobTimelineViewEventHandlers from "./useJobTimelineViewEventHandlers"
import useJobTimelineViewRenderers from "./useJobTimelineViewRenderers"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewUIUtils from "./useJobTimelineViewUIUtils"

const zoomSteps = 2

const { isDesktop } = matchesDesktopScreen()

// Constants for slope and intercept to avoid magic numbers and repetition
const MONTH_SLOPE = (7 - 31) / 40 // -0.15
const MONTH_INTERCEPT = 31

const WEEK_SLOPE = (3 - 7) / 40 // -0.1
const WEEK_INTERCEPT = 7

const THREE_DAYS_DIVISOR = { desktop: { low: 12, high: 24 }, mobile: { low: 6, high: 12 } }

// Utility function to calculate ticks based on provided slope and intercept
const calculateTick = (dimension: number, zoom: number, slope: number, intercept: number) =>
    dimension / (slope * zoom + intercept)

// Calculate ticks for Month, Week, and Three Days presets
const calculateMonthTick = (dimension: number, zoom: number) =>
    calculateTick(dimension, zoom, MONTH_SLOPE, MONTH_INTERCEPT)
const calculateWeekTick = (dimension: number, zoom: number) =>
    calculateTick(dimension, zoom, WEEK_SLOPE, WEEK_INTERCEPT)

const calculateThreeDaysTick = (dimension: number, zoom: number) => {
    const number = isDesktop
        ? zoom > 20
            ? THREE_DAYS_DIVISOR.desktop.high
            : THREE_DAYS_DIVISOR.desktop.low
        : zoom > 20
          ? THREE_DAYS_DIVISOR.mobile.high
          : THREE_DAYS_DIVISOR.mobile.low
    return dimension / (3 - (zoom / 40) * 2) / number
}

const createMonthPreset = (schedulerWidth: number, schedulerHeight: number, zoom: number): Partial<ViewPreset> => ({
    base: "monthAndYear",
    tickWidth: calculateMonthTick(schedulerWidth, zoom),
    tickHeight: calculateMonthTick(schedulerHeight, zoom),
    timeResolution: { unit: "minute", increment: 60 },
    defaultSpan: 31,
    headers: [
        { unit: "month", dateFormat: "MMMM YYYY" },
        { unit: "day", dateFormat: "D" },
    ],
})

const createWeekPreset = (schedulerWidth: number, schedulerHeight: number, zoom: number): Partial<ViewPreset> => ({
    base: "dayAndWeek",
    tickWidth: calculateWeekTick(schedulerWidth, zoom),
    tickHeight: calculateWeekTick(schedulerHeight, zoom),
    timeResolution: { unit: "minute", increment: 15 },
    defaultSpan: 7,
    headers: [
        { unit: "week", dateFormat: "MMM DD", renderer: renderHeaderCurrentDay },
        { unit: "day", dateFormat: "ddd" },
    ],
})

const createThreeDaysPreset = (
    schedulerWidth: number,
    schedulerHeight: number,
    zoom: number,
): Partial<ViewPreset> => ({
    base: "dayAndWeek",
    displayDateFormat: "ll HH:mm",
    tickWidth: calculateThreeDaysTick(schedulerWidth, zoom),
    tickHeight: calculateThreeDaysTick(schedulerHeight, zoom),
    mainHeaderLevel: 0,
    columnLinesFor: 0,
    timeResolution: { unit: "minute", increment: 15 },
    headers: [
        {
            unit: "day",
            align: "center",
            increment: 1,
            dateFormat: "MMM DD YYYY",
            renderer: renderHeaderCurrentDay,
        },
        {
            unit: "hour",
            align: "start",
            increment: isDesktop ? (zoom > 20 ? 1 : 2) : zoom > 20 ? 2 : 4,
            dateFormat: "h a",
        },
    ],
})

const createDayPreset = (zoom: number): Partial<ViewPreset> => ({
    base: "dayAndWeek",
    tickWidth: zoom + 20,
    tickHeight: zoom + 20,
    displayDateFormat: "ll HH:mm",
    mainUnit: "day",
    defaultSpan: 1,
    timeResolution: { unit: "minute", increment: 15 },
    headers: [
        {
            unit: "day",
            align: "start",
            dateFormat: "MMM DD YYYY",
            renderer: renderHeaderCurrentDay,
        },
        {
            unit: "minute",
            align: "start",
            increment: 15,
            dateFormat: "h a",
            renderer: renderHeaderMinutes,
        },
    ],
})

const delayedExecution = debounce((fn: () => void) => {
    fn()
})

export default function useJobTimelineViewSchedulerProps(): {
    verticalProps: BryntumSchedulerProProps
    horizontalProps: BryntumSchedulerProProps
} {
    const {
        techniciansColumnStatus,
        jobCardStyle,
        schedulerZoom,
        isSchedulerConfigured,
        dateRange,
        timeFrameType,
        visibleDateRange,
        setVisibleDateRange,
        setDateRange,
    } = useJobTimelineViewStates()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { hasPermission } = useHasAccess()

    const zoomLevels = useMemo(() => {
        const techniciansColumnWidth =
            techniciansColumnStatus === "collapsed"
                ? HORIZONTAL_COLLAPSED_TECHNICIANS_BAR_WIDTH
                : HORIZONTAL_EXPANDED_TECHNICIANS_BAR_WIDTH

        const schedulerWidth =
            window.innerWidth -
            Number(document.querySelector(".jsMainSideBar")?.clientWidth || 0) -
            techniciansColumnWidth
        const schedulerHeight =
            window.innerHeight -
            Number(document.querySelector(".jsTopBar")?.clientHeight || 0) -
            VERTICAL_TIMELINE_TECHNICIANS_COLUMN_HEIGHT

        const levels: TimelineViewPresets = {
            day: [],
            threeDays: [],
            week: [],
            month: [],
        }

        for (let zoom = MINIMUM_ZOOM; zoom <= MAXIMUM_ZOOM; zoom += zoomSteps) {
            levels.month.push({
                ...createMonthPreset(schedulerWidth, schedulerHeight, zoom),
                id: `month-${zoom}`,
            })

            levels.week.push({
                ...createWeekPreset(schedulerWidth, schedulerHeight, zoom),
                id: `week-${zoom}`,
            })

            levels.threeDays.push({
                ...createThreeDaysPreset(schedulerWidth, schedulerHeight, zoom),
                id: `threeDays-${zoom}`,
            })

            levels.day.push({
                ...createDayPreset(zoom),
                id: `day-${zoom}`,
            })
        }

        return levels
    }, [])

    const { isFetchingCalendarData, addResourceTimeRangesToScheduler } = useJobTimelineViewData()

    const {
        onEventDrop,
        onEventResizeEnd,
        onEventClick,
        onScheduleClick,
        onEventPartialResize,
        onEventDrag,
        overlappingEventSorter,
        onSchedulerPaint,
        onMouseMoveOverScheduler,
        onEventDragAbort,
        onZoomChange,
    } = useJobTimelineViewEventHandlers()

    const { hideEventFeedback, showEventFeedback, hideEventMoveFeedback } = useJobTimelineViewUIUtils()

    const {
        renderJobTimelineViewCard,
        renderJobTimelineViewCardTooltip,
        renderTechniciansColumnCell,
        renderTechniciansColumnHeader,
        renderResourceTimeRange,
        renderVerticalTechnicianBarTechCell,
    } = useJobTimelineViewRenderers()

    const isDesktop = useIsDesktop()

    const sharedProps: BryntumSchedulerProProps = {
        presets: zoomLevels[timeFrameType],
        viewPreset: `${timeFrameType}-${schedulerZoom[timeFrameType]}`,
        useInitialAnimation: false,
        enableEventAnimations: false,
        // @ts-expect-error bad typing
        onScheduleClick: onScheduleClick,
        eventRenderer: renderJobTimelineViewCard,
        resourceTimeRangeRenderer: renderResourceTimeRange,
        eventTooltipFeature: {
            cls: "job-timeline-view__job-preview-tooltip",
            template: renderJobTimelineViewCardTooltip,
            hideDelay: true,
            allowOver: false,
            showOnHover: true,
            hoverDelay: 0,
            textContent: true,
            scrollAction: "hide",
            preventTooltipOnTouch: true,
            disabled: !isDesktop,
            align: {
                align: "t-b",
                overlap: false,
                anchor: true,
                offset: 4,
            },
        },
        // @ts-expect-error bad typing
        onEventPartialResize: onEventPartialResize,
        onEventDragAbort: onEventDragAbort,
        onEventDragReset: hideEventMoveFeedback,
        // @ts-expect-error bad typing
        onEventDrop: onEventDrop,
        eventResizeFeature: {
            disabled: !hasPermission("jobs_edit_permission", PERMISSION_LEVEL.FULL),
            showTooltip: false,
        },
        onEventDrag: onEventDrag,
        eventDragFeature: {
            disabled: !hasPermission("jobs_edit_permission", PERMISSION_LEVEL.FULL),
            showTooltip: false,
            copyKey: "",
            dragHelperConfig: {
                dropTargetSelector: ".jsEventDroppableArea",
            },
        },
        onBeforeRenderRow: ({ row }) => {
            row.addCls("jsEventDroppableArea")
        },
        // @ts-expect-error bad typing
        onEventResizeEnd: onEventResizeEnd,
        // @ts-expect-error bad typing
        onEventClick: hasPermission("jobs_view_permission", PERMISSION_LEVEL.FULL) ? onEventClick : undefined,
        onMouseOut: hideEventFeedback,
        onEventMouseEnter: hideEventFeedback,
        onEventMouseLeave: showEventFeedback,
        listeners: {
            presetchange: ({ zoomDate, to }: { zoomDate: Date; to: { _id: string } }) => {
                if (zoomDate) {
                    // This regex extracts the first sequence of digits from the string since Bryntum Scheduler stores
                    // IDs as strings, not numbers, which are needed to calculate the zoom level.
                    const NUMBER_REGEX = /\d+/
                    const match = to._id.match(NUMBER_REGEX)
                    if (match) {
                        onZoomChange(Number(match[0]))
                    }
                }
            },
        },
        onVisibleDateRangeChange: (props) => {
            const { old: oldDate, new: newDate } = props
            if (!oldDate?.startDate || !newDate?.startDate) {
                return
            } else {
                const isNewDateRange =
                    !dayjs(visibleDateRange.start).isSame(newDate.startDate, "day") ||
                    !dayjs(visibleDateRange.end).isSame(newDate.endDate, "day")

                const schedulerInstance = (schedulerPro.current?.instance as JobTimelineScheduler) || {}

                if (isNewDateRange && isSchedulerConfigured && !schedulerInstance.stopDateStateUpdates) {
                    if (timeFrameType === "day") {
                        addResourceTimeRangesToScheduler(newDate.startDate, newDate.endDate)
                        setVisibleDateRange({ start: newDate.startDate, end: newDate.endDate })
                    } else {
                        delayedExecution(() => {
                            setVisibleDateRange({ start: newDate.startDate, end: newDate.endDate })
                        })
                    }
                }
            }
        },
        onDateRangeChange: (props) => {
            const { new: newDates } = props

            if (!newDates.startDate) {
                return
            } else {
                const isNewStartDate = DateHelper.diff(newDates.startDate, dateRange.start, "day", false) !== 0
                const isNewEndDate = DateHelper.diff(newDates.endDate, dateRange.end, "day", false) !== 0

                const isNewDateRange = isNewStartDate || isNewEndDate
                const schedulerInstance = (schedulerPro.current?.instance as JobTimelineScheduler) || {}

                if (
                    isSchedulerConfigured &&
                    isNewDateRange &&
                    !isFetchingCalendarData &&
                    !schedulerInstance.stopDateStateUpdates
                ) {
                    delayedExecution(() => {
                        setDateRange({ start: newDates.startDate, end: newDates.endDate })
                    })
                }
            }
        },
    }

    const verticalProps: BryntumSchedulerProProps = {
        ...sharedProps,
        cls: "jsJobTimelineView job-timeline-view--vertical",
        mode: "vertical",
        eventLayout: "mixed",
        resourceColumns: {
            columnWidth: VERTICAL_TIMELINE_TECHNICIANS_COLUMN_WIDTH,
            height: VERTICAL_TIMELINE_TECHNICIANS_COLUMN_HEIGHT,
            headerRenderer: renderVerticalTechnicianBarTechCell,
        },
    }

    const horizontalProps: BryntumSchedulerProProps = {
        ...sharedProps,
        cls: clsx("jsJobTimelineView", {
            "job-timeline-view__technicians-bar--collapsed": techniciansColumnStatus === "collapsed",
            "job-timeline-view--compact": jobCardStyle === "compact",
        }),
        overlappingEventSorter: overlappingEventSorter,
        rowHeight: jobCardStyle === "regular" ? REGULAR_TIMELINE_ROW_HEIGHT : COMPACT_TIMELINE_ROW_HEIGHT,
        listeners: {
            paint: onSchedulerPaint,
            scheduleMouseMove: onMouseMoveOverScheduler,
            ...sharedProps.listeners,
        },
        // eventLayout: "mixed",
        columns: [
            {
                type: "resourceInfo",
                id: "techniciansColumn",
                text: "Technicians",
                field: "name",
                showEventCount: false,
                width:
                    techniciansColumnStatus === "collapsed"
                        ? HORIZONTAL_COLLAPSED_TECHNICIANS_BAR_WIDTH
                        : HORIZONTAL_EXPANDED_TECHNICIANS_BAR_WIDTH,
                collapsed: techniciansColumnStatus === "collapsed",
                compact: jobCardStyle === "compact",
                collapsible: false,
                readOnly: true,
                filterable: false,
                enableHeaderContextMenu: false,
                sortable: false,
                resizable: false,
                enableCellContextMenu: false,
                region: "left",
                cls: "job-timeline-view__technicians-bar",
                cellCls: "job-timeline-view__technicians-bar-cell-wrap",
                renderer: renderTechniciansColumnCell,
                headerRenderer: renderTechniciansColumnHeader,
            } as Partial<Column>,
        ],
    }

    return {
        verticalProps,
        horizontalProps,
    }
}
