import PriceBookListCard from "@legacy/pricebook/components/PriceBookItemListCard"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import replaceSlugs from "@utils/replaceSlugs"

import { CardList, Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { CREATE_ROUTE_MAP } from "@pages/Pricebook/PricebookList/views/PricebookTableView/PricebookTableView"
import {
    DiscountsTabs,
    MiscellaneousTabs,
    PartsAndMaterialsTabs,
    PricebookTypeName,
    ServicesTabs,
    TaxRatesTabs,
} from "@pages/Pricebook/PricebookList/views/PricebookTableView/PricebookTableViewTabs"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB,
    PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH,
    PRICEBOOK_LIST_CONTROLS_ORDERING,
    PRICEBOOK_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { PricebookCardViewProps } from "./PricebookCardView.types"

export default function PricebookCardView(props: PricebookCardViewProps) {
    const { pricebookType } = props
    const [createRoute] = useSlugExtractor([CREATE_ROUTE_MAP[pricebookType]])
    const { hasPermission } = useHasAccess()

    const tabsByPricebookType: { [key in PricebookTypeName]: JSX.Element } = {
        Services: <ServicesTabs />,
        "Parts & Materials": <PartsAndMaterialsTabs />,
        Miscellaneous: <MiscellaneousTabs />,
        Discounts: <DiscountsTabs />,
        "Tax Rates": <TaxRatesTabs />,
    }

    return (
        <Table.Provider
            activePhaseStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_PHASE, {
                type: pricebookType ?? "undefined",
            })}
            activeTabStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB, {
                type: pricebookType ?? "undefined",
            })}
            searchKeywordsStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH, {
                type: pricebookType ?? "undefined",
            })}
            orderingStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_ORDERING, {
                type: pricebookType ?? "undefined",
            })}
            listTitle={pricebookType ?? ""}
            objectType="pricebook_item"
            objectCreateRoute={createRoute}
            objectIndexName="PriceBookItem"
            objectName="Pricebook Item"
            defaultActivePhase="active"
            objectTypeVariation={pricebookType}
            isRowClickable={hasPermission("pricebook_edit_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("pricebook_create_permission", PERMISSION_LEVEL.FULL)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="active" title="Active" />
                            <TableViewPhase phase="inactive" title="Inactive" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        {tabsByPricebookType[pricebookType] ?? null}
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <CardList CardComponent={PriceBookListCard} />
        </Table.Provider>
    )
}
