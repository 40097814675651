import { INVENTORY_LEVEL_STATUS_MAP, INVENTORY_LEVEL_STATUS_MAP_UNKNOWN } from "@constants/mappings"
import { snakeCaseToTitleCase } from "../utils/utils"

function InventoryStatusRenderer(props) {
    const { value, trackInventory } = props
    const { level } = INVENTORY_LEVEL_STATUS_MAP[value] ?? INVENTORY_LEVEL_STATUS_MAP_UNKNOWN

    const inventoryClassName = `inventory-details-text--${level.toLowerCase()}`
    const inventoryTagName = snakeCaseToTitleCase(level)

    if (trackInventory) {
        return <span className={inventoryClassName}>{inventoryTagName}</span>
    }
    else {
        return <>Untracked</>
    }
}

export default InventoryStatusRenderer
