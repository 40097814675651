export const AccountingIntegrations = {
    none: 0,
    apideck: 1,
    quickbooks_desktop: 2,
}

export const APIDeckTaxStrategies = {
    normal: 0,
    manual: 1,
    automatic: 2,
}

export const PriceBookItemTypes = {
    service: 1,
    part: 2,
    other: 3,
    discount: 4,
    tax: 5,
}

export const RecurrenceFrequency = {
    DAILY: 3,
    WEEKLY: 2,
    MONTHLY: 1,
    YEARLY: 0,
}

export const InventoryLevels = {
    depleted: 0,
    low: 1,
    limited: 2,
    full: 3,
} as const
