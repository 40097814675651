import { Table } from "@organisms"

import { INVENTORY_LEVEL_STATUS_MAP, INVENTORY_LEVEL_STATUS_MAP_UNKNOWN } from "@constants/mappings"

import { InventoryStatusCellProps } from "./InventoryStatusCell.types"

export default function InventoryStatusCell(props: InventoryStatusCellProps) {
    const { value, trackInventory } = props
    const { variant, level } = INVENTORY_LEVEL_STATUS_MAP[value] ?? INVENTORY_LEVEL_STATUS_MAP_UNKNOWN

    if (!trackInventory) {
        return <Table.Cells.TruncatedTextCell content={"Untracked"} />
    }

    return <Table.Cells.TruncatedTextCell variant={variant} content={level} />
}
