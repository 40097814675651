import Decimal from "decimal.js-light";
import nr from 'number-rounding';
import { PriceBookItemTypes } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";


function calculateInvoiceAmounts(invoice) {
    let subtotal = 0
    let tax = 0
    let amountPaid = 0
    let amountPaidFromDownPayments = 0
    let amountPaidFromPayments = 0

    // Subtotal
    const serviceCharges = invoice.service_charges || []
    const parts = invoice.parts || []
    const otherCharges = invoice.other_charges || []
    const discounts = invoice.discounts || []

    const addToSubtotal = (lineItem) => {subtotal += nr.roundHalfEven(parseFloat(lineItem.quantity || 0) * parseFloat(lineItem.price || 0), 2)}
    const addToTax = (lineItem) => {lineItem.is_taxable ? tax += parseFloat(lineItem.quantity || 0) * parseFloat(lineItem.price || 0) * parseFloat(invoice.tax_percent / 100 || 0) : 0}

    serviceCharges.forEach(addToSubtotal)
    serviceCharges.forEach(addToTax)
    parts.forEach(addToSubtotal)
    parts.forEach(addToTax)
    otherCharges.forEach(addToSubtotal)
    otherCharges.forEach(addToTax)
    discounts.forEach(addToSubtotal)
    discounts.forEach(addToTax)

    if (serviceCharges.length === 0 && parts.length === 0 && otherCharges.length === 0 && discounts.length === 0 && invoice.line_items !== undefined && invoice.line_items.length !== 0) {
        invoice.line_items.forEach(addToSubtotal)
        invoice.line_items.forEach(addToTax)
    }

    // Round Tax after combining items
    tax = nr.roundHalfEven(tax, 2)

    // Total
    let total = subtotal + tax
    total = (total.toFixed(2) === "-0.00" ? 0 : total)

    // Amount Paid
    const payments = invoice.payments || []
    payments.forEach((payment) => {
        const amount = parseFloat(payment.amount || 0)
        amountPaid += amount

        if (payment.from_down_payment) {
            amountPaidFromDownPayments += amount
        }
        else {
            amountPaidFromPayments += amount
        }
    })

    // Amount Due
    let amountDue = total - amountPaid
    amountDue = (amountDue.toFixed(2) === "-0.00" ? 0 : amountDue)

    return {subtotal, tax, total, amountPaid, amountPaidFromDownPayments, amountPaidFromPayments, amountDue}
}


function validateLineItem(parent, lineItem, priceRequired=true, quickBooksItemRequired=false, datesRequired=true, isPurchaseOrderLineItem=false, isBillLineItem=false) {
    let isValid = true
    let errors = {}

    const allLineItems = [...parent.service_charges, ...parent.parts, ...parent.other_charges, ...parent.discounts]
    const allDescriptions = allLineItems.map(singleLineItem => singleLineItem.description)

    if (!valueIsDefined(lineItem.description)) {
        isValid = false
        errors.description = "Description cannot be blank."
    }
    else if (lineItem.description.length > 100) {
        isValid = false
        errors.description = "Description must be fewer than 100 characters."
    }
    else if (lineItem.description !== lineItem.originalDescription && allDescriptions.includes(lineItem.description)) {
        isValid = false
        errors.description = "A line item with this description already exists."
    }

    if (datesRequired && lineItem.line_item_type === 1) {
        if (!valueIsDefined(lineItem.service_start_date)) {
            isValid = false
            errors.service_start_date = "Please enter a start date."
        }
        if (!valueIsDefined(lineItem.service_end_date)) {
            isValid = false
            errors.service_end_date = "Please enter an end date."
        }
        if (
            valueIsDefined(lineItem.service_start_date) && valueIsDefined(lineItem.service_end_date)
            && lineItem.service_end_date < lineItem.service_start_date
        ) {
            isValid = false
            errors.service_end_date = "The end date must be on or after the start date."
        }
    }

    if (lineItem.unit_type !== 2) {
        if (!valueIsDefined(lineItem.quantity)) {
            isValid = false
            errors.quantity = "Quantity is required."
        }
        else if (!(lineItem.quantity >= 0.001 && lineItem.quantity <= 9999.999)) {
            isValid = false
            errors.quantity = "Quanity must be between 0.001 and 9999.999"
        }
    }

    if (isPurchaseOrderLineItem) {
        if (!valueIsDefined(lineItem.expected_cost)) {
            isValid = false
            errors.expected_cost = "Cost is required."
        }
        else if (!(lineItem.expected_cost >= 0 && lineItem.expected_cost <= 9999999.99)) {
            isValid = false
            errors.expected_cost = "Cost must be between 0 and 9999999.99"
        }

        if (!valueIsDefined(lineItem.part_number)) {
            isValid = false
            errors.part_number = "Part Number cannot be blank."
        }
        else if (lineItem.part_number.length > 100) {
            isValid = false
            errors.part_number = "Part Number must be fewer than 100 characters."
        }
    }

    if ((lineItem.line_item_type === PriceBookItemTypes.part || lineItem.line_item_type === PriceBookItemTypes.service || lineItem.line_item_type === PriceBookItemTypes.other) && !valueIsDefined(lineItem.cost) && !isBillLineItem) {
        if (priceRequired) {
            isValid = false
            errors.cost = "Cost is required."
        }
    }

    if (valueIsDefined(lineItem.cost) && !(lineItem.cost >= 0 && lineItem.cost <= 9999999.99) && !isBillLineItem) {
        isValid = false
        errors.cost = "Cost must be between 0 and 9999999.99"
    }

    if (!valueIsDefined(lineItem.price)) {
        if (priceRequired) {
            isValid = false
            errors.price = "Price is required."
        }
    }
    else if (Decimal(lineItem.price).decimalPlaces() > 2) {
        isValid = false
        errors.price = "Ensure that there are no more than 2 decimal places."
    }
    else {
        if (lineItem.line_item_type === PriceBookItemTypes.discount) {
            if (!(lineItem.price >= -9999999.99 && lineItem.price <= 0)) {
                isValid = false
                errors.price = "Price must be between 0 and 9999999.99"
            }
        }
        else {
            if (!(lineItem.price >= 0 && lineItem.price <= 9999999.99)) {
                isValid = false
                errors.price = "Price must be between 0 and 9999999.99"
            }
        }
    }

    if (!valueIsDefined(lineItem.quickbooks_desktop_item_id)) {
        if (quickBooksItemRequired) {
            isValid = false
            errors.quickbooks_desktop_item_id = "Please select a QuickBooks item."
        }
    }

    if (!isValid) {
        console.error(`Line item ${lineItem.description} (${lineItem.id}) is invalid:`, errors)
    }

    return {isValid, errors}
}

function getInvoiceStatusBarLabel(invoice) {
    let statusBarLabel = invoice.status_label

    if (invoice.status_label === "Draft") {
        statusBarLabel = "Draft Preview"
    }

    return statusBarLabel
}

export { calculateInvoiceAmounts, getInvoiceStatusBarLabel, validateLineItem };
