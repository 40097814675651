import { useEffect, useState } from "react"

import useTable from "@organisms/Table/Table.context"

const LOADING_STATE_THRESHOLD = 100
const MINIMUM_LOADING_DURATION = 200

export default function withAlternativeStates<P extends object>({
    LoadingState,
    ErrorState,
    EmptyState,
    SuccessState,
    NoResultsState,
}: {
    LoadingState: React.FC<P>
    ErrorState: React.FC<P>
    EmptyState: React.FC<P>
    SuccessState: React.FC<P>
    NoResultsState: React.FC<P>
}): React.FC<P> {
    return function WithConditionalRenderingComponent(props: P) {
        const { isError, isLoading, isEmpty, isSearching } = useTable()

        const [loadingState, setLoadingState] = useState<"not-started" | "ongoing" | "finished">("not-started")

        // After LOADING_STATE_THRESHOLD ms, determine whether or not to show the loading state
        // If the data has already loaded, don't show it
        // This is to prevent the loading state from showing for very short requests
        useEffect(() => {
            let timeout: NodeJS.Timeout | null = null

            if (isLoading) {
                timeout = setTimeout(() => {
                    setLoadingState("ongoing")
                }, LOADING_STATE_THRESHOLD)
            }

            return () => {
                if (timeout) {
                    clearTimeout(timeout)
                }
            }
        }, [isLoading])

        // Once the loading state is shown, it must be shown for at least MINUMUM_LOADING_DURATION ms
        // This is to prevent the loading state from briefly flashing on the screen
        useEffect(() => {
            let timeout: NodeJS.Timeout | null = null

            if (loadingState === "ongoing") {
                timeout = setTimeout(() => {
                    setLoadingState("finished")
                }, MINIMUM_LOADING_DURATION)
            }

            return () => {
                if (timeout) {
                    clearTimeout(timeout)
                }
            }
        }, [loadingState])

        const dataLoaded = !isLoading && !isError && !isEmpty

        useEffect(() => {
            if (dataLoaded && loadingState === "finished") {
                setLoadingState("not-started")
            }
        }, [dataLoaded, loadingState])

        if ((dataLoaded && loadingState === "finished") || (dataLoaded && loadingState === "not-started")) {
            return <SuccessState {...props} />
        } else if (loadingState === "ongoing" || (loadingState === "finished" && isLoading)) {
            return <LoadingState {...props} />
        } else if (isError) {
            return <ErrorState {...props} />
        } else if (isSearching && isEmpty) {
            return <NoResultsState {...props} />
        } else if (isEmpty) {
            return <EmptyState {...props} />
        } else {
            return null
        }
    }
}
