import replaceSlugs from "@utils/replaceSlugs"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import { GetObjectDetailsRouteFunction } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP } from "@pages/Pricebook/PricebookList/views/PricebookTableView/PricebookTableView.utils"

import { BILLS_ROUTES } from "@routes/bills"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { JOB_SERIES_ROUTES } from "@routes/jobs-series"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import useHasAccess from "./useHasAccess"
import useSlugExtractor from "./useSlugExtractor"

export type ObjectsKeysMap = {
    [key in ObjectIndexName]: GetObjectDetailsRouteFunction
}

export default function useObjectDetailsRoutes(
    objectName?: ObjectIndexName,
): ObjectsKeysMap | GetObjectDetailsRouteFunction {
    const [
        estimateUpdateRoute,
        estimateDetailsRoute,
        jobUpdateRoute,
        jobDetailsRoute,
        jobSeriesDetailsRoute,
        invoiceUpdateRoute,
        invoiceDetailsRoute,
        billUpdateRoute,
        billDetailsRoute,
        purchaseOrderUpdateRoute,
        purchaseOrderDetailsRoute,
        vendorDetailsRoute,
        clientDetailsRoute,
        pricebookItemUpdateRoute,
        pricebookItemReviewRoute,
    ] = useSlugExtractor([
        ESTIMATES_ROUTES.UPDATE,
        ESTIMATES_ROUTES.DETAILS,
        JOBS_ROUTES.UPDATE,
        JOBS_ROUTES.DETAILS,
        JOB_SERIES_ROUTES.DETAILS,
        INVOICES_ROUTES.UPDATE,
        INVOICES_ROUTES.DETAILS,
        BILLS_ROUTES.UPDATE,
        BILLS_ROUTES.DETAILS,
        PURCHASE_ORDER_ROUTES.UPDATE,
        PURCHASE_ORDER_ROUTES.DETAILS,
        VENDOR_ROUTES.DETAILS,
        CLIENTS_ROUTES.DETAILS,
        PRICEBOOK_ITEMS_ROUTES.UPDATE,
        PRICEBOOK_ITEMS_ROUTES.REVIEW,
    ])

    const { hasPermission } = useHasAccess()

    const getObjectDetailsRoute: {
        [key in ObjectIndexName]: (object: ObjectsData | ObjectsIndex) => string
    } = {
        Estimate: (estimate: Estimate | EstimateIndex) => {
            const pathname = "is_draft" in estimate && estimate.is_draft ? estimateUpdateRoute : estimateDetailsRoute
            return replaceSlugs(pathname, { id: estimate.id })
        },
        Job: (job: Job | JobIndex) => {
            const pathname =
                "is_draft" in job && job.is_draft && hasPermission("jobs_edit_permission", PERMISSION_LEVEL.RESTRICTED)
                    ? jobUpdateRoute
                    : jobDetailsRoute

            return replaceSlugs(pathname, { id: job.id })
        },
        Invoice: (invoice: Invoice | InvoiceIndex) => {
            const pathname =
                "is_draft" in invoice &&
                invoice.is_draft &&
                hasPermission("invoices_edit_permission", PERMISSION_LEVEL.RESTRICTED)
                    ? invoiceUpdateRoute
                    : invoiceDetailsRoute
            return replaceSlugs(pathname, { id: invoice.id })
        },
        PurchaseOrder: (purchaseOrder: PurchaseOrder | PurchaseOrderIndex) => {
            const pathname =
                "is_draft" in purchaseOrder &&
                purchaseOrder.is_draft &&
                hasPermission("purchase_orders_edit_permission", PERMISSION_LEVEL.RESTRICTED)
                    ? purchaseOrderUpdateRoute
                    : purchaseOrderDetailsRoute
            return replaceSlugs(pathname, { id: purchaseOrder.id })
        },
        Bill: (bill: Bill | BillIndex) => {
            const pathname =
                "is_draft" in bill &&
                bill.is_draft &&
                hasPermission("bills_edit_permission", PERMISSION_LEVEL.RESTRICTED)
                    ? billUpdateRoute
                    : billDetailsRoute
            return replaceSlugs(pathname, { id: bill.id })
        },
        Vendor: (vendor: Vendor | VendorIndex) => {
            return replaceSlugs(vendorDetailsRoute, { id: vendor.id })
        },
        JobSeries: (series: JobSeries | JobSeriesIndex) => {
            const pathname = replaceSlugs(jobSeriesDetailsRoute, { id: series.id })
            const params = new URLSearchParams()
            params.set("phase", "open")
            params.set("tab", "All Open")
            params.set("keywords", "")
            params.set("filter__series", series.id)
            return `${pathname}?${params.toString()}`
        },
        Client: (client: Client | ClientIndex) => {
            return replaceSlugs(clientDetailsRoute, { id: client.id })
        },
        PriceBookItem: (pricebookItem: PricebookItem | PricebookItemIndex) => {
            const pathname = pricebookItem.confirmed ? pricebookItemUpdateRoute : pricebookItemReviewRoute
            const query = `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[pricebookItem.pricebook_item_type]}`
            return `${replaceSlugs(pathname, { id: String(pricebookItem.id) })}${query}`
        },
    }

    if (objectName) {
        return getObjectDetailsRoute[objectName]
    } else {
        return getObjectDetailsRoute
    }
}
