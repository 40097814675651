import dayjs from "dayjs"

import useUser from "@hooks/useUser"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewStates from "./useJobTimelineViewStates"

export default function useJobTimelineViewSchedulerConfig() {
    const { user } = useUser()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { dateRange, visibleDateRange } = useJobTimelineViewStates()

    const configureSchedulerTimezone = () => {
        return new Promise<void>((resolve) => {
            if (user && schedulerPro.current) {
                schedulerPro.current.instance.timeZone = user.service_company.preferred_timezone

                void schedulerPro.current.instance.project.commitAsync().then(() => {
                    resolve()
                })
            }
        })
    }

    const configureSchedulerDates = () => {
        const startDate = dayjs(dateRange.start).startOf("day").toDate()
        const endDate = dayjs(dateRange.end).endOf("day").toDate()

        const schedulerInstance = schedulerPro.current?.instance

        if (schedulerInstance) {
            schedulerInstance.setStartDate(startDate)
            schedulerInstance.setEndDate(endDate)
            schedulerInstance.visibleDate = dayjs(visibleDateRange.start).toDate()
        }
    }

    return {
        configureSchedulerTimezone,
        configureSchedulerDates,
    }
}
