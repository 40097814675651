import { RoomProvider } from "@configs/liveblocks.config"
import Spinner from "@legacy/core/components/Spinner"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Provider as JotaiProvider } from "jotai"
import { ThemeProvider } from "next-themes"
import { Helmet } from "react-helmet"

import useIsDesktop from "@hooks/useIsDesktop"
import useToastQueue from "@hooks/useToastQueue"
import useUser from "@hooks/useUser"

import { Toaster } from "@molecules/Toast/Toast"

import { BottomBar, SideBar, TopBar } from "@organisms"

import { themeNameBySchemeCode } from "@constants/mappings/themeNameBySchemeCode.mapping"

import styles from "./AppFrame.module.scss"
import { AppFrameProps } from "./AppFrame.types"
import AppFramePageContent from "./AppFramePageContent/AppFramePageContent"

const queryClient = new QueryClient()

function AppFrame(props: AppFrameProps) {
    const { children, navigationType = "main", pageId } = props
    const { user } = useUser()
    const { ToastQueueManager } = useToastQueue()

    const isDesktop = useIsDesktop()
    const roomId = user?.service_company?.slug && `${user.service_company.slug}-${pageId}`

    const shouldHideNavigation = navigationType === "none"
    const shouldHideBottomBar = navigationType === "settings" || navigationType === "none" || isDesktop

    const renderNavigation = () => {
        if (shouldHideNavigation || !isDesktop || !user) {
            return null
        } else if (navigationType === "settings") {
            return <SideBar.Settings />
        } else {
            return <SideBar.Main />
        }
    }

    const renderPageContent = () => {
        return (
            <>
                <Helmet>
                    {process.env.FONTAWESOME_KIT_ID && (
                        <script
                            src={`https://kit.fontawesome.com/${process.env.FONTAWESOME_KIT_ID}.js`}
                            crossOrigin="anonymous"
                        />
                    )}
                </Helmet>
                {renderNavigation()}
                <div className={styles.base}>
                    <main>
                        <div className={styles.page}>{children}</div>
                    </main>

                    {!shouldHideBottomBar && <BottomBar />}
                </div>
                <ToastQueueManager />
                <Toaster />
            </>
        )
    }

    if (user) {
        return (
            <ThemeProvider
                defaultTheme={themeNameBySchemeCode[user.color_mode] || "system"}
                disableTransitionOnChange={true}
                attribute="class"
                storageKey="roopairs_theme"
            >
                <QueryClientProvider client={queryClient}>
                    <JotaiProvider>
                        <TopBar.Provider>
                            {roomId ? (
                                <RoomProvider id={roomId} initialPresence={{ online: true }}>
                                    {renderPageContent()}
                                </RoomProvider>
                            ) : (
                                renderPageContent()
                            )}
                        </TopBar.Provider>
                    </JotaiProvider>
                </QueryClientProvider>
            </ThemeProvider>
        )
    } else {
        return (
            <div className={styles.loading}>
                <Spinner />
            </div>
        )
    }
}

const Root = AppFrame
const PageContent = AppFramePageContent

export default { Root, PageContent }
