// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnscheduledJobsBottomSheetTrigger-base__u11WY{position:absolute;bottom:var(--bottom-bar-height);display:flex;flex-shrink:0;align-items:flex-start;justify-content:space-between;width:var(--size-full);height:var(--size-12);padding:var(--size-3) var(--size-4);background:var(--white);border:var(--size-0);border-top:var(--size-0_25) solid var(--gray-3)}.UnscheduledJobsBottomSheetTrigger-headingAndCounter__S8g2v{display:flex;gap:var(--size-1_5);align-items:center;justify-content:center}.UnscheduledJobsBottomSheetTrigger-heading__TLmzV{color:var(--gray-12);text-align:center;font:var(--font-mobile-heading-03);font-feature-settings:var(--feature-settings-mobile-heading-03);letter-spacing:var(--letter-spacing-mobile-heading-03)}.UnscheduledJobsBottomSheetTrigger-icon__WgrGk{color:var(--gray-8)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "UnscheduledJobsBottomSheetTrigger-base__u11WY",
	"headingAndCounter": "UnscheduledJobsBottomSheetTrigger-headingAndCounter__S8g2v",
	"heading": "UnscheduledJobsBottomSheetTrigger-heading__TLmzV",
	"icon": "UnscheduledJobsBottomSheetTrigger-icon__WgrGk"
};
export default ___CSS_LOADER_EXPORT___;
