import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { ColumnDefResolved } from "@tanstack/react-table"
import { Command } from "cmdk"

import generateRandomKey from "@utils/generateRandomKey"

import { Popover } from "@molecules"
import usePopover from "@molecules/Popover/Popover.store"

import useTable from "@organisms/Table/Table.context"

import styles from "./PopoverAddColumns.module.scss"
import { PopoverAddColumnsProps } from "./PopoverAddColumns.types"

export default function PopoverAddColumns(props: PopoverAddColumnsProps) {
    const { isVisible } = props

    const { invisibleColumns, updateColumnVisibility } = useTable()
    const { setContentActive } = usePopover()

    const handleColumnVisibilityChange = (column: ColumnDefResolved<unknown>) => {
        updateColumnVisibility(column.accessorKey as string, true)
    }

    const noColumns = invisibleColumns.length === 0

    const onHeaderBackClick = () => setContentActive(undefined)

    if (noColumns) {
        return (
            <>
                <Popover.Header title="Add Column" showBackButton={true} onBackClick={onHeaderBackClick} />
                <div className={styles.noColumns}>
                    <div className={styles.noColumnsText}>No columns to add</div>
                </div>
            </>
        )
    }

    return (
        <Command>
            <Popover.Header
                title="Add Column"
                onBackClick={onHeaderBackClick}
                showBackButton={true}
                showSearchInput={true}
            />
            <Command.List>
                <Command.Empty>
                    <div className={styles.noColumns}>
                        <div className={styles.noColumnsText}>No columns found</div>
                    </div>
                </Command.Empty>
                <Command.Group className={styles.items}>
                    {invisibleColumns.map((column) => (
                        <Command.Item key={generateRandomKey()} value={column.header}>
                            <Popover.Button
                                icon={icon({
                                    name: "plus",
                                    style: "light",
                                    family: "sharp",
                                })}
                                isTabbable={isVisible}
                                onClick={() => handleColumnVisibilityChange(column)}
                            >
                                {column.header}
                            </Popover.Button>
                        </Command.Item>
                    ))}
                </Command.Group>
            </Command.List>
        </Command>
    )
}
