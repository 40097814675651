import { Component } from 'react';
import WrapperLinkButton from '../../core/buttons/WrapperLinkButton';
import { getJobStatusColorClass, getJobStatusOrSubStatusLabel } from "../../jobs/utils/utils";
import { getUnprefixedJobTitle } from '../utils/utils';


class JobObjectLink extends Component {

    render() {
        const { job, destination, disabled=false } = this.props

        const buttonTitle = job.__str__
        const buttonPillClass = getJobStatusColorClass(job)
        const buttonPillLabel = getJobStatusOrSubStatusLabel(job)
        const buttonLabel = getUnprefixedJobTitle(job.__str__)

        return (
            <WrapperLinkButton type="object_link" destination={destination} openNew={true} title={buttonTitle} ariaLabel="View Job" disabled={disabled}>
                <div className="data-panel__list-select__option__label"><div className={`data-panel__list-select__option__status-pill ${buttonPillClass}`}>{buttonPillLabel}</div><span>{buttonLabel}</span></div><div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperLinkButton>
        )
    }
}

export default JobObjectLink;
