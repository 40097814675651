import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Button, ListInfoGlyph } from "@atoms"

import styles from "@organisms/CardList/CardListEdgeStates/CardListEdgeStates.module.scss"
import useTable from "@organisms/Table/Table.context"

export default function CardListErrorState() {
    const { error, refetch } = useTable()

    return (
        <div className={styles.base}>
            <div className={styles.content}>
                <div className={styles.copyWrap}>
                    <ListInfoGlyph variant="dangerous" icon={icon({ name: "bug", style: "solid", family: "sharp" })} />
                    <div className={styles.copy}>
                        <h2 className={styles.title}>Hmm, something went wrong...</h2>
                        <p className={styles.explanation}>{error?.message}</p>
                    </div>
                </div>

                <Button colorScheme="gray" size="md" variant="outline" onClick={() => void refetch()}>
                    Try Again
                </Button>
            </div>
        </div>
    )
}
