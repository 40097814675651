import ButtonGroup from "@legacy/core/buttons/ButtonGroup"
import ButtonGroupRow from "@legacy/core/buttons/ButtonGroupRow"
import UniversalButton from "@legacy/core/buttons/UniversalButton"
import ListSelectField from "@legacy/core/fields/ListSelectField"
import { Component, Fragment } from "react"
import deepcopy from "rfdc"

import { PriceBookItemTypes } from "@constants/choices"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import PriceOverrideListEditSelect from "../components/PriceOverrideListEditSelect"

class PriceBookClientOverridesForm extends Component {
    constructor(props) {
        super(props)

        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            priceBookOverridesData: null,
            errors: {
                priceBookOverridesDataErrors: null,
            },
        }
    }

    componentDidMount = async () => {
        if (this.state.priceBookOverridesData === null) {
            const priceBookOverridesEndpoint =
                DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG) +
                `?external_client=${this.props.client.id}`
            try {
                const priceBookOverridesResponse = await fetch(priceBookOverridesEndpoint)
                const priceBookOverrides = await priceBookOverridesResponse.json()

                const priceBookOverridesServices = priceBookOverrides.filter(
                    (override) => override.pricebook_item.pricebook_item_type === PriceBookItemTypes.service,
                )
                const priceBookOverridesParts = priceBookOverrides.filter(
                    (override) => override.pricebook_item.pricebook_item_type === PriceBookItemTypes.part,
                )
                const priceBookOverridesOthers = priceBookOverrides.filter(
                    (override) => override.pricebook_item.pricebook_item_type === PriceBookItemTypes.other,
                )
                const priceBookOverridesDiscounts = priceBookOverrides.filter(
                    (override) => override.pricebook_item.pricebook_item_type === PriceBookItemTypes.discount,
                )

                this.setState((state) => {
                    return {
                        ...state,
                        priceBookOverridesData: {
                            service_charges: priceBookOverridesServices,
                            parts: priceBookOverridesParts,
                            other_charges: priceBookOverridesOthers,
                            discounts: priceBookOverridesDiscounts,
                        },
                    }
                })
            } catch (error) {
                this.setState((state) => {
                    return {
                        ...state,
                        errors: {
                            priceBookOverridesDataErrors: error,
                        },
                    }
                })
            }
        }
    }

    updateOverride = (type, index, updatedItem) => {
        this.setState((state) => {
            const updatedpriceBookOverridesData = { ...state.priceBookOverridesData }
            updatedpriceBookOverridesData[type][index] = updatedItem

            return { priceBookOverridesData: updatedpriceBookOverridesData }
        })
    }

    deleteOverride = (type, item) => {
        this.setState((state) => {
            const updatedpriceBookOverridesData = { ...state.priceBookOverridesData }
            updatedpriceBookOverridesData[type] = updatedpriceBookOverridesData[type].filter(
                (data) => data.pricebook_item.id !== item.pricebook_item.id,
            )

            return { priceBookOverridesData: updatedpriceBookOverridesData }
        })
    }

    render = () => {
        const { client, formatCurrencyValue, nonFieldErrorMessage } = this.props

        const newServiceChargeExistsInList = (this.state.priceBookOverridesData?.service_charges || []).some(
            (item) => item.__new__ || false,
        )
        const newPartExistsInList = (this.state.priceBookOverridesData?.parts || []).some(
            (item) => item.__new__ || false,
        )
        const newOtherExistsInList = (this.state.priceBookOverridesData?.other_charges || []).some(
            (item) => item.__new__ || false,
        )
        const newDiscountExistsInList = (this.state.priceBookOverridesData?.discounts || []).some(
            (item) => item.__new__ || false,
        )

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Client Deactivate">
                    <div className="data-panel__form" aria-label="Client Deactivate Form">
                        <Fragment>
                            <div
                                className="data-panel__form__field"
                                id="div_id_line_items"
                                aria-label="Line Item Errors"
                                style={
                                    this.state.errors.priceBookOverridesDataErrors
                                        ? { display: "initial" }
                                        : { display: "none" }
                                }
                            >
                                {this.state.errors.priceBookOverridesDataErrors && (
                                    <div
                                        className="data-panel__form__field__errors text-centered"
                                        aria-label="Field Errors"
                                    >
                                        {nonFieldErrorMessage}
                                    </div>
                                )}
                            </div>
                            <ListSelectField
                                fieldName="service_charges"
                                fieldLabel="Service Charge Overrides"
                                inputComponent={
                                    <PriceOverrideListEditSelect
                                        fieldName="service"
                                        formatCurrencyValue={formatCurrencyValue}
                                        currencySymbol={this.props.currencySymbol}
                                        priceBookOverridesData={
                                            this.state.priceBookOverridesData?.service_charges || []
                                        }
                                        updateOverride={(index, item) =>
                                            this.updateOverride("service_charges", index, item)
                                        }
                                        deleteOverride={(item) => this.deleteOverride("service_charges", item)}
                                        addToastToQueue={this.addToastToQueue}
                                        client={this.props.client}
                                    />
                                }
                                showButton={!newServiceChargeExistsInList && window.CURRENT_USER?.permissions.pricebook_edit_permission >= PERMISSION_LEVEL.FULL}
                                buttonLabel="Add Service Charge Override"
                                buttonAction={(event) => {
                                    let newOverride = deepcopy()(
                                        this.state.priceBookOverridesData?.service_charges || [],
                                    )
                                    newOverride.push({
                                        external_client: client.id,
                                        pricebook_item: {
                                            pricebook_item_type: PriceBookItemTypes.service,
                                        },
                                        price: "",
                                        __new__: true,
                                    })

                                    this.setState((state, props) => {
                                        let updatedState = state
                                        updatedState.priceBookOverridesData.service_charges = newOverride
                                        return updatedState
                                    })
                                }}
                                errors={this.state.errors}
                            ></ListSelectField>
                            <ListSelectField
                                fieldName="parts"
                                fieldLabel="Part or Material Overrides"
                                inputComponent={
                                    <PriceOverrideListEditSelect
                                        fieldName="part"
                                        formatCurrencyValue={formatCurrencyValue}
                                        currencySymbol={this.props.currencySymbol}
                                        priceBookOverridesData={this.state.priceBookOverridesData?.parts || []}
                                        updateOverride={(index, item) => this.updateOverride("parts", index, item)}
                                        deleteOverride={(item) => this.deleteOverride("parts", item)}
                                        addToastToQueue={this.addToastToQueue}
                                        client={this.props.client}
                                    />
                                }
                                showButton={!newPartExistsInList && window.CURRENT_USER?.permissions.pricebook_edit_permission >= PERMISSION_LEVEL.FULL}
                                buttonLabel="Add Part or Material Override"
                                buttonAction={(event) => {
                                    let newOverride = deepcopy()(this.state.priceBookOverridesData?.parts || [])
                                    newOverride.push({
                                        external_client: client.id,
                                        pricebook_item: {
                                            pricebook_item_type: PriceBookItemTypes.part,
                                        },
                                        price: "",
                                        __new__: true,
                                    })

                                    this.setState((state, props) => {
                                        let updatedState = state
                                        updatedState.priceBookOverridesData.parts = newOverride
                                        return updatedState
                                    })
                                }}
                                errors={this.state.errors}
                            ></ListSelectField>
                            <ListSelectField
                                fieldName="other_charges"
                                fieldLabel="Misc. Charge Overrides"
                                inputComponent={
                                    <PriceOverrideListEditSelect
                                        fieldName="other"
                                        formatCurrencyValue={formatCurrencyValue}
                                        currencySymbol={this.props.currencySymbol}
                                        priceBookOverridesData={this.state.priceBookOverridesData?.other_charges || []}
                                        updateOverride={(index, item) =>
                                            this.updateOverride("other_charges", index, item)
                                        }
                                        deleteOverride={(item) => this.deleteOverride("other_charges", item)}
                                        addToastToQueue={this.addToastToQueue}
                                        client={this.props.client}
                                    />
                                }
                                showButton={!newOtherExistsInList && window.CURRENT_USER?.permissions.pricebook_edit_permission >= PERMISSION_LEVEL.FULL}
                                buttonLabel="Add Misc. Charge Override"
                                buttonAction={(event) => {
                                    let newOverride = deepcopy()(
                                        this.state.priceBookOverridesData?.other_charges || [],
                                    )
                                    newOverride.push({
                                        external_client: client.id,
                                        pricebook_item: {
                                            pricebook_item_type: PriceBookItemTypes.other,
                                        },
                                        price: "",
                                        __new__: true,
                                    })

                                    this.setState((state, props) => {
                                        let updatedState = state
                                        updatedState.priceBookOverridesData.other_charges = newOverride
                                        return updatedState
                                    })
                                }}
                                errors={this.state.errors}
                            ></ListSelectField>
                            <ListSelectField
                                fieldName="discounts"
                                fieldLabel="Discount Overrides"
                                inputComponent={
                                    <PriceOverrideListEditSelect
                                        fieldName="discount"
                                        formatCurrencyValue={formatCurrencyValue}
                                        currencySymbol={this.props.currencySymbol}
                                        priceBookOverridesData={this.state.priceBookOverridesData?.discounts || []}
                                        updateOverride={(index, item) => this.updateOverride("discounts", index, item)}
                                        deleteOverride={(item) => this.deleteOverride("discounts", item)}
                                        addToastToQueue={this.addToastToQueue}
                                        client={this.props.client}
                                    />
                                }
                                showButton={!newDiscountExistsInList && window.CURRENT_USER?.permissions.pricebook_edit_permission >= PERMISSION_LEVEL.FULL}
                                buttonLabel="Add Discount Override"
                                buttonAction={(event) => {
                                    let newOverride = deepcopy()(this.state.priceBookOverridesData?.discounts || [])
                                    newOverride.push({
                                        external_client: client.id,
                                        pricebook_item: {
                                            pricebook_item_type: PriceBookItemTypes.discount,
                                        },
                                        price: "",
                                        __new__: true,
                                    })

                                    this.setState((state, props) => {
                                        let updatedState = state
                                        updatedState.priceBookOverridesData.discounts = newOverride
                                        return updatedState
                                    })
                                }}
                                errors={this.state.errors}
                            ></ListSelectField>
                        </Fragment>
                    </div>
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton
                                type="secondary"
                                text="Return to Client"
                                handler={(event) => this.props.switchToPrimaryForm()}
                            />
                        </ButtonGroupRow>
                    </ButtonGroup>
                </div>
            </div>
        )
    }
}

export default PriceBookClientOverridesForm
