import { Fragment } from "react";
import SearchOrCreateSelectInput from "../../core/inputs/SearchOrCreateSelectInput";
import { PriceBookItemTypes } from "../../core/utils/enums";
import { getPriceBookItemPartContext } from "../utils/utils";


function getPriceBookServiceObjectLabel(priceBookItem) {
    return <Fragment>
        <span><strong>{priceBookItem.description}</strong><br></br></span>
        {getPriceBookItemPartContext(priceBookItem) && <span className="select-label-secondary-level"><em>{getPriceBookItemPartContext(priceBookItem)}</em><br></br></span>}
        {priceBookItem.labels && priceBookItem.labels.length !== 0 && <span className="select-label-secondary-level">{priceBookItem.labels.map((label) => label.name).join(", ")}</span>}
    </Fragment>
}

function priceBookServiceToPriceBookServiceOption(priceBookItem) {
    return {value: priceBookItem.id, label: getPriceBookServiceObjectLabel(priceBookItem), object: priceBookItem}
}

function PriceBookServiceSearchOrCreateSelect(props) {
    return <SearchOrCreateSelectInput
        objectName="Service"
        getObjectLabel={getPriceBookServiceObjectLabel}
        listEndpoint={DjangoUrls["pricebook:api-pricebookitem-lightweight-list"](window.MARKETPLACE_ENTITY_SLUG)}
        listEndpointKwargs={[
            ["pricebook_item_type", PriceBookItemTypes.service],
            ["is_active", true],
            ["confirmed", true],
            ["ordering", "description"],
        ]}
        getDetailsEndpoint={(priceBookItem) => DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, priceBookItem.id)}
        {...props}
    />
}

export { priceBookServiceToPriceBookServiceOption };
export default PriceBookServiceSearchOrCreateSelect
