import { BryntumDateField } from "@bryntum/schedulerpro-react"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import dayjs from "dayjs"
import { useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import formatDate from "@utils/formatDate"

import { AccessCheck } from "@particles/index"

import { Badge, Button, Icon, IconButton, Tooltip } from "@atoms"

import TimelineViewMenu from "@pages/Jobs/JobList/views/JobTimelineView/TimelineViewMenu/TimelineViewMenu"
import UnscheduledJobsHeader from "@pages/Jobs/JobList/views/JobTimelineView/UnscheduledJobsHeader/UnscheduledJobsHeader"
import useJobTimelineViewData from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewData"
import useJobTimelineViewEventHandlers from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useJobTimelineViewStates from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewStates"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import styles from "./JobTimelineViewControls.module.scss"
import JobTimelineViewZoom from "./JobTimelineViewZoom/JobTimelineViewZoom"

export default function JobTimelineViewControls() {
    const isDesktop = useIsDesktop()

    const {
        visibleDateRange,
        unscheduledJobsColumnStatus,
        totalOfUnscheduledJobs,
        expandUnscheduledJobsColumn,
        timelineViewOrientation,
        timeFrameType,
    } = useJobTimelineViewStates()

    const nextDate = new Date(visibleDateRange.start)
    const previousDate = new Date(visibleDateRange.start)
    nextDate.setDate(visibleDateRange.start.getDate() + 1)
    previousDate.setDate(visibleDateRange.start.getDate() - 1)

    const { onTopBarDateFieldChange, onTopBarPreviousButtonClick, onTopBarNextButtonClick, onTopBarNowButtonClick } =
        useJobTimelineViewEventHandlers()

    const { isLoading } = useJobTimelineViewData()

    const { localDateString: currentDateString } = formatDate({
        datetime: visibleDateRange.start,
        preferredTimezone: undefined,
        short: true,
    })

    const { localDateString: currentEndDateString } = formatDate({
        datetime: visibleDateRange.end,
        preferredTimezone: undefined,
        short: true,
    })

    const { localDateString: previousDateString } = formatDate({
        datetime: previousDate,
        preferredTimezone: undefined,
        short: true,
    })

    const { localDateString: nextDateString } = formatDate({
        datetime: nextDate,
        preferredTimezone: undefined,
        short: true,
    })

    const datePickerButtonContent = useMemo(() => {
        if (timeFrameType === "day") {
            return currentDateString
        } else if (timeFrameType === "threeDays" || timeFrameType === "week") {
            return `${currentDateString} - ${currentEndDateString}`
        } else if (timeFrameType === "month") {
            return dayjs(visibleDateRange.start).format("MMMM YYYY")
        }
    }, [timeFrameType, currentDateString, currentEndDateString])

    const previousDateButtonLabel = useMemo(() => {
        if (timeFrameType === "day") {
            return previousDateString
        } else if (timeFrameType === "threeDays") {
            return "Previous 3 days"
        } else if (timeFrameType === "week") {
            return "Previous week"
        } else if (timeFrameType === "month") {
            return "Previous month"
        }
    }, [previousDateString])

    const nextDateButtonLabel = useMemo(() => {
        if (timeFrameType === "day") {
            return nextDateString
        } else if (timeFrameType === "threeDays") {
            return "Next 3 days"
        } else if (timeFrameType === "week") {
            return "Next week"
        } else if (timeFrameType === "month") {
            return "Next month"
        }
    }, [nextDateString])

    return (
        <div className={styles.base}>
            <div className={styles.controls}>
                <div className={styles.navControls}>
                    <Button
                        onClick={() => null}
                        colorScheme="gray"
                        size={isDesktop ? "sm" : "md"}
                        variant="outline"
                        leftIcon={icon({
                            name: "calendar",
                            family: "sharp",
                            style: "regular",
                        })}
                        className={styles.datePicker}
                    >
                        <span className={styles.stringDate}>{datePickerButtonContent}</span>
                        <BryntumDateField
                            type="datefield"
                            editable={false}
                            format="MMM D"
                            value={visibleDateRange.start}
                            inputType="button"
                            cls={styles.bryntumDateField}
                            onChange={onTopBarDateFieldChange}
                            hidden={true}
                        />
                    </Button>
                    <div className={styles.previousAndNextDays}>
                        <Tooltip label={previousDateButtonLabel}>
                            <IconButton
                                icon={icon({
                                    name: "angle-left",
                                    style: "regular",
                                    family: "sharp",
                                })}
                                onClick={onTopBarPreviousButtonClick}
                                colorScheme="gray"
                                size={isDesktop ? "sm" : "md"}
                                variant="ghost"
                                iconRotation={timelineViewOrientation === "vertical" ? 90 : undefined}
                            />
                        </Tooltip>
                        <Tooltip label={nextDateButtonLabel}>
                            <IconButton
                                icon={icon({
                                    name: "angle-right",
                                    style: "regular",
                                    family: "sharp",
                                })}
                                onClick={onTopBarNextButtonClick}
                                colorScheme="gray"
                                size={isDesktop ? "sm" : "md"}
                                variant="ghost"
                                iconRotation={timelineViewOrientation === "vertical" ? 90 : undefined}
                            />
                        </Tooltip>
                    </div>

                    <Button
                        colorScheme="gray"
                        size={isDesktop ? "sm" : "md"}
                        variant="outline"
                        onClick={onTopBarNowButtonClick}
                    >
                        Now
                    </Button>
                    {isLoading && (
                        <div className={styles.spinner}>
                            <Icon
                                icon={icon({
                                    name: "spinner-third",
                                })}
                                spin={true}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.viewControls}>
                    <JobTimelineViewZoom />
                    <TimelineViewMenu />
                    <AccessCheck
                        permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                        }}
                    >
                        {unscheduledJobsColumnStatus === "expanded" || !isDesktop ? null : (
                            <Button
                                colorScheme="gray"
                                size={isDesktop ? "sm" : "md"}
                                variant="outline"
                                leftIcon={icon({
                                    name: "arrow-left-to-line",
                                    style: "regular",
                                    family: "sharp",
                                })}
                                onClick={expandUnscheduledJobsColumn}
                                noDebounce={true}
                            >
                                <div className={styles.badgeContainer}>
                                    Unscheduled Jobs
                                    <Badge
                                        colorScheme={totalOfUnscheduledJobs === 0 ? "gray" : "tomato"}
                                        size="md"
                                        type="counter"
                                        variant="subtle"
                                    >
                                        {totalOfUnscheduledJobs.toString()}
                                    </Badge>
                                </div>
                            </Button>
                        )}
                    </AccessCheck>
                </div>
            </div>
            <UnscheduledJobsHeader />
        </div>
    )
}
