import { Breadcrumb } from "@organisms/index"

import { Settings } from "@templates/index"

import JobsAssignmentNotificationsForm from "@pages/Settings/SettingsNotifications/components/JobsNotificationsForm/JobsAssignmentNotificationsForm"
import JobsManagerNotificationsForm from "@pages/Settings/SettingsNotifications/components/JobsNotificationsForm/JobsManagerNotificationsForm"

import { SETTINGS_ROUTES } from "@routes/settings"

export default function SettingsNotificationsJobs() {
    return (
        <Settings.Wrapper title="Jobs" backDestination={SETTINGS_ROUTES.notifications}>
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item
                        label="Notifications"
                        showSlash={true}
                        isActive={false}
                        path={SETTINGS_ROUTES.notifications}
                    />
                    <Breadcrumb.Item label="Jobs" path={SETTINGS_ROUTES.notificationsJobs} />
                </Settings.Breadcrumbs>
                <Settings.PageHeader
                    variant="page-heading"
                    title="Jobs"
                    subtitle="Select the topics for which you'd like to receive notifications."
                />

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Assignment"
                        subtitle="Notifications regarding jobs you are assigned."
                    />
                    <JobsAssignmentNotificationsForm />
                </Settings.Section>

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Manager updates"
                        subtitle="Notifications regarding job in your workspace."
                    />
                    <JobsManagerNotificationsForm />
                </Settings.Section>
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
