import { RotateProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { Icon, TabItem } from "@atoms/index"

import useTable from "@organisms/Table/Table.context"

import { TableViewPhaseProps } from "./TableViewPhase.types"

const icons = {
    active: {
        icon: icon({ name: "circle-minus", style: "light", family: "sharp" }),
        iconOnActive: icon({ name: "circle-minus", style: "regular", family: "sharp" }),
        rotation: 90 as RotateProp,
    },
    inactive: {
        icon: icon({ name: "circle", style: "light", family: "sharp" }),
        iconOnActive: icon({ name: "circle", style: "regular", family: "sharp" }),
        rotation: undefined,
    },
    open: {
        icon: icon({ name: "circle-dashed", style: "light", family: "sharp" }),
        iconOnActive: icon({ name: "circle-dashed", style: "regular", family: "sharp" }),
        rotation: undefined,
    },
    closed: {
        icon: icon({ name: "archive", style: "light", family: "sharp" }),
        iconOnActive: icon({ name: "archive", style: "regular", family: "sharp" }),
        rotation: undefined,
    },
}

export default function TableViewPhase(props: TableViewPhaseProps) {
    const { phase, title } = props

    const { setActivePhase, activePhase } = useTable()

    return (
        <TabItem
            iconInReactNode={
                <Icon
                    icon={icons[phase].icon}
                    iconOnActive={icons[phase].iconOnActive}
                    rotation={icons[phase].rotation}
                    isActive={true}
                />
            }
            isPressed={phase === activePhase}
            onClick={() => setActivePhase(phase)}
            noDebounce={true}
            title={title}
        />
    )
}
