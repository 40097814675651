import { Component, Fragment } from "react";
import ListSelectField from "../fields/ListSelectField";
import LineItemListSelect from "./LineItemListSelect";


class LineItemListSelectFieldGroup extends Component {

    render = () => {
        const {
            object,
            labelPrefix="",
            formatCurrencyValue,
            showSummaryHint=false,
            showQuickBooksItemSelect=false,
            useTaxes=false,
            switchToSecondaryForm,
            errors,
            nonFieldErrorMessage,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isBillLineItem=false,
        } = this.props

        return (
            <Fragment>
                <div className="data-panel__form__field" id="div_id_line_items" aria-label="Line Item Errors" style={errors.line_items ?  {"display": "initial"} : {"display": "none"}}>
                    {errors.line_items && <div className="data-panel__form__field__errors text-centered" aria-label="Field Errors">{nonFieldErrorMessage}</div>}
                </div>
                <ListSelectField
                    fieldName="service_charges"
                    fieldLabel={`${labelPrefix}Service Charges`}
                    inputComponent={
                        <LineItemListSelect
                            fieldName="service_charges"
                            onSelect={data => switchToSecondaryForm("EDIT_SERVICE_CHARGE", data, null)}
                            parent={object}
                            items={object.service_charges || []}
                            formatCurrencyValue={formatCurrencyValue}
                            showSummaryHint={showSummaryHint}
                            showQuickBooksItemSelect={showQuickBooksItemSelect}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                        ></LineItemListSelect>
                    }
                    showButton={true}
                    buttonLabel="Add Service Charge"
                    buttonAction={event => switchToSecondaryForm("ADD_SERVICE_CHARGE", null, null)}
                    errors={errors}
                ></ListSelectField>
                <ListSelectField
                    fieldName="parts"
                    fieldLabel={`${labelPrefix}Parts and Materials`}
                    inputComponent={
                        <LineItemListSelect
                            fieldName="parts"
                            onSelect={data => switchToSecondaryForm("EDIT_PART", data, null)}
                            parent={object}
                            items={object.parts || []}
                            formatCurrencyValue={formatCurrencyValue}
                            showQuickBooksItemSelect={showQuickBooksItemSelect}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                        ></LineItemListSelect>
                    }
                    showButton={true}
                    buttonLabel="Add Part or Material"
                    buttonAction={event => switchToSecondaryForm("ADD_PART", null, null)}
                    optional={true}
                    optionalText="if any"
                    errors={errors}
                ></ListSelectField>
                <ListSelectField
                    fieldName="other_charges"
                    fieldLabel={`${labelPrefix}Trip Charges, Diagnostic Fees, etc.`}
                    inputComponent={
                        <LineItemListSelect
                            fieldName="other_charges"
                            onSelect={data => switchToSecondaryForm("EDIT_OTHER_CHARGE", data, null)}
                            parent={object}
                            items={object.other_charges || []}
                            formatCurrencyValue={formatCurrencyValue}
                            showQuickBooksItemSelect={showQuickBooksItemSelect}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                        ></LineItemListSelect>
                    }
                    showButton={true}
                    buttonLabel="Add Miscellaneous Charge"
                    buttonAction={event => switchToSecondaryForm("ADD_OTHER_CHARGE", null, null)}
                    optional={true}
                    optionalText="if any"
                    errors={errors}
                ></ListSelectField>
                <ListSelectField
                    fieldName="discounts"
                    fieldLabel={`${labelPrefix}Discounts`}
                    inputComponent={
                        <LineItemListSelect
                            fieldName="discounts"
                            onSelect={data => switchToSecondaryForm("EDIT_DISCOUNT", data, null)}
                            parent={object}
                            items={object.discounts || []}
                            formatCurrencyValue={formatCurrencyValue}
                            showQuickBooksItemSelect={showQuickBooksItemSelect}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                        ></LineItemListSelect>
                    }
                    showButton={true}
                    buttonLabel="Add Discount"
                    buttonAction={event => switchToSecondaryForm("ADD_DISCOUNT", null, null)}
                    optional={true}
                    optionalText="if any"
                    errors={errors}
                ></ListSelectField>
            </Fragment>
        )
    }
}

export default LineItemListSelectFieldGroup;
