import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import BasicSelectField from "@legacy/core/fields/BasicSelectField";
import { Component, Fragment } from "react";
import ContactSearchOrCreateSelect, { contactToContactOption } from "../../../clients/inputs/ContactSearchOrCreateSelect";
import AttachmentUploadField from "../../../core/fields/AttachmentUploadField";
import BooleanField from "../../../core/fields/BooleanField";
import SearchCreateSelectField from "../../../core/fields/SearchCreateSelectField";
import TagField from "../../../core/fields/TagField";
import TextField from "../../../core/fields/TextField";
import TagSearchInput from "../../../core/inputs/TagSearchInput";
import { valueIsDefined } from "../../../core/utils/utils";
import EquipmentSearchOrCreateSelect, { equipmentToEquipmentOption } from "../../../equipment/inputs/EquipmentSearchOrCreateSelect";

class JobEditableDetailsFields extends Component {

    constructor(props) {
        super(props)

        this.state = {
            serviceSearchInput: "",
            reporterSearchInput: "",
            pointOfContactSearchInput: "",
            equipmentSearchInput: "",
        }
    }

    render() {
        const {
            mode,
            submitting,
            job,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateReporterSelection,
            updatePointOfContactSelection,
            updateEquipmentSelection,
            selectedClient,
            selectedServiceLocation,
            selectedReporter,
            selectedPointOfContact,
            selectedEquipment,
            defaultCountryCode,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            priorityLevelsOptions,
        } = this.props

        return (
            <Fragment>
                <TextField
                    fieldName="details"
                    fieldLabel={job.is_callback ? "Reason For Callback" : "Reason For Call"}
                    fieldValue={job.details || ""}
                    fieldOnChange={details => onFormDataChange("details", details || "")}
                    rows={3}
                    placeholder="Customer states..."
                    errors={errors}
                ></TextField>
                <TextField
                    fieldName="dispatcher_notes"
                    fieldLabel="Technician Instructions"
                    fieldValue={job.dispatcher_notes || ""}
                    fieldOnChange={dispatcher_notes => onFormDataChange("dispatcher_notes", dispatcher_notes || "")}
                    rows={3}
                    placeholder={`Notes specific to this ${job.is_job_walk ? "job walk" : "service"}. e.g. 'Ask client about replacing the unit.' or 'Make sure to collect payment on site.'`}
                    optional={true}
                    errors={errors}
                ></TextField>
                <BasicSelectField
                    fieldName="priority"
                    fieldLabel="Priority"
                    fieldValue={job.priority}
                    fieldOnChange={priority => onFormDataChange("priority", priority)}
                    choices={priorityLevelsOptions}
                    errors={errors}
                ></BasicSelectField>
                <TagField
                    fieldName="labels"
                    fieldLabel="Labels"
                    inputComponent={
                        <TagSearchInput
                            defaultSelectedTags={job.labels?.map(label => label.name)}
                            onSelectionChange={labelNames => onFormDataChange("labels", labelNames ? labelNames.map(labelName => ({"name": labelName})) : [])}
                            choicesEndpoint={DjangoUrls["jobs:api-jobs-labels-list"](window.MARKETPLACE_ENTITY_SLUG)}
                            placeholder="Add Labels..."
                            noOptionsMessage="No Labels Found"
                            isMulti={true}
                        ></TagSearchInput>
                    }
                    optional={true}
                    errors={errors}
                ></TagField>
                <BooleanField
                    fieldName="equipment_expected"
                    fieldLabel="Equipment Expected"
                    fieldValue={job.equipment_expected}
                    fieldOnChange={equipment_expected => {onFormDataChange("equipment_expected", equipment_expected); equipment_expected === false ? updateEquipmentSelection([]) : null}}
                    inputLabel="Is Equipment involved on this Job?"
                    errors={errors}
                ></BooleanField>
                {
                    job.equipment_expected && (
                        <SearchCreateSelectField
                            fieldName="equipment"
                            fieldLabel="Equipment"
                            fieldValue={(selectedEquipment.length !== 0 ? selectedEquipment.map(equipment => equipment.display_name).join(", ") : "")}
                            inputComponent={
                                <EquipmentSearchOrCreateSelect
                                    disabled={window.CURRENT_USER?.permissions.equipment_list_permission < PERMISSION_LEVEL.RESTRICTED}
                                    isMulti={true}
                                    refetchSelection={false}
                                    onSelectionChange={selectedOptions => updateEquipmentSelection((selectedOptions || []).map(option => option.object))}
                                    onInputChange={(input, action) => {action.action === "input-change" && this.setState({equipmentSearchInput: input})}}
                                    defaultSelected={selectedEquipment.map(equipment => equipmentToEquipmentOption(equipment))}
                                    serviceLocation={selectedServiceLocation}
                                    showCreateButton={window.CURRENT_USER?.permissions.equipment_create_permission >= PERMISSION_LEVEL.FULL}
                                    onCreateClick={event => switchToSecondaryForm("ADD_EQUIPMENT", null, {display_name: this.state.equipmentSearchInput})}
                                ></EquipmentSearchOrCreateSelect>
                            }
                            showButton={window.CURRENT_USER?.permissions.equipment_list_permission >= PERMISSION_LEVEL.FULL}
                            buttonLabel={<Fragment>View/Edit Logged Equipment{"  "}<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></Fragment>}
                            buttonAction={event => window.open(DjangoUrls["clients:clients-equipment"](window.MARKETPLACE_ENTITY_SLUG, selectedClient.id) + `?service_location_id=${selectedServiceLocation.id}`, "_blank")}
                            errors={errors}
                        ></SearchCreateSelectField>
                    )
                }
                <SearchCreateSelectField
                    fieldName="reporter"
                    fieldLabel={`Who called in this ${job.is_job_walk ? "job walk" : "service"}?`}
                    fieldValue={selectedReporter ? selectedReporter.name : null}
                    inputComponent={
                        <ContactSearchOrCreateSelect
                            disabled={window.CURRENT_USER?.permissions.clients_view_permission < PERMISSION_LEVEL.FULL}
                            fieldName="reporter"
                            onSelectionChange={selectedOption => updateReporterSelection(selectedOption != null ? selectedOption.object : null)}
                            onInputChange={(input, action) => {action.action === "input-change" && this.setState({reporterSearchInput: input})}}
                            defaultSelected={selectedReporter ? contactToContactOption(selectedReporter, null) : null}
                            client={selectedClient}
                            serviceLocation={selectedServiceLocation}
                            showCreateButton={window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                            onCreateClick={event => switchToSecondaryForm("ADD_CONTACT", null, {name: this.state.reporterSearchInput, populationRef: "updateReporterSelection"})}
                        ></ContactSearchOrCreateSelect>
                    }
                    showButton={selectedReporter !== null && valueIsDefined(selectedReporter.id) && !valueIsDefined(selectedReporter.__isNonExistant__) && window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                    buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Contact</Fragment>}
                    buttonAction={event => switchToSecondaryForm("EDIT_CONTACT", selectedReporter, {populationRef: "updateReporterSelection", dataRef: "selectedReporter"})}
                    errors={errors}
                ></SearchCreateSelectField>
                <SearchCreateSelectField
                    fieldName="point_of_contact"
                    fieldLabel="Who should the tech(s) ask for?"
                    fieldValue={selectedPointOfContact ? selectedPointOfContact.name : null}
                    inputComponent={
                        <ContactSearchOrCreateSelect
                            disabled={window.CURRENT_USER?.permissions.clients_view_permission < PERMISSION_LEVEL.FULL}
                            fieldName="point_of_contact"
                            onSelectionChange={selectedOption => updatePointOfContactSelection(selectedOption != null ? selectedOption.object : null)}
                            onInputChange={(input, action) => {action.action === "input-change" && this.setState({pointOfContactSearchInput: input})}}
                            defaultSelected={selectedPointOfContact ? contactToContactOption(selectedPointOfContact, null) : null}
                            client={selectedClient}
                            serviceLocation={selectedServiceLocation}
                            showCreateButton={window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                            onCreateClick={event => switchToSecondaryForm("ADD_CONTACT", null, {name: this.state.pointOfContactSearchInput, populationRef: "updatePointOfContactSelection"})}
                        ></ContactSearchOrCreateSelect>
                    }
                    showButton={selectedPointOfContact !== null && valueIsDefined(selectedPointOfContact.id) && !valueIsDefined(selectedPointOfContact.__isNonExistant__) && window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                    buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Contact</Fragment>}
                    buttonAction={event => switchToSecondaryForm("EDIT_CONTACT", selectedPointOfContact, {populationRef: "updatePointOfContactSelection", dataRef: "selectedPointOfContact"})}
                    errors={errors}
                ></SearchCreateSelectField>
                {
                    window.FILE_UPLOADS_ENABLED && (
                        <AttachmentUploadField
                            fieldName="attachments"
                            fieldLabel="Attachments"
                            attachments={job.attachments}
                            fileStackAPIKey={fileStackAPIKey}
                            fileStackPolicy={fileStackPolicy}
                            fileStackSignature={fileStackSignature}
                            onUploadDone={(response) => updateAttachments(
                                response.filesUploaded.map(file => ({
                                    "upload_filename": file.filename,
                                    "upload_id": file.uploadId,
                                    "upload_handle": file.handle,
                                    "upload_url": file.url,
                                    "upload_mimetype": file.mimetype,
                                    "upload_size": file.size,
                                    "upload_source": file.source
                                }))
                            )}
                            optional={true}
                            optionalText={"if any"}
                            errors={errors}
                        />
                    )
                }
            </Fragment>
        )
    }
}

export default JobEditableDetailsFields;
