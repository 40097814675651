import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { JOBS_ROUTES } from "@routes/jobs"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    JOBS_SERIES_LIST_CONTROLS_ACTIVE_TAB,
    JOBS_SERIES_LIST_CONTROLS_KEYWORD_SEARCH,
    JOBS_SERIES_LIST_CONTROLS_ORDERING,
    JOBS_SERIES_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { JobSeriesTableViewTabs } from "./JobSeriesTableViewTabs"

export default function JobSeriesTableView() {
    const [createRoute] = useSlugExtractor([JOBS_ROUTES.CREATE])

    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={JOBS_SERIES_LIST_CONTROLS_PHASE}
            activeTabStorageKey={JOBS_SERIES_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={JOBS_SERIES_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={JOBS_SERIES_LIST_CONTROLS_ORDERING}
            listTitle="Job Series"
            objectType="job_series"
            objectCreateRoute={createRoute}
            objectIndexName="JobSeries"
            objectName="Job Series"
            defaultActivePhase="open"
            isRowClickable={hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("jobs_list_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Opem" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <JobSeriesTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<JobSeries>
                    accessorKey="service_name"
                    header="Service"
                    size={224}
                    cell={({ getValue }) => {
                        return <Table.Cells.TruncatedTextCell content={getValue()} />
                    }}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<JobSeries>
                    accessorKey="client_name"
                    header="Client"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<JobSeries>
                    accessorKey="service_location_name"
                    header="Location Name"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<JobSeries>
                    accessorKey="service_location_address"
                    header="Location Address"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<JobSeries>
                    accessorKey="recurrence_start"
                    header="Recurrence Start"
                    size={176}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<JobSeries>
                    accessorKey="recurrence_end"
                    header="Recurrence End"
                    size={176}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<JobSeries>
                    accessorKey="recurrence"
                    header="Recurrence"
                    size={288}
                    cell={({ row }) => (
                        <Table.Cells.RecurrenceCell
                            interval={row.original.recurrence_interval}
                            frequency={row.original.recurrence_frequency}
                            startDate={row.original.recurrence_start}
                            weeklyRecurrence={row.original.weekly_recurrence}
                            monthlyRecurrence={row.original.monthly_recurrence}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<JobSeries>
                    accessorKey="open_jobs_count"
                    header="Open Jobs"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="number" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<JobSeries>
                    accessorKey="created_at"
                    header="Created At"
                    size={176}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
