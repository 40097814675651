import { PERMISSION_LEVEL } from '@constants/permissionLevel';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from "dayjs";
import debounce from "debounce-promise";
import { Component } from "react";
import CalendarDayPicker from "../../calendar/components/CalendarDayPicker";
import BooleanField from "../fields/BooleanField";
import CharField from "../fields/CharField";
import { sendDataToServer } from "../utils/utils";
import Spinner from './Spinner';


class SettingsAvailabilitySchedulesPanel extends Component {

    constructor(props) {
        super(props)

        this.currentAvailabilityScheduleAbortController = new AbortController()

        const desiredDate = new Date(new URLSearchParams(document.location.search).get("date") || "")

        this.state = {
            selectedDay: !isNaN(desiredDate) ? desiredDate : new Date(),
            availabilityScheduleData: null,
            errors: {},
        }
    }

    fetchAvailabilityScheduleDataForDate = async (date) => {
        const dateString = dayjs(date).format("YYYY-MM-DD")

        const { signal } = this.currentAvailabilityScheduleAbortController
        const availabilityScheduleDataEndpoint = DjangoUrls["calendar:api-availability-schedules-list"](window.MARKETPLACE_ENTITY_SLUG, dateString, dateString)
        const availabilityScheduleDataResponse = await fetch(availabilityScheduleDataEndpoint, { signal }).catch((error) => {
            if (error.name !== "AbortError") {
                throw error
            }
            else {
                console.log("Availability Schedule request aborted:", date)
            }
        })

        let availabilityScheduleData

        if (availabilityScheduleDataResponse !== undefined) {
            availabilityScheduleData = await availabilityScheduleDataResponse.json()
        }
        else {
            availabilityScheduleData = null
        }

        return availabilityScheduleData
    }

    componentDidMount = async () => {
        if (this.state.availabilityScheduleData === null) {
            const availabilityScheduleData = await this.fetchAvailabilityScheduleDataForDate(this.state.selectedDay)

            this.setState((state, props) => {
                let updatedState = state
                updatedState.availabilityScheduleData = availabilityScheduleData
                return updatedState
            })
        }
    }

    handleDayChange = async (day) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState.selectedDay = day
            updatedState.availabilityScheduleData = null
            return updatedState
        })

        this.currentAvailabilityScheduleAbortController.abort()
        this.currentAvailabilityScheduleAbortController = new AbortController()

        const availabilityScheduleData = await this.fetchAvailabilityScheduleDataForDate(day)

        this.setState((state, props) => {
            let updatedState = state
            updatedState.availabilityScheduleData = availabilityScheduleData
            return updatedState
        })
    }

    // Form Helpers

    updateAvailabilityScheduleDataForTechnician = debounce(
        async (technicianID, fieldName, fieldValue) => {
            const dataIndex = this.state.availabilityScheduleData.findIndex(schedule => schedule.technician.id === technicianID)

            this.setState(
                (state, props) => {
                    let updatedState = state
                    updatedState.availabilityScheduleData[dataIndex][fieldName] = fieldValue
                    return updatedState
                },
                async () => await this.submitAvailabilityScheduleData(technicianID)
            )
        },
        1000
    )

    submitAvailabilityScheduleData = async (technicianID) => {
        const dateString = dayjs(this.state.selectedDay).format("YYYY-MM-DD")
        const endpoint = DjangoUrls["calendar:api-availability-schedules-detail"](window.MARKETPLACE_ENTITY_SLUG, dateString, dateString, technicianID)
        const endpointMethod = "PATCH"

        const dataName = "availabilityScheduleData"
        const submittingName = `submittingAvailabilitySchedule_${technicianID}`
        const errorDictName = `availabilitySchedule_${technicianID}`
        const successName = `showSuccess_${technicianID}`

        const onSuccess = (job) => {
            this.setState({[successName]: true})
            setTimeout(
                function() {
                    this.setState({[successName]: false})
                }
                .bind(this),
                1000
            )
        }

        const dataManipulator = (data, state) => data.find(schedule => schedule.technician.id === technicianID)

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, undefined, dataManipulator, undefined)
    }

    // Render

    renderAvailabilitySchedule = () => {
        const dateString = dayjs(this.state.selectedDay).format("YYYY-MM-DD")

        if (this.state.availabilityScheduleData === null) {
            return <Spinner />
        }
        else {
            return (
                <div className="data-panel availability-schedule-container" aria-label="Availability Schedule">
                    <div className="data-panel__form data-panel__form--no-padding" aria-label="Availability Schedule Form">
                        <div className="flexible-table" key={`table_${dateString}`}>
                            <table>
                                <thead>
                                    <tr className="flexible-table__row">
                                        <th className="flexible-table__column">Technician</th>
                                        <th className="flexible-table__column">Available</th>
                                        <th className="flexible-table__column">Status</th>
                                        <th className="flexible-table__column flexible-table__column--placeholder"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.availabilityScheduleData.map(availability => {
                                        return (
                                            <tr className="flexible-table__row" key={`technician_${availability.technician.id}_${dateString}`}>
                                                <td className="flexible-table__column">{availability.technician.full_name}</td>
                                                <td className="flexible-table__column flexible-table__column--centered">
                                                    <BooleanField
                                                        fieldName="available"
                                                        fieldLabel=""
                                                        fieldValue={availability.available}
                                                        fieldOnChange={available => this.updateAvailabilityScheduleDataForTechnician(availability.technician.id, "available", available)}
                                                        inputLabel=""
                                                        errors={this.state.errors[`availabilitySchedule_${availability.technician.id}`] || {}}
                                                        idKey={`_${availability.technician.id}_${dateString}`}
                                                        disabled={window.CURRENT_USER.permissions.jobs_list_permission < PERMISSION_LEVEL.FULL || window.CURRENT_USER.permissions.settings_edit_permission < PERMISSION_LEVEL.RESTRICTED}
                                                    ></BooleanField>
                                                </td>
                                                <td className="flexible-table__column">
                                                    <CharField
                                                        fieldName="status"
                                                        fieldLabel=""
                                                        fieldValue={availability.status}
                                                        maxLength={30}
                                                        fieldOnChange={status => this.updateAvailabilityScheduleDataForTechnician(availability.technician.id, "status", status)}
                                                        placeholder={availability.available ? "Working" : "Not Working"}
                                                        errors={this.state.errors[`availabilitySchedule_${availability.technician.id}`] || {}}
                                                        idKey={`_${availability.technician.id}_${dateString}`}
                                                        disabled={window.CURRENT_USER.permissions.jobs_list_permission < PERMISSION_LEVEL.FULL || window.CURRENT_USER.permissions.settings_edit_permission < PERMISSION_LEVEL.RESTRICTED}
                                                    ></CharField>
                                                    {}
                                                </td>
                                                <td className="flexible-table__column flexible-table__column--no-padding success-icon">{this.state[`showSuccess_${availability.technician.id}`] && <FontAwesomeIcon icon={faCheck} />}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const queryClient = new QueryClient()

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <QueryClientProvider client={queryClient}>
                <CalendarDayPicker
                    initialDate={this.state.selectedDay}
                    onDateChange={this.handleDayChange}
                    showAvailability={false}
                ></CalendarDayPicker>
                </QueryClientProvider>
                {this.renderAvailabilitySchedule()}
            </div>
        )
    }
}

export default SettingsAvailabilitySchedulesPanel;
