import Spinner from "@legacy/core/components/Spinner";
import TagField from "@legacy/core/fields/TagField";
import TagSearchInput from "@legacy/core/inputs/TagSearchInput";
import { Component } from "react";
import deepcopy from "rfdc";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AddressAutocompleteFieldGroup from "../../core/fields/AddressAutocompleteFieldGroup";
import BasicSelectField from "../../core/fields/BasicSelectField";
import CharField from "../../core/fields/CharField";
import ListSelectField from "../../core/fields/ListSelectField";
import PaymentTermsField from "../../core/fields/PaymentTermsField";
import PriceField from "../../core/fields/PriceField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import SwitchField from "../../core/fields/SwitchField";
import TextField from "../../core/fields/TextField";
import { ClientTypes, IndustryTypes } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import PriceBookTaxSearchOrCreateSelect, { getPriceBookTaxObjectLabel, priceBookTaxToPriceBookTaxOption } from "../../pricebook/inputs/PriceBookTaxSearchOrCreateSelect";
import ContactListEditSelect from "../components/ContactListEditSelect";
import ServiceLocationListSelect from "../inputs/ServiceLocationListSelect";
import { getClientDefaultTaxDisplay, getClientDefaultTermsDisplay } from "../utils/utils";

const INDUSTRY_TYPES = [
    {"value": "", "label": "Choose industry type..."},
    {"value": IndustryTypes.residential, "label": "Residential"},
    {"value": IndustryTypes.commercial, "label": "Commercial"},
    {"value": IndustryTypes.industrial, "label": "Industrial"},
    {"value": IndustryTypes.government, "label": "Government"},
]


class ClientForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            useCreditLimit: valueIsDefined(this.props.client.credit_limit),
            useTerms: valueIsDefined(this.props.client.default_invoice_net),
            usePriceBookTax: valueIsDefined(this.props.client.default_pricebook_tax),
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            client,
            errors,
            onFormDataChange,
            priceBookTaxes,
            requestAction,
            switchToSecondaryForm,
            showServiceLocationSelect,
            defaultCountryCode,
            currencySymbol,
            defaultClientType,
            useTaxes,
            showTaxCreateButton,
            selectedPriceBookTax,
            updatePriceBookTaxSelection,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_CLIENT") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create" handler={event => requestAction("CLIENT_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("CLIENT_CANCEL_CREATE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_CLIENT") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("CLIENT_UPDATE")} />
                                <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("CLIENT_CANCEL_EDITS")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    toggleUseDefaultPriceBookTax = () => {
        this.props.onFormDataChange("default_pricebook_tax", null)
        this.props.updatePriceBookTaxSelection(null)
        this.setState({usePriceBookTax: !this.state.usePriceBookTax})
    }

    toggleUseTerms = () => {
        this.props.onFormDataChange("default_invoice_net", null)
        this.setState({useTerms: !this.state.useTerms})
    }

    toggleUseCreditLimit = () => {
        this.props.onFormDataChange("credit_limit", null)
        this.setState({useCreditLimit: !this.state.useCreditLimit})
    }

    render() {
        const {
            mode,
            submitting,
            client,
            errors,
            onFormDataChange,
            priceBookTaxes,
            requestAction,
            switchToSecondaryForm,
            showServiceLocationSelect,
            defaultCountryCode,
            currencySymbol,
            defaultClientType,
            useTaxes,
            showTaxCreateButton,
            selectedPriceBookTax,
            updatePriceBookTaxSelection,
            returnScroll
        } = this.props
        const newContactExistsInList = (client.contacts || []).some(contact => contact.__new__ || false)

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Client Create/Update">
                    <div className="data-panel__form" aria-label="Client Create/Update Form">
                        <p className="data-panel__form__caption">Please provide the following information about your client:</p>
                        <SwitchField
                            fieldName="client_type"
                            fieldLabel="Client Type"
                            fieldValue={client.client_type !== undefined ? client.client_type === ClientTypes.individual : defaultClientType === ClientTypes.individual || false}
                            fieldOnChange={client_type => onFormDataChange("client_type", client_type ? ClientTypes.individual : ClientTypes.business)}
                            uncheckedText="Business"
                            checkedText="Individual"
                            errors={errors}
                        ></SwitchField>
                        <BasicSelectField
                            fieldName="industry_type"
                            fieldLabel="Industry Type"
                            fieldValue={client.industry_type || ""}
                            fieldOnChange={industry_type => onFormDataChange("industry_type", industry_type || "")}
                            choices={INDUSTRY_TYPES}
                            optional={true}
                            errors={errors}
                        ></BasicSelectField>
                        <CharField
                            fieldName="name"
                            fieldLabel={`${client.client_type === ClientTypes.business ? "Business" : "Individual"} Name`}
                            fieldValue={client.name || ""}
                            fieldOnChange={name => onFormDataChange("name", name || "")}
                            maxLength="100"
                            errors={errors}
                        ></CharField>
                        <AddressAutocompleteFieldGroup
                            key="client_billing_address"
                            fieldGroupName="billing_address"
                            fieldGroupLabel="Billing Address"
                            fieldPrefix="billing_address"
                            fieldValues={{
                                "billing_address_recipient": client.billing_address_recipient || "",
                                "billing_address_street": client.billing_address_street || "",
                                "billing_address_unit": client.billing_address_unit || "",
                                "billing_address_city": client.billing_address_city || "",
                                "billing_address_state": client.billing_address_state || "",
                                "billing_address_postal": client.billing_address_postal || "",
                                "billing_address_country": client.billing_address_country || "",
                            }}
                            onFormDataChange={onFormDataChange}
                            includeRecipient={true}
                            optional={true}
                            errors={errors}
                        ></AddressAutocompleteFieldGroup>
                        <div className="data-panel__form__field" id="div_id_credit_limit" aria-label="Credit Limit">
                            <label htmlFor="id_client" className="data-panel__form__field__label in-form">Credit Limit <span className="text-optional">(optional)</span></label>
                            <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                <input type="checkbox" className="checkbox-input" name="use_credit_limit" id="id_use_credit_limit" checked={!this.state.useCreditLimit} onChange={event => this.toggleUseCreditLimit()} />
                                <label className="checkbox-label" htmlFor="id_use_credit_limit">No credit limit</label>
                            </div>
                            {
                                this.state.useCreditLimit && (
                                    <PriceField
                                        fieldName="credit_limit"
                                        fieldLabel=""
                                        fieldValue={client.credit_limit !== null ? client.credit_limit : ""}
                                        fieldOnChange={credit_limit => onFormDataChange("credit_limit", credit_limit)}
                                        currencySymbol={currencySymbol}
                                        errors={errors}
                                    ></PriceField>
                                )
                            }
                        </div>
                        <TextField
                            fieldName="notes"
                            fieldLabel="Client Notes"
                            fieldValue={client.notes || ""}
                            fieldOnChange={notes => onFormDataChange("notes", notes || "")}
                            rows={3}
                            placeholder="Any additional details about this client. Details about a particular job should not be entered here."
                            optional={true}
                            errors={errors}
                        ></TextField>
                        <TagField
                            fieldName="labels"
                            fieldLabel="Labels"
                            inputComponent={
                                <TagSearchInput
                                    defaultSelectedTags={client.labels?.map(label => label.name) ?? []}
                                    onSelectionChange={labelNames => onFormDataChange("labels", labelNames ? labelNames.map(labelName => ({"name": labelName})) : [])}
                                    choicesEndpoint={DjangoUrls["clients:api-clients-labels-list"](window.MARKETPLACE_ENTITY_SLUG)}
                                    placeholder="Add Labels..."
                                    noOptionsMessage="No Labels Found"
                                    isMulti={true}
                                ></TagSearchInput>
                            }
                            optional={true}
                            errors={errors}
                        ></TagField>
                        <div className="data-panel__form__field" id="div_id_default_invoice_net" aria-label="Default Client Payment Terms">
                            <label htmlFor="id_client" className="data-panel__form__field__label in-form">Default Client Payment Terms <span className="text-optional">(optional)</span></label>
                            <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                <input type="checkbox" className="checkbox-input" name="use_terms" id="id_use_terms" checked={!this.state.useTerms} onChange={event => this.toggleUseTerms()} />
                                <label className="checkbox-label" htmlFor="id_use_terms">{getClientDefaultTermsDisplay(null, client.service_company_default_invoice_net)}</label>
                            </div>
                            {
                                this.state.useTerms && (
                                    <PaymentTermsField
                                        fieldName="default_invoice_net"
                                        fieldLabel=""
                                        fieldValue={client.default_invoice_net !== null ? client.default_invoice_net : ""}
                                        fieldOnChange={net => onFormDataChange("default_invoice_net", net || "")}
                                        errors={errors}
                                    ></PaymentTermsField>
                                )
                            }
                        </div>
                        {
                            useTaxes && (
                                <div className="data-panel__form__field" id="div_id_default_pricebook_tax" aria-label="Default Client Tax Rate">
                                    <label htmlFor="id_client" className="data-panel__form__field__label in-form">Default Client Tax Rate <span className="text-optional">(optional)</span></label>
                                    <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                        <input type="checkbox" className="checkbox-input" name="default_pricebook_tax" id="id_default_pricebook_tax" checked={!this.state.usePriceBookTax} onChange={event => this.toggleUseDefaultPriceBookTax()} />
                                        <label className="checkbox-label" htmlFor="id_default_pricebook_tax">{getClientDefaultTaxDisplay(null, client.service_company_default_pricebook_tax, priceBookTaxes)}</label>
                                    </div>
                                    {
                                        this.state.usePriceBookTax && (
                                            <SearchCreateSelectField
                                                key={selectedPriceBookTax ? getPriceBookTaxObjectLabel(selectedPriceBookTax) : null}
                                                fieldName="default_pricebook_tax"
                                                fieldLabel=""
                                                fieldValue={selectedPriceBookTax ? getPriceBookTaxObjectLabel(selectedPriceBookTax) : null}
                                                inputComponent={
                                                    <PriceBookTaxSearchOrCreateSelect
                                                        onSelectionChange={selectedOption => {
                                                            onFormDataChange("default_pricebook_tax", selectedOption?.object?.id || null)
                                                            updatePriceBookTaxSelection(selectedOption?.object || null)
                                                        }}
                                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({taxSearchInput: input})}}
                                                        defaultSelected={selectedPriceBookTax ? priceBookTaxToPriceBookTaxOption(selectedPriceBookTax) : null}
                                                        showCreateButton={showTaxCreateButton}
                                                        limitToQuickBooksSyncedItems={!showTaxCreateButton}
                                                        onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_TAX", null, {description: this.state.taxSearchInput})}
                                                    />
                                                }
                                                showButton={!!selectedPriceBookTax}
                                                buttonLabel={<><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Tax Rate</>}
                                                buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_TAX", selectedPriceBookTax, null)}
                                                errors={errors}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                        <ListSelectField
                            fieldName="contacts"
                            fieldLabel="Client Contacts"
                            inputComponent={<ContactListEditSelect fieldName="contacts" contacts={client.contacts || []} primaryContactId={client.primary_contact} updateContacts={contacts => onFormDataChange("contacts", contacts)} />}
                            showButton={!newContactExistsInList}
                            buttonLabel="Add Contact"
                            buttonAction={event => {
                                let newContacts = deepcopy()(client.contacts || [])
                                newContacts.push({
                                    "id": null,
                                    "name": "",
                                    "phone": "",
                                    "phone_extension": "",
                                    "email": "",
                                    "receive_invoice_reminders_via_email": false,
                                    "receive_invoice_reminders_via_sms": false,
                                    "receive_estimate_reminders_via_email": false,
                                    "receive_estimate_reminders_via_sms": false,
                                    "primary": false,
                                    "attached_to": "external_client",
                                    "__new__": true
                                })
                                onFormDataChange("contacts", newContacts)
                            }}
                            errors={errors}
                        ></ListSelectField>
                        {
                            mode === "EDIT_CLIENT" && showServiceLocationSelect && (
                                <ListSelectField
                                    fieldName="service_locations"
                                    fieldLabel="Service Locations"
                                    inputComponent={<ServiceLocationListSelect fieldName="service_locations" onSelect={data => switchToSecondaryForm("EDIT_SERVICE_LOCATION", data, null)} client={client}></ServiceLocationListSelect>}
                                    showButton={true}
                                    buttonLabel="Add Service Location"
                                    buttonAction={event => switchToSecondaryForm("ADD_SERVICE_LOCATION", null, null)}
                                    errors={errors}
                                ></ListSelectField>
                            )
                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

}

export default ClientForm;
