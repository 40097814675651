import BillCreate from "./BillCreate/BillCreate"
import BillDetails from "./BillDetails/BillDetails"
import BillEdit from "./BillEdit/BillEdit"
import BillList from "./BillList/BillList"

const List = BillList
const Create = BillCreate
const Edit = BillEdit
const Details = BillDetails

export default {
    List,
    Create,
    Edit,
    Details,
}
