import { AssignmentModel, DateHelper, ResourceTimeRangeModel, ResourceTimeRangeStore } from "@bryntum/schedulerpro"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import Cookies from "js-cookie"
import { useMemo, useRef } from "react"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import fetchObjectListData from "@utils/fetchObjectListData"
import replaceSlugs from "@utils/replaceSlugs"

import toast from "@molecules/Toast/Toast"

import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"

import {
    EventModel,
    EventRecord,
    JobUpdatePayload,
    SchedulerDateRange,
    SchedulerEventModel,
    SchedulerResourceModel,
    TechnicianAvailabilityData,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

import { AVAILABILITY_SCHEDULES_ENDPOINTS, CALENDAR_DATA_ENDPOINTS } from "@endpoints/calendar"
import { JOB_ENDPOINTS } from "@endpoints/jobs"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewOverlappingUtils from "./useJobTimelineViewOverlappingUtils"
import useJobTimelineViewStates from "./useJobTimelineViewStates"
import useJobTimelineViewTimeRanges from "./useJobTimelineViewTimeRanges"

const activeControllers = new Map<string, AbortController>()

export default function useJobTimelineViewData() {
    const { limitOfItemsPerGridFetch, dateRange, visibleDateRange } = useJobTimelineViewStates()

    const [unscheduledJobsListEndpoint] = useSlugExtractor([CALENDAR_DATA_ENDPOINTS.LIST_UNSCHEDULED_JOBS])

    const unscheduledJobsURL = `${unscheduledJobsListEndpoint}?limit=${limitOfItemsPerGridFetch}&offset=0"`

    const { user } = useUser()

    const { hasFlag } = useHasAccess()

    const serviceCompanySlug = user?.service_company?.slug ?? "undefined"

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { getTimeRanges, generateAllSchedulerTimeRanges, arrayOfDatesFromTimeFrame } = useJobTimelineViewTimeRanges()

    const { handleOverlappingEvents } = useJobTimelineViewOverlappingUtils()

    const { timeFrameType, setDateRange } = useJobTimelineViewStates()

    const queryClient = useQueryClient()

    const {
        data: unscheduledJobsData,
        isLoading: isFetchingUnscheduledJobs,
        isError: isUnscheduledJobsError,
        fetchNextPage: fetchNextUnscheduledJobsPage,
        hasNextPage: unscheduledJobsPaginationHasNextPage,
        refetch: refetchUnscheduledJobs,
    } = useInfiniteQuery<ObjectListData<CalendarEvent>>({
        queryKey: [unscheduledJobsURL],
        queryFn: ({ pageParam }) =>
            fetchObjectListData({
                objectName: "Unscheduled Job",
                endpoint: unscheduledJobsURL,
                endpointKwargs: [],
                searchKeywords: "",
                filters: null,
                sorting: undefined,
                nextPage: pageParam as string,
            }),
        initialPageParam: "",
        staleTime: 60000,
        getNextPageParam: (lastPage) => lastPage.next,
        enabled: !!limitOfItemsPerGridFetch,
    })

    const createIntervalPerDateRange = (dateRange: SchedulerDateRange) => {
        let rangeStartDate: Date

        if (timeFrameType === "month") {
            rangeStartDate = DateHelper.add(dateRange.start, 0, "M")
        } else if (timeFrameType === "week") {
            rangeStartDate = DateHelper.add(dateRange.start, 0, "d")
        } else if (timeFrameType === "threeDays") {
            rangeStartDate = DateHelper.add(dateRange.start, 0, "d")
        } else {
            rangeStartDate = DateHelper.add(dateRange.start, -1, "d")
        }

        let rangeEndDate: Date
        if (timeFrameType === "month") {
            rangeEndDate = DateHelper.add(dateRange.end, 0, "M")
        } else if (timeFrameType === "week") {
            rangeEndDate = DateHelper.add(dateRange.end, 0, "d")
        } else if (timeFrameType === "threeDays") {
            rangeEndDate = DateHelper.add(dateRange.end, 0, "d")
        } else {
            rangeEndDate = DateHelper.add(dateRange.end, 1, "d")
        }

        return {
            start: rangeStartDate,
            end: rangeEndDate,
        }
    }

    const previousIntervalForFetch = useRef<SchedulerDateRange>(createIntervalPerDateRange(visibleDateRange))

    const intervalForFetch = useMemo(() => {
        if (timeFrameType === "day") {
            const startDateDiffWithPrevious = DateHelper.diff(
                visibleDateRange.start,
                previousIntervalForFetch.current.start,
                "d",
                false,
            )

            const endDateDiffWithPrevious = DateHelper.diff(
                visibleDateRange.end,
                previousIntervalForFetch.current.end,
                "d",
                false,
            )

            const renewInterval = startDateDiffWithPrevious >= 0 || endDateDiffWithPrevious <= 0

            if (!renewInterval) {
                return previousIntervalForFetch.current
            }
        }

        previousIntervalForFetch.current = createIntervalPerDateRange(visibleDateRange)

        return previousIntervalForFetch.current
    }, [visibleDateRange.start, visibleDateRange.end])

    const {
        data: calendarData,
        isLoading: isFetchingCalendarData,
        isError: isCalendarDataError,
        refetch: refetchCalendarData,
    } = useQuery<CalendarEvent[], Error>({
        queryKey: ["calendarData", intervalForFetch.start, intervalForFetch.end],
        queryFn: ({ signal }) => fetchCalendarDataForDates(intervalForFetch.start, intervalForFetch.end, signal),
        staleTime: 60000,
    })

    const {
        data: availabilitySchedulesData,
        isLoading: isFetchingAvailabilitySchedulesData,
        refetch: refetchAvailabilitySchedules,
        isError: isAvailabilitySchedulesError,
    } = useQuery<TechnicianAvailabilityData[], Error>({
        queryKey: ["availabilitySchedules", dateRange.start, dateRange.end],
        queryFn: ({ signal }) => fetchAvailabilitySchedulesForDates(dateRange.start, dateRange.end, signal),
        staleTime: 60000,
    })

    async function fetchDataForDates<T>(
        start: Date,
        end: Date,
        endpointTemplate: string,
        init: RequestInit = {},
    ): Promise<T[]> {
        const startDate = DateHelper.format(start, "YYYY-MM-DD")
        const endDate = DateHelper.format(end, "YYYY-MM-DD")

        const endpoint = replaceSlugs(endpointTemplate, {
            service_company_slug: serviceCompanySlug,
            start_date: startDate,
            end_date: endDate,
        })

        const fetchKey = `${endpoint}-${startDate}-${endDate}`

        if (activeControllers.has(fetchKey)) {
            activeControllers.delete(fetchKey)
            return []
        }

        const controller = new AbortController()
        activeControllers.set(fetchKey, controller)

        try {
            const response = await fetch(endpoint, { ...init, signal: controller.signal })
            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return (await response.json()) as T[]
        } catch (error) {
            if ((error as Error).name === "AbortError") {
                console.warn("Fetch aborted for:", fetchKey)
                return []
            }
            throw error
        } finally {
            activeControllers.delete(fetchKey)
        }
    }

    async function fetchCalendarDataForDates(start: Date, end: Date, signal: AbortSignal) {
        return fetchDataForDates<CalendarEvent>(start, end, CALENDAR_DATA_ENDPOINTS.DATA, {
            // TODO: use service worker to cache data? this exists only because of the back_forward navigation
            // that returns the old cached data. As we can't update the cache, sometimes it returns data without
            // the new jobs. This is a workaround to avoid this issue. However, it would be better to have another
            // layer of control of cache before useQuery one.
            cache: "no-cache",
            signal,
        })
    }

    async function fetchAvailabilitySchedulesForDates(start: Date, end: Date, signal: AbortSignal) {
        return fetchDataForDates<TechnicianAvailabilityData>(start, end, AVAILABILITY_SCHEDULES_ENDPOINTS.LIST, {
            signal,
        })
    }

    const resources = useMemo(() => {
        const resources: CalendarTechnician[] = []
        const resourceIDs = new Set()

        const hasAvailabilitySchedulesData =
            availabilitySchedulesData !== undefined &&
            availabilitySchedulesData?.length > 0 &&
            !isFetchingAvailabilitySchedulesData

        if (!hasAvailabilitySchedulesData) {
            return resources
        } else {
            availabilitySchedulesData?.forEach((schedule) => {
                resourceIDs.add(schedule.technician?.id)
                const resourceAlreadyInList = resources.some((resource) => resource.id === schedule.technician?.id)

                if (resourceAlreadyInList) {
                    return
                } else {
                    const availability = availabilitySchedulesData
                        .filter((techSchedule) => schedule.technician?.id === techSchedule.technician?.id)
                        .map((techSchedule) => ({
                            available: techSchedule.available,
                            status:
                                techSchedule.status !== ""
                                    ? techSchedule.status
                                    : techSchedule.available
                                      ? "Working"
                                      : "Not Working",
                            date: techSchedule.date,
                        }))

                    resources.push({
                        ...schedule.technician,
                        availability,
                        id: schedule.technician?.id,
                        first_name: schedule.technician.first_name,
                        last_name: schedule.technician.last_name,
                        full_name: schedule.technician.full_name,
                        short_name: schedule.technician.short_name,
                        avatar: schedule.technician.avatar,
                        gravatar: schedule.technician.gravatar,
                        calendar: schedule.available ? "common-calendar" : "full-day-off",
                    })
                }
            })

            calendarData?.forEach((event) => {
                if (!resourceIDs.has(event.technician_id)) {
                    const technician = event.assigned_technicians.find((tech) => tech?.id === event.technician_id)
                    if (technician) {
                        resources.push({
                            ...technician,
                            availability: [
                                {
                                    available: false,
                                    status: "Inactive",
                                    date: dayjs(event.start_time).startOf("day").toDate(),
                                },
                            ],
                            calendar: "full-day-off",
                        })
                    }
                }
            })

            return resources
        }
    }, [availabilitySchedulesData, isFetchingAvailabilitySchedulesData, calendarData])

    const dataIsLoaded = useMemo(() => {
        return (
            calendarData !== undefined &&
            availabilitySchedulesData !== undefined &&
            !isFetchingCalendarData &&
            !isFetchingAvailabilitySchedulesData &&
            resources.length > 0
        )
    }, [
        calendarData,
        availabilitySchedulesData,
        isFetchingCalendarData,
        isFetchingAvailabilitySchedulesData,
        resources,
    ])

    const mapCalendarEventsAndAssignments = () => {
        const assignments: AssignmentModel[] = []
        const events: EventRecord[] = []

        calendarData?.forEach((event, index) => {
            assignments.push({
                id: index,
                event: event.id,
                resource: event.technician_id ?? "unassigned",
            } as AssignmentModel)

            const eventIdIsRepeated = events.some((e) => e.id === event.id)

            if (!eventIdIsRepeated) {
                const DURATION_IN_HOURS = event.estimated_duration / 3600

                events.push({
                    ...event,
                    name: event.service_name,
                    startDate: event.start_time,
                    endDate: event.end_time,
                    duration: DURATION_IN_HOURS,
                })
            }
        })

        return { assignments, events }
    }

    const generateUnassignedResource = (): CalendarTechnician => {
        return {
            calendar: "common-calendar",
            id: "unassigned",
            name: "Unassigned",
        } as CalendarTechnician
    }

    const populateSchedulerWithTimeRanges = (resourceTimeRanges: ResourceTimeRangeModel[]) => {
        const schedulerInstance = schedulerPro.current?.instance
        const resourceTimeRangeStore = schedulerInstance?.resourceTimeRangeStore as ResourceTimeRangeStore

        if (schedulerInstance) {
            resourceTimeRanges.forEach((resourceTimeRange) => {
                const timeRangeAlreadyInList = schedulerInstance?.resourceTimeRanges.some(
                    (updated) => updated.id === resourceTimeRange.id,
                )

                if (!timeRangeAlreadyInList) {
                    resourceTimeRangeStore.add(resourceTimeRange)
                }
            })
        }
    }

    const populateSchedulerWithResources = (resources: CalendarTechnician[]) => {
        const schedulerInstance = schedulerPro.current?.instance

        if (schedulerInstance) {
            if (schedulerInstance.resources.length > 0) {
                schedulerInstance.resources.forEach((resource) => {
                    const updatedResource = resources.find(
                        (updated) => updated.id === resource.id,
                    ) as CalendarTechnician

                    const resourceOriginalData = (resource as SchedulerResourceModel<CalendarTechnician>).originalData
                    if (resourceOriginalData) {
                        resourceOriginalData.availability = updatedResource?.availability
                    }
                })
            } else {
                schedulerInstance.resources = []
                schedulerInstance.resources = resources
            }
        }
    }

    const populateSchedulerWithEvents = (events: EventModel<EventRecord>[]) => {
        const schedulerInstance = schedulerPro.current?.instance
        if (schedulerInstance) {
            schedulerInstance.events = events
        }
    }

    const populateSchedulerWithAssignments = (assignments: AssignmentModel[]) => {
        const schedulerInstance = schedulerPro.current?.instance
        if (schedulerInstance) {
            schedulerInstance.assignments = assignments
        }
    }

    const addResourceTimeRangesToScheduler = (startDate = visibleDateRange.start, endDate = visibleDateRange.end) => {
        const datesInterval = arrayOfDatesFromTimeFrame(startDate, endDate)
        const timeRanges = datesInterval.map((day) => getTimeRanges(day))
        const resourceTimeRanges = generateAllSchedulerTimeRanges(timeRanges, resources)

        populateSchedulerWithTimeRanges(resourceTimeRanges)
    }

    const configureAndPopulateScheduler = () => {
        const { assignments, events } = mapCalendarEventsAndAssignments()

        const shouldAddResourceTimeRanges = timeFrameType === "day"

        if (shouldAddResourceTimeRanges) {
            addResourceTimeRangesToScheduler()
        }

        const finalResourcesList = [
            ...(!hasFlag("restricted_to_assigned_jobs") ? [generateUnassignedResource()] : []),
            ...resources,
        ] as CalendarTechnician[]

        const unscheduledJobs = schedulerPro.current
            ? (schedulerPro.current.instance.events as EventModel<EventRecord>[]).filter((event) => {
                  return !event.originalData.startDate ? event : null
              })
            : []
        const finalEventsList = [...(events as unknown as EventModel<EventRecord>[]), ...unscheduledJobs]

        populateSchedulerWithResources(finalResourcesList)
        populateSchedulerWithEvents(finalEventsList)
        populateSchedulerWithAssignments(assignments)

        if (schedulerPro.current) {
            schedulerPro.current.instance.refreshRows()
        }

        handleOverlappingEvents()
    }

    const syncJobUpdatesWithServer = async (jobId: Job["id"], updatedJob: JobUpdatePayload) => {
        const updateJobEndpoint = replaceSlugs(JOB_ENDPOINTS.UPDATE, {
            service_company_slug: serviceCompanySlug,
            id: jobId,
        })

        try {
            const response = await fetch(updateJobEndpoint, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken") ?? "",
                },
                body: JSON.stringify({
                    skip_success_modal: true,
                    ...updatedJob,
                }),
            })

            if (response.ok) {
                toast({
                    type: "success",
                    size: "md",
                    title: `Job ${updatedJob?.custom_id || updatedJob?.id || jobId} updated`,
                })
            } else {
                toast({
                    type: "error",
                    size: "md",
                    title: "Job could not be updated",
                })
            }
        } catch (error) {
            toast({
                type: "error",
                size: "md",
                title: "Job could not be updated",
            })
        }
    }

    const updateEventRecord = (
        eventRecord: SchedulerEventModel,
        newData: {
            resizingStartTime?: Date | null
            resizingEndTime?: Date | null
            isResizingEventStart?: boolean
            isResizingEventEnd?: boolean
            isOverlappingOnResize?: boolean
            isOverlapping?: boolean
        },
    ) => {
        if (newData.isOverlappingOnResize !== undefined) {
            eventRecord.isOverlappingOnResize = newData.isOverlappingOnResize
        }
        if (newData.resizingEndTime !== undefined) {
            eventRecord.resizingEndTime = newData.resizingEndTime
        }
        if (newData.resizingStartTime !== undefined) {
            eventRecord.resizingStartTime = newData.resizingStartTime
        }
        if (newData.isResizingEventStart !== undefined) {
            eventRecord.isResizingEventStart = newData.isResizingEventStart
        }
        if (newData.isResizingEventEnd !== undefined) {
            eventRecord.isResizingEventEnd = newData.isResizingEventEnd
        }
    }

    const updateEventStore = (jobId: Job["id"], newData: CalendarEvent) => {
        if (schedulerPro.current) {
            const eventStore = schedulerPro.current.instance.eventStore.getById(jobId)
            eventStore.set(newData)
        }
    }

    const updateEventInCache = ({
        jobId,
        technicianToDrop,
        technicianToAdd,
        newData,
    }: {
        jobId: Job["id"]
        newData: CalendarEvent
        technicianToDrop?: CalendarTechnician
        technicianToAdd?: CalendarTechnician
    }) => {
        queryClient.setQueryData(
            ["calendarData", intervalForFetch.start, intervalForFetch.end],
            (oldData: CalendarEvent[]) => {
                if (oldData) {
                    const newCalendarData = oldData.map((event: CalendarEvent) => {
                        const currentAssignedTechnician = event.technician_id ?? "unassigned"

                        const shouldDropCurrentTechnician = currentAssignedTechnician === technicianToDrop?.id
                        const shouldUnassign = technicianToAdd?.id === "unassigned"

                        const isTargetedEvent = event.id === jobId
                        const isMultiAssignedEvent = event.assigned_technicians.length > 1
                        const isSameTechnicianOperation = (!technicianToAdd || !technicianToDrop) && isTargetedEvent

                        const shouldReplaceTechnician = isTargetedEvent && shouldDropCurrentTechnician
                        const shouldRemoveFromCache =
                            isTargetedEvent && shouldDropCurrentTechnician && shouldUnassign && isMultiAssignedEvent

                        const isPartOfUpdatedEvent =
                            isTargetedEvent &&
                            !shouldDropCurrentTechnician &&
                            isMultiAssignedEvent &&
                            !isSameTechnicianOperation

                        if (isPartOfUpdatedEvent || isSameTechnicianOperation) {
                            return {
                                ...event,
                                ...newData,
                            }
                        } else if (!isTargetedEvent) {
                            return event
                        } else if (shouldRemoveFromCache) {
                            return null
                        } else if (shouldReplaceTechnician) {
                            return {
                                ...event,
                                ...newData,
                                technician_id: technicianToAdd?.id,
                            }
                        } else {
                            return event
                        }
                    })

                    return newCalendarData.filter(Boolean)
                }
            },
        )
    }

    const resetTimeRanges = () => {
        if (schedulerPro.current?.instance) {
            schedulerPro.current.instance.resourceTimeRanges = []
        }
    }

    const addEventToCache = (newEvent: CalendarEvent) => {
        queryClient.setQueryData(
            ["calendarData", intervalForFetch.start, intervalForFetch.end],
            (oldData: CalendarEvent[]) => {
                // Because in the first time this function is called there might not be an old data yet
                if (oldData) {
                    return [...oldData, newEvent]
                }
            },
        )
    }

    const narrowDateRangeToCenterDate = () => {
        const schedulerInstance = schedulerPro.current?.instance

        if (schedulerInstance) {
            const centerDate = schedulerInstance?.viewportCenterDate

            schedulerInstance.setStartDate(centerDate)
            schedulerInstance.setEndDate(centerDate)

            setDateRange({
                start: centerDate,
                end: centerDate,
            })
        }
    }

    return {
        unscheduledJobsData,
        isFetchingUnscheduledJobs,
        fetchNextUnscheduledJobsPage,
        unscheduledJobsPaginationHasNextPage,
        refetchUnscheduledJobs,
        calendarData,
        isFetchingCalendarData,
        refetchCalendarData,
        availabilitySchedulesData,
        isFetchingAvailabilitySchedulesData,
        refetchAvailabilitySchedules,
        resources,
        mapCalendarEventsAndAssignments,
        generateUnassignedResource,
        configureAndPopulateScheduler,
        syncJobUpdatesWithServer,
        updateEventRecord,
        updateEventStore,
        isUnscheduledJobsError,
        isCalendarDataError,
        isAvailabilitySchedulesError,
        updateEventInCache,
        addEventToCache,
        resetTimeRanges,
        dataIsLoaded,
        addResourceTimeRangesToScheduler,
        isLoading: isFetchingUnscheduledJobs || isFetchingCalendarData || isFetchingAvailabilitySchedulesData,
        narrowDateRangeToCenterDate,
    }
}
