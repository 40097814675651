import { Component } from 'react';
import WrapperLinkButton from '../../core/buttons/WrapperLinkButton';
import { convertToKebabCase, formatCurrencyForServiceCompany } from '../../core/utils/utils';
import { calculateInvoiceAmounts } from '../utils/utils';


class InvoiceObjectLink extends Component {

    render() {
        const { invoice, destination, disabled=false } = this.props

        const buttonTitle = `Invoice ${invoice.id} [${invoice.status_label}]`
        const buttonPillClass = `invoices-${convertToKebabCase(invoice.status_label)}`
        const buttonPillLabel = invoice.status_label
        const buttonLabel = formatCurrencyForServiceCompany(calculateInvoiceAmounts(invoice).total, invoice.service_company)

        return (
            <WrapperLinkButton type="object_link" destination={destination} openNew={true} title={buttonTitle} ariaLabel="View Invoice" disabled={disabled}>
                <div className="data-panel__list-select__option__label"><div className={`data-panel__list-select__option__status-pill ${buttonPillClass}`}>{buttonPillLabel}</div><span>{buttonLabel}</span></div><div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperLinkButton>
        )
    }
}

export default InvoiceObjectLink;
