import styles from "./TopBarBody.module.scss"
import { TopBarBodyProps } from "./TopBarBody.types"

export default function TopBarBody(props: TopBarBodyProps) {
    const { children } = props

    return (
        <div className={styles.base}>
            <div className={styles.container}>{children}</div>
        </div>
    )
}
