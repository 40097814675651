import clsx from "clsx"
import { useEffect, useState } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import Table from "@organisms/Table/Table"
import useTable from "@organisms/Table/Table.context"
import tableStyles from "@organisms/Table/Table.module.scss"
import tableEdgeStatesStyles from "@organisms/Table/TableEdgeStates/TableEdgeStates.module.scss"
import { cellOrientationByHeader, loadingTypeByHeader } from "@organisms/Table/TableEdgeStates/TableEdgeStates.utils"

import { TableLoadingStateProps } from "./TableLoadingState.types"

const DEFAULT_NUMBER_OF_LOADING_ROWS = 14

const LOADING_STATE_THRESHOLD = 100
const MINIMUM_LOADING_DURATION = 200

export default function TableLoadingState(props: TableLoadingStateProps) {
    const { isAnimated = true, isBackground, numberOfRows = DEFAULT_NUMBER_OF_LOADING_ROWS, children } = props

    const { visibleColumns, tabContentName } = useTable()

    const [loadingState, setLoadingState] = useState<"not-started" | "ongoing" | "finished">("not-started")

    // After LOADING_STATE_THRESHOLD ms, determine whether or not to show the loading state
    // If the data has already loaded, don't show it
    // This is to prevent the loading state from showing for very short requests
    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null

        timeout = setTimeout(() => {
            setLoadingState("ongoing")
        }, LOADING_STATE_THRESHOLD)

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    // Once the loading state is shown, it must be shown for at least MINUMUM_LOADING_DURATION ms
    // This is to prevent the loading state from briefly flashing on the screen
    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null

        if (loadingState === "ongoing") {
            timeout = setTimeout(() => {
                setLoadingState("finished")
            }, MINIMUM_LOADING_DURATION)
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [loadingState])

    const rows = Array.from(Array(numberOfRows).keys())

    return (
        <>
            {children}
            <table
                className={clsx(tableEdgeStatesStyles.table, tableStyles.noScroll, {
                    [tableStyles.tableAsBackground]: isBackground,
                })}
                aria-label={`${tabContentName} List Loading`}
                role="tabpanel"
            >
                <thead className={tableStyles.tableHeader}>
                    <tr className={tableStyles.headerRow}>
                        {visibleColumns?.map(
                            (column) =>
                                column && (
                                    <Table.Cells.SkeletonCell
                                        isAnimated={isAnimated}
                                        key={generateRandomKey()}
                                        variant="header"
                                        cellWidth={column.size}
                                        isRightAligned={
                                            cellOrientationByHeader[column.id as keyof ObjectsData] === "right"
                                        }
                                    />
                                ),
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(() => (
                        <tr key={generateRandomKey()} className={clsx(tableStyles.bodyRow, tableStyles.loadingRow)}>
                            {visibleColumns?.map(
                                (column) =>
                                    column && (
                                        <Table.Cells.SkeletonCell
                                            isAnimated={isAnimated}
                                            key={generateRandomKey()}
                                            variant={loadingTypeByHeader[column.id as keyof ObjectsData] ?? "text"}
                                            cellWidth={column.size}
                                            isRightAligned={
                                                cellOrientationByHeader[column.id as keyof ObjectsData] === "right"
                                            }
                                        />
                                    ),
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
