import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { PriceBookItemTypes } from "../core/utils/enums";
import { historyHasState, sendDataToServer } from "../core/utils/utils";
import JobCompleteConfirmForm from "./forms/JobCompleteConfirmForm";
import JobSkipSignatureForm from "./forms/JobSkipSignatureForm";

const PAGE_MODES = {
    VIEW_JOB_SUMMARY: "VIEW_JOB_SUMMARY",
    SKIP_SIGNATURE: "SKIP_SIGNATURE",
}

const PAGE_MODE_SUBTITLES = {
    VIEW_JOB_SUMMARY: "Summary Confirmation",
    SKIP_SIGNATURE: "Skip Signature",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_JOB_SUMMARY: "flex",
    SKIP_SIGNATURE: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_JOB_SUMMARY]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.SKIP_SIGNATURE,
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_JOB_SUMMARY: "confirmData",
    SKIP_SIGNATURE: "confirmData",
}

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_JOB_SUMMARY: "submittingConfirm",
    SKIP_SIGNATURE: "submittingConfirm",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_JOB_SUMMARY: "confirm",
    SKIP_SIGNATURE: "confirm",
}


class JobCompleteConfirmContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_JOB_SUMMARY
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            jobData: null,

            confirmData: {},

            errors: {
                confirm: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            forceSkipSignature: false,
            jobSendEnabled: window.JOB_SEND_ENABLED || false,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.jobData === null) {
            const jobEndpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
            const jobResponse = await fetch(jobEndpoint)
            const job = await jobResponse.json()

            this.setState((state, props) => {
                let updatedState = state
                updatedState.jobData = job

                // Split line items into their respective lists
                updatedState.jobData.service_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                updatedState.jobData.parts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                updatedState.jobData.other_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                updatedState.jobData.discounts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                if (job.is_job_walk && !job.service_company.collect_signature_on_job_walks) {
                    updatedState.forceSkipSignature = true
                    updatedState.forceSkipSignatureMessage = "No Signature (Job Walk)"
                }
                else if (job.service_company.bypass_job_signature) {
                    updatedState.forceSkipSignature = true
                    updatedState.forceSkipSignatureMessage = "Signature Bypassed via Setting"
                }

                // Clear any existing job signature state
                updatedState.jobData.signature = ""
                updatedState.jobData.signed_by = ""
                updatedState.jobData.signature_skip_reason = ""

                return updatedState
            })
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    // Confirm Job

    confirmJob = async (skipSignature) => {
        const endpoint = DjangoUrls["jobs:api-jobs-complete"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id)
        const endpointMethod = "POST"
        let successUrl

        if (this.state.jobSendEnabled && window.CURRENT_USER?.permissions.jobs_edit_permission >= PERMISSION_LEVEL.FULL) {
            successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id) + "?mode=send-job"
        } else {
            successUrl = DjangoUrls["jobs:jobs-list"](window.MARKETPLACE_ENTITY_SLUG)
        }

        const dataName = "confirmData"
        const submittingName = "submittingConfirmData"
        const errorDictName = "confirm"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${this.state.jobData.custom_id || this.state.jobData.id}" completed`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            data.skip_signature = skipSignature

            if (this.state.forceSkipSignature) {
                data.skip_signature = true
                data.signature_skip_reason = this.state.forceSkipSignatureMessage
            }

            return data
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job could not be completed",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "JOB_CONFIRM":
                this.confirmJob(false)
                break
            case "JOB_UPDATE_SUMMARY":
                location.assign(DjangoUrls["jobs:jobs-complete"](window.MARKETPLACE_ENTITY_SLUG, this.state.jobData.id))
                break
            case "JOB_SKIP_SIGNATURE":
                this.switchToSecondaryForm(PAGE_MODES.SKIP_SIGNATURE, null, null)
                break
            case "JOB_SKIP_SIGNATURE_SUBMIT":
                this.confirmJob(true)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.jobData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <JobCompleteConfirmForm
                    job={this.state.jobData}
                    confirmData={this.state.confirmData}
                    forceSkipSignature={this.state.forceSkipSignature}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    submitting={this.state.submittingConfirm}
                    errors={this.state.errors.confirm}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("confirmData", fieldName, fieldValue)}
                    returnScroll={this.state.returnScroll}
                    addToastToQueue={this.addToastToQueue}
                ></JobCompleteConfirmForm>
            }
            else if (this.state.mode === PAGE_MODES.SKIP_SIGNATURE) {
                return <JobSkipSignatureForm
                    job={this.state.jobData}
                    confirmData={this.state.confirmData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingConfirm}
                    errors={this.state.errors.confirm}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("confirmData", fieldName, fieldValue)}
                    returnScroll={0}
                ></JobSkipSignatureForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default JobCompleteConfirmContainer;
