import { ColumnDefResolved, createColumnHelper } from "@tanstack/react-table"
import { useEffect } from "react"

import useTable from "@organisms/Table/Table.context"

import { TableColumnProps } from "./TableColumn.types"

function TableColumn<T>(props: TableColumnProps<T>) {
    const { header, accessorKey, size, cell, enableSorting, enablePinning, dataType } = props

    const { setColumns } = useTable<T>()

    const columnHelper = createColumnHelper<T>()

    useEffect(() => {
        setColumns((prevColumns: ColumnDefResolved<T>[]) => {
            const isColumnAlreadyAdded = prevColumns.some(
                (column) => "accessorKey" in column && column.accessorKey === accessorKey,
            )

            if (isColumnAlreadyAdded) {
                return prevColumns
            } else {
                const newColumn = columnHelper.accessor(accessorKey, {
                    header,
                    cell,
                    enableSorting,
                    enablePinning,
                    size,
                    meta: {
                        dataType,
                    },
                }) as ColumnDefResolved<T>

                return [...prevColumns, newColumn]
            }
        })
    }, [])

    return null
}

export default TableColumn
