// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabStack-base__JLebE{display:flex;gap:var(--size-0_5);align-items:center}.TabStack-attached__dapYF{gap:var(--size-px);padding:var(--size-px);background:var(--gray-a3);border-radius:var(--border-radius-1)}.TabStack-underlined__dYaVp{gap:var(--size-4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TabStack-base__JLebE",
	"attached": "TabStack-attached__dapYF",
	"underlined": "TabStack-underlined__dYaVp"
};
export default ___CSS_LOADER_EXPORT___;
