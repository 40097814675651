import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import { CellSkeletonVariant } from "@organisms/Table/TableCells/SkeletonCell/SkeletonCell.types"

export const loadingTypeByHeader: {
    [key in keyof ObjectsData]?: CellSkeletonVariant
} = {
    id: "text",
    is_draft: "text",
    track_inventory: "text",
    confirmed: "text",
    created_at: "text",
    custom_id: "text",
    default_unit_type: "text",
    down_payment_amount: "text",
    down_payment_amount_due: "text",
    initial_started_time: "text",
    is_active: "text",
    is_expired: "text",
    is_overdue: "text",
    is_past_due: "text",
    pricebook_item_type: "text",
    pricebook_item_type_label: "text",
    service_location_name: "text",
    status_color_override: "text",
    default_unit_type_label: "text",
    client_name: "text",
    state_label: "badge",
    status_label: "badge",
    service_name: "text",
    service_location_address: "text",
    total: "text",
    date_due: "text",
    down_payment_status: "text",
    last_status_transition_time: "text",
    last_sent_at: "text",
    last_approved_at: "text",
    last_won_at: "text",
    last_lost_at: "text",
    last_cancelled_at: "text",
    last_modified_at: "text",
    last_viewed: "icon-text",
    estimated_arrival_time: "text",
    job_type: "text",
    priority: "text",
    assigned_technicians: "avatar",
    date_received: "text",
    last_scheduled_at: "text",
    last_active_at: "text",
    last_paused_at: "text",
    last_on_hold_at: "text",
    last_completed_at: "text",
    last_finalized_at: "text",
    estimated_duration: "text",
    amount_due: "text",
    last_paid_at: "text",
    last_voided_at: "text",
    last_forgiven_at: "text",
    vendor_name: "text",
    item_count: "text",
    subtotal: "text",
    shipping: "text",
    preferred_shipping: "text",
    tracking_number: "text",
    associated_jobs: "badge",
    date_issued: "text",
    payment_terms: "text",
    last_acknowledged_at: "text",
    last_in_transit_at: "text",
    last_delivered_at: "text",
    last_stocked_at: "text",
    name: "text",
    client_type_label: "text",
    service_locations_display: "text",
    default_invoice_net: "text",
    credit_limit: "text",
    outstanding_balance: "text",
    current_estimates_count: "text",
    current_jobs_count: "text",
    current_invoices_count: "text",
    description: "text",
    summary_template: "text",
    rate: "text",
    cost: "text",
    default_price: "text",
    discount: "text",
    tax_rate: "text",
    manufacturer: "text",
    part_number: "text",
    default_job_duration: "text",
    labels: "badge",
    lowest_inventory_status: "text",
    inventory_level: "text",
}

export const cellOrientationByHeader: {
    [key in keyof ObjectsData]?: "right" | "left"
} = {
    id: "left",
    is_draft: "left",
    track_inventory: "left",
    confirmed: "left",
    created_at: "left",
    custom_id: "left",
    default_unit_type: "left",
    down_payment_amount: "right",
    down_payment_amount_due: "left",
    initial_started_time: "left",
    is_active: "left",
    is_expired: "left",
    is_overdue: "left",
    is_past_due: "left",
    pricebook_item_type: "left",
    pricebook_item_type_label: "left",
    service_location_name: "left",
    status_color_override: "left",
    default_unit_type_label: "left",
    client_name: "left",
    state_label: "left",
    status_label: "left",
    service_name: "left",
    service_location_address: "left",
    total: "right",
    date_due: "left",
    down_payment_status: "left",
    last_status_transition_time: "left",
    last_sent_at: "left",
    last_approved_at: "left",
    last_won_at: "left",
    last_lost_at: "left",
    last_cancelled_at: "left",
    last_modified_at: "left",
    last_viewed: "left",
    estimated_arrival_time: "left",
    job_type: "left",
    priority: "left",
    assigned_technicians: "left",
    date_received: "left",
    last_scheduled_at: "left",
    last_active_at: "left",
    last_paused_at: "left",
    last_on_hold_at: "left",
    last_completed_at: "left",
    last_finalized_at: "left",
    estimated_duration: "right",
    amount_due: "right",
    last_paid_at: "left",
    last_voided_at: "left",
    last_forgiven_at: "left",
    vendor_name: "left",
    item_count: "right",
    subtotal: "right",
    shipping: "left",
    preferred_shipping: "left",
    tracking_number: "left",
    associated_jobs: "left",
    date_issued: "left",
    payment_terms: "left",
    last_acknowledged_at: "left",
    last_in_transit_at: "left",
    last_delivered_at: "left",
    last_stocked_at: "left",
    name: "left",
    client_type_label: "left",
    service_locations_display: "left",
    default_invoice_net: "left",
    credit_limit: "right",
    outstanding_balance: "left",
    current_estimates_count: "right",
    current_jobs_count: "right",
    current_invoices_count: "right",
    description: "left",
    summary_template: "left",
    rate: "left",
    cost: "right",
    default_price: "right",
    discount: "right",
    tax_rate: "left",
    manufacturer: "left",
    part_number: "left",
    default_job_duration: "right",
    labels: "left",
    lowest_inventory_status: "left",
    inventory_level: "left",
}
