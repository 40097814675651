import { useMemo } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { Select } from "@molecules"

import useTable from "@organisms/Table/Table.context"

import styles from "./PopoverSortingColumns.module.scss"
import { PopoverSortingColumnsProps } from "./PopoverSortingColumns.types"

export default function PopoverSortingColumns(props: PopoverSortingColumnsProps) {
    const { isVisible } = props

    const {
        sortingEnabledColumns,
        activeSorting,
        activeSortingIcons,
        activeSortingLabels,
        setCurrentSortToAscending,
        setCurrentSortToDescending,
        onSortingItemSelection,
        activeSortingColumn,
    } = useTable()

    const selectedColumnSortingText = useMemo(() => {
        return activeSorting?.desc ? activeSortingLabels?.desc : activeSortingLabels?.asc
    }, [activeSorting])

    const selectedColumnSortingIcon = useMemo(() => {
        return activeSorting?.desc ? activeSortingIcons?.desc : activeSortingIcons?.asc
    }, [activeSorting])

    return (
        <div className={styles.base}>
            <Select.Root>
                <Select.Input
                    text={activeSortingColumn?.header}
                    placeholder="Select Column"
                    className={styles.droplist}
                    isTabbable={isVisible}
                    size="sm"
                />
                {sortingEnabledColumns && (
                    <Select.List className={styles.droplist}>
                        <Select.ListHeader />
                        <Select.ListItemStack>
                            {sortingEnabledColumns.map((column) => {
                                return (
                                    <Select.ListItem
                                        key={generateRandomKey()}
                                        item={{
                                            value: column.accessorKey as string,
                                            label: column.header as string,
                                        }}
                                        onSelect={() => onSortingItemSelection(column)}
                                        isSelected={activeSorting?.id === column.accessorKey}
                                    />
                                )
                            })}
                        </Select.ListItemStack>
                    </Select.List>
                )}
            </Select.Root>
            <Select.Root>
                <Select.Input
                    icon={selectedColumnSortingIcon}
                    text={selectedColumnSortingText}
                    placeholder="Select Sort Order"
                    className={styles.droplist}
                    isTabbable={isVisible}
                    size="sm"
                />
                {sortingEnabledColumns && activeSorting && (
                    <Select.List className={styles.droplist}>
                        <Select.ListItemStack>
                            <Select.ListItem
                                item={{
                                    icon: activeSortingIcons?.asc,
                                    value: activeSorting?.id,
                                    label: activeSortingLabels?.asc ?? "",
                                }}
                                onSelect={setCurrentSortToAscending}
                                isSelected={!activeSorting?.desc}
                            />
                            <Select.ListItem
                                item={{
                                    icon: activeSortingIcons?.desc,
                                    value: activeSorting?.id,
                                    label: activeSortingLabels?.desc ?? "",
                                }}
                                onSelect={setCurrentSortToDescending}
                                isSelected={activeSorting?.desc}
                            />
                        </Select.ListItemStack>
                    </Select.List>
                )}
            </Select.Root>
        </div>
    )
}
