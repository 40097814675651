import { GetObjectDetailsRouteFunction } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP } from "@pages/Pricebook/PricebookList/views/PricebookTableView/PricebookTableView.utils"

import { BILLS_ROUTES } from "@routes/bills"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { JOB_SERIES_ROUTES } from "@routes/jobs-series"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import replaceSlugs from "./replaceSlugs"

export type ObjectsKeysMap = {
    [key in ObjectIndexName]: GetObjectDetailsRouteFunction
}

interface GetObjectDetailsRouteArgs {
    objectName?: ObjectIndexName
    hasDraftEstimateEditPermission?: boolean
    hasDraftJobEditPermission?: boolean
    hasDraftInvoiceEditPermission?: boolean
    hasDraftPurchaseOrderEditPermission?: boolean
    hasDraftBillEditPermission?: boolean
    serviceCompanySlug: User["service_company"]["slug"]
}

export default function getObjectDetailsRoute(
    args: GetObjectDetailsRouteArgs,
): ObjectsKeysMap | GetObjectDetailsRouteFunction {
    const {
        objectName,
        hasDraftEstimateEditPermission,
        hasDraftJobEditPermission,
        hasDraftInvoiceEditPermission,
        hasDraftPurchaseOrderEditPermission,
        hasDraftBillEditPermission,
        serviceCompanySlug,
    } = args

    const getObjectDetailsRoute: ObjectsKeysMap = {
        Estimate: (estimate: Estimate | EstimateIndex) => {
            const pathname =
                "is_draft" in estimate && estimate.is_draft && hasDraftEstimateEditPermission
                    ? ESTIMATES_ROUTES.UPDATE
                    : ESTIMATES_ROUTES.DETAILS
            return replaceSlugs(pathname, { service_company_slug: serviceCompanySlug, id: estimate.id })
        },
        Job: (job: Job | JobIndex) => {
            const pathname =
                "is_draft" in job && job.is_draft && hasDraftJobEditPermission
                    ? JOBS_ROUTES.UPDATE
                    : JOBS_ROUTES.DETAILS

            return replaceSlugs(pathname, { service_company_slug: serviceCompanySlug, id: job.id })
        },
        Invoice: (invoice: Invoice | InvoiceIndex) => {
            const pathname =
                "is_draft" in invoice && invoice.is_draft && hasDraftInvoiceEditPermission
                    ? INVOICES_ROUTES.UPDATE
                    : INVOICES_ROUTES.DETAILS
            return replaceSlugs(pathname, { service_company_slug: serviceCompanySlug, id: invoice.id })
        },
        PurchaseOrder: (purchaseOrder: PurchaseOrder | PurchaseOrderIndex) => {
            const pathname =
                "is_draft" in purchaseOrder && purchaseOrder.is_draft && hasDraftPurchaseOrderEditPermission
                    ? PURCHASE_ORDER_ROUTES.UPDATE
                    : PURCHASE_ORDER_ROUTES.DETAILS
            return replaceSlugs(pathname, { service_company_slug: serviceCompanySlug, id: purchaseOrder.id })
        },
        Bill: (bill: Bill | BillIndex) => {
            const pathname =
                "is_draft" in bill && bill.is_draft && hasDraftBillEditPermission
                    ? BILLS_ROUTES.UPDATE
                    : BILLS_ROUTES.DETAILS
            return replaceSlugs(pathname, { service_company_slug: serviceCompanySlug, id: bill.id })
        },
        Vendor: (vendor: Vendor | VendorIndex) => {
            return replaceSlugs(VENDOR_ROUTES.DETAILS, { service_company_slug: serviceCompanySlug, id: vendor.id })
        },
        JobSeries: (series: JobSeries | JobSeriesIndex) => {
            const pathname = replaceSlugs(JOB_SERIES_ROUTES.DETAILS, {
                service_company_slug: serviceCompanySlug,
                id: series.id,
            })
            const params = new URLSearchParams()
            params.set("phase", "open")
            params.set("tab", "All Open")
            params.set("keywords", "")
            params.set("filter__series", series.id)
            return `${pathname}?${params.toString()}`
        },
        Client: (client: Client | ClientIndex) => {
            return replaceSlugs(CLIENTS_ROUTES.DETAILS, { service_company_slug: serviceCompanySlug, id: client.id })
        },
        PriceBookItem: (pricebookItem: PricebookItem | PricebookItemIndex) => {
            const pathname = pricebookItem.confirmed ? PRICEBOOK_ITEMS_ROUTES.UPDATE : PRICEBOOK_ITEMS_ROUTES.REVIEW
            const query = `?type=${PRICEBOOK_ITEM_TYPE_URL_PARAM_MAP[pricebookItem.pricebook_item_type]}`
            return `${replaceSlugs(pathname, { service_company_slug: serviceCompanySlug, id: String(pricebookItem.id) })}${query}`
        },
    }

    if (objectName) {
        return getObjectDetailsRoute[objectName] ?? (() => "")
    } else {
        return getObjectDetailsRoute
    }
}
