import EventAccordion from '../../core/components/EventAccordion'
import { valueIsDefined } from '../../core/utils/utils'


class BillFeedAccordion extends EventAccordion {

    fetchData = async () => {
        const { billID } = this.props

        if (valueIsDefined(billID)) {
            const endpoint = DjangoUrls["bills:api-bills-feed-list"](window.MARKETPLACE_ENTITY_SLUG, billID)
            const response = await fetch(endpoint)
            return await response.json()
        }
        else {
            return null
        }
    }

    getTitle = () => {
        return "Bill Timeline"
    }

    getEmphasis = () => {
        return "time"
    }
}

export default BillFeedAccordion;
