import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { BILLS_ROUTES } from "@routes/bills"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    BILLS_LIST_CONTROLS_ACTIVE_TAB,
    BILLS_LIST_CONTROLS_KEYWORD_SEARCH,
    BILLS_LIST_CONTROLS_ORDERING,
    BILLS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { BillTableViewTabs } from "./BillTableViewTabs"

export default function BillTableView() {
    const [createRoute] = useSlugExtractor([BILLS_ROUTES.CREATE])

    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={BILLS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={BILLS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={BILLS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={BILLS_LIST_CONTROLS_ORDERING}
            listTitle="Bills"
            objectType="bill"
            objectIndexName="Bill"
            objectName="Bill"
            defaultActivePhase="open"
            objectCreateRoute={createRoute}
            isRowClickable={hasPermission("bills_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("bills_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <BillTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<Bill>
                    accessorKey="id"
                    header="ID"
                    size={144}
                    cell={({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Bill>
                    accessorKey="status_label"
                    header="Status"
                    size={176}
                    cell={({ getValue, row }) => (
                        <Table.Cells.StatusCell status={getValue()} dateDue={row.original.date_due} />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Bill>
                    accessorKey="state_label"
                    header="State"
                    size={176}
                    cell={({ getValue, row }) => (
                        <Table.Cells.StatusCell status={getValue()} dateDue={row.original.date_due} />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Bill>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as BillLabel[])?.map((label: BillLabel) => label.name)}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Bill>
                    accessorKey="subtotal"
                    header="Total"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<Bill>
                    accessorKey="vendor_name"
                    header="Vendor Name"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Bill>
                    accessorKey="date_due"
                    header="Due Date"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Bill>
                    accessorKey="last_status_transition_time"
                    header="Status Changed"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Bill>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Bill>
                    accessorKey="last_received_at"
                    header="Received"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Bill>
                    accessorKey="last_paid_at"
                    header="Paid"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Bill>
                    accessorKey="last_voided_at"
                    header="Voided"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
