import InvoiceListCard from "@legacy/invoices/components/InvoiceListCard"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { CardList, Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { InvoiceTableViewTabs } from "@pages/Invoices/InvoiceList/views/InvoiceTableView/InvoiceTableViewTabs"

import { INVOICES_ROUTES } from "@routes/invoices"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    INVOICES_LIST_CONTROLS_ACTIVE_TAB,
    INVOICES_LIST_CONTROLS_KEYWORD_SEARCH,
    INVOICES_LIST_CONTROLS_ORDERING,
    INVOICES_LIST_CONTROLS_PHASE,
} from "@constants/storage"

export default function InvoiceCardView() {
    const [createRoute] = useSlugExtractor([INVOICES_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={INVOICES_LIST_CONTROLS_PHASE}
            activeTabStorageKey={INVOICES_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={INVOICES_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={INVOICES_LIST_CONTROLS_ORDERING}
            listTitle="Invoices"
            objectType="invoice"
            objectIndexName="Invoice"
            objectName="Invoice"
            defaultActivePhase="open"
            objectCreateRoute={createRoute}
            isRowClickable={hasPermission("invoices_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("invoices_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <InvoiceTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <CardList CardComponent={InvoiceListCard} />
        </Table.Provider>
    )
}
