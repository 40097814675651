// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyLabel-base__smwcf{color:var(--gray-12);-webkit-user-select:none;-moz-user-select:none;user-select:none;font:var(--font-mobile-heading-03);font-feature-settings:var(--feature-settings-mobile-heading-03);letter-spacing:var(--letter-spacing-mobile-heading-03)}@media only screen and (min-width: 64rem){.PropertyLabel-base__smwcf{font:var(--font-global-heading-03);font-feature-settings:var(--feature-settings-global-heading-03);letter-spacing:var(--letter-spacing-global-heading-03)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PropertyLabel-base__smwcf"
};
export default ___CSS_LOADER_EXPORT___;
