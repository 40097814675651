import { useState } from "react"

import useLocalStorage from "@hooks/useLocalStorage"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { UseTableStateProps } from "@organisms/Table/Table.types"
import { TableViewTabProps } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { LIST_SEARCH_PARAM_KEYWORD_SEARCH, LIST_SEARCH_PARAM_PHASE } from "@constants/searchParams"

const phaseOverride = extractURLSearchParam(LIST_SEARCH_PARAM_PHASE) as ObjectPhase
const searchKeywordsOverride = extractURLSearchParam(LIST_SEARCH_PARAM_KEYWORD_SEARCH)

export default function useTableState(props: UseTableStateProps) {
    const {
        activePhaseStorageKey,
        activeTabStorageKey,
        searchKeywordsStorageKey,
        defaultActivePhase,
        defaultFilters,
    } = props

    const [activePhase, setActivePhase] = useLocalStorage<ObjectPhase>(
        activePhaseStorageKey,
        defaultActivePhase,
        phaseOverride,
    )
    const [activeTab, setActiveTab] = useLocalStorage<TableViewTabProps | undefined>(activeTabStorageKey, undefined)
    const [searchKeywords, setSearchKeywords] = useLocalStorage<string>(
        searchKeywordsStorageKey,
        "",
        searchKeywordsOverride,
    )
    const [filters, setFilters] = useState<URLSearchParams | null>(defaultFilters ?? null)

    return {
        activePhase,
        setActivePhase,
        activeTab,
        setActiveTab,
        searchKeywords,
        setSearchKeywords,
        filters,
        setFilters,
    }
}
