import { TableDataType } from "@organisms/Table/Table.types"

import getTableSortingIcon from "./getTableSortingIcon"

export default function getTableSortingIconsPairByDataType(dataType: TableDataType) {
    const asc = getTableSortingIcon({
        dataType,
        ascending: true,
    })

    const desc = getTableSortingIcon({
        dataType,
        ascending: false,
    })

    return {
        asc,
        desc,
    }
}
