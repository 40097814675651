import { SortingState } from "@tanstack/react-table"

export default function getSortingStateFromOrdering(ordering: string): SortingState {
    if (ordering) {
        return ordering.split(",").map((order) => ({
            id: order.startsWith("-") ? order.replace("-", "") : order,
            desc: order.startsWith("-"),
        }))
    } else {
        return []
    }
}
