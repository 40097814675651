import { DomClassList, EventStore } from "@bryntum/schedulerpro"

import {
    EventModel,
    JobTimelineScheduler,
    OverlappingRegion,
    SchedulerEventModel,
    SchedulerResourceModel,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"

export default function useJobTimelineViewOverlappingUtils() {
    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const handleOverlappingEvents = () => {
        if (!schedulerPro.current) {
            return
        }

        const scheduler = schedulerPro.current.instance as JobTimelineScheduler
        const resourcesEventsMap = groupEventsByResource(scheduler.eventStore)

        const overlappingRegions = findOverlappingRegions(resourcesEventsMap)

        overlappingRegions.forEach((region) => addOverlappingRegionToScheduler(region, scheduler))
    }

    const groupEventsByResource = (eventStore: EventStore) => {
        const resourcesEventsMap: {
            [resourceId: string]: EventModel<CalendarEvent>[]
        } = {}

        eventStore.forEach((event: EventModel<CalendarEvent>) => {
            const { resourceId } = event

            if (resourceId === undefined || resourceId === null) {
                return
            } else if (!resourcesEventsMap[resourceId]) {
                resourcesEventsMap[resourceId] = []
            }

            const eventIsMultiAssigned = event._data.assigned_technicians.length > 1

            if (eventIsMultiAssigned) {
                event._data.assigned_technicians.forEach((technician: CalendarTechnician) => {
                    if (!resourcesEventsMap[technician.id]) {
                        resourcesEventsMap[technician.id] = []
                    }

                    resourcesEventsMap[technician.id].push(event)
                })
            } else {
                resourcesEventsMap[resourceId].push(event)
            }
        })

        return resourcesEventsMap
    }

    const findOverlappingRegions = (resourcesEventsMap: { [resourceId: string]: EventModel<CalendarEvent>[] }) => {
        const overlappingRegions: OverlappingRegion[] = []
        Object.entries(resourcesEventsMap).forEach(([resourceId, events]) => {
            events.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())

            let currentOverlap: OverlappingRegion | null = null
            events.forEach((event, index, arr) => {
                const nextEvent = arr[index + 1]
                if (isOverlapping(event, nextEvent, currentOverlap)) {
                    currentOverlap = updateOverlapRegion(currentOverlap, event, resourceId)
                    if (isLastOverlappingEvent(nextEvent, currentOverlap)) {
                        overlappingRegions.push(finalizeCurrentOverlap(currentOverlap))
                        currentOverlap = null
                    }
                }
            })
        })

        return overlappingRegions
    }

    const findOverlappingEvents = (
        eventRecord: SchedulerEventModel,
        resourceRecord: SchedulerResourceModel<CalendarTechnician>,
        startDate: Date,
        endDate: Date,
    ) => {
        if (schedulerPro.current) {
            return schedulerPro.current.instance.eventStore.query((record: SchedulerEventModel) => {
                return (
                    record !== eventRecord && // Exclude the dragged event itself
                    record.resourceId === resourceRecord.id && // Check events on the same resource
                    record.startDate < endDate &&
                    record.endDate > startDate
                )
            })
        } else {
            return []
        }
    }

    const isOverlapping = (
        event: EventModel<CalendarEvent>,
        nextEvent: EventModel<CalendarEvent>,
        currentOverlap: OverlappingRegion | null,
    ) => {
        return (
            (nextEvent && event.endDate > nextEvent.startDate) ||
            (currentOverlap && (event.endDate > currentOverlap.startDate || currentOverlap.endDate > event.startDate))
        )
    }

    const updateOverlapRegion = (
        currentOverlap: OverlappingRegion | null,
        event: EventModel<CalendarEvent>,
        resourceId: string,
    ): OverlappingRegion => {
        if (!currentOverlap) {
            return {
                startDate: event.startDate,
                endDate: event.endDate,
                resourceId,
                amountOfItems: 1,
            } as OverlappingRegion
        }
        if (event.endDate > currentOverlap.endDate) {
            currentOverlap.endDate = new Date(event.endDate)
        }
        if (currentOverlap.amountOfItems !== undefined) {
            currentOverlap.amountOfItems += 1
        } else {
            currentOverlap.amountOfItems = 1
        }
        return currentOverlap
    }

    const isLastOverlappingEvent = (nextEvent: EventModel<CalendarEvent>, currentOverlap: OverlappingRegion) => {
        return !nextEvent || nextEvent.startDate >= currentOverlap.endDate
    }

    const finalizeCurrentOverlap = (overlap: OverlappingRegion) => {
        overlap.cls = `job-timeline-view__overlapping-region`
        overlap.id = `${overlap.startDate.toString()}-${overlap.resourceId}`
        return overlap
    }

    const addOverlappingRegionToScheduler = (region: OverlappingRegion, scheduler: JobTimelineScheduler) => {
        const overlappingRegion: OverlappingRegion & { id: string } = {
            ...region,
            id: `${region.startDate.toString()}-${region.resourceId}`,
        }
        const counter = {
            ...region,
            cls: `job-timeline-view__overlapping-region--counter`,
            id: `${region.startDate.toString()}-${region.resourceId}-counter`,
            isOverlappingTimeRange: true,
            amountOfItems: region.amountOfItems,
        }

        if (!scheduler.resourceTimeRangeStore.getById(overlappingRegion.id)) {
            scheduler.resourceTimeRangeStore.add(overlappingRegion)
            scheduler.resourceTimeRangeStore.add(counter)
        }

        scheduler.refreshRows()
    }

    const removeAllOverlappingRegionFeedback = () => {
        if (!schedulerPro.current) {
            return
        }

        const scheduler = schedulerPro.current.instance as JobTimelineScheduler

        const items = scheduler.resourceTimeRangeStore.query((timeRange: EventModel<CalendarEvent>) => {
            const classes = timeRange.cls as DomClassList

            return (
                classes.contains("job-timeline-view__overlapping-region") ||
                classes.contains("job-timeline-view__overlapping-region--counter")
            )
        })

        scheduler.resourceTimeRangeStore.remove(items)
    }

    return {
        handleOverlappingEvents,
        removeAllOverlappingRegionFeedback,
        findOverlappingEvents,
    }
}
