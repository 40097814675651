import { Component, Fragment } from "react";
import LineItemListDisplay from "./LineItemListDisplay";


class LineItemListDisplayGroup extends Component {

    render = () => {
        const {
            object,
            labelPrefix="",
            formatCurrencyValue,
            useTaxes=false,
            isEstimateLineItem=false,
            isJobLineItem=false,
            isInvoiceLineItem=false,
            isBillLineItem=false,
            isShownToClients=false,
            asServiceSummary=false,
            showPricebookPartDisplay=false,
        } = this.props

        return (
            <Fragment>
                <LineItemListDisplay
                    fieldName="service_charges"
                    fieldLabel={asServiceSummary ? "Technician Service Summary" : `${labelPrefix}Service Charges`}
                    parent={object}
                    lineItemList={object.service_charges}
                    emptyMessage="No service charges"
                    formatCurrencyValue={formatCurrencyValue}
                    useTaxes={useTaxes}
                    isEstimateLineItem={isEstimateLineItem}
                    isJobLineItem={isJobLineItem}
                    isInvoiceLineItem={isInvoiceLineItem}
                    isBillLineItem={isBillLineItem}
                    isShownToClients={isShownToClients}
                ></LineItemListDisplay>
                {
                    !asServiceSummary && <>
                        <LineItemListDisplay
                            fieldName="parts"
                            fieldLabel={`${labelPrefix}Parts and Materials`}
                            parent={object}
                            lineItemList={object.parts}
                            emptyMessage="No parts and materials charges"
                            formatCurrencyValue={formatCurrencyValue}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                            isShownToClients={isShownToClients}
                            showPricebookPartDisplay={showPricebookPartDisplay}
                        ></LineItemListDisplay>
                        <LineItemListDisplay
                            fieldName="other_charges"
                            fieldLabel={`${labelPrefix}Trip Charges, Diagnostic Fees, etc.`}
                            parent={object}
                            lineItemList={object.other_charges}
                            emptyMessage="No miscellaneous charges"
                            formatCurrencyValue={formatCurrencyValue}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                            isShownToClients={isShownToClients}
                        ></LineItemListDisplay>
                        <LineItemListDisplay
                            fieldName="discounts"
                            fieldLabel={`${labelPrefix}Discounts`}
                            parent={object}
                            lineItemList={object.discounts}
                            emptyMessage="No discounts"
                            formatCurrencyValue={formatCurrencyValue}
                            useTaxes={useTaxes}
                            isEstimateLineItem={isEstimateLineItem}
                            isJobLineItem={isJobLineItem}
                            isInvoiceLineItem={isInvoiceLineItem}
                            isBillLineItem={isBillLineItem}
                            isShownToClients={isShownToClients}
                        ></LineItemListDisplay>
                    </>
                }
            </Fragment>
        )
    }
}

export default LineItemListDisplayGroup;
