import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import ClientCreateContainer from "@legacy/clients/ClientCreateContainer"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { CLIENTS_ROUTES } from "@routes/clients"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

export default function ClientCreate() {
    const isDesktop = useIsDesktop()

    const [listRoute] = useSlugExtractor([CLIENTS_ROUTES.LIST])
    const { addToastToQueue } = useToastQueue()
    const { hasPermission } = useHasAccess()

    const backDestination = hasPermission("clients_list_permission", PERMISSION_LEVEL.FULL) ? listRoute : undefined

    return (
        <AppFrame.Root pageId="ClientCreate">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            iconOnly={!isDesktop}
                            icon={icon({ name: "user-circle", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "user-circle", style: "solid", family: "sharp" })}
                            label="Clients"
                            destination={backDestination}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="New Client" backDestination={backDestination} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <ClientCreateContainer formMode="ADD_CLIENT" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
