import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import Spinner from "@legacy/core/components/Spinner"
import "@upsolve-labs/common/styles/global.css"
import { UpsolveDashboard } from "@upsolve-labs/sdk"
import { useTheme } from "next-themes"

import useHasAccess from "@hooks/useHasAccess"
import useUser from "@hooks/useUser"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import styles from "./Reports.module.scss"
import { useReportsTokenQuery } from "./Reports.store"

function ReportsDashboard() {
    const { user } = useUser()
    const { hasPermission } = useHasAccess()
    const { resolvedTheme } = useTheme()
    const { data: tokenResult, isLoading } = useReportsTokenQuery(user)

    if (!user) {
        return null
    }

    if (isLoading) {
        return (
            <div className={styles.base}>
                <Spinner />
            </div>
        )
    } else if (tokenResult && tokenResult.status === "success") {
        return (
            <div className={styles.base}>
                <UpsolveDashboard
                    dashboardId={window.DASHBOARD_ID}
                    tenantJWT={tokenResult.data.token}
                    tenantEditingPermissions={{
                        addChart: hasPermission("reports_edit_permission", PERMISSION_LEVEL.FULL),
                        addFilter: false,
                        editCharts: hasPermission("reports_edit_permission", PERMISSION_LEVEL.FULL),
                        removeChart: hasPermission("reports_edit_permission", PERMISSION_LEVEL.FULL),
                        createChart: false,
                    }}
                    theme={resolvedTheme as "light" | "dark"}
                    globalDashboardName="Default"
                    hideHeader={true}
                />
            </div>
        )
    } else {
        return <div className={styles.base}>Error loading reports dashboard.</div>
    }
}

export default function Reports() {
    return (
        <AppFrame.Root pageId="Reports">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Reports"
                            icon={icon({ name: "chart-mixed", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "chart-mixed", style: "solid", family: "sharp" })}
                        />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <ReportsDashboard />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
