import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import TextField from "../../core/fields/TextField";
import { JobOriginTypes, JobStatuses } from "../../core/utils/enums";
import JobSubStatusSearchOrCreateSelect, { subStatusToSubStatusOption } from "../inputs/JobSubStatusSearchOrCreateSelect";


class JobStartForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { job, mode, startData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text={mode === "RESUME_JOB" ? "Resume" : "Start" } handler={event => requestAction(mode === "RESUME_JOB" ? "JOB_RESUME_SUBMIT" : "JOB_START_SUBMIT")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { job, mode, startData, resumeData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, updateSubStatusSelection, selectedSubStatus, returnScroll } = this.props
        let placeholderText
        let infoBannerText

        if (mode === "RESUME_JOB") {
            if (job.status === JobStatuses.completed){
                placeholderText = ""
                infoBannerText = "Resuming this job will return it to Active status. A new signature will need to be collected to complete this job."
            }
            else {
                placeholderText = ""
                infoBannerText = "Resuming a job resumes the clock. You can always pause this job again if you need to"
            }
        }
        else {
            if (job.initial_started_time) {
                placeholderText = ""
                infoBannerText = "Resuming a job resumes the clock. You can always pause this job again if you need to"
            }
            else {
                placeholderText = ""
                infoBannerText = "Starting a job starts the clock. You can always pause a job if you need to."
            }
        }

        if (selectedSubStatus?.reason_placeholder) {
            placeholderText = selectedSubStatus.reason_placeholder
        }

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Active">
                    <div className="data-panel__form" aria-label="Job Active Form">
                        <p className="data-panel__form__caption">
                            {
                                job.status === JobStatuses.active
                                ?
                                "Please confirm you'd like to update this job's active status."
                                :
                                `Please confirm you'd like to ${mode === "RESUME_JOB" ? 'resume' : 'start'} this job.`
                            }
                        </p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Job ID"
                            fieldValue={job.custom_id ? job.custom_id : job.id}
                        ></BasicDisplayField>
                        {
                            job.origin_type !== JobOriginTypes.roopairs && (
                                <BasicDisplayField
                                    fieldName="origin_id"
                                    fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                    fieldValue={job.origin_id}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={job.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="is_job_walk"
                            fieldLabel="Job Type"
                            fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                        ></BasicDisplayField>

                        {job.status !== JobStatuses.active && <Banner type="info" text={infoBannerText} />}

                        {
                            window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST && (
                                <SearchCreateSelectField
                                    fieldName="substatus"
                                    fieldLabel="Sub-Status"
                                    fieldValue={selectedSubStatus}
                                    inputComponent={
                                        <JobSubStatusSearchOrCreateSelect
                                            onSelectionChange={selectedOption => updateSubStatusSelection(selectedOption != null ? selectedOption.object : null)}
                                            defaultSelected={selectedSubStatus !== null ? subStatusToSubStatusOption(selectedSubStatus) : null}
                                            showCreateButton={false}
                                            selectedStatus={JobStatuses.active}
                                        ></JobSubStatusSearchOrCreateSelect>
                                    }
                                    showButton={false}
                                    errors={errors}
                                ></SearchCreateSelectField>
                            )
                        }
                        {
                            mode === "RESUME_JOB"
                            ?
                            <TextField
                                key={selectedSubStatus?.id}
                                fieldName="resume_message"
                                fieldLabel={selectedSubStatus !== null && selectedSubStatus.reason_label !== "" ? selectedSubStatus.reason_label : "Resume Reason"}
                                fieldValue={resumeData?.resume_message || ""}
                                fieldOnChange={resume_message => onFormDataChange("resume_message", resume_message || "")}
                                rows={3}
                                placeholder={placeholderText}
                                optional={selectedSubStatus === null || !selectedSubStatus.reason_required}
                                errors={errors}
                            ></TextField>
                            :
                            <TextField
                                key={selectedSubStatus?.id}
                                fieldName="start_message"
                                fieldLabel={selectedSubStatus !== null && selectedSubStatus.reason_label !== "" ? selectedSubStatus.reason_label : "Start Reason"}
                                fieldValue={startData?.start_message || ""}
                                fieldOnChange={start_message => onFormDataChange("start_message", start_message || "")}
                                rows={3}
                                placeholder={placeholderText}
                                optional={selectedSubStatus === null || !selectedSubStatus.reason_required}
                                errors={errors}
                            ></TextField>

                        }
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobStartForm;
