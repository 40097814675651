import determineDaysDue from "@utils/determineDaysDue"

import { Badge, StatusDot } from "@atoms"

import { StatusBadgeProps } from "./StatusBadge.types"

export default function StatusBadge(props: StatusBadgeProps) {
    const { status, dateDue, dotStatusOverride } = props

    const isOutstandingOrOverdue = ["Outstanding", "Overdue"].includes(status)

    const statusText = isOutstandingOrOverdue && dateDue ? determineDaysDue(dateDue) : status

    return (
        <Badge
            colorScheme="gray"
            size="md"
            variant="subtle"
            type="default"
            showTooltip={true}
            statusDot={
                <StatusDot
                    status={dotStatusOverride || status}
                    isOutstandingOrOverdue={isOutstandingOrOverdue}
                    dateDue={dateDue}
                />
            }
        >
            {statusText}
        </Badge>
    )
}
