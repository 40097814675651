import { PurchaseOrderStatuses } from "@legacy/core/utils/enums"

import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"

export const PurchaseOrderTableViewTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_open"
            phase="open"
            title="All Open"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.pending],
                ["status", PurchaseOrderStatuses.sent],
                ["status", PurchaseOrderStatuses.acknowledged],
                ["status", PurchaseOrderStatuses.in_transit],
                ["status", PurchaseOrderStatuses.delivered],
                ["status", PurchaseOrderStatuses.stocked],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="pending"
            title="Pending"
            phase="open"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.pending],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="open"
            title="Open"
            phase="open"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.sent],
                ["status", PurchaseOrderStatuses.acknowledged],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="in_transit"
            title="In Transit"
            phase="open"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.in_transit],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="delivered"
            title="Delivered"
            phase="open"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.delivered],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="stocked"
            title="Stocked"
            phase="open"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.stocked],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_closed"
            title="All Closed"
            phase="closed"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.paid],
                ["status", PurchaseOrderStatuses.cancelled],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="paid"
            title="Paid"
            phase="closed"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.paid],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
        <TableViewTab
            id="cancelled"
            title="Cancelled"
            phase="closed"
            endpointKwargs={[
                ["status", PurchaseOrderStatuses.cancelled],
                ["ordering", "date_issued"],
            ]}
            listEndpoint={PURCHASE_ORDER_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PURCHASE_ORDER_ROUTES.COUNT}
        />
    </TableViewTabs>
)
