import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import BillCreateContainer from "@legacy/bills/BillCreateContainer"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { BILLS_ROUTES } from "@routes/bills"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

export default function BillCreate() {
    const isDesktop = useIsDesktop()

    const [listRoute] = useSlugExtractor([BILLS_ROUTES.LIST])
    const { addToastToQueue } = useToastQueue()
    const { hasPermission } = useHasAccess()

    const backDestination = hasPermission("bills_list_permission", PERMISSION_LEVEL.FULL) ? listRoute : undefined

    return (
        <AppFrame.Root pageId="BillCreate">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label="Bills"
                            icon={icon({ name: "file-invoice-dollar", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "file-invoice-dollar", style: "solid", family: "sharp" })}
                            destination={backDestination}
                            iconOnly={!isDesktop}
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="New Bill" backDestination={backDestination} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <BillCreateContainer formMode="ADD_BILL" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
