import { Badge, Tooltip } from "@atoms"

import { Table } from "@organisms"

import styles from "./BadgesCell.module.scss"
import { BadgesCellProps } from "./BadgesCell.types"

export default function BadgesCell(props: BadgesCellProps) {
    const { badges } = props

    const badgeQuantity = badges.length
    const twoBadges = badgeQuantity === 2
    const multipleBadges = badgeQuantity > 2
    const singleBadge = badgeQuantity === 1

    if (multipleBadges) {
        return (
            <Tooltip label={badges.join("\n")}>
                <div className={styles.base}>
                    <Badge colorScheme="gray" size="md" variant="subtle" type="default">
                        {badges[0]}
                    </Badge>
                    <Badge colorScheme="gray" size="md" variant="subtle" type="default">{`+${
                        badgeQuantity - 1
                    } more`}</Badge>
                </div>
            </Tooltip>
        )
    } else if (twoBadges) {
        return (
            <Tooltip label={badges.join("\n")}>
                <div className={styles.base}>
                    <Badge colorScheme="gray" size="md" variant="subtle" type="default">
                        {badges[0]}
                    </Badge>
                    <Badge colorScheme="gray" size="md" variant="subtle" type="default">
                        {badges[1]}
                    </Badge>
                </div>
            </Tooltip>
        )
    } else if (singleBadge) {
        return (
            <Tooltip label={badges.join("\n")}>
                <div className={styles.base}>
                    <Badge colorScheme="gray" size="md" variant="subtle" type="default">
                        {badges[0]}
                    </Badge>
                </div>
            </Tooltip>
        )
    } else {
        return <Table.Cells.NoValueCell />
    }
}
