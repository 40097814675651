import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import { IconButton, Tooltip } from "@atoms/index"

import { Slider } from "@molecules/index"

import { JobTimelineScheduler } from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"
import useJobTimelineViewBryntumInstances from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewEventHandlers from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useJobTimelineViewStates from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewStates"

import { MAXIMUM_ZOOM, MINIMUM_ZOOM, ZOOM_LIMITS } from "@constants/schedulerZoom"

import styles from "./JobTimelineViewZoom.module.scss"

export default function JobTimelineViewZoom() {
    const { schedulerPro } = useJobTimelineViewBryntumInstances()
    const { schedulerZoom, timeFrameType } = useJobTimelineViewStates()
    const { onZoomChange } = useJobTimelineViewEventHandlers()

    const isDesktop = useIsDesktop()

    const activeTimeFrameTypeZoom = schedulerZoom[timeFrameType]

    const isAtMinimumZoom = activeTimeFrameTypeZoom === ZOOM_LIMITS[timeFrameType].min
    const isAtMaximumZoom = activeTimeFrameTypeZoom === ZOOM_LIMITS[timeFrameType].max

    useEffect(() => {
        // see comment in adjustZoom function
        const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler
        if (schedulerInstance) {
            schedulerInstance.infiniteScroll = true
        }
    }, [schedulerZoom])

    const zoomIn = () => {
        onZoomChange(activeTimeFrameTypeZoom + 2)
    }

    const zoomOut = () => {
        onZoomChange(activeTimeFrameTypeZoom - 2)
    }

    if (!isDesktop) {
        return null
    } else {
        return (
            <div className={styles.base}>
                <Tooltip label="Zoom out">
                    <IconButton
                        icon={icon({
                            name: "magnifying-glass-minus",
                            style: "regular",
                            family: "sharp",
                        })}
                        colorScheme="gray"
                        size="sm"
                        variant="ghost"
                        isDisabled={isAtMinimumZoom}
                        onClick={zoomOut}
                        noDebounce={true}
                    />
                </Tooltip>

                <Slider
                    className={styles.slider}
                    value={[activeTimeFrameTypeZoom]}
                    max={MAXIMUM_ZOOM}
                    min={MINIMUM_ZOOM}
                    step={2}
                    aria-labelledby="Zoom"
                    onValueChange={(value) => onZoomChange(value[0])}
                />

                <Tooltip label="Zoom in">
                    <IconButton
                        icon={icon({
                            name: "magnifying-glass-plus",
                            style: "regular",
                            family: "sharp",
                        })}
                        colorScheme="gray"
                        size="sm"
                        variant="ghost"
                        isDisabled={isAtMaximumZoom}
                        noDebounce={true}
                        onClick={zoomIn}
                    />
                </Tooltip>
            </div>
        )
    }
}
