import { roundHalfEven } from "number-rounding"

type LineItemFields = {
    quantity: string
    price: string
    cost?: string
}

type ObjectFields = {
    service_charges?: LineItemFields[]
    parts?: LineItemFields[]
    other_charges?: LineItemFields[]
    discounts?: LineItemFields[]
}

type ProfitBreakdown = {
    service: number
    part: number
    other: number
    discount: number
}

type ProfitResult = {
    profit: number
    profitBreakdown: ProfitBreakdown
}

function calculateProfit(obj: ObjectFields): ProfitResult {
    let profit = 0
    const breakdown: ProfitBreakdown = { service: 0, part: 0, other: 0, discount: 0 }

    const calculateLineItemProfit = (lineItem: LineItemFields, type: keyof ProfitBreakdown) => {
        const quantity = parseFloat(lineItem.quantity ?? "0")
        const price = parseFloat(lineItem.price ?? "0")
        const cost = parseFloat(lineItem.cost ?? "0")

        const itemProfit = roundHalfEven(price * quantity, 2) - roundHalfEven(cost * quantity, 2)
        breakdown[type] += itemProfit
        profit += itemProfit
    }

    const categories: { [key in keyof ProfitBreakdown]: LineItemFields[] } = {
        service: obj.service_charges ?? [],
        part: obj.parts ?? [],
        other: obj.other_charges ?? [],
        discount: obj.discounts ?? [],
    }

    for (const [type, items] of Object.entries(categories)) {
        items.forEach((lineItem: LineItemFields) => calculateLineItemProfit(lineItem, type as keyof ProfitBreakdown))
    }

    return { profit, profitBreakdown: breakdown }
}

export default calculateProfit
