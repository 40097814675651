import InvoiceListCard from "@legacy/invoices/components/InvoiceListCard"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { CardList, Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { EstimateTableViewTabs } from "@pages/Estimates/EstimateList/views/EstimateTableView/EstimateTableViewTabs"

import { ESTIMATES_ROUTES } from "@routes/estimates"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    ESTIMATES_LIST_CONTROLS_ACTIVE_TAB,
    ESTIMATES_LIST_CONTROLS_KEYWORD_SEARCH,
    ESTIMATES_LIST_CONTROLS_ORDERING,
    ESTIMATES_LIST_CONTROLS_PHASE,
} from "@constants/storage"

export default function EstimateCardView() {
    const [createRoute] = useSlugExtractor([ESTIMATES_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={ESTIMATES_LIST_CONTROLS_PHASE}
            activeTabStorageKey={ESTIMATES_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={ESTIMATES_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={ESTIMATES_LIST_CONTROLS_ORDERING}
            listTitle="Estimates"
            objectType="estimate"
            objectIndexName="Estimate"
            objectName="Estimate"
            defaultActivePhase="open"
            objectCreateRoute={createRoute}
            isRowClickable={hasPermission("estimates_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("estimates_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <EstimateTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <CardList CardComponent={InvoiceListCard} />
        </Table.Provider>
    )
}
