import nr from 'number-rounding';


function calculateBillAmounts(bill) {

    let subtotal = 0
    let amountPaid = 0
    let amountPaidFromDownPayments = 0
    let amountPaidFromPayments = 0

    // Subtotal
    const serviceCharges = bill.service_charges || []
    const parts = bill.parts || []
    const otherCharges = bill.other_charges || []
    const discounts = bill.discounts || []

    const addToSubtotal = (lineItem) => {subtotal += nr.roundHalfEven(parseFloat(lineItem.quantity || 0) * parseFloat(lineItem.price || 0), 2)}

    serviceCharges.forEach(addToSubtotal)
    parts.forEach(addToSubtotal)
    otherCharges.forEach(addToSubtotal)
    discounts.forEach(addToSubtotal)

    if (serviceCharges.length === 0 && parts.length === 0 && otherCharges.length === 0 && discounts.length === 0 && bill.line_items !== undefined && bill.line_items.length !== 0) {
        bill.line_items.forEach(addToSubtotal)
    }

    // Total
    let total = subtotal
    total = (total.toFixed(2) === "-0.00" ? 0 : total)

    // Amount Paid
    const payments = bill.payments || []
    payments.forEach((payment) => {
        const amount = parseFloat(payment.amount || 0)
        amountPaid += amount

        if (payment.from_down_payment) {
            amountPaidFromDownPayments += amount
        }
        else {
            amountPaidFromPayments += amount
        }
    })

    // Amount Due
    let amountDue = total - amountPaid
    amountDue = (amountDue.toFixed(2) === "-0.00" ? 0 : amountDue)

    return {subtotal, total, amountPaid, amountPaidFromDownPayments, amountPaidFromPayments, amountDue}
}

function getBillStatusBarLabel(bill) {
    let statusBarLabel = bill.status_label

    if (bill.status_label === "Draft") {
        statusBarLabel = "Draft Preview"
    }

    return statusBarLabel
}

export { calculateBillAmounts, getBillStatusBarLabel };
