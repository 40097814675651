import { useVirtualizer } from "@tanstack/react-virtual"
import clsx from "clsx"
import { useEffect, useRef } from "react"
import { TableDataType, TableProps } from "roopairs/pwa/src/components/organisms/Table/Table.types"

import useHasAccess from "@hooks/useHasAccess"
import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useIsDesktop from "@hooks/useIsDesktop"
import useIsScrolling from "@hooks/useIsScrolling"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import getObjectDetailsRoute from "@utils/getObjectDetailsRoute"
import { ifSpaceOrEnter } from "@utils/keyboard"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import useTable from "@organisms/Table/Table.context"
import TableBodyCell from "@organisms/Table/TableBodyCell/TableBodyCell"
import TableEmptyState from "@organisms/Table/TableEdgeStates/TableEmptyState/TableEmptyState"
import TableErrorState from "@organisms/Table/TableEdgeStates/TableErrorState/TableErrorState"
import TableLoadingState from "@organisms/Table/TableEdgeStates/TableLoadingState/TableLoadingState"
import TableNoResultsState from "@organisms/Table/TableEdgeStates/TableNoResultsState/TableNoResultsState"
import TableHeaderCell from "@organisms/Table/TableHeaderCell/TableHeaderCell"
import TableLoaderRow from "@organisms/Table/TableLoaderRow/TableLoaderRow"
import { GetObjectDetailsRouteFunction } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import withAlternativeStates from "@HOCs/withAlternativeStates"

import styles from "./Table.module.scss"
import TableProvider from "./Table.provider"
import BadgesCell from "./TableCells/BadgesCell/BadgesCell"
import DateTimeCell from "./TableCells/DateTimeCell/DateTimeCell"
import DownPaymentCell from "./TableCells/DownPaymentCell/DownPaymentCell"
import IDCell from "./TableCells/IDCell/IDCell"
import InventoryStatusCell from "./TableCells/InventoryStatusCell/InventoryStatusCell"
import LinkCell from "./TableCells/LinkCell/LinkCell"
import NoValueCell from "./TableCells/NoValueCell/NoValueCell"
import NumericalDataCell from "./TableCells/NumericalDataCell/NumericalDataCell"
import PaymentTermsCell from "./TableCells/PaymentTermsCell/PaymentTermsCell"
import PriorityCell from "./TableCells/PriorityCell/PriorityCell"
import RecurrenceCell from "./TableCells/RecurrenceCell/RecurrenceCell"
import SeenCell from "./TableCells/SeenCell/SeenCell"
import SkeletonCell from "./TableCells/SkeletonCell/SkeletonCell"
import StatusCell from "./TableCells/StatusCell/StatusCell"
import TruncatedTextCell from "./TableCells/TruncatedTextCell/TruncatedTextCell"
import UsersCell from "./TableCells/UsersCell/UsersCell"
import TableColumn from "./TableColumn/TableColumn"
import TableKeywordSearch from "./TableKeywordSearch/TableKeywordSearch"
import TableViewMenu from "./TableViewMenu/TableViewMenu"

const TABLE_ROW_HEIGHT = 48

function Table(props: TableProps) {
    const { children } = props

    const { table, data, hasNextPage, fetchNextPage, isFetchingNextPage, objectIndexName, isRowClickable } =
        useTable<ObjectsData>()

    const { user } = useUser()
    const { hasPermission } = useHasAccess()
    const router = useRouter()

    const isDesktop = useIsDesktop()
    const { isAnyDialogOpen } = useIsAnyDialogOpen()

    const { rows } = table.getRowModel()

    const { isScrolling, ref: scrollingElement } = useIsScrolling<HTMLTableElement>({ inHorizontal: true })

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? data.length + 1 : data.length,
        getScrollElement: () => scrollingElement.current,
        estimateSize: () => TABLE_ROW_HEIGHT,
    })

    const headerCellRefs = useRef<(HTMLTableCellElement | null)[]>([])
    const bodyCellRefs = useRef<(HTMLTableCellElement | null)[]>([])

    const virtualRows = rowVirtualizer.getVirtualItems()

    // Fetch more rows when we hit the bottom
    useEffect(() => {
        const [lastItem] = [...virtualRows].reverse()

        if (!lastItem) {
            return
        }

        if (lastItem.index === data?.length && hasNextPage && !isFetchingNextPage) {
            void fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, data?.length, virtualRows])

    const objectsDetailGetter = getObjectDetailsRoute({
        objectName: objectIndexName,
        hasDraftEstimateEditPermission: hasPermission("estimates_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        hasDraftJobEditPermission: hasPermission("jobs_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        hasDraftInvoiceEditPermission: hasPermission("invoices_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        hasDraftPurchaseOrderEditPermission: hasPermission(
            "purchase_orders_edit_permission",
            PERMISSION_LEVEL.RESTRICTED,
        ),
        hasDraftBillEditPermission: hasPermission("bills_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        serviceCompanySlug: user?.service_company?.slug || "",
    }) as GetObjectDetailsRouteFunction

    const headerGroups = table.getHeaderGroups()

    const onRowClick = (detailsURL: string | undefined) => {
        if (isRowClickable && detailsURL) {
            router.push(detailsURL)
        }
    }

    return (
        <>
            {children}
            <table ref={scrollingElement} className={styles.scrollContainer} role="tabpanel" tabIndex={-1}>
                <thead
                    className={styles.tableHeader}
                    style={{
                        minWidth: table.getTotalSize(),
                    }}
                >
                    {headerGroups.map((headerGroup) => (
                        <tr key={headerGroup.id} className={styles.headerRow} tabIndex={-1}>
                            {headerGroup.headers.map((header, index) => {
                                const previousHeader = headerCellRefs.current[index - 1]

                                const isSticky = header.column.getIsPinned()

                                const leftColumnWidth = previousHeader ? previousHeader?.clientWidth : 0
                                const leftColumnLeftOffset = Number(previousHeader?.style?.left?.replace(/\D+/, ""))

                                const leftStickyOffset = leftColumnWidth + leftColumnLeftOffset

                                const nextColumn = headerGroup.headers[index + 1]?.column?.getIsPinned()
                                const isLastSticky = !nextColumn

                                while (headerCellRefs.current.length <= index) {
                                    headerCellRefs.current.push(null)
                                }

                                const handleHeaderCellRef = (cellElement: HTMLTableCellElement | null) => {
                                    headerCellRefs.current[index] = cellElement
                                }

                                return (
                                    <TableHeaderCell
                                        ref={handleHeaderCellRef}
                                        canSort={header.column.getCanSort()}
                                        isSorted={header.column.getIsSorted()}
                                        content={header.column.columnDef.header}
                                        sortIndex={header.column.getSortIndex()}
                                        isPlaceholder={header.isPlaceholder}
                                        onClick={header.column.getToggleSortingHandler()}
                                        width={header.getSize()}
                                        colSpan={header.colSpan}
                                        context={header.getContext()}
                                        isSticky={isSticky && isDesktop}
                                        leftStickyOffset={leftStickyOffset || 0}
                                        key={header?.id}
                                        isScrolling={isScrolling}
                                        isLastSticky={isSticky && isLastSticky}
                                        isTabbable={!isAnyDialogOpen}
                                        dataType={header.column.columnDef.meta?.dataType as TableDataType}
                                    />
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody
                    className={styles.virtualizer}
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                    }}
                >
                    {virtualRows?.map((virtualRow) => {
                        const isLoaderRow = virtualRow.index > data?.length - 1
                        const row = rows[virtualRow.index]

                        if (isLoaderRow && hasNextPage) {
                            return (
                                <TableLoaderRow
                                    key={virtualRow.index}
                                    height={virtualRow.size}
                                    transform={virtualRow.start}
                                />
                            )
                        } else {
                            const rowData = row.original
                            const detailsURL = isRowClickable ? objectsDetailGetter(rowData) : undefined
                            const visibleCells = row.getVisibleCells()

                            return (
                                <tr
                                    key={row?.id}
                                    style={{
                                        height: `${virtualRow.size}px`,
                                        transform: `translateY(${virtualRow.start}px)`,
                                        minWidth: table.getTotalSize(),
                                    }}
                                    className={clsx(styles.bodyRow, {
                                        [styles.clickable]: isRowClickable,
                                    })}
                                    onClick={() => onRowClick(detailsURL)}
                                    onKeyDown={(event) => ifSpaceOrEnter(event, () => onRowClick(detailsURL))}
                                    tabIndex={isAnyDialogOpen ? -1 : 0}
                                    id={rowData?.id}
                                    data-index={virtualRow.index}
                                    ref={rowVirtualizer.measureElement}
                                >
                                    {visibleCells.map((cell, index, allCells) => {
                                        const previousCell = bodyCellRefs.current[index - 1]

                                        const leftColumnWidth = previousCell ? previousCell?.clientWidth : 0
                                        const leftColumnLeftOffset = Number(
                                            previousCell?.style?.left?.replace(/\D+/, ""),
                                        )

                                        const leftStickyOffset = leftColumnWidth + leftColumnLeftOffset

                                        const isSticky = cell.column.getIsPinned()

                                        const nextColumn = allCells[index + 1]?.column?.getIsPinned()

                                        const isLastSticky = !nextColumn

                                        while (bodyCellRefs.current.length <= index) {
                                            bodyCellRefs.current.push(null)
                                        }

                                        const handleBodyCellRef = (cellElement: HTMLTableCellElement | null) => {
                                            bodyCellRefs.current[index] = cellElement
                                        }

                                        return (
                                            <TableBodyCell
                                                key={cell?.id}
                                                cell={cell}
                                                ref={handleBodyCellRef}
                                                isSticky={isSticky && isDesktop}
                                                leftStickyOffset={leftStickyOffset || 0}
                                                virtualRow={virtualRow}
                                                isLastSticky={isSticky && isLastSticky}
                                                isScrolling={isScrolling}
                                                width={cell.column.getSize()}
                                                isTabbable={!isAnyDialogOpen}
                                            />
                                        )
                                    })}
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </>
    )
}

const TableWithAlternativeStates = withAlternativeStates<TableProps>({
    LoadingState: TableLoadingState,
    ErrorState: TableErrorState,
    EmptyState: TableEmptyState,
    NoResultsState: TableNoResultsState,
    SuccessState: Table,
})

export default {
    Provider: TableProvider,
    Table: TableWithAlternativeStates,
    ViewMenu: TableViewMenu,
    Column: TableColumn,
    KeywordSearch: TableKeywordSearch,
    Cells: {
        IDCell,
        NoValueCell,
        TruncatedTextCell,
        PriorityCell,
        LinkCell,
        InventoryStatusCell,
        PaymentTermsCell,
        BadgesCell,
        StatusCell,
        NumericalDataCell,
        DateTimeCell,
        SeenCell,
        UsersCell,
        RecurrenceCell,
        DownPaymentCell,
        SkeletonCell,
    },
}
