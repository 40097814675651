import {
    DndContext,
    DragEndEvent,
    DragStartEvent,
    PointerSensor,
    UniqueIdentifier,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import { restrictToParentElement } from "@dnd-kit/modifiers"
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { Fragment, useEffect, useState } from "react"

import generateRandomKey from "@utils/generateRandomKey"

import { Divider } from "@atoms"

import useTable from "@organisms/Table/Table.context"
import TableViewMenuColumnOrderingItem from "@organisms/Table/TableViewMenu/TableViewMenuColumnOrderingItem/TableViewMenuColumnOrderingItem"

import styles from "./TableViewMenuColumnsOrder.module.scss"
import { TableViewMenuColumnsOrderProps } from "./TableViewMenuColumnsOrder.types"

export default function TableViewMenuColumnsOrder(props: TableViewMenuColumnsOrderProps) {
    const { isVisible } = props

    const {
        currentTableViewConfig,
        updateColumnOrder,
        pinColumn,
        unpinColumn,
        updateColumnPinningOrder,
        pinnedColumns,
        unpinnedColumns,
        columnsNamesList,
    } = useTable()

    const [allColumnsIds, setAllColumnsIds] = useState<UniqueIdentifier[]>([])
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

    useEffect(() => {
        if (columnsNamesList) {
            setAllColumnsIds(columnsNamesList)
        }
    }, [columnsNamesList])

    const getColumnIndex = (id: UniqueIdentifier) => allColumnsIds.indexOf(id)

    const activeIndex = activeId ? getColumnIndex(activeId) : -1

    const columnIsPinned = (columnId: string) => {
        return (currentTableViewConfig?.columnPinning.left ?? []).includes(columnId)
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
    )

    const onDragStart = ({ active }: DragStartEvent) => {
        if (!active) {
            return
        }

        setActiveId(active.id)
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        setActiveId(null)

        if (over) {
            const overIndex = getColumnIndex(over.id)
            const isPinned = columnIsPinned(over.id as string)

            if (isPinned) {
                const newColumnPinningOrder = arrayMove(pinnedColumns, activeIndex, overIndex)
                updateColumnPinningOrder(newColumnPinningOrder)
            } else if (activeIndex !== overIndex) {
                const newTableOrder = arrayMove(columnsNamesList, activeIndex, overIndex)
                updateColumnOrder(newTableOrder)
            }
        }
    }

    const onPinColumn = (columnId: string) => {
        pinColumn(columnId)
    }

    const onUnpinColumn = (columnId: string) => {
        unpinColumn(columnId)
    }

    const hasPinnedColumns = pinnedColumns.length > 0

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            sensors={sensors}
            onDragEnd={onDragEnd}
            modifiers={[restrictToParentElement]}
        >
            <div className={styles.base} data-no-drag={true}>
                {hasPinnedColumns && (
                    <>
                        <div className={styles.pinnedArea}>
                            <SortableContext items={pinnedColumns} strategy={verticalListSortingStrategy}>
                                {pinnedColumns.map((columnId) => {
                                    return (
                                        <Fragment key={generateRandomKey()}>
                                            <TableViewMenuColumnOrderingItem
                                                isTabbable={isVisible}
                                                columnId={columnId}
                                                onPinColumn={onPinColumn}
                                                onUnpinColumn={onUnpinColumn}
                                                isPinned={true}
                                            />
                                        </Fragment>
                                    )
                                })}
                            </SortableContext>
                        </div>
                        <div className={styles.divider}>
                            <Divider contrast="low" />
                        </div>
                    </>
                )}
                <div className={styles.unpinnedArea}>
                    <SortableContext items={unpinnedColumns} strategy={verticalListSortingStrategy}>
                        {unpinnedColumns.map((columnId) => {
                            return (
                                <Fragment key={generateRandomKey()}>
                                    <TableViewMenuColumnOrderingItem
                                        isTabbable={isVisible}
                                        columnId={columnId}
                                        onPinColumn={onPinColumn}
                                        onUnpinColumn={onUnpinColumn}
                                        isPinned={false}
                                    />
                                </Fragment>
                            )
                        })}
                    </SortableContext>
                </div>
            </div>
        </DndContext>
    )
}
