import clsx from "clsx"
import { useMemo } from "react"

import debounce from "@utils/debounce"

import { Badge, Icon, Tooltip } from "@atoms"
import { BadgeProps } from "@atoms/Badge/Badge.types"
import { IconSize } from "@atoms/Icon/Icon.types"

import useTabStack from "@molecules/TabStack/TabStack.store"
import { TabStackProps } from "@molecules/TabStack/TabStack.types"

import styles from "./TabItem.module.scss"
import { TabItemProps } from "./TabItem.types"

export default function TabItem(props: TabItemProps) {
    const {
        icon,
        iconInReactNode,
        children,
        count,
        isPressed = false,
        isDisabled = false,
        isTabbable = true,
        onClick,
        noDebounce,
        title,
    } = props

    const { variant, size } = useTabStack()

    const iconSize = useMemo(() => {
        const sizeMap: { [key in TabStackProps["size"]]: IconSize } = {
            xs: 12,
            sm: 14,
            md: 14,
        }
        return sizeMap[size]
    }, [size])

    const badgeSize = useMemo(() => {
        const sizeMap: { [key in TabStackProps["size"]]: BadgeProps["size"] } = {
            xs: "sm",
            sm: "md",
            md: "md",
        }
        return sizeMap[size]
    }, [size])

    const desiredIcon = useMemo(() => {
        if (iconInReactNode) {
            return iconInReactNode
        } else if (icon) {
            return <Icon icon={icon} size={iconSize} noContainer={true} />
        } else {
            return null
        }
    }, [icon, iconInReactNode])

    const showActiveBadge = isPressed && variant === "default"

    return (
        <Tooltip label={title}>
            <button
                onClick={
                    noDebounce
                        ? onClick
                        : debounce((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(event))
                }
                type="button"
                disabled={isDisabled}
                role="tab"
                className={clsx(styles.base, styles[`${size}Size`], {
                    [styles.isPressed]: isPressed,
                    [styles.attached]: variant === "attached",
                    [styles.underlined]: variant === "underlined",
                })}
                tabIndex={isTabbable ? 0 : -1}
            >
                {(icon || iconInReactNode) && desiredIcon}
                {children && <div className={styles.content}>{children}</div>}
                {count !== undefined && (
                    <div className={styles.badgeContainer}>
                        <Badge
                            colorScheme={showActiveBadge ? "white" : "gray"}
                            size={badgeSize}
                            variant={showActiveBadge ? "solid" : "subtle"}
                            type="counter"
                        >
                            {count.toString()}
                        </Badge>
                    </div>
                )}
            </button>
        </Tooltip>
    )
}
