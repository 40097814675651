// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthAlternativeLoginMethods-base__CEmLx{display:flex;flex-direction:column;gap:var(--size-2);width:var(--size-full)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "AuthAlternativeLoginMethods-base__CEmLx"
};
export default ___CSS_LOADER_EXPORT___;
