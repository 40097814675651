import { DateHelper } from "@bryntum/schedulerpro"

import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { JobTimelineScheduler } from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

import { TIMELINE_VIEW_PARAM_SCROLL_POSITION } from "@constants/searchParams"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewStates from "./useJobTimelineViewStates"

export default function useJobTimelineViewNavigation() {
    const { user } = useUser()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler

    const { todayInUserTimezone, visibleDateRange, isMultipleVisibleDays } = useJobTimelineViewStates()

    const scrollTo = (date: Date, block = "center", animate = true) => {
        if (schedulerInstance && typeof schedulerInstance?.scrollToDate === "function") {
            return schedulerInstance.scrollToDate(date, {
                block,
                animate,
            })
        } else {
            return Promise.resolve()
        }
    }

    const scrollToToday = () => {
        if (schedulerInstance) {
            void scrollTo(todayInUserTimezone).then(() => {
                schedulerInstance.stopDateStateUpdates = false
            })
        }
    }

    const scrollToStartOfWorkingDay = (date = visibleDateRange.start) => {
        const isToday = DateHelper.isEqual(new Date(date), new Date(todayInUserTimezone), "day")
        if (isToday) {
            scrollToToday()
            return
        } else if (user && schedulerInstance) {
            // (Workaround) Disable infinite scroll to avoid issues with scrollToDate.
            schedulerInstance.infiniteScroll = false

            const startOfDayStr = user.service_company.start_of_day
            const [startHour, startMinute] = startOfDayStr.split(":")
            const startOfDay = new Date(date)
            startOfDay.setHours(Number(startHour), Number(startMinute), 0, 0)

            void scrollTo(startOfDay).then(() => {
                schedulerInstance.infiniteScroll = true
                schedulerInstance.stopDateStateUpdates = false
            })
        }
    }

    const scrollToVisibleDate = () => {
        void scrollTo(visibleDateRange.start, "start").then(() => {
            schedulerInstance.stopDateStateUpdates = false
        })
    }

    const handleScrollPosition = (date = visibleDateRange.start) => {
        const isToday = DateHelper.isEqual(new Date(date), new Date(todayInUserTimezone), "day")

        if (isToday) {
            void scrollToToday()
        } else {
            const [navigation] = performance.getEntriesByType("navigation") as PerformanceNavigationTiming[]
            const scrollPositionParam = extractURLSearchParam(TIMELINE_VIEW_PARAM_SCROLL_POSITION, true)

            const navigationType = scrollPositionParam || navigation.type

            if (navigationType === "reload" && !isMultipleVisibleDays) {
                void scrollToStartOfWorkingDay(date)
            } else if (navigationType === "back_forward" || isMultipleVisibleDays) {
                void scrollToVisibleDate()
            } else {
                void scrollToStartOfWorkingDay(date)
            }
        }
    }

    return { scrollToToday, scrollToStartOfWorkingDay, handleScrollPosition, scrollTo }
}
