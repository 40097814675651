import { EstimateStatuses } from "@legacy/core/utils/enums"

import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { ESTIMATES_ROUTES } from "@routes/estimates"

export const EstimateTableViewTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_open"
            phase="open"
            title="All Open"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["status", EstimateStatuses.pending],
                ["status", EstimateStatuses.sent],
                ["status", EstimateStatuses.approved],
                ["ordering", "date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="pending"
            phase="open"
            title="Pending"
            endpointKwargs={[
                ["status", EstimateStatuses.pending],
                ["ordering", "date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="sent"
            phase="open"
            title="Sent"
            endpointKwargs={[
                ["status", EstimateStatuses.sent],
                ["ordering", "date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="approved"
            phase="open"
            title="Approved"
            endpointKwargs={[
                ["status", EstimateStatuses.approved],
                ["ordering", "date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_closed"
            phase="closed"
            title="All Closed"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["status", EstimateStatuses.won],
                ["status", EstimateStatuses.lost],
                ["status", EstimateStatuses.cancelled],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="won"
            phase="closed"
            title="Won"
            endpointKwargs={[
                ["status", EstimateStatuses.won],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="lost"
            phase="closed"
            title="Lost"
            endpointKwargs={[
                ["status", EstimateStatuses.lost],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
        <TableViewTab
            id="cancelled"
            phase="closed"
            title="Cancelled"
            endpointKwargs={[
                ["status", EstimateStatuses.cancelled],
                ["ordering", "-date_due"],
            ]}
            listEndpoint={ESTIMATES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={ESTIMATES_ROUTES.COUNT}
        />
    </TableViewTabs>
)
