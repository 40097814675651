import WrapperButton from "./WrapperButton";


class WrapperLinkButton extends WrapperButton {

    render() {
        const { type, destination, openNew=false, title, extraClasses, ariaLabel, children, disabled=false } = this.props
        return <a className={`${this.getClassName(type)}${extraClasses ? ` ${extraClasses}` : ""}`} href={destination} target={openNew ? "_blank" : undefined} title={title} aria-label={ariaLabel} disabled={disabled}>{children}</a>
    }
}

export default WrapperLinkButton;
