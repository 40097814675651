import { MultipleQueriesQuery, SearchResponse } from "@algolia/client-search"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import algoliasearch from "algoliasearch"
import clsx from "clsx"
import { Command, useCommandState } from "cmdk"
import { useEffect, useMemo, useState } from "react"

import useDebounce from "@hooks/useDebounce"
import useHasAccess from "@hooks/useHasAccess"
import useObjectDetailsRoutes, { ObjectsKeysMap } from "@hooks/useObjectDetailsRoute"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import generateRandomKey from "@utils/generateRandomKey"
import { ifSpaceOrEnter } from "@utils/keyboard"
import replaceSlugs from "@utils/replaceSlugs"
import { getSearchResultIcon, getSearchResultPrimaryText, getSearchResultSecondaryText } from "@utils/searchUtils"

import { AccessCheck } from "@particles/index"

import { Badge, Icon, StatusDot } from "@atoms"

import { BILLS_ROUTES } from "@routes/bills"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import styles from "./AppSearch.module.scss"
import { AlgoliaSearchResultItem, AppSearchAction, AppSearchProps } from "./AppSearch.types"

const CREATE_ACTIONS: AppSearchAction[] = [
    {
        label: "Create Estimate",
        route: ESTIMATES_ROUTES.CREATE,
        entitlements: ["entitlement_estimates_enabled"],
        permissions: {
            estimates_create_permission: PERMISSION_LEVEL.RESTRICTED,
        },
    },
    {
        label: "Create Job",
        route: JOBS_ROUTES.CREATE,
        entitlements: ["entitlement_jobs_enabled"],
        permissions: {
            jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
        },
    },
    {
        label: "Create Invoice",
        route: INVOICES_ROUTES.CREATE,
        entitlements: ["entitlement_invoices_enabled"],
        permissions: {
            invoices_create_permission: PERMISSION_LEVEL.RESTRICTED,
        },
    },
    {
        label: "Create Purchase Order",
        route: PURCHASE_ORDER_ROUTES.CREATE,
        entitlements: ["entitlement_purchase_orders_enabled"],
        permissions: {
            purchase_orders_create_permission: PERMISSION_LEVEL.RESTRICTED,
        },
    },
    {
        label: "Create Bill",
        route: BILLS_ROUTES.CREATE,
        entitlements: ["entitlement_bills_enabled"],
        permissions: {
            bills_create_permission: PERMISSION_LEVEL.RESTRICTED,
        },
    },
    {
        label: "Create Vendor",
        route: VENDOR_ROUTES.CREATE,
        permissions: {
            vendors_create_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Create Client",
        route: CLIENTS_ROUTES.CREATE,
        permissions: {
            clients_create_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Create Pricebook Item",
        route: PRICEBOOK_ITEMS_ROUTES.CREATE,
        permissions: {
            pricebook_create_permission: PERMISSION_LEVEL.FULL,
        },
    },
]

const NAVIGATION_ACTIONS: AppSearchAction[] = [
    {
        label: "Go to Estimates",
        route: ESTIMATES_ROUTES.LIST,
        entitlements: ["entitlement_estimates_enabled"],
        permissions: {
            estimates_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Jobs",
        route: JOBS_ROUTES.LIST,
        entitlements: ["entitlement_jobs_enabled"],
        permissions: {
            jobs_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Invoices",
        route: INVOICES_ROUTES.LIST,
        entitlements: ["entitlement_invoices_enabled"],
        permissions: {
            invoices_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Purchase Orders",
        route: PURCHASE_ORDER_ROUTES.LIST,
        entitlements: ["entitlement_purchase_orders_enabled"],
        permissions: {
            purchase_orders_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Bills",
        route: BILLS_ROUTES.LIST,
        entitlements: ["entitlement_bills_enabled"],
        permissions: {
            bills_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Vendors",
        route: VENDOR_ROUTES.LIST,
        permissions: {
            vendors_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Clients",
        route: CLIENTS_ROUTES.LIST,
        permissions: {
            clients_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
    {
        label: "Go to Pricebook",
        route: PRICEBOOK_ITEMS_ROUTES.LIST,
        permissions: {
            pricebook_list_permission: PERMISSION_LEVEL.FULL,
        },
    },
]

export default function AppSearch(props: AppSearchProps) {
    const { closeSearchModal, searchScope = null } = props

    const { hasEntitlement, hasPermission, hasFlag } = useHasAccess()

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [algoliaSearchResults, setAlgoliaSearchResults] = useState<AlgoliaSearchResultItem[]>([])
    const [isTyping, setIsTyping] = useState<boolean>(false)

    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500)

    const objectDetailsRoute = useObjectDetailsRoutes() as ObjectsKeysMap

    const router = useRouter()

    const { user } = useUser()
    const serviceCompanySlug = user?.service_company?.slug ?? "undefined"

    const itemsShownCount = useCommandState((state) => state.filtered.count)

    useEffect(() => {
        if (debouncedSearchTerm.length > 0) {
            setIsTyping(false)
            void performSearch(debouncedSearchTerm)
        }
    }, [debouncedSearchTerm])

    const indexNameByScope: {
        [key in ObjectsNames]: ObjectIndexName
    } = {
        Estimates: "Estimate",
        Jobs: "Job",
        "Job Series": "JobSeries",
        Invoices: "Invoice",
        "Purchase Orders": "PurchaseOrder",
        Bills: "Bill",
        Vendors: "Vendor",
        Clients: "Client",
        Pricebook: "PriceBookItem",
    }

    const createQueriesArray = (query: string) => {
        const queries: MultipleQueriesQuery[] = []

        if (
            hasEntitlement("entitlement_estimates_enabled") &&
            hasPermission("estimates_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("estimates_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "Estimate", query })
        }

        if (
            hasEntitlement("entitlement_jobs_enabled") &&
            hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("jobs_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "Job", query })
        }

        if (
            hasEntitlement("entitlement_invoices_enabled") &&
            hasPermission("invoices_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("invoices_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "Invoice", query })
        }

        if (
            hasEntitlement("entitlement_purchase_orders_enabled") &&
            hasPermission("purchase_orders_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("purchase_orders_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "PurchaseOrder", query })
        }

        if (
            hasEntitlement("entitlement_bills_enabled") &&
            hasPermission("bills_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("bills_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "Bill", query })
        }

        if (
            hasPermission("vendors_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("vendors_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "Vendor", query })
        }

        if (
            hasPermission("clients_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("clients_view_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "Client", query })
        }

        if (
            hasPermission("pricebook_list_permission", PERMISSION_LEVEL.FULL) &&
            hasPermission("pricebook_edit_permission", PERMISSION_LEVEL.FULL)
        ) {
            queries.push({ indexName: "PriceBookItem", query })
        }

        if (searchScope) {
            return queries.filter((query) => {
                return indexNameByScope[searchScope] === query.indexName
            })
        } else {
            return queries
        }
    }

    const performSearch = async (query: string) => {
        const client = algoliasearch(process.env.ALGOLIA_APPLICATION_ID ?? "", user?.algolia_search_key ?? "")

        const queries = createQueriesArray(query)

        const searchResults = (await client.multipleQueries<ObjectsIndex>(queries))
            .results as SearchResponse<ObjectsIndex>[]

        const results = searchResults.flatMap((item) =>
            item.hits.map((hit) => {
                const index = item.index as ObjectIndexName
                const getDetailsRoute = objectDetailsRoute[index]

                const detailsRoute = getDetailsRoute(hit)

                const object_type = item.index as ObjectIndexName

                return { object_type, detailsRoute, ...hit }
            }),
        )

        setAlgoliaSearchResults(results)
        setIsLoading(false)
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value

        setIsLoading(!!value)
        setIsTyping(!!value)

        setSearchTerm(value)
    }

    useEffect(() => {
        if (searchTerm.length === 0 && algoliaSearchResults.length > 0) {
            setAlgoliaSearchResults([])
        }
    }, [algoliaSearchResults])

    const navigateToPath = (path: string) => {
        const route = replaceSlugs(path, { service_company_slug: serviceCompanySlug })
        router.push(route)
    }

    const onItemSelect = (path: string) => {
        closeSearchModal?.()

        navigateToPath(path)
    }

    const shouldShowResultItems = searchTerm.length > 0 && !isLoading && !isTyping

    const isEmpty = itemsShownCount === 0 && !isLoading && !isTyping

    const filteredResults = useMemo(
        () =>
            algoliaSearchResults.filter((result) => {
                const resultIsAJob = result.object_type === "Job"
                const userIsRestrictedToAssignedJobs = hasFlag("restricted_to_assigned_jobs")
                const userIsAssignee = result.assigned_technicians_list?.some(
                    (technician) => technician.id === user?.id,
                )

                // If the result is a job and the user has the "restricted_to_assigned_jobs" flag, restrict entries to only jobs the user is assigned to.
                // Otherwise (other object types or if the user doens't have the flag), show all results.
                if (resultIsAJob && userIsRestrictedToAssignedJobs) {
                    return userIsAssignee
                } else {
                    return true
                }
            }),
        [algoliaSearchResults, user, hasFlag],
    )

    return (
        <>
            {searchScope && (
                <div className={styles.scopeChip}>
                    <Badge colorScheme="gray" size="md" variant="subtle" type="default">
                        {`Searching ${searchScope}`}
                    </Badge>
                </div>
            )}
            <div className={styles.inputContainer}>
                <Icon
                    icon={icon({
                        name: "search",
                        style: "regular",
                        family: "sharp",
                    })}
                    size={14}
                    className={styles.inputIcon}
                />
                <Command.Input
                    className={styles.input}
                    onChangeCapture={onInputChange}
                    placeholder={`Search${searchScope === null ? " anything" : ""}`}
                    autoFocus={true}
                />
            </div>
            <div className={styles.lists} tabIndex={-1}>
                <Command.List className={styles.cmdkList}>
                    {isLoading && (
                        <Command.Loading>
                            <div className={styles.loading}>
                                <Icon
                                    icon={icon({
                                        name: "search",
                                        style: "regular",
                                        family: "sharp",
                                    })}
                                    size={14}
                                    className={styles.itemIcon}
                                />
                                <span className={styles.itemText}>Loading search results...</span>
                                <Icon
                                    icon={icon({ name: "spinner-third", style: "regular", family: "sharp" })}
                                    spin={true}
                                    size={14}
                                />
                            </div>
                        </Command.Loading>
                    )}
                    {shouldShowResultItems && (
                        <Command.Group heading="Search Results" className={styles.group}>
                            {filteredResults.length ? (
                                filteredResults.map((result) => {
                                    return (
                                        <Command.Item
                                            key={generateRandomKey()}
                                            value={`${result.object_type}${result.id}`}
                                            onSelect={() => onItemSelect(result.detailsRoute)}
                                            onKeyDown={(event) =>
                                                ifSpaceOrEnter(event, () => onItemSelect(result.detailsRoute))
                                            }
                                            tabIndex={0}
                                            className={styles.item}
                                        >
                                            {result.state_label ? (
                                                <StatusDot
                                                    status={result.status_label}
                                                    isOutstandingOrOverdue={false}
                                                />
                                            ) : (
                                                <div className={styles.emptyDot}></div>
                                            )}
                                            <div className={styles.itemText}>
                                                <span className={styles.itemPrimaryText}>
                                                    {getSearchResultPrimaryText(result.object_type, result)}
                                                </span>
                                                <span className={styles.itemSecondaryText}>
                                                    {getSearchResultSecondaryText(result.object_type, result)}
                                                </span>
                                            </div>
                                            <Icon
                                                icon={getSearchResultIcon(result.object_type)}
                                                isActive={false}
                                                size={14}
                                                className={styles.itemIcon}
                                            />
                                        </Command.Item>
                                    )
                                })
                            ) : (
                                <Command.Item
                                    key={generateRandomKey()}
                                    value="No search results."
                                    onSelect={() => {}}
                                    onKeyDown={() => {}}
                                    tabIndex={0}
                                    className={styles.item}
                                >
                                    No search results.
                                </Command.Item>
                            )}
                        </Command.Group>
                    )}
                    {searchScope === null && !isLoading && (
                        <>
                            <Command.Group heading="Actions" className={styles.group}>
                                {CREATE_ACTIONS.map((item) => {
                                    const commandItem = (
                                        <Command.Item
                                            key={generateRandomKey()}
                                            className={styles.item}
                                            onSelect={() => onItemSelect(item.route)}
                                            onKeyDown={(event) =>
                                                ifSpaceOrEnter(event, () => onItemSelect(item.route))
                                            }
                                            tabIndex={0}
                                        >
                                            <Icon
                                                icon={icon({
                                                    name: "plus",
                                                    style: "regular",
                                                    family: "sharp",
                                                })}
                                                size={14}
                                                className={styles.itemIcon}
                                            />
                                            <span className={styles.itemText}>{item.label}</span>
                                        </Command.Item>
                                    )

                                    return (
                                        <AccessCheck
                                            key={generateRandomKey()}
                                            entitlements={item.entitlements}
                                            permissions={item.permissions}
                                        >
                                            {commandItem}
                                        </AccessCheck>
                                    )
                                })}
                            </Command.Group>
                            <Command.Group heading="Navigation" className={styles.group}>
                                {NAVIGATION_ACTIONS.map((item) => {
                                    const commandItem = (
                                        <Command.Item
                                            key={generateRandomKey()}
                                            className={styles.item}
                                            onSelect={() => onItemSelect(item.route)}
                                            onKeyDown={(event) =>
                                                ifSpaceOrEnter(event, () => onItemSelect(item.route))
                                            }
                                            tabIndex={0}
                                        >
                                            <Icon
                                                icon={icon({
                                                    name: "arrow-right",
                                                    style: "regular",
                                                    family: "sharp",
                                                })}
                                                size={14}
                                                className={styles.itemIcon}
                                            />
                                            <span className={styles.itemText}>{item.label}</span>
                                        </Command.Item>
                                    )

                                    return (
                                        <AccessCheck
                                            key={generateRandomKey()}
                                            entitlements={item.entitlements}
                                            permissions={item.permissions}
                                        >
                                            {commandItem}
                                        </AccessCheck>
                                    )
                                })}
                            </Command.Group>
                        </>
                    )}
                    <Command.Empty
                        className={clsx(styles.empty, {
                            [styles.emptyVisible]: isEmpty,
                        })}
                    >
                        No results found.
                    </Command.Empty>
                </Command.List>
            </div>
        </>
    )
}
