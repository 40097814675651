import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { AccessCheck } from "@particles"

import { TopBar } from "@organisms"
import ViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider"

import { AppFrame } from "@templates"

import { ESTIMATES_ROUTES } from "@routes/estimates"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { ESTIMATES_LIST_CONTROLS_VIEW } from "@constants/storage"

import EstimateCardView from "./views/EstimateCardView/EstimateCardView"
import EstimateTableView from "./views/EstimateTableView/EstimateTableView"

export default function EstimateList() {
    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([ESTIMATES_ROUTES.CREATE])

    return (
        <AppFrame.Root pageId="EstimateList">
            <ViewTypesProvider
                viewTypeKey={ESTIMATES_LIST_CONTROLS_VIEW}
                defaultViewType={isDesktop ? "table" : "card"}
            >
                {({ activeViewType }) => (
                    <>
                        <TopBar.Root>
                            <TopBar.Header>
                                <TopBar.TopBarHeaderStart>
                                    <TopBar.PageTitle
                                        label="Estimates"
                                        icon={icon({
                                            name: "file-lines",
                                            style: "regular",
                                            family: "sharp",
                                        })}
                                        iconActive={icon({
                                            name: "file-lines",
                                            style: "solid",
                                            family: "sharp",
                                        })}
                                    />
                                </TopBar.TopBarHeaderStart>
                                <TopBar.TopBarHeaderEnd>
                                    <TopBar.PagePresence />
                                    <AccessCheck
                                        permissions={{
                                            estimates_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <AccessCheck
                                            permissions={{
                                                estimates_view_permission: PERMISSION_LEVEL.FULL,
                                            }}
                                        >
                                            <TopBar.SearchButton scope="Estimates" />
                                        </AccessCheck>
                                    </AccessCheck>
                                    <TopBar.ViewSelector viewTypes={["table", "card"]} />
                                    <AccessCheck
                                        permissions={{
                                            estimates_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <TopBar.CTAButton destination={createRoute}>
                                            {isDesktop ? "New Estimate" : null}
                                        </TopBar.CTAButton>
                                    </AccessCheck>
                                </TopBar.TopBarHeaderEnd>
                            </TopBar.Header>
                        </TopBar.Root>
                        {activeViewType === "card" ? <EstimateCardView /> : <EstimateTableView />}
                    </>
                )}
            </ViewTypesProvider>
        </AppFrame.Root>
    )
}
