import { Component } from 'react';
import WrapperLinkButton from '../../core/buttons/WrapperLinkButton';
import { convertToKebabCase, formatCurrencyForServiceCompany } from '../../core/utils/utils';
import { calculateBillAmounts } from '../utils/utils';


class BillObjectLink extends Component {

    render() {
        const { bill, destination } = this.props

        const buttonTitle = `Bill ${bill.id} [${bill.status_label}]`
        const buttonPillClass = `bills-${convertToKebabCase(bill.status_label)}`
        const buttonPillLabel = bill.status_label
        const buttonLabel = formatCurrencyForServiceCompany(calculateBillAmounts(bill).total, bill.service_company)

        return (
            <WrapperLinkButton type="object_link" destination={destination} openNew={true} title={buttonTitle} ariaLabel="View Bill">
                <div className="data-panel__list-select__option__label"><div className={`data-panel__list-select__option__status-pill ${buttonPillClass}`}>{buttonPillLabel}</div><span>{buttonLabel}</span></div><div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperLinkButton>
        )
    }
}

export default BillObjectLink;
