import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import getJobStatusColorClass from "@utils/getJobStatusColorClass"
import getJobStatusOrSubStatusLabel from "@utils/getJobStatusOrSubStatusLabel"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { JOBS_ROUTES } from "@routes/jobs"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    JOBS_LIST_CONTROLS_ACTIVE_TAB,
    JOBS_LIST_CONTROLS_KEYWORD_SEARCH,
    JOBS_LIST_CONTROLS_ORDERING,
    JOBS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { JobTableViewTabs } from "./JobTableViewTabs"

export default function JobTableView() {
    const [createRoute] = useSlugExtractor([JOBS_ROUTES.CREATE])

    const { hasPermission } = useHasAccess()

    const seriesFilter = extractURLSearchParam("filter__series", false)
    const seriesSearch = new URLSearchParams()

    if (seriesFilter) {
        seriesSearch.set("series", seriesFilter)
    }

    return (
        <Table.Provider
            activePhaseStorageKey={JOBS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={JOBS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={JOBS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={JOBS_LIST_CONTROLS_ORDERING}
            listTitle="Jobs"
            listDataFilters={seriesSearch}
            objectType="job"
            objectCreateRoute={createRoute}
            objectIndexName="Job"
            objectName="Job"
            defaultActivePhase="open"
            isRowClickable={hasPermission("jobs_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("jobs_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <JobTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<Job>
                    accessorKey="id"
                    header="ID"
                    size={144}
                    cell={({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Job>
                    accessorKey="service_name"
                    header="Service"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Job>
                    accessorKey="state_label"
                    header="State"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Job>
                    accessorKey="status_label"
                    header="Status"
                    size={240}
                    cell={({ row }) => (
                        <Table.Cells.StatusCell
                            status={getJobStatusOrSubStatusLabel(row.original)}
                            dotStatusOverride={getJobStatusColorClass(row.original)}
                        />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Job>
                    accessorKey="job_type"
                    header="Job Type"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Job>
                    accessorKey="priority"
                    header="Priority"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.PriorityCell priority={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="other"
                />
                <Table.Column<Job>
                    accessorKey="assigned_technicians"
                    header="Techs"
                    size={80}
                    cell={({ getValue }) => <Table.Cells.UsersCell users={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Job>
                    accessorKey="date_received"
                    header="Date Received"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="client_name"
                    header="Client"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Job>
                    accessorKey="service_location_name"
                    header="Location Name"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Job>
                    accessorKey="service_location_address"
                    header="Location Address"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Job>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as JobLabel[])?.map((label: JobLabel) => label.name)}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Job>
                    accessorKey="estimated_arrival_time"
                    header="Scheduled For"
                    size={224}
                    cell={({ getValue, row }) => (
                        <Table.Cells.DateTimeCell
                            value={getValue()}
                            showTime={true}
                            isDangerous={
                                ["Pending", "Scheduled"].includes(row.original.state_label) && row.original.is_past_due
                            }
                        />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="estimated_duration"
                    header="Duration"
                    size={112}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell type="duration" value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<Job>
                    accessorKey="last_scheduled_at"
                    header="Scheduled"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_status_transition_time"
                    header="Status Changed"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_active_at"
                    header="Active"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_paused_at"
                    header="Paused"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_on_hold_at"
                    header="On Hold"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_completed_at"
                    header="Completed"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_finalized_at"
                    header="Finalized"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Job>
                    accessorKey="last_cancelled_at"
                    header="Cancelled"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
