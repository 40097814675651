import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { AccessCheck } from "@particles/index"

import { TopBar } from "@organisms"
import ViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider"

import { AppFrame } from "@templates"

import { BILLS_ROUTES } from "@routes/bills"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { BILLS_LIST_CONTROLS_VIEW } from "@constants/storage"

import BillTableView from "./views/BillTableView/BillTableView"

export default function BillList() {
    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([BILLS_ROUTES.CREATE])

    return (
        <AppFrame.Root pageId="BillList">
            <ViewTypesProvider viewTypeKey={BILLS_LIST_CONTROLS_VIEW} defaultViewType={"table"}>
                {({ activeViewType }) => (
                    <>
                        <TopBar.Root>
                            <TopBar.Header>
                                <TopBar.TopBarHeaderStart>
                                    <TopBar.PageTitle
                                        label="Bills"
                                        icon={icon({ name: "file-invoice-dollar", style: "regular", family: "sharp" })}
                                        iconActive={icon({
                                            name: "file-invoice-dollar",
                                            style: "solid",
                                            family: "sharp",
                                        })}
                                    />
                                </TopBar.TopBarHeaderStart>
                                <TopBar.TopBarHeaderEnd>
                                    <TopBar.PagePresence />
                                    <AccessCheck
                                        permissions={{
                                            bills_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <AccessCheck
                                            permissions={{
                                                bills_view_permission: PERMISSION_LEVEL.FULL,
                                            }}
                                        >
                                            <TopBar.SearchButton scope="Bills" />
                                        </AccessCheck>
                                    </AccessCheck>
                                    <TopBar.ViewSelector viewTypes={["table"]} />
                                    <AccessCheck
                                        permissions={{
                                            bills_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <TopBar.CTAButton destination={createRoute}>
                                            {isDesktop ? "New Bill" : null}
                                        </TopBar.CTAButton>
                                    </AccessCheck>
                                </TopBar.TopBarHeaderEnd>
                            </TopBar.Header>
                        </TopBar.Root>
                        {activeViewType === "table" ? <BillTableView /> : <BillTableView />}
                    </>
                )}
            </ViewTypesProvider>
        </AppFrame.Root>
    )
}
