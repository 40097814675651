import { PERMISSION_LEVEL } from '@constants/permissionLevel'
import Spinner from '@legacy/core/components/Spinner'
import { AccessCheck } from '@particles/index'
import { Component, Fragment } from 'react'
import ButtonGroup from '../../core/buttons/ButtonGroup'
import ButtonGroupRow from '../../core/buttons/ButtonGroupRow'
import UniversalButton from '../../core/buttons/UniversalButton'
import ClientInformationDisplay from '../../core/components/ClientInformationDisplay'
import BasicDisplayField from '../../core/fields/BasicDisplayField'
import SearchCreateSelectField from '../../core/fields/SearchCreateSelectField'
import { JobOriginTypes } from '../../core/utils/enums'
import EquipmentDetailsAccordion from '../../equipment/components/EquipmentDetailsAccordion'
import EquipmentSearchOrCreateSelect, { equipmentToEquipmentOption } from '../../equipment/inputs/EquipmentSearchOrCreateSelect'

class JobEquipmentSummaryForm extends Component {

    constructor(props) {
        super(props)

        const urlParams = new URLSearchParams(window.location.search)
        const type = urlParams.get("type")
        this.standaloneMode = type === "standalone"

        this.state = {
            equipmentSearchInput: "",
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            job,
            submitting,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateEquipmentSelection,
            selectedEquipment,
            returnScroll,
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (this.standaloneMode) {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("ADD_EQUIPMENT_STANDALONE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Continue" handler={event => requestAction("SUBMIT_JOB_EQUIPMENT_SUMMARY")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    render() {
        const {
            mode,
            job,
            submitting,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateEquipmentSelection,
            selectedEquipment,
            returnScroll,
            addToastToQueue,
        } = this.props

        return (
            <Fragment>
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Job Equipment Summary Form">
                        <ClientInformationDisplay
                            client={job.service_location.external_client}
                            serviceLocation={job.service_location}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Job Equipment Summary">
                            <p className="data-panel__form__caption">Please ensure the following equipment details are correct for this job: </p>
                            <BasicDisplayField
                                fieldName="id"
                                fieldLabel="Roopairs Job ID"
                                fieldValue={job.custom_id ? job.custom_id : job.id}
                            ></BasicDisplayField>
                            {
                                job.origin_type !== JobOriginTypes.roopairs && (
                                    <BasicDisplayField
                                        fieldName="origin_id"
                                        fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                        fieldValue={job.origin_id}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="service_name"
                                fieldLabel="Service Type"
                                fieldValue={job.service_name}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="is_job_walk"
                                fieldLabel="Job Type"
                                fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                            ></BasicDisplayField>

                            <SearchCreateSelectField
                                fieldName="equipment"
                                fieldLabel="Equipment"
                                fieldValue={(selectedEquipment.length !== 0 ? selectedEquipment.map(equipment => equipment.display_name).join(", ") : "")}
                                inputComponent={
                                    <EquipmentSearchOrCreateSelect
                                        disabled={window.CURRENT_USER?.permissions.equipment_list_permission < PERMISSION_LEVEL.RESTRICTED}
                                        isMulti={true}
                                        refetchSelection={true}
                                        onSelectionChange={selectedOptions => updateEquipmentSelection((selectedOptions || []).map(option => option.object))}
                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({equipmentSearchInput: input})}}
                                        defaultSelected={selectedEquipment.map(equipment => equipmentToEquipmentOption(equipment))}
                                        serviceLocation={job.service_location}
                                        showCreateButton={window.CURRENT_USER?.permissions.equipment_create_permission >= PERMISSION_LEVEL.FULL}
                                        onCreateClick={event => switchToSecondaryForm(this.standaloneMode ? "ADD_EQUIPMENT_STANDALONE" : "ADD_EQUIPMENT", null, { display_name: this.state.equipmentSearchInput })}
                                    ></EquipmentSearchOrCreateSelect>
                                }
                                showButton={false}
                                errors={errors}
                            ></SearchCreateSelectField>
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                <AccessCheck permissions={{
                    equipment_list_permission: PERMISSION_LEVEL.RESTRICTED,
                }}>
                    {
                        selectedEquipment.sort((obj, other) => obj.display_name.localeCompare(other.display_name)).map(equipment => {
                            return (
                                <div key={`equipment_${equipment.id}`} className="accordion-wrapper">
                                    <EquipmentDetailsAccordion
                                        clientID={job.service_location.external_client.id}
                                        data={equipment}
                                        editable={window.CURRENT_USER?.permissions.equipment_edit_permission >= PERMISSION_LEVEL.FULL}
                                        startOpen={window.CURRENT_USER?.permissions.equipment_view_permission >= PERMISSION_LEVEL.FULL}
                                        addToastToQueue={addToastToQueue}
                                        openable={window.CURRENT_USER?.permissions.equipment_view_permission >= PERMISSION_LEVEL.FULL}
                                    />
                                </div>
                            )
                        })
                    }
                </AccessCheck>
            </Fragment>
        )
    }
}

export default JobEquipmentSummaryForm;
