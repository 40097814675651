import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import useHasAccess from "@hooks/useHasAccess";
import useUser from "@hooks/useUser";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { formatDateTime } from "../../core/utils/utils";
import { DashboardCardRowStates } from "./DashboardCard";


async function fetchEvents(currentUser, pageSize, page, filterValue) {
    const endpoint = DjangoUrls["dashboard:api-events-list"](currentUser.service_company.slug)

    const searchParams = new URLSearchParams()
    searchParams.append("page_size", pageSize)
    searchParams.append("page", page)
    searchParams.append("module", filterValue)

    const response = await fetch(endpoint + "?" + searchParams.toString())
    if (!response.ok) {
        throw new Error("There was an error while trying to load events.")
    }
    return await response.json()
}


function LatestActivityCardRow(props) {
    const { eventData } = props

    const { localDateTimeString } = formatDateTime(
        eventData.timestamp,
        eventData.preferred_timezone,
        {short: true, addDay: true, addFullDay: false, addYear: true}
    )

    return (
        <div className="card__data__row event" aria-label="Event">
            <div className="card__data__row__content-container">
                <div className="card__data__row__body" aria-label="Event Details">
                    <div className="event__string" aria-label="Event String" dangerouslySetInnerHTML={{__html: eventData.event_string}}></div>
                    <div className="event__timestamp" aria-label="Event Timestamp">{localDateTimeString}</div>
                    <div className="event__message" aria-label="Event Message">{eventData.message}</div>
                </div>
            </div>
        </div>
    )
}


function LatestActivityCard(props) {
    const { pageSize, currentPage, filterSelection, setFilterOptions, setCurrentPageData } = props
    const { user:currentUser } = useUser()
    const { hasEntitlement, hasPermission, hasFlag } = useHasAccess()
    const filterValue = filterSelection?.value || ""
    const queryClient = useQueryClient()

    const { data, error, isLoading, isError } = useQuery({
        queryKey: ["events-latest-activity", pageSize, currentPage, filterValue],
        queryFn: () => fetchEvents(currentUser, pageSize, currentPage, filterValue),
        staleTime: 60000,
    })

    React.useEffect(() => {
        const filterOptions = [
            {"label": "All", "value": ""},
        ];

        if (hasEntitlement("entitlement_estimates_enabled") && hasPermission("estimates_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("estimates_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Estimates", "value": "estimate"});
        }

        if (hasEntitlement("entitlement_jobs_enabled") && hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("jobs_view_permission", PERMISSION_LEVEL.FULL) && !hasFlag("restricted_to_assigned_jobs")) {
            filterOptions.push({"label": "Jobs", "value": "job"});
        }

        if (hasEntitlement("entitlement_invoices_enabled") && hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("invoices_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Invoices", "value": "invoice"});
        }

        if (hasEntitlement("entitlement_purchase_orders_enabled") && hasPermission("purchase_orders_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("purchase_orders_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Purchase Orders", "value": "purchase_order"});
        }

        if (hasEntitlement("entitlement_bills_enabled") && hasPermission("bills_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("bills_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Bills", "value": "bill"});
        }

        if (hasPermission("pricebook_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("pricebook_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Pricebook", "value": "pricebookitem"});
        }

        if (hasPermission("clients_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("clients_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Clients", "value": "external_client"});
        }

        if (hasPermission("vendors_list_permission", PERMISSION_LEVEL.FULL) && hasPermission("vendors_view_permission", PERMISSION_LEVEL.FULL)) {
            filterOptions.push({"label": "Vendors", "value": "vendor"});
        }

        setFilterOptions(filterOptions);
    }, [])

    // Send the data to the parent to render pagination
    React.useEffect(() => {
        setCurrentPageData(data)
    }, [data])

    // Prefetch the next page
    React.useEffect(() => {
        if (data?.next) {
            queryClient.prefetchQuery({
                queryKey: ["events-latest-activity", pageSize, currentPage + 1, filterValue],
                queryFn: () => fetchEvents(currentUser, pageSize, currentPage + 1, filterValue),
            })
        }
    }, [data, pageSize, currentPage, filterValue, queryClient])

    return <DashboardCardRowStates
        isLoading={isLoading}
        isError={isError}
        errorMessage={error?.message}
        isEmpty={data?.results.length === 0}
        emptyIcon="fa-empty-set"
        emptyMessage="There is no activity to display."
    >
        {data?.results.map(eventData => <LatestActivityCardRow eventData={eventData} key={eventData.id} />)}
    </DashboardCardRowStates>
}


export default LatestActivityCard
