import clsx from "clsx"

import { Label } from "@atoms"
import { LabelProps } from "@atoms/Label/Label.types"

import styles from "./PropertyLabel.module.scss"

export default function PropertyLabel(props: Omit<LabelProps, "size">) {
    const { className, ...rest } = props

    return <Label className={clsx(styles.base, className)} variant="focused" size="lg" {...rest} />
}
