import clsx from "clsx"

import getTableSortingIcon from "@utils/getTableSortingIcon"

import { Icon } from "@atoms"

import styles from "./SortingIcons.module.scss"
import { SortingIconsProps } from "./SortingIcons.types"

export default function SortingIcons(props: SortingIconsProps) {
    const { isSorted, ascending, dataType } = props

    const icon = getTableSortingIcon({ dataType: dataType, ascending: isSorted && ascending })

    return (
        icon && (
            <div
                className={clsx(styles.base, {
                    [styles.sorting]: isSorted,
                })}
            >
                <div className={styles.icon}>
                    <Icon className={clsx({ [styles.active]: isSorted })} icon={icon} size={12} />
                </div>
            </div>
        )
    )
}
