import clsx from "clsx"
import dayjs from "dayjs"

import { Tooltip } from "@atoms"
import AvatarStatus from "@atoms/Avatar/AvatarStatus/AvatarStatus"

import { UnknownAvatar, UserAvatar } from "@molecules"

import useJobTimelineViewStates from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewTechniciansBarTechCell.module.scss"
import { TimelineViewTechniciansBarTechCellProps } from "./TimelineViewTechniciansBarTechCell.types"

export default function TimelineViewTechniciansBarTechCell(props: TimelineViewTechniciansBarTechCellProps) {
    const {
        technician,
        isCollapsed = undefined,
        isCompact = undefined,
        isVertical = false,
        schedulerVisibleDate,
        schedulerTimeFrameType,
    } = props

    const { techniciansColumnStatus, jobCardStyle, visibleDateRange, timeFrameType } = useJobTimelineViewStates()

    const isUnassigned = technician === null

    const availability: Availability = technician?.availability?.find((availability) => {
        const availabilityDate = dayjs(availability.date)

        const dateToCheck = schedulerVisibleDate ?? visibleDateRange.start

        return availabilityDate.isSame(dateToCheck, "day")
    }) ?? { available: true, status: "Working", date: schedulerVisibleDate ?? visibleDateRange.start }

    // The component is rendered differently for vertical and horizontal schedulers.
    // For the vertical scheduler, which always re-renders this component, we use the `isCollapsed` prop.
    // For the horizontal scheduler, which doesn't always re-render but retains React capabilities, we use the `techniciansColumnStatus` state.
    const techsBarIsCollapsed = isCollapsed ?? techniciansColumnStatus === "collapsed"

    // The explanation of the following logic is the same as above.
    const jobCardStyleIsCompact = isCompact ?? jobCardStyle === "compact"

    const timeFrameTypeIsDay = (schedulerTimeFrameType ?? timeFrameType) === "day"

    const shouldDisplayAvatarStatus =
        !isUnassigned && (jobCardStyleIsCompact || techsBarIsCollapsed) && timeFrameTypeIsDay
    const shouldDisplayStatusText = !isUnassigned && !jobCardStyleIsCompact && timeFrameTypeIsDay

    return (
        <div
            className={clsx(styles.base, {
                [styles.unassigned]: isUnassigned,
                [styles.collapsed]: techsBarIsCollapsed,
                [styles.compact]: jobCardStyleIsCompact,
                [styles.vertical]: isVertical,
            })}
        >
            <Tooltip
                label={!isUnassigned && jobCardStyleIsCompact && availability.status !== "" ? availability.status : ""}
                side="bottom"
            >
                <div className={styles.avatar}>
                    {isUnassigned ? (
                        <UnknownAvatar shape="circle" />
                    ) : (
                        <UserAvatar
                            user={technician}
                            size={32}
                            shape="circle"
                            status={
                                shouldDisplayAvatarStatus
                                    ? availability.available
                                        ? "working"
                                        : "unavailable"
                                    : undefined
                            }
                        />
                    )}
                </div>
            </Tooltip>
            <div className={styles.content}>
                <div className={styles.name}>{isUnassigned ? "Unassigned" : technician?.full_name}</div>
                {shouldDisplayStatusText && (
                    <div className={styles.statusWrap}>
                        <AvatarStatus status={availability.available ? "working" : "unavailable"} size={6} />
                        <div className={styles.statusWrapText}>{availability.status}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
