import { ProjectModelConfig } from "@bryntum/schedulerpro"
import { BryntumGridProps, BryntumSchedulerProProps } from "@bryntum/schedulerpro-react"

import { JobEventModel } from "@pages/Jobs/JobList/views/JobTimelineView/JobEventModel"

const GRID_ROW_HEIGHT = 108

export const REGULAR_TIMELINE_ROW_HEIGHT = 90
export const COMPACT_TIMELINE_ROW_HEIGHT = 52
export const HORIZONTAL_COLLAPSED_TECHNICIANS_BAR_WIDTH = 56
export const HORIZONTAL_EXPANDED_TECHNICIANS_BAR_WIDTH = 256
export const VERTICAL_TIMELINE_TECHNICIANS_COLUMN_HEIGHT = 80
export const VERTICAL_TIMELINE_TECHNICIANS_COLUMN_WIDTH = 200

export const BUTTON_DEBOUNCE_DELAY = 250

export const projectConfig: Partial<ProjectModelConfig> = {
    eventStore: {
        removeUnassignedEvent: true,
        modelClass: JobEventModel,
    },
}

const schedulerConfig: BryntumSchedulerProProps = {
    extraData: {
        startHour: 0,
        endHour: 23,
    },
    forceFit: false,
    enableEventAnimations: true,
    columnLines: true,
    columnLinesFeature: true,
    cls: "job-timeline-view jsJobTimelineView",
    bufferCoef: 20,
    bufferThreshold: 0.01,
    infiniteScroll: true,
    eventDragCreateFeature: false,
    project: projectConfig,
    emptyText: "",
    barMargin: 4,
    createEventOnDblClick: false,
    zoomOnTimeAxisDoubleClick: false,
    eventStyle: "plain",
    taskEditFeature: false,
    showTooltip: false,
    cellTooltipFeature: false,
    eventTooltipFeature: false,
    scheduleTooltipFeature: false,
    eventSegmentsFeature: false,
    dependenciesFeature: false,
    timeAxisHeaderMenuFeature: false,
    monitorResize: false,
    cellMenuFeature: false,
    eventMenuFeature: false,
    headerMenuFeature: false,
    resourceMenuFeature: false,
    scheduleMenuFeature: false,
    scheduleContextFeature: false,
    eventResizeFeature: {
        tip: () => false,
    },
    regionResizeFeature: false,
    resizeToFitIncludesHeader: false,
    columnResizeFeature: false,
    features: {
        timeRanges: {
            currentDateFormat: "h:mm A",
            showCurrentTimeLine: true,
        },
    },
    resourceTimeRangesFeature: true,
    snap: true,
}

// Custom grid that holds unscheduled jobs
const gridConfig: BryntumGridProps = {
    selectionMode: {
        cell: false,
    },
    stripeFeature: true,

    rowHeight: GRID_ROW_HEIGHT,
    disableGridRowModelWarning: true,
}

export { GRID_ROW_HEIGHT, gridConfig, schedulerConfig }
