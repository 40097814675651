import { JobStatuses } from "@legacy/core/utils/enums"

import useIsDesktop from "@hooks/useIsDesktop"

import { EventRecord } from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"
import useJobTimelineViewNavigation from "./useJobTimelineViewNavigation"

export default function useJobTimelineViewUIUtils() {
    const { schedulerPro } = useJobTimelineViewBryntumInstances()
    const isDesktop = useIsDesktop()

    const { scrollTo } = useJobTimelineViewNavigation()

    const hideCancelledJobsFromScheduler = () => {
        if (!schedulerPro.current) {
            return
        }

        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.filter({
            id: "cancelledJobsFilter",
            filterBy: (event: EventRecord) => {
                return event.status !== JobStatuses.cancelled
            },
        })
    }

    const showCancelledJobsInScheduler = () => {
        if (!schedulerPro.current) {
            return
        }

        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.removeFilter("cancelledJobsFilter")
    }

    const hideWeekendsFromScheduler = () => {
        try {
            const SATURDAY = 6
            const SUNDAY = 0

            const schedulerInstance = schedulerPro.current?.instance
            if (schedulerInstance) {
                schedulerInstance.suspendRefresh()
                const { startDate } = schedulerInstance.visibleDateRange

                void schedulerInstance.timeAxis.filterBy((tick: { startDate: { getDay: () => number } }) => {
                    const day = tick.startDate.getDay()
                    return day !== SATURDAY && day !== SUNDAY
                })

                void scrollTo(startDate, "start", false)

                void schedulerInstance.resumeRefresh()
                document.body.classList.add("bryntum-hide-weekends")
            }
        } catch (e) {
            console.error("Failed to hide weekends from scheduler", e)
        }
    }

    const showWeekendsOnScheduler = () => {
        try {
            const schedulerInstance = schedulerPro.current?.instance
            if (schedulerInstance) {
                schedulerInstance.suspendRefresh()
                const { startDate } = schedulerInstance.visibleDateRange

                void schedulerInstance.timeAxis.filterBy(() => true)

                void scrollTo(startDate, "start", false)

                void schedulerInstance.resumeRefresh()
                document.body.classList.remove("bryntum-hide-weekends")
            }
        } catch (e) {
            console.error("Failed to show weekends from scheduler", e)
        }
    }

    const hideFinalizedJobsFromScheduler = () => {
        if (!schedulerPro.current) {
            return
        }

        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.filter({
            id: "finalizedJobsFilter",
            filterBy: (event: EventRecord) => {
                return event.status !== JobStatuses.finalized
            },
        })
    }

    const showFinalizedJobsInScheduler = () => {
        if (!schedulerPro.current) {
            return
        }

        const scheduler = schedulerPro.current.instance

        void scheduler.eventStore.removeFilter("finalizedJobsFilter")
    }

    const hideEventFeedback = () => {
        const eventAddFeedback: HTMLElement | null = document.querySelector(".jsJobTimelineViewCreateJobIndicator")

        if (eventAddFeedback) {
            eventAddFeedback.style.display = "none"
        }
    }

    const showEventFeedback = () => {
        if (!schedulerPro.current) {
            return
        }

        const isResizing = schedulerPro.current.instance.features.eventResize.isResizing
        const isDragging = schedulerPro.current.instance.features.eventDrag.isDragging

        if (isResizing || isDragging || !isDesktop) {
            return hideEventFeedback()
        }

        const eventAddFeedback: HTMLElement | null = document.querySelector(".jsJobTimelineViewCreateJobIndicator")

        if (eventAddFeedback) {
            eventAddFeedback.style.display = "flex"
        }
    }

    const hideEventMoveFeedback = () => {
        const eventAddFeedback: HTMLElement | null = document.querySelector(".jsBryntumTimelineViewMoveFeedback")

        if (eventAddFeedback) {
            eventAddFeedback.classList.remove("job-timeline-view__job-moving-indicator--visible")
        }
    }

    const showEventMoveFeedback = ({
        isOverlapping,
        startDate,
        endDate,
        resourceId,
    }: {
        isOverlapping: boolean
        startDate: Date
        endDate: Date
        resourceId: string | number
    }) => {
        if (!schedulerPro.current) {
            return
        }

        const resourceTimeRangeStore = schedulerPro.current.instance.resourceTimeRangeStore as {
            getById(id: string | number): {
                id: string | number
                resourceId: string | number
                startDate: Date
                endDate: Date
                name: string
                cls: string
                setStartEndDate(startDate: Date, endDate: Date): void
            }
            add: (timeRange: {
                id: string | number
                resourceId: string | number
                startDate: Date
                endDate: Date
                name: string
                cls: string
            }) => void
        }

        const targetedTimeRange = resourceTimeRangeStore.getById("job-move-feedback")

        if (targetedTimeRange) {
            targetedTimeRange.setStartEndDate(startDate, endDate)
            targetedTimeRange.resourceId = resourceId

            const eventAddFeedback = document.querySelector(".jsBryntumTimelineViewMoveFeedback")

            eventAddFeedback?.classList?.add("job-timeline-view__job-moving-indicator--visible")

            if (eventAddFeedback && isOverlapping) {
                eventAddFeedback?.classList?.add("job-timeline-view__job-moving-indicator--overlapping")
            } else {
                eventAddFeedback?.classList?.remove("job-timeline-view__job-moving-indicator--overlapping")
            }
        }
    }

    return {
        hideCancelledJobsFromScheduler,
        showCancelledJobsInScheduler,
        hideFinalizedJobsFromScheduler,
        showFinalizedJobsInScheduler,
        hideEventFeedback,
        showEventFeedback,
        hideEventMoveFeedback,
        showEventMoveFeedback,
        hideWeekendsFromScheduler,
        showWeekendsOnScheduler,
    }
}
