import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useUser from "@hooks/useUser"

import { BILLS_ROUTES } from "@routes/bills"
import { CALENDAR_ROUTE } from "@routes/calendar"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { HOME_ROUTE } from "@routes/home"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { JOB_SERIES_ROUTES } from "@routes/jobs-series"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { REPORTS_ROUTE } from "@routes/reports"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"
import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import useHasAccess from "./useHasAccess"
import useSlugExtractor from "./useSlugExtractor"

export type NavigationLink = {
    label: string
    path: string
    subLinks?: NavigationLink[]
    predicate?: (user: User) => boolean
    icon?: IconProp
    iconActive?: IconProp
}

export default function useNavigationLinks() {
    const { user } = useUser()

    const { hasEntitlement, hasPermission, hasFlag } = useHasAccess()

    const isUsingTaxRates =
        user?.service_company?.use_taxes &&
        !(
            user?.service_company?.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company?.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const [
        homeRoute,
        calendarRoute,
        clientsListRoute,
        estimatesListRoute,
        jobsListRoute,
        jobSeriesListRoute,
        invoicesListRoute,
        purchaseOrdersListRoute,
        billsListRoute,
        vendorsListRoute,
        pricebookServicesListRoute,
        pricebookPartsOrMaterialsListRoute,
        pricebookMiscellaneousListRoute,
        pricebookDiscountsListRoute,
        pricebookTaxRatesListRoute,
        reportsRoute,
    ] = useSlugExtractor([
        HOME_ROUTE,
        CALENDAR_ROUTE,
        CLIENTS_ROUTES.LIST,
        ESTIMATES_ROUTES.LIST,
        JOBS_ROUTES.LIST,
        JOB_SERIES_ROUTES.LIST,
        INVOICES_ROUTES.LIST,
        PURCHASE_ORDER_ROUTES.LIST,
        BILLS_ROUTES.LIST,
        VENDOR_ROUTES.LIST,
        PRICEBOOK_ITEMS_ROUTES.SERVICE.LIST,
        PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.LIST,
        PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.LIST,
        PRICEBOOK_ITEMS_ROUTES.DISCOUNT.LIST,
        PRICEBOOK_ITEMS_ROUTES.TAX_RATES.LIST,
        REPORTS_ROUTE,
    ])

    const home: NavigationLink = {
        path: homeRoute,
        label: "Home",
        icon: icon({ name: "house", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "house", style: "solid", family: "sharp" }),
    }

    const calendar: NavigationLink = {
        path: calendarRoute,
        label: "Calendar",
        predicate: (user: User) =>
            hasEntitlement("entitlement_jobs_enabled") &&
            hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL) &&
            user.service_company.feature_legacy_calendar_enabled,
        icon: icon({ name: "objects-align-left", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "objects-align-left", style: "solid", family: "sharp" }),
    }

    const clients: NavigationLink = {
        path: clientsListRoute,
        label: "Clients",
        predicate: () => hasPermission("clients_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "user-circle", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "user-circle", style: "solid", family: "sharp" }),
    }

    const estimates: NavigationLink = {
        path: estimatesListRoute,
        label: "Estimates",
        predicate: () =>
            hasEntitlement("entitlement_estimates_enabled") &&
            hasPermission("estimates_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "file-lines", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "file-lines", style: "solid", family: "sharp" }),
    }

    const jobs: NavigationLink = {
        path: "",
        label: "Jobs",
        predicate: () =>
            hasEntitlement("entitlement_jobs_enabled") && hasPermission("jobs_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "wrench-simple", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "wrench-simple", style: "solid", family: "sharp" }),
        subLinks: [
            {
                label: "Jobs",
                path: jobsListRoute,
            },
            {
                label: "Job Series",
                path: jobSeriesListRoute,
                predicate: () => !hasFlag("restricted_to_assigned_jobs"),
            },
        ],
    }

    const invoices: NavigationLink = {
        path: invoicesListRoute,
        label: "Invoices",
        predicate: () =>
            hasEntitlement("entitlement_invoices_enabled") &&
            hasPermission("invoices_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "circle-dollar", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "circle-dollar", style: "solid", family: "sharp" }),
    }

    const purchase_orders: NavigationLink = {
        path: purchaseOrdersListRoute,
        label: "Purchase Orders",
        predicate: () =>
            hasEntitlement("entitlement_purchase_orders_enabled") &&
            hasPermission("purchase_orders_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "basket-shopping", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "basket-shopping", style: "solid", family: "sharp" }),
    }

    const bills: NavigationLink = {
        path: billsListRoute,
        label: "Bills",
        predicate: () =>
            hasEntitlement("entitlement_bills_enabled") &&
            hasPermission("bills_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "file-invoice-dollar", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "file-invoice-dollar", style: "solid", family: "sharp" }),
    }

    const vendors: NavigationLink = {
        path: vendorsListRoute,
        label: "Vendors",
        predicate: () => hasPermission("vendors_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "store", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "store", style: "solid", family: "sharp" }),
    }

    const pricebook: NavigationLink = {
        path: "",
        label: "Pricebook",
        predicate: () => hasPermission("pricebook_list_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "tag", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "tag", style: "solid", family: "sharp" }),
        subLinks: [
            {
                label: "Services",
                path: pricebookServicesListRoute,
            },
            {
                label: "Parts & Materials",
                path: pricebookPartsOrMaterialsListRoute,
            },
            {
                label: "Miscellaneous",
                path: pricebookMiscellaneousListRoute,
            },
            {
                label: "Discounts",
                path: pricebookDiscountsListRoute,
            },
            ...(isUsingTaxRates
                ? [
                      {
                          label: "Tax Rates",
                          path: pricebookTaxRatesListRoute,
                      },
                  ]
                : []),
        ],
    }

    const reports: NavigationLink = {
        path: reportsRoute,
        label: "Reports",
        predicate: (user: User) =>
            user.service_company.feature_reports_enabled &&
            hasPermission("reports_view_permission", PERMISSION_LEVEL.FULL),
        icon: icon({ name: "chart-mixed", style: "regular", family: "sharp" }),
        iconActive: icon({ name: "chart-mixed", style: "solid", family: "sharp" }),
    }

    return {
        home,
        calendar,
        clients,
        estimates,
        jobs,
        invoices,
        purchase_orders,
        bills,
        vendors,
        pricebook,
        reports,
    }
}
