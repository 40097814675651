import Spinner from '@legacy/core/components/Spinner';
import { Component } from "react";
import deepcopy from "rfdc";
import { PriceBookItemTypes } from '../core/utils/enums';
import { historyHasState, sendDataToServer } from "../core/utils/utils";
import BillDetailsCard from "./components/BillDetailsCard";
import BillVoidForm from "./forms/BillVoidForm";

const PAGE_MODES = {
    VIEW_BILL: "VIEW_BILL",
    VOID_BILL: "VOID_BILL",
}

const PAGE_MODE_SUBTITLES = {
    VIEW_BILL: "Bill Details",
    VOID_BILL: "Void Bill",
}

const PAGE_MODE_BACK_BUTTON_DISPLAY = {
    VIEW_BILL: "flex",
    VOID_BILL: "none",
}

const PRIMARY_PAGE_MODES = [PAGE_MODES.VIEW_BILL]
const SECONDARY_PAGE_MODES = [
    PAGE_MODES.VOID_BILL,
]

const FORM_DATA_NAMES_BY_MODE = {
    VIEW_BILL: "billData",
    VOID_BILL: "voidData",
}

const SUBMITTING_NAMES_BY_MODE = {
    VIEW_BILL: "submittingBill",
    VOID_BILL: "submittingVoid",
}

const ERROR_NAMES_BY_MODE = {
    VIEW_BILL: "bill",
    VOID_BILL: "void",
}


class BillDetailsContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || PAGE_MODES.VIEW_BILL

        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            billData: null,

            voidData: {},

            errors: {
                bill: {},
                void: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.billData === null) {
            let billEndpoint

            if (window.USING_PUBLIC_URL === true) {
                billEndpoint = window.PUBLIC_REST_URL
            }
            else {
                billEndpoint = DjangoUrls["bills:api-bills-detail"](window.MARKETPLACE_ENTITY_SLUG, window.BILL_ID)
            }

            const billResponse = await fetch(billEndpoint)
            const bill = await billResponse.json()

            const parts = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)

            this.setState((state, props) => {
                let updatedState = state
                updatedState.billData = bill

                // Split line items into their respective lists
                updatedState.billData.service_charges = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                updatedState.billData.parts = parts
                updatedState.billData.other_charges = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                updatedState.billData.discounts = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                return updatedState
            })
        }

        if (historyHasState(history)) {
            if (document.querySelector(".page-subtitle")) {
                document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[history.state.mode]
            }
            if (document.querySelector(".back-button")) {
                document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            }
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue, then=null) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        }, then)
    }

    switchFormMode = (mode) => {
        if (document.querySelector(".page-subtitle")) {
            document.querySelector(".page-subtitle").innerHTML = PAGE_MODE_SUBTITLES[mode]
        }
        if (document.querySelector(".back-button")) {
            document.querySelector(".back-button").style.display = PAGE_MODE_BACK_BUTTON_DISPLAY[mode]
        }

        if (SECONDARY_PAGE_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }


    // Create bill

    createBill = async () => {
        const dataName = "billData"
        const submittingName = "submittingBill"
        const errorDictName = "bill"
        const endpoint = DjangoUrls["bills:api-bills-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const dataManipulator = (data, state) => {
            let preparedData = deepcopy()(data)
            preparedData.is_draft = false

            // Convert purchase_order to ID
            if (data.purchase_order !== null ) {
                preparedData.purchase_order = data.purchase_order.id
            }

            // Convert service company to slug
            preparedData.service_company = data.service_company.slug

            // Convert service location to ID
            preparedData.vendor = data.vendor.id

            return preparedData
        }

        const onSuccess = (bill) => {
            const successUrl = DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, bill.id)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${bill.custom_id || bill.id}" created`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Bill could not be created",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    voidBill = async () => {
        const endpoint = DjangoUrls["bills:api-bills-void"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)

        const dataName = "voidData"
        const submittingName = "submittingVoid"
        const errorDictName = "void"

        const onSuccess = (json) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${this.state.billData.custom_id || this.state.billData.id}" voided`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Bill could not be voided",
            })
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, undefined, undefined)
    }

    // Mark Bill as Paid
    markBillAsPaid = async () => {
        const endpoint = DjangoUrls["bills:api-bills-mark-as-paid"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)
        const endpointMethod = "POST"
        const successUrl = DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)

        const dataName = "paidData"
        const submittingName = "submittingSend"
        const errorDictName = "send"

        const onSuccess = () => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${this.state.billData.custom_id || this.state.billData.id}" marked as paid`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        const dataManipulator = (data, state) => {
            return data
        }

        document.querySelectorAll("#message_modal_mark_as_paid .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_mark_as_paid .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Bill could not be marked as sent",
            })
            const markAsBilldModal = document.querySelector("#message_modal_mark_as_paid .modal__close")
            if (markAsBilldModal) {
                markAsBilldModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, undefined)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "BILL_CREATE":
                this.createBill()
                break
            case "BILL_EDIT":
                location.assign(DjangoUrls["bills:bills-update"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id))
                break
            case "BILL_PAY":
                // Mark as paid
                window.markBillAsPaid = this.markBillAsPaid
                document.querySelector("#message_modal_mark_as_paid").style.display = ""
                window.MicroModal.show("message_modal_mark_as_paid")
                break
            case "BILL_VOID":
                this.switchToSecondaryForm(PAGE_MODES.VOID_BILL, null, null)
                break
            case "BILL_VOID_SUBMIT":
                this.voidBill()
                break
            case "BILL_DOWNLOAD_PDF":
                location.assign(window.PUBLIC_PDF_URL)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.billData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_PAGE_MODES.includes(this.state.mode)) {
                return <BillDetailsCard
                    bill={this.state.billData}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    returnScroll={this.state.returnScroll}
                    submitting={this.state.submittingBill}
                    errors={this.state.errors.bill}
                ></BillDetailsCard>
            }
            else if (this.state.mode === PAGE_MODES.VOID_BILL) {
                return <BillVoidForm
                    bill={this.state.billData}
                    voidData={this.state.voidData}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    submitting={this.state.submittingVoid}
                    errors={this.state.errors.void}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("voidData", fieldName, fieldValue)}
                    inventoryUsed={this.state.inventoryUsed}
                    returnScroll={0}
                ></BillVoidForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default BillDetailsContainer;
