import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import PriceBookItemCreateContainer from "@legacy/pricebook/PriceBookItemCreateContainer"

import useHasAccess from "@hooks/useHasAccess"
import useIsDesktop from "@hooks/useIsDesktop"
import useNavigationLinks from "@hooks/useNavigationLinks"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useToastQueue from "@hooks/useToastQueue"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"

import { TopBar } from "@organisms"

import { AppFrame } from "@templates"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

import { DECODED_PRICEBOOK_ITEM_URL_PARAM_TYPE_VALUES_MAP } from "@constants/decodedPricebookItemURLParamTypeValues"
import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { LIST_SEARCH_PARAM_TYPE } from "@constants/searchParams"

export default function PricebookEdit() {
    const isDesktop = useIsDesktop()

    const { user } = useUser()
    const { push } = useRouter()
    const { pricebook: pricebookLink } = useNavigationLinks()

    const sublinks = filterUserLinks(pricebookLink.subLinks, user)

    const [listRoute] = useSlugExtractor([PRICEBOOK_ITEMS_ROUTES.LIST])
    const { addToastToQueue } = useToastQueue()
    const { hasPermission } = useHasAccess()

    const pricebookTypeFromURL = new URLSearchParams(window.location.search).get(LIST_SEARCH_PARAM_TYPE)
    const backDestinationWithType = pricebookTypeFromURL
        ? `${listRoute}?${LIST_SEARCH_PARAM_TYPE}=${DECODED_PRICEBOOK_ITEM_URL_PARAM_TYPE_VALUES_MAP[pricebookTypeFromURL]}`
        : listRoute

    const backDestination = hasPermission("pricebook_list_permission", PERMISSION_LEVEL.FULL)
        ? backDestinationWithType
        : undefined

    return (
        <AppFrame.Root pageId="PricebookEdit">
            <TopBar.Root>
                <TopBar.Header>
                    <TopBar.TopBarHeaderStart>
                        <TopBar.PageTitle
                            label={pricebookTypeFromURL ?? ""}
                            icon={icon({ name: "tag", style: "regular", family: "sharp" })}
                            iconActive={icon({ name: "tag", style: "solid", family: "sharp" })}
                            iconOnly={!isDesktop}
                            onNavigationItemSelect={(item) => {
                                push(item.value)
                            }}
                            navigationItems={
                                sublinks.length > 1
                                    ? sublinks.map((item) => ({
                                          label: item.label,
                                          value: item.path,
                                          isActive: pricebookTypeFromURL === item.label,
                                      }))
                                    : undefined
                            }
                        />
                        <TopBar.VerticalDivider />
                        <TopBar.PageSubtitle label="Edit Pricebook Item" backDestination={backDestination} />
                    </TopBar.TopBarHeaderStart>
                </TopBar.Header>
            </TopBar.Root>
            <AppFrame.PageContent>
                <PriceBookItemCreateContainer formMode="EDIT_PRICEBOOKITEM" addToastToQueue={addToastToQueue} />
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
