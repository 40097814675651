import { ColumnPinningState, VisibilityState } from "@tanstack/react-table"

interface GetSortingEnabledColumnsByIdsArgs {
    columnIds: string[]
    columnVisibility: VisibilityState
    columnPinning?: ColumnPinningState
}

type VisibleColumnsMap = {
    pinnedColumns: string[]
    unpinnedColumns: string[]
    allColumns: string[]
}

export default function mapTableVisibleColumns(args: GetSortingEnabledColumnsByIdsArgs): VisibleColumnsMap {
    const { columnIds, columnVisibility, columnPinning } = args

    if (columnIds?.length === 0 || !columnIds) {
        return {
            pinnedColumns: [],
            unpinnedColumns: [],
            allColumns: [],
        }
    }

    const pinnedColumns: string[] = columnPinning?.left ?? []
    const unpinnedColumns: string[] = []

    columnIds.forEach((columnId) => {
        if (columnVisibility[columnId] === false) {
            return
        }

        if (!columnPinning?.left?.includes(columnId)) {
            unpinnedColumns.push(columnId)
        }
    })

    return {
        pinnedColumns,
        unpinnedColumns,
        allColumns: pinnedColumns.concat(unpinnedColumns),
    }
}
