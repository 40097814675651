import useUser from "./useUser"

export default function useHasAccess() {
    const { user } = useUser()

    return {
        hasEntitlement: (entitlement: Entitlement) => !!user?.service_company?.[entitlement],
        hasPermission: (permission: keyof UserPermissionsOnly, level: PermissionLevel) =>
            (user?.permissions?.[permission] ?? 0) >= level,
        hasFlag: (flag: keyof UserPermissionFlags) => !!user?.permissions?.[flag],
    }
}
