import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { Checkbox, Icon } from "@atoms/index"

import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"
import SMSNotificationTooltip from "@pages/Settings/SettingsNotifications/components/SMSNotificationTooltip/SMSNotificationTooltip"

import { SMSCheckboxProps } from "./SMSCheckbox.types"

export default function SMSCheckbox(props: SMSCheckboxProps) {
    const { isChecked, isIndeterminate, onChecked } = props

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()
    const isDesktop = useIsDesktop()

    const { user } = useUser()

    const phoneNotificationsDisabled = !user?.phone || !user?.phone_verified || !defaultMethodsPreference?.contact_sms

    return (
        <SMSNotificationTooltip>
            <Checkbox
                labelPosition="left"
                label={{
                    children: isDesktop ? (
                        "Text"
                    ) : (
                        <Icon
                            icon={icon({
                                name: "message-lines",
                                family: "sharp",
                                style: "regular",
                            })}
                            size={14}
                        />
                    ),
                }}
                colorScheme="gray"
                size="md"
                onCheckedChange={onChecked}
                checked={isIndeterminate ? "indeterminate" : isChecked}
                isDisabled={phoneNotificationsDisabled}
                isControlled={false}
            />
        </SMSNotificationTooltip>
    )
}
