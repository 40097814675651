import { Component } from "react";


class WrapperButton extends Component {

    getClassName = (type) => {
        const typeClassMap = {
            "open_trigger": "open-trigger",
            "tab": "card-tab-switcher__item-button",
            "object_link": "button button--object-link data-panel__list-select__option",
            "back_button": "text-link text-link--back-button",
        }
        return `wrapper-button ${typeClassMap[type] || ""}`
    }

    render() {
        const { type, handler, title, extraClasses, ariaLabel, ariaRole, children, disabled } = this.props
        return <button className={`${this.getClassName(type)}${extraClasses ? ` ${extraClasses}` : ""}`} onClick={handler} title={title} aria-label={ariaLabel} role={ariaRole} disabled={disabled}>{children}</button>
    }
}

export default WrapperButton;
