// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecurrenceCell-base__b_P2m{display:flex;align-items:center;width:var(--size-full);padding:var(--size-3_5) var(--size-4);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.RecurrenceCell-primary__XLvl0{color:var(--gray-12)}.RecurrenceCell-secondary__rt5YV{color:var(--gray-11)}.RecurrenceCell-content__zf_Gg{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "RecurrenceCell-base__b_P2m",
	"primary": "RecurrenceCell-primary__XLvl0",
	"secondary": "RecurrenceCell-secondary__rt5YV",
	"content": "RecurrenceCell-content__zf_Gg"
};
export default ___CSS_LOADER_EXPORT___;
