import { Component } from "react"

import { PriceBookItemTypes } from "@constants/choices"

import InlinePriceOverrideContainer from "../InlinePriceOverrideContainer"

class PriceOverrideListEditSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    renderItem = (item, index) => {
        const { fieldName } = this.props
        return (
            <InlinePriceOverrideContainer
                key={`item_${item.id}_${item.description}_${item.errors}`}
                pricebookItemType={PriceBookItemTypes[fieldName]}
                allItems={this.props.items}
                priceBookOverride={item}
                index={index}
                formatCurrencyValue={this.props.formatCurrencyValue}
                currencySymbol={this.props.currencySymbol}
                updateOverride={(updatedItem) => this.props.updateOverride(index, updatedItem)}
                deleteOverride={this.props.deleteOverride}
                addToastToQueue={this.props.addToastToQueue}
                client={this.props.client}
            />
        )
    }

    render = () => {
        const { fieldName, priceBookOverridesData } = this.props

        return (
            <div id={`id_${fieldName}`} className="data-panel__list-select">
                {priceBookOverridesData.map(this.renderItem)}
            </div>
        )
    }
}

export default PriceOverrideListEditSelect
