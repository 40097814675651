import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useEffect, useRef } from "react"

import { Badge, Icon } from "@atoms"

import styles from "./UnscheduledJobsBottomSheetTrigger.module.scss"
import { UnscheduledJobsBottomSheetTriggerProps } from "./UnscheduledJobsBottomSheetTrigger.types"

export default function UnscheduledJobsBottomSheetTrigger(props: UnscheduledJobsBottomSheetTriggerProps) {
    const { count, onClick } = props

    const triggerRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (triggerRef.current) {
            const buttonTriggerHeight = triggerRef.current.offsetHeight

            const root = document.documentElement
            root.style.setProperty("--unscheduled-jobs-bottom-sheet-trigger-height", `${buttonTriggerHeight}px`)
        }

        return () => {
            const root = document.documentElement
            root.style.setProperty("--unscheduled-jobs-bottom-sheet-trigger-height", `0`)
        }
    }, [triggerRef])

    return (
        <button ref={triggerRef} className={styles.base} onClick={onClick} aria-label="Unscheduled Jobs">
            <Icon
                icon={icon({
                    name: "angles-up",
                    style: "light",
                    family: "sharp",
                })}
                className={styles.icon}
            />
            <div className={styles.headingAndCounter}>
                <div className={styles.heading}>Unscheduled Jobs</div>
                <Badge colorScheme={count === 0 ? "gray" : "tomato"} size="md" variant="subtle" type="counter">
                    {count.toString()}
                </Badge>
            </div>
            <Icon
                icon={icon({
                    name: "angles-up",
                    style: "light",
                    family: "sharp",
                })}
                className={styles.icon}
            />
        </button>
    )
}
