import { valueIsDefined } from "../../core/utils/utils"


function getPriceBookItemPartContext(priceBookItem) {
    const separator = valueIsDefined(priceBookItem.manufacturer) && valueIsDefined(priceBookItem.part_number) ? " \u2022 " : ""
    return `${priceBookItem.manufacturer || ""}${separator}${priceBookItem.part_number || ""}`
}


function validateVendorPartCost(allVendorPartCosts, vendorPartCost, vendorPartCostIndex) {
    let isValid = true
    let errors = {}

    const matchingVendorPartCost = allVendorPartCosts.find(comparison => vendorPartCost.vendor === comparison.vendor)
    const matchingIndex = allVendorPartCosts.findIndex(comparison => vendorPartCost.vendor === comparison.vendor)

    if (matchingVendorPartCost && vendorPartCostIndex !== matchingIndex) {
        isValid = false
        errors.non_field_error = "Another vendor cost for this vendor already exists."
    }

    if (!valueIsDefined(vendorPartCost.vendor_cost)) {
        isValid = false
        errors.vendor_cost = "Cost is required."
    }
    else if (!(vendorPartCost.vendor_cost >= -9999999.99 && vendorPartCost.vendor_cost <= 9999999.99)) {
        isValid = false
        errors.vendor_cost = "Cost must be between -9999999.99 and 9999999.99"
    }

    if (!valueIsDefined(vendorPartCost.vendor_part_number)) {
        isValid = false
        errors.vendor_part_number = "Part Number cannot be blank."
    }

    return { isValid, errors }
}


export function getPricebookTaxById(tax_id, priceBookTaxes) {
    return priceBookTaxes?.find(tax => tax.id === tax_id) || null
}


export function getPricebookTaxByName(tax_name, priceBookTaxes) {
    return priceBookTaxes?.find(tax => tax.description === tax_name) || null
}

export function renderVendorString(vendor) {
    return `${vendor.name}${vendor.is_active ? "" : " (inactive)"}`
    }


export { getPriceBookItemPartContext, validateVendorPartCost }
