import Spinner from "@legacy/core/components/Spinner";

import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import { validateLineItem } from "@legacy/invoices/utils/utils";
import VendorForm from "@legacy/pricebook/forms/VendorForm";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Component } from "react";
import deepcopy from "rfdc";
import LineItemForm from "../core/forms/LineItemForm";
import { CustomIDGenerationModes, LineItemUnitTypes, PriceBookItemTypes } from "../core/utils/enums";
import { currencyFormatter, getCurrencySymbol, historyHasState, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import BillForm from "./forms/BillForm";

dayjs.extend(timezone)

const DEFAULT_NET_VALUES = [
    0, 7, 14, 21, 30, 45, 60, 90
]

const FORM_MODES = {
    ADD_BILL: "ADD_BILL",
    EDIT_BILL: "EDIT_BILL",
    ADD_VENDOR: "ADD_VENDOR",
    EDIT_VENDOR: "EDIT_VENDOR",
    ADD_SERVICE_CHARGE: "ADD_SERVICE_CHARGE",
    EDIT_SERVICE_CHARGE: "EDIT_SERVICE_CHARGE",
    ADD_PART: "ADD_PART",
    EDIT_PART: "EDIT_PART",
    ADD_OTHER_CHARGE: "ADD_OTHER_CHARGE",
    EDIT_OTHER_CHARGE: "EDIT_OTHER_CHARGE",
    ADD_DISCOUNT: "ADD_DISCOUNT",
    EDIT_DISCOUNT: "EDIT_DISCOUNT",
}

const FORM_MODE_SUBTITLES = {
    ADD_BILL: "Add Bill Details",
    EDIT_BILL: "Edit Bill Details",
    ADD_VENDOR: "New Vendor",
    EDIT_VENDOR: "Edit Vendor",
    ADD_SERVICE_CHARGE: "Add Service Charge",
    EDIT_SERVICE_CHARGE: "Edit Service Charge",
    ADD_PART: "Add Part or Material",
    EDIT_PART: "Edit Part or Material",
    ADD_OTHER_CHARGE: "Add Miscellaneous Charge",
    EDIT_OTHER_CHARGE: "Edit Miscellaneous Charge",
    ADD_DISCOUNT: "Add Discount",
    EDIT_DISCOUNT: "Edit Discount",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_BILL: "flex",
    EDIT_BILL: "flex",
    ADD_VENDOR: "none",
    EDIT_VENDOR: "none",
    ADD_SERVICE_CHARGE: "none",
    EDIT_SERVICE_CHARGE: "none",
    ADD_PART: "none",
    EDIT_PART: "none",
    ADD_OTHER_CHARGE: "none",
    EDIT_OTHER_CHARGE: "none",
    ADD_DISCOUNT: "none",
    EDIT_DISCOUNT: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_BILL, FORM_MODES.EDIT_BILL]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_VENDOR,
    FORM_MODES.EDIT_VENDOR,
    FORM_MODES.ADD_SERVICE_CHARGE,
    FORM_MODES.EDIT_SERVICE_CHARGE,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
    FORM_MODES.ADD_OTHER_CHARGE,
    FORM_MODES.EDIT_OTHER_CHARGE,
    FORM_MODES.ADD_DISCOUNT,
    FORM_MODES.EDIT_DISCOUNT,
]

const VENDOR_FORM_MODES = [
    FORM_MODES.ADD_VENDOR,
    FORM_MODES.EDIT_VENDOR,
]

const LINE_ITEM_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_CHARGE,
    FORM_MODES.EDIT_SERVICE_CHARGE,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
    FORM_MODES.ADD_OTHER_CHARGE,
    FORM_MODES.EDIT_OTHER_CHARGE,
    FORM_MODES.ADD_DISCOUNT,
    FORM_MODES.EDIT_DISCOUNT,
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_BILL: "billData",
    EDIT_BILL: "billData",
    ADD_VENDOR: "vendorData",
    EDIT_VENDOR: "vendorData",
    ADD_SERVICE_CHARGE: "lineItemData",
    EDIT_SERVICE_CHARGE: "lineItemData",
    ADD_PART: "lineItemData",
    EDIT_PART: "lineItemData",
    ADD_OTHER_CHARGE: "lineItemData",
    EDIT_OTHER_CHARGE: "lineItemData",
    ADD_DISCOUNT: "lineItemData",
    EDIT_DISCOUNT: "lineItemData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_BILL: "submittingBill",
    EDIT_BILL: "submittingBill",
    ADD_VENDOR: "submittingVendor",
    EDIT_VENDOR: "submittingVendor",
    ADD_SERVICE_CHARGE: "submittingLineItem",
    EDIT_SERVICE_CHARGE: "submittingLineItem",
    ADD_PART: "submittingLineItem",
    EDIT_PART: "submittingLineItem",
    ADD_OTHER_CHARGE: "submittingLineItem",
    EDIT_OTHER_CHARGE: "submittingLineItem",
    ADD_DISCOUNT: "submittingLineItem",
    EDIT_DISCOUNT: "submittingLineItem",
}

const ERROR_NAMES_BY_MODE = {
    ADD_BILL: "bill",
    EDIT_BILL: "bill",
    ADD_VENDOR: "vendor",
    EDIT_VENDOR: "vendor",
    ADD_SERVICE_CHARGE: "lineItem",
    EDIT_SERVICE_CHARGE: "lineItem",
    ADD_PART: "lineItem",
    EDIT_PART: "lineItem",
    ADD_OTHER_CHARGE: "lineItem",
    EDIT_OTHER_CHARGE: "lineItem",
    ADD_DISCOUNT: "lineItem",
    EDIT_DISCOUNT: "lineItem",
}


const LINE_ITEM_TYPES = {
    SERVICE_CHARGES: "service_charges",
    PARTS: "parts",
    OTHER_CHARGES: "other_charges",
    DISCOUNTS: "discounts",
}

const LINE_ITEM_TYPE_MAP = {
    [LINE_ITEM_TYPES.SERVICE_CHARGES]: PriceBookItemTypes.service,
    [LINE_ITEM_TYPES.PARTS]: PriceBookItemTypes.part,
    [LINE_ITEM_TYPES.OTHER_CHARGES]: PriceBookItemTypes.other,
    [LINE_ITEM_TYPES.DISCOUNTS]: PriceBookItemTypes.discount,
}

const LINE_ITEM_TYPE_BY_MODE = {
    ADD_SERVICE_CHARGE: PriceBookItemTypes.service,
    EDIT_SERVICE_CHARGE: PriceBookItemTypes.service,
    ADD_PART: PriceBookItemTypes.part,
    EDIT_PART: PriceBookItemTypes.part,
    ADD_OTHER_CHARGE: PriceBookItemTypes.other,
    EDIT_OTHER_CHARGE: PriceBookItemTypes.other,
    ADD_DISCOUNT: PriceBookItemTypes.discount,
    EDIT_DISCOUNT: PriceBookItemTypes.discount,
}

const BILLING_ADDRESS_FIELD_NAMES = [
    "billing_address_recipient",
    "billing_address_street",
    "billing_address_unit",
    "billing_address_city",
    "billing_address_state",
    "billing_address_postal",
    "billing_address_country",
]

const SHIPPING_ADDRESS_FIELD_NAMES = [
    "shipping_address_street",
    "shipping_address_unit",
    "shipping_address_city",
    "shipping_address_state",
    "shipping_address_postal",
    "shipping_address_country",
]

class BillCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_BILL
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            billData: null,
            purchaseOrderData: null,
            vendorData: {},
            lineItemData: {},
            deletedLineItemIDs: {},

            attachments: [],

            selectedVendor: null,

            isDraft: false,

            errors: {
                bill: {},
                vendor: {},
                lineItem: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            preferredTimezone: window.PREFERRED_TIMEZONE,
            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,

            phoneNumberCountry: window.PHONE_NUMBER_COUNTRY,
            showCustomBillIDField: window.BILL_CUSTOM_ID_GENERATION_MODE === CustomIDGenerationModes.manual,
            showQuickBooksItemSelect: false,
            showQuickBooksRevenueAccountSelect: false,
            showQuickBooksTaxAgencyVendorSelect: false,

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            defaultSelectedNet: null,
            useBillingAddress: false,
            useShippingAddress: false,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        // If props tell us this is an edit view, grab bill data via rest
        if (this.state.defaultMode === FORM_MODES.EDIT_BILL && window.BILL_ID) {
            if (this.state.billData === null) {
                const billEndpoint = DjangoUrls["bills:api-bills-detail"](window.MARKETPLACE_ENTITY_SLUG, window.BILL_ID)
                const billResponse = await fetch(billEndpoint)
                const bill = await billResponse.json()
            this.setState((state, props) => {
                let updatedState = state
                    updatedState.billData = bill

                    updatedState.selectedVendor = bill.vendor
                    updatedState.vendor_id = updatedState.selectedVendor.id

                    updatedState.isDraft = bill.is_draft

                    updatedState.attachments = deepcopy()(bill.attachments)
                    updatedState.labels = deepcopy()(bill.labels)

                    // Move purchaseOrder data and convert purchaseOrder to ID
                    if (bill.purchase_order !== null && bill.purchase_order !== undefined) {
                        updatedState.purchaseOrderData = deepcopy()(bill.purchase_order)

                        updatedState.purchaseOrderData.parts = bill.purchase_order.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                        updatedState.billData.purchase_order = bill.purchase_order.id
                    }
                    else {
                        updatedState.purchaseOrderData = {}
                    }

                    // Convert service company to slug
                    updatedState.billData.service_company = bill.service_company.slug

                    // Convert service location to ID
                    updatedState.billData.vendor = bill.vendor.id

                    this.resetBillData(updatedState, state, bill)

                    // Split line items into their respective lists
                    updatedState.billData.service_charges = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                    updatedState.billData.parts = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    updatedState.billData.other_charges = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                    updatedState.billData.discounts = bill.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                    return updatedState
                })
            }
        }
        else {
            const localTime = dayjs.tz(undefined, window.PREFERRED_TIMEZONE)
            const localDateString = localTime.format("YYYY-MM-DD")

            const hasPurchaseOrdersEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_purchase_orders_enabled;
            const hasPurchaseOrdersPermissions = window.CURRENT_USER?.permissions.purchase_orders_view_permission >= PERMISSION_LEVEL.FULL
            const fromPurchaseOrderID = new URLSearchParams(document.location.search).get("from_purchase_order") || null
            if (hasPurchaseOrdersEntitlement && hasPurchaseOrdersPermissions && fromPurchaseOrderID !== null && this.state.purchaseOrderData === null) {
                const purchaseOrderEndpoint = DjangoUrls["purchase-orders:api-purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, fromPurchaseOrderID)

                const purchaseOrderResponse = await fetch(purchaseOrderEndpoint)
                let purchaseOrder = await purchaseOrderResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.purchaseOrderData = purchaseOrder

                    // Split purchaseOrder line items into their respective lists
                    updatedState.purchaseOrderData.parts = purchaseOrder.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    return updatedState
                })
            }
            else {
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.purchaseOrderData = {}
                    return updatedState
                })
            }

            this.setState((state, props) => {
                let updatedState = state

                this.resetBillData(updatedState, state, {})

                updatedState.billData.service_company = window.MARKETPLACE_ENTITY_SLUG
                updatedState.billData.date_received = localDateString
                updatedState.billData.date_issued = localDateString
                updatedState.billData.attachments = []
                updatedState.billData.labels = []

                // Set fields from the purchaseOrder (if from a purchaseOrder)
                if (Object.keys(state.purchaseOrderData).length !== 0) {
                    updatedState.billData.purchase_order = state.purchaseOrderData.id

                    updatedState.selectedVendor = state.purchaseOrderData.vendor
                    updatedState.vendor_id = updatedState.selectedVendor.id

                    updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(state)
                    updatedState.billData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)

                    updatedState.billData.vendor = state.purchaseOrderData.vendor.id
                }

                // Pull bill line items from purchaseOrder line items (if from a purchaseOrder)
                for (let line_item_type of Object.values(LINE_ITEM_TYPES)) {
                    if (state.billData[line_item_type] === undefined) {
                        updatedState.billData[line_item_type] = []

                        if (Object.keys(state.purchaseOrderData).length !== 0) {
                            state.purchaseOrderData[line_item_type]?.forEach(lineItem => {
                                lineItem.id = null
                                lineItem.price = lineItem.expected_cost
                                updatedState.billData[line_item_type].push(lineItem)
                            })
                        }
                    }
                }

                this.resetBillData(updatedState, state, updatedState.billData)

                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue

            if (formName === "billData" && fieldName === "date_issued") {
                updatedState.billData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
                updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
            }
            else if (formName === "billData" && fieldName === "date_due") {
                updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
                updatedState.billData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
            }
            else if (formName === "billData" && fieldName === "net") {
                updatedState.defaultSelectedNet = valueIsDefined(fieldValue) ? parseInt(fieldValue) : null
                updatedState.billData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
            }
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                // To help with uniqueness check
                data.originalDescription = data.description
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (LINE_ITEM_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].line_item_type = LINE_ITEM_TYPE_BY_MODE[newFormMode]
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    resetBillData = (updatedState, state, billData) => {
        updatedState.billData = billData
        updatedState.billData.is_draft = state.isDraft

        // Handle setting the initial billing and shipping addresses
        let noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.billData[fieldName]))
        let noShippingAddress = SHIPPING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.billData[fieldName]))

        // If a billing address isn't set, first attempt to grab it from the selected vendor
        if (noBillingAddress && updatedState.selectedVendor !== null) {
            BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.billData[fieldName] = updatedState.selectedVendor[fieldName] || "")
            noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.billData[fieldName]))
        }

        // If a shipping address isn't set, first attempt to grab it from the service company
        if (noShippingAddress) {
            SHIPPING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.billData[fieldName] = window.CURRENT_USER.service_company[fieldName.replace("shipping_address", "physical_address")] || "")
            noShippingAddress = SHIPPING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.billData[fieldName]))
        }

        // If the billing address is the same as the vendor's billing address, unset it so that "same as vendor" takes over
        if (updatedState.selectedVendor !== null) {
            const billingAddressesAreEqual = BILLING_ADDRESS_FIELD_NAMES.every(
                fieldName => updatedState.billData[fieldName] === updatedState.selectedVendor[fieldName]
            )
            if (billingAddressesAreEqual) {
                BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.billData[fieldName] = "")
            }
        }

        // If the shipping address is the same as the service company's physical address, unset it so that "same as company" takes over
        const shippingAddressesAreEqual = SHIPPING_ADDRESS_FIELD_NAMES.every(
            fieldName => updatedState.billData[fieldName] === window.CURRENT_USER.service_company[fieldName.replace("shipping_address", "physical_address")]
        )
        if (shippingAddressesAreEqual) {
            SHIPPING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.billData[fieldName] = "")
        }

        // Re-set the no address check
        noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.billData[fieldName]))
        noShippingAddress = SHIPPING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.billData[fieldName]))
        updatedState.useBillingAddress = !noBillingAddress
        updatedState.useShippingAddress = !noShippingAddress
    }

    updateVendorSelection = (selectedVendor) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedVendor !== null) {
                updatedState.selectedVendor = selectedVendor

                // If a different service location is selected, wipe relevant data
                if (state.vendor_id !== undefined && selectedVendor.id !== state.vendor_id) {
                    updatedState.billData.date_due = null
                    BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.billData[fieldName] = "")
                }

                // Set the selection, set the data
                updatedState.billData.vendor = selectedVendor.id
                updatedState.vendor_id = selectedVendor.id

                this.resetBillData(updatedState, state, state.billData)

                // Update default selected net
                updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
                updatedState.billData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
            }
            else {
                // Vendor was unset. Unset data
                updatedState.selectedVendor = null
            }

            return updatedState
        })
    }

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.billData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    calculateDefaultSelectedNet = (state) => {
        let billNetValue = null
        if (valueIsDefined(state.billData.date_due) && valueIsDefined(state.billData.date_issued)) {
            billNetValue = dayjs(state.billData.date_due).diff(dayjs(state.billData.date_issued), "day")
        }

        const defaultVendorNet = state.selectedVendor ? state.selectedVendor.payment_terms : null

        if (valueIsDefined(billNetValue)) {
            return billNetValue
        }
        else if (valueIsDefined(defaultVendorNet)) {
            return defaultVendorNet
        }
        else {
            return null
        }
    }

    calculateDueDate = (state, net) => {
        if (valueIsDefined(state.billData.date_issued) && valueIsDefined(net)){
            return dayjs(state.billData.date_issued).add(net, "day").format("YYYY-MM-DD")
        }
        else {
            return null
        }
    }

    getNetChoices = () => {
        const defaultVendorNet = this.state.selectedVendor ? this.state.selectedVendor.payment_terms : null

        let choiceValues = new Set(DEFAULT_NET_VALUES)
        choiceValues.add(this.state.defaultSelectedNet)
        choiceValues.add(defaultVendorNet)
        choiceValues = Array.from(choiceValues).filter(net => net != null).sort((a, b) => a - b)

        let choices = [{"value": "", "label": "Choose payment terms..."}]
        choiceValues.map(net => choices.push({"value": net, "label": net !== 0 ? `Net ${net}` : "Same Day"}))

        return choices
    }

    // Crud Line Items

    addCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.billData, this.state.lineItemData, true, this.state.showQuickBooksItemSelect, false, false, true)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData
                data.line_item_type = LINE_ITEM_TYPE_MAP[lineItemListName]

                // Re-use an existing ID for this description to avoid uniqueness constraint issues
                if (data.description in state.deletedLineItemIDs) {
                    data.id = state.deletedLineItemIDs[data.description]
                }
                else {
                    data.id = null
                }

                if (!valueIsDefined(data.cost)) {
                    data.cost = 0
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                updatedState.billData[lineItemListName].push(data)
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    lineItemEquals = (existing, updated) => {
        // If the object is new, it won't have an id. Check the description instead
        return existing.id !== null ? existing.id === updated.id : existing.description === updated.originalDescription
    }

    updateCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.billData, this.state.lineItemData, true, this.state.showQuickBooksItemSelect, false, false, true)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData

                if (!valueIsDefined(data.cost)) {
                    data.cost = 0
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                const lineItemIndex = updatedState.billData[lineItemListName].findIndex(lineItem => this.lineItemEquals(lineItem, state.lineItemData))

                updatedState.billData[lineItemListName][lineItemIndex] = data
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    deleteCurrentLineItem = (lineItemListName) => {
        this.setState((state, props) => {
            let updatedState = state

            const data = state.lineItemData

            // Save the ID in case this description is going to be reused
            if (data.id) {
                updatedState.deletedLineItemIDs[data.description] = data.id
            }

            updatedState.billData[lineItemListName] = updatedState.billData[lineItemListName].filter(lineItem => !this.lineItemEquals(lineItem, data))
            updatedState.errors.lineItem = {}
            return updatedState
        })
        this.switchToPrimaryForm()
    }

    // Crud Bill

    previewBill = async => {
        // Create a draft and redirect to the preview page
        const endpoint = DjangoUrls["bills:api-bills-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (bill) => {
            const successUrl = DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, bill.id)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${bill.custom_id || bill.id}" draft saved`,
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Bill draft could not be saved",
            })
        }
        this.CUDBill(endpoint, "POST", onSuccess, onError, true)
    }

    createBill = async (isDraft) => {
        const endpoint = DjangoUrls["bills:api-bills-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (bill) => {
            let successUrl

            if (window.CURRENT_USER?.permissions.bills_list_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["dashboard:dashboard"](window.MARKETPLACE_ENTITY_SLUG)
            } else if (isDraft || window.CURRENT_USER?.permissions.bills_view_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["bills:bills-list"](window.MARKETPLACE_ENTITY_SLUG)
            }
            else {
                successUrl = DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, bill.id)
            }
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${bill.custom_id || bill.id}" ${isDraft ? "draft saved" : "created"}`,
                cta:
                    isDraft && window.CURRENT_USER?.permissions.bills_view_permission >= PERMISSION_LEVEL.FULL ?
                    {
                        children: "View",
                        destination: DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, bill.id)
                    }
                    :
                    undefined,
                path: successUrl.split("?")[0],
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Bill ${isDraft ? "draft could not be saved" : "could not be created"}`,
            })
        }

        this.CUDBill(endpoint, "POST", onSuccess, onError, isDraft)
    }

    updateBill = async (resend=false) => {
        const endpoint = DjangoUrls["bills:api-bills-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)
        const successUrl = DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)
        const onSuccess = (bill) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${bill.custom_id || bill.id}" updated`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Bill could not be updated",
            })
        }
        this.CUDBill(endpoint, "PUT", onSuccess, onError, false)
    }

    deleteDraftBill = async () => {
        const endpoint = DjangoUrls["bills:api-bills-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id)
        let successUrl = DjangoUrls["bills:bills-list"](window.MARKETPLACE_ENTITY_SLUG)

        if (valueIsDefined(this.state.billData.purchase_order)) {
            successUrl = DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.purchase_order)
        }

        const onSuccess = (bill) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Bill "${this.state.billData.custom_id || this.state.billData.id}" draft deleted`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_confirm_delete_draft .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_delete_draft .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Bill draft could not be deleted",
            })
            const deleteDraftModal = document.querySelector("#message_modal_confirm_delete_draft .modal__close")
            if (deleteDraftModal) {
                deleteDraftModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        this.CUDBill(endpoint, "DELETE", onSuccess, onError, false)
    }

    CUDBill = async (endpoint, endpointMethod, onSuccess, onError, isDraft) => {
        const dataName = "billData"
        const submittingName = "submittingBill"
        const errorDictName = "bill"

        const dataManipulator = (data, state) => {
            data.line_items = [...data.service_charges.map(item => ({...item, cost: item.cost ?? 0})), ...data.parts, ...data.other_charges.map(item => ({...item, cost: item.cost ?? 0})), ...data.discounts.map(item => ({...item, cost: item.cost ?? 0}))]

            // Don't send a billing address if "same as physical address" was selected
            if (!state.useBillingAddress) {
                BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => delete data[fieldName])
            }
            // Don't send a shipping address if "same as physical address" was selected
            if (!state.useShippingAddress) {
                SHIPPING_ADDRESS_FIELD_NAMES.forEach(fieldName => delete data[fieldName])
            }

            // Convert blank Bill ID value to null
            data.custom_id = data.custom_id || null

             // Set a blank label list if there aren't any selected
             data.labels = data.labels || []

            const cleanData = deepcopy()(data)
            cleanData.is_draft = isDraft  // We don't want this to change the actual object
            return cleanData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, custom_id must make a unique set.") {
                errorDict["custom_id"] = "A bill with this ID already exists."
            }
            else if (fieldName === "line_items_subtotal") {
                errorDict["line_items"] = message
            }
            else if (fieldName === "line_items") {
                errorDict["line_items"] = "Please correct the line item errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state

                    const indexedLineItems = [
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.SERVICE_CHARGES],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.PARTS],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.OTHER_CHARGES],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.DISCOUNTS]
                    ]
                    message.map((lineItemError, index) => indexedLineItems[index].errors = lineItemError)

                    return updatedState
                })
            }
            else if (fieldName === "labels" && Array.isArray(message)) {
                errorDict["labels"] = "Labels must be fewer than 100 characters."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud Vendor

    createVendor = async () => {
        const endpoint = DjangoUrls["pricebook:api-vendors-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const onSuccess = (vendor) => {
            this.updateVendorSelection(vendor)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${vendor.name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be created",
            })
        }

        this.createUpdateVendor(endpoint, endpointMethod, onSuccess, onError)
    }

    updateVendor = async () => {
        const endpoint = DjangoUrls["pricebook:api-vendors-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedVendor.id)
        const endpointMethod = "PUT"

        const onSuccess = (vendor) => {
            this.updateVendorSelection(vendor)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${vendor.name}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be updated",
            })
        }

        this.createUpdateVendor(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateVendor = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "vendorData"
        const submittingName = "submittingVendor"
        const errorDictName = "vendor"

        const dataManipulator = (data, state) => {
            data.contacts = data.contacts || []

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A vendor with this name already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "BILL_PREVIEW":
                this.previewBill()
                break
            case "BILL_CREATE":
                this.createBill(false)
                break
            case "BILL_CREATE_DRAFT":
                this.createBill(true)
                break
            case "BILL_UPDATE":
                this.updateBill(false)
                break
            case "BILL_DELETE_DRAFT":
                window.deleteDraftBill = this.deleteDraftBill
                document.querySelector("#message_modal_confirm_delete_draft").style.display = ""
                window.MicroModal.show("message_modal_confirm_delete_draft")
                break
            case "BILL_CANCEL_EDITS":
                location.assign(DjangoUrls["bills:bills-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.billData.id))
                break
            case "VENDOR_CREATE":
                this.createVendor()
                break
            case "VENDOR_UPDATE":
                this.updateVendor()
                break
            case "VENDOR_CANCEL_CREATE":
                this.switchToPrimaryForm()
                break
            case "VENDOR_CANCEL_EDITS":
                this.switchToPrimaryForm()
                break
            case "ADD_SERVICE_CHARGE":
                this.addCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "EDIT_SERVICE_CHARGE":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "DELETE_SERVICE_CHARGE":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "ADD_PART":
                this.addCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "EDIT_PART":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "DELETE_PART":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "ADD_OTHER_CHARGE":
                this.addCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "EDIT_OTHER_CHARGE":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "DELETE_OTHER_CHARGE":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "ADD_DISCOUNT":
                this.addCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "EDIT_DISCOUNT":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "DELETE_DISCOUNT":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "TOGGLE_USE_BILLING_ADDRESS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.useBillingAddress = !state.useBillingAddress
                    return updatedState
                })
                break
            case "TOGGLE_USE_SHIPPING_ADDRESS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.useShippingAddress = !state.useShippingAddress
                    return updatedState
                })
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    foldDataComplete = () => {
        const noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(this.state.billData[fieldName]))
        const noShippingAddress = SHIPPING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(this.state.billData[fieldName]))

        const billingAddressValid = this.state.useBillingAddress === false || (this.state.useBillingAddress === true && !noBillingAddress)
        const shippingAddressValid = this.state.useShippingAddress === false || (this.state.useShippingAddress === true && !noShippingAddress)
        const dateReceivedValid = Boolean(this.state.billData.date_received)
        const dateIssuedValid = Boolean(this.state.billData.date_issued)
        const dateDueValid = Boolean(this.state.billData.date_due)
        const billNumberValid = this.state.showCustomBillIDField === false || (this.state.showCustomBillIDField === true && Boolean(this.state.billData.custom_id))

        return billingAddressValid && shippingAddressValid && dateReceivedValid && dateIssuedValid && dateDueValid && billNumberValid
    }

    render() {
        if (this.state.billData === null || this.state.purchaseOrderData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <BillForm
                    mode={this.state.mode}
                    submitting={this.state.submittingBill}
                    bill={this.state.billData}
                    purchaseOrder={this.state.purchaseOrderData}
                    errors={this.state.errors.bill}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("billData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateVendorSelection={this.updateVendorSelection}
                    selectedVendor={this.state.selectedVendor}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    showCustomBillIDField={this.state.showCustomBillIDField}
                    useBillingAddress={this.state.useBillingAddress}
                    useShippingAddress={this.state.useShippingAddress}
                    foldDataComplete={this.foldDataComplete()}
                    showQuickBooksItemSelect={this.state.showQuickBooksItemSelect}
                    defaultSelectedNet={this.state.defaultSelectedNet}
                    netChoices={this.getNetChoices()}
                    fileStackAPIKey={this.state.fileStackAPIKey}
                    fileStackPolicy={this.state.fileStackPolicy}
                    fileStackSignature={this.state.fileStackSignature}
                    updateAttachments={this.updateAttachments}
                    returnScroll={this.state.returnScroll}
                ></BillForm>
            }
            else if (VENDOR_FORM_MODES.includes(this.state.mode)) {
                return <VendorForm
                    mode={this.state.mode}
                    submitting={this.state.submittingVendor}
                    vendor={this.state.vendorData}
                    errors={this.state.errors.vendor}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("vendorData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={undefined}
                    returnScroll={0}
                />
            }
            else if (LINE_ITEM_FORM_MODES.includes(this.state.mode)) {
                return <LineItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingLineItem}
                    parent={this.state.billData}
                    lineItem={this.state.lineItemData}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    errors={this.state.errors.lineItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("lineItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                    objectName={"Bill"}
                    preferredTimezone={this.state.preferredTimezone}
                    isBillLineItem={true}
                    showQuickBooksItemSelect={this.state.showQuickBooksItemSelect}
                ></LineItemForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default BillCreateContainer;
