import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { IconButton } from "@atoms/index"

export default function TimelineViewTechniciansBarHeader() {
    return (
        <div className="job-timeline-view__technicians-bar__header">
            <span className="job-timeline-view__technicians-bar__header-text">Technicians</span>
            <IconButton
                className="job-timeline-view__technicians-bar__header-toggle-button jsJobTechniciansBarColumnHeaderToggle"
                icon={icon({
                    name: "arrow-left-to-line",
                    family: "sharp",
                    style: "regular",
                })}
                variant="ghost"
                size="sm"
                onClick={() => null}
                colorScheme="gray"
            />
        </div>
    )
}
