import useHasAccess from "@hooks/useHasAccess"

import { AccessCheckProps } from "./AccessCheck.types"

export default function AccessCheck(props: AccessCheckProps) {
    const { children, entitlements, permissions, flags, placeholder = null } = props

    const { hasEntitlement, hasPermission, hasFlag } = useHasAccess()

    const hasEntitlementAccess = entitlements?.length
        ? entitlements.some((entitlement) => hasEntitlement(entitlement))
        : true

    const hasPermissionAccess = permissions
        ? Object.entries(permissions).some(([key, level]) => hasPermission(key as keyof UserPermissionsOnly, level))
        : true

    const hasFlagAccess = flags?.length ? flags.some((flag) => hasFlag(flag)) : true

    if (!hasEntitlementAccess || !hasPermissionAccess || !hasFlagAccess) {
        return placeholder
    } else {
        return children
    }
}
