import { useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { createContext, useEffect, useState } from "react"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import matchesDesktopScreen from "@utils/matchesDesktopScreen"

import { AppFrame } from "@templates"

import { ROOT_ROUTE } from "@routes/root"

import { AUTH_NEXT_PARAM } from "@constants/searchParams"
import { AUTHENTICATION_METHOD_PREFERENCE } from "@constants/storage"

import { AuthContentHeight, AuthContextValues, AuthMethod, EmailData, PhoneData } from "./Auth.types"
import AuthAlternativeLoginMethods from "./AuthAlternativeLoginMethods/AuthAlternativeLoginMethods"
import AuthContainer from "./AuthContainer/AuthContainer"
import AuthContent from "./AuthContent/AuthContent"
import AuthDisclaimer from "./AuthDisclaimer/AuthDisclaimer"
import AuthDivider from "./AuthDivider/AuthDivider"
import AuthEmailCodeForm from "./AuthEmailCodeForm/AuthEmailCodeForm"
import AuthEmailPasswordForm from "./AuthEmailPasswordForm/AuthEmailPasswordForm"
import AuthHeader from "./AuthHeader/AuthHeader"
import AuthOTP from "./AuthOTP/AuthOTP"
import AuthPhoneCodeForm from "./AuthPhoneCodeForm/AuthPhoneCodeForm"
import AuthPhonePasswordForm from "./AuthPhonePasswordForm/AuthPhonePasswordForm"
import AuthSlide from "./AuthSlide/AuthSlide"
import AuthSlides from "./AuthSlides/AuthSlides"

export const AuthContext = createContext<AuthContextValues | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const { isDesktop } = matchesDesktopScreen()

const authMethodAtom = atomWithStorage<AuthMethod>(
    AUTHENTICATION_METHOD_PREFERENCE,
    isDesktop ? "email_and_code" : "phone_and_code",
    undefined,
    {
        getOnInit: true,
    },
)

export default function Auth() {
    const [authMethod, setAuthMethod] = useAtom(authMethodAtom)

    const [isOnOTPSlide, setIsOnOTPSlide] = useState<boolean>(false)

    const [emailData, setEmailData] = useState<EmailData>({
        email: null,
        emailId: null,
    })
    const [phoneData, setPhoneData] = useState<PhoneData>({
        phone: null,
        phoneId: null,
    })
    const [isWaitingForCodeInput, setIsWaitingForCodeInput] = useState<boolean>(false)

    const [contentHeight, setContentHeight] = useState<AuthContentHeight>("auto")

    const isEmailAndCodeMethod = authMethod === "email_and_code"
    const isEmailAndPasswordMethod = authMethod === "email_and_password"
    const isPhoneAndCodeMethod = authMethod === "phone_and_code"
    const isPhoneAndPasswordMethod = authMethod === "phone_and_password"

    const urlRouteParam = extractURLSearchParam(AUTH_NEXT_PARAM, false)

    const postAuthRedirectRoute = urlRouteParam || ROOT_ROUTE

    useEffect(() => {
        if (isEmailAndCodeMethod || isEmailAndPasswordMethod) {
            setPhoneData({
                phone: null,
                phoneId: null,
            })
        }

        if (isPhoneAndCodeMethod || isPhoneAndPasswordMethod) {
            setEmailData({
                email: null,
                emailId: null,
            })
        }
    }, [isPhoneAndCodeMethod, isOnOTPSlide, isEmailAndCodeMethod, isEmailAndPasswordMethod])

    return (
        <AppFrame.Root pageId="Auth" navigationType="none">
            <AppFrame.PageContent>
                <AuthContainer>
                    <AuthContext.Provider
                        value={{
                            contentHeight,
                            authMethod,
                            isOnOTPSlide,
                            setContentHeight,
                            setAuthMethod,
                            setIsOnOTPSlide,
                            emailData,
                            setEmailData,
                            phoneData,
                            setPhoneData,
                            isWaitingForCodeInput,
                            setIsWaitingForCodeInput,
                            postAuthRedirectRoute,
                        }}
                    >
                        <AuthContent>
                            <AuthHeader />

                            <AuthSlides>
                                <AuthSlide animateFrom="left" isActive={isEmailAndCodeMethod && !isOnOTPSlide}>
                                    <AuthEmailCodeForm />
                                </AuthSlide>
                                <AuthSlide animateFrom="right" isActive={isEmailAndCodeMethod && isOnOTPSlide}>
                                    <AuthOTP isActive={isEmailAndCodeMethod && isOnOTPSlide} />
                                </AuthSlide>
                                <AuthSlide animateFrom="right" isActive={isEmailAndPasswordMethod}>
                                    <AuthEmailPasswordForm />
                                </AuthSlide>
                                <AuthSlide animateFrom="left" isActive={isPhoneAndCodeMethod && !isOnOTPSlide}>
                                    <AuthPhoneCodeForm />
                                </AuthSlide>
                                <AuthSlide animateFrom="right" isActive={isPhoneAndCodeMethod && isOnOTPSlide}>
                                    <AuthOTP isActive={isPhoneAndCodeMethod && isOnOTPSlide} />
                                </AuthSlide>
                                <AuthSlide animateFrom="right" isActive={isPhoneAndPasswordMethod}>
                                    <AuthPhonePasswordForm />
                                </AuthSlide>
                            </AuthSlides>
                            <AuthDivider />
                            <AuthAlternativeLoginMethods />
                            <AuthDisclaimer />
                        </AuthContent>
                    </AuthContext.Provider>
                </AuthContainer>
            </AppFrame.PageContent>
        </AppFrame.Root>
    )
}
