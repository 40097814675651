import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { AccessCheck } from "@particles"

import { TopBar } from "@organisms"
import ViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider"

import { AppFrame } from "@templates"

import { VENDOR_ROUTES } from "@routes/vendor"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { VENDORS_LIST_CONTROLS_VIEW } from "@constants/storage"

import VendorTableView from "./views/VendorTableView/VendorTableView"

export default function VendorList() {
    const isDesktop = useIsDesktop()

    const [createRoute] = useSlugExtractor([VENDOR_ROUTES.CREATE])

    return (
        <AppFrame.Root pageId="VendorList">
            <ViewTypesProvider viewTypeKey={VENDORS_LIST_CONTROLS_VIEW} defaultViewType="table">
                {() => (
                    <>
                        <TopBar.Root>
                            <TopBar.Header>
                                <TopBar.TopBarHeaderStart>
                                    <TopBar.PageTitle
                                        label="Vendors"
                                        icon={icon({ name: "store", style: "regular", family: "sharp" })}
                                        iconActive={icon({ name: "store", style: "solid", family: "sharp" })}
                                    />
                                </TopBar.TopBarHeaderStart>
                                <TopBar.TopBarHeaderEnd>
                                    <TopBar.PagePresence />
                                    <AccessCheck
                                        permissions={{
                                            vendors_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <AccessCheck
                                            permissions={{
                                                vendors_view_permission: PERMISSION_LEVEL.FULL,
                                            }}
                                        >
                                            <TopBar.SearchButton scope="Vendors" />
                                        </AccessCheck>
                                    </AccessCheck>
                                    <TopBar.ViewSelector viewTypes={["table"]} />
                                    <AccessCheck
                                        permissions={{
                                            vendors_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <TopBar.CTAButton destination={createRoute}>
                                            {isDesktop ? "New Vendor" : null}
                                        </TopBar.CTAButton>
                                    </AccessCheck>
                                </TopBar.TopBarHeaderEnd>
                            </TopBar.Header>
                        </TopBar.Root>
                        <VendorTableView />
                    </>
                )}
            </ViewTypesProvider>
        </AppFrame.Root>
    )
}
