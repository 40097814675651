import { memo, useEffect } from "react"

import toast from "@molecules/Toast/Toast"

import TopBar from "@organisms/TopBar/TopBar"

import { JobTimelineScheduler, JobTimelineViewSchedulerWrapperProps } from "./JobTimelineView.types"
import JobTimelineViewControls from "./JobTimelineViewControls/JobTimelineViewControls"
import useJobTimelineViewBryntumInstances from "./hooks/useJobTimelineViewBryntumInstances"
import useJobTimelineViewData from "./hooks/useJobTimelineViewData"
import useJobTimelineViewNavigation from "./hooks/useJobTimelineViewNavigation"
import useJobTimelineViewSchedulerConfig from "./hooks/useJobTimelineViewSchedulerConfig"
import useJobTimelineViewSchedulerRefresh from "./hooks/useJobTimelineViewSchedulerRefresh"
import useJobTimelineViewStates from "./hooks/useJobTimelineViewStates"
import useJobTimelineViewUIUtils from "./hooks/useJobTimelineViewUIUtils"

function JobTimelineViewSchedulerWrapper(props: JobTimelineViewSchedulerWrapperProps) {
    const { children } = props

    const {
        isSchedulerConfigured,
        setIsSchedulerConfigured,
        visibleDateRange,
        dateRange,
        timelineViewOrientation,
        timeFrameType,
        hasVisibleDateRangeChanged,
        hasTimeFrameTypeChanged,
        shouldShowCancelledJobs,
        shouldShowFinalizedJobs,
        shouldShowWeekends,
    } = useJobTimelineViewStates()

    const { configureAndPopulateScheduler, dataIsLoaded } = useJobTimelineViewData()

    const { configureSchedulerTimezone, configureSchedulerDates } = useJobTimelineViewSchedulerConfig()

    const {
        calendarData,
        isFetchingCalendarData,
        isFetchingAvailabilitySchedulesData,
        isAvailabilitySchedulesError,
        isCalendarDataError,
    } = useJobTimelineViewData()

    const { startRefreshRowsInterval } = useJobTimelineViewSchedulerRefresh()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const { handleScrollPosition } = useJobTimelineViewNavigation()

    const {
        hideFinalizedJobsFromScheduler,
        showFinalizedJobsInScheduler,
        hideCancelledJobsFromScheduler,
        showCancelledJobsInScheduler,
        hideWeekendsFromScheduler,
        showWeekendsOnScheduler,
    } = useJobTimelineViewUIUtils()

    useEffect(() => {
        const interval = startRefreshRowsInterval()

        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (!isSchedulerConfigured) {
            void configureSchedulerTimezone().then(() => {
                configureSchedulerDates()
                shouldShowFinalizedJobs ? showFinalizedJobsInScheduler() : hideFinalizedJobsFromScheduler()
                shouldShowCancelledJobs ? showCancelledJobsInScheduler() : hideCancelledJobsFromScheduler()
                shouldShowWeekends ? showWeekendsOnScheduler() : hideWeekendsFromScheduler()
            })
        }
    }, [])

    useEffect(() => {
        if ((dataIsLoaded && !isSchedulerConfigured) || (hasVisibleDateRangeChanged && dataIsLoaded)) {
            configureAndPopulateScheduler()

            if (!isSchedulerConfigured) {
                setIsSchedulerConfigured(true)
            }
        }
    }, [calendarData, isFetchingCalendarData, isFetchingAvailabilitySchedulesData, dateRange])

    useEffect(() => {
        // this is a workaround to force vertical headers to rerender when the visible date changes
        // scheduler pro has a `visibleDate` property but to modify it causes too many side effects
        if (timelineViewOrientation === "vertical" && hasVisibleDateRangeChanged) {
            const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler

            if (schedulerInstance) {
                schedulerInstance.currentVisibleDates = visibleDateRange

                schedulerInstance.refresh()
                schedulerInstance.resourceColumns.refresh()
            }
        }
    }, [visibleDateRange])

    useEffect(() => {
        if (timelineViewOrientation === "vertical" && hasTimeFrameTypeChanged) {
            const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler

            if (schedulerInstance) {
                schedulerInstance.timeFrameType = timeFrameType
            }
        }
    }, [timeFrameType])

    useEffect(() => {
        if (isCalendarDataError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not load jobs",
            })
        }
    }, [isCalendarDataError])

    useEffect(() => {
        if (isAvailabilitySchedulesError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not load technicians",
            })
        }
    }, [isAvailabilitySchedulesError])

    useEffect(() => {
        const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler

        if (isSchedulerConfigured && schedulerInstance) {
            schedulerInstance.stopDateStateUpdates = true
            handleScrollPosition()
        }
    }, [isSchedulerConfigured])

    useEffect(() => {
        const schedulerInstance = schedulerPro.current?.instance as JobTimelineScheduler
        if (hasTimeFrameTypeChanged && schedulerInstance) {
            schedulerInstance.infiniteScroll = true
        }
    }, [timeFrameType])

    return (
        <>
            <TopBar.Portal>
                <JobTimelineViewControls />
            </TopBar.Portal>

            {children}
        </>
    )
}

export default memo(JobTimelineViewSchedulerWrapper)
