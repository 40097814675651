import { DateHelper, TimeZoneHelper } from "@bryntum/schedulerpro"
import dayjs from "dayjs"
import { atom, useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { useEffect, useMemo, useRef } from "react"

import useUser from "@hooks/useUser"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import isValidDate from "@utils/isValidDate"

import {
    CollapsibleSchedulerColumnStates,
    JobTimelineViewOrientation,
    JobTimelineViewStyle,
    SchedulerDateRange,
    TimelineViewTimeFrames,
    TimelineViewZoom,
} from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

import { DEFAULT_ZOOM } from "@constants/schedulerZoom"
import {
    TIMELINE_VIEW_PARAM_END_DATE,
    TIMELINE_VIEW_PARAM_START_DATE,
    TIMELINE_VIEW_PARAM_VISIBLE_DATE_END,
    TIMELINE_VIEW_PARAM_VISIBLE_DATE_START,
} from "@constants/searchParams"
import {
    JOBS_LIST_TIMELINE_VIEW_CARD_STYLE,
    JOBS_LIST_TIMELINE_VIEW_DATE_RANGE_KEY,
    JOBS_LIST_TIMELINE_VIEW_ORIENTATION,
    JOBS_LIST_TIMELINE_VIEW_SCHEDULER_ZOOM_LEVELS,
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_CANCELLED_JOBS,
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_FINALIZED_JOBS,
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_WEEKENDS,
    JOBS_LIST_TIMELINE_VIEW_TECHNICIANS_COLUMN_STATUS_KEY,
    JOBS_LIST_TIMELINE_VIEW_TIME_FRAME_TYPE,
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_MESSAGE_STATUS_KEY,
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_STATUS_KEY,
    JOBS_LIST_TIMELINE_VIEW_VISIBLE_DATE_RANGE_KEY,
} from "@constants/storage"

const DEFAULT_DATE_RANGE_SIZE_DAYS = 2

const jobCardStyleAtom = atomWithStorage<JobTimelineViewStyle>(
    JOBS_LIST_TIMELINE_VIEW_CARD_STYLE,
    "regular",
    undefined,
    {
        getOnInit: true,
    },
)
const shouldShowWeekendsAtom = atomWithStorage<boolean>(
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_WEEKENDS,
    true,
    undefined,
    {
        getOnInit: true,
    },
)
const shouldShowCancelledJobsAtom = atomWithStorage<boolean>(
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_CANCELLED_JOBS,
    false,
    undefined,
    {
        getOnInit: true,
    },
)
const shouldShowFinalizedJobsAtom = atomWithStorage<boolean>(
    JOBS_LIST_TIMELINE_VIEW_SHOULD_SHOW_FINALIZED_JOBS,
    false,
    undefined,
    {
        getOnInit: true,
    },
)
const timelineViewOrientationAtom = atomWithStorage<JobTimelineViewOrientation>(
    JOBS_LIST_TIMELINE_VIEW_ORIENTATION,
    "horizontal",
    undefined,
    {
        getOnInit: true,
    },
)
const technicianColumnStatusAtom = atomWithStorage<CollapsibleSchedulerColumnStates>(
    JOBS_LIST_TIMELINE_VIEW_TECHNICIANS_COLUMN_STATUS_KEY,
    "collapsed",
    undefined,
    {
        getOnInit: true,
    },
)
const unscheduledJobsColumnStatusAtom = atomWithStorage<CollapsibleSchedulerColumnStates>(
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_STATUS_KEY,
    "collapsed",
    undefined,
    {
        getOnInit: true,
    },
)
const unscheduledJobsColumnMessageStatusAtom = atomWithStorage<"initial" | "shown" | "hidden">(
    JOBS_LIST_TIMELINE_VIEW_UNSCHEDULED_JOBS_COLUMN_MESSAGE_STATUS_KEY,
    "initial",
    undefined,
    {
        getOnInit: true,
    },
)

const dateRangeOverride = {
    start: extractURLSearchParam(TIMELINE_VIEW_PARAM_START_DATE, true),
    end: extractURLSearchParam(TIMELINE_VIEW_PARAM_END_DATE, true),
}

const initialDateRange = {
    start:
        dateRangeOverride?.start && isValidDate(dateRangeOverride?.start)
            ? new Date(dateRangeOverride.start)
            : new Date(),
    end:
        dateRangeOverride?.end && isValidDate(dateRangeOverride?.end)
            ? new Date(dateRangeOverride.end)
            : DateHelper.add(new Date(), DEFAULT_DATE_RANGE_SIZE_DAYS, "d"),
}

const dateRangeAtom = atomWithStorage<SchedulerDateRange>(
    JOBS_LIST_TIMELINE_VIEW_DATE_RANGE_KEY,
    initialDateRange,
    undefined,
    {
        getOnInit: true,
    },
)

const visibleDateRangeOverride = {
    start: extractURLSearchParam(TIMELINE_VIEW_PARAM_VISIBLE_DATE_START, true) as string,
    end: extractURLSearchParam(TIMELINE_VIEW_PARAM_VISIBLE_DATE_END, true) as string,
}

const isValidVisibleDateRange =
    isValidDate(visibleDateRangeOverride?.start) && isValidDate(visibleDateRangeOverride?.end)

const initialVisibleDateRange = isValidVisibleDateRange
    ? {
          start: new Date(visibleDateRangeOverride?.start),
          end: new Date(visibleDateRangeOverride.end),
      }
    : {
          start: new Date(),
          end: DateHelper.add(new Date(), 2, "d"),
      }

const visibleDateRangeAtom = atomWithStorage<SchedulerDateRange>(
    JOBS_LIST_TIMELINE_VIEW_VISIBLE_DATE_RANGE_KEY,
    initialVisibleDateRange,
    undefined,
    {
        getOnInit: true,
    },
)

const schedulerZoomAtom = atomWithStorage<TimelineViewZoom>(
    JOBS_LIST_TIMELINE_VIEW_SCHEDULER_ZOOM_LEVELS,
    DEFAULT_ZOOM,
    undefined,
    {
        getOnInit: true,
    },
)

const timeFrameTypeAtom = atomWithStorage<TimelineViewTimeFrames>(
    JOBS_LIST_TIMELINE_VIEW_TIME_FRAME_TYPE,
    "day",
    undefined,
    {
        getOnInit: true,
    },
)

const totalOfUnscheduledJobsAtom = atom<number>(0)
const limitOfItemsPerGridFetchAtom = atom<number>(0)

const isSchedulerConfiguredAtom = atom<boolean>(false)

const allowShowMouseMoveFeedbackAtom = atom<boolean>(true)

export default function useJobTimelineViewStates() {
    const { user } = useUser()

    const preferredTimezone = user?.service_company?.preferred_timezone ?? "UTC"

    const [jobCardStyle, setJobCardStyle] = useAtom(jobCardStyleAtom)

    const [shouldShowCancelledJobs, setShouldShowCancelledJobs] = useAtom(shouldShowCancelledJobsAtom)
    const [shouldShowWeekends, setShouldShowWeekends] = useAtom(shouldShowWeekendsAtom)
    const [shouldShowFinalizedJobs, setShouldShowFinalizedJobs] = useAtom(shouldShowFinalizedJobsAtom)
    const [timelineViewOrientation, setTimelineViewOrientation] = useAtom(timelineViewOrientationAtom)
    const [techniciansColumnStatus, setTechniciansColumnStatus] = useAtom(technicianColumnStatusAtom)
    const [unscheduledJobsColumnStatus, setUnscheduledColumnStatus] = useAtom(unscheduledJobsColumnStatusAtom)
    const [unscheduledJobsColumnMessageStatus, setUnscheduledColumnMessageStatus] = useAtom(
        unscheduledJobsColumnMessageStatusAtom,
    )
    const [totalOfUnscheduledJobs, setTotalOfUnscheduledJobs] = useAtom(totalOfUnscheduledJobsAtom)
    const [limitOfItemsPerGridFetch, setLimitOfItemsPerGridFetch] = useAtom(limitOfItemsPerGridFetchAtom)

    const [dateRange, setDateRange] = useAtom(dateRangeAtom)
    const [visibleDateRange, setVisibleDateRange] = useAtom(visibleDateRangeAtom)

    const [isSchedulerConfigured, setIsSchedulerConfigured] = useAtom(isSchedulerConfiguredAtom)
    const [allowShowMouseMoveFeedback, setAllowShowMouseMoveFeedback] = useAtom(allowShowMouseMoveFeedbackAtom)
    const [schedulerZoom, setSchedulerZoom] = useAtom(schedulerZoomAtom)
    const [timeFrameType, setTimeFrameType] = useAtom(timeFrameTypeAtom)

    const setJobCardStyleToRegular = () => {
        void setJobCardStyle("regular")
    }
    const setJobCardStyleToCompact = () => {
        void setJobCardStyle("compact")
    }

    const toggleShowWeekends = () => {
        void setShouldShowWeekends((prev) => !prev)
    }

    const toggleCancelledJobs = () => {
        void setShouldShowCancelledJobs((prev) => !prev)
    }

    const toggleFinalizedJobs = () => {
        void setShouldShowFinalizedJobs((prev) => !prev)
    }

    const setTimelineViewOrientationToHorizontal = () => {
        void setTimelineViewOrientation("horizontal")
    }
    const setTimelineViewOrientationToVertical = () => {
        void setTimelineViewOrientation("vertical")
    }

    const showUnscheduledJobsColumnMessage = () => {
        void setUnscheduledColumnMessageStatus("shown")
    }

    const hideUnscheduledJobsColumnMessage = () => {
        void setUnscheduledColumnMessageStatus("hidden")
    }

    const collapseUnscheduledJobsColumn = () => {
        void setUnscheduledColumnStatus("collapsed")
    }

    const expandUnscheduledJobsColumn = () => {
        void setUnscheduledColumnStatus("expanded")
    }

    useEffect(() => {
        const root = document.documentElement
        const HOUR_WIDTH_MULTIPLIER = timelineViewOrientation == "horizontal" ? 4 : 5
        const zoom = schedulerZoom[timeFrameType] + 20

        if (timeFrameType === "day") {
            root.style.setProperty("--job-timeline-hour-width", `${zoom * HOUR_WIDTH_MULTIPLIER}px`)
        }
    }, [schedulerZoom, timelineViewOrientation])

    const todayInUserTimezone = useMemo(
        () => TimeZoneHelper.toTimeZone(new Date(), preferredTimezone),
        [preferredTimezone],
    )

    const visibleDateRangeInterval = useMemo(
        () => ({
            start: new Date(isValidDate(visibleDateRange.start) ? visibleDateRange.start : todayInUserTimezone),
            end: new Date(isValidDate(visibleDateRange.end) ? visibleDateRange.end : todayInUserTimezone),
        }),
        [visibleDateRange, todayInUserTimezone],
    )

    const displayedDateRange = useMemo(
        () => ({
            start: isValidDate(dateRange.start) ? new Date(dateRange.start) : todayInUserTimezone,
            end: isValidDate(dateRange.end) ? new Date(dateRange.end) : todayInUserTimezone,
        }),
        [dateRange, todayInUserTimezone],
    )

    const isMultipleVisibleDays = useMemo(
        () => !dayjs(visibleDateRangeInterval.start).isSame(visibleDateRangeInterval.end, "day"),
        [visibleDateRangeInterval],
    )

    const previousDateRange = useRef(displayedDateRange)
    const previousVisibleRange = useRef(visibleDateRangeInterval)
    const previousTimeFrameType = useRef(timeFrameType)

    const hasRangeDateChanged = useMemo(() => {
        const isNewStartDate =
            DateHelper.diff(displayedDateRange.start, previousDateRange.current.start, "day", false) > 0
        const isNewEndDate = DateHelper.diff(displayedDateRange.end, previousDateRange.current.end, "day", false) < 0

        return isNewStartDate || isNewEndDate
    }, [displayedDateRange, previousDateRange])

    const hasVisibleDateRangeChanged = useMemo(() => {
        const isNewVisibleStartDate =
            DateHelper.diff(
                dayjs(visibleDateRangeInterval.start).startOf("day").toDate(),
                dayjs(previousVisibleRange.current.start).startOf("day").toDate(),
                "day",
                false,
            ) != 0
        const isNewVisibleEndDate =
            DateHelper.diff(
                dayjs(visibleDateRangeInterval.end).startOf("day").toDate(),
                dayjs(previousVisibleRange.current.end).startOf("day").toDate(),
                "day",
                false,
            ) != 0

        return isNewVisibleStartDate || isNewVisibleEndDate
    }, [visibleDateRangeInterval, previousVisibleRange])

    const hasTimeFrameTypeChanged = useMemo(() => {
        return timeFrameType !== previousTimeFrameType.current
    }, [timeFrameType, previousTimeFrameType])

    if (hasRangeDateChanged) {
        previousDateRange.current = displayedDateRange
    }

    if (hasVisibleDateRangeChanged) {
        previousVisibleRange.current = visibleDateRangeInterval
    }

    if (hasTimeFrameTypeChanged) {
        previousTimeFrameType.current = timeFrameType
    }

    return {
        dateRange: displayedDateRange,
        visibleDateRange: visibleDateRangeInterval,
        isMultipleVisibleDays,
        todayInUserTimezone,
        jobCardStyle,
        shouldShowCancelledJobs,
        shouldShowFinalizedJobs,
        timelineViewOrientation,
        techniciansColumnStatus,
        unscheduledJobsColumnStatus,
        unscheduledJobsColumnMessageStatus,
        totalOfUnscheduledJobs,
        limitOfItemsPerGridFetch,
        isSchedulerConfigured,
        setDateRange,
        setLimitOfItemsPerGridFetch,
        setTotalOfUnscheduledJobs,
        toggleCancelledJobs,
        toggleFinalizedJobs,
        setJobCardStyleToCompact,
        setJobCardStyleToRegular,
        setTechniciansColumnStatus,
        expandUnscheduledJobsColumn,
        collapseUnscheduledJobsColumn,
        showUnscheduledJobsColumnMessage,
        hideUnscheduledJobsColumnMessage,
        setTimelineViewOrientationToHorizontal,
        setTimelineViewOrientationToVertical,
        setIsSchedulerConfigured,
        allowShowMouseMoveFeedback,
        setAllowShowMouseMoveFeedback,
        schedulerZoom,
        setSchedulerZoom,
        shouldShowWeekends,
        setShouldShowWeekends,
        toggleShowWeekends,
        setVisibleDateRange,
        timeFrameType,
        setTimeFrameType,
        hasRangeDateChanged,
        previousDateRange,
        hasVisibleDateRangeChanged,
        hasTimeFrameTypeChanged,
    }
}
