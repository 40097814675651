import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { OBJECTS_CURRENT_VIEW_TYPE } from "@constants/storage"

import useLocalStorage from "./useLocalStorage"

export default function useCurrentViewType() {
    const [currentViewType, setCurrentViewType] = useLocalStorage<ObjectsViewType | null>(
        OBJECTS_CURRENT_VIEW_TYPE,
        null,
    )

    return {
        currentViewType,
        setCurrentViewType,
    }
}
