import { Tooltip } from "@atoms/index";
import { APIDeckTaxStrategies } from "@constants/choices";
import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import TagField from "@legacy/core/fields/TagField";
import TagSearchInput from "@legacy/core/inputs/TagSearchInput";
import { AccessCheck } from "@particles/index";
import calculateProfit from "@utils/getObjectsProfit";
import { Component, Fragment } from "react";
import ClientSearchOrCreateSelect, { clientToClientOption } from "../../clients/inputs/ClientSearchOrCreateSelect";
import ServiceLocationSearchOrCreateSelect, { serviceLocationToServiceLocationOption } from "../../clients/inputs/ServiceLocationSearchOrCreateSelect";
import { renderClientString, renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import LineItemListSelectFieldGroup from "../../core/components/LineItemListSelectFieldGroup";
import AddressAutocompleteFieldGroup from "../../core/fields/AddressAutocompleteFieldGroup";
import AttachmentUploadField from "../../core/fields/AttachmentUploadField";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import BasicSelectField from "../../core/fields/BasicSelectField";
import BooleanField from "../../core/fields/BooleanField";
import CharField from "../../core/fields/CharField";
import DateField from "../../core/fields/DateField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import TextField from "../../core/fields/TextField";
import { valueIsDefined } from "../../core/utils/utils";
import EstimateObjectLink from "../../estimates/components/EstimateObjectLink";
import { calculateEstimateAmounts } from "../../estimates/utils/utils";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import PriceBookServiceSearchOrCreateSelect, { priceBookServiceToPriceBookServiceOption } from "../../pricebook/inputs/PriceBookServiceSearchOrCreateSelect";
import PriceBookTaxSearchOrCreateSelect, { getPriceBookTaxObjectLabel, priceBookTaxToPriceBookTaxOption } from "../../pricebook/inputs/PriceBookTaxSearchOrCreateSelect";
import InvoiceFeedAccordion from "../components/InvoiceFeedAccordion";
import { calculateInvoiceAmounts } from "../utils/utils";


class InvoiceForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            clientSearchInput: "",
            serviceLocationSearchInput: "",
            serviceSearchInput: "",
            taxSearchInput: "",
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    invoiceIsSeen = (invoice) => {
        return valueIsDefined(invoice.id) && valueIsDefined(invoice.is_draft) && invoice.is_draft === false && invoice.last_viewed !== null
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            invoice,
            job,
            estimate,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            updateServiceLocationSelection,
            updatePriceBookServiceSelection,
            selectedClient,
            selectedServiceLocation,
            selectedPriceBookService,
            selectedPriceBookTax,
            formatCurrencyValue,
            showCustomInvoiceIDField,
            useBillingAddress,
            foldDataComplete,
            showQuickBooksItemSelect=false,
            showTaxCreateButton=false,
            useTaxes=false,
            hideTaxRates=false,
            defaultSelectedNet,
            netChoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_INVOICE") {
                    const showDraftButtonAtTheTop = window.CURRENT_USER?.permissions.invoices_edit_permission !== PERMISSION_LEVEL.FULL
                        && window.CURRENT_USER?.permissions.invoices_create_permission === PERMISSION_LEVEL.RESTRICTED

                    return (
                        <ButtonGroup>
                            {showDraftButtonAtTheTop && (
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Save Draft" handler={event => requestAction("INVOICE_CREATE_DRAFT")} />
                                </ButtonGroupRow>
                            )}
                            <AccessCheck permissions={{
                                invoices_create_permission: PERMISSION_LEVEL.FULL
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton
                                        type="primary"
                                        text={window.CURRENT_USER?.permissions.invoices_edit_permission === PERMISSION_LEVEL.FULL ? "Create and Send" : "Create"}
                                        handler={event => requestAction("INVOICE_CREATE")}
                                    />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <AccessCheck permissions={{
                                    invoices_view_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    <UniversalButton type="secondary" text="Preview Invoice" handler={event => requestAction("INVOICE_PREVIEW")} />
                                </AccessCheck>
                                {
                                    !showDraftButtonAtTheTop && (
                                        <AccessCheck permissions={{
                                            invoices_create_permission: PERMISSION_LEVEL.RESTRICTED
                                        }}>
                                            <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("INVOICE_CREATE_DRAFT")} />
                                        </AccessCheck>
                                    )
                                }
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_INVOICE") {
                    if (invoice.is_draft) {
                        return (
                            <ButtonGroup>
                                <AccessCheck permissions={{
                                    invoices_create_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton
                                            type="primary"
                                            text={window.CURRENT_USER?.permissions.invoices_edit_permission === PERMISSION_LEVEL.FULL ? "Create and Send" : "Create"}
                                            handler={event => requestAction("INVOICE_CREATE")}
                                        />
                                    </ButtonGroupRow>
                                </AccessCheck>
                                <ButtonGroupRow>
                                    <AccessCheck permissions={{
                                        invoices_view_permission: PERMISSION_LEVEL.FULL
                                    }}>
                                        <UniversalButton type="secondary" text="Preview Invoice" handler={event => requestAction("INVOICE_PREVIEW")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Save Draft" handler={event => requestAction("INVOICE_CREATE_DRAFT")} />
                                    <AccessCheck permissions={{
                                        invoices_delete_permission: PERMISSION_LEVEL.RESTRICTED
                                    }}>
                                        <UniversalButton type="danger" text="Delete Draft" handler={event => requestAction("INVOICE_DELETE_DRAFT")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <AccessCheck permissions={{
                                    invoices_edit_permission: PERMISSION_LEVEL.FULL
                                }}>
                                    {
                                        this.invoiceIsSeen(invoice) && (
                                            <ButtonGroupRow>
                                                <UniversalButton type="primary" text="Save and Resend" handler={event => requestAction("INVOICE_UPDATE_AND_RESEND")} />
                                            </ButtonGroupRow>
                                        )
                                    }
                                </AccessCheck>
                                <ButtonGroupRow>
                                    <AccessCheck permissions={{
                                        invoices_edit_permission: PERMISSION_LEVEL.FULL
                                    }}>
                                        <UniversalButton type={this.invoiceIsSeen(invoice) ? "secondary" : "primary"} text="Save" handler={event => requestAction("INVOICE_UPDATE")} />
                                    </AccessCheck>
                                    <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("INVOICE_CANCEL_EDITS")} />
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
            }
        }
    }

    renderDownPaymentBanner = (estimate) => {
        const { formatCurrencyValue } = this.props
        const { downPaymentAmountDue, downPaymentAmountPaid } = calculateEstimateAmounts(estimate)

        if (downPaymentAmountDue === 0) {
            return <Banner type="success" text="The requested down payment for this estimate has been paid in full." extraMargin={true} />
        }
        else if (downPaymentAmountPaid !== 0) {
            return <Banner type="warning" text={`The requested down payment for this estimate has only been partially paid (${formatCurrencyValue(downPaymentAmountPaid)} of ${formatCurrencyValue(estimate.down_payment_amount)})`} extraMargin={true} />
        }
        else {
            return <Banner type="warning" text="The requested down payment for this estimate has not yet been paid." extraMargin={true} />
        }
    }

    render() {
        const {
            mode,
            submitting,
            invoice,
            job,
            estimate,
            errors,
            onFormDataChange,
            requestAction,
            switchToSecondaryForm,
            updateClientSelection,
            updateServiceLocationSelection,
            updatePriceBookServiceSelection,
            updatePriceBookTaxSelection,
            selectedClient,
            selectedServiceLocation,
            selectedPriceBookService,
            selectedPriceBookTax,
            formatCurrencyValue,
            showCustomInvoiceIDField,
            useBillingAddress,
            foldDataComplete,
            showQuickBooksItemSelect=false,
            showTaxCreateButton=false,
            useTaxes=false,
            hideTaxRates=false,
            defaultSelectedNet,
            netChoices,
            fileStackAPIKey,
            fileStackPolicy,
            fileStackSignature,
            updateAttachments,
            returnScroll
        } = this.props
        const {subtotal, tax, total, amountPaid, amountDue} = calculateInvoiceAmounts(invoice)
        const { profit, profitBreakdown } = calculateProfit(invoice)

        const allowClientDetailsEdit = Object.keys(job).length === 0 && (invoice.job === null || invoice.job === undefined) && Object.keys(estimate).length === 0 && (invoice.estimate === null || invoice.estimate === undefined)

        return (
            <Fragment>
                <div id="invoice_form_react_wrapper">
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Invoice Create/Update">
                            <div className="data-panel__form" aria-label="Invoice Create/Update Form">
                                <p className="data-panel__form__caption">Please provide the following information to create your invoice:</p>
                                <fieldset>
                                    <legend>Client Details</legend>
                                    <SearchCreateSelectField
                                        fieldName="client"
                                        fieldLabel="Client"
                                        fieldValue={selectedClient !== null ? renderClientString(selectedClient) : null}
                                        inputComponent={
                                            <ClientSearchOrCreateSelect
                                                disabled={!allowClientDetailsEdit || window.CURRENT_USER?.permissions.clients_view_permission < PERMISSION_LEVEL.RESTRICTED}
                                                onSelectionChange={selectedOption => updateClientSelection(selectedOption != null ? selectedOption.object : null)}
                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({clientSearchInput: input})}}
                                                defaultSelected={selectedClient !== null ? clientToClientOption(selectedClient) : null}
                                                showCreateButton={window.CURRENT_USER?.permissions.clients_create_permission >= PERMISSION_LEVEL.FULL}
                                                onCreateClick={event => switchToSecondaryForm("ADD_CLIENT", null, {name: this.state.clientSearchInput})}
                                                listEndpointKwargs={[
                                                    ["is_active", true]
                                                ]}
                                            ></ClientSearchOrCreateSelect>
                                        }
                                        showButton={allowClientDetailsEdit && selectedClient !== null  && window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Client</Fragment>}
                                        buttonAction={event => switchToSecondaryForm("EDIT_CLIENT", selectedClient, null)}
                                        disabled={!allowClientDetailsEdit}
                                        errors={errors}
                                    ></SearchCreateSelectField>
                                    {
                                        selectedClient !== null && selectedClient.notes !== "" && (
                                            <BasicDisplayField
                                                fieldName="client_notes"
                                                fieldLabel="Client Notes"
                                                fieldValue={selectedClient.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                    {
                                        selectedClient !== null && (
                                            <SearchCreateSelectField
                                                fieldName="service_location"
                                                fieldLabel="Service Location"
                                                fieldValue={selectedServiceLocation !== null ? renderServiceLocationString(selectedServiceLocation) : null}
                                                inputComponent={
                                                    <ServiceLocationSearchOrCreateSelect
                                                        disabled={!allowClientDetailsEdit || window.CURRENT_USER?.permissions.clients_list_permission < PERMISSION_LEVEL.RESTRICTED}
                                                        onSelectionChange={selectedOption => updateServiceLocationSelection(selectedOption != null ? selectedOption.object : null)}
                                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({serviceLocationSearchInput: input})}}
                                                        defaultSelected={selectedServiceLocation !== null ? serviceLocationToServiceLocationOption(selectedServiceLocation) : null}
                                                        client={selectedClient}
                                                        showCreateButton={window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                                                        onCreateClick={event => switchToSecondaryForm("ADD_SERVICE_LOCATION", null, {physical_address_street: this.state.serviceLocationSearchInput})}
                                                    ></ServiceLocationSearchOrCreateSelect>
                                                }
                                                showButton={allowClientDetailsEdit && selectedServiceLocation !== null && window.CURRENT_USER?.permissions.clients_edit_permission >= PERMISSION_LEVEL.FULL}
                                                buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Service Location</Fragment>}
                                                buttonAction={event => switchToSecondaryForm("EDIT_SERVICE_LOCATION", selectedServiceLocation, null)}
                                                disabled={!allowClientDetailsEdit}
                                                errors={errors}
                                            ></SearchCreateSelectField>
                                        )
                                    }
                                    {
                                        selectedClient !== null && selectedServiceLocation !== null && selectedServiceLocation.notes !== "" && (
                                            <BasicDisplayField
                                                fieldName="service_location_notes"
                                                fieldLabel="Service Location Notes"
                                                fieldValue={selectedServiceLocation.notes}
                                                indented={true}
                                            ></BasicDisplayField>
                                        )
                                    }
                                </fieldset>
                                {
                                    selectedClient !== null && selectedServiceLocation !== null && (
                                        <fieldset>
                                            <legend>Basic Details</legend>
                                            {
                                                Object.keys(job).length !== 0 && !(invoice.job === null || invoice.job === undefined) && (
                                                    <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                                                        <LinkedObjectsDisplayField
                                                            fieldName="job"
                                                            fieldLabel="Created From Job"
                                                            objectComponentList={[
                                                                <JobObjectLink
                                                                    key="parent_job_0"
                                                                    job={job}
                                                                    destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)}
                                                                    disabled={window.CURRENT_USER?.permissions.jobs_view_permission < PERMISSION_LEVEL.FULL}
                                                                />
                                                            ]}
                                                        ></LinkedObjectsDisplayField>
                                                    </AccessCheck>
                                                )
                                            }
                                            {
                                                Object.keys(job).length !== 0 && !(invoice.job === null || invoice.job === undefined) && job.technician_notes && window.CURRENT_USER?.permissions.jobs_view_permission >= PERMISSION_LEVEL.FULL && (
                                                    <BasicDisplayField
                                                        fieldName="technician_notes"
                                                        fieldLabel="Notes From Technician"
                                                        fieldValue={job.technician_notes}
                                                        indented={true}
                                                    ></BasicDisplayField>
                                                )
                                            }
                                            {
                                                Object.keys(estimate).length !== 0 && !(invoice.estimate === null || invoice.estimate === undefined) && (
                                                    <Fragment>
                                                        <AccessCheck entitlements={["entitlement_estimates_enabled"]}>
                                                            <LinkedObjectsDisplayField
                                                                fieldName="estimate"
                                                                fieldLabel="Created From Estimate"
                                                                objectComponentList={[
                                                                    <EstimateObjectLink
                                                                        key="parent_estimate_0"
                                                                        estimate={estimate}
                                                                        destination={DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, estimate.id)}
                                                                        disabled={window.CURRENT_USER?.permissions.estimates_view_permission < PERMISSION_LEVEL.FULL}
                                                                    />
                                                                ]}
                                                                fieldClasses={parseInt(estimate.down_payment_amount) !== 0 ? ["data-panel__form__field", "data-panel__form__field--no-margin"] : ["data-panel__form__field"]}
                                                            ></LinkedObjectsDisplayField>
                                                        </AccessCheck>
                                                        {parseInt(estimate.down_payment_amount) !== 0 && this.renderDownPaymentBanner(estimate)}
                                                    </Fragment>
                                                )
                                            }
                                            {
                                                showCustomInvoiceIDField
                                                ?
                                                <CharField
                                                    fieldName="custom_id"
                                                    fieldLabel="Invoice ID"
                                                    fieldValue={invoice.custom_id || ""}
                                                    fieldOnChange={custom_id => onFormDataChange("custom_id", custom_id || "")}
                                                    maxLength="15"
                                                    errors={errors}
                                                ></CharField>
                                                :
                                                <div id="div_id_custom_id">{errors.custom_id && <Banner type="warning" text={errors.custom_id} extraMargin={true} />}</div>
                                            }
                                            <SearchCreateSelectField
                                                fieldName="service_name"
                                                fieldLabel="Service Type"
                                                fieldValue={selectedPriceBookService ? selectedPriceBookService.description : (invoice.service_name || null)}
                                                inputComponent={
                                                    <PriceBookServiceSearchOrCreateSelect
                                                        disabled={!allowClientDetailsEdit || window.CURRENT_USER?.permissions.pricebook_view_permission < PERMISSION_LEVEL.RESTRICTED}
                                                        onSelectionChange={selectedOption => updatePriceBookServiceSelection(selectedOption != null ? selectedOption.object : null)}
                                                        onInputChange={(input, action) => {action.action === "input-change" && this.setState({serviceSearchInput: input})}}
                                                        defaultSelected={selectedPriceBookService ? priceBookServiceToPriceBookServiceOption(selectedPriceBookService) : (invoice.service_name ? {value: null, label: invoice.service_name} : null)}
                                                        showCreateButton={window.CURRENT_USER?.permissions.pricebook_create_permission >= PERMISSION_LEVEL.FULL}
                                                        onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_SERVICE", null, {description: this.state.serviceSearchInput})}
                                                    ></PriceBookServiceSearchOrCreateSelect>
                                                }
                                                showButton={allowClientDetailsEdit && selectedPriceBookService !== null && window.CURRENT_USER?.permissions.pricebook_edit_permission >= PERMISSION_LEVEL.FULL}
                                                buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Service Type</Fragment>}
                                                buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_SERVICE", selectedPriceBookService, null)}
                                                disabled={!allowClientDetailsEdit}
                                                errors={errors}
                                            ></SearchCreateSelectField>
                                            <TagField
                                                fieldName="labels"
                                                fieldLabel="Labels"
                                                inputComponent={
                                                    <TagSearchInput
                                                        defaultSelectedTags={invoice.labels?.map(label => label.name) ?? []}
                                                        onSelectionChange={labelNames => onFormDataChange("labels", labelNames ? labelNames.map(labelName => ({"name": labelName})) : [])}
                                                        choicesEndpoint={DjangoUrls["invoices:api-invoices-labels-list"](window.MARKETPLACE_ENTITY_SLUG)}
                                                        placeholder="Add Labels..."
                                                        noOptionsMessage="No Labels Found"
                                                        isMulti={true}
                                                    ></TagSearchInput>
                                                }
                                                optional={true}
                                                errors={errors}
                                            ></TagField>
                                            <div className="data-panel__form__field" id="div_id_billing_address" aria-label="Billing Address">
                                                <label htmlFor="id_client" className="data-panel__form__field__label in-form">Billing Address</label>
                                                <div className="data-panel__form__field__input data-panel__form__field__input--with-checkbox">
                                                    <input type="checkbox" className="checkbox-input" name="use_billing_address" id="id_use_billing_address" checked={!useBillingAddress} onChange={event => requestAction("TOGGLE_USE_BILLING_ADDRESS")} />
                                                    <label className="checkbox-label" htmlFor="id_use_billing_address">Same as service location</label>
                                                </div>
                                                {
                                                    useBillingAddress && (
                                                        <AddressAutocompleteFieldGroup
                                                            key={`invoice_billing_address_${selectedClient.id}_${selectedServiceLocation.id}`}
                                                            fieldGroupName="billing_address"
                                                            fieldGroupLabel=""
                                                            fieldPrefix="billing_address"
                                                            fieldValues={{
                                                                "billing_address_recipient": invoice.billing_address_recipient || "",
                                                                "billing_address_street": invoice.billing_address_street || "",
                                                                "billing_address_unit": invoice.billing_address_unit || "",
                                                                "billing_address_city": invoice.billing_address_city || "",
                                                                "billing_address_state": invoice.billing_address_state || "",
                                                                "billing_address_postal": invoice.billing_address_postal || "",
                                                                "billing_address_country": invoice.billing_address_country || "",
                                                            }}
                                                            onFormDataChange={onFormDataChange}
                                                            includeRecipient={true}
                                                            errors={errors}
                                                        ></AddressAutocompleteFieldGroup>
                                                    )
                                                }
                                            </div>
                                            <DateField
                                                fieldName="date_issued"
                                                fieldLabel="Issue Date"
                                                fieldValue={invoice.date_issued || ""}
                                                fieldOnChange={date_issued => onFormDataChange("date_issued", date_issued || "")}
                                                errors={errors}
                                            ></DateField>
                                            <BasicSelectField
                                                key={`terms_${defaultSelectedNet}`}
                                                fieldName="terms"
                                                fieldLabel="Terms"
                                                fieldValue={valueIsDefined(defaultSelectedNet) ? defaultSelectedNet : ""}
                                                fieldOnChange={net => onFormDataChange("net", valueIsDefined(net) ? net : null)}
                                                choices={netChoices}
                                                errors={errors}
                                            ></BasicSelectField>
                                            <DateField
                                                key={`date_due_${invoice.date_due}`}
                                                fieldName="date_due"
                                                fieldLabel="Due Date"
                                                fieldValue={invoice.date_due || ""}
                                                fieldOnChange={date_due => onFormDataChange("date_due", date_due || "")}
                                                errors={errors}
                                            ></DateField>
                                            {
                                                window.ACCEPT_ONLINE_PAYMENTS && (
                                                    <BooleanField
                                                        fieldName="accept_online_payments"
                                                        fieldLabel="Payment Methods"
                                                        fieldValue={invoice.accept_online_payments}
                                                        fieldOnChange={accept_online_payments => onFormDataChange("accept_online_payments", accept_online_payments)}
                                                        inputLabel="Accept Online Card Payments"
                                                        errors={errors}
                                                    ></BooleanField>
                                                )
                                            }
                                        </fieldset>
                                    )
                                }
                                {
                                    selectedClient !== null && selectedServiceLocation !== null && foldDataComplete === true && (
                                        <fieldset>
                                            <legend>Charge Details</legend>
                                            <br />
                                            <AccessCheck permissions={{
                                                pricebook_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                            }}>
                                                <AccessCheck permissions={{
                                                    pricebook_view_permission: PERMISSION_LEVEL.FULL,
                                                }}>
                                                    <LineItemListSelectFieldGroup
                                                        object={invoice}
                                                        formatCurrencyValue={formatCurrencyValue}
                                                        showQuickBooksItemSelect={showQuickBooksItemSelect}
                                                        useTaxes={useTaxes}
                                                        switchToSecondaryForm={switchToSecondaryForm}
                                                        errors={errors}
                                                        nonFieldErrorMessage={errors.line_items || "There's an issue with one or more of the items below. Try coming back to this page and trying again in a bit."}
                                                        isInvoiceLineItem={true}
                                                    ></LineItemListSelectFieldGroup>
                                                </AccessCheck>
                                            </AccessCheck>
                                            {
                                                useTaxes && !hideTaxRates ? (
                                                    <SearchCreateSelectField
                                                        key={invoice.tax_name}
                                                        fieldName="tax_name"
                                                        fieldLabel="Tax Rate"
                                                        fieldValue={selectedPriceBookTax ? selectedPriceBookTax.description : (invoice.tax_name || null)}
                                                        inputComponent={
                                                            <PriceBookTaxSearchOrCreateSelect
                                                                disabled={window.CURRENT_USER?.permissions.pricebook_list_permission < PERMISSION_LEVEL.RESTRICTED}
                                                                onSelectionChange={selectedOption => updatePriceBookTaxSelection(selectedOption != null ? selectedOption.object : null)}
                                                                onInputChange={(input, action) => {action.action === "input-change" && this.setState({taxSearchInput: input})}}
                                                                defaultSelected={
                                                                    selectedPriceBookTax
                                                                    ?
                                                                    priceBookTaxToPriceBookTaxOption(selectedPriceBookTax)
                                                                    :
                                                                    (invoice.tax_name ? { value: null, label: getPriceBookTaxObjectLabel({ description: invoice.tax_name, default_price: invoice.tax_percent})} : null)
                                                                }
                                                                showCreateButton={showTaxCreateButton && window.CURRENT_USER?.permissions.pricebook_create_permission >= PERMISSION_LEVEL.FULL}
                                                                limitToQuickBooksSyncedItems={!showTaxCreateButton}
                                                                onCreateClick={event => switchToSecondaryForm("ADD_PRICEBOOKITEM_TAX", null, {description: this.state.taxSearchInput})}
                                                            />
                                                        }
                                                        showButton={selectedPriceBookTax !== null && window.CURRENT_USER?.permissions.pricebook_create_permission >= PERMISSION_LEVEL.FULL}
                                                        buttonLabel={<Fragment><i className="fa-sharp fa-light fa-pen-to-square" aria-hidden="true"></i>Edit Tax Rate</Fragment>}
                                                        buttonAction={event => switchToSecondaryForm("EDIT_PRICEBOOKITEM_TAX", selectedPriceBookTax, null)}
                                                        errors={errors}
                                                    />
                                                )
                                                :
                                                (
                                                    window.CURRENT_USER.service_company.apideck_tax_strategy === APIDeckTaxStrategies.automatic
                                                    ?
                                                    <>
                                                        <BasicDisplayField
                                                            fieldName="tax_name"
                                                            fieldLabel="Tax Rate"
                                                            fieldValue={invoice.tax_name ? getPriceBookTaxObjectLabel({ description: invoice.tax_name, default_price: invoice.tax_percent}) : ""}
                                                        />
                                                        <Banner type="info" text="The Tax Rate will be automatically calculated once created and synced to your accounting integration. If a 0% rate comes back, make sure tax is properly configured for the service location in your accounting integration and then re-save this invoice." extraMargin={true} />
                                                    </>
                                                    :
                                                    <></>
                                                )
                                            }
                                            <TextField
                                                fieldName="comments"
                                                fieldLabel="Comments"
                                                fieldValue={invoice.comments || ""}
                                                fieldOnChange={comments => onFormDataChange("comments", comments || "")}
                                                rows={3}
                                                placeholder="Any comments to add to the invoice."
                                                optional={true}
                                                errors={errors}
                                            ></TextField>
                                            {
                                                window.FILE_UPLOADS_ENABLED && (
                                                    <AttachmentUploadField
                                                        fieldName="attachments"
                                                        fieldLabel="Attachments"
                                                        attachments={invoice.attachments}
                                                        fileStackAPIKey={fileStackAPIKey}
                                                        fileStackPolicy={fileStackPolicy}
                                                        fileStackSignature={fileStackSignature}
                                                        onUploadDone={(response) => updateAttachments(
                                                            response.filesUploaded.map(file => ({
                                                                "upload_filename": file.filename,
                                                                "upload_id": file.uploadId,
                                                                "upload_handle": file.handle,
                                                                "upload_url": file.url,
                                                                "upload_mimetype": file.mimetype,
                                                                "upload_size": file.size,
                                                                "upload_source": file.source
                                                            }))
                                                        )}
                                                        optional={true}
                                                        optionalText={"if any"}
                                                        errors={errors}
                                                    />
                                                )
                                            }
                                        </fieldset>
                                    )
                                }
                            </div>
                            {
                                selectedClient !== null && selectedServiceLocation !== null && foldDataComplete === true && (
                                    <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                        <hr aria-hidden="true" />
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--subtotal" aria-label="Subtotal">
                                            <div className="data-panel__data-summary__data__label"><span>Subtotal</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyValue(subtotal)}</div>
                                        </div>
                                        {
                                            useTaxes && (
                                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--tax" aria-label="Tax">
                                                    {
                                                        hideTaxRates
                                                        ?
                                                        <div className="data-panel__data-summary__data__label"><span title={invoice.tax_breakdown?.map(component => `[${component.rate}%] ${component.name}`).join("\n")}>Tax ({invoice.tax_percent ? `${invoice.tax_percent}%` : "none"})</span></div>
                                                        :
                                                        <div className="data-panel__data-summary__data__label"><span>Tax ({selectedPriceBookTax ? `${selectedPriceBookTax.default_price}%` : "none"})</span></div>
                                                    }
                                                    <div className="data-panel__data-summary__data__value">{formatCurrencyValue(tax)}</div>
                                                </div>
                                            )
                                        }
                                        <hr aria-hidden="true" />
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--total" aria-label="Total">
                                            <div className="data-panel__data-summary__data__label"><span>Total</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyValue(total)}</div>
                                        </div>
                                                    <Tooltip
                                                        type="message"
                                                        title="Profit Breakdown:"
                                                        message={<>
                                                            <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                                Services:
                                                                <span style={{paddingLeft:"1em"}}>{formatCurrencyValue(profitBreakdown.service)}</span>
                                                            </span>
                                                            <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                                Parts:
                                                                <span style={{paddingLeft:"1em"}}>{formatCurrencyValue(profitBreakdown.part)}</span>
                                                            </span>
                                                            <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                                Misc.:
                                                                <span style={{paddingLeft:"1em"}}>{formatCurrencyValue(profitBreakdown.other)}</span>
                                                            </span>
                                                            <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                                Discounts:
                                                                <span style={{paddingLeft:"1em"}}>{formatCurrencyValue(profitBreakdown.discount)}</span>
                                                            </span>
                                                        </>}
                                                    >
                                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--profit" aria-label="Profit">
                                                            <div className="data-panel__data-summary__data__label"><span className={profit<0 ? "text-alerting" : ""}>Profit</span></div>
                                                            <div className="data-panel__data-summary__data__value"><span className={profit<0 ? "text-alerting" : ""}>{formatCurrencyValue(profit)}</span></div>
                                                        </div>
                                                    </Tooltip>
                                        {
                                            Object.keys(job).length !== 0 && !(invoice.job === null || invoice.job === undefined) && job.estimate !== null && calculateEstimateAmounts(job.estimate).downPaymentAmountPaid > 0 && (
                                                <Banner type="info" text={<span>A down payment of {formatCurrencyValue(calculateEstimateAmounts(job.estimate).downPaymentAmountPaid)} was made on an associated estimate and will be applied to this invoice.</span>} />
                                            )
                                        }
                                        {
                                            Object.keys(estimate).length !== 0 && !(invoice.estimate === null || invoice.estimate === undefined) && estimate !== null && calculateEstimateAmounts(estimate).downPaymentAmountPaid > 0 && (
                                                <Banner type="info" text={<span>A down payment of {formatCurrencyValue(calculateEstimateAmounts(estimate).downPaymentAmountPaid)} was made on an associated estimate and will be applied to this invoice.</span>} />
                                            )
                                        }
                                        {
                                            this.invoiceIsSeen(invoice) && (
                                                <Banner type="warning" text="The latest version of this invoice has been seen. We recommend resending it to your client with a message summarizing the revisions you've made." />
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                selectedClient !== null && selectedServiceLocation !== null && foldDataComplete === true && this.renderButtons()
                            }
                        </div>
                    </div>
                </div>
                <AccessCheck permissions={{
                    invoices_view_permission: PERMISSION_LEVEL.FULL
                }}>
                    {
                        valueIsDefined(invoice.id) && (
                            <div className="accordion-wrapper">
                                <InvoiceFeedAccordion invoiceID={invoice.id} />
                            </div>
                        )
                    }
                </AccessCheck>
            </Fragment>
        )
    }

}

export default InvoiceForm;
