import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { faSignalBars, faSignalBarsFair, faSignalBarsGood } from "@fortawesome/sharp-duotone-solid-svg-icons"

export function getPriorityIcon(priority: number | null): IconDefinition {
    if (priority === 1) {
        return icon({ name: "square-exclamation", style: "solid", family: "sharp" })
    } else if (priority === 2) {
        return faSignalBars as IconDefinition
    } else if (priority === 3) {
        return faSignalBarsGood as IconDefinition
    } else if (priority === 4) {
        return faSignalBarsFair as IconDefinition
    } else {
        return icon({ name: "minus", style: "solid", family: "sharp" })
    }
}

export function getPriorityLabel(priority: number | null): string {
    if (priority === 1) {
        return "Urgent"
    } else if (priority === 2) {
        return "High"
    } else if (priority === 3) {
        return "Medium"
    } else if (priority === 4) {
        return "Low"
    } else {
        return "No Priority"
    }
}
