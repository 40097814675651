import { Tooltip } from "@atoms/index";
import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import Spinner from "@legacy/core/components/Spinner";
import EquipmentDetailsAccordion from "@legacy/equipment/components/EquipmentDetailsAccordion";
import { AccessCheck } from "@particles/index";
import calculateProfit from "@utils/getObjectsProfit";
import { Component, Fragment } from "react";
import { renderClientString, renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import Banner from "../../core/components/Banner";
import EmailDisplay from "../../core/components/EmailDisplay";
import LineItemListDisplayGroup from "../../core/components/LineItemListDisplayGroup";
import PaymentListDisplay from "../../core/components/PaymentListDisplay";
import PaymentListSelect from "../../core/components/PaymentListSelect";
import PhoneNumberDisplay from "../../core/components/PhoneNumberDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import { convertToKebabCase, currencyFormatterForServiceCompany, formatCurrencyForServiceCompany, formatLocalTime, renderInvoiceEstimateDate } from "../../core/utils/utils";
import InvoiceObjectLink from "../../invoices/components/InvoiceObjectLink";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import { calculateEstimateAmounts, getEstimateStatusBarLabel } from "../utils/utils";
import EstimateFeedAccordion from "./EstimateFeedAccordion";


class EstimateDetailsCard extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderResumeDraftButton = (childObjects, buttonType, endpointName, buttonText) => {
        return childObjects.filter(object => object.is_draft).map(object => (
            <ButtonGroupRow>
                <UniversalButton type={buttonType} text={buttonText} handler={event => location.assign(DjangoUrls[endpointName](window.MARKETPLACE_ENTITY_SLUG, object.id))} />
            </ButtonGroupRow>
        )).shift()
    }

    renderButtons = () => {
        const {estimate, childJobs, childInvoices, requestAction, switchToSecondaryForm, submitting, errors} = this.props
        const {downPaymentAmountDue} = calculateEstimateAmounts(estimate)
        const stripeChargeExists = estimate.payments.some(payment => payment.is_online_payment === true)

        const hasJobsEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_jobs_enabled;

        if (submitting) {
            return (
                <div className="data-panel__action-feedback">
                    <Spinner centered={true} />
                </div>
            )
        }
        else {
            if (window.USING_PUBLIC_URL === true) {
                if (estimate.state_label === "Sent") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Approve Estimate" handler={event => requestAction("ESTIMATE_APPROVE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="danger" text="Decline Estimate" handler={event => requestAction("ESTIMATE_DECLINE")} />
                            </ButtonGroupRow>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            {
                                (estimate.state_label === "Approved" || estimate.state_label === "Won") && estimate.service_company.accept_online_payments && estimate.service_company.online_payments_configured && estimate.accept_online_payments && downPaymentAmountDue.toFixed(2) > 0.50 && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="primary" text="Pay Now" handler={event => requestAction("ESTIMATE_PAY_ONLINE")} />
                                    </ButtonGroupRow>
                                )
                            }
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
            else {
                if (estimate.state_label === "Pending") {
                    if (estimate.is_draft) {
                        return (
                            <ButtonGroup>
                                <AccessCheck permissions={{
                                    estimates_create_permission: PERMISSION_LEVEL.FULL,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="primary" text="Create and Send" handler={event => requestAction("ESTIMATE_CREATE")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                                    <AccessCheck permissions={{
                                        estimates_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <UniversalButton type="secondary" text="Edit Draft" handler={event => requestAction("ESTIMATE_EDIT")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <AccessCheck permissions={{
                                        estimates_edit_permission: PERMISSION_LEVEL.FULL,
                                    }}>
                                        <UniversalButton type="primary" text="Send Estimate" handler={event => requestAction("ESTIMATE_SEND")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <AccessCheck permissions={{
                                        estimates_edit_permission: PERMISSION_LEVEL.FULL,
                                    }}>
                                        <UniversalButton type="secondary" text="Revise Estimate" handler={event => requestAction("ESTIMATE_EDIT")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                                    <AccessCheck permissions={{
                                        estimates_delete_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        {!stripeChargeExists && <UniversalButton type="danger" text="Cancel Estimate" handler={event => requestAction("ESTIMATE_CANCEL")} />}
                                    </AccessCheck>
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                }
                else if (estimate.state_label === "Sent") {
                    return (
                        <ButtonGroup>
                            <AccessCheck permissions={{
                                estimates_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="primary" text="Mark as Approved" handler={event => requestAction("ESTIMATE_APPROVE")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <AccessCheck permissions={{
                                estimates_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="danger" text="Mark as Declined" handler={event => requestAction("ESTIMATE_DECLINE")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <AccessCheck permissions={{
                                estimates_edit_permission: PERMISSION_LEVEL.FULL,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Revise Estimate" handler={event => requestAction("ESTIMATE_EDIT")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <AccessCheck permissions={{
                                    estimates_edit_permission: PERMISSION_LEVEL.FULL,
                                }}>
                                    <UniversalButton type="secondary" text="Resend Estimate" handler={event => requestAction("ESTIMATE_SEND")} />
                                </AccessCheck>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (estimate.state_label === "Approved") {
                    return (
                        <ButtonGroup>
                            {
                                downPaymentAmountDue !== 0 && (
                                    <AccessCheck permissions={{
                                        estimates_edit_permission: PERMISSION_LEVEL.FULL,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="primary" text="Add Payment" handler={event => requestAction("ESTIMATE_ADD_PAYMENT")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                            {
                                childJobs.length === 0 && childInvoices.length === 0 && (
                                    <Fragment>
                                        <AccessCheck entitlements={["entitlement_jobs_enabled"]} permissions={{
                                            jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type={downPaymentAmountDue !== 0 ? "secondary" : "primary"} text="Convert to Job" handler={event => requestAction("ESTIMATE_CONVERT_JOB")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                        <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={{
                                            invoices_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type={downPaymentAmountDue === 0 && window.CURRENT_USER?.permissions.jobs_create_permission < PERMISSION_LEVEL.RESTRICTED ? "primary" : "secondary"} text="Convert to Invoice" handler={event => requestAction("ESTIMATE_CONVERT_INVOICE")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                    </Fragment>
                                )
                            }
                            {
                                (childJobs.length !== 0 || childInvoices.length !== 0) && (
                                    childJobs.length !== 0
                                    ?
                                    <AccessCheck entitlements={["entitlement_jobs_enabled"]} permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        {this.renderResumeDraftButton(childJobs, downPaymentAmountDue !== 0 ? "secondary" : "primary", "jobs:jobs-update", "Resume Draft Job")}
                                    </AccessCheck>
                                    :
                                    <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={{
                                        invoices_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        {this.renderResumeDraftButton(childInvoices, downPaymentAmountDue !== 0 ? "secondary" : "primary", "invoices:invoices-update", "Resume Draft Invoice")}
                                    </AccessCheck>
                                )
                            }
                            <AccessCheck permissions={{
                                estimates_edit_permission: PERMISSION_LEVEL.FULL,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Resend Estimate" handler={event => requestAction("ESTIMATE_SEND")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                                <AccessCheck permissions={{
                                    estimates_delete_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    {!stripeChargeExists && <UniversalButton type="danger" text="Cancel Estimate" handler={event => requestAction("ESTIMATE_CANCEL")} />}
                                </AccessCheck>
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (estimate.state_label === "Won") {
                    return (
                        <ButtonGroup>
                            <AccessCheck permissions={{
                                estimates_edit_permission: PERMISSION_LEVEL.FULL,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Resend Estimate" handler={event => requestAction("ESTIMATE_SEND")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (estimate.state_label === "Lost" || estimate.state_label === "Cancelled") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={event => requestAction("ESTIMATE_DOWNLOAD_PDF")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    renderLastViewed = (lastViewed, preferredTimezone) => {
        const { localDateTimeString } = formatLocalTime(lastViewed, preferredTimezone, false, true, false, true)
        return localDateTimeString
    }

    render() {
        const {estimate, selectedEquipment, childJobs, childInvoices, requestAction, switchToSecondaryForm, submitting, errors, addToastToQueue} = this.props
        const {subtotal, tax, total, downPaymentAmountPaid, downPaymentAmountDue} = calculateEstimateAmounts(estimate)
        const { profit, profitBreakdown } = calculateProfit(estimate)

        return (
            <Fragment>
                <div id="estimate_details_react_wrapper">
                    <div className="data-panel-container">
                        <div className={`data-panel-status-bar estimates-${convertToKebabCase(estimate.status_label)}`} aria-label="Status"><span>{getEstimateStatusBarLabel(estimate)}{window.USING_PUBLIC_URL === true && " Estimate"}</span></div>
                        <div className="data-panel with-status-bar" aria-label="Estimate Details">
                            {
                                estimate.service_company.logo && (
                                    <div className="data-panel__logo" aria-hidden="true">
                                        <div className="data-panel__logo__frame">
                                            <img src={estimate.service_company.logo} alt="Logo" />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="data-panel__form" aria-label="Estimate Details">
                                <div className="data-panel__form__field" aria-label="Estimate Sender">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Sender</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Sender">
                                        <div className="data-panel__form__field__display-list__object estimate-contact-card" aria-label="Sender">
                                            <p>
                                                <strong>{estimate.service_company.name}</strong><br />
                                                <span>{estimate.service_company.physical_address_formatted_multiline}</span><br />
                                                {
                                                    estimate.service_company.phone !== "" && (
                                                        <Fragment><span><PhoneNumberDisplay phone={estimate.service_company.phone} phoneExtension={estimate.service_company.phone_extension} hyperlink={true} /></span><br /></Fragment>
                                                    )
                                                }
                                                {
                                                    estimate.service_company.email !== "" && (
                                                        <Fragment><span><EmailDisplay email={estimate.service_company.email} hyperlink={true} /></span><br /></Fragment>
                                                    )
                                                }
                                                {
                                                    estimate.service_company.contractor_license_number !== "" && (
                                                        <Fragment><span>License #{estimate.service_company.contractor_license_number}</span><br /></Fragment>
                                                    )
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="data-panel__form__field" aria-label="Estimate Recipient">
                                    <span className="data-panel__form__field__label" aria-hidden="true">Recipient (Bill To)</span>
                                    <div className="data-panel__form__field__display-list" aria-label="Recipient (Bill To)">
                                        <div className="data-panel__form__field__display-list__object estimate-contact-card" aria-label="Recipient (Bill To)">
                                            <p>
                                                <strong>{renderClientString(estimate.service_location.external_client)}</strong><br />
                                                <span>{estimate.billing_address_formatted_multiline}</span><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <BasicDisplayField
                                    fieldName="service_location"
                                    fieldLabel="Service Location"
                                    fieldValue={renderServiceLocationString(estimate.service_location, true)}
                                ></BasicDisplayField>
                                {
                                    estimate.job_walk !== null && (
                                        <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                                            <LinkedObjectsDisplayField
                                                fieldName="job_walk"
                                                fieldLabel="Created From Job Walk"
                                                objectComponentList={[
                                                    <JobObjectLink
                                                        key="parent_job_walk_0"
                                                        job={estimate.job_walk}
                                                        destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_JOB_URL : DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, estimate.job_walk.id)}
                                                        disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.jobs_view_permission < PERMISSION_LEVEL.FULL}
                                                    />
                                                ]}
                                            ></LinkedObjectsDisplayField>
                                        </AccessCheck>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="id"
                                    fieldLabel="Roopairs Estimate ID"
                                    fieldValue={estimate.custom_id ? estimate.custom_id : estimate.id}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="service_name"
                                    fieldLabel="Service Type"
                                    fieldValue={estimate.service_name}
                                ></BasicDisplayField>
                                {
                                    !window.USING_PUBLIC_URL && estimate.labels.length !==0 && (
                                        <BasicDisplayField
                                            fieldName="labels"
                                            fieldLabel={<Fragment>Labels <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                            fieldValue={estimate.labels.map((label, index) => <span key={`label_${index}`} className="data-tag">{label.name}</span>)}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="date_issued"
                                    fieldLabel="Date Issued"
                                    fieldValue={renderInvoiceEstimateDate(estimate.date_issued, estimate.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                <BasicDisplayField
                                    fieldName="date_due"
                                    fieldLabel="Expires On"
                                    fieldValue={renderInvoiceEstimateDate(estimate.date_due, estimate.service_company.preferred_timezone, estimate.state_label === "Sent")}
                                ></BasicDisplayField>
                                {
                                    estimate.down_payment_amount > 0 && downPaymentAmountDue === 0 && estimate.down_payment_date_paid !== null && (
                                        <BasicDisplayField
                                            fieldName="down_payment_date_paid"
                                            fieldLabel="Down Payment Date Paid"
                                            fieldValue={renderInvoiceEstimateDate(estimate.down_payment_date_paid, estimate.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    !window.USING_PUBLIC_URL && estimate.last_viewed !== null && (
                                        <BasicDisplayField
                                            fieldName="last_viewed"
                                            fieldLabel="Last Seen"
                                            fieldValue={this.renderLastViewed(estimate.last_viewed, estimate.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    estimate.last_status_transition_time !== null && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_time"
                                            fieldLabel={`${estimate.state_label} On`}
                                            fieldValue={this.renderLastViewed(estimate.last_status_transition_time, estimate.service_company.preferred_timezone)}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    estimate.last_status_transition_message !== "" && (
                                        <BasicDisplayField
                                            fieldName="last_status_transition_message"
                                            fieldLabel={`${estimate.state_label} Message`}
                                            fieldValue={estimate.last_status_transition_message}
                                        ></BasicDisplayField>
                                    )
                                }
                                <BasicDisplayField
                                    fieldName="last_modified_at"
                                    fieldLabel="Last Modified"
                                    fieldValue={this.renderLastViewed(estimate.last_modified_at, estimate.service_company.preferred_timezone)}
                                ></BasicDisplayField>
                                {
                                    estimate.details !== "" && (
                                        <BasicDisplayField
                                            fieldName="details"
                                            fieldLabel="Reason for Call"
                                            fieldValue={estimate.details}
                                        ></BasicDisplayField>
                                    )
                                }
                                <LineItemListDisplayGroup
                                    object={estimate}
                                    labelPrefix={"Estimated "}
                                    formatCurrencyValue={currencyFormatterForServiceCompany(estimate.service_company)}
                                    useTaxes={estimate.service_company.use_taxes}
                                    isEstimateLineItem={true}
                                ></LineItemListDisplayGroup>
                                {
                                    estimate.service_company.use_taxes && estimate.tax_name && (
                                        <BasicDisplayField
                                            fieldName="tax_name"
                                            fieldLabel="Tax Type"
                                            fieldValue={<span title={estimate.tax_breakdown.map(component => `[${component.rate}%] ${component.name}`).join("\n")}>[{estimate.tax_percent}%] {estimate.tax_name}</span>}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    parseInt(estimate.down_payment_amount) !== 0 && (
                                        <div className="data-panel__form__field" id="div_id_payments" aria-label="Down Payments">
                                            <label htmlFor="id_payments" className="data-panel__form__field__label">Down Payments</label>
                                            {
                                                estimate.payments.length === 0
                                                ?
                                                "(No down payments made)"
                                                :
                                                <Fragment>
                                                {
                                                    (window.USING_PUBLIC_URL === true || window.CURRENT_USER?.permissions.estimates_edit_permission < PERMISSION_LEVEL.FULL || estimate.state_label === "Won" || estimate.state_label === "Lost" || estimate.state_label === "Cancelled")
                                                    ?
                                                    <PaymentListDisplay fieldId="id_payments" fieldName="payments" object={estimate} payments={estimate.payments}></PaymentListDisplay>
                                                    :
                                                    <div className="data-panel__form__field__input data-panel__form__field__input--with-list-select">
                                                        <PaymentListSelect fieldId="id_payments" fieldName="payments" onSelect={data => switchToSecondaryForm("EDIT_PAYMENT", data, null)} object={estimate} endpoint={DjangoUrls["estimates:api-estimates-payments-list"](window.MARKETPLACE_ENTITY_SLUG, estimate.id)}></PaymentListSelect>
                                                    </div>
                                                }
                                                </Fragment>
                                            }
                                        </div>
                                    )
                                }
                                {
                                    estimate.comments !== "" && (
                                        <BasicDisplayField
                                            fieldName="comments"
                                            fieldLabel="Comments"
                                            fieldValue={estimate.comments}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    estimate.service_company.estimate_fine_print !== "" && (
                                        <BasicDisplayField
                                            fieldName="estimate_fine_print"
                                            fieldLabel={estimate.service_company.estimate_fine_print_label}
                                            fieldValue={estimate.service_company.estimate_fine_print}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    estimate.attachments.length !== 0 && estimate.service_company.feature_file_uploads_enabled && (
                                        <BasicDisplayField
                                            fieldName="attachments"
                                            fieldLabel="Attachments"
                                            fieldValue={<AttachmentHorizontalDisplay attachments={estimate.attachments} />}
                                        ></BasicDisplayField>
                                    )
                                }
                                {
                                    !(window.USING_PUBLIC_URL === true) && childJobs.length !== 0 && (
                                        <AccessCheck entitlements={["entitlement_jobs_enabled"]} permissions={{
                                            jobs_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <LinkedObjectsDisplayField
                                                fieldName="jobs"
                                                fieldLabel="Jobs"
                                                objectComponentList={childJobs.map((job, index) => (
                                                    <JobObjectLink
                                                        key={`child_jobs_${index}`}
                                                        job={job}
                                                        destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.id)}
                                                        disabled={window.CURRENT_USER?.permissions.jobs_view_permission < PERMISSION_LEVEL.FULL}
                                                    />
                                                ))}
                                            ></LinkedObjectsDisplayField>
                                        </AccessCheck>
                                    )
                                }
                                {
                                    !(window.USING_PUBLIC_URL === true) && childInvoices.length !== 0 && (
                                        <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={{
                                            invoices_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <LinkedObjectsDisplayField
                                                fieldName="invoices"
                                                fieldLabel="Invoices"
                                                objectComponentList={childInvoices.map((invoice, index) => (
                                                    <InvoiceObjectLink
                                                        key={`child_invoice_${index}`}
                                                        invoice={invoice}
                                                        destination={DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id)}
                                                        disabled={window.CURRENT_USER?.permissions.invoices_view_permission < PERMISSION_LEVEL.FULL}
                                                    />
                                                ))}
                                            ></LinkedObjectsDisplayField>
                                        </AccessCheck>
                                    )
                                }
                            </div>
                            <div className="data-panel__form data-panel__data-summary amount-summary" aria-label="Amount Summary">
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--subtotal" aria-label="Estimated Subtotal">
                                    <div className="data-panel__data-summary__data__label"><span>Estimated Subtotal</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(subtotal, estimate.service_company)}</div>
                                </div>
                                {
                                    estimate.service_company.use_taxes && (
                                        <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--tax" aria-label="Estimated Tax">
                                            <div className="data-panel__data-summary__data__label"><span title={estimate.tax_breakdown.map(component => `[${component.rate}%] ${component.name}`).join("\n")}>Estimated Tax ({estimate.tax_percent ? `${estimate.tax_percent}%` : "none"})</span></div>
                                            <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(tax, estimate.service_company)}</div>
                                        </div>
                                    )
                                }
                                <hr aria-hidden="true" />
                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--total" aria-label="Estimated Total">
                                    <div className="data-panel__data-summary__data__label"><span>Estimated Total</span></div>
                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(total, estimate.service_company)}</div>
                                </div>
                                {
                                    !(window.USING_PUBLIC_URL === true) && (
                                        <>
                                            <Tooltip
                                                type="message"
                                                title="Estimated Profit Breakdown:"
                                                message={<>
                                                    <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                        Services:
                                                        <span style={{paddingLeft:"1em"}}>{formatCurrencyForServiceCompany(profitBreakdown.service, estimate.service_company)}</span>
                                                    </span>
                                                    <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                        Parts:
                                                        <span style={{paddingLeft:"1em"}}>{formatCurrencyForServiceCompany(profitBreakdown.part, estimate.service_company)}</span>
                                                    </span>
                                                    <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                        Misc.:
                                                        <span style={{paddingLeft:"1em"}}>{formatCurrencyForServiceCompany(profitBreakdown.other, estimate.service_company)}</span>
                                                    </span>
                                                    <span style={{display:"flex", justifyContent: "space-between", paddingLeft:"1em"}}>
                                                        Discounts:
                                                        <span style={{paddingLeft:"1em"}}>{formatCurrencyForServiceCompany(profitBreakdown.discount, estimate.service_company)}</span>
                                                    </span>
                                                </>}
                                            >
                                                <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--profit" aria-label="Estimated Profit">
                                                    <div className="data-panel__data-summary__data__label"><span>Estimated Profit</span></div>
                                                    <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(profit, estimate.service_company)}</div>
                                                </div>
                                            </Tooltip>
                                        </>
                                    )
                                }

                                {
                                    parseInt(estimate.down_payment_amount) !== 0 && (
                                        <Fragment>
                                            <hr aria-hidden="true" />
                                            <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--down-payment">
                                                <div className="data-panel__data-summary__data__label"><span>Required Down Payment</span></div>
                                                <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(estimate.down_payment_amount, estimate.service_company)}</div>
                                            </div>
                                            <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--amount-paid" aria-label="Amount Paid">
                                                <div className="data-panel__data-summary__data__label"><span>Amount Paid</span></div>
                                                <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(downPaymentAmountPaid * -1, estimate.service_company)}</div>
                                            </div>
                                            <div className="data-panel__data-summary__row amount-summary__item amount-summary__item--amount-due" aria-label="Amount Due Now">
                                                <div className="data-panel__data-summary__data__label"><span>Amount Due Now</span></div>
                                                <div className="data-panel__data-summary__data__value">{formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)}</div>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            </div>
                            {
                                Object.keys(errors).length !== 0 && (
                                    <Banner type="danger" text="This estimate has errors and cannot be created. Please edit the draft to fix them." extraMargin={true} />
                                )
                            }
                            {this.renderButtons()}
                        </div>
                    </div>
                </div>
                    { (window.USING_PUBLIC_URL === true || window.CURRENT_USER?.permissions.equipment_list_permission >= PERMISSION_LEVEL.RESTRICTED) &&
                        selectedEquipment.sort((obj, other) => obj.display_name.localeCompare(other.display_name)).map(equipment => {
                            return (
                                <div key={`equipment_${equipment.id}`} className="accordion-wrapper">
                                    <EquipmentDetailsAccordion
                                        data={equipment}
                                        clientID={estimate.service_location.external_client.id}
                                        editable={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.equipment_edit_permission >= PERMISSION_LEVEL.FULL}
                                        showJobHistory={false}
                                        addToastToQueue={addToastToQueue}
                                        openable={window.USING_PUBLIC_URL === true || window.CURRENT_USER?.permissions.equipment_view_permission >= PERMISSION_LEVEL.FULL}
                                    />
                                </div>
                            )
                        })
                    }
                {!(window.USING_PUBLIC_URL === true) &&
                    <div className="accordion-wrapper">
                        <AccessCheck permissions={{
                            estimates_view_permission: PERMISSION_LEVEL.FULL
                        }}>
                            <EstimateFeedAccordion estimateID={estimate.id} />
                        </AccessCheck>
                    </div>
                }
                {window.USING_PUBLIC_URL === true &&
                    <div className="powered-by-panel">
                        <span>Powered By</span>
                        <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                    </div>
                }
            </Fragment>
        )
    }

}

export default EstimateDetailsCard;
