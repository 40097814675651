import { createContext, useContext } from "react"

import { TopBarContextValues } from "./TopBar.types"

export const TopBarContext = createContext<TopBarContextValues | undefined>(undefined)
TopBarContext.displayName = "TopBar"

export default function useTopBar(): TopBarContextValues {
    const context = useContext(TopBarContext)
    if (!context) {
        throw new Error("useTopBar must be used within a TopBar Provider component")
    }

    return context
}
