// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineViewTechniciansBarTechCell-base__nIfbm{display:flex;gap:var(--size-3);align-items:center;width:var(--size-full);height:var(--size-full);padding:calc(var(--size-6) + var(--size-0_5)) var(--size-4)}@media only screen and (min-width: 64rem){.TimelineViewTechniciansBarTechCell-base__nIfbm{padding:var(--size-4)}}.TimelineViewTechniciansBarTechCell-base__nIfbm.TimelineViewTechniciansBarTechCell-collapsed__qY_ko{padding:var(--size-3)}.TimelineViewTechniciansBarTechCell-base__nIfbm.TimelineViewTechniciansBarTechCell-collapsed__qY_ko .TimelineViewTechniciansBarTechCell-content__dG5TX{display:none}.TimelineViewTechniciansBarTechCell-base__nIfbm.TimelineViewTechniciansBarTechCell-compact__QzTxk{padding:var(--size-2_5) var(--size-4)}.TimelineViewTechniciansBarTechCell-base__nIfbm.TimelineViewTechniciansBarTechCell-compact__QzTxk.TimelineViewTechniciansBarTechCell-collapsed__qY_ko{padding:var(--size-3)}.TimelineViewTechniciansBarTechCell-base__nIfbm.TimelineViewTechniciansBarTechCell-vertical__hpIK8{justify-content:center}.TimelineViewTechniciansBarTechCell-content__dG5TX{display:flex;flex-direction:column;gap:var(--size-0_5)}.TimelineViewTechniciansBarTechCell-name__ehNev{font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375);color:var(--gray-12)}.TimelineViewTechniciansBarTechCell-statusWrap__RZ3OM{display:flex;gap:var(--size-1);align-items:center}.TimelineViewTechniciansBarTechCell-statusWrapText__KJ7uA{font:var(--font-global-caption-medium-375);font-feature-settings:var(--feature-settings-global-caption-medium-375);letter-spacing:var(--letter-spacing-global-caption-medium-375);color:var(--gray-11)}.TimelineViewTechniciansBarTechCell-unassigned__duQOA{background-color:var(--gray-1)}.TimelineViewTechniciansBarTechCell-unassigned__duQOA .TimelineViewTechniciansBarTechCell-name__ehNev{color:var(--gray-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TimelineViewTechniciansBarTechCell-base__nIfbm",
	"collapsed": "TimelineViewTechniciansBarTechCell-collapsed__qY_ko",
	"content": "TimelineViewTechniciansBarTechCell-content__dG5TX",
	"compact": "TimelineViewTechniciansBarTechCell-compact__QzTxk",
	"vertical": "TimelineViewTechniciansBarTechCell-vertical__hpIK8",
	"name": "TimelineViewTechniciansBarTechCell-name__ehNev",
	"statusWrap": "TimelineViewTechniciansBarTechCell-statusWrap__RZ3OM",
	"statusWrapText": "TimelineViewTechniciansBarTechCell-statusWrapText__KJ7uA",
	"unassigned": "TimelineViewTechniciansBarTechCell-unassigned__duQOA"
};
export default ___CSS_LOADER_EXPORT___;
