import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices";
import { renderServiceLocationString } from "@legacy/clients/utils/utils";
import Spinner from "@legacy/core/components/Spinner";

import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Component } from "react";
import deepcopy from "rfdc";
import ClientForm from "../clients/forms/ClientForm";
import ServiceLocationForm from "../clients/forms/ServiceLocationForm";
import LineItemForm from "../core/forms/LineItemForm";
import { ClientTypes, CustomIDGenerationModes, LineItemUnitTypes, PriceBookItemTypes } from "../core/utils/enums";
import { currencyFormatter, getCurrencySymbol, getIsTaxable, historyHasState, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import PriceBookItemForm from "../pricebook/forms/PriceBookItemForm";
import { getPricebookTaxById, getPricebookTaxByName } from "../pricebook/utils/utils";
import InvoiceForm from "./forms/InvoiceForm";
import { validateLineItem } from "./utils/utils";

dayjs.extend(timezone)

const DEFAULT_NET_VALUES = [
    0, 7, 14, 21, 30, 45, 60, 90
]

const FORM_MODES = {
    ADD_INVOICE: "ADD_INVOICE",
    EDIT_INVOICE: "EDIT_INVOICE",
    ADD_CLIENT: "ADD_CLIENT",
    EDIT_CLIENT: "EDIT_CLIENT",
    ADD_SERVICE_LOCATION: "ADD_SERVICE_LOCATION",
    EDIT_SERVICE_LOCATION: "EDIT_SERVICE_LOCATION",
    ADD_PRICEBOOKITEM_SERVICE: "ADD_PRICEBOOKITEM_SERVICE",
    EDIT_PRICEBOOKITEM_SERVICE: "EDIT_PRICEBOOKITEM_SERVICE",
    ADD_PRICEBOOKITEM_TAX: "ADD_PRICEBOOKITEM_TAX",
    EDIT_PRICEBOOKITEM_TAX: "EDIT_PRICEBOOKITEM_TAX",
    ADD_SERVICE_CHARGE: "ADD_SERVICE_CHARGE",
    EDIT_SERVICE_CHARGE: "EDIT_SERVICE_CHARGE",
    ADD_PART: "ADD_PART",
    EDIT_PART: "EDIT_PART",
    ADD_OTHER_CHARGE: "ADD_OTHER_CHARGE",
    EDIT_OTHER_CHARGE: "EDIT_OTHER_CHARGE",
    ADD_DISCOUNT: "ADD_DISCOUNT",
    EDIT_DISCOUNT: "EDIT_DISCOUNT",
}

const FORM_MODE_SUBTITLES = {
    ADD_INVOICE: "Add Invoice Details",
    EDIT_INVOICE: "Edit Invoice Details",
    ADD_CLIENT: "New Client",
    EDIT_CLIENT: "Edit Client",
    ADD_SERVICE_LOCATION: "Add Service Location",
    EDIT_SERVICE_LOCATION: "Edit Service Location",
    ADD_PRICEBOOKITEM_SERVICE: "Add PriceBook Service",
    EDIT_PRICEBOOKITEM_SERVICE: "Edit PriceBook Service",
    ADD_PRICEBOOKITEM_TAX: "Add PriceBook Tax",
    EDIT_PRICEBOOKITEM_TAX: "Edit PriceBook Tax",
    ADD_SERVICE_CHARGE: "Add Service Charge",
    EDIT_SERVICE_CHARGE: "Edit Service Charge",
    ADD_PART: "Add Part or Material",
    EDIT_PART: "Edit Part or Material",
    ADD_OTHER_CHARGE: "Add Miscellaneous Charge",
    EDIT_OTHER_CHARGE: "Edit Miscellaneous Charge",
    ADD_DISCOUNT: "Add Discount",
    EDIT_DISCOUNT: "Edit Discount",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_INVOICE: "flex",
    EDIT_INVOICE: "flex",
    ADD_CLIENT: "none",
    EDIT_CLIENT: "none",
    ADD_SERVICE_LOCATION: "none",
    EDIT_SERVICE_LOCATION: "none",
    ADD_PRICEBOOKITEM_SERVICE: "none",
    EDIT_PRICEBOOKITEM_SERVICE: "none",
    ADD_PRICEBOOKITEM_TAX: "none",
    EDIT_PRICEBOOKITEM_TAX: "none",
    ADD_SERVICE_CHARGE: "none",
    EDIT_SERVICE_CHARGE: "none",
    ADD_PART: "none",
    EDIT_PART: "none",
    ADD_OTHER_CHARGE: "none",
    EDIT_OTHER_CHARGE: "none",
    ADD_DISCOUNT: "none",
    EDIT_DISCOUNT: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_INVOICE, FORM_MODES.EDIT_INVOICE]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_CLIENT,
    FORM_MODES.EDIT_CLIENT,
    FORM_MODES.ADD_SERVICE_LOCATION,
    FORM_MODES.EDIT_SERVICE_LOCATION,
    FORM_MODES.ADD_PRICEBOOKITEM_SERVICE,
    FORM_MODES.EDIT_PRICEBOOKITEM_SERVICE,
    FORM_MODES.ADD_PRICEBOOKITEM_TAX,
    FORM_MODES.EDIT_PRICEBOOKITEM_TAX,
    FORM_MODES.ADD_SERVICE_CHARGE,
    FORM_MODES.EDIT_SERVICE_CHARGE,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
    FORM_MODES.ADD_OTHER_CHARGE,
    FORM_MODES.EDIT_OTHER_CHARGE,
    FORM_MODES.ADD_DISCOUNT,
    FORM_MODES.EDIT_DISCOUNT,
]

const CLIENT_FORM_MODES = [
    FORM_MODES.ADD_CLIENT,
    FORM_MODES.EDIT_CLIENT,
]

const SERVICE_LOCATION_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_LOCATION,
    FORM_MODES.EDIT_SERVICE_LOCATION,
]

const PRICEBOOK_ITEM_FORM_MODES = [
    FORM_MODES.ADD_PRICEBOOKITEM_SERVICE,
    FORM_MODES.EDIT_PRICEBOOKITEM_SERVICE,
    FORM_MODES.ADD_PRICEBOOKITEM_TAX,
    FORM_MODES.EDIT_PRICEBOOKITEM_TAX,
]

const LINE_ITEM_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_CHARGE,
    FORM_MODES.EDIT_SERVICE_CHARGE,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
    FORM_MODES.ADD_OTHER_CHARGE,
    FORM_MODES.EDIT_OTHER_CHARGE,
    FORM_MODES.ADD_DISCOUNT,
    FORM_MODES.EDIT_DISCOUNT,
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_INVOICE: "invoiceData",
    EDIT_INVOICE: "invoiceData",
    ADD_CLIENT: "clientData",
    EDIT_CLIENT: "clientData",
    ADD_SERVICE_LOCATION: "serviceLocationData",
    EDIT_SERVICE_LOCATION: "serviceLocationData",
    ADD_PRICEBOOKITEM_SERVICE: "priceBookItemData",
    EDIT_PRICEBOOKITEM_SERVICE: "priceBookItemData",
    ADD_PRICEBOOKITEM_TAX: "priceBookItemData",
    EDIT_PRICEBOOKITEM_TAX: "priceBookItemData",
    ADD_SERVICE_CHARGE: "lineItemData",
    EDIT_SERVICE_CHARGE: "lineItemData",
    ADD_PART: "lineItemData",
    EDIT_PART: "lineItemData",
    ADD_OTHER_CHARGE: "lineItemData",
    EDIT_OTHER_CHARGE: "lineItemData",
    ADD_DISCOUNT: "lineItemData",
    EDIT_DISCOUNT: "lineItemData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_INVOICE: "submittingInvoice",
    EDIT_INVOICE: "submittingInvoice",
    ADD_CLIENT: "submittingClient",
    EDIT_CLIENT: "submittingClient",
    ADD_SERVICE_LOCATION: "submittingServiceLocation",
    EDIT_SERVICE_LOCATION: "submittingServiceLocation",
    ADD_PRICEBOOKITEM_SERVICE: "submittingPriceBookItem",
    EDIT_PRICEBOOKITEM_SERVICE: "submittingPriceBookItem",
    ADD_PRICEBOOKITEM_TAX: "submittingPriceBookItem",
    EDIT_PRICEBOOKITEM_TAX: "submittingPriceBookItem",
    ADD_SERVICE_CHARGE: "submittingLineItem",
    EDIT_SERVICE_CHARGE: "submittingLineItem",
    ADD_PART: "submittingLineItem",
    EDIT_PART: "submittingLineItem",
    ADD_OTHER_CHARGE: "submittingLineItem",
    EDIT_OTHER_CHARGE: "submittingLineItem",
    ADD_DISCOUNT: "submittingLineItem",
    EDIT_DISCOUNT: "submittingLineItem",
}

const ERROR_NAMES_BY_MODE = {
    ADD_INVOICE: "invoice",
    EDIT_INVOICE: "invoice",
    ADD_CLIENT: "client",
    EDIT_CLIENT: "client",
    ADD_SERVICE_LOCATION: "serviceLocation",
    EDIT_SERVICE_LOCATION: "serviceLocation",
    ADD_PRICEBOOKITEM_SERVICE: "priceBookItem",
    EDIT_PRICEBOOKITEM_SERVICE: "priceBookItem",
    ADD_PRICEBOOKITEM_TAX: "priceBookItem",
    EDIT_PRICEBOOKITEM_TAX: "priceBookItem",
    ADD_SERVICE_CHARGE: "lineItem",
    EDIT_SERVICE_CHARGE: "lineItem",
    ADD_PART: "lineItem",
    EDIT_PART: "lineItem",
    ADD_OTHER_CHARGE: "lineItem",
    EDIT_OTHER_CHARGE: "lineItem",
    ADD_DISCOUNT: "lineItem",
    EDIT_DISCOUNT: "lineItem",
}


const LINE_ITEM_TYPES = {
    SERVICE_CHARGES: "service_charges",
    PARTS: "parts",
    OTHER_CHARGES: "other_charges",
    DISCOUNTS: "discounts",
}

const LINE_ITEM_TYPE_MAP = {
    [LINE_ITEM_TYPES.SERVICE_CHARGES]: PriceBookItemTypes.service,
    [LINE_ITEM_TYPES.PARTS]: PriceBookItemTypes.part,
    [LINE_ITEM_TYPES.OTHER_CHARGES]: PriceBookItemTypes.other,
    [LINE_ITEM_TYPES.DISCOUNTS]: PriceBookItemTypes.discount,
}

const LINE_ITEM_TYPE_BY_MODE = {
    ADD_SERVICE_CHARGE: PriceBookItemTypes.service,
    EDIT_SERVICE_CHARGE: PriceBookItemTypes.service,
    ADD_PART: PriceBookItemTypes.part,
    EDIT_PART: PriceBookItemTypes.part,
    ADD_OTHER_CHARGE: PriceBookItemTypes.other,
    EDIT_OTHER_CHARGE: PriceBookItemTypes.other,
    ADD_DISCOUNT: PriceBookItemTypes.discount,
    EDIT_DISCOUNT: PriceBookItemTypes.discount,
}

const BILLING_ADDRESS_FIELD_NAMES = [
    "billing_address_recipient",
    "billing_address_street",
    "billing_address_unit",
    "billing_address_city",
    "billing_address_state",
    "billing_address_postal",
    "billing_address_country",
]


class InvoiceCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_INVOICE
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            invoiceData: null,
            jobData: null,
            estimateData: null,
            clientData: {},
            serviceLocationData: {},
            priceBookItemData: {},
            lineItemData: {},
            deletedLineItemIDs: {},
            priceBookClientOverrides: [],

            attachments: [],

            selectedClient: null,
            selectedServiceLocation: null,
            selectedPriceBookService: null,
            selectedPriceBookTax: null,

            isDraft: false,

            errors: {
                invoice: {},
                client: {},
                serviceLocation: {},
                priceBookItem: {},
                lineItem: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            priceBookServices: window.PRICEBOOK_SERVICES || [],
            priceBookTaxes: window.PRICEBOOK_TAXES || [],

            preferredTimezone: window.PREFERRED_TIMEZONE,
            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
            useTaxes: window.USE_TAXES,
            hideTaxRates: window.CURRENT_USER.service_company.accounting_integration === AccountingIntegrations.apideck && window.CURRENT_USER.service_company.apideck_tax_strategy !== APIDeckTaxStrategies.normal,

            pricebook_default_taxable_service: window.PRICEBOOK_DEFAULT_TAXABLE_SERVICE,
            pricebook_default_taxable_part: window.PRICEBOOK_DEFAULT_TAXABLE_PART,
            pricebook_default_taxable_other: window.PRICEBOOK_DEFAULT_TAXABLE_OTHER,

            phoneNumberCountry: window.PHONE_NUMBER_COUNTRY,
            defaultClientType: window.DEFAULT_CLIENT_TYPE,
            showCustomInvoiceIDField: window.INVOICE_CUSTOM_ID_GENERATION_MODE === CustomIDGenerationModes.manual,
            showQuickBooksItemSelect: window.ACCOUNTING_INTEGRATION === 2 && window.QUICKBOOKS_LINE_ITEM_SCHEME === 2,
            showQuickBooksRevenueAccountSelect: window.ACCOUNTING_INTEGRATION === 2 && window.QUICKBOOKS_LINE_ITEM_SCHEME === 2,
            showQuickBooksTaxAgencyVendorSelect: window.ACCOUNTING_INTEGRATION === 2,
            showTaxCreateButton: window.ACCOUNTING_INTEGRATION === 0,
            serviceComapnyDefaultInvoiceNet: window.SERVICE_COMPANY_DEFAULT_INVOICE_NET,

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            defaultSelectedNet: null,
            useBillingAddress: false,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        // If props tell us this is an edit view, grab invoice data via rest
        if (this.state.defaultMode === FORM_MODES.EDIT_INVOICE && window.INVOICE_ID) {
            if (this.state.invoiceData === null) {
                const invoiceEndpoint = DjangoUrls["invoices:api-invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, window.INVOICE_ID)
                const invoiceResponse = await fetch(invoiceEndpoint)
                const invoice = await invoiceResponse.json()
                const clientID = invoice.service_location.external_client.id

                const overridesEndpoint =
                    DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG) +
                    `?external_client=${clientID}`;

                const responseOverrides = await fetch(overridesEndpoint);
                const clientOverrides = await responseOverrides.json();

            this.setState((state, props) => {
                let updatedState = state
                    updatedState.invoiceData = invoice
                    updatedState.priceBookClientOverrides = clientOverrides || []

                    updatedState.selectedClient = invoice.service_location.external_client
                    updatedState.client_id = updatedState.selectedClient.id
                    updatedState.selectedServiceLocation = invoice.service_location
                    updatedState.service_location_id = updatedState.selectedServiceLocation.id
                    updatedState.selectedPriceBookService = state.priceBookServices.find(service => service.description === invoice.service_name) || null
                    updatedState.selectedPriceBookTax = getPricebookTaxByName(invoice.tax_name, state.priceBookTaxes)

                    updatedState.isDraft = invoice.is_draft

                    updatedState.attachments = deepcopy()(invoice.attachments)
                    updatedState.labels = deepcopy()(invoice.labels)

                    // Move job data and convert job to ID
                    if (invoice.job !== null && invoice.job !== undefined) {
                        updatedState.jobData = deepcopy()(invoice.job)

                        updatedState.jobData.service_charges = invoice.job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                        updatedState.jobData.parts = invoice.job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                        updatedState.jobData.other_charges = invoice.job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                        updatedState.jobData.discounts = invoice.job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                        updatedState.invoiceData.job = invoice.job.id
                    }
                    else {
                        updatedState.jobData = {}
                    }

                    // Move estimate data and convert estimate to ID
                    if (invoice.estimate !== null && invoice.estimate !== undefined) {
                        updatedState.estimateData = deepcopy()(invoice.estimate)

                        updatedState.estimateData.service_charges = invoice.estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                        updatedState.estimateData.parts = invoice.estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                        updatedState.estimateData.other_charges = invoice.estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                        updatedState.estimateData.discounts = invoice.estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                        updatedState.invoiceData.estimate = invoice.estimate.id
                    }
                    else {
                        updatedState.estimateData = {}
                    }

                    // Convert service company to slug
                    updatedState.invoiceData.service_company = invoice.service_company.slug

                    // Convert service location to ID
                    updatedState.invoiceData.service_location = invoice.service_location.id


                    updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(state)

                    this.resetInvoiceData(updatedState, state, invoice)

                    // Split line items into their respective lists
                    updatedState.invoiceData.service_charges = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                    updatedState.invoiceData.parts = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    updatedState.invoiceData.other_charges = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                    updatedState.invoiceData.discounts = invoice.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                    return updatedState
                })
            }
        }
        else {
            const localTime = dayjs.tz(undefined, window.PREFERRED_TIMEZONE)
            const localDateString = localTime.format("YYYY-MM-DD")

            const hasJobsEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_jobs_enabled;
            const hasJobsPermissions = window.CURRENT_USER?.permissions.jobs_view_permission >= PERMISSION_LEVEL.FULL
            const fromJobID = new URLSearchParams(document.location.search).get("from_job") || null
            if (hasJobsEntitlement && hasJobsPermissions && fromJobID !== null && this.state.jobData === null) {
                const jobEndpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, fromJobID)

                const jobResponse = await fetch(jobEndpoint)
                let job = await jobResponse.json()

                const clientID = job.service_location.external_client.id
                const overridesEndpoint =
                DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG) +
                `?external_client=${clientID}`;
                const responseOverrides = await fetch(overridesEndpoint);
                const clientOverrides = await responseOverrides.json();

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.jobData = job
                    updatedState.priceBookClientOverrides = clientOverrides || []

                    // Split job line items into their respective lists
                    updatedState.jobData.service_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                    updatedState.jobData.parts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    updatedState.jobData.other_charges = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                    updatedState.jobData.discounts = job.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                    return updatedState
                })
            }
            else {
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.jobData = {}
                    return updatedState
                })
            }

            const hasEstimatesEntitlement = !!window.CURRENT_USER?.service_company?.entitlement_estimates_enabled;
            const hasEstimatesPermissions = window.CURRENT_USER?.permissions.estimates_view_permission >= PERMISSION_LEVEL.FULL
            const fromEstimateID = new URLSearchParams(document.location.search).get("from_estimate") || null
            if (hasEstimatesEntitlement && hasEstimatesPermissions && fromEstimateID !== null && this.state.estimateData === null) {
                const estimateEndpoint = DjangoUrls["estimates:api-estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, fromEstimateID)

                const estimateResponse = await fetch(estimateEndpoint)
                let estimate = await estimateResponse.json()
                const clientID = estimate.service_location.external_client.id
                const overridesEndpoint =
                DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG) +
                `?external_client=${clientID}`;
                const responseOverrides = await fetch(overridesEndpoint);
                const clientOverrides = await responseOverrides.json();


                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.estimateData = estimate
                    updatedState.priceBookClientOverrides = clientOverrides || []

                    // Split estimate line items into their respective lists
                    updatedState.estimateData.service_charges = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                    updatedState.estimateData.parts = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                    updatedState.estimateData.other_charges = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                    updatedState.estimateData.discounts = estimate.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                    return updatedState
                })
            }
            else {
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.estimateData = {}
                    return updatedState
                })
            }

            this.setState((state, props) => {
                let updatedState = state

                this.resetInvoiceData(updatedState, state, {})

                updatedState.invoiceData.service_company = window.MARKETPLACE_ENTITY_SLUG
                updatedState.invoiceData.date_issued = localDateString
                updatedState.invoiceData.accept_online_payments = true
                updatedState.invoiceData.attachments = []
                updatedState.invoiceData.labels = []

                // Set fields from the job (if from a job)
                if (Object.keys(state.jobData).length !== 0) {
                    updatedState.invoiceData.job = state.jobData.id

                    updatedState.selectedClient = state.jobData.service_location.external_client
                    updatedState.client_id = updatedState.selectedClient.id
                    updatedState.selectedServiceLocation = state.jobData.service_location
                    updatedState.service_location_id = updatedState.selectedServiceLocation.id
                    updatedState.selectedPriceBookService = state.priceBookServices.find(service => service.description === state.jobData.service_name) || null
                    updatedState.selectedPriceBookTax = getPricebookTaxByName(state.jobData.tax_name, state.priceBookTaxes)

                    updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(state)
                    updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)

                    updatedState.invoiceData.service_location = state.jobData.service_location.id
                    updatedState.invoiceData.service_name = state.jobData.service_name
                    updatedState.invoiceData.custom_id = state.jobData.invoice_custom_id
                    updatedState.invoiceData.tax_name = state.jobData.tax_name
                    updatedState.invoiceData.tax_percent = state.jobData.tax_percent
                    updatedState.invoiceData.tax_breakdown = state.jobData.tax_breakdown
                    updatedState.invoiceData.tax_quickbooks_desktop_item_id = state.jobData.tax_quickbooks_desktop_item_id
                }

                // Set fields from the estimate (if from a estimate)
                if (Object.keys(state.estimateData).length !== 0) {
                    updatedState.invoiceData.estimate = state.estimateData.id

                    updatedState.selectedClient = state.estimateData.service_location.external_client
                    updatedState.client_id = updatedState.selectedClient.id
                    updatedState.selectedServiceLocation = state.estimateData.service_location
                    updatedState.service_location_id = updatedState.selectedServiceLocation.id
                    updatedState.selectedPriceBookService = state.priceBookServices.find(service => service.description === state.estimateData.service_name) || null
                    updatedState.selectedPriceBookTax = getPricebookTaxByName(state.estimateData.tax_name, state.priceBookTaxes)

                    updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(state)
                    updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)

                    updatedState.invoiceData.service_location = state.estimateData.service_location.id
                    updatedState.invoiceData.service_name = state.estimateData.service_name
                    updatedState.invoiceData.tax_name = state.estimateData.tax_name
                    updatedState.invoiceData.tax_percent = state.estimateData.tax_percent
                    updatedState.invoiceData.tax_breakdown = state.estimateData.tax_breakdown
                    updatedState.invoiceData.tax_quickbooks_desktop_item_id = state.estimateData.tax_quickbooks_desktop_item_id
                }

                // Pull invoice line items from job/estimate line items (if from a job/estimate)
                for (let line_item_type of Object.values(LINE_ITEM_TYPES)) {
                    if (state.invoiceData[line_item_type] === undefined) {
                        updatedState.invoiceData[line_item_type] = []

                        if (Object.keys(state.jobData).length !== 0) {
                            state.jobData[line_item_type].forEach(lineItem => {
                                lineItem.id = null
                                updatedState.invoiceData[line_item_type].push(lineItem)
                            })
                        }

                        if (Object.keys(state.estimateData).length !== 0) {
                            state.estimateData[line_item_type].forEach(lineItem => {
                                lineItem.id = null
                                updatedState.invoiceData[line_item_type].push(lineItem)
                            })
                        }
                    }
                }

                if(!updatedState.invoiceData?.tax_name) {
                    this.updateDefaultTax(updatedState)
                }

                this.resetInvoiceData(updatedState, state, updatedState.invoiceData)

                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue

            if (formName === "invoiceData" && fieldName === "date_issued") {
                updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
                updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
            }
            else if (formName === "invoiceData" && fieldName === "date_due") {
                updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
                updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
            }
            else if (formName === "invoiceData" && fieldName === "net") {
                updatedState.defaultSelectedNet = valueIsDefined(fieldValue) ? parseInt(fieldValue) : null
                updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
            }
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                // To help with uniqueness check
                data.originalDescription = data.description
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)

                if (newFormMode === FORM_MODES.EDIT_PRICEBOOKITEM_TAX) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].returnMode = this.state.mode
                }
            }
            else {
                if (SERVICE_LOCATION_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].external_client = state.client_id  // Set the client id on the new service location
                }
                else if (newFormMode === FORM_MODES.ADD_CLIENT) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].client_type = window.DEFAULT_CLIENT_TYPE || ClientTypes.business
                }
                else if (newFormMode === FORM_MODES.ADD_PRICEBOOKITEM_SERVICE) {
                    // Set the type to `service` on the new PriceBook Item. Also set typical defaults
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].pricebook_item_type = PriceBookItemTypes.service
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].default_unit_type = LineItemUnitTypes.hourly
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_active = true
                }
                else if (newFormMode === FORM_MODES.ADD_PRICEBOOKITEM_TAX) {
                    // Set the type to `tax` on the new PriceBook Item.
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].pricebook_item_type = PriceBookItemTypes.tax
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].is_active = true
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].returnMode = this.state.mode
                }
                else if (LINE_ITEM_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].line_item_type = LINE_ITEM_TYPE_BY_MODE[newFormMode]
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    resetInvoiceData = (updatedState, state, invoiceData) => {
        updatedState.invoiceData = invoiceData
        updatedState.invoiceData.is_draft = state.isDraft

        // Handle setting the initial billing address
        let noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.invoiceData[fieldName]))

        // If one isn't set, first attempt to grab it from the selected service location
        if (noBillingAddress && updatedState.selectedServiceLocation !== null) {
            BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.invoiceData[fieldName] = updatedState.selectedServiceLocation[fieldName] || "")
            noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.invoiceData[fieldName]))
        }

        // If one still isn't set, grab it from the selected client
        if (noBillingAddress && updatedState.selectedClient !== null && updatedState.selectedServiceLocation !== null) {
            BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.invoiceData[fieldName] = updatedState.selectedClient[fieldName] || "")
            noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.invoiceData[fieldName]))
        }

        // If the billing address is the same as the service location's physical address, unset it so that "same as service location" takes over
        if (updatedState.selectedServiceLocation !== null) {
            const addressesAreEqual = BILLING_ADDRESS_FIELD_NAMES.filter(fieldName => fieldName !== "billing_address_recipient").every(
                fieldName => updatedState.invoiceData[fieldName] === updatedState.selectedServiceLocation[fieldName.replace("billing_address", "physical_address")]
            )
            if (addressesAreEqual) {
                BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.invoiceData[fieldName] = "")
            }
        }

        // Re-set the no address check
        noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(updatedState.invoiceData[fieldName]))
        updatedState.useBillingAddress = !noBillingAddress
    }

    updateClientSelection = async (selectedClient) => {
        let clientOverrides = []
        if (selectedClient !== null){
            const clientID = selectedClient.id

            const overridesEndpoint =
            DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG) +
            `?external_client=${clientID}`;

            const responseOverrides = await fetch(overridesEndpoint);
            clientOverrides = await responseOverrides.json();

            this.setState((state, props) => {
                let updatedState = state
                updatedState.priceBookClientOverrides = clientOverrides || []
            })
        }

        this.setState((state, props) => {
            let updatedState = state

            if (selectedClient !== null) {
                updatedState.selectedClient = selectedClient

                // If a different client is selected, wipe the selected location and equipment
                if (state.client_id !== undefined && selectedClient.id !== state.client_id) {
                    updatedState.selectedServiceLocation = null
                    updatedState.invoiceData.service_location = null
                    updatedState.invoiceData.date_due = null
                    BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.invoiceData[fieldName] = "")
                }

                // Set the selection, set the data
                updatedState.invoiceData.external_client = selectedClient.id
                updatedState.client_id = selectedClient.id

                // Set the service location if only one exists
                if (selectedClient.service_locations.length == 1) {
                    updatedState.selectedServiceLocation = selectedClient.service_locations[0]
                    updatedState.invoiceData.service_location = updatedState.selectedServiceLocation.id
                    updatedState.service_location_id = updatedState.selectedServiceLocation.id

                    // Update default selected net
                    updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
                    updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
                }

                this.resetInvoiceData(updatedState, state, state.invoiceData)
            }
            else {
                // Client was unset. Unset data
                updatedState.selectedClient = null
                updatedState.selectedServiceLocation = null
                updatedState.priceBookClientOverrides = []
            }

            this.updateDefaultTax(updatedState)

            return updatedState
        })
    }

    updateServiceLocationSelection = (selectedServiceLocation) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedServiceLocation !== null) {
                updatedState.selectedServiceLocation = selectedServiceLocation

                // If a different service location is selected, wipe relevant data
                if (state.service_location_id !== undefined && selectedServiceLocation.id !== state.service_location_id) {
                    updatedState.invoiceData.date_due = null
                    BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => updatedState.invoiceData[fieldName] = "")
                }

                // Set the selection, set the data
                updatedState.invoiceData.service_location = selectedServiceLocation.id
                updatedState.service_location_id = selectedServiceLocation.id

                this.resetInvoiceData(updatedState, state, state.invoiceData)

                // Update default selected net
                updatedState.defaultSelectedNet = this.calculateDefaultSelectedNet(updatedState)
                updatedState.invoiceData.date_due = this.calculateDueDate(state, updatedState.defaultSelectedNet)
            }
            else {
                // Service location was unset. Unset data
                updatedState.selectedServiceLocation = null
            }

            this.updateDefaultTax(updatedState)

            return updatedState
        })
    }

    updatePriceBookServiceSelection = (selectedPriceBookService) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedPriceBookService !== null) {
                updatedState.selectedPriceBookService = selectedPriceBookService
                updatedState.invoiceData.service_name = selectedPriceBookService.description
            }
            else {
                updatedState.selectedPriceBookService = null
                updatedState.invoiceData.service_name = ""
            }

            return updatedState
        })
    }

    updateDefaultTax = (updatedState) => {
        if (this.state.hideTaxRates) {
            if (window.CURRENT_USER.service_company.apideck_tax_strategy === APIDeckTaxStrategies.automatic) {
                updatedState.invoiceData.tax_name = "To Be Calculated"
                updatedState.invoiceData.tax_percent = 0
                updatedState.invoiceData.tax_breakdown = []
                updatedState.invoiceData.tax_quickbooks_desktop_item_id = ""
            }
        }
        else {
            const default_tax_id = (
                updatedState.selectedServiceLocation?.default_pricebook_tax ||
                updatedState.selectedClient?.default_pricebook_tax ||
                window.CURRENT_USER?.service_company?.default_pricebook_tax
            )

            const default_tax = getPricebookTaxById(default_tax_id, this.state.priceBookTaxes)
            this.updatePriceBookTaxSelection(default_tax)
        }
    }

    updatePriceBookTaxSelection = (selectedPriceBookTax, then=null) => {
        this.setState((state, props) => {
            let updatedState = state

            if (selectedPriceBookTax) {
                // Update the pricebook tax list with any changes
                if (getPricebookTaxById(selectedPriceBookTax.id, state.priceBookTaxes)) {
                    const updateIndex = state.priceBookTaxes.findIndex(tax => tax.id === selectedPriceBookTax.id)
                    updatedState.priceBookTaxes[updateIndex] = selectedPriceBookTax
                }
                else {
                    updatedState.priceBookTaxes.push(selectedPriceBookTax)
                }
            }

            // If no return mode is set (i.e. we're not on the pricebook item form, use the current mode)
            const parentMode = state[FORM_DATA_NAMES_BY_MODE[state.mode]].returnMode || state.mode

            if (PRIMARY_FORM_MODES.includes(parentMode)) {
                updatedState.selectedPriceBookTax = selectedPriceBookTax
                updatedState.invoiceData.tax_name = selectedPriceBookTax?.description || ""
                updatedState.invoiceData.tax_percent = selectedPriceBookTax?.default_price || 0
                updatedState.invoiceData.tax_breakdown = []
                updatedState.invoiceData.tax_quickbooks_desktop_item_id = selectedPriceBookTax?.quickbooks_desktop_id || ""
            } else {
                updatedState[FORM_DATA_NAMES_BY_MODE[parentMode]].selectedPriceBookTax = selectedPriceBookTax
                updatedState[FORM_DATA_NAMES_BY_MODE[parentMode]].default_pricebook_tax = selectedPriceBookTax?.id || null

                // If edits were made in the child form to the currently selected tax on the parent form, update the data
                if (selectedPriceBookTax && selectedPriceBookTax.id === updatedState.selectedPriceBookTax?.id) {
                    updatedState.selectedPriceBookTax = selectedPriceBookTax
                    updatedState.invoiceData.tax_name = selectedPriceBookTax.description
                    updatedState.invoiceData.tax_percent = selectedPriceBookTax.default_price
                    updatedState.invoiceData.tax_breakdown = []
                    updatedState.invoiceData.tax_quickbooks_desktop_item_id = selectedPriceBookTax.quickbooks_desktop_id
                }
            }

            return updatedState
        }, then)
    }

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.invoiceData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    calculateDefaultSelectedNet = (state) => {
        let invoiceNetValue = null
        if (valueIsDefined(state.invoiceData.date_due) && valueIsDefined(state.invoiceData.date_issued)) {
            invoiceNetValue = dayjs(state.invoiceData.date_due).diff(dayjs(state.invoiceData.date_issued), "day")
        }
        const defaultServiceLocationNet = state.selectedServiceLocation ? state.selectedServiceLocation.default_invoice_net : null
        const defaultClientNet = state.selectedClient ? state.selectedClient.default_invoice_net : null
        const defaultServiceCompanyNet = state.serviceComapnyDefaultInvoiceNet

        if (valueIsDefined(invoiceNetValue)) {
            return invoiceNetValue
        }
        else if (valueIsDefined(defaultServiceLocationNet)) {
            return defaultServiceLocationNet
        }
        else if (valueIsDefined(defaultClientNet)) {
            return defaultClientNet
        }
        else if (valueIsDefined(defaultServiceCompanyNet)) {
            return defaultServiceCompanyNet
        }
        else {
            return null
        }
    }

    calculateDueDate = (state, net) => {
        if (valueIsDefined(state.invoiceData.date_issued) && valueIsDefined(net)) {
            return dayjs(state.invoiceData.date_issued).add(net, "day").format("YYYY-MM-DD")
        }
        else {
            return null
        }
    }

    getNetChoices = () => {
        const defaultServiceLocationNet = this.state.selectedServiceLocation ? this.state.selectedServiceLocation.default_invoice_net : null
        const defaultClientNet = this.state.selectedClient ? this.state.selectedClient.default_invoice_net : null
        const defaultServiceCompanyNet = this.state.serviceComapnyDefaultInvoiceNet

        let choiceValues = new Set(DEFAULT_NET_VALUES)
        choiceValues.add(this.state.defaultSelectedNet)
        choiceValues.add(defaultServiceLocationNet)
        choiceValues.add(defaultClientNet)
        choiceValues.add(defaultServiceCompanyNet)
        choiceValues = Array.from(choiceValues).filter(net => net != null).sort((a, b) => a - b)

        let choices = [{"value": "", "label": "Choose payment terms..."}]
        choiceValues.map(net => choices.push({"value": net, "label": net !== 0 ? `Net ${net}` : "Same Day"}))

        return choices
    }

    // Crud Line Items

    addCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.invoiceData, this.state.lineItemData, true, this.state.showQuickBooksItemSelect, true, false, false)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData
                data.line_item_type = LINE_ITEM_TYPE_MAP[lineItemListName]

                // Re-use an existing ID for this description to avoid uniqueness constraint issues
                if (data.description in state.deletedLineItemIDs) {
                    data.id = state.deletedLineItemIDs[data.description]
                }
                else {
                    data.id = null
                }

                if (!valueIsDefined(data.cost)) {
                    data.cost = 0
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                if (!valueIsDefined(data.is_taxable)) {
                    data.is_taxable = getIsTaxable(data, this.state.pricebook_default_taxable_service, this.state.pricebook_default_taxable_part, this.state.pricebook_default_taxable_other)
                }

                updatedState.invoiceData[lineItemListName].push(data)
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    lineItemEquals = (existing, updated) => {
        // If the object is new, it won't have an id. Check the description instead
        return existing.id !== null ? existing.id === updated.id : existing.description === updated.originalDescription
    }

    updateCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.invoiceData, this.state.lineItemData, true, this.state.showQuickBooksItemSelect, true, false, false)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData

                if (!valueIsDefined(data.cost)) {
                    data.cost = 0
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                const lineItemIndex = updatedState.invoiceData[lineItemListName].findIndex(lineItem => this.lineItemEquals(lineItem, state.lineItemData))

                updatedState.invoiceData[lineItemListName][lineItemIndex] = data
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    deleteCurrentLineItem = (lineItemListName) => {
        this.setState((state, props) => {
            let updatedState = state

            const data = state.lineItemData

            // Save the ID in case this description is going to be reused
            if (data.id) {
                updatedState.deletedLineItemIDs[data.description] = data.id
            }

            updatedState.invoiceData[lineItemListName] = updatedState.invoiceData[lineItemListName].filter(lineItem => !this.lineItemEquals(lineItem, data))
            updatedState.errors.lineItem = {}
            return updatedState
        })
        this.switchToPrimaryForm()
    }

    // Crud Invoice

    previewInvoice = async => {
        // Create a draft and redirect to the preview page
        const endpoint = DjangoUrls["invoices:api-invoices-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (invoice) => {
            const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${invoice.custom_id || invoice.id}" draft saved`,
                path: successUrl,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice draft could not be saved",
            })
        }
        this.CUDInvoice(endpoint, "POST", onSuccess, onError, true)
    }

    createInvoice = async (isDraft) => {
        const endpoint = DjangoUrls["invoices:api-invoices-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (invoice) => {
            let successUrl

            if (window.CURRENT_USER?.permissions.invoices_list_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["dashboard:dashboard"](window.MARKETPLACE_ENTITY_SLUG)
            } else if (isDraft || window.CURRENT_USER?.permissions.invoices_view_permission < PERMISSION_LEVEL.FULL) {
                successUrl = DjangoUrls["invoices:invoices-list"](window.MARKETPLACE_ENTITY_SLUG)
            }
            else {
                const param = window.CURRENT_USER?.permissions.invoices_edit_permission === PERMISSION_LEVEL.FULL ? "mode=send-invoice" : ""
                successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id) + `?${param}`
            }
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${invoice.custom_id || invoice.id}" ${isDraft ? "draft saved" : "created"}`,
                cta:
                    isDraft && window.CURRENT_USER?.permissions.invoices_view_permission >= PERMISSION_LEVEL.FULL ?
                    {
                        children: "View",
                        destination: DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id)
                    }
                    :
                    undefined,
                path: successUrl.split("?")[0],
                delayRender: true,
            })

            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: `Invoice ${isDraft ? "draft could not be saved" : "could not be created"}`,
            })
        }

        this.CUDInvoice(endpoint, "POST", onSuccess, onError, isDraft)
    }

    updateInvoice = async (resend=false) => {
        const endpoint = DjangoUrls["invoices:api-invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        const successUrl = DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id) + `${resend ? "?mode=send-invoice" : ""}`
        const onSuccess = (invoice) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${invoice.custom_id || invoice.id}" updated`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice could not be updated",
            })
        }
        this.CUDInvoice(endpoint, "PUT", onSuccess, onError, false)
    }

    deleteDraftInvoice = async () => {
        const endpoint = DjangoUrls["invoices:api-invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id)
        let successUrl = DjangoUrls["invoices:invoices-list"](window.MARKETPLACE_ENTITY_SLUG)

        if (valueIsDefined(this.state.invoiceData.job)) {
            successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.job)
        }
        else if (valueIsDefined(this.state.invoiceData.estimate)) {
            successUrl = DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.estimate)
        }

        const onSuccess = (invoice) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Invoice "${this.state.invoiceData.custom_id || this.state.invoiceData.id}" draft deleted`,
                path: successUrl.split("?")[0],
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }

        document.querySelectorAll("#message_modal_confirm_delete_draft .modal__close .button").forEach(button => button.style.display = "none")
        document.querySelector("#message_modal_confirm_delete_draft .modal__close .spinner-centered").style.display = "block"

        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Invoice draft could not be deleted",
            })
            const deleteDraftModal = document.querySelector("#message_modal_confirm_delete_draft .modal__close")
            if (deleteDraftModal) {
                deleteDraftModal.innerHTML = '<span class="text-invalid"><strong>An unexpected error occurred.</strong></span>'
            }
        }

        this.CUDInvoice(endpoint, "DELETE", onSuccess, onError, false)
    }

    CUDInvoice = async (endpoint, endpointMethod, onSuccess, onError, isDraft) => {
        const dataName = "invoiceData"
        const submittingName = "submittingInvoice"
        const errorDictName = "invoice"

        const dataManipulator = (data, state) => {
            data.line_items = [...data.service_charges.map(item => ({...item, cost: item.cost ?? 0})), ...data.parts, ...data.other_charges.map(item => ({...item, cost: item.cost ?? 0})), ...data.discounts.map(item => ({...item, cost: item.cost ?? 0}))]

            // Don't send a billing address if "same as physical address" was selected
            if (!state.useBillingAddress) {
                BILLING_ADDRESS_FIELD_NAMES.forEach(fieldName => delete data[fieldName])
            }

            // Convert blank Invoice ID value to null
            data.custom_id = data.custom_id || null

             // Set a blank label list if there aren't any selected
             data.labels = data.labels || []

            // Ensure accept online payment is sent back to the server
            data.accept_online_payments = data.accept_online_payments || false

            const cleanData = deepcopy()(data)
            cleanData.is_draft = isDraft  // We don't want this to change the actual object
            return cleanData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, custom_id must make a unique set.") {
                errorDict["custom_id"] = "An invoice with this ID already exists."
            }
            else if (fieldName === "line_items_subtotal") {
                errorDict["line_items"] = message
            }
            else if (fieldName === "line_items") {
                errorDict["line_items"] = "Please correct the line item errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state

                    const indexedLineItems = [
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.SERVICE_CHARGES],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.PARTS],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.OTHER_CHARGES],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.DISCOUNTS]
                    ]
                    message.map((lineItemError, index) => indexedLineItems[index].errors = lineItemError)

                    return updatedState
                })
            }
            else if (fieldName === "labels" && Array.isArray(message)) {
                errorDict["labels"] = "Labels must be fewer than 100 characters."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud Client

    createClient = async () => {
        const endpoint = DjangoUrls["clients:api-clients-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const onSuccess = (client) => {
            this.updateClientSelection(client)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${client.name}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be created",
            })
        }

        this.createUpdateClient(endpoint, endpointMethod, onSuccess, onError)
    }

    updateClient = async () => {
        const endpoint = DjangoUrls["clients:api-clients-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        const endpointMethod = "PUT"

        const onSuccess = (client) => {
            this.updateClientSelection(client)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Client "${client.name}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Client could not be updated",
            })
        }

        this.createUpdateClient(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateClient = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "clientData"
        const submittingName = "submittingClient"
        const errorDictName = "client"

        const dataManipulator = (data, state) => {
            data.industry_type = data.industry_type || null  // Convert blank Industry Type value to null
            data.default_invoice_net = data.default_invoice_net !== "" ? data.default_invoice_net : null  // Convert blank net value to null
            data.contacts = data.contacts || []

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A client with this name already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud service location

    createServiceLocation = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-list"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id)
        const endpointMethod = "POST"

        const onSuccess = (serviceLocation) => {
            this.updateServiceLocationSelection(serviceLocation)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "lg",
                title: "Service Location created",
                subtitle: renderServiceLocationString(serviceLocation)
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Location could not be created"
            })
        }

        this.createUpdateServiceLocation(endpoint, endpointMethod, onSuccess, onError)
    }

    updateServiceLocation = async () => {
        const endpoint = DjangoUrls["clients:api-clients-service-locations-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedClient.id, this.state.selectedServiceLocation.id)
        const endpointMethod = "PUT"

        const onSuccess = (serviceLocation) => {
            this.updateServiceLocationSelection(serviceLocation)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "lg",
                title: "Service Location updated",
                subtitle: renderServiceLocationString(serviceLocation)
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service Location could not be updated"
            })
        }

        this.createUpdateServiceLocation(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateServiceLocation = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "serviceLocationData"
        const submittingName = "submittingServiceLocation"
        const errorDictName = "serviceLocation"

        const dataManipulator = (data, state) => {
            data.default_invoice_net = data.default_invoice_net !== "" ? data.default_invoice_net : null  // Convert blank net value to null
            data.contacts = data.contacts || []
            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields external_client, name, physical_address_formatted must make a unique set.") {
                errorDict["physical_address_street"] = "A service location with this name and address already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Crud PriceBook Items

    createPriceBookService = async (isDraft) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG)

        const onSuccess = (priceBookItem) => {
            this.updatePriceBookServiceSelection(priceBookItem)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Service "${priceBookItem.description}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service could not be created"
            })
        }

        this.CUDPriceBookItem(endpoint, "POST", onSuccess, onError)
    }

    updatePriceBookService = async () => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.selectedPriceBookService.id)

        const onSuccess = (priceBookItem) => {
            this.updatePriceBookServiceSelection(priceBookItem)
            this.switchToPrimaryForm()
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Service "${priceBookItem.description}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Service could not be updated"
            })
        }

        this.CUDPriceBookItem(endpoint, "PUT", onSuccess, onError)
    }

    createPriceBookTax = async (isDraft) => {
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-list"](window.MARKETPLACE_ENTITY_SLUG)

        const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

        const onSuccess = (priceBookItem) => {
            const switchToForm = () => {
                this.switchToPrimaryForm()

                if (CLIENT_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.clientData), null)
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
            }
            this.updatePriceBookTaxSelection(priceBookItem, switchToForm)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Tax Rate "${priceBookItem.description}" created`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Tax Rate could not be created"
            })
        }

        this.CUDPriceBookItem(endpoint, "POST", onSuccess, onError)
    }

    updatePriceBookTax = async () => {
        const priceBookItemID = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].id
        const endpoint = DjangoUrls["pricebook:api-pricebookitem-detail"](window.MARKETPLACE_ENTITY_SLUG, priceBookItemID)

        const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

        const onSuccess = (priceBookItem) => {
            const switchToForm = () => {
                this.switchToPrimaryForm()

                if (CLIENT_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.clientData), null)
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
            }
            this.updatePriceBookTaxSelection(priceBookItem, switchToForm)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Tax Rate "${priceBookItem.description}" updated`,
            })
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Tax Rate could not be updated"
            })
        }

        this.CUDPriceBookItem(endpoint, "PUT", onSuccess, onError)
    }

    CUDPriceBookItem = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "priceBookItemData"
        const submittingName = "submittingPriceBookItem"
        const errorDictName = "priceBookItem"

        const dataManipulator = (data, state) => {
            let finalData = deepcopy()(data)
            finalData.confirmed = true

            // If this isn't a service charge, remove service-charge-specific data
            if (state.priceBookItemData.pricebook_item_type !== PriceBookItemTypes.service) {
                delete finalData.default_unit_type
                delete finalData.expected_job_duration
            }

            return finalData
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields description, service_company must make a unique set.") {
                errorDict["description"] = "A PriceBook item with this name already exists."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "INVOICE_PREVIEW":
                this.previewInvoice()
                break
            case "INVOICE_CREATE":
                this.createInvoice(false)
                break
            case "INVOICE_CREATE_DRAFT":
                this.createInvoice(true)
                break
            case "INVOICE_UPDATE":
                this.updateInvoice(false)
                break
            case "INVOICE_UPDATE_AND_RESEND":
                this.updateInvoice(true)
                break
            case "INVOICE_DELETE_DRAFT":
                window.deleteDraftInvoice = this.deleteDraftInvoice
                document.querySelector("#message_modal_confirm_delete_draft").style.display = ""
                window.MicroModal.show("message_modal_confirm_delete_draft")
                break
            case "INVOICE_CANCEL_EDITS":
                location.assign(DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.invoiceData.id))
                break
            case "CLIENT_CREATE":
                this.createClient()
                break
            case "CLIENT_UPDATE":
                this.updateClient()
                break
            case "CLIENT_CANCEL_CREATE":
                this.switchToPrimaryForm()
                break
            case "CLIENT_CANCEL_EDITS":
                this.switchToPrimaryForm()
                break
            case "SERVICE_LOCATION_CREATE":
                this.createServiceLocation()
                break
            case "SERVICE_LOCATION_UPDATE":
                this.updateServiceLocation()
                break
            case "PRICEBOOK_SERVICE_CREATE":
                this.createPriceBookService()
                break
            case "PRICEBOOK_SERVICE_UPDATE":
                this.updatePriceBookService()
                break
            case "PRICEBOOK_TAX_CREATE":
                this.createPriceBookTax()
                break
            case "PRICEBOOK_TAX_UPDATE":
                this.updatePriceBookTax()
                break
            case "PRICEBOOK_TAX_CANCEL_CREATE":
                const returnMode = this.state[FORM_DATA_NAMES_BY_MODE[this.state.mode]].returnMode

                this.switchToPrimaryForm()

                if (CLIENT_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.clientData), null)
                }
                else if (SERVICE_LOCATION_FORM_MODES.includes(returnMode)) {
                    this.switchToSecondaryForm(returnMode, deepcopy()(this.state.serviceLocationData), null)
                }
                break
            case "ADD_SERVICE_CHARGE":
                this.addCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "EDIT_SERVICE_CHARGE":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "DELETE_SERVICE_CHARGE":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "ADD_PART":
                this.addCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "EDIT_PART":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "DELETE_PART":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "ADD_OTHER_CHARGE":
                this.addCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "EDIT_OTHER_CHARGE":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "DELETE_OTHER_CHARGE":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "ADD_DISCOUNT":
                this.addCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "EDIT_DISCOUNT":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "DELETE_DISCOUNT":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "TOGGLE_USE_BILLING_ADDRESS":
                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.useBillingAddress = !state.useBillingAddress
                    return updatedState
                })
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    foldDataComplete = () => {
        const noBillingAddress = BILLING_ADDRESS_FIELD_NAMES.every(fieldName => !valueIsDefined(this.state.invoiceData[fieldName]))

        const serviceName = Boolean(this.state.invoiceData.service_name)
        const billingAddressValid = this.state.useBillingAddress === false || (this.state.useBillingAddress === true && !noBillingAddress)
        const dateIssuedValid = Boolean(this.state.invoiceData.date_issued)
        const dateDueValid = Boolean(this.state.invoiceData.date_due)
        const invoiceNumberValid = this.state.showCustomInvoiceIDField === false || (this.state.showCustomInvoiceIDField === true && Boolean(this.state.invoiceData.custom_id))

        return serviceName && billingAddressValid && dateIssuedValid && dateDueValid && invoiceNumberValid
    }

    render() {
        if (this.state.invoiceData === null || this.state.jobData === null || this.state.estimateData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return <InvoiceForm
                    mode={this.state.mode}
                    submitting={this.state.submittingInvoice}
                    invoice={this.state.invoiceData}
                    job={this.state.jobData}
                    estimate={this.state.estimateData}
                    errors={this.state.errors.invoice}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("invoiceData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    updateClientSelection={this.updateClientSelection}
                    updateServiceLocationSelection={this.updateServiceLocationSelection}
                    updatePriceBookServiceSelection={this.updatePriceBookServiceSelection}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    selectedClient={this.state.selectedClient}
                    selectedServiceLocation={this.state.selectedServiceLocation}
                    selectedPriceBookService={this.state.selectedPriceBookService}
                    selectedPriceBookTax={this.state.selectedPriceBookTax}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    showCustomInvoiceIDField={this.state.showCustomInvoiceIDField}
                    useBillingAddress={this.state.useBillingAddress}
                    foldDataComplete={this.foldDataComplete()}
                    showQuickBooksItemSelect={this.state.showQuickBooksItemSelect}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    useTaxes={this.state.useTaxes}
                    hideTaxRates={this.state.hideTaxRates}
                    defaultSelectedNet={this.state.defaultSelectedNet}
                    netChoices={this.getNetChoices()}
                    fileStackAPIKey={this.state.fileStackAPIKey}
                    fileStackPolicy={this.state.fileStackPolicy}
                    fileStackSignature={this.state.fileStackSignature}
                    updateAttachments={this.updateAttachments}
                    returnScroll={this.state.returnScroll}
                ></InvoiceForm>
            }
            else if (CLIENT_FORM_MODES.includes(this.state.mode)) {
                return <ClientForm
                    mode={this.state.mode}
                    submitting={this.state.submittingClient}
                    client={this.state.clientData}
                    errors={this.state.errors.client}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("clientData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    showServiceLocationSelect={false}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    defaultClientType={this.state.defaultClientType}
                    useTaxes={this.state.useTaxes}
                    priceBookTaxes={this.state.priceBookTaxes}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    selectedPriceBookTax={(
                        this.state.clientData.selectedPriceBookTax ||
                        (this.state.clientData.default_pricebook_tax && getPricebookTaxById(this.state.clientData.default_pricebook_tax, this.state.priceBookTaxes))
                    )}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    returnScroll={0}
                />
            }
            else if (SERVICE_LOCATION_FORM_MODES.includes(this.state.mode)) {
                return <ServiceLocationForm
                    mode={this.state.mode}
                    submitting={this.state.submittingServiceLocation}
                    client={this.state.selectedClient}
                    serviceLocation={this.state.serviceLocationData}
                    errors={this.state.errors.serviceLocation}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("serviceLocationData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    switchToSecondaryForm={this.switchToSecondaryForm}
                    defaultCountryCode={this.state.phoneNumberCountry}
                    useTaxes={this.state.useTaxes}
                    showTaxCreateButton={this.state.showTaxCreateButton}
                    selectedPriceBookTax={(
                        this.state.serviceLocationData.selectedPriceBookTax ||
                        (this.state.serviceLocationData.default_pricebook_tax && getPricebookTaxById(this.state.serviceLocationData.default_pricebook_tax, this.state.priceBookTaxes))
                    )}
                    updatePriceBookTaxSelection={this.updatePriceBookTaxSelection}
                    returnScroll={0}
                />
            }
            else if (PRICEBOOK_ITEM_FORM_MODES.includes(this.state.mode)) {
                return <PriceBookItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingPriceBookItem}
                    priceBookItem={this.state.priceBookItemData}
                    errors={this.state.errors.priceBookItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("priceBookItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    showQuickBooksRevenueAccountSelect={this.state.showQuickBooksRevenueAccountSelect}
                    showQuickBooksTaxAgencyVendorSelect={this.state.showQuickBooksTaxAgencyVendorSelect}
                    useTaxes={this.state.useTaxes}
                    pricebookDefaultTaxableService={this.state.pricebook_default_taxable_service}
                    pricebookDefaultTaxablePart={this.state.pricebook_default_taxable_part}
                    pricebookDefaultTaxableOther={this.state.pricebook_default_taxable_other}
                    returnScroll={0}
                ></PriceBookItemForm>
            }
            else if (LINE_ITEM_FORM_MODES.includes(this.state.mode)) {
                return <LineItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingLineItem}
                    parent={this.state.invoiceData}
                    lineItem={this.state.lineItemData}
                    priceBookClientOverrides={this.state.priceBookClientOverrides}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    errors={this.state.errors.lineItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("lineItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                    objectName={"Invoice"}
                    preferredTimezone={this.state.preferredTimezone}
                    isInvoiceLineItem={true}
                    estimatedDuration={this.state.jobData.estimated_duration}
                    totalTimeLogged={this.state.jobData.total_time_logged}
                    showQuickBooksItemSelect={this.state.showQuickBooksItemSelect}
                    useTaxes={this.state.useTaxes}
                    pricebookDefaultTaxableService={this.state.pricebook_default_taxable_service}
                    pricebookDefaultTaxablePart={this.state.pricebook_default_taxable_part}
                    pricebookDefaultTaxableOther={this.state.pricebook_default_taxable_other}
                ></LineItemForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default InvoiceCreateContainer;
