// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineViewMenuPopover-content__YXYQJ{display:flex;flex-direction:column;align-items:flex-start;align-self:stretch;padding:var(--size-3)}.TimelineViewMenuPopover-item__vAiwy{padding:var(--size-1_5) var(--size-1) var(--size-1_5) 0}.TimelineViewMenuPopover-itemLabel__p7VAa{font:var(--font-global-body-375);font-feature-settings:var(--feature-settings-global-body-375);letter-spacing:var(--letter-spacing-global-body-375)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "TimelineViewMenuPopover-content__YXYQJ",
	"item": "TimelineViewMenuPopover-item__vAiwy",
	"itemLabel": "TimelineViewMenuPopover-itemLabel__p7VAa"
};
export default ___CSS_LOADER_EXPORT___;
