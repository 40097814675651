import clsx from "clsx"
import { useMemo } from "react"

import { Icon, Tooltip } from "@atoms"
import { IconSize } from "@atoms/Icon/Icon.types"

import styles from "./Badge.module.scss"
import { BadgeDefaultProps, BadgeProps } from "./Badge.types"

export default function Badge(props: BadgeProps) {
    const { children, colorScheme, size, variant, weight = "medium", type = "default", showTooltip = false } = props

    const iconSize = useMemo(() => {
        const sizeMap: { [key in BadgeProps["size"]]: IconSize } = {
            sm: 10,
            md: 12,
            lg: 14,
        }
        return sizeMap[size]
    }, [size])

    let statusDot: BadgeDefaultProps["statusDot"]
    let leftIcon: BadgeDefaultProps["leftIcon"]
    let rightIcon: BadgeDefaultProps["rightIcon"]

    if (type === "default") {
        // Destructure icons only for 'default' type
        statusDot = (props as BadgeDefaultProps).statusDot
        leftIcon = (props as BadgeDefaultProps).leftIcon
        rightIcon = (props as BadgeDefaultProps).rightIcon
    }

    return (
        <div
            className={clsx(
                styles.base,
                styles[`${type}Type`],
                styles[`${colorScheme}ColorScheme`],
                styles[`${variant}Variant`],
                styles[`${size}Size`],
                styles[`${weight}Weight`],
            )}
        >
            {statusDot && (
                <span className={clsx(styles.statusDotContainerBase, styles[`${size}StatusDotContainer`])}>
                    {statusDot}
                </span>
            )}
            {leftIcon && <Icon icon={leftIcon} size={iconSize} />}
            <Tooltip label={showTooltip ? children : undefined}>
                <div className={styles.content}>{children}</div>
            </Tooltip>
            {rightIcon && <Icon icon={rightIcon} size={iconSize} />}
        </div>
    )
}
