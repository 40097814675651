import { Icon } from "@atoms/index";
import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import PDFDownloadDialog from "@legacy/pricebook/components/PDFDownloadDialog";
import AccessCheck from "@particles/AccessCheck/AccessCheck";
import formatDuration from "@utils/formatDuration";
import { getPriorityIcon, getPriorityLabel } from "@utils/priority";
import { Component, Fragment } from "react";
import { renderServiceLocationString } from "../../clients/utils/utils";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import AttachmentHorizontalDisplay from "../../core/components/AttachmentHorizontalDisplay";
import ClientInformationDisplay from "../../core/components/ClientInformationDisplay";
import ContactListDisplay from "../../core/components/ContactListDisplay";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import LinkedObjectsDisplayField from "../../core/fields/LinkedObjectsDisplayField";
import ShowMoreTextRenderer from "../../core/renderers/ShowMoreTextRenderer";
import { JobOriginTypes } from "../../core/utils/enums";
import { format_phone_number_with_extension, renderInvoiceEstimateDate, valueIsDefined } from "../../core/utils/utils";
import EquipmentDetailsAccordion from "../../equipment/components/EquipmentDetailsAccordion";
import EstimateObjectLink from "../../estimates/components/EstimateObjectLink";
import InvoiceObjectLink from "../../invoices/components/InvoiceObjectLink";
import JobObjectLink from "../../jobs/components/JobObjectLink";
import PurchaseOrderObjectLink from "../../purchase_orders/components/PurchaseOrderObjectLink";
import { getJobStatusBarLabel, getJobStatusColorClass, renderJobTime } from "../utils/utils";
import JobFeedAccordion from "./JobFeedAccordion";
import JobHistoryAccordion from "./JobHistoryAccordion";
import JobSummaryAccordion from "./JobSummaryAccordion";


class JobDetailsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPDFDialogOpen: false,
        };
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderResumeDraftButton = (childObjects, buttonType, endpointName, buttonText) => {
        return childObjects.filter(object => object.is_draft).map(object => (
            <ButtonGroupRow>
                <UniversalButton type={buttonType} text={buttonText} handler={event => location.assign(DjangoUrls[endpointName](window.MARKETPLACE_ENTITY_SLUG, object.id))} />
            </ButtonGroupRow>
        )).shift()
    }

    handlePDFDownload = (selectedObjects, isPublicVersion) => {
        let url = window.PUBLIC_PDF_URL;
        const includeEstimate = selectedObjects?.includes("Estimate") ? "true" : "false";
        const includeJob = selectedObjects?.includes("Job") ? "true" : "false";

        if (selectedObjects?.length === 0 || selectedObjects === undefined && !isPublicVersion) {
            location.assign(url);
        } else {
            url = `${window.PUBLIC_PDF_URL}?include_job=${includeJob}&include_estimate=${includeEstimate}&public_version=${isPublicVersion ? "true" : "false"}`;
            location.assign(url);
        }

        this.setState({ isPDFDialogOpen: false });
    };

    renderButtons = () => {
        const { job, requestAction, switchToSecondaryForm, childEstimates, childInvoices, childCallbacks, purchaseOrders, clockInStatus } = this.props

        const handlePDFDialog = () => {
            const { job } = this.props
            job.estimate
            ?
            this.setState({ isPDFDialogOpen: !this.state.isPDFDialogOpen })
            :
            this.handlePDFDownload()
        };

        if (window.USING_PUBLIC_URL === true) {
            return (
                <ButtonGroup>
                    <ButtonGroupRow>
                        <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                    </ButtonGroupRow>
                </ButtonGroup>
            )
        }
        else if (!window.CURRENT_USER?.permissions.restricted_to_assigned_jobs || window.USER_IS_ASSIGNED_TECHNICIAN === true) {
            if (job.state_label === "Pending") {
                    if (job.status_label === "Unscheduled") {
                        return (
                            <ButtonGroup>
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="primary" text="Schedule Job" handler={event => requestAction("JOB_SCHEDULE")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                                {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && clockInStatus.can_clock_out && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                                    </ButtonGroupRow>
                                )}
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    {
                                        window.FILE_UPLOADS_ENABLED && (
                                            <ButtonGroupRow>
                                                <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                            </ButtonGroupRow>
                                        )
                                    }
                                </AccessCheck>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                                    <AccessCheck permissions={{
                                        jobs_delete_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <UniversalButton type="danger" text="Cancel Job" handler={event => requestAction("JOB_CANCEL")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                    }
                    else {
                        return (
                            <ButtonGroup>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <UniversalButton type="secondary" text="Edit Draft" handler={event => requestAction("JOB_EDIT")} />
                                    </AccessCheck>
                                </ButtonGroupRow>
                            </ButtonGroup>
                        )
                }
            }
            else if (job.state_label === "Scheduled") {
                return (
                    <ButtonGroup>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text={job.initial_started_time !== null ? "Resume Job" : "Start Job"} handler={event => requestAction("JOB_START")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && clockInStatus.can_clock_out && (
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                            </ButtonGroupRow>
                        )}
                        {
                            <Fragment>
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                    jobs_delete_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                        }}>
                                            <UniversalButton type="secondary" text="Edit Job" handler={event => requestAction("JOB_EDIT")} />
                                        </AccessCheck>
                                        <AccessCheck permissions={{
                                            jobs_delete_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <UniversalButton type="danger" text="Cancel Job" handler={event => requestAction("JOB_CANCEL")} />
                                        </AccessCheck>
                                    </ButtonGroupRow>
                                </AccessCheck>
                                {
                                    job.initial_started_time !== null && <>
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                    </>
                                }
                                {
                                    window.FILE_UPLOADS_ENABLED && (
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                    )
                                }
                                {
                                    job.initial_started_time === null && (
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type="secondary" text="Unschedule Job" handler={event => requestAction("JOB_UNSCHEDULE")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                    )
                                }
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                                </ButtonGroupRow>
                            </Fragment>
                        }
                    </ButtonGroup>
                )
            }
            else if (job.status_label === "Active") {
                return (
                    <ButtonGroup>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Complete Job" handler={event => requestAction("JOB_COMPLETE")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <ButtonGroupRow>
                            <AccessCheck permissions={{
                                jobs_edit_permission: PERMISSION_LEVEL.FULL,
                            }}>
                                <UniversalButton type="secondary" text="Reschedule Job" handler={event => requestAction("JOB_RESCHEDULE")} />
                            </AccessCheck>
                            <AccessCheck permissions={{
                                jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                            }}>
                                <UniversalButton type="secondary" text="Pause Job" handler={event => requestAction("JOB_PAUSE")} />
                            </AccessCheck>
                        </ButtonGroupRow>
                        {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && (
                            <ButtonGroupRow>
                                {(clockInStatus.can_clock_in || !clockInStatus.can_clock_out) && window.USER_IS_ASSIGNED_TECHNICIAN === true && (
                                    <UniversalButton type="secondary" text="Clock In" handler={event => requestAction("JOB_CLOCK_IN")} />
                                )}
                                {clockInStatus.can_clock_out && (
                                    <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                                )}
                            </ButtonGroupRow>
                        )}
                        {
                            window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.ACTIVE_SUBSTATUSES_EXIST && (
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Change Active Status" handler={event => requestAction("JOB_RESUME")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            )
                        }
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        {
                            window.FILE_UPLOADS_ENABLED && (
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
            else if (job.status_label === "Quick-Paused" || job.status_label === "On Hold") {
                return (
                    <ButtonGroup>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Resume Job" handler={event => requestAction("JOB_RESUME")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && (
                            <ButtonGroupRow>
                                {(clockInStatus.can_clock_in || !clockInStatus.can_clock_out) && window.USER_IS_ASSIGNED_TECHNICIAN === true && (
                                    <UniversalButton type="secondary" text="Clock In" handler={event => requestAction("JOB_CLOCK_IN")} />
                                )}
                                {clockInStatus.can_clock_out && (
                                    <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                                )}
                            </ButtonGroupRow>
                        )}
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Reschedule Job" handler={event => requestAction("JOB_RESCHEDULE")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Change Pause Status" handler={event => requestAction("JOB_PAUSE")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                        }}>
                            {
                                job.status_label === "On Hold" && window.JOB_SEND_ENABLED && (
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Send Job Summary" handler={event => requestAction("JOB_SEND")} />
                                    </ButtonGroupRow>
                                )
                            }
                        </AccessCheck>
                        <AccessCheck permissions={{
                            jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                            </ButtonGroupRow>
                        </AccessCheck>
                        {
                            window.FILE_UPLOADS_ENABLED && (
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            )
                        }
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
            else if (job.state_label === "Completed") {

                if (job.is_job_walk) {
                    return (
                        <ButtonGroup>
                            {
                                childEstimates.length === 0 && (
                                    <Fragment>
                                        <AccessCheck entitlements={["entitlement_estimates_enabled"]} permissions={{
                                            estimates_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type="primary" text="Create Estimate" handler={event => requestAction("JOB_CREATE_ESTIMATE")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton
                                                    type={
                                                        window.CURRENT_USER?.permissions.estimates_create_permission < PERMISSION_LEVEL.RESTRICTED ||
                                                        !window.CURRENT_USER?.service_company.entitlement_estimates_enabled
                                                        ? "primary"
                                                        : "secondary"
                                                    }
                                                    text="Mark as Estimated"
                                                    handler={event => requestAction("JOB_MARK_AS_ESTIMATED")}
                                                />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                    </Fragment>
                                )
                            }
                                <AccessCheck entitlements={["entitlement_estimates_enabled"]} permissions={{
                                    estimates_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    {this.renderResumeDraftButton(childEstimates, "primary", "estimates:estimates-update", "Resume Draft Estimate")}
                                </AccessCheck>
                            {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && clockInStatus.can_clock_out && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                                </ButtonGroupRow>
                            )}
                            {
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            }
                            <AccessCheck permissions={{
                                jobs_edit_permission: PERMISSION_LEVEL.FULL,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            {
                                window.JOB_SEND_ENABLED && (
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Resend Job Summary" handler={event => requestAction("JOB_SEND")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                            <AccessCheck permissions={{
                                jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            {
                                window.FILE_UPLOADS_ENABLED && (
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                            {
                                childEstimates.length === 0 && (
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Resume Job" handler={event => requestAction("JOB_RESUME_FROM_COMPLETED")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else {
                    return (
                        <ButtonGroup>
                            {
                                childInvoices.length === 0 && (
                                    <Fragment>
                                        <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={{
                                            invoices_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton type="primary" text="Create Invoice" handler={event => requestAction("JOB_CREATE_INVOICE")} />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                        <AccessCheck permissions={{
                                            jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                        }}>
                                            <ButtonGroupRow>
                                                <UniversalButton
                                                    type={
                                                        window.CURRENT_USER?.permissions.invoices_create_permission < PERMISSION_LEVEL.RESTRICTED ||
                                                        !window.CURRENT_USER?.service_company.entitlement_invoices_enabled
                                                        ? "primary"
                                                        : "secondary"
                                                    }
                                                    text="Mark as Invoiced"
                                                    handler={event => requestAction("JOB_MARK_AS_INVOICED")}
                                                />
                                            </ButtonGroupRow>
                                        </AccessCheck>
                                    </Fragment>
                                )
                            }
                            <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={{
                                invoices_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                            }}>
                                {this.renderResumeDraftButton(childInvoices, "primary", "invoices:invoices-update", "Resume Draft Invoice")}
                            </AccessCheck>
                            {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && clockInStatus.can_clock_out && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                                </ButtonGroupRow>
                            )}
                            {
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Edit Equipment" handler={event => requestAction("EQUIPMENT_EDIT")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            }
                            <AccessCheck permissions={{
                                jobs_edit_permission: PERMISSION_LEVEL.FULL,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Job Details" handler={event => requestAction("JOB_EDIT_DETAILS")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            <AccessCheck permissions={{
                                jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                            }}>
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Edit Job Summary" handler={event => requestAction("JOB_EDIT_SUMMARY")} />
                                </ButtonGroupRow>
                            </AccessCheck>
                            {
                                window.JOB_SEND_ENABLED && (
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Resend Job Summary" handler={event => requestAction("JOB_SEND")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                            {
                                window.FILE_UPLOADS_ENABLED && (
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Update Attachments" handler={event => requestAction("UPLOAD_ATTACHMENTS")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                            {
                                childInvoices.length === 0 && (
                                    <AccessCheck permissions={{
                                        jobs_edit_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <ButtonGroupRow>
                                            <UniversalButton type="secondary" text="Resume Job" handler={event => requestAction("JOB_RESUME_FROM_COMPLETED")} />
                                        </ButtonGroupRow>
                                    </AccessCheck>
                                )
                            }
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                                    </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
            else if (job.state_label === "Finalized") {
                return (
                    <ButtonGroup>
                        {
                            !job.is_job_walk && childCallbacks.length === 0 && (
                                <AccessCheck permissions={{
                                    jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Create Callback" handler={event => requestAction("JOB_CREATE_CALLBACK")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            )
                        }
                        {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && clockInStatus.can_clock_out && (
                            <ButtonGroupRow>
                                <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                            </ButtonGroupRow>
                        )}
                        {
                            window.JOB_SEND_ENABLED && (
                                <AccessCheck permissions={{
                                    jobs_edit_permission: PERMISSION_LEVEL.FULL,
                                }}>
                                    <ButtonGroupRow>
                                        <UniversalButton type="secondary" text="Resend Job Summary" handler={event => requestAction("JOB_SEND")} />
                                    </ButtonGroupRow>
                                </AccessCheck>
                            )
                        }
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                                </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
            else if (job.state_label === "Cancelled") {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            {window.CURRENT_USER?.service_company.feature_timesheets_enabled && window.CURRENT_USER?.permissions.is_working && clockInStatus && clockInStatus.can_clock_out && (
                                <ButtonGroupRow>
                                    <UniversalButton type="secondary" text="Clock Out" handler={event => requestAction("JOB_CLOCK_OUT")} />
                                </ButtonGroupRow>
                            )}
                            <UniversalButton type="secondary" text="Download PDF" handler={handlePDFDialog} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { job, requestAction, switchToSecondaryForm, childInvoices, childEstimates, childCallbacks, purchaseOrders, addToastToQueue } = this.props

        const showServiceSummary = (
            job.service_charges.length > 0 &&
            job.initial_started_time !== null &&
            !["Completed", "Finalized", "Cancelled"].includes(job.state_label)
        )

        return (
            <Fragment>
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className={`data-panel-status-bar ${getJobStatusColorClass(job)}`} aria-label="Status"><span>{getJobStatusBarLabel(job)}{(window.USING_PUBLIC_URL === true && " Job")}</span></div>
                    <div className="data-panel with-status-bar" aria-label="Job Details">
                        <ClientInformationDisplay
                            client={job.service_location.external_client}
                            serviceLocation={job.service_location}
                            showOpenInMapsButton={job.service_location.physical_address_geocode_formatted && !window.USING_PUBLIC_URL}
                            separatorClass={getJobStatusColorClass(job)}
                        ></ClientInformationDisplay>
                        <div className="data-panel__form" aria-label="Job Details">
                            {
                                job.estimate !== null && !window.CURRENT_USER?.permissions.cannot_view_job_financials && (
                                    <AccessCheck entitlements={["entitlement_estimates_enabled"]}>
                                        <LinkedObjectsDisplayField
                                            fieldName="estimate"
                                            fieldLabel="Created From Estimate"
                                            objectComponentList={[
                                                <EstimateObjectLink
                                                    key="parent_estimate_0"
                                                    estimate={job.estimate}
                                                    destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_ESTIMATE_URL : DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, job.estimate.id)}
                                                    disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.estimates_view_permission < PERMISSION_LEVEL.FULL}
                                                />
                                            ]}
                                        ></LinkedObjectsDisplayField>
                                    </AccessCheck>
                                )
                            }
                            {
                                job.is_callback && (
                                    <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                                        <LinkedObjectsDisplayField
                                            fieldName="callback_to"
                                            fieldLabel="Callback To"
                                            objectComponentList={[
                                                <JobObjectLink
                                                    key="callback_to_0"
                                                    job={job.callback_to}
                                                    destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_CALLBACK_TO_URL : DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, job.callback_to.id)}
                                                    disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.jobs_view_permission < PERMISSION_LEVEL.FULL}
                                                />
                                            ]}
                                        ></LinkedObjectsDisplayField>
                                    </AccessCheck>
                                )
                            }
                            <BasicDisplayField
                                fieldName="id"
                                fieldLabel="Roopairs Job ID"
                                fieldValue={job.custom_id ? job.custom_id : job.id}
                            ></BasicDisplayField>
                            {
                                job.origin_type !== JobOriginTypes.roopairs && (
                                    <BasicDisplayField
                                        fieldName="origin_id"
                                        fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                        fieldValue={job.origin_id}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="date_received"
                                fieldLabel="Date Received"
                                fieldValue={renderInvoiceEstimateDate(job.date_received, job.service_company.preferred_timezone)}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="service_name"
                                fieldLabel="Service Type"
                                fieldValue={job.service_name}
                            ></BasicDisplayField>
                            <BasicDisplayField
                                fieldName="is_job_walk"
                                fieldLabel="Job Type"
                                fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                            ></BasicDisplayField>
                            {
                                window.USING_PUBLIC_URL === true && (
                                    <BasicDisplayField
                                        fieldName="service_company"
                                        fieldLabel="Service Company"
                                        fieldValue={job.service_company.name}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="service_location"
                                fieldLabel="Service Location"
                                fieldValue={renderServiceLocationString(job.service_location, true)}
                            ></BasicDisplayField>
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && valueIsDefined(job.service_location.notes) && !window.CURRENT_USER?.permissions.cannot_view_job_location_notes && (
                                    <BasicDisplayField
                                        fieldName="service_location_notes"
                                        fieldLabel={<Fragment>Service Location Notes <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.service_location.notes}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.service_location.external_client && valueIsDefined(job.service_location.external_client.notes) && !window.CURRENT_USER?.permissions.cannot_view_job_client_notes && (
                                    <BasicDisplayField
                                        fieldName="client_notes"
                                        fieldLabel={<Fragment>Client Notes <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.service_location.external_client.notes}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.estimated_arrival_time && (
                                    <BasicDisplayField
                                        fieldName="estimated_arrival_time"
                                        fieldLabel="Scheduled For"
                                        fieldValue={renderJobTime(job.estimated_arrival_time, job.service_company.preferred_timezone, job.state_label === "Scheduled")}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.initial_started_time && (
                                    <BasicDisplayField
                                        fieldName="initial_started_time"
                                        fieldLabel="Started On"
                                        fieldValue={renderJobTime(job.initial_started_time, job.service_company.preferred_timezone)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                showServiceSummary && (
                                    <div className="data-panel__form__field" aria-label="Service Summary To Date">
                                        <span className="data-panel__form__field__label" aria-hidden="true">Service Summary To Date</span>
                                        <div className="data-panel__form__field__display-list" aria-label="Service Summary">
                                            {
                                                job.service_charges.map((item) => (
                                                    <div key={item.id} className="data-panel__form__field__display-list__object line-item-display">
                                                        <div className="line-item-display__row">
                                                            <div className="line-item-display__field">
                                                                <div className="line-item-display__field-label">Short Description</div>
                                                                <div className="line-item-display__field-value">
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="line-item-display__row">
                                                            <div className="line-item-display__field">
                                                                <div className="line-item-display__field-label">Service Summary</div>
                                                                <div className="line-item-display__field-value preserve-whitespace">
                                                                    <ShowMoreTextRenderer text={item.summary} startClamped={window.USING_PUBLIC_URL === false}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                            {
                                job.last_status_transition_time !== null && (
                                    <BasicDisplayField
                                        fieldName="last_status_transition_time"
                                        fieldLabel={`${["Active", "Quick-Paused"].includes(job.status_label) ? job.status_label : job.state_label} On`}
                                        fieldValue={renderJobTime(job.last_status_transition_time, job.service_company.preferred_timezone)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.substatus && (
                                    <BasicDisplayField
                                        fieldName="substatus_label"
                                        fieldLabel={`${["Active", "Quick-Paused"].includes(job.status_label) ? job.status_label : job.state_label} SubStatus`}
                                        fieldValue={job.substatus_label}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.last_status_transition_message !== "" && (
                                    <BasicDisplayField
                                        fieldName="last_status_transition_message"
                                        fieldLabel={`${["Active", "Quick-Paused"].includes(job.status_label) ? job.status_label : job.state_label} Message`}
                                        fieldValue={job.substatus_reason_label ? <span><i>{job.substatus_reason_label}</i><br />{job.last_status_transition_message}</span> : job.last_status_transition_message}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="last_modified_at"
                                fieldLabel="Last Modified"
                                fieldValue={renderJobTime(job.last_modified_at, job.service_company.preferred_timezone)}
                            ></BasicDisplayField>
                            {
                                job.estimated_duration && !(window.USING_PUBLIC_URL === true) && (
                                    <BasicDisplayField
                                        fieldName="estimated_duration"
                                        fieldLabel="Estimated Job Duration"
                                        fieldValue={formatDuration(job.estimated_duration)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.assigned_technicians.length !== 0 && (
                                    <BasicDisplayField
                                        fieldName="assigned_technicians"
                                        fieldLabel="Assigned Technician(s)"
                                        fieldValue={job.assigned_technicians.map((technician, index) => <span key={index}>{technician.full_name}{job.assigned_technicians.length !== index + 1 && <br />}</span>)}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="details"
                                fieldLabel={job.is_callback ? "Reason For Callback" : "Reason For Call"}
                                fieldValue={job.details || "--"}
                            ></BasicDisplayField>
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.dispatcher_notes !== "" && (
                                    <BasicDisplayField
                                        fieldName="dispatcher_notes"
                                        fieldLabel={<Fragment>Technician Instructions <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.dispatcher_notes}
                                    ></BasicDisplayField>
                                )
                            }
                            <BasicDisplayField
                                fieldName="priority"
                                fieldLabel="Priority"
                                fieldValue={
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <Icon
                                            icon={getPriorityIcon(job.priority)}
                                            size={10}
                                            containerClassName={job.priority === 1 ? "text-alerting" : ""}
                                        />
                                        {getPriorityLabel(job.priority)}
                                    </div>
                                }

                            ></BasicDisplayField>
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.labels.length !== 0 && (
                                    <BasicDisplayField
                                        fieldName="labels"
                                        fieldLabel={<Fragment>Labels <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={job.labels.map((label, index) => <span key={`label_${index}`} className="data-tag">{label.name}</span>)}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                window.MARKETPLACE_ENTITY_SLUG === job.service_company.slug && job.companycam_project_url !== "" && (
                                    <BasicDisplayField
                                        fieldName="companycam_project_url"
                                        fieldLabel={<Fragment>CompanyCam <span className="text-optional">(Only Visible to You)</span></Fragment>}
                                        fieldValue={<a className="text-link text-link--inline" href={job.companycam_project_url} target="_blank">Open CompanyCam Project&nbsp;&nbsp;<i className="fa-sharp fa-light fa-arrow-up-right-from-square" aria-hidden="true"></i></a>}
                                    ></BasicDisplayField>
                                )
                            }
                            {
                                job.reporter && (
                                    <ContactListDisplay
                                        fieldName="contacts_reporter"
                                        fieldLabel="Job Reporter"
                                        contacts={[job.reporter]}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                job.point_of_contact && (
                                    <ContactListDisplay
                                        fieldName="contacts_job_point_of_Contact"
                                        fieldLabel="Job Contact"
                                        contacts={[job.point_of_contact]}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                job.service_location.primary_contact && (
                                    <ContactListDisplay
                                        fieldName="contacts_service_location"
                                        fieldLabel="Location Contact"
                                        contacts={job.service_location.contacts.filter(contact => contact.id === job.service_location.primary_contact)}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                job.service_location.external_client && job.service_location.external_client.primary_contact && (
                                    <ContactListDisplay
                                        fieldName="contacts_client"
                                        fieldLabel="Client Contact"
                                        contacts={job.service_location.external_client.contacts.filter(contact => contact.id === job.service_location.external_client.primary_contact)}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                window.USING_PUBLIC_URL === true && (
                                    <ContactListDisplay
                                        fieldName="contacts_service_company"
                                        fieldLabel="Service Company Contact"
                                        contacts={[
                                            {
                                                "name": job.service_company.name,
                                                "phone": job.service_company.phone ? format_phone_number_with_extension(job.service_company.phone, job.service_company.phone_extension) : "--",
                                                "email": job.service_company.email || "--",
                                            }
                                        ]}
                                    ></ContactListDisplay>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && childEstimates.length !== 0 && !window.CURRENT_USER?.permissions.cannot_view_job_financials && (
                                    <AccessCheck entitlements={["entitlement_estimates_enabled"]} permissions={{
                                        estimates_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <LinkedObjectsDisplayField
                                            fieldName="estimates"
                                            fieldLabel="Estimates"
                                            objectComponentList={childEstimates.map((estimate, index) =>
                                                <EstimateObjectLink
                                                    key={`child_estimate_${index}`}
                                                    estimate={estimate}
                                                    destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_ESTIMATE_URL: DjangoUrls["estimates:estimates-detail"](window.MARKETPLACE_ENTITY_SLUG, estimate.id)}
                                                    disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.estimates_view_permission < PERMISSION_LEVEL.FULL}
                                                />
                                            )}
                                        ></LinkedObjectsDisplayField>
                                    </AccessCheck>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && childCallbacks.length !== 0 && (
                                    <AccessCheck entitlements={["entitlement_jobs_enabled"]} permissions={{
                                        jobs_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <LinkedObjectsDisplayField
                                            fieldName="callbacks"
                                            fieldLabel="Callbacks"
                                            objectComponentList={childCallbacks.map((callback, index) =>
                                                <JobObjectLink
                                                    key={`callbacks_${index}`}
                                                    job={callback}
                                                    destination={DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, callback.id)}
                                                    disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.jobs_view_permission < PERMISSION_LEVEL.FULL}
                                                />
                                            )}
                                        ></LinkedObjectsDisplayField>
                                    </AccessCheck>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && childInvoices.length !== 0 && !window.CURRENT_USER?.permissions.cannot_view_job_financials && (
                                    <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={{
                                        invoices_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <LinkedObjectsDisplayField
                                            fieldName="invoices"
                                            fieldLabel="Invoices"
                                            objectComponentList={childInvoices.map((invoice, index) =>
                                                <InvoiceObjectLink
                                                    key={`child_invoice_${index}`}
                                                    invoice={invoice}
                                                    destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_INVOICE_URL : DjangoUrls["invoices:invoices-detail"](window.MARKETPLACE_ENTITY_SLUG, invoice.id)}
                                                    disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.invoices_view_permission < PERMISSION_LEVEL.FULL}
                                                />
                                            )}
                                        ></LinkedObjectsDisplayField>
                                    </AccessCheck>
                                )
                            }
                            {
                                !(window.USING_PUBLIC_URL === true) && purchaseOrders.length !== 0 && !window.CURRENT_USER?.permissions.cannot_view_job_financials && (
                                    <AccessCheck entitlements={["entitlement_purchase_orders_enabled"]} permissions={{
                                        purchase_orders_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                    }}>
                                        <LinkedObjectsDisplayField
                                            fieldName="purchase_orders"
                                            fieldLabel="Purchase Orders"
                                            objectComponentList={purchaseOrders.map((purchaseOrder, index) =>
                                                <PurchaseOrderObjectLink
                                                    key={`purchase_order_${index}`}
                                                    purchaseOrder={purchaseOrder}
                                                    destination={window.USING_PUBLIC_URL === true ? window.PUBLIC_PURCHASE_ORDER_URL : DjangoUrls["purchase-orders:purchase-orders-detail"](window.MARKETPLACE_ENTITY_SLUG, purchaseOrder.id)}
                                                    disabled={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.purchase_orders_view_permission < PERMISSION_LEVEL.FULL}
                                                />
                                            )}
                                        ></LinkedObjectsDisplayField>
                                    </AccessCheck>
                                )
                            }
                            {
                                job.attachments.length !== 0 && job.service_company.feature_file_uploads_enabled && (
                                    <BasicDisplayField
                                        fieldName="attachments"
                                        fieldLabel="Attachments"
                                        fieldValue={<AttachmentHorizontalDisplay attachments={job.attachments} />}
                                    />
                                )
                            }
                        </div>
                            {this.renderButtons()}
                            <PDFDownloadDialog
                                isOpen={this.state.isPDFDialogOpen}
                                onOpenChange={(newValue) => this.setState({ isPDFDialogOpen: newValue })}
                                objects={[
                                    'Job',
                                    job.estimate && 'Estimate',
                                ]}
                                detailsData={this.detailsData}
                                onDownload={this.handlePDFDownload}
                                showToggle={job.service_company.slug === window.CURRENT_USER?.service_company?.slug}
                            />
                    </div>
                </div>
                { (window.USING_PUBLIC_URL === true || window.CURRENT_USER?.permissions.equipment_list_permission >= PERMISSION_LEVEL.RESTRICTED) &&
                    job.equipment.sort((obj, other) => obj.display_name.localeCompare(other.display_name)).map(equipment => {
                        return (
                            <div key={`equipment_${equipment.id}`} className="accordion-wrapper">
                                <EquipmentDetailsAccordion
                                    data={equipment}
                                    clientID={job.service_location.external_client.id}
                                    editable={!(window.USING_PUBLIC_URL === true) && window.CURRENT_USER?.permissions.equipment_edit_permission >= PERMISSION_LEVEL.FULL}
                                    showJobHistory={window.USING_PUBLIC_URL !== true}
                                    addToastToQueue={addToastToQueue}
                                    openable={window.USING_PUBLIC_URL === true || window.CURRENT_USER?.permissions.equipment_view_permission >= PERMISSION_LEVEL.FULL}
                                />
                            </div>
                        )
                    })
                }
                {
                    job.initial_started_time && (
                        <div className="accordion-wrapper">
                            <JobSummaryAccordion job={job} publicMode={window.USING_PUBLIC_URL === true} />
                        </div>
                    )
                }
                <AccessCheck permissions={{
                    jobs_view_permission: PERMISSION_LEVEL.FULL,
                }}>
                    {
                        !(window.USING_PUBLIC_URL === true) && (
                            <div className="accordion-wrapper">
                                <JobFeedAccordion jobID={job.id} totalTimeLogged={job.total_time_logged} />
                            </div>
                        )
                    }
                </AccessCheck>
                <AccessCheck permissions={{
                    jobs_list_permission: PERMISSION_LEVEL.RESTRICTED,
                }}>
                    {
                        !(window.USING_PUBLIC_URL === true) && (
                            <div className="accordion-wrapper">
                                <JobHistoryAccordion jobID={job.id} />
                            </div>
                        )
                    }
                </AccessCheck>
                {
                    window.USING_PUBLIC_URL === true && (
                        <div className="powered-by-panel">
                            <span>Powered By</span>
                            <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                        </div>
                    )
                }
            </Fragment>
        )
    }

}

export default JobDetailsCard;
