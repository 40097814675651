import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import replaceSlugs from "@utils/replaceSlugs"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB,
    PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH,
    PRICEBOOK_LIST_CONTROLS_ORDERING,
    PRICEBOOK_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { PricebookTableViewProps } from "./PricebookTableView.types"
import {
    DiscountsTabs,
    MiscellaneousTabs,
    PartsAndMaterialsTabs,
    PricebookTypeName,
    ServicesTabs,
    TaxRatesTabs,
} from "./PricebookTableViewTabs"

export const CREATE_ROUTE_MAP: { [key in PricebookTypeName]: string } = {
    Services: PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE,
    "Parts & Materials": PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE,
    Miscellaneous: PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE,
    Discounts: PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE,
    "Tax Rates": PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE,
}

export default function PricebookTableView(props: PricebookTableViewProps) {
    const { pricebookType } = props

    const [createRoute] = useSlugExtractor([CREATE_ROUTE_MAP[pricebookType]])
    const { hasPermission } = useHasAccess()

    const tabsByPricebookType: { [key in PricebookTypeName]: JSX.Element } = {
        Services: <ServicesTabs />,
        "Parts & Materials": <PartsAndMaterialsTabs />,
        Miscellaneous: <MiscellaneousTabs />,
        Discounts: <DiscountsTabs />,
        "Tax Rates": <TaxRatesTabs />,
    }

    return (
        <Table.Provider
            activePhaseStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_PHASE, {
                type: pricebookType ?? "undefined",
            })}
            activeTabStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_ACTIVE_TAB, {
                type: pricebookType ?? "undefined",
            })}
            searchKeywordsStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_KEYWORD_SEARCH, {
                type: pricebookType ?? "undefined",
            })}
            orderingStorageKey={replaceSlugs(PRICEBOOK_LIST_CONTROLS_ORDERING, {
                type: pricebookType ?? "undefined",
            })}
            listTitle={pricebookType ?? ""}
            objectType="pricebook_item"
            objectCreateRoute={createRoute}
            objectIndexName="PriceBookItem"
            objectName="Pricebook Item"
            defaultActivePhase="active"
            objectTypeVariation={pricebookType}
            isRowClickable={hasPermission("pricebook_edit_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("pricebook_create_permission", PERMISSION_LEVEL.FULL)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="active" title="Active" />
                            <TableViewPhase phase="inactive" title="Inactive" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        {tabsByPricebookType[pricebookType] ?? null}
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<PricebookItem>
                    accessorKey="description"
                    header="Short Description"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<PricebookItem>
                    accessorKey="status_label"
                    header="State"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<PricebookItem>
                    accessorKey="summary_template"
                    header="Summary"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                {pricebookType === "Services" && (
                    <Table.Column<PricebookItem>
                        accessorKey="default_price"
                        header="Rate"
                        size={144}
                        cell={({ getValue, row }) => (
                            <Table.Cells.NumericalDataCell
                                value={Number(getValue())}
                                type="value-hour"
                                unitType={row.original.default_unit_type_label}
                            />
                        )}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                )}
                {(pricebookType === "Parts & Materials" || pricebookType === "Miscellaneous") && (
                    <Table.Column<PricebookItem>
                        accessorKey="default_price"
                        header="Price"
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                )}
                {pricebookType === "Discounts" && (
                    <Table.Column<PricebookItem>
                        accessorKey="default_price"
                        header="Discount"
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                )}
                {pricebookType === "Tax Rates" && (
                    <Table.Column<PricebookItem>
                        accessorKey="default_price"
                        header="Tax Rate"
                        size={128}
                        cell={({ getValue }) => (
                            <Table.Cells.NumericalDataCell value={parseFloat(getValue())} type="percentage" />
                        )}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                )}
                {pricebookType !== "Discounts" && pricebookType !== "Tax Rates" && (
                    <Table.Column<PricebookItem>
                        accessorKey="cost"
                        header="Cost"
                        size={144}
                        cell={({ getValue, row }) => (
                            <Table.Cells.NumericalDataCell
                                value={getValue()}
                                type="value-hour"
                                unitType={row.original.default_unit_type_label}
                            />
                        )}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                )}
                {pricebookType === "Parts & Materials" && (
                    <>
                        <Table.Column<PricebookItem>
                            accessorKey="manufacturer"
                            header="OEM"
                            size={144}
                            cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                            enableSorting={true}
                            enablePinning={false}
                            dataType="other"
                        />
                        <Table.Column<PricebookItem>
                            accessorKey="part_number"
                            header="OEM Part #"
                            size={144}
                            cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                            enableSorting={true}
                            enablePinning={false}
                            dataType="other"
                        />
                    </>
                )}
                {pricebookType === "Services" && (
                    <Table.Column<PricebookItem>
                        accessorKey="default_job_duration"
                        header="Est. Duration"
                        size={144}
                        cell={({ getValue }) => <Table.Cells.NumericalDataCell type="duration" value={getValue()} />}
                        enableSorting={true}
                        enablePinning={false}
                        dataType="numerical"
                    />
                )}
                <Table.Column<PricebookItem>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as PricebookItemLabel[]).map(
                                (label: PricebookItemLabel) => label.name,
                            )}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                {pricebookType === "Parts & Materials" && (
                    <>
                        <Table.Column<PricebookItem>
                            accessorKey="lowest_inventory_status"
                            header="Stock"
                            size={112}
                            cell={({ getValue, row }) => (
                                <Table.Cells.InventoryStatusCell
                                    value={getValue()}
                                    trackInventory={row.original.track_inventory}
                                />
                            )}
                            enableSorting={true}
                            enablePinning={false}
                            dataType="other"
                        />
                        <Table.Column<PricebookItem>
                            accessorKey="inventory_level"
                            header="Levels"
                            size={112}
                            cell={({ row }) => (
                                <Table.Cells.TruncatedTextCell
                                    content={
                                        row.original.track_inventory
                                            ? `${row.original.total_quantity}/${row.original.total_quantity_desired}`
                                            : null
                                    }
                                />
                            )}
                            enableSorting={true}
                            enablePinning={false}
                            dataType="numerical"
                        />
                    </>
                )}
                <Table.Column<PricebookItem>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
