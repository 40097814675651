import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { useMutation } from "@tanstack/react-query"
import Cookies from "js-cookie"

import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import { Icon } from "@atoms/index"

import { CompanyAvatar, ResponsiveSelector } from "@molecules"
import { Item } from "@molecules/Dropdown/Dropdown.types"
import { GenericError } from "@molecules/Form/GenericForm/GenericForm.types"
import toast from "@molecules/Toast/Toast"

import { ROOT_ROUTE } from "@routes/root"

import { SERVICE_COMPANY } from "@endpoints/serviceCompany"

import styles from "./WorkspaceSelector.module.scss"
import { SwitchServiceCompanyError } from "./WorkspaceSelector.types"

export default function WorkspaceSelector() {
    const { user } = useUser()
    const router = useRouter()
    const isDesktop = useIsDesktop()

    const switchServiceCompanyMutation = useMutation<void, GenericError<SwitchServiceCompanyError>, string>({
        mutationFn: async (serviceCompanyId: string): Promise<void> => {
            const response = await fetch(SERVICE_COMPANY.SWITCH_SERVICE_COMPANY, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": Cookies.get("csrftoken") ?? "",
                },
                body: JSON.stringify({ service_company_id: serviceCompanyId }),
            })

            if (!response.ok) {
                await response.json().then((error: SwitchServiceCompanyError) => {
                    throw new GenericError("Failed to switch service company", error)
                })
            }
        },
        onSuccess: () => {
            router.push(ROOT_ROUTE)
        },
        onError: (error) => {
            toast({
                size: "md",
                title: error.error.detail,
                type: "error",
            })
        },
    })
    const handleSelect = (item: Item) => {
        const serviceCompanyId = item.value
        switchServiceCompanyMutation.mutate(serviceCompanyId)
    }

    if (user) {
        const items: Item[] = user.accessible_service_companies.map((company) => ({
            value: String(company.id),
            label: company.name,
            isActive: company.id === user.service_company.id,
        }))

        const hasMultipleCompanies = items.length > 1

        return hasMultipleCompanies ? (
            <div className={styles.switchable}>
                <ResponsiveSelector
                    items={items}
                    onSelect={handleSelect}
                    triggerClassName={styles.trigger}
                    side="top"
                    startingPoint="right"
                    headerTitle="Switch Service Company"
                    trigger={
                        <div className={styles.base}>
                            <div className={styles.logo}>
                                <CompanyAvatar serviceCompany={user.service_company} />
                            </div>
                            <div className={styles.name}>{user.service_company.name}</div>
                            <Icon
                                icon={icon({
                                    name: "angles-up-down",
                                    style: "regular",
                                    family: "sharp",
                                })}
                                size={isDesktop ? 10 : 14}
                            />
                        </div>
                    }
                />
            </div>
        ) : (
            <div className={styles.base}>
                <div className={styles.logo}>
                    <CompanyAvatar serviceCompany={user.service_company} />
                </div>
                <div className={styles.name}>{user.service_company.name}</div>
            </div>
        )
    } else {
        return null
    }
}
