import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useNavigationLinks from "@hooks/useNavigationLinks"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"

import { AccessCheck } from "@particles"

import { TopBar } from "@organisms"
import ViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider"

import { AppFrame } from "@templates"

import { JOBS_ROUTES } from "@routes/jobs"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { JOBS_LIST_CONTROLS_VIEW } from "@constants/storage"

import JobCardView from "./views/JobCardView/JobCardView"
import JobTableView from "./views/JobTableView/JobTableView"
import JobTimelineView from "./views/JobTimelineView/JobTimelineView"

export default function JobList() {
    const { user } = useUser()
    const isDesktop = useIsDesktop()
    const [createRoute] = useSlugExtractor([JOBS_ROUTES.CREATE])

    const router = useRouter()
    const { jobs: jobsLink } = useNavigationLinks()

    const sublinks = filterUserLinks(jobsLink.subLinks, user)

    return (
        <AppFrame.Root pageId="JobList">
            <ViewTypesProvider viewTypeKey={JOBS_LIST_CONTROLS_VIEW} defaultViewType={isDesktop ? "table" : "card"}>
                {({ activeViewType }) => (
                    <>
                        <TopBar.Root>
                            <TopBar.Header>
                                <TopBar.TopBarHeaderStart>
                                    <TopBar.PageTitle
                                        label="Jobs"
                                        icon={icon({ name: "wrench-simple", style: "regular", family: "sharp" })}
                                        iconActive={icon({
                                            name: "wrench-simple",
                                            style: "solid",
                                            family: "sharp",
                                        })}
                                        navigationItems={
                                            sublinks.length > 1
                                                ? sublinks.map((item) => ({
                                                      label: item.label,
                                                      value: item.path,
                                                      isActive: item.label === "Jobs",
                                                  }))
                                                : undefined
                                        }
                                        onNavigationItemSelect={(item) => {
                                            router.push(item.value)
                                        }}
                                    />
                                </TopBar.TopBarHeaderStart>
                                <TopBar.TopBarHeaderEnd>
                                    <TopBar.PagePresence />
                                    <AccessCheck
                                        permissions={{
                                            jobs_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <AccessCheck
                                            permissions={{
                                                jobs_view_permission: PERMISSION_LEVEL.FULL,
                                            }}
                                        >
                                            <TopBar.SearchButton scope="Jobs" />
                                        </AccessCheck>
                                    </AccessCheck>
                                    <TopBar.ViewSelector viewTypes={["table", "card", "timeline"]} />
                                    <AccessCheck
                                        permissions={{
                                            jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <TopBar.CTAButton destination={createRoute}>
                                            {isDesktop ? "New Job" : null}
                                        </TopBar.CTAButton>
                                    </AccessCheck>
                                </TopBar.TopBarHeaderEnd>
                            </TopBar.Header>
                        </TopBar.Root>
                        {activeViewType === "card" && <JobCardView />}
                        {activeViewType === "table" && <JobTableView />}
                        {activeViewType === "timeline" && <JobTimelineView />}
                    </>
                )}
            </ViewTypesProvider>
        </AppFrame.Root>
    )
}
