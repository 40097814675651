import { ResourceTimeRangeModel } from "@bryntum/schedulerpro"
import clsx from "clsx"
import dayjs from "dayjs"

import useUser from "@hooks/useUser"

import { SchedulerTimeRange } from "@pages/Jobs/JobList/views/JobTimelineView/JobTimelineView.types"

import useJobTimelineViewBryntumInstances from "./useJobTimelineViewBryntumInstances"

export default function useJobTimelineViewTimeRanges() {
    const { user } = useUser()

    const { schedulerPro } = useJobTimelineViewBryntumInstances()

    const arrayOfDatesFromTimeFrame = (startDate: Date, endDate: Date): Date[] => {
        const dates: Date[] = []
        const currentDate = dayjs(startDate).startOf("day").toDate()
        const finalDate = dayjs(endDate).startOf("day").toDate()

        while (currentDate <= finalDate) {
            dates.push(new Date(currentDate))
            currentDate.setDate(currentDate.getDate() + 1)
        }

        return dates
    }

    const getTimeRanges = (date: Date): SchedulerTimeRange => {
        const midnight = dayjs(date).startOf("day").toDate()

        const startOfWorkStr = user?.service_company?.start_of_day ?? "00:00"
        const [hours, minutes] = startOfWorkStr.split(":")
        const startOfWork = dayjs(date)
            .set("hour", Number(hours))
            .set("minute", Number(minutes))
            .set("second", 0)
            .set("millisecond", 0)
            .toDate()

        const endOfDay = dayjs(date).endOf("day").toDate()

        const endOfWorkStr = user?.service_company?.end_of_day ?? "23:59"
        const [endHours, endMinutes] = endOfWorkStr.split(":")
        const endOfWork = dayjs(date)
            .set("hour", Number(endHours))
            .set("minute", Number(endMinutes))
            .set("second", 0)
            .set("millisecond", 0)
            .toDate()

        return {
            midnight,
            startOfWork,
            endOfWork,
            endOfDay,
        }
    }

    const createNonWorkingInterval = (
        timeRange: SchedulerTimeRange,
        resourceId: CalendarTechnician["id"],
        type: "full-day" | "start-of-day" | "end-of-day",
    ) => {
        const startDate = type === "full-day" || type === "start-of-day" ? timeRange.midnight : timeRange.endOfWork
        const endDate = type === "full-day" || type === "end-of-day" ? timeRange.endOfDay : timeRange.startOfWork

        return {
            startDate: startDate,
            endDate: endDate,
            cls: clsx("job-timeline-view__non-working-time", {
                "job-timeline-view__non-working-time--unassigned": resourceId === "unassigned",
            }),
            resourceId: resourceId,
            id: resourceId + type + startDate.toString(),
        }
    }

    const createWorkingInterval = (timeRange: SchedulerTimeRange, resourceId: CalendarTechnician["id"]) => {
        return {
            startDate: timeRange.startOfWork,
            endDate: timeRange.endOfWork,
            cls: clsx("job-timeline-view__working-time", {
                "job-timeline-view__working-time--unassigned": resourceId === "unassigned",
            }),
            resourceId: resourceId,
            id: resourceId + "working-interval" + timeRange.startOfWork.toString(),
        }
    }

    const createAllResourcesTimeRanges = (
        timeRange: SchedulerTimeRange,
        resources: CalendarTechnician[],
    ): ResourceTimeRangeModel[] => {
        return resources.flatMap((resource) => {
            const isUnavailable = resource?.availability?.some((availability) => {
                const availabilityDate = dayjs(availability.date).startOf("day")

                const dateToCheck = dayjs(timeRange.startOfWork).startOf("day")

                return !availability.available && availabilityDate.isSame(dateToCheck)
            })

            if (isUnavailable) {
                return [createNonWorkingInterval(timeRange, resource.id, "full-day")]
            } else {
                return [
                    createNonWorkingInterval(timeRange, resource.id, "start-of-day"),
                    createWorkingInterval(timeRange, resource.id),
                    createNonWorkingInterval(timeRange, resource.id, "end-of-day"),
                ]
            }
        }) as ResourceTimeRangeModel[]
    }

    const createUnassignedTimeRange = (timeRange: SchedulerTimeRange): ResourceTimeRangeModel[] => {
        return [
            createNonWorkingInterval(timeRange, "unassigned", "start-of-day"),
            createWorkingInterval(timeRange, "unassigned"),
            createNonWorkingInterval(timeRange, "unassigned", "end-of-day"),
        ] as ResourceTimeRangeModel[]
    }

    const generateSchedulerMouseIndicatorTimeRanges = (): ResourceTimeRangeModel[] => {
        return [
            {
                id: "hovered-time-range",
                startDate: new Date(),
                endDate: new Date(),
                cls: "job-timeline-view__create-job-indicator jsJobTimelineViewCreateJobIndicator",
            },
            {
                id: "job-move-feedback",
                startDate: new Date(),
                endDate: new Date(),
                cls: "job-timeline-view__job-moving-indicator jsBryntumTimelineViewMoveFeedback",
            },
        ] as ResourceTimeRangeModel[]
    }

    const generateAllSchedulerTimeRanges = (
        timeRanges: SchedulerTimeRange[],
        resources: CalendarTechnician[],
    ): ResourceTimeRangeModel[] => {
        const resourceTimeRanges = timeRanges.map((timeRange) => createAllResourcesTimeRanges(timeRange, resources))

        const unassignedTimeRange = timeRanges.map((timeRange) => createUnassignedTimeRange(timeRange))

        const schedulerBaseTimeRanges = [...unassignedTimeRange.flat(), ...resourceTimeRanges.flat()]

        const utilTimeRanges = generateSchedulerMouseIndicatorTimeRanges()

        return [...schedulerBaseTimeRanges, ...utilTimeRanges]
    }

    const configureSchedulerTimeRange = (timeRange: SchedulerTimeRange) => {
        if (schedulerPro.current) {
            schedulerPro.current.instance.setStartDate(timeRange.midnight)
            schedulerPro.current.instance.setEndDate(timeRange.endOfWork)
        }
    }

    return {
        getTimeRanges,
        generateAllSchedulerTimeRanges,
        configureSchedulerTimeRange,
        arrayOfDatesFromTimeFrame,
    }
}
