import { ColumnDef, ColumnDefResolved } from "@tanstack/react-table"
import { useEffect, useMemo, useState } from "react"

import getTableSortingIconsPairByDataType from "@utils/getTableSortingIconsPairByDataType"
import getTableSortingLabelsPairByDataType from "@utils/getTableSortingLabelsPairByDataType"
import mapTableVisibleColumns from "@utils/mapTableVisibleColumns"

import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import { UseTableColumnsProps } from "@organisms/Table/Table.types"

const MINIMUM_OF_COLUMNS_VISIBLE = 1

export default function useTableColumns<T = ObjectsData>(props: UseTableColumnsProps) {
    const { currentTableViewConfig, updateCurrentTableViewConfig } = props

    const [columns, setColumns] = useState<ColumnDefResolved<T, unknown>[]>([])
    const [reachedPinLimit, setReachedPinLimit] = useState(false)

    useEffect(() => {
        const currentColumnPinning = currentTableViewConfig?.columnPinning.left ?? []
        setReachedPinLimit(currentColumnPinning.length >= 3)
    }, [currentTableViewConfig?.columnPinning.left])

    const updateColumnVisibility = (columnId: string, visibility: boolean) => {
        const newColumnVisibility = {
            [columnId]: visibility,
        }

        const columnVisibility = {
            ...currentTableViewConfig?.columnVisibility,
            ...newColumnVisibility,
        }

        const columnIsPinned = currentTableViewConfig?.columnPinning.left?.includes(columnId)

        if (columnIsPinned) {
            unpinColumn(columnId)
        }

        updateCurrentTableViewConfig("columnVisibility", columnVisibility)
    }

    const unpinColumn = (columnId: string) => {
        const currentColumnPinning = currentTableViewConfig?.columnPinning.left ?? []

        const columnPinning = {
            ...currentTableViewConfig?.columnPinning,
            left: currentColumnPinning.filter((column) => column !== columnId),
        }

        updateCurrentTableViewConfig("columnPinning", columnPinning)
    }

    const pinColumn = (columnId: string) => {
        // Block pinning more columns than the limit
        if (reachedPinLimit) {
            return
        }

        const currentColumnPinning = currentTableViewConfig?.columnPinning.left ?? []

        const columnPinning = {
            ...currentTableViewConfig?.columnPinning,
            left: [...currentColumnPinning, columnId],
        }

        updateCurrentTableViewConfig("columnPinning", columnPinning)
    }

    const updateColumnPinningOrder = (newColumnPinningOrder: string[]) => {
        updateCurrentTableViewConfig("columnPinning", {
            left: newColumnPinningOrder,
        })
    }

    const updateColumnOrder = (newColumnOrder: string[]) => {
        updateCurrentTableViewConfig("columnOrder", newColumnOrder)
    }

    const { allColumns, pinnedColumns, unpinnedColumns } = mapTableVisibleColumns({
        columnIds: currentTableViewConfig?.columnOrder ?? [],
        columnVisibility: currentTableViewConfig?.columnVisibility ?? {},
        columnPinning: currentTableViewConfig?.columnPinning,
    })

    const tableColumns = useMemo(() => {
        return (currentTableViewConfig?.columnOrder
            ?.map((columnId) => columns.find((column) => column.accessorKey === columnId))
            .filter(Boolean) || []) as ColumnDef<T, unknown>[]
    }, [columns, currentTableViewConfig?.columnOrder])

    const invisibleColumns = useMemo(() => {
        return tableColumns.filter((column) => {
            const accessorKey = (column as ColumnDefResolved<T, unknown>)
                .accessorKey as keyof typeof currentTableViewConfig.columnVisibility
            return currentTableViewConfig?.columnVisibility?.[accessorKey] === false
        }) as ColumnDefResolved<T, unknown>[]
    }, [currentTableViewConfig?.columnOrder, currentTableViewConfig?.columnVisibility])

    const visibleColumns = useMemo(() => {
        return tableColumns.filter((column) => {
            const accessorKey = (column as ColumnDefResolved<T, unknown>)
                .accessorKey as keyof typeof currentTableViewConfig.columnVisibility
            return currentTableViewConfig?.columnVisibility?.[accessorKey] !== false
        })
    }, [tableColumns, currentTableViewConfig?.columnVisibility])

    const reachedMinimumOfColumnsVisible = useMemo(() => {
        return allColumns.length <= MINIMUM_OF_COLUMNS_VISIBLE
    }, [allColumns])

    const activeSorting = useMemo(() => {
        return currentTableViewConfig?.sorting?.[0]
    }, [currentTableViewConfig])

    const activeSortingColumn = useMemo(() => {
        if (!activeSorting) {
            return undefined
        }
        return tableColumns.find(
            (column) => "accessorKey" in column && column.accessorKey === activeSorting.id,
        ) as ColumnDefResolved<T, unknown>
    }, [activeSorting, tableColumns])

    const sortingEnabledColumns = useMemo(() => {
        return tableColumns.filter((column) => column.enableSorting) as ColumnDefResolved<T, unknown>[]
    }, [tableColumns])

    const activeSortingIcons = useMemo(() => {
        return (
            activeSortingColumn?.meta?.dataType &&
            getTableSortingIconsPairByDataType(activeSortingColumn?.meta?.dataType)
        )
    }, [activeSortingColumn?.meta?.dataType])

    const activeSortingLabels = useMemo(() => {
        return (
            activeSortingColumn?.meta?.dataType &&
            getTableSortingLabelsPairByDataType(activeSortingColumn?.meta?.dataType)
        )
    }, [activeSortingColumn?.meta?.dataType])

    return {
        setColumns,
        tableColumns,
        reachedPinLimit,
        setReachedPinLimit,
        updateColumnVisibility,
        unpinColumn,
        pinColumn,
        updateColumnPinningOrder,
        updateColumnOrder,
        invisibleColumns,
        reachedMinimumOfColumnsVisible,
        pinnedColumns,
        unpinnedColumns,
        columnsNamesList: allColumns,
        visibleColumns,
        activeSorting,
        sortingEnabledColumns,
        activeSortingIcons,
        activeSortingLabels,
        activeSortingColumn,
    }
}
