import pluralize from "pluralize"

import convertToSnakeCase from "@utils/convertToSnakeCase"

export interface GetObjectTableViewConfigStorageKeyArgs {
    objectType: ObjectType
    objectTypeVariation?: string
    phase: ObjectPhase
    tab: string
}

export default function getObjectTableViewConfigStorageKey(args: GetObjectTableViewConfigStorageKeyArgs) {
    const { objectType, phase, tab, objectTypeVariation } = args

    const objectTypePlural = pluralize(objectType)
    const snakeCaseTab = convertToSnakeCase(tab)

    return `${objectTypePlural}${
        objectTypeVariation ? "_" + convertToSnakeCase(objectTypeVariation) : ""
    }_table__${phase}_${snakeCaseTab}`
}
