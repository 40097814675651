import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"

import useHasAccess from "@hooks/useHasAccess"
import useIsAnyDialogOpen from "@hooks/useIsAnyDialogOpen"
import useRouter from "@hooks/useRouter"
import useUser from "@hooks/useUser"

import { ifSpaceOrEnter } from "@utils/keyboard"
import replaceSlugs from "@utils/replaceSlugs"

import { HotKey, Icon } from "@atoms"

import { OverflowMenu } from "@molecules"
import { Item, SubItem } from "@molecules/OverflowMenu/OverflowMenu.types"

import { BILLS_ROUTES } from "@routes/bills"
import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"
import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import styles from "./SideBarCreateButton.module.scss"
import { SideBarCreateButtonProps } from "./SideBarCreateButton.types"

const OPEN_OVERFLOW_MENU_HOTKEY = "c"

export default function SideBarCreateButton(props: SideBarCreateButtonProps) {
    const { isDisabled = false } = props

    const { user } = useUser()
    const router = useRouter()

    const { hasEntitlement, hasPermission } = useHasAccess()

    const isUsingTaxRates =
        user?.service_company?.use_taxes &&
        !(
            user?.service_company?.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company?.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [userIsInteracting, setUserIsInteracting] = useState<boolean>(false)

    const openOverflowMenu = () => (!isDisabled ? setIsOpen(true) : null)
    const closeOverflowMenu = () => setIsOpen(false)

    const onObjectSelect = (item: Item | SubItem) => {
        const route = replaceSlugs(item.value, { service_company_slug: user?.service_company?.slug ?? "undefined" })
        router.push(route)
    }

    const onHoverActiveOrFocusVisible = () => {
        setUserIsInteracting(true)
    }

    const onMouseLeave = () => {
        setUserIsInteracting(false)
    }

    useHotkeys(
        OPEN_OVERFLOW_MENU_HOTKEY,
        () => openOverflowMenu(),
        {
            enabled: !isDisabled,
        },
        [isDisabled],
    )

    const { setIsAnyDialogOpen } = useIsAnyDialogOpen()

    useEffect(() => {
        setIsAnyDialogOpen(isOpen)
    }, [isOpen])

    const filteredObjectCreateItems = useMemo(() => {
        const items: Item[] = []

        if (
            hasEntitlement("entitlement_estimates_enabled") &&
            hasPermission("estimates_create_permission", PERMISSION_LEVEL.RESTRICTED)
        ) {
            items.push({
                icon: icon({ name: "file-lines", style: "light", family: "sharp" }),
                value: ESTIMATES_ROUTES.CREATE,
                label: "Estimate",
            })
        }

        if (
            hasEntitlement("entitlement_jobs_enabled") &&
            hasPermission("jobs_create_permission", PERMISSION_LEVEL.RESTRICTED)
        ) {
            items.push({
                icon: icon({ name: "wrench-simple", style: "light", family: "sharp" }),
                value: JOBS_ROUTES.CREATE,
                label: "Job",
            })
        }

        if (
            hasEntitlement("entitlement_invoices_enabled") &&
            hasPermission("invoices_create_permission", PERMISSION_LEVEL.RESTRICTED)
        ) {
            items.push({
                icon: icon({ name: "circle-dollar", style: "light", family: "sharp" }),
                value: INVOICES_ROUTES.CREATE,
                label: "Invoice",
            })
        }

        if (
            hasEntitlement("entitlement_purchase_orders_enabled") &&
            hasPermission("purchase_orders_create_permission", PERMISSION_LEVEL.RESTRICTED)
        ) {
            items.push({
                icon: icon({ name: "basket-shopping", style: "light", family: "sharp" }),
                value: PURCHASE_ORDER_ROUTES.CREATE,
                label: "Purchase Order",
            })
        }

        if (
            hasEntitlement("entitlement_bills_enabled") &&
            hasPermission("bills_create_permission", PERMISSION_LEVEL.RESTRICTED)
        ) {
            items.push({
                icon: icon({ name: "file-invoice-dollar", style: "light", family: "sharp" }),
                value: BILLS_ROUTES.CREATE,
                label: "Bill",
            })
        }

        if (hasPermission("vendors_create_permission", PERMISSION_LEVEL.FULL)) {
            items.push({
                icon: icon({ name: "store", style: "light", family: "sharp" }),
                value: VENDOR_ROUTES.CREATE,
                label: "Vendor",
            })
        }

        if (hasPermission("clients_create_permission", PERMISSION_LEVEL.FULL)) {
            items.push({
                icon: icon({ name: "user-circle", style: "light", family: "sharp" }),
                value: CLIENTS_ROUTES.CREATE,
                label: "Client",
            })
        }

        if (hasPermission("pricebook_create_permission", PERMISSION_LEVEL.FULL)) {
            items.push({
                value: "pricebook_item",
                label: "Pricebook Item",
                icon: icon({ name: "tag", style: "light", family: "sharp" }),
                subItems: [
                    {
                        value: PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE,
                        label: "Service",
                    },
                    {
                        value: PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE,
                        label: "Part or Material",
                    },
                    {
                        value: PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE,
                        label: "Miscellaneous",
                    },
                    {
                        value: PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE,
                        label: "Discount",
                    },
                    ...(isUsingTaxRates
                        ? [
                              {
                                  value: PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE,
                                  label: "Tax Rate",
                              },
                          ]
                        : []),
                ],
            })
        }

        return items
    }, [hasEntitlement, hasPermission, isUsingTaxRates])

    return (
        <div className={styles.base}>
            <div
                className={clsx(styles.trigger, {
                    [styles.active]: isOpen,
                    [styles.isDisabled]: isDisabled,
                })}
                tabIndex={0}
                role="button"
                onClick={openOverflowMenu}
                onKeyDown={(e) => ifSpaceOrEnter(e, openOverflowMenu)}
                onMouseOver={onHoverActiveOrFocusVisible}
                onFocus={onHoverActiveOrFocusVisible}
                onMouseOut={onMouseLeave}
            >
                <div className={styles.iconAndText}>
                    <div className={styles.iconWrapper}>
                        <Icon
                            className={styles.icon}
                            icon={icon({ name: "circle-plus", style: "solid", family: "sharp" })}
                            size={16}
                        />
                    </div>
                    <div className={styles.label}>
                        <span>Create</span>
                    </div>
                </div>
                {!isDisabled && <HotKey symbolName="C" variant={isOpen || userIsInteracting ? "solid" : "subtle"} />}
            </div>
            <div className={styles.overflowMenu}>
                <OverflowMenu
                    triggerClassName={styles.overflowMenu}
                    onClose={closeOverflowMenu}
                    isOpen={isOpen}
                    onSelect={onObjectSelect}
                    startingPoint="right"
                    items={filteredObjectCreateItems}
                />
            </div>
        </div>
    )
}
