import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { memo, useEffect } from "react"

import useIsDesktop from "@hooks/useIsDesktop"
import useRouter from "@hooks/useRouter"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { Button } from "@atoms/index"

import toast from "@molecules/Toast/Toast"

import { AUTH_ROUTES } from "@routes/auth"

import { AUTH_SOCIAL_ERROR_PARAM } from "@constants/searchParams"

import { SocialErrorTypes } from "./AuthSocialButtons.types"

const socialError = extractURLSearchParam(AUTH_SOCIAL_ERROR_PARAM) as SocialErrorTypes | undefined

function AuthSocialButtons() {
    const isDesktop = useIsDesktop()

    const router = useRouter()

    useEffect(() => {
        if (socialError === "login_error") {
            setTimeout(() => {
                toast({
                    type: "error",
                    size: "md",
                    title: "An error occurred while trying to authenticate with the selected provider.",
                })
            })
        } else if (socialError === "login_cancelled") {
            setTimeout(() => {
                toast({
                    type: "info",
                    size: "md",
                    title: "Authentication with the selected provider was cancelled.",
                })
            })
        } else if (socialError === "user_inactive") {
            setTimeout(() => {
                toast({
                    type: "error",
                    size: "md",
                    title: "The selected user is not active.",
                })
            })
        } else if (socialError === "not_authorized") {
            setTimeout(() => {
                toast({
                    type: "error",
                    size: "md",
                    title: "You are not authorized to log in via this authentication method.",
                })
            })
        } else if (socialError === "user_not_found") {
            setTimeout(() => {
                toast({
                    type: "error",
                    size: "md",
                    title: "The selected user is not registered.",
                })
            })
        } else if (socialError === "configuration_error") {
            setTimeout(() => {
                toast({
                    type: "error",
                    size: "md",
                    title: "The selected user is not configured correctly.",
                })
            })
        }
    }, [socialError])

    const handleGoogleLogin = () => {
        router.push(AUTH_ROUTES.GOOGLE_LOGIN)
    }

    const handleMicrosoftLogin = () => {
        router.push(AUTH_ROUTES.MICROSOFT_LOGIN)
    }

    return (
        <>
            <Button
                colorScheme="gray"
                size={isDesktop ? "md" : "lg"}
                onClick={handleGoogleLogin}
                variant="subtle"
                isFullWidth={true}
                data-prevent-input-validation={true}
                leftIcon={icon({
                    name: "google",
                    style: "brands",
                })}
            >
                Continue with Google
            </Button>
            <Button
                colorScheme="gray"
                size={isDesktop ? "md" : "lg"}
                onClick={handleMicrosoftLogin}
                variant="subtle"
                isFullWidth={true}
                data-prevent-input-validation={true}
                leftIcon={icon({
                    name: "microsoft",
                    style: "brands",
                })}
            >
                Continue with Microsoft
            </Button>
        </>
    )
}

export default memo(AuthSocialButtons)
