import type { NavigationLink } from "@hooks/useNavigationLinks"

import type { NavigationItem } from "@molecules/NavigationItem/NavigationItem.types"

export default function filterUserLinks<LinkType extends NavigationItem | NavigationLink>(
    links: LinkType[] | undefined,
    user: User | null,
): LinkType[] {
    return (links ?? []).filter((link) => !link.predicate || (user ? link.predicate(user) : false))
}
