// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarBodyEnd-base__uTalV{position:relative;display:flex;gap:var(--size-2);align-items:center;align-self:stretch;height:var(--size-13);padding:var(--size-0) var(--size-3)}@media only screen and (min-width: 64rem){.TopBarBodyEnd-base__uTalV{height:var(--size-14);padding:var(--size-0)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarBodyEnd-base__uTalV"
};
export default ___CSS_LOADER_EXPORT___;
