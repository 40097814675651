import clsx from "clsx"
import dayjs from "dayjs"
import pluralize from "pluralize"

import { Table } from "@organisms"

import { RecurrenceFrequency } from "@constants/choices"

import styles from "./RecurrenceCell.module.scss"
import { RecurrenceCellProps } from "./RecurrenceCell.types"

export default function RecurrenceCell(props: RecurrenceCellProps) {
    const { interval, frequency, startDate, weeklyRecurrence, monthlyRecurrence } = props

    if (!interval || !frequency || !startDate) {
        return <Table.Cells.NoValueCell />
    }

    const date = dayjs(startDate)

    const renderRecurrenceText = () => {
        if (frequency === RecurrenceFrequency.DAILY) {
            return `Every ${interval > 1 ? `${interval} ` : ""}${pluralize("Day", interval)}`
        } else if (frequency === RecurrenceFrequency.WEEKLY) {
            const weekdaysNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
            const weekdays = weeklyRecurrence.map((index) => weekdaysNames[index])

            return `Every ${interval > 1 ? `${interval} ` : ""}${pluralize("Week", interval)} on ${weekdays.join(", ")}`
        } else if (frequency === RecurrenceFrequency.MONTHLY) {
            const day = date.format("D")
            const dayOfWeek = date.format("dddd")

            const firstOfMonth = date.startOf("month")
            const occurrence = Math.floor((date.date() - firstOfMonth.date()) / 7) + 1
            // We're leveraging dayjs to get the ordinal suffix for the occurrence.
            // It doesn't have anything to do with dates, just a handy util.
            const occurrenceFormatted = dayjs().date(occurrence).format("Do")

            if (monthlyRecurrence === 0) {
                return `Every ${interval > 1 ? `${interval} ` : ""}${pluralize("Month", interval)} on day ${day}`
            } else {
                return `Every ${interval > 1 ? `${interval} ` : ""}${pluralize("Month", interval)} on the ${occurrenceFormatted} ${dayOfWeek}`
            }
        } else if (frequency === RecurrenceFrequency.YEARLY) {
            const month = date.format("MMMM")
            const day = date.format("D")
            return `Every ${interval > 1 ? `${interval} ` : ""}${pluralize("Year", interval)} on ${month} ${day}`
        } else {
            return "Unknown"
        }
    }

    return <div className={clsx(styles.base)}>{renderRecurrenceText()}</div>
}
