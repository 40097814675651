import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { CLIENTS_ROUTES } from "@routes/clients"

export const ClientsTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_active"
            phase="active"
            title="All Active"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["ordering", "name"],
                ["is_active", "true"],
            ]}
            listEndpoint={CLIENTS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={CLIENTS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_inactive"
            phase="inactive"
            title="All Inactive"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["ordering", "name"],
                ["is_active", "false"],
            ]}
            listEndpoint={CLIENTS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={CLIENTS_ROUTES.COUNT}
        />
    </TableViewTabs>
)
