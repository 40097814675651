import Spinner from "@legacy/core/components/Spinner"
import clsx from "clsx"

import styles from "@organisms/CardList/CardListEdgeStates/CardListEdgeStates.module.scss"
import useTable from "@organisms/Table/Table.context"

export default function CardListLoadingState() {
    const { tabContentName, activeTab } = useTable()

    if (!activeTab) {
        return null
    }

    return (
        <div
            className={clsx(styles.base, styles.noScroll)}
            aria-label={`${tabContentName} List Loading`}
            role="tabpanel"
        >
            <div className={styles.content}>
                <Spinner />
            </div>
        </div>
    )
}
