import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { Checkbox, Icon } from "@atoms/index"

import EmailNotificationTooltip from "@pages/Settings/SettingsNotifications/components/EmailNotificationTooltip/EmailNotificationTooltip"
import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"

import { EmailCheckboxProps } from "./EmailCheckbox.types"

export default function EmailCheckbox(props: EmailCheckboxProps) {
    const { isChecked, isIndeterminate, onChecked } = props

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()
    const isDesktop = useIsDesktop()

    const { user } = useUser()

    const emailNotificationsDisabled =
        !user?.email || !user?.email_verified || !defaultMethodsPreference?.contact_email

    return (
        <EmailNotificationTooltip>
            <Checkbox
                labelPosition="left"
                label={{
                    children: isDesktop ? (
                        "Email"
                    ) : (
                        <Icon
                            icon={icon({
                                name: "at",
                                family: "sharp",
                                style: "regular",
                            })}
                            size={14}
                        />
                    ),
                }}
                colorScheme="gray"
                size="md"
                onCheckedChange={onChecked}
                checked={isIndeterminate ? "indeterminate" : isChecked}
                isDisabled={emailNotificationsDisabled}
                isControlled={false}
            />
        </EmailNotificationTooltip>
    )
}
