import { ColumnDefResolved, ColumnSort, OnChangeFn, SortingState, Updater } from "@tanstack/react-table"
import { useMemo, useState } from "react"

import useLocalStorage from "@hooks/useLocalStorage"

import extractURLSearchParam from "@utils/extractURLSearchParam"
import getOrderingFromSortingState from "@utils/getOrderingFromSortingState"
import getSortingStateFromOrdering from "@utils/getSortingStateFromOrdering"

import { UseTableSortingStateProps } from "@organisms/Table/Table.types"

import { LIST_SEARCH_PARAM_ORDERING } from "@constants/searchParams"

export default function useTableSortingState<T>(props: UseTableSortingStateProps) {
    const { activeTab, orderingStorageKey, updateCurrentTableViewConfig } = props

    const [sortingChanged, setSortingChanged] = useState(false)

    // Get sorting
    const sortingOverride = useMemo(() => extractURLSearchParam(LIST_SEARCH_PARAM_ORDERING), [])
    const defaultOrdering = useMemo(
        () => activeTab?.endpointKwargs?.find((kwarg) => kwarg[0] === "ordering")?.[1] || "",
        [activeTab],
    )
    const [ordering, setOrdering] = useLocalStorage(orderingStorageKey, "", sortingOverride)

    const sorting = getSortingStateFromOrdering(ordering || (defaultOrdering as string))
    const setSorting = (newSorting: SortingState) => {
        setOrdering(getOrderingFromSortingState(newSorting))
    }

    const onSortingChange: OnChangeFn<SortingState> = (newSorting: Updater<SortingState>) => {
        if (typeof newSorting === "function") {
            newSorting = newSorting(sorting)
        }
        updateCurrentTableViewConfig("sorting", newSorting)
        setSorting(newSorting as ColumnSort[])
        setSortingChanged(true)
    }

    const setCurrentSortToAscending = () => {
        onSortingChange([{ id: sorting[0].id, desc: false }])
    }

    const setCurrentSortToDescending = () => {
        onSortingChange([{ id: sorting[0].id, desc: true }])
    }

    const onSortingItemSelection = (column: ColumnDefResolved<T>) => {
        const notSelected = sorting[0]?.id !== column.accessorKey

        if (notSelected) {
            onSortingChange([
                {
                    id: column.accessorKey as string,
                    desc: false,
                },
            ])
        }
    }

    return {
        sorting,
        setSorting,
        sortingChanged,
        setSortingChanged,
        onSortingChange,
        setCurrentSortToAscending,
        setCurrentSortToDescending,
        onSortingItemSelection,
    }
}
