import { TabItem, Toggle } from "@atoms"

import { Popover, Property, TabStack } from "@molecules"

import PropertyStack from "@organisms/PropertyStack/PropertyStack"

import TimelineViewTimeFrameSelector from "@pages/Jobs/JobList/views/JobTimelineView/TimelineViewMenu/TimelineViewTimeFrameSelector/TimelineViewTimeFrameSelector"
import useJobTimelineViewEventHandlers from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useTimelineViewStates from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewMenuPopover.module.scss"
import { TimelineViewMenuPopoverProps } from "./TimelineViewMenuPopover.types"

export default function TimelineViewMenuPopover(props: TimelineViewMenuPopoverProps) {
    const { isOpen, onClose, anchorRef } = props

    const {
        jobCardStyle,
        setJobCardStyleToCompact,
        setJobCardStyleToRegular,
        shouldShowCancelledJobs,
        // shouldShowFinalizedJobs,
        timelineViewOrientation,
        shouldShowWeekends,
    } = useTimelineViewStates()

    const {
        onCancelledJobsToggle,
        // onFinalizedJobsToggle,
        onSetSchedulerToHorizontal,
        onSetSchedulerToVertical,
        onShowWeekendsToggle,
    } = useJobTimelineViewEventHandlers()

    if (!isOpen) {
        return null
    } else {
        return (
            <Popover.Root isOpen={isOpen} onClose={onClose} anchorRef={anchorRef}>
                <Popover.Content isVisible={isOpen} priority="primary">
                    {() => {
                        return (
                            <>
                                <Popover.Header
                                    title="Timeline View Settings"
                                    icon="fa-sharp-light-timeline-view"
                                    showBorderBottom={true}
                                />

                                <PropertyStack className={styles.content}>
                                    {/* Temporarily disabling these toggles until the filtering is done via the backed.
                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel} htmlFor="show-finalized-jobs">
                                                Show Finalized Jobs
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <Toggle
                                                id="show-finalized-jobs"
                                                checked={shouldShowFinalizedJobs}
                                                onClick={onFinalizedJobsToggle}
                                                size="md"
                                                isControlled={false}
                                            />
                                        </Property.Value>
                                    </Property.Root>
                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel} htmlFor="show-cancelled-jobs">
                                                Show Cancelled Jobs
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <Toggle
                                                size="md"
                                                id="show-cancelled-jobs"
                                                checked={shouldShowCancelledJobs}
                                                onClick={onCancelledJobsToggle}
                                                isControlled={false}
                                            />
                                        </Property.Value>
                                    </Property.Root> */}
                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel} htmlFor="show-weekends">
                                                Show Weekends
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <Toggle
                                                size="md"
                                                id="show-weekends"
                                                checked={shouldShowWeekends}
                                                onClick={onShowWeekendsToggle}
                                                isControlled={false}
                                            />
                                        </Property.Value>
                                    </Property.Root>

                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel}>
                                                Timeline Orientation
                                            </Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <TabStack variant="attached" size="sm">
                                                <TabItem
                                                    isPressed={timelineViewOrientation === "vertical"}
                                                    onClick={onSetSchedulerToVertical}
                                                    noDebounce={true}
                                                >
                                                    Vertical
                                                </TabItem>
                                                <TabItem
                                                    isPressed={timelineViewOrientation === "horizontal"}
                                                    onClick={onSetSchedulerToHorizontal}
                                                    noDebounce={true}
                                                >
                                                    Horizontal
                                                </TabItem>
                                            </TabStack>
                                        </Property.Value>
                                    </Property.Root>

                                    {timelineViewOrientation === "horizontal" && (
                                        <Property.Root className={styles.item}>
                                            <Property.Content>
                                                <Property.Label className={styles.itemLabel}>
                                                    Job Card Style
                                                </Property.Label>
                                            </Property.Content>
                                            <Property.Value>
                                                <TabStack variant="attached" size="sm">
                                                    <TabItem
                                                        isPressed={jobCardStyle === "compact"}
                                                        onClick={setJobCardStyleToCompact}
                                                        noDebounce={true}
                                                    >
                                                        Compact
                                                    </TabItem>
                                                    <TabItem
                                                        isPressed={jobCardStyle === "regular"}
                                                        onClick={setJobCardStyleToRegular}
                                                        noDebounce={true}
                                                    >
                                                        Regular
                                                    </TabItem>
                                                </TabStack>
                                            </Property.Value>
                                        </Property.Root>
                                    )}

                                    <Property.Root className={styles.item}>
                                        <Property.Content>
                                            <Property.Label className={styles.itemLabel}>Time Frame</Property.Label>
                                        </Property.Content>
                                        <Property.Value>
                                            <TimelineViewTimeFrameSelector.Select />
                                        </Property.Value>
                                    </Property.Root>
                                </PropertyStack>
                            </>
                        )
                    }}
                </Popover.Content>
            </Popover.Root>
        )
    }
}
