// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarBodyStart-base__xvEA_{display:flex;align-items:center;min-width:var(--size-0);height:var(--size-13);padding:var(--size-0) var(--size-3);padding-right:var(--size-0);border-bottom:var(--size-0_25) solid var(--gray-a3)}@media only screen and (min-width: 64rem){.TopBarBodyStart-base__xvEA_{height:var(--size-14);padding:var(--size-0);border:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarBodyStart-base__xvEA_"
};
export default ___CSS_LOADER_EXPORT___;
