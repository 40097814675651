import Spinner from '@legacy/core/components/Spinner';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Component, Fragment } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from '../../core/components/Banner';
import CharField from "../../core/fields/CharField";
import EmailField from "../../core/fields/EmailField";
import { formatCurrencyForServiceCompany } from "../../core/utils/utils";
import { calculateEstimateAmounts } from "../utils/utils";


class EstimateOnlinePaymentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            cardholder_name: props.estimate.service_location.external_client.name || "",

            theme: document.documentElement.classList.contains('dark') ? 'dark' : 'light',

            errors: {}
        }
    }

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    submitCardData = async () => {
        const {mode, submitting, estimate, errors, onFormDataChange, requestAction, switchToPrimaryForm, stripe, elements} = this.props

        if (this.state.cardholder_name === "") {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.cardholder_name = "Please supply a cardholder name."
                return updatedState
            })
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors = {}
                return updatedState
            })

            const card = elements.getElement(CardElement);
            const paymentMethodResult = await stripe.createPaymentMethod({
                type: 'card',
                card: card,
                billing_details: {
                  name: this.state.cardholder_name,
                },
            });

            if (paymentMethodResult.error) {
                this.setState({"errors": {"card_data": paymentMethodResult.error.message}})
            } else {
                onFormDataChange(
                    "stripe_payment_method_id",
                    paymentMethodResult.paymentMethod.id,
                    () => onFormDataChange(
                        "receipt_email",
                        this.state.receipt_email,
                        () => requestAction(mode)
                    )
                )
            }
        }
    }

    renderButtons = () => {
        const {mode, submitting, estimate, errors, onFormDataChange, requestAction, switchToPrimaryForm} = this.props
        const { downPaymentAmountDue } = calculateEstimateAmounts(estimate)

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text={`Pay ${formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)} Now`} handler={event => this.submitCardData()} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Pay Later" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {mode, submitting, estimate, errors, onFormDataChange, requestAction, switchToPrimaryForm, stripe, elements} = this.props
        const {downPaymentAmountDue} = calculateEstimateAmounts(estimate)

        return stripe && elements && (
            <Fragment>
                <div className="data-panel-container data-panel-container--with-margin">
                    <div className="data-panel" aria-label="Pay Online">
                        <div className="data-panel__form data-panel__form--no-margin" aria-label="Pay Online Form">
                            <fieldset>
                                <legend>Pay with Card {window.STRIPE_PUBLISHABLE_KEY && window.STRIPE_PUBLISHABLE_KEY.includes("test") && <span className="stripe-test-signifier">Test Mode</span>}</legend>
                                <p className="data-panel__form__caption">Add your card details below to pay this estimate online. Your data is securely managed by our payments partner, <a className="text-link text-link--inline text-link--inline-stripe" href="https://stripe.com" target="_blank"><i className="fa-brands fa-stripe inline-stripe" aria-label="Stripe"></i></a>.</p>

                                <div className="data-panel__form__field" id="div_id_card_data" aria-label="Card Information">
                                    <label htmlFor="id_card_data" className="data-panel__form__field__label in-form">Card Information</label>
                                    <div className="data-panel__form__field__input data-panel__form__field__input--stripe-elements">
                                        <CardElement options={{
                                            style: {
                                                base: {
                                                    color: this.state.theme === 'dark' ? "#eeeeee" : "#202020",
                                                    "::placeholder": {
                                                        color: this.state.theme === 'dark' ? "#7b7b7b" : "#838383",
                                                    },
                                                },
                                            }
                                        }}/>
                                    </div>
                                    {errors.card_data && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.card_data}</div>}
                                </div>

                                <CharField
                                    fieldName="cardholder_name"
                                    fieldLabel="Name on Card"
                                    fieldValue={this.state.cardholder_name || ""}
                                    fieldOnChange={cardholder_name => this.setState({"cardholder_name": cardholder_name || ""})}
                                    errors={this.state.errors}  /* Name is validated inside of this component */
                                ></CharField>
                                <EmailField
                                    fieldName="receipt_email"
                                    fieldLabel="Receipt Email"
                                    fieldValue={this.state.receipt_email || ""}
                                    fieldOnChange={receipt_email => this.setState({"receipt_email": receipt_email || ""})}
                                    errors={errors}
                                ></EmailField>
                                <Banner type="info" text={<span>You're paying <strong>{formatCurrencyForServiceCompany(downPaymentAmountDue, estimate.service_company)} {estimate.service_company.currency_code}</strong> to <strong>{estimate.service_company.name}</strong> as a down payment on Estimate <strong>{estimate.custom_id ? estimate.custom_id : estimate.id}</strong></span>} />
                            </fieldset>
                        </div>
                        {this.renderButtons()}
                    </div>
                </div>
                {window.USING_PUBLIC_URL === true &&
                    <div className="powered-by-panel">
                        <span>Powered By</span>
                        <img className="logo--grey" src={window.LOGOTYPE_URL} width="148px" alt="Roopairs"></img>
                    </div>
                }
            </Fragment>
        )
    }
}

export default (props) => <ElementsConsumer>{({stripe, elements}) => <EstimateOnlinePaymentForm {...props} stripe={stripe} elements={elements} />}</ElementsConsumer>
