import Spinner from "@legacy/core/components/Spinner";
import formatDuration from "@utils/formatDuration";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import Banner from "../../core/components/Banner";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import SearchCreateSelectField from "../../core/fields/SearchCreateSelectField";
import SwitchField from "../../core/fields/SwitchField";
import TextField from "../../core/fields/TextField";
import { JobOriginTypes, JobStatuses } from "../../core/utils/enums";
import { valueIsDefined } from "../../core/utils/utils";
import JobSubStatusSearchOrCreateSelect, { subStatusToSubStatusOption } from "../inputs/JobSubStatusSearchOrCreateSelect";
import { renderJobTime } from "../utils/utils";


class JobPauseForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const { job, pauseData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, returnScroll } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Pause" handler={event => requestAction("JOB_PAUSE_SUBMIT")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Pause and Edit Job Summary" handler={event => requestAction("JOB_PAUSE_SUBMIT_AND_EDIT")} />
                        </ButtonGroupRow>
                        <ButtonGroupRow>
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const { job, pauseData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, updateSubStatusSelection, selectedSubStatus, returnScroll } = this.props
        let placeholderText
        let infoBannerText
        let infoWarningBannerText = "The pause reason should be used to add brief context on why a job needs to be paused. For all additions to the Job Summary, click the “Pause and Edit Job Summary” button below to be taken to the Job Summary page."

        if (pauseData.place_on_hold) {
            placeholderText = "Why do you need to place this job on hold? e.g. 'Parts on order; will reschedule when they arrive.'"
            infoBannerText = "Placing a Job on hold is for longer pauses, like if you need to wait for parts to be shipped and aren't sure when to reschedule yet."
        }
        else {
            placeholderText = "Why do you need to quick-pause this job? e.g. 'Grabbing lunch.' or 'Grabbing parts.'"
            infoBannerText = "Quick-Pausing is typically for periods within the same day -- for things like taking a lunch or heading to the shop for parts."
        }

        if (selectedSubStatus?.reason_placeholder) {
            placeholderText = selectedSubStatus.reason_placeholder
        }

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Job Pause">
                    <div className="data-panel__form" aria-label="Job Pause Form">
                        <p className="data-panel__form__caption">Please confirm you'd like to pause this job.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Job ID"
                            fieldValue={job.custom_id ? job.custom_id : job.id}
                        ></BasicDisplayField>
                        {
                            job.origin_type !== JobOriginTypes.roopairs && (
                                <BasicDisplayField
                                    fieldName="origin_id"
                                    fieldLabel={`${job.origin_type !== JobOriginTypes.other ? job.origin_type_label : job.origin_type_other_name} ID`}
                                    fieldValue={job.origin_id}
                                ></BasicDisplayField>
                            )
                        }
                        <BasicDisplayField
                            fieldName="service_name"
                            fieldLabel="Service Type"
                            fieldValue={job.service_name}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="is_job_walk"
                            fieldLabel="Job Type"
                            fieldValue={job.is_callback ? "Callback" : job.is_job_walk ? "Job Walk" : "Service"}
                        ></BasicDisplayField>

                        <BasicDisplayField
                            fieldName="total_time_logged"
                            fieldLabel="Total Time Logged"
                            fieldValue={formatDuration(job.total_time_logged)}
                        ></BasicDisplayField>
                        <BasicDisplayField
                            fieldName="last_active_at"
                            fieldLabel="Last Started/Resumed On"
                            fieldValue={renderJobTime(job.last_active_at, job.service_company.preferred_timezone)}
                        ></BasicDisplayField>

                        <Banner type="info" text={infoBannerText} />

                        <SwitchField
                            fieldName="place_on_hold"
                            fieldLabel="Pause Type"
                            fieldValue={valueIsDefined(pauseData.place_on_hold) ? pauseData.place_on_hold : false}
                            fieldOnChange={place_on_hold => {
                                onFormDataChange("place_on_hold", place_on_hold)
                                updateSubStatusSelection(null)
                            }}
                            uncheckedText="Quick-Pause"
                            checkedText="On Hold"
                            errors={errors}
                        ></SwitchField>

                        {
                            window.FEATURE_CUSTOM_SUBSTATUSES_ENABLED && window.PAUSED_SUBSTATUSES_EXIST && (
                                <SearchCreateSelectField
                                    fieldName="substatus"
                                    fieldLabel="Sub-Status"
                                    fieldValue={selectedSubStatus}
                                    inputComponent={
                                        <JobSubStatusSearchOrCreateSelect
                                            onSelectionChange={selectedOption => updateSubStatusSelection(selectedOption != null ? selectedOption.object : null)}
                                            defaultSelected={selectedSubStatus !== null ? subStatusToSubStatusOption(selectedSubStatus) : null}
                                            showCreateButton={false}
                                            selectedStatus={valueIsDefined(pauseData.place_on_hold) ? (pauseData.place_on_hold ? JobStatuses.on_hold : JobStatuses.paused) : JobStatuses.paused}
                                        ></JobSubStatusSearchOrCreateSelect>
                                    }
                                    showButton={false}
                                    errors={errors}
                                ></SearchCreateSelectField>
                            )
                        }
                        {(pauseData.pause_message?.length || 0) > 60 && <Banner type="warning" text={infoWarningBannerText} extraMargin={true} />}
                        <TextField
                            key={selectedSubStatus?.id}
                            fieldName="pause_message"
                            fieldLabel={selectedSubStatus !== null && selectedSubStatus.reason_label !== "" ? selectedSubStatus.reason_label : "Pause Reason"}
                            fieldValue={pauseData.pause_message || ""}
                            fieldOnChange={pause_message => onFormDataChange("pause_message", pause_message || "")}
                            rows={3}
                            placeholder={placeholderText}
                            optional={selectedSubStatus === null || !selectedSubStatus.reason_required}
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default JobPauseForm;
