import Spinner from "@legacy/core/components/Spinner";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Component } from "react";
import deepcopy from "rfdc";
import LineItemForm from "../core/forms/LineItemForm";
import { LineItemUnitTypes, PriceBookItemTypes } from "../core/utils/enums";
import { currencyFormatter, getCurrencySymbol, getIsTaxable, historyHasState, sendDataToServer, valueIsDefined } from "../core/utils/utils";
import { validateLineItem } from "../invoices/utils/utils";
import JobSummaryForm from "./forms/JobSummaryForm";
import { getUnprefixedJobTitle } from "./utils/utils";

dayjs.extend(timezone)

const FORM_MODES = {
    ADD_JOB_SUMMARY: "ADD_JOB_SUMMARY",
    ADD_SERVICE_CHARGE: "ADD_SERVICE_CHARGE",
    EDIT_SERVICE_CHARGE: "EDIT_SERVICE_CHARGE",
    ADD_PART: "ADD_PART",
    EDIT_PART: "EDIT_PART",
    ADD_OTHER_CHARGE: "ADD_OTHER_CHARGE",
    EDIT_OTHER_CHARGE: "EDIT_OTHER_CHARGE",
    ADD_DISCOUNT: "ADD_DISCOUNT",
    EDIT_DISCOUNT: "EDIT_DISCOUNT",
}

const FORM_MODE_SUBTITLES = {
    ADD_JOB_SUMMARY: "Job Summary",
    ADD_SERVICE_CHARGE: "Add Service Charge",
    EDIT_SERVICE_CHARGE: "Edit Service Charge",
    ADD_PART: "Add Part or Material",
    EDIT_PART: "Edit Part or Material",
    ADD_OTHER_CHARGE: "Add Miscellaneous Charge",
    EDIT_OTHER_CHARGE: "Edit Miscellaneous Charge",
    ADD_DISCOUNT: "Add Discount",
    EDIT_DISCOUNT: "Edit Discount",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_JOB_SUMMARY: "flex",
    ADD_SERVICE_CHARGE: "none",
    EDIT_SERVICE_CHARGE: "none",
    ADD_PART: "none",
    EDIT_PART: "none",
    ADD_OTHER_CHARGE: "none",
    EDIT_OTHER_CHARGE: "none",
    ADD_DISCOUNT: "none",
    EDIT_DISCOUNT: "none",
}

const PRIMARY_FORM_MODES = [FORM_MODES.ADD_JOB_SUMMARY]
const SECONDARY_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_CHARGE,
    FORM_MODES.EDIT_SERVICE_CHARGE,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
    FORM_MODES.ADD_OTHER_CHARGE,
    FORM_MODES.EDIT_OTHER_CHARGE,
    FORM_MODES.ADD_DISCOUNT,
    FORM_MODES.EDIT_DISCOUNT,
]
const LINE_ITEM_FORM_MODES = [
    FORM_MODES.ADD_SERVICE_CHARGE,
    FORM_MODES.EDIT_SERVICE_CHARGE,
    FORM_MODES.ADD_PART,
    FORM_MODES.EDIT_PART,
    FORM_MODES.ADD_OTHER_CHARGE,
    FORM_MODES.EDIT_OTHER_CHARGE,
    FORM_MODES.ADD_DISCOUNT,
    FORM_MODES.EDIT_DISCOUNT,
]

const FORM_DATA_NAMES_BY_MODE = {
    ADD_JOB_SUMMARY: "jobData",
    ADD_SERVICE_CHARGE: "lineItemData",
    EDIT_SERVICE_CHARGE: "lineItemData",
    ADD_PART: "lineItemData",
    EDIT_PART: "lineItemData",
    ADD_OTHER_CHARGE: "lineItemData",
    EDIT_OTHER_CHARGE: "lineItemData",
    ADD_DISCOUNT: "lineItemData",
    EDIT_DISCOUNT: "lineItemData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_JOB_SUMMARY: "submittingJob",
    ADD_SERVICE_CHARGE: "submittingLineItem",
    EDIT_SERVICE_CHARGE: "submittingLineItem",
    ADD_PART: "submittingLineItem",
    EDIT_PART: "submittingLineItem",
    ADD_OTHER_CHARGE: "submittingLineItem",
    EDIT_OTHER_CHARGE: "submittingLineItem",
    ADD_DISCOUNT: "submittingLineItem",
    EDIT_DISCOUNT: "submittingLineItem",
}

const ERROR_NAMES_BY_MODE = {
    ADD_JOB_SUMMARY: "job",
    ADD_SERVICE_CHARGE: "lineItem",
    EDIT_SERVICE_CHARGE: "lineItem",
    ADD_PART: "lineItem",
    EDIT_PART: "lineItem",
    ADD_OTHER_CHARGE: "lineItem",
    EDIT_OTHER_CHARGE: "lineItem",
    ADD_DISCOUNT: "lineItem",
    EDIT_DISCOUNT: "lineItem",
}


const LINE_ITEM_TYPES = {
    SERVICE_CHARGES: "service_charges",
    PARTS: "parts",
    OTHER_CHARGES: "other_charges",
    DISCOUNTS: "discounts",
}

const LINE_ITEM_TYPE_MAP = {
    [LINE_ITEM_TYPES.SERVICE_CHARGES]: PriceBookItemTypes.service,
    [LINE_ITEM_TYPES.PARTS]: PriceBookItemTypes.part,
    [LINE_ITEM_TYPES.OTHER_CHARGES]: PriceBookItemTypes.other,
    [LINE_ITEM_TYPES.DISCOUNTS]: PriceBookItemTypes.discount,
}

const LINE_ITEM_TYPE_BY_MODE = {
    ADD_SERVICE_CHARGE: PriceBookItemTypes.service,
    EDIT_SERVICE_CHARGE: PriceBookItemTypes.service,
    ADD_PART: PriceBookItemTypes.part,
    EDIT_PART: PriceBookItemTypes.part,
    ADD_OTHER_CHARGE: PriceBookItemTypes.other,
    EDIT_OTHER_CHARGE: PriceBookItemTypes.other,
    ADD_DISCOUNT: PriceBookItemTypes.discount,
    EDIT_DISCOUNT: PriceBookItemTypes.discount,
}


class JobSummaryContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_JOB_SUMMARY
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            jobData: null,
            lineItemData: {},
            deletedLineItemIDs: {},
            priceBookClientOverrides: [],

            errors: {
                job: {},
                lineItem: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,

            attachments: [],

            priceBookServices: window.PRICEBOOK_SERVICES || [],
            pricebook_default_taxable_service: window.PRICEBOOK_DEFAULT_TAXABLE_SERVICE,
            pricebook_default_taxable_part: window.PRICEBOOK_DEFAULT_TAXABLE_PART,
            pricebook_default_taxable_other: window.PRICEBOOK_DEFAULT_TAXABLE_OTHER,

            preferredTimezone: window.PREFERRED_TIMEZONE,
            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
            useTaxes: window.USE_TAXES,
            jobSendEnabled: window.JOB_SEND_ENABLED,

            fileStackAPIKey: window.FILESTACK_API_KEY,
            fileStackPolicy: window.FILESTACK_POLICY,
            fileStackSignature: window.FILESTACK_SIGNATURE,

            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {
        if (this.state.jobData === null) {
            const endpoint = DjangoUrls["jobs:api-jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
            const response = await fetch(endpoint)
            const job = await response.json()
            const clientID = job.service_location.external_client.id

            const overridesEndpoint =
                DjangoUrls["pricebook:api-pricebookitem-client-overrides-list"](window.MARKETPLACE_ENTITY_SLUG) +
                `?external_client=${clientID}`;

            const responseOverrides = await fetch(overridesEndpoint);
            const clientOverrides = await responseOverrides.json();

            this.setState((state, props) => {
                let updatedState = state

                updatedState.jobData = job
                updatedState.priceBookClientOverrides = clientOverrides || []

                // Split line items into their respective lists
                updatedState.jobData.service_charges = updatedState.jobData.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.service)
                updatedState.jobData.parts = updatedState.jobData.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.part)
                updatedState.jobData.other_charges = updatedState.jobData.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.other)
                updatedState.jobData.discounts = updatedState.jobData.line_items.filter(lineItem => lineItem.line_item_type === PriceBookItemTypes.discount)

                updatedState.attachments = deepcopy()(job.attachments)

                let startDate = dayjs.tz(new Date(job.estimated_arrival_time), this.state.preferredTimezone)
                const endDate = dayjs.tz(new Date(), this.state.preferredTimezone)
                let startDateValue
                let endDateValue

                // If this job is started early, ensure the start date isn't after the end date
                if (endDate.isBefore(startDate)) {
                    startDate = endDate
                }

                // Set the final date. Job walks don't get service dates
                if (job.is_job_walk) {
                    startDateValue = null
                    endDateValue = null
                }
                else {
                    startDateValue = startDate.format("YYYY-MM-DD")
                    endDateValue = endDate.format("YYYY-MM-DD")
                }

                if (updatedState.jobData.service_charges.length === 0) {
                    const selectedServiceOption = state.priceBookServices.find(service => service.description === state.jobData.service_name)

                    if (selectedServiceOption) {
                        const matchingOverride = updatedState.priceBookClientOverrides.find(
                            (override) => override.pricebook_item.id === selectedServiceOption.id
                        );
                        updatedState.jobData.service_charges = [
                            {
                                "id": null,
                                "line_item_type": PriceBookItemTypes.service,
                                "description": selectedServiceOption.description,
                                "service_start_date": startDateValue,
                                "service_end_date": endDateValue,
                                "unit_type": selectedServiceOption.default_unit_type,
                                "cost": parseFloat(selectedServiceOption.cost),
                                "price": matchingOverride? parseFloat(matchingOverride.price) : parseFloat(selectedServiceOption.default_price),
                                "quantity": selectedServiceOption.default_unit_type === LineItemUnitTypes.flat_rate ? 1 : (job.estimated_duration / 3600),
                                "summary": selectedServiceOption.summary_template,
                                "is_taxable": selectedServiceOption.default_is_taxable,
                                "quickbooks_desktop_item_id": selectedServiceOption.quickbooks_desktop_id,
                            }
                        ]
                    }
                    else {
                        updatedState.jobData.service_charges = [
                            {
                                "id": null,
                                "line_item_type": PriceBookItemTypes.service,
                                "description": getUnprefixedJobTitle(updatedState.jobData.__str__),
                                "service_start_date": startDateValue,
                                "service_end_date": endDateValue,
                                "summary": "",
                            }
                        ]
                    }
                }
                else {
                    // Auto-populate dates for existing service charges
                    // This use case is almost always for jobs that come from an invoice
                    updatedState.jobData.service_charges.forEach(item => {
                        if (item.service_start_date === null) {
                            item.service_start_date = startDateValue
                        }

                        if (item.service_end_date === null) {
                            item.service_end_date = endDateValue
                        }
                    })
                }

                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                // To help with uniqueness check
                data.originalDescription = data.description
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }
            else {
                if (LINE_ITEM_FORM_MODES.includes(newFormMode)) {
                    updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]].line_item_type = LINE_ITEM_TYPE_BY_MODE[newFormMode]
                }
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    // Crud Line Items

    addCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.jobData, this.state.lineItemData, false, false, !this.state.jobData.is_job_walk, false, false)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData
                data.line_item_type = LINE_ITEM_TYPE_MAP[lineItemListName]

                // Re-use an existing ID for this description to avoid uniqueness constraint issues
                if (data.description in state.deletedLineItemIDs) {
                    data.id = state.deletedLineItemIDs[data.description]
                }
                else {
                    data.id = null
                }

                if (!valueIsDefined(data.cost)) {
                    data.cost = null
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                if (!valueIsDefined(data.is_taxable)) {
                    data.is_taxable = getIsTaxable(data, this.state.pricebook_default_taxable_service, this.state.pricebook_default_taxable_part, this.state.pricebook_default_taxable_other)
                }

                updatedState.jobData[lineItemListName].push(data)
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    lineItemEquals = (existing, updated) => {
        // If the object is new, it won't have an id. Check the description instead
        return existing.id !== null ? existing.id === updated.id : existing.description === updated.originalDescription
    }

    updateCurrentLineItem = (lineItemListName) => {
        const {isValid, errors} = validateLineItem(this.state.jobData, this.state.lineItemData, false, false, !this.state.jobData.is_job_walk, false, false)

        if (isValid) {
            this.setState((state, props) => {
                let updatedState = state

                let data = state.lineItemData

                if (!valueIsDefined(data.cost)) {
                    data.cost = null
                }

                if (!valueIsDefined(data.price)) {
                    data.price = null
                }

                if (data.line_item_type === PriceBookItemTypes.service) {
                    if (!valueIsDefined(data.unit_type)) {
                        data.unit_type = LineItemUnitTypes.hourly
                    }

                    if (data.unit_type === LineItemUnitTypes.flat_rate) {
                        data.quantity = 1
                    }
                }
                else {
                    delete data.unit_type
                }

                const lineItemIndex = updatedState.jobData[lineItemListName].findIndex(lineItem => this.lineItemEquals(lineItem, state.lineItemData))

                updatedState.jobData[lineItemListName][lineItemIndex] = data
                updatedState.errors.lineItem = {}
                return updatedState
            })
            this.switchToPrimaryForm()
        }
        else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.errors.lineItem = errors
                return updatedState
            })
        }
    }

    updateAttachments = (attachmentUploadData) => {
        this.setState((state, props) => {
            let updatedState = state

            updatedState.attachments.push(...attachmentUploadData)
            updatedState.jobData.attachments.push(...attachmentUploadData)

            return updatedState
        })
    }

    deleteCurrentLineItem = (lineItemListName) => {
        this.setState((state, props) => {
            let updatedState = state

            const data = state.lineItemData

            // Save the ID in case this description is going to be reused
            if (data.id) {
                updatedState.deletedLineItemIDs[data.description] = data.id
            }

            updatedState.jobData[lineItemListName] = updatedState.jobData[lineItemListName].filter(lineItem => !this.lineItemEquals(lineItem, data))
            updatedState.errors.lineItem = {}
            return updatedState
        })
        this.switchToPrimaryForm()
    }

    // Update summary

    submitSummary = async () => {
        const endpoint = DjangoUrls["jobs:api-jobs-summary-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        const endpointMethod = "PUT"

        const dataName = "jobData"
        const submittingName = "submittingJob"
        const errorDictName = "job"

        let successUrl

        if (this.props.isInCompleteFlow) {
            successUrl = DjangoUrls["jobs:jobs-complete-confirm"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID)
        }
        else {
            successUrl = DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID) + (this.state.jobSendEnabled ? "?mode=send-job" : "")
        }

        const onSuccess = (job) => {
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Job "${job.custom_id || job.id}" summary updated`,
                path: successUrl.split("?")[0],
                barePathOnly: true,
                delayRender: true,
            })
            history.replaceState({}, "", "")
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Job summary could not be updated",
            })
        }

        const dataManipulator = (data, state) => {
            data.line_items = [...data.service_charges, ...data.parts, ...data.other_charges, ...data.discounts]

            // Convert blank Estimate ID value to null
            data.estimate_custom_id = data.estimate_custom_id || null

            // Convert blank Invoice ID value to null
            data.invoice_custom_id = data.invoice_custom_id || null

            // Send whether we're in the complete flow. This determines whether to send notifications and show modals
            data.is_in_complete_flow = this.props.isInCompleteFlow

            // Don't validate minimum line item count with summaries if this is not part of the completion flow
            data.validate_minimum_line_items = this.props.isInCompleteFlow || ["Completed", "Finalized"].includes(this.state.jobData.state_label)

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "line_items_subtotal") {
                errorDict["line_items"] = message
            }
            else if (fieldName === "line_items") {
                errorDict["line_items"] = "Please correct the line item errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state

                    const indexedLineItems = [
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.SERVICE_CHARGES],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.PARTS],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.OTHER_CHARGES],
                        ...updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]][LINE_ITEM_TYPES.DISCOUNTS]
                    ]
                    message.map((lineItemError, index) => indexedLineItems[index].errors = lineItemError)

                    return updatedState
                })
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

     // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "SUBMIT_JOB_SUMMARY":
                this.submitSummary()
                break
            case "JOB_SUMMARY_CANCEL_EDITS":
                if (this.state.jobData.equipment_expected && this.props.isInCompleteFlow) {
                    location.assign(DjangoUrls["jobs:jobs-equipment-summary"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID))
                }
                else {
                    location.assign(DjangoUrls["jobs:jobs-detail"](window.MARKETPLACE_ENTITY_SLUG, window.JOB_ID))
                }
                break
            case "ADD_SERVICE_CHARGE":
                this.addCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "EDIT_SERVICE_CHARGE":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "DELETE_SERVICE_CHARGE":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.SERVICE_CHARGES)
                break
            case "ADD_PART":
                this.addCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "EDIT_PART":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "DELETE_PART":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.PARTS)
                break
            case "ADD_OTHER_CHARGE":
                this.addCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "EDIT_OTHER_CHARGE":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "DELETE_OTHER_CHARGE":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.OTHER_CHARGES)
                break
            case "ADD_DISCOUNT":
                this.addCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "EDIT_DISCOUNT":
                this.updateCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            case "DELETE_DISCOUNT":
                this.deleteCurrentLineItem(LINE_ITEM_TYPES.DISCOUNTS)
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.jobData === null) {
            return <Spinner centered={true} />
        }
        else {
            if (PRIMARY_FORM_MODES.includes(this.state.mode)) {
                return (
                    <JobSummaryForm
                        mode={this.state.mode}
                        job={this.state.jobData}
                        submitting={this.state.submittingJob}
                        errors={this.state.errors.job}
                        onFormDataChange={(fieldName, fieldValue) => this.updateFormData("jobData", fieldName, fieldValue)}
                        requestAction={this.handleActionRequest}
                        switchToSecondaryForm={this.switchToSecondaryForm}
                        isInCompleteFlow={this.props.isInCompleteFlow}
                        fileStackAPIKey={this.state.fileStackAPIKey}
                        fileStackPolicy={this.state.fileStackPolicy}
                        fileStackSignature={this.state.fileStackSignature}
                        updateAttachments={this.updateAttachments}
                        returnScroll={this.state.returnScroll}
                        addToastToQueue={this.addToastToQueue}
                    ></JobSummaryForm>
                )
            }
            else if (LINE_ITEM_FORM_MODES.includes(this.state.mode)) {
                return <LineItemForm
                    mode={this.state.mode}
                    submitting={this.state.submittingLineItem}
                    parent={this.state.jobData}
                    lineItem={this.state.lineItemData}
                    priceBookClientOverrides={this.state.priceBookClientOverrides}
                    formatCurrencyValue={currencyFormatter(this.state.currencyCode, this.state.languageCode)}
                    currencySymbol={getCurrencySymbol(this.state.currencyCode, this.state.languageCode)}
                    errors={this.state.errors.lineItem}
                    onFormDataChange={(fieldName, fieldValue) => this.updateFormData("lineItemData", fieldName, fieldValue)}
                    requestAction={this.handleActionRequest}
                    switchToPrimaryForm={this.switchToPrimaryForm}
                    returnScroll={0}
                    objectName={this.state.jobData.is_job_walk ? "Estimate" : "Invoice"}
                    preferredTimezone={this.state.preferredTimezone}
                    isJobLineItem={true}
                    useTaxes={this.state.useTaxes}
                    pricebookDefaultTaxableService={this.state.pricebook_default_taxable_service}
                    pricebookDefaultTaxablePart={this.state.pricebook_default_taxable_part}
                    pricebookDefaultTaxableOther={this.state.pricebook_default_taxable_other}
                    estimatedDuration={this.state.jobData.estimated_duration}
                    totalTimeLogged={this.state.jobData.total_time_logged}
                ></LineItemForm>
            }
            else {
                return (
                    <div className="data-panel-container data-panel-container--with-margin">
                        <div className="data-panel" aria-label="Unknown Form Mode">
                            <div className="data-panel__form">
                                <p className="data-panel__form__caption">An unhandled form mode was supplied.</p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}

export default JobSummaryContainer;
