import { useMemo } from "react"

import useIsDesktop from "@hooks/useIsDesktop"

import generateRandomKey from "@utils/generateRandomKey"
import getObjectsViewIcon from "@utils/getObjectsViewIcon"
import getObjectsViewLabel from "@utils/getObjectsViewLabel"

import { TabItem } from "@atoms"

import { Select, TabStack } from "@molecules"

import useViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider.context"

import styles from "./TopBarViewSelector.module.scss"
import { TopBarViewSelectorProps } from "./TopBarViewSelector.types"

export default function TopBarViewSelector(props: TopBarViewSelectorProps) {
    const { viewTypes } = props

    const { activeViewType, setActiveViewType } = useViewTypesProvider()

    const isDesktop = useIsDesktop()

    const selectedViewTypeIcon = useMemo(() => {
        return getObjectsViewIcon(activeViewType)
    }, [activeViewType])

    const selectedViewTypeLabel = useMemo(() => {
        return getObjectsViewLabel(activeViewType)
    }, [activeViewType])

    const listItems = viewTypes.map((viewType) => (
        <Select.ListItem
            key={generateRandomKey()}
            item={{
                iconInReactNode: useMemo(() => getObjectsViewIcon(viewType), []),
                value: viewType,
                label: useMemo(() => getObjectsViewLabel(viewType) ?? "", []),
            }}
            onSelect={() => setActiveViewType(viewType)}
            isSelected={activeViewType === viewType}
        />
    ))

    const tabStackTabs = viewTypes.map((viewType) => (
        <TabItem
            key={viewType}
            iconInReactNode={getObjectsViewIcon(viewType)}
            isPressed={activeViewType === viewType}
            onClick={() => setActiveViewType(viewType)}
        >
            {isDesktop && getObjectsViewLabel(viewType)}
        </TabItem>
    ))

    const showSelect = isDesktop ? viewTypes.length > 4 : viewTypes.length > 2

    return (
        <div className={styles.base}>
            {showSelect ? (
                <Select.Root>
                    <Select.Input
                        iconInReactNode={selectedViewTypeIcon}
                        text={selectedViewTypeLabel}
                        placeholder="Select view"
                        showLabel={isDesktop}
                        className={styles.droplist}
                        isTabbable={true}
                        size={isDesktop ? "sm" : "md"}
                    />
                    <Select.List className={styles.droplist}>
                        <Select.ListItemStack>{listItems}</Select.ListItemStack>
                    </Select.List>
                </Select.Root>
            ) : (
                <TabStack variant="attached" size="sm">
                    {tabStackTabs}
                </TabStack>
            )}
        </div>
    )
}
