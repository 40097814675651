// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineViewEventCardIcon-base__v0ej2{display:flex;flex-shrink:0;align-items:center;justify-content:center;width:var(--size-6);height:var(--size-6)}.TimelineViewEventCardIcon-scheduled__whKX9{color:var(--blue-9)}.TimelineViewEventCardIcon-active__XMjb3{color:var(--jade-9)}.TimelineViewEventCardIcon-paused__BZlWr{color:var(--amber-9)}.TimelineViewEventCardIcon-pastDue__B8hJm{color:var(--tomato-9)}.TimelineViewEventCardIcon-onHold__cCslP{color:var(--orange-11)}.TimelineViewEventCardIcon-onHoldInternal__oQBEr{color:var(--amber-11)}.TimelineViewEventCardIcon-onHoldExternal__mNnPb{color:var(--gray-12)}.TimelineViewEventCardIcon-completed__VN6TW{color:var(--orange-9)}.TimelineViewEventCardIcon-finalized__zMT62{color:var(--gray-8)}.TimelineViewEventCardIcon-cancelled__K1dUv{color:var(--gray-8)}.TimelineViewEventCardIcon-pending__xz71Z{color:var(--violet-9)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TimelineViewEventCardIcon-base__v0ej2",
	"scheduled": "TimelineViewEventCardIcon-scheduled__whKX9",
	"active": "TimelineViewEventCardIcon-active__XMjb3",
	"paused": "TimelineViewEventCardIcon-paused__BZlWr",
	"pastDue": "TimelineViewEventCardIcon-pastDue__B8hJm",
	"onHold": "TimelineViewEventCardIcon-onHold__cCslP",
	"onHoldInternal": "TimelineViewEventCardIcon-onHoldInternal__oQBEr",
	"onHoldExternal": "TimelineViewEventCardIcon-onHoldExternal__mNnPb",
	"completed": "TimelineViewEventCardIcon-completed__VN6TW",
	"finalized": "TimelineViewEventCardIcon-finalized__zMT62",
	"cancelled": "TimelineViewEventCardIcon-cancelled__K1dUv",
	"pending": "TimelineViewEventCardIcon-pending__xz71Z"
};
export default ___CSS_LOADER_EXPORT___;
