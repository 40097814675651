import { useState } from "react"

import { TabItem, Toggle } from "@atoms"

import { TabStack } from "@molecules"

import { BottomSheet } from "@organisms"

import TimelineViewTimeFrameSelector from "@pages/Jobs/JobList/views/JobTimelineView/TimelineViewMenu/TimelineViewTimeFrameSelector/TimelineViewTimeFrameSelector"
import useJobTimelineViewEventHandlers from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewEventHandlers"
import useJobTimelineViewStates from "@pages/Jobs/JobList/views/JobTimelineView/hooks/useJobTimelineViewStates"

import styles from "./TimelineViewMenuBottomSheet.module.scss"
import { TimelineViewMenuBottomSheetProps } from "./TimelineViewMenuBottomSheet.types"

export default function TimelineViewMenuBottomSheet(props: TimelineViewMenuBottomSheetProps) {
    const { isOpen, onClose } = props

    const [isSubItemsOpen, setIsSubItemsOpen] = useState<boolean>(false)

    const {
        jobCardStyle,
        setJobCardStyleToCompact,
        setJobCardStyleToRegular,
        // shouldShowCancelledJobs,
        // shouldShowFinalizedJobs,
        timelineViewOrientation,
        shouldShowWeekends,
    } = useJobTimelineViewStates()

    const {
        // onCancelledJobsToggle,
        // onFinalizedJobsToggle,
        onSetSchedulerToHorizontal,
        onSetSchedulerToVertical,
        onShowWeekendsToggle,
    } = useJobTimelineViewEventHandlers()

    const openTimeFrameSelect = () => {
        setIsSubItemsOpen(true)
    }
    const closeTimeFrameSelect = () => {
        setIsSubItemsOpen(false)
    }

    return (
        <BottomSheet.Root isOpen={isOpen} onClose={onClose}>
            <BottomSheet.Content
                header={{
                    title: "Timeline View Settings",
                    titleIcon: "fa-sharp-light-timeline-view",
                    isTabbable: isOpen,
                    showDragIndicator: true,
                }}
                priority="primary"
                isVisible={!isSubItemsOpen}
            >
                {(isTabbable) => (
                    <div className={styles.content}>
                        {/* Temporarily disabling these toggles until the filtering is done via the backed.
                        <BottomSheet.Item
                            isTabbable={isTabbable}
                            label="Show Finalized Jobs"
                            onClick={onFinalizedJobsToggle}
                        >
                            <Toggle size="md" checked={shouldShowFinalizedJobs} isControlled={false} />
                        </BottomSheet.Item>
                        <BottomSheet.Item
                            isTabbable={isTabbable}
                            label="Show Cancelled Jobs"
                            onClick={onCancelledJobsToggle}
                        >
                            <Toggle size="md" checked={shouldShowCancelledJobs} isControlled={false} />
                        </BottomSheet.Item> */}
                        <BottomSheet.Item label="Show Weekends" onClick={onShowWeekendsToggle}>
                            <Toggle size="md" checked={shouldShowWeekends} isControlled={false} />
                        </BottomSheet.Item>
                        <BottomSheet.Item isTabbable={isTabbable} label="Timeline Orientation">
                            <TabStack variant="attached" size="sm">
                                <TabItem
                                    isPressed={timelineViewOrientation === "vertical"}
                                    onClick={onSetSchedulerToVertical}
                                    noDebounce={true}
                                >
                                    Vertical
                                </TabItem>
                                <TabItem
                                    isPressed={timelineViewOrientation === "horizontal"}
                                    onClick={onSetSchedulerToHorizontal}
                                    noDebounce={true}
                                >
                                    Horizontal
                                </TabItem>
                            </TabStack>
                        </BottomSheet.Item>
                        {timelineViewOrientation === "horizontal" && (
                            <BottomSheet.Item isTabbable={isTabbable} label="Job Card Style">
                                <TabStack variant="attached" size="sm">
                                    <TabItem
                                        isPressed={jobCardStyle === "compact"}
                                        onClick={setJobCardStyleToCompact}
                                        noDebounce={true}
                                    >
                                        Compact
                                    </TabItem>
                                    <TabItem
                                        isPressed={jobCardStyle === "regular"}
                                        onClick={setJobCardStyleToRegular}
                                        noDebounce={true}
                                    >
                                        Regular
                                    </TabItem>
                                </TabStack>
                            </BottomSheet.Item>
                        )}
                        <BottomSheet.Item
                            isTabbable={isTabbable}
                            label="Time Frame"
                            onClick={openTimeFrameSelect}
                            hasSubItems={true}
                        />
                    </div>
                )}
            </BottomSheet.Content>
            <TimelineViewTimeFrameSelector.BottomSheet
                header={{
                    title: "Time Frame",
                    onBackClick: closeTimeFrameSelect,
                }}
                priority="secondary"
                isVisible={isSubItemsOpen}
            />
        </BottomSheet.Root>
    )
}
