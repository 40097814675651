import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import useIsDesktop from "@hooks/useIsDesktop"
import useNavigationLinks from "@hooks/useNavigationLinks"
import useRouter from "@hooks/useRouter"
import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"

import { AccessCheck } from "@particles"

import { TopBar } from "@organisms"
import ViewTypesProvider from "@organisms/ObjectsView/ViewTypesProvider/ViewTypesProvider"

import { AppFrame } from "@templates"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { LIST_SEARCH_PARAM_TYPE } from "@constants/searchParams"
import { PRICEBOOK_LIST_CONTROLS_VIEW } from "@constants/storage"

import PricebookCardView from "./views/PricebookCardView/PricebookCardView"
import PricebookTableView from "./views/PricebookTableView/PricebookTableView"
import { PricebookTypeName } from "./views/PricebookTableView/PricebookTableViewTabs"

const CREATE_ROUTE_MAP: { [key: string]: string } = {
    Services: PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE,
    "Parts & Materials": PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE,
    Miscellaneous: PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE,
    Discounts: PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE,
    "Tax Rates": PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE,
}

export default function PricebookList() {
    const isDesktop = useIsDesktop()

    const { user } = useUser()
    const { push } = useRouter()
    const { pricebook: pricebookLink } = useNavigationLinks()

    const sublinks = filterUserLinks(pricebookLink.subLinks, user)

    let pricebookTypeFromURL: PricebookTypeName = new URLSearchParams(window.location.search).get(
        LIST_SEARCH_PARAM_TYPE,
    ) as PricebookTypeName

    if (!pricebookTypeFromURL) {
        pricebookTypeFromURL = "Services"
        const url = new URL(window.location.href)
        url.searchParams.set(LIST_SEARCH_PARAM_TYPE, "Services")
        window.history.replaceState({}, "", url)
    }

    const [createRoute] = useSlugExtractor([CREATE_ROUTE_MAP[pricebookTypeFromURL]])

    return (
        <AppFrame.Root pageId={`PricebookList-${pricebookTypeFromURL}`}>
            <ViewTypesProvider
                viewTypeKey={PRICEBOOK_LIST_CONTROLS_VIEW}
                defaultViewType={isDesktop ? "table" : "card"}
            >
                {({ activeViewType }) => (
                    <>
                        <TopBar.Root>
                            <TopBar.Header>
                                <TopBar.TopBarHeaderStart>
                                    <TopBar.PageTitle
                                        label={pricebookTypeFromURL ?? ""}
                                        icon={icon({ name: "tag", style: "regular", family: "sharp" })}
                                        iconActive={icon({ name: "tag", style: "solid", family: "sharp" })}
                                        onNavigationItemSelect={(item) => {
                                            push(item.value)
                                        }}
                                        navigationItems={
                                            sublinks.length > 1
                                                ? sublinks.map((item) => ({
                                                      label: item.label,
                                                      value: item.path,
                                                      isActive: pricebookTypeFromURL === item.label,
                                                  }))
                                                : undefined
                                        }
                                    />
                                </TopBar.TopBarHeaderStart>
                                <TopBar.TopBarHeaderEnd>
                                    <TopBar.PagePresence />
                                    <AccessCheck
                                        permissions={{
                                            pricebook_list_permission: PERMISSION_LEVEL.RESTRICTED,
                                        }}
                                    >
                                        <AccessCheck
                                            permissions={{
                                                pricebook_view_permission: PERMISSION_LEVEL.FULL,
                                            }}
                                        >
                                            <TopBar.SearchButton scope="Pricebook" />
                                        </AccessCheck>
                                    </AccessCheck>
                                    <TopBar.ViewSelector viewTypes={["table", "card"]} />
                                    <AccessCheck
                                        permissions={{
                                            pricebook_create_permission: PERMISSION_LEVEL.FULL,
                                        }}
                                    >
                                        <TopBar.CTAButton destination={createRoute}>
                                            {isDesktop ? "New Item" : null}
                                        </TopBar.CTAButton>
                                    </AccessCheck>
                                </TopBar.TopBarHeaderEnd>
                            </TopBar.Header>
                        </TopBar.Root>
                        {activeViewType === "card" ? (
                            <PricebookCardView pricebookType={pricebookTypeFromURL} />
                        ) : (
                            <PricebookTableView pricebookType={pricebookTypeFromURL} />
                        )}
                    </>
                )}
            </ViewTypesProvider>
        </AppFrame.Root>
    )
}
