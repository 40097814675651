import Spinner from "@legacy/core/components/Spinner";
import { AccessCheck } from "@particles/index";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BooleanField from "../../core/fields/BooleanField";
import CharField from "../../core/fields/CharField";
import EmailField from "../../core/fields/EmailField";
import PhoneNumberField from "../../core/fields/PhoneNumberField";
import SwitchField from "../../core/fields/SwitchField";
import { valueIsDefined } from "../../core/utils/utils";
import ContactSaveCheckBox from "../components/ContactSaveCheckBox";


class ContactForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {
            mode,
            submitting,
            contact,
            errors,
            onFormDataChange,
            requestAction,
            attachToVendor=false,
            returnScroll
        } = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return (
                    <div className="data-panel__action-feedback">
                        <Spinner centered={true} />
                    </div>
                )
            }
            else {
                if (mode === "ADD_CONTACT") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Create" handler={event => requestAction("CONTACT_CREATE")} />
                                <UniversalButton type="secondary" text="Cancel" handler={event => requestAction("CONTACT_CANCEL_CREATE")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
                else if (mode === "EDIT_CONTACT") {
                    return (
                        <ButtonGroup>
                            <ButtonGroupRow>
                                <UniversalButton type="primary" text="Save" handler={event => requestAction("CONTACT_UPDATE")} />
                                <UniversalButton type="secondary" text="Cancel Edits" handler={event => requestAction("CONTACT_CANCEL_EDITS")} />
                            </ButtonGroupRow>
                        </ButtonGroup>
                    )
                }
            }
        }
    }

    render() {
        const {
            mode,
            submitting,
            contact,
            errors,
            onFormDataChange,
            requestAction,
            attachToVendor=false,
            returnScroll
        } = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Contact Create/Update">
                    <div className="data-panel__form" aria-label="Contact Edit Form">
                        {
                            !attachToVendor && (
                                <SwitchField
                                    fieldName="attached_to"
                                    fieldLabel="Attach contact to"
                                    fieldValue={contact.attached_to === "service_location"}
                                    fieldOnChange={attached_to => onFormDataChange("attached_to", attached_to ? "service_location" : "external_client")}
                                    uncheckedText="Client"
                                    checkedText="Service Location"
                                    disabled={mode !== "ADD_CONTACT"}
                                    errors={errors}
                                ></SwitchField>
                            )
                        }
                        <CharField
                            fieldName="name"
                            fieldLabel="Name"
                            fieldValue={contact.name || ""}
                            fieldOnChange={name => onFormDataChange("name", name || "")}
                            maxLength="100"
                            optional={true}
                            errors={errors}
                        ></CharField>
                        <PhoneNumberField
                            fieldName="phone"
                            fieldLabel="Phone"
                            fieldValue={contact.phone || ""}
                            fieldOnChange={phone => onFormDataChange("phone", phone || "")}
                            extensionFieldName="phone_extension"
                            extensionFieldLabel="Ext."
                            extensionFieldValue={contact.phone_extension || ""}
                            extensionOnChange={phone_extension => onFormDataChange("phone_extension", phone_extension || "")}
                            defaultCountryCode={window.PHONE_NUMBER_COUNTRY}
                            optional={true}
                            errors={errors}
                            showExtensionField={true}
                        ></PhoneNumberField>
                        <EmailField
                            fieldName="email"
                            fieldLabel="Email"
                            fieldValue={contact.email || ""}
                            fieldOnChange={email => onFormDataChange("email", email || "")}
                            optional={true}
                            errors={errors}
                        ></EmailField>
                        {(contact.attached_to === "external_client" || contact.attached_to === "service_location") && (
                            <>
                                <AccessCheck entitlements={["entitlement_invoices_enabled"]}>
                                    <BooleanField
                                        fieldName="receive_invoice_reminders_via_email"
                                        fieldLabel="Reminders"
                                        fieldValue={contact.receive_invoice_reminders_via_email || false}
                                        fieldOnChange={(receive_invoice_reminders_via_email) =>
                                            onFormDataChange(
                                                "receive_invoice_reminders_via_email",
                                                receive_invoice_reminders_via_email,
                                            )
                                        }
                                        inputLabel="Receive Invoice Reminders via Email"
                                        errors={errors}
                                    ></BooleanField>
                                </AccessCheck>
                                <AccessCheck entitlements={["entitlement_invoices_enabled"]}>
                                    <BooleanField
                                        fieldName="receive_invoice_reminders_via_sms"
                                        fieldValue={contact.receive_invoice_reminders_via_sms || false}
                                        fieldOnChange={(receive_invoice_reminders_via_sms) =>
                                            onFormDataChange(
                                                "receive_invoice_reminders_via_sms",
                                                receive_invoice_reminders_via_sms,
                                            )
                                        }
                                        inputLabel="Receive Invoice Reminders via SMS"
                                        errors={errors}
                                    ></BooleanField>
                                </AccessCheck>
                                <AccessCheck entitlements={["entitlement_estimates_enabled"]}>
                                    <BooleanField
                                        fieldName="receive_estimate_reminders_via_email"
                                        fieldValue={contact.receive_estimate_reminders_via_email || false}
                                        fieldOnChange={(receive_estimate_reminders_via_email) =>
                                        onFormDataChange(
                                                "receive_estimate_reminders_via_email",
                                                receive_estimate_reminders_via_email,
                                            )
                                        }
                                        inputLabel="Receive Estimate Reminders via Email"
                                        errors={errors}
                                        ></BooleanField>
                                </AccessCheck>
                                <AccessCheck entitlements={["entitlement_estimates_enabled"]}>
                                    <BooleanField
                                        fieldName="receive_estimate_reminders_via_sms"
                                        fieldValue={contact.receive_estimate_reminders_via_sms || false}
                                        fieldOnChange={(receive_estimate_reminders_via_sms) =>
                                        onFormDataChange(
                                                "receive_estimate_reminders_via_sms",
                                                receive_estimate_reminders_via_sms,
                                            )
                                        }
                                        inputLabel="Receive Estimate Reminders via SMS"
                                        errors={errors}
                                    ></BooleanField>
                                </AccessCheck>
                            </>
                        )}
                        {
                            valueIsDefined(contact.is_ephemeral) && mode !== "EDIT_CONTACT" && (
                                <ContactSaveCheckBox
                                    isChecked={!contact.is_ephemeral}
                                    handleOnChange={event => onFormDataChange("is_ephemeral", !event.target.checked)}
                                />
                            )
                        }
                        {errors.non_field_error && <div className="data-panel__form__non_field_errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default ContactForm;
