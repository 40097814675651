import { JobStatuses } from "@legacy/core/utils/enums"

import useHasAccess from "@hooks/useHasAccess"

import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { JOBS_ROUTES } from "@routes/jobs"

export const JobTableViewTabs = () => {
    const { hasFlag } = useHasAccess()

    return (
        <TableViewTabs>
            <TableViewTab
                id="all_open"
                phase="open"
                title="All Open"
                isDefaultActiveTabForPhase={true}
                endpointKwargs={[
                    ["status", JobStatuses.pending],
                    ["status", JobStatuses.scheduled],
                    ["status", JobStatuses.active],
                    ["status", JobStatuses.paused],
                    ["status", JobStatuses.on_hold],
                    ["status", JobStatuses.completed],
                    ["is_archived", "false"],
                    ["ordering", "estimated_arrival_time"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                title="Pending"
                phase="open"
                endpointKwargs={[
                    ["status", JobStatuses.pending],
                    ["is_archived", "false"],
                    ["ordering", "-id"],
                ]}
                id="pending"
                hide={hasFlag("restricted_to_assigned_jobs")}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="scheduled"
                title="Scheduled"
                phase="open"
                endpointKwargs={[
                    ["status", JobStatuses.scheduled],
                    ["is_archived", "false"],
                    ["ordering", "estimated_arrival_time"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="in_progress"
                title="In Progress"
                phase="open"
                endpointKwargs={[
                    ["status", JobStatuses.active],
                    ["status", JobStatuses.paused],
                    ["is_archived", "false"],
                    ["ordering", "estimated_arrival_time"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="on_hold"
                title="On Hold"
                phase="open"
                endpointKwargs={[
                    ["status", JobStatuses.on_hold],
                    ["is_archived", "false"],
                    ["ordering", "estimated_arrival_time"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="completed"
                title="Completed"
                phase="open"
                endpointKwargs={[
                    ["status", JobStatuses.completed],
                    ["is_archived", "false"],
                    ["ordering", "-last_completed_at"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="all_closed"
                title="All Closed"
                phase="closed"
                isDefaultActiveTabForPhase={true}
                endpointKwargs={[
                    ["status", JobStatuses.finalized],
                    ["status", JobStatuses.cancelled],
                    ["is_archived", "true"],
                    ["ordering", "-id"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="finalized"
                title="Finalized"
                phase="closed"
                endpointKwargs={[
                    ["status", JobStatuses.finalized],
                    ["is_archived", "true"],
                    ["ordering", "-last_finalized_at"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
            <TableViewTab
                id="cancelled"
                title="Cancelled"
                phase="closed"
                endpointKwargs={[
                    ["status", JobStatuses.cancelled],
                    ["is_archived", "true"],
                    ["ordering", "-estimated_arrival_time"],
                ]}
                listEndpoint={JOBS_ROUTES.LIGHTWEIGHT_LIST}
                countEndpoint={JOBS_ROUTES.COUNT}
            />
        </TableViewTabs>
    )
}
