// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PriorityCell-base__qTY71{display:flex;align-items:center;width:var(--size-full);padding:var(--size-3_5) var(--size-4);font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}.PriorityCell-primary__o0mvq{color:var(--gray-12)}.PriorityCell-secondary__Y3s0t{color:var(--gray-11)}.PriorityCell-warning__RY6xj{color:var(--orange-9)}.PriorityCell-content__lhmbT{display:flex;flex-direction:row;gap:var(--size-1);align-items:center;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "PriorityCell-base__qTY71",
	"primary": "PriorityCell-primary__o0mvq",
	"secondary": "PriorityCell-secondary__Y3s0t",
	"warning": "PriorityCell-warning__RY6xj",
	"content": "PriorityCell-content__lhmbT"
};
export default ___CSS_LOADER_EXPORT___;
