import { Component } from 'react';
import WrapperLinkButton from '../../core/buttons/WrapperLinkButton';
import { convertToKebabCase, formatCurrencyForServiceCompany } from '../../core/utils/utils';
import { calculateEstimateAmounts } from '../utils/utils';


class EstimateObjectLink extends Component {

    render() {
        const { estimate, destination, disabled=False } = this.props

        const buttonTitle = `Estimate ${estimate.id} [${estimate.status_label}]`
        const buttonPillClass = `estimates-${convertToKebabCase(estimate.status_label)}`
        const buttonPillLabel = estimate.status_label
        const buttonLabel = formatCurrencyForServiceCompany(calculateEstimateAmounts(estimate).total, estimate.service_company)

        return (
            <WrapperLinkButton type="object_link" destination={destination} openNew={true} title={buttonTitle} ariaLabel="View Estimate" disabled={disabled}>
                <div className="data-panel__list-select__option__label"><div className={`data-panel__list-select__option__status-pill ${buttonPillClass}`}>{buttonPillLabel}</div><span>{buttonLabel}</span></div><div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperLinkButton>
        )
    }
}

export default EstimateObjectLink;
