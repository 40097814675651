import { SortingState } from "@tanstack/react-table"

import { ObjectListData } from "@organisms/ObjectsView/ObjectsView.types"

import getOrderingFromSortingState from "./getOrderingFromSortingState"

interface fetchObjectListDataArgs {
    objectName: string
    endpoint: string | null
    endpointKwargs: Kwargs
    searchKeywords: string
    filters: URLSearchParams | null
    sorting?: SortingState
    nextPage?: string
}

export default async function fetchObjectListData<T>(args: fetchObjectListDataArgs): Promise<ObjectListData<T>> {
    const { objectName, endpoint, endpointKwargs, searchKeywords, filters, sorting, nextPage } = args
    let fetchUrl: string

    if (!endpoint) {
        throw new Error(`An endpoint for ${objectName.toLowerCase()} was not provided.`)
    }

    if (nextPage) {
        fetchUrl = nextPage
    } else {
        const searchParams = new URLSearchParams()

        endpointKwargs.forEach(([key, value]) => searchParams.append(key, value.toString()))

        searchParams.set("keywords", searchKeywords)
        searchParams.set("ordering", getOrderingFromSortingState(sorting ?? []))

        if (filters) {
            for (const [key, value] of filters.entries()) {
                searchParams.set(key, value)
            }
        }

        fetchUrl = endpoint + "?" + searchParams.toString()
    }

    const response = await fetch(fetchUrl)
    if (!response.ok) {
        throw new Error(`There was an error while trying to load ${objectName.toLowerCase()} list.`)
    }

    return (await response.json()) as ObjectListData<T>
}
