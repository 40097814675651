// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopBarBody-base__tsrRX{border-bottom:var(--size-0_25) solid var(--gray-a3)}.TopBarBody-base__tsrRX .TopBarBody-container__b1vJ_{position:relative;display:flex;flex-direction:column}@media only screen and (min-width: 64rem){.TopBarBody-base__tsrRX .TopBarBody-container__b1vJ_{flex-direction:row;gap:var(--size-2);align-items:center;justify-content:space-between}}@media only screen and (min-width: 64rem){.TopBarBody-base__tsrRX{padding:var(--size-0) var(--size-4)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "TopBarBody-base__tsrRX",
	"container": "TopBarBody-container__b1vJ_"
};
export default ___CSS_LOADER_EXPORT___;
