import { TruncatedTextCellProps } from "@organisms/Table/TableCells/TruncatedTextCell/TruncatedTextCell.types"

import { InventoryLevels, PriceBookItemTypes } from "./choices"

export const PRICEBOOK_ITEM_TYPE_MAP = {
    [PriceBookItemTypes.service]: "Service",
    [PriceBookItemTypes.part]: "Part or Material",
    [PriceBookItemTypes.other]: "Miscellaneous",
    [PriceBookItemTypes.discount]: "Discount",
    [PriceBookItemTypes.tax]: "Tax Rate",
}

type InventoryLevel = (typeof InventoryLevels)[keyof typeof InventoryLevels]
type InventoryLevelMapping = {
    [key in InventoryLevel]: { variant: TruncatedTextCellProps["variant"]; level: string }
}

export const INVENTORY_LEVEL_STATUS_MAP: InventoryLevelMapping = {
    [InventoryLevels.depleted]: { variant: "dangerous", level: "Depleted" },
    [InventoryLevels.low]: { variant: "warning", level: "Low" },
    [InventoryLevels.limited]: { variant: "caution", level: "Limited" },
    [InventoryLevels.full]: { variant: "success", level: "Full" },
}
export const INVENTORY_LEVEL_STATUS_MAP_UNKNOWN = { variant: "secondary", level: "Unknown" }
