import { PriceBookItemTypes } from "@constants/choices";
import { PRICEBOOK_ITEM_TYPE_MAP } from "@constants/mappings";
import { Component } from "react";
import deepcopy from "rfdc";
import WrapperButton from "../../core/buttons/WrapperButton";


class InlinePriceOverrideDisplay extends Component {

    render() {
        const { item, requestAction, formatCurrencyValue} = this.props

        let errors = deepcopy()(item.errors || {})

        const isDiscount = item.pricebook_item?.pricebook_item_type === PriceBookItemTypes.discount
        const description = item.pricebook_item?.description || "\u2014"
        const default_price = item.pricebook_item?.default_price ? isDiscount ? formatCurrencyValue(item.pricebook_item?.default_price * -1) : formatCurrencyValue(item.pricebook_item?.default_price) : "\u2014"
        const price = errors.price ? `${item.price}` : item.price ? isDiscount ? formatCurrencyValue(item.price * -1) : formatCurrencyValue(item.price) : "\u2014"

        const itemString = `${description !== "\u2014" ? description : "(No Description)"}: ${default_price}, ${price}`
        const inputLabel = PRICEBOOK_ITEM_TYPE_MAP[item.pricebook_item?.pricebook_item_type] || '';

        return (
            <WrapperButton key={`${item.id}_${item.pricebook_item?.name}_${item.pricebook_item?.name}_${item.price}`} type="object_link" handler={event => requestAction("ITEM_EDIT")} title={itemString} ariaLabel="Update Item">
                <div className="data-panel__list-select__option__label line-item-label">
                            {errors.non_field_error && <div className="data-panel__form__field__errors" aria-label="Non Field Error">{errors.non_field_error}</div>}
                    <div className="line-item-label__row">
                        <div className="line-item-label__field" aria-label="item">
                            <div className={"line-item-label__field-label" + (errors.description ? " invalid" : "")} aria-hidden="true">{inputLabel}</div>
                            <div className="line-item-label__field-value">{description}</div>
                            {errors.name && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.name}</div>}
                        </div>
                    </div>
                    <div className="line-item-label__row">
                        <div className="line-item-label__field" aria-label="default_price">
                            <div className={"line-item-label__field-label" + (errors.default_price ? " invalid" : "")} aria-hidden="true">Default Price</div>
                            <div className="line-item-label__field-value">{default_price}</div>
                            {errors.default_price && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.default_price}</div>}
                        </div>
                    </div>
                    <div className="line-item-label__row">
                        <div className="line-item-label__field" aria-label="Price">
                            <div className={"line-item-label__field-label" + (errors.price ? " invalid" : "")} aria-hidden="true">Price Override</div>
                            <div className="line-item-label__field-value">{price}</div>
                            {errors.price && <div className="data-panel__form__field__errors" aria-label="Field Errors">{errors.price}</div>}
                        </div>
                    </div>
                </div>
                <div className="data-panel__list-select__option__icon"><i className="fa-sharp fa-angle-right" aria-hidden="true"></i></div>
            </WrapperButton>
        )
    }
}

export default InlinePriceOverrideDisplay;
