import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import ButtonGroup from "../../core/buttons/ButtonGroup";
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow";
import UniversalButton from "../../core/buttons/UniversalButton";
import BasicDisplayField from "../../core/fields/BasicDisplayField";
import TextField from "../../core/fields/TextField";


class BillVoidForm extends Component {

    componentDidMount() {
        // Return to the previous scroll
        document.querySelector(".main").scrollTo(0, this.props.returnScroll || 0)
    }

    renderButtons = () => {
        const {bill, voidData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, inventoryUsed, returnScroll} = this.props

        if (errors.unexpectedError) {
            return (
                <div className="data-panel__action-feedback">
                    <span className="text-invalid"><strong>An unexpected error occurred.</strong></span>
                </div>
            )
        }
        else {
            if (submitting) {
                return <Spinner centered={true} />
            }
            else {
                return (
                    <ButtonGroup>
                        <ButtonGroupRow>
                            <UniversalButton type="danger" text="Void" handler={event => requestAction("BILL_VOID_SUBMIT")} />
                            <UniversalButton type="secondary" text="Cancel" handler={event => switchToPrimaryForm()} />
                        </ButtonGroupRow>
                    </ButtonGroup>
                )
            }
        }
    }

    render() {
        const {bill, voidData, requestAction, switchToPrimaryForm, submitting, errors, onFormDataChange, inventoryUsed, returnScroll} = this.props

        return (
            <div className="data-panel-container data-panel-container--with-margin">
                <div className="data-panel" aria-label="Bill Void">
                    <div className="data-panel__form" aria-label="Bill Void Form">
                        <p className="data-panel__form__caption">Please confirm that you'd like to void this bill.</p>
                        <BasicDisplayField
                            fieldName="id"
                            fieldLabel="Roopairs Bill ID"
                            fieldValue={bill.custom_id ? bill.custom_id : bill.id}
                        ></BasicDisplayField>
                        <TextField
                            fieldName="void_message"
                            fieldLabel="Void Reason"
                            fieldValue={voidData.void_message || ""}
                            fieldOnChange={void_message => onFormDataChange("void_message", void_message || "")}
                            rows={3}
                            placeholder="Why are you voiding this bill?"
                            errors={errors}
                        ></TextField>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default BillVoidForm;
