// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WorkspaceSelector-switchable__AJ9q3{position:relative;overflow:hidden;cursor:pointer}.WorkspaceSelector-base__yWeYp{display:flex;gap:var(--size-2_5);align-items:center;max-width:100%;padding:var(--size-2);overflow:hidden}@media only screen and (min-width: 64rem){.WorkspaceSelector-base__yWeYp{gap:var(--size-1_5);padding:var(--size-1_5)}}.WorkspaceSelector-name__nQDQV{max-width:100%;overflow:hidden;color:var(--gray-12);white-space:nowrap;text-overflow:ellipsis;-webkit-user-select:none;-moz-user-select:none;user-select:none;font:var(--font-mobile-body-400);font-feature-settings:var(--feature-settings-mobile-body-400);letter-spacing:var(--letter-spacing-mobile-body-400)}@media only screen and (min-width: 64rem){.WorkspaceSelector-name__nQDQV{font:var(--font-global-body-400);font-feature-settings:var(--feature-settings-global-body-400);letter-spacing:var(--letter-spacing-global-body-400)}}.WorkspaceSelector-trigger__zyikd{top:0;right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchable": "WorkspaceSelector-switchable__AJ9q3",
	"base": "WorkspaceSelector-base__yWeYp",
	"name": "WorkspaceSelector-name__nQDQV",
	"trigger": "WorkspaceSelector-trigger__zyikd"
};
export default ___CSS_LOADER_EXPORT___;
