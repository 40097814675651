import { Tooltip } from "@atoms"

function TagListRenderer(props) {
    const { tags, forceSingle=false } = props

    const count = tags.length

    if (count > 2 || (forceSingle && count > 1)) {
        return <Tooltip label={tags.join("\n")}>
            <div className="tag-list">
                <div className="tag-list-tag data-tag">{tags[0]}</div>
                <div className="tag-list-tag data-tag">{`+${count - 1} more`}</div>
            </div>
        </Tooltip>
    }
    else if (count === 2) {
        return <Tooltip label={tags.join("\n")}>
            <div className="tag-list">
                <div className="tag-list-tag data-tag">{tags[0]}</div>
                <div className="tag-list-tag data-tag">{tags[1]}</div>
            </div>
        </Tooltip>
    }
    else if (count === 1) {
        return <Tooltip label={tags.join("\n")}>
            <div className="tag-list">
                <div className="tag-list-tag data-tag">{tags[0]}</div>
            </div>
        </Tooltip>
    }
    else {
        return null
    }
}

export default TagListRenderer
