import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { ESTIMATES_ROUTES } from "@routes/estimates"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    ESTIMATES_LIST_CONTROLS_ACTIVE_TAB,
    ESTIMATES_LIST_CONTROLS_KEYWORD_SEARCH,
    ESTIMATES_LIST_CONTROLS_ORDERING,
    ESTIMATES_LIST_CONTROLS_PHASE,
} from "@constants/storage"

import { EstimateTableViewTabs } from "./EstimateTableViewTabs"

export default function EstimateTableView() {
    const [createRoute] = useSlugExtractor([ESTIMATES_ROUTES.CREATE])

    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={ESTIMATES_LIST_CONTROLS_PHASE}
            activeTabStorageKey={ESTIMATES_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={ESTIMATES_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={ESTIMATES_LIST_CONTROLS_ORDERING}
            listTitle="Estimates"
            objectType="estimate"
            objectCreateRoute={createRoute}
            objectIndexName="Estimate"
            objectName="Estimate"
            defaultActivePhase="open"
            isRowClickable={hasPermission("estimates_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("estimates_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <EstimateTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                        <Table.ViewMenu />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <Table.Table>
                <Table.Column<Estimate>
                    accessorKey="id"
                    header="ID"
                    size={144}
                    cell={({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Estimate>
                    accessorKey="client_name"
                    header="Client"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={true}
                    dataType="alphabetical"
                />
                <Table.Column<Estimate>
                    accessorKey="state_label"
                    header="State"
                    size={128}
                    cell={({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Estimate>
                    accessorKey="status_label"
                    header="Status"
                    size={176}
                    cell={({ getValue, row }) => (
                        <Table.Cells.StatusCell status={getValue()} dateDue={row.original.date_due} />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Estimate>
                    accessorKey="labels"
                    header="Labels"
                    size={240}
                    cell={({ getValue }) => (
                        <Table.Cells.BadgesCell
                            badges={(getValue() as EstimateLabel[])?.map((label: EstimateLabel) => label.name)}
                        />
                    )}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Estimate>
                    accessorKey="total"
                    header="Total"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.NumericalDataCell value={getValue()} type="currency" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="numerical"
                />
                <Table.Column<Estimate>
                    accessorKey="service_name"
                    header="Service"
                    size={224}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Estimate>
                    accessorKey="service_location_name"
                    header="Location Name"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Estimate>
                    accessorKey="service_location_address"
                    header="Location Address"
                    size={288}
                    cell={({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="alphabetical"
                />
                <Table.Column<Estimate>
                    accessorKey="date_due"
                    header="Expires"
                    size={144}
                    cell={({ getValue, row }) => (
                        <Table.Cells.DateTimeCell value={getValue()} isDangerous={row.original.is_expired} />
                    )}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="down_payment_status"
                    header="Down Payment"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.DownPaymentCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Estimate>
                    accessorKey="last_status_transition_time"
                    header="Status Changed"
                    size={160}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={false}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="last_modified_at"
                    header="Last Modified"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="last_viewed"
                    header="Seen"
                    size={96}
                    cell={({ getValue }) => <Table.Cells.SeenCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="other"
                />
                <Table.Column<Estimate>
                    accessorKey="last_sent_at"
                    header="Sent"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="last_approved_at"
                    header="Approved"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="last_won_at"
                    header="Won"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="last_lost_at"
                    header="Lost"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
                <Table.Column<Estimate>
                    accessorKey="last_cancelled_at"
                    header="Cancelled"
                    size={144}
                    cell={({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />}
                    enableSorting={true}
                    enablePinning={false}
                    dataType="timing"
                />
            </Table.Table>
        </Table.Provider>
    )
}
