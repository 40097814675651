import { TableDataType } from "@organisms/Table/Table.types"

export default function getTableSortingLabelsPairByDataType(dataType: TableDataType) {
    if (dataType === "alphabetical") {
        return {
            asc: "A to Z",
            desc: "Z to A",
        }
    } else if (dataType === "numerical") {
        return {
            asc: "1 to 9",
            desc: "9 to 1",
        }
    } else if (dataType === "timing") {
        return {
            asc: "Least Recent",
            desc: "Most Recent",
        }
    } else {
        return {
            asc: "Ascending",
            desc: "Descending",
        }
    }
}
