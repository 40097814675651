import JobListCard from "@legacy/jobs/components/JobListCard"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import extractURLSearchParam from "@utils/extractURLSearchParam"

import { CardList, Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { JobTableViewTabs } from "@pages/Jobs/JobList/views/JobTableView/JobTableViewTabs"

import { JOBS_ROUTES } from "@routes/jobs"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    JOBS_LIST_CONTROLS_ACTIVE_TAB,
    JOBS_LIST_CONTROLS_KEYWORD_SEARCH,
    JOBS_LIST_CONTROLS_ORDERING,
    JOBS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

export default function JobCardView() {
    const [createRoute] = useSlugExtractor([JOBS_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    const seriesFilter = extractURLSearchParam("filter__series", false)
    const seriesSearch = new URLSearchParams()

    if (seriesFilter) {
        seriesSearch.set("series", seriesFilter)
    }

    return (
        <Table.Provider
            activePhaseStorageKey={JOBS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={JOBS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={JOBS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={JOBS_LIST_CONTROLS_ORDERING}
            listTitle="Jobs"
            listDataFilters={seriesSearch}
            objectType="job"
            objectCreateRoute={createRoute}
            objectIndexName="Job"
            objectName="Job"
            defaultActivePhase="open"
            isRowClickable={hasPermission("jobs_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission("jobs_create_permission", PERMISSION_LEVEL.RESTRICTED)}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <JobTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <CardList CardComponent={JobListCard} />
        </Table.Provider>
    )
}
