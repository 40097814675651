import dayjs from "dayjs"
import { useEffect, useState } from "react"
import * as Yup from "yup"

import useIsDesktop from "@hooks/useIsDesktop"
import useUser from "@hooks/useUser"

import { AlertBanner, Button } from "@atoms/index"

import { PasswordInput } from "@molecules/Form/Form"
import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { ResponsiveDialog } from "@molecules/index"

import { PASSWORD_ENDPOINTS } from "@endpoints/password"

import styles from "./SetPasswordDialog.module.scss"
import {
    PasswordChangeErrorResponse,
    PasswordChangePayload,
    PasswordConfirmationForm,
    SetPasswordDialogProps,
} from "./SetPasswordDialog.types"

const formSchema: Yup.ObjectSchema<PasswordConfirmationForm> = Yup.object().shape({
    new_password: Yup.string()
        .required("Please enter a password")
        .min(8, "Password length should be at least 8 characters"),
    new_password_confirm: Yup.string()
        .required("Please confirm your password")
        .min(8, "Password length should be at least 8 characters")
        .oneOf([Yup.ref("new_password")], "Passwords don't match"),
})

export default function SetPasswordDialog(props: SetPasswordDialogProps) {
    const { children, isDefaultOpen, onOpenChange = () => {} } = props

    const { user, updateUser } = useUser()

    const isDesktop = useIsDesktop()

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(isDefaultOpen)

    const closeDialog = () => setIsDialogOpen(false)

    const payloadFormatter = (data: PasswordConfirmationForm) => {
        return {
            new_password: data.new_password,
            new_password_confirm: data.new_password_confirm,
        }
    }

    useEffect(() => {
        onOpenChange(isDialogOpen)
    }, [isDialogOpen])

    const setOrUpdatePasswordCopy = user?.hasPassword ? "Update password" : "Set password"

    const onMutationSuccess = () => {
        closeDialog()

        updateUser({
            // todo: once we have user timezone setup, update this
            password_last_modified_at: dayjs().toDate(),
        })
    }

    const onMutationError = () => {
        closeDialog()
    }

    return (
        <ResponsiveDialog.Root
            headerTitle={setOrUpdatePasswordCopy}
            trigger={children}
            isOpen={isDialogOpen}
            onOpenChange={setIsDialogOpen}
        >
            <GenericForm<PasswordConfirmationForm, void, PasswordChangeErrorResponse, PasswordChangePayload>
                formConfig={{
                    defaultValues: {
                        new_password: "",
                        new_password_confirm: "",
                    },
                }}
                formValidationSchema={formSchema}
                mutationConfig={{
                    endpoint: PASSWORD_ENDPOINTS.CHANGE_PASSWORD,
                    method: "POST",
                    genericErrorMessage: "Could not update password.",
                    genericSuccessMessage: "Password updated",
                    payloadFormatter,
                }}
                onMutationSuccess={onMutationSuccess}
                onMutationError={onMutationError}
                triggerFormReset={!isDialogOpen}
            >
                {({ isPending, nonFieldError, form }) => {
                    const passwords = form.getValues()
                    const formIsValid = form.formState.isValid
                    const passwordsMatch = passwords.new_password === passwords.new_password_confirm

                    return (
                        <>
                            <ResponsiveDialog.Body>
                                {nonFieldError && (
                                    <AlertBanner size="md" title={nonFieldError} type="error" fullWidth={true} />
                                )}
                                <span className={styles.subtitle}>
                                    Choose a password for your account{" "}
                                    <span className={styles.subtitleHighlight}>{user?.email}</span>
                                </span>
                                <div className={styles.passwordInputs}>
                                    <PasswordInput<PasswordConfirmationForm>
                                        size={isDesktop ? "md" : "lg"}
                                        autoFocus={isDialogOpen}
                                        displayStrengthIndicator={true}
                                        label="Password"
                                        name="new_password"
                                        inputHelpText={{
                                            type: "none",
                                            children:
                                                "Recommendations: min. 8 characters, lower & uppercase letters, symbols, digits.",
                                        }}
                                    />
                                    <PasswordInput<PasswordConfirmationForm>
                                        size={isDesktop ? "md" : "lg"}
                                        label="Confirm password"
                                        inputHelpText={
                                            passwordsMatch && formIsValid
                                                ? {
                                                      type: "success",
                                                      children: "Passwords match",
                                                  }
                                                : {
                                                      type: "none",
                                                      children: "Passwords must match",
                                                  }
                                        }
                                        name="new_password_confirm"
                                    />
                                </div>
                            </ResponsiveDialog.Body>
                            <ResponsiveDialog.Footer>
                                <Button
                                    colorScheme="gray"
                                    type="submit"
                                    variant="solid"
                                    size={isDesktop ? "md" : "xl"}
                                    isLoading={isPending}
                                    loadingText={user?.hasPassword ? "Updating password" : "Setting password"}
                                    isFullWidth={true}
                                >
                                    {setOrUpdatePasswordCopy}
                                </Button>
                            </ResponsiveDialog.Footer>
                        </>
                    )
                }}
            </GenericForm>
        </ResponsiveDialog.Root>
    )
}
