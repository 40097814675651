// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardListCard-cardContainer__M_qzv{position:absolute;top:var(--size-0);left:var(--size-0);width:100%;cursor:default}.CardListCard-cardContainer__M_qzv.CardListCard-clickable__rG8Pi{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "CardListCard-cardContainer__M_qzv",
	"clickable": "CardListCard-clickable__rG8Pi"
};
export default ___CSS_LOADER_EXPORT___;
