import { PERMISSION_LEVEL } from "@constants/permissionLevel";
import useHasAccess from "@hooks/useHasAccess";
import useUser from "@hooks/useUser";
import Spinner from "@legacy/core/components/Spinner";
import AccessCheck from "@particles/AccessCheck/AccessCheck";
import DashboardCard from "./components/DashboardCard";
import DashboardCardColumn from "./components/DashboardCardColumn";
import LatestActivityCard from "./components/LatestActivityCard";
import RecommendedActionsCard from "./components/RecommendedActionsCard";
import RevenuePotentialCard from "./components/RevenuePotentialCard";
import WorkStatusCard from "./components/WorkStatusCard";


export default function DashboardContainer() {
    const { user:currentUser, isLoading } = useUser()

    const { hasPermission } = useHasAccess()

    const urgentActionsPermissions = {
        estimates_list_permission: PERMISSION_LEVEL.FULL,
        jobs_list_permission: PERMISSION_LEVEL.FULL,
        invoices_list_permission: PERMISSION_LEVEL.FULL,
        purchase_orders_list_permission: PERMISSION_LEVEL.FULL,
        bills_list_permission: PERMISSION_LEVEL.FULL,
        pricebook_list_permission: PERMISSION_LEVEL.FULL,
    }

    const latestActivityPermissions = {
        estimates_view_permission: PERMISSION_LEVEL.FULL,
        jobs_view_permission: PERMISSION_LEVEL.FULL,
        invoices_view_permission: PERMISSION_LEVEL.FULL,
        purchase_orders_view_permission: PERMISSION_LEVEL.FULL,
        bills_view_permission: PERMISSION_LEVEL.FULL,
        vendors_view_permission: PERMISSION_LEVEL.FULL,
        clients_view_permission: PERMISSION_LEVEL.FULL,
        pricebook_view_permission: PERMISSION_LEVEL.FULL,
    }

    const estimatesWorkStatusPermissions = {
        estimates_list_permission: PERMISSION_LEVEL.FULL,
    }

    const jobsWorkStatusPermissions = {
        jobs_list_permission: PERMISSION_LEVEL.FULL,
    }

    const invoicesWorkStatusPermissions = {
        invoices_list_permission: PERMISSION_LEVEL.FULL,
    }

    const purchaseOrdersWorkStatusPermissions = {
        purchase_orders_list_permission: PERMISSION_LEVEL.FULL,
    }

    const billsWorkStatusPermissions = {
        bills_list_permission: PERMISSION_LEVEL.FULL,
    }

    const workStatusPermissions = {
        ...estimatesWorkStatusPermissions,
        ...jobsWorkStatusPermissions,
        ...invoicesWorkStatusPermissions,
        ...purchaseOrdersWorkStatusPermissions,
        ...billsWorkStatusPermissions,
    }

    const hasUrgentActionsAccess = Object.entries(urgentActionsPermissions).some(([key, level]) => hasPermission(key, level))
    const hasLatestActivityAccess = Object.entries(latestActivityPermissions).some(([key, level]) => hasPermission(key, level))

    const shouldHideActionsColumn = !hasUrgentActionsAccess && !hasLatestActivityAccess

    if (isLoading) {
        return (
            <div className="card-column-container dashboard" aria-label="Dashboard">
                <Spinner />
            </div>
        )
    } else {
        return (
            <div className="card-column-container dashboard" aria-label="Dashboard">
                <AccessCheck entitlements={["entitlement_estimates_enabled", "entitlement_jobs_enabled", "entitlement_invoices_enabled", "entitlement_purchase_orders_enabled", "entitlement_bills_enabled"]}
                    permissions={workStatusPermissions}
                >
                    <DashboardCardColumn title="Work Status">
                        <AccessCheck entitlements={["entitlement_estimates_enabled"]} permissions={estimatesWorkStatusPermissions}>
                            <DashboardCard title="Estimates" showCreateButton={hasPermission("estimates_create_permission", PERMISSION_LEVEL.RESTRICTED)}>
                                <WorkStatusCard />
                            </DashboardCard>
                        </AccessCheck>
                        <AccessCheck entitlements={["entitlement_jobs_enabled"]} permissions={jobsWorkStatusPermissions}>
                            <DashboardCard title="Jobs" showCreateButton={hasPermission("jobs_create_permission", PERMISSION_LEVEL.RESTRICTED)}>
                                <WorkStatusCard />
                            </DashboardCard>
                        </AccessCheck>
                        <AccessCheck entitlements={["entitlement_invoices_enabled"]} permissions={invoicesWorkStatusPermissions}>
                            <DashboardCard title="Invoices" showCreateButton={hasPermission("invoices_create_permission", PERMISSION_LEVEL.RESTRICTED)}>
                                <WorkStatusCard />
                            </DashboardCard>
                        </AccessCheck>
                        <AccessCheck entitlements={["entitlement_purchase_orders_enabled"]} permissions={purchaseOrdersWorkStatusPermissions}>
                            <DashboardCard title="Purchase Orders" showCreateButton={hasPermission("purchase_orders_create_permission", PERMISSION_LEVEL.RESTRICTED)}>
                                <WorkStatusCard />
                            </DashboardCard>
                        </AccessCheck>
                        <AccessCheck entitlements={["entitlement_bills_enabled"]} permissions={billsWorkStatusPermissions}>
                            <DashboardCard title="Bills" showCreateButton={hasPermission("bills_create_permission", PERMISSION_LEVEL.RESTRICTED)}>
                                <WorkStatusCard />
                            </DashboardCard>
                        </AccessCheck>
                    </DashboardCardColumn>
                </AccessCheck>
                {!shouldHideActionsColumn && (
                    <DashboardCardColumn title="Actions">
                        <AccessCheck
                            entitlements={["entitlement_estimates_enabled", "entitlement_jobs_enabled", "entitlement_invoices_enabled", "entitlement_purchase_orders_enabled", "entitlement_bills_enabled"]}
                            permissions={urgentActionsPermissions}
                        >
                            <DashboardCard title="Urgent Actions" pageSize={4}>
                                <RecommendedActionsCard />
                            </DashboardCard>
                        </AccessCheck>
                        <AccessCheck permissions={latestActivityPermissions}>
                            <DashboardCard title="Latest Activity" pageSize={5}>
                                <LatestActivityCard />
                            </DashboardCard>
                        </AccessCheck>
                    </DashboardCardColumn>
                )}
                <AccessCheck entitlements={["entitlement_estimates_enabled", "entitlement_invoices_enabled"]} permissions={{
                    reports_view_permission: PERMISSION_LEVEL.FULL
                }}>
                    <DashboardCardColumn title="Performance Metrics">
                        <DashboardCard title="Revenue Potential" pageSize={5}>
                            <RevenuePotentialCard />
                        </DashboardCard>
                    </DashboardCardColumn>
                </AccessCheck>
            </div>
        )
    }
}
