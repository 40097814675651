import useIsDesktop from "@hooks/useIsDesktop"

import { TabStack } from "@molecules/index"

import { TableViewPhasesProps } from "./TableViewPhases.types"

export default function TableViewPhases(props: TableViewPhasesProps) {
    const { children } = props
    const isDesktop = useIsDesktop()

    return (
        <TabStack variant="attached" size={isDesktop ? "sm" : "md"}>
            {children}
        </TabStack>
    )
}
