import clsx from "clsx"

import { Divider } from "@atoms"

import styles from "./TopBarVerticalDivider.module.scss"
import { TopBarVerticalDividerProps } from "./TopBarVerticalDivider.types"

export default function TopBarVerticalDivider(props: TopBarVerticalDividerProps) {
    const { hasSideMargins = false } = props

    return (
        <Divider
            className={clsx(styles.base, { [styles.sideMargins]: hasSideMargins })}
            orientation="vertical"
            contrast="low"
        />
    )
}
