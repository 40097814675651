import { PriceBookItemTypes } from "@legacy/core/utils/enums"

import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"

export type PricebookTypeName = "Services" | "Parts & Materials" | "Miscellaneous" | "Discounts" | "Tax Rates"

export const ServicesTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_active"
            title="All Active"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.service],
                ["is_active", "true"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="review"
            title="Review"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.service],
                ["is_active", "true"],
                ["confirmed", "false"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="confirmed"
            title="Confirmed"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.service],
                ["is_active", "true"],
                ["confirmed", "true"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_inactive"
            title="All Inactive"
            phase="inactive"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.service],
                ["is_active", "false"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
    </TableViewTabs>
)

export const PartsAndMaterialsTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_active"
            title="All Active"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.part],
                ["is_active", "true"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="review"
            title="Review"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.part],
                ["is_active", "true"],
                ["confirmed", "false"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="confirmed"
            title="Confirmed"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.part],
                ["is_active", "true"],
                ["confirmed", "true"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_inactive"
            title="All Inactive"
            phase="inactive"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.part],
                ["is_active", "false"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
    </TableViewTabs>
)

export const MiscellaneousTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_active"
            title="All Active"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.other],
                ["is_active", "true"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="review"
            title="Review"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.other],
                ["is_active", "true"],
                ["confirmed", "false"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="confirmed"
            title="Confirmed"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.other],
                ["is_active", "true"],
                ["confirmed", "true"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_inactive"
            title="All Inactive"
            phase="inactive"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.other],
                ["is_active", "false"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
    </TableViewTabs>
)

export const DiscountsTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_active"
            title="All Active"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.discount],
                ["is_active", "true"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="review"
            title="Review"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.discount],
                ["is_active", "true"],
                ["confirmed", "false"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="confirmed"
            title="Confirmed"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.discount],
                ["is_active", "true"],
                ["confirmed", "true"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_inactive"
            title="All Inactive"
            phase="inactive"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.discount],
                ["is_active", "false"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
    </TableViewTabs>
)

export const TaxRatesTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_active"
            title="All Active"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.tax],
                ["is_active", "true"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="review"
            title="Review"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.tax],
                ["is_active", "true"],
                ["confirmed", "false"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="confirmed"
            title="Confirmed"
            phase="active"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.tax],
                ["is_active", "true"],
                ["confirmed", "true"],
                ["ordering", "description"],
            ]}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_inactive"
            title="All Inactive"
            phase="inactive"
            endpointKwargs={[
                ["pricebook_item_type", PriceBookItemTypes.tax],
                ["is_active", "false"],
                ["ordering", "confirmed,description"],
            ]}
            isDefaultActiveTabForPhase={true}
            listEndpoint={PRICEBOOK_ITEMS_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={PRICEBOOK_ITEMS_ROUTES.COUNT}
        />
    </TableViewTabs>
)
