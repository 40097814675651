import clsx from "clsx"

import useRouter from "@hooks/useRouter"

import { ifSpaceOrEnter } from "@utils/keyboard"

import useTable from "@organisms/Table/Table.context"

import styles from "./CardListCard.module.scss"
import { CardListCardProps } from "./CardListCard.types"

export default function CardListCard(props: CardListCardProps) {
    const { height, transform, index, measureElement, detailsURL, currentData, CardComponent } = props
    const cardData = currentData[index]
    const router = useRouter()

    const { activeTab, isRowClickable } = useTable()

    return (
        <div
            className={clsx(styles.cardContainer, {
                [styles.clickable]: isRowClickable,
            })}
            style={{
                height: `${height}px`,
                transform: `translateY(${transform}px)`,
            }}
            onClick={() => isRowClickable && router.push(detailsURL)}
            onKeyDown={(event) => ifSpaceOrEnter(event, () => isRowClickable && router.push(detailsURL))}
            tabIndex={0}
            id={cardData?.id}
            data-index={index}
            ref={measureElement}
        >
            <CardComponent data={cardData} showState={activeTab?.title?.includes("All") ?? false} />
        </div>
    )
}
