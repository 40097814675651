export const MINIMUM_ZOOM = 0
export const MAXIMUM_ZOOM = 40

export const ZOOM_LIMITS = {
    month: {
        min: MINIMUM_ZOOM,
        max: MAXIMUM_ZOOM,
    },
    week: {
        min: MINIMUM_ZOOM,
        max: MAXIMUM_ZOOM,
    },
    threeDays: {
        min: MINIMUM_ZOOM,
        max: MAXIMUM_ZOOM,
    },
    day: {
        min: MINIMUM_ZOOM,
        max: MAXIMUM_ZOOM,
    },
}

export const DEFAULT_ZOOM = {
    month: ZOOM_LIMITS.month.min,
    week: ZOOM_LIMITS.week.min,
    threeDays: ZOOM_LIMITS.threeDays.min,
    day: ZOOM_LIMITS.day.max,
}
