import { createContext, useContext } from "react"

import { TableContextValues } from "./Table.types"

// `any` is used here as workaround because it is not possible to create a context with a generic type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableContext = createContext<TableContextValues<any> | undefined>(undefined)
TableContext.displayName = "Table"

export default function useTable<T>(): TableContextValues<T> {
    const context = useContext(TableContext)
    if (!context) {
        throw new Error("useTable must be used within a Table Provider component")
    }

    return context as TableContextValues<T>
}
