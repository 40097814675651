import { useEffect } from "react"

import useCurrentViewType from "@hooks/useCurrentViewType"
import useLocalStorage from "@hooks/useLocalStorage"

import { ObjectsViewType } from "@organisms/ObjectsView/ObjectsView.types"

import { ViewTypesProviderContext } from "./ViewTypesProvider.context"
import { ViewTypesProviderProps } from "./ViewTypesProvider.types"

export default function ViewTypesProvider(props: ViewTypesProviderProps) {
    const { children, viewTypeKey, defaultViewType } = props
    const [activeViewType, setActiveViewType] = useLocalStorage<ObjectsViewType>(viewTypeKey, defaultViewType)

    const { setCurrentViewType } = useCurrentViewType()

    useEffect(() => {
        setCurrentViewType(activeViewType)
    }, [activeViewType])

    return (
        <ViewTypesProviderContext.Provider value={{ activeViewType, setActiveViewType }}>
            {children({ activeViewType })}
        </ViewTypesProviderContext.Provider>
    )
}
