import Spinner from "@legacy/core/components/Spinner";
import { Component } from "react";
import deepcopy from "rfdc";
import { historyHasState, sendDataToServer } from "../core/utils/utils";
import VendorForm from "../pricebook/forms/VendorForm";

const FORM_MODES = {
    ADD_VENDOR: "ADD_VENDOR",
    EDIT_VENDOR: "EDIT_VENDOR",
}

const FORM_MODE_SUBTITLES = {
    ADD_VENDOR: "New Vendor",
    EDIT_VENDOR: "Edit Vendor",
}

const FORM_MODE_BACK_BUTTON_DISPLAY = {
    ADD_VENDOR: "none",
    EDIT_VENDOR: "none",
}

const PRIMARY_FORM_MODES = [
    FORM_MODES.ADD_VENDOR,
    FORM_MODES.EDIT_VENDOR,
]

const SECONDARY_FORM_MODES = []

const FORM_DATA_NAMES_BY_MODE = {
    ADD_VENDOR: "vendorData",
    EDIT_VENDOR: "vendorData",
}

const SUBMITTING_NAMES_BY_MODE = {
    ADD_VENDOR: "submittingVendor",
    EDIT_VENDOR: "submittingVendor",
}

const ERROR_NAMES_BY_MODE = {
    ADD_VENDOR: "vendor",
    EDIT_VENDOR: "vendor",
}



class VendorCreateContainer extends Component {

    // Initialize

    constructor(props) {
        super(props)

        const defaultMode = this.props.formMode || FORM_MODES.ADD_VENDOR
        this.addToastToQueue = this.props.addToastToQueue

        this.state = {
            vendorData: null,
            errors: {
                vendor: {},
            },

            defaultMode: defaultMode,
            mode: defaultMode,
            returnScroll: 0,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    componentDidMount = async () => {

        if (this.state.defaultMode === FORM_MODES.EDIT_VENDOR && window.VENDOR_ID) {
            if (this.state.vendorData === null) {
                const vendorEndpoint = DjangoUrls["pricebook:api-vendors-detail"](window.MARKETPLACE_ENTITY_SLUG, window.VENDOR_ID)
                const vendorResponse = await fetch(vendorEndpoint)
                const vendor = await vendorResponse.json()

                this.setState((state, props) => {
                    let updatedState = state
                    updatedState.vendorData = vendor
                    return updatedState
                })
            }
        } else {
            this.setState((state, props) => {
                let updatedState = state
                updatedState.vendorData = {}
                updatedState.vendorData.contacts = []
                updatedState.vendorData.labels = []

                return updatedState
            })
        }

        if (historyHasState(history)) {
            document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[history.state.mode]
            document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[history.state.mode]
            this.setState(history.state)
        }
    }

    // Form helpers

    updateFormData = (formName, fieldName, fieldValue) => {
        this.setState((state, props) => {
            let updatedState = state
            updatedState[formName][fieldName] = fieldValue
            return updatedState
        })
    }

    switchFormMode = (mode) => {
        document.querySelector(".page-subtitle").innerHTML = FORM_MODE_SUBTITLES[mode]
        document.querySelector(".back-button").style.display = FORM_MODE_BACK_BUTTON_DISPLAY[mode]

        if (SECONDARY_FORM_MODES.includes(mode)) {
            history.replaceState(this.state, "", "")
        }

        this.setState((state, props) => {
            let updatedState = state
            updatedState.mode = mode
            history.pushState(updatedState, "", "?mode=" + mode.toLowerCase().replace(/_/g, "-"));
            return updatedState
        })
    }

    switchToPrimaryForm = () => {
        this.setState((state, props) => {
            let updatedState = state

            // Clear the secondary form data
            updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]] = {}
            updatedState[SUBMITTING_NAMES_BY_MODE[state.mode]] = false
            updatedState.errors[ERROR_NAMES_BY_MODE[state.mode]] = {}

            return updatedState
        })
        this.switchFormMode(this.state.defaultMode)
    }

    switchToSecondaryForm = (newFormMode, data, initialData) => {
        this.setState((state, props) => {
            let updatedState = state
            // Set the scroll state
            updatedState.returnScroll = document.querySelector(".main").scrollTop

            updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = {}

            if (data !== null) {
                // To help with uniqueness check
                data.originalDescription = data.description
                updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]] = deepcopy()(data)
            }

            if (initialData !== null) {
                Object.assign(updatedState[FORM_DATA_NAMES_BY_MODE[newFormMode]], initialData)
            }

            return updatedState
        })

        this.switchFormMode(newFormMode)
    }

    // Crud Vendor

    createVendor = async () => {
        const endpoint = DjangoUrls["pricebook:api-vendors-list"](window.MARKETPLACE_ENTITY_SLUG)
        const endpointMethod = "POST"

        const onSuccess = (vendor) => {
            const successUrl = DjangoUrls["pricebook:vendor-detail"](window.MARKETPLACE_ENTITY_SLUG, vendor.id)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${vendor.name}" created`,
                path: successUrl,
                delayRender: true,
            })
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be created",
            })}

        this.createUpdateVendor(endpoint, endpointMethod, onSuccess, onError)
    }

    updateVendor = async () => {
        const endpoint = DjangoUrls["pricebook:api-vendors-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id)
        const endpointMethod = "PUT"

        const onSuccess = (vendor) => {
            const successUrl = DjangoUrls["pricebook:vendor-detail"](window.MARKETPLACE_ENTITY_SLUG, vendor.id)
            this.addToastToQueue({
                type: "success",
                size: "md",
                title: `Vendor "${vendor.name}" updated`,
                path: successUrl,
                delayRender: true,
            })
            location.assign(successUrl)
        }
        const onError = () => {
            this.addToastToQueue({
                type: "error",
                size: "md",
                title: "Vendor could not be updated",
            })
        }

        this.createUpdateVendor(endpoint, endpointMethod, onSuccess, onError)
    }

    createUpdateVendor = async (endpoint, endpointMethod, onSuccess, onError) => {
        const dataName = "vendorData"
        const submittingName = "submittingVendor"
        const errorDictName = "vendor"

        const dataManipulator = (data, state) => {
            data.skip_success_modal = true

            data.contacts = data.contacts || []

            return data
        }

        const setErrors = (fieldName, message, errorDict) => {
            if (fieldName === "non_field_errors" && message === "The fields service_company, name must make a unique set.") {
                errorDict["name"] = "A vendor with this name already exists."
            }
            else if (fieldName === "contacts") {
                errorDict["contacts"] = "Please correct the contact errors below:"

                // Apply the nested errors
                this.setState((state, props) => {
                    let updatedState = state
                    message.map((contactError, index) => updatedState[FORM_DATA_NAMES_BY_MODE[state.mode]].contacts[index].errors = contactError)
                    return updatedState
                })
            }
            else if (fieldName === "labels" && Array.isArray(message)) {
                errorDict["labels"] = "Labels must be fewer than 100 characters."
            }
            else {
                errorDict[fieldName] = message
            }
        }

        await sendDataToServer(this, endpoint, endpointMethod, dataName, submittingName, errorDictName, onSuccess, onError, dataManipulator, setErrors)
    }

    // Handle Actions

    handleActionRequest = (action) => {
        switch (action) {
            case "VENDOR_CREATE":
                this.createVendor()
                break
            case "VENDOR_UPDATE":
                this.updateVendor()
                break
            case "VENDOR_CANCEL_CREATE":
                location.assign(DjangoUrls["pricebook:vendor-list"](window.MARKETPLACE_ENTITY_SLUG))
                break
            case "VENDOR_CANCEL_EDITS":
                location.assign(DjangoUrls["pricebook:vendor-detail"](window.MARKETPLACE_ENTITY_SLUG, this.state.vendorData.id))
                break
            default:
                console.error(`No action handler exists for action "${action}".`)
        }
    }

    // Render

    render() {
        if (this.state.vendorData === null) {
            return <Spinner centered={true} />
        }
        else {
            return <VendorForm
                mode={this.state.mode}
                submitting={this.state.submittingVendor}
                vendor={this.state.vendorData}
                errors={this.state.errors.vendor}
                onFormDataChange={(fieldName, fieldValue) => this.updateFormData("vendorData", fieldName, fieldValue)}
                requestAction={this.handleActionRequest}
                switchToSecondaryForm={undefined}
                returnScroll={0}
            ></VendorForm>
            }
        }
    }


export default VendorCreateContainer;
