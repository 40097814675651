import { useQuery } from "@tanstack/react-query"

import replaceSlugs from "@utils/replaceSlugs"

import { REPORTS_ENDPOINTS } from "@endpoints/reports"

import { ReportsAuth } from "./Reports.types"

const useReportsTokenQuery = (user: User | null) => {
    const serviceCompanySlug = user?.service_company?.slug ?? "undefined"
    const tokenAuthEndpoint = replaceSlugs(REPORTS_ENDPOINTS.TOKEN_AUTH, {
        service_company_slug: serviceCompanySlug,
    })
    const reportsTokenQueryKey = ["retrieve-reports-token", tokenAuthEndpoint]

    return useQuery({
        queryKey: reportsTokenQueryKey,
        queryFn: async (): Promise<ReportsAuth> => {
            const response = await fetch(tokenAuthEndpoint)
            return (await response.json()) as ReportsAuth
        },
        enabled: !!user,
        refetchInterval: 1000 * 60 * 55, // JWT token expires in 1 hour. Refresh just before it expires.
        refetchOnWindowFocus: false,
    })
}

export { useReportsTokenQuery }
