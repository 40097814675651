import TableViewTab from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab"
import TableViewTabs from "@organisms/Table/TableViewControls/TableViewTabs/TableViewTabs"

import { JOB_SERIES_ROUTES } from "@routes/jobs-series"

export const JobSeriesTableViewTabs = () => (
    <TableViewTabs>
        <TableViewTab
            id="all_open"
            phase="open"
            title="All Open"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["ordering", "recurrence_start,created_at"],
                ["is_closed", "false"],
            ]}
            listEndpoint={JOB_SERIES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={JOB_SERIES_ROUTES.COUNT}
        />
        <TableViewTab
            id="all_closed"
            phase="closed"
            title="All Closed"
            isDefaultActiveTabForPhase={true}
            endpointKwargs={[
                ["ordering", "-recurrence_start,-created_at"],
                ["is_closed", "true"],
            ]}
            listEndpoint={JOB_SERIES_ROUTES.LIGHTWEIGHT_LIST}
            countEndpoint={JOB_SERIES_ROUTES.COUNT}
        />
    </TableViewTabs>
)
