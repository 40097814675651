import { createContext, useContext } from "react"

import { ViewTypesProviderContextValues } from "./ViewTypesProvider.types"

export const ViewTypesProviderContext = createContext<ViewTypesProviderContextValues | undefined>(undefined)
ViewTypesProviderContext.displayName = "ViewTypesProvider"

export default function useViewTypesProvider(): ViewTypesProviderContextValues {
    const context = useContext(ViewTypesProviderContext)
    if (!context) {
        throw new Error("useViewTypesProvider must be used within a ViewTypesProvider component")
    }

    return context
}
