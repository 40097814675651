import Spinner from "@legacy/core/components/Spinner"
import { useEffect, useMemo, useState } from "react"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import SearchCreateSelectField from "@legacy/core/fields/SearchCreateSelectField"
import extractURLSearchParam from "@utils/extractURLSearchParam"
import CardFilterInput from "../core/components/CardFilterInput"
import EquipmentDetailsAccordion from "../equipment/components/EquipmentDetailsAccordion"
import { filterEquipment } from "../equipment/utils/filters"
import ServiceLocationSearchOrCreateSelect, { serviceLocationToServiceLocationOption } from "./inputs/ServiceLocationSearchOrCreateSelect"
import { renderServiceLocationString } from "./utils/utils"

export default function ClientEquipmentListContainer(props) {
    const { addToastToQueue } = props

    const serviceLocationIDOverride = useMemo(() => extractURLSearchParam("service_location_id"), [])

    const [groupedEquipment, setGroupedEquipment] = useState(undefined)
    const [filteredGroupedEquipment, setFilteredGroupedEquipment] = useState(undefined)
    const [selectedServiceLocation, setSelectedServiceLocation] = useState(null)

    useEffect(() => {
        console.log("got id", serviceLocationIDOverride)
        const fetchServiceLocationData = async () => {
            if (serviceLocationIDOverride) {
                const response = await fetch(
                    DjangoUrls["clients:api-clients-service-locations-detail"](
                        window.MARKETPLACE_ENTITY_SLUG,
                        window.CLIENT_ID,
                        serviceLocationIDOverride,
                    )
                )
                const json = await response.json()
                setSelectedServiceLocation(json)
            }
        }

        fetchServiceLocationData()
    }, [serviceLocationIDOverride])

    useEffect(() => {
        const fetchEquipmentData = async () => {
            console.log("fetching equipment data", selectedServiceLocation?.id)
            let endpoint
            if (selectedServiceLocation?.id) {
                endpoint = DjangoUrls["equipment:api-service-location-equipment-list-by-location"](
                    window.MARKETPLACE_ENTITY_SLUG,
                    selectedServiceLocation.id,
                )
            } else {
                endpoint = DjangoUrls["equipment:api-client-equipment-list-by-location"](
                    window.MARKETPLACE_ENTITY_SLUG,
                    window.CLIENT_ID,
                )
            }

            const response = await fetch(endpoint)
            const json = await response.json()
            setGroupedEquipment(json)
            setFilteredGroupedEquipment(json)
        }
        fetchEquipmentData()
    }, [selectedServiceLocation?.id])

    const filterGroupedEquipment = (filterKwargs) => {
        let filteredGrouped = {}

        for (let [location, equipmentList] of Object.entries(groupedEquipment)) {
            filteredGrouped[location] = equipmentList

            if (filterKwargs.searchQuery !== "") {
                for (let queryChunk of filterKwargs.searchQuery.split(" ")) {
                    queryChunk = queryChunk.toLowerCase()
                    filteredGrouped[location] = filteredGrouped[location].filter((equipment) =>
                        filterEquipment(equipment, queryChunk),
                    )
                }
            }
        }

        setFilteredGroupedEquipment(filteredGrouped)
    }

    const renderLocationGroups = () => {
        let locationGroups = []

        for (let [location, equipmentList] of Object.entries(filteredGroupedEquipment)) {
            if (equipmentList.length !== 0) {
                const equipmentCards = equipmentList.map((equipment) => (
                    <EquipmentDetailsAccordion
                        key={`equipment_${equipment.id}`}
                        clientID={window.CLIENT_ID}
                        data={equipment}
                        editable={window.CURRENT_USER?.permissions.equipment_edit_permission >= PERMISSION_LEVEL.FULL}
                        startInactive={true}
                        showHelpText={false}
                        showJobHistory={true}
                        addToastToQueue={addToastToQueue}
                        openable={window.CURRENT_USER?.permissions.equipment_view_permission >= PERMISSION_LEVEL.FULL}
                    />
                ))

                locationGroups.push(
                    <div className="equipment-location-group" key={location}>
                        <div className="equipment-location-group__location">{location}</div>
                        <div className="equipment-location-group__equipment">{equipmentCards}</div>
                    </div>
                )
            }
        }

        if (locationGroups.length === 0) {
            locationGroups.push(
                <div className="equipment-location-group" key="empty">
                    <span className="equipment-emptytext">No equipment found.</span>
                </div>
            )
        }

        return locationGroups
    }

    console.log("rendering default", selectedServiceLocation !== null ? serviceLocationToServiceLocationOption(selectedServiceLocation) : null)

    return (
        <div id="equipment_list_container">
            <div className="full-width-bar">
                <div className="card-filter">
                    <SearchCreateSelectField
                        fieldName="service_location"
                        fieldLabel="Service Location"
                        fieldValue={selectedServiceLocation !== null ? renderServiceLocationString(selectedServiceLocation) : null}
                        inputComponent={
                            <ServiceLocationSearchOrCreateSelect
                                client={{ id: window.CLIENT_ID }}
                                onSelectionChange={selectedOption => setSelectedServiceLocation(selectedOption != null ? selectedOption.object : null)}
                                defaultSelected={selectedServiceLocation !== null ? serviceLocationToServiceLocationOption(selectedServiceLocation) : null}
                                showCreateButton={false}
                                extraKey={selectedServiceLocation?.id}
                            />
                        }
                        showButton={false}
                        errors={{client: {}}}
                    />
                </div>
            </div>
            <div className="full-width-bar">
                <CardFilterInput onKwargsChange={filterGroupedEquipment} showSearch={true} showDropdown={false} label="Keyword Search" />
            </div>
            <div className="equipment-list" aria-label="Equipment">
                {groupedEquipment === undefined ? (
                    <Spinner centered={true} />
                ) : Object.entries(groupedEquipment).length === 0 && groupedEquipment.constructor === Object ? (
                    <span className="equipment-emptytext">Looks like you don't have any equipment logged for this client (yet).</span>
                ) : (
                    renderLocationGroups()
                )}
            </div>
        </div>
    )
}
