import { useVirtualizer } from "@tanstack/react-virtual"
import { useEffect } from "react"

import useHasAccess from "@hooks/useHasAccess"
import useIsScrolling from "@hooks/useIsScrolling"
import useUser from "@hooks/useUser"

import getObjectDetailsRoute from "@utils/getObjectDetailsRoute"

import CardListCard from "@organisms/CardList/CardListCard/CardListCard"
import CardListEmptyState from "@organisms/CardList/CardListEdgeStates/CardListEmptyState/CardListEmptyState"
import CardListErrorState from "@organisms/CardList/CardListEdgeStates/CardListErrorState/CardListErrorState"
import CardListLoadingState from "@organisms/CardList/CardListEdgeStates/CardListLoadingState/CardListLoadingState"
import CardListNoResultsState from "@organisms/CardList/CardListEdgeStates/CardListNoResultsState/CardListNoResultsState"
import CardListLoaderCard from "@organisms/CardList/CardListLoaderCard/CardListLoaderCard"
import { ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import useTable from "@organisms/Table/Table.context"
import { GetObjectDetailsRouteFunction } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import withAlternativeStates from "@HOCs/withAlternativeStates"

import styles from "./CardList.module.scss"
import { CardListProps } from "./CardList.types"

const CARD_HEIGHT = 157.5

function CardList(props: CardListProps) {
    const { CardComponent } = props

    const { data, hasNextPage, fetchNextPage, isFetchingNextPage, objectIndexName } = useTable<ObjectsData>()

    const { user } = useUser()
    const { hasPermission } = useHasAccess()

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? data.length + 1 : data.length,
        getScrollElement: () => scrollingElement.current,
        estimateSize: () => CARD_HEIGHT,
        overscan: 5,
    })

    const virtualRows = rowVirtualizer.getVirtualItems()

    // Fetch more rows when we hit the bottom
    useEffect(() => {
        const [lastItem] = [...virtualRows].reverse()

        if (!lastItem) {
            return
        }

        if (lastItem.index === data?.length && hasNextPage && !isFetchingNextPage) {
            void fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, data?.length, virtualRows])

    const objectsDetailGetter = getObjectDetailsRoute({
        objectName: objectIndexName,
        hasDraftEstimateEditPermission: hasPermission("estimates_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        hasDraftJobEditPermission: hasPermission("jobs_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        hasDraftInvoiceEditPermission: hasPermission("invoices_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        hasDraftPurchaseOrderEditPermission: hasPermission(
            "purchase_orders_edit_permission",
            PERMISSION_LEVEL.RESTRICTED,
        ),
        hasDraftBillEditPermission: hasPermission("bills_edit_permission", PERMISSION_LEVEL.RESTRICTED),
        serviceCompanySlug: user?.service_company?.slug || "",
    }) as GetObjectDetailsRouteFunction

    const { ref: scrollingElement } = useIsScrolling<HTMLDivElement>()

    // Fetch more rows when we hit the bottom
    useEffect(() => {
        const [lastItem] = [...virtualRows].reverse()

        if (!lastItem) {
            return
        }

        if (lastItem.index === data?.length && hasNextPage && !isFetchingNextPage) {
            void fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, data?.length, virtualRows])

    return (
        <div ref={scrollingElement} className={styles.scrollContainer}>
            <div className={styles.virtualizer} style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {virtualRows?.map((virtualRow) => {
                    const rowData = data[virtualRow.index]
                    if (!rowData) {
                        return null
                    }
                    const detailsURL = objectsDetailGetter(rowData)

                    const isLoaderRow = virtualRow.index > data?.length - 1

                    if (isLoaderRow && hasNextPage) {
                        return (
                            <CardListLoaderCard
                                key={virtualRow.index}
                                height={virtualRow.size}
                                transform={virtualRow.start}
                            />
                        )
                    } else {
                        return (
                            <CardListCard
                                key={virtualRow.index}
                                height={virtualRow.size}
                                transform={virtualRow.start}
                                index={virtualRow.index}
                                measureElement={rowVirtualizer.measureElement}
                                currentData={data}
                                detailsURL={detailsURL}
                                CardComponent={CardComponent}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}

export default withAlternativeStates({
    LoadingState: CardListLoadingState,
    ErrorState: CardListErrorState,
    EmptyState: CardListEmptyState,
    NoResultsState: CardListNoResultsState,
    SuccessState: CardList,
})
