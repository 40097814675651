import { InventoryLevels } from "@constants/choices"
import { valueIsDefined } from "../../core/utils/utils"

function validateInventoryLevel(inventoryLevel) {
    let isValid = true
    let errors = {}

    if (!valueIsDefined(inventoryLevel.quantity_on_hand)) {
        isValid = false
        errors.quantity_on_hand = "Quantity on Hand is required."
    }

    if (!valueIsDefined(inventoryLevel.quantity_on_order)) {
        isValid = false
        errors.quantity_on_order = "Quantity on Order is required."
    }

    if (!valueIsDefined(inventoryLevel.quantity_desired)) {
        isValid = false
        errors.quantity_desired = "Quantity Desired is required."
    }

    if (!valueIsDefined(inventoryLevel.quantity_low_alert_threshold)) {
        isValid = false
        errors.quantity_low_alert_threshold = "Quantity Low Alert Threshold is required."
    }

    if (inventoryLevel.quantity_desired && parseFloat(inventoryLevel.quantity_desired) !== 0 && (parseFloat(inventoryLevel.quantity_low_alert_threshold) >= parseFloat(inventoryLevel.quantity_desired))) {
        isValid = false
        errors.quantity_low_alert_threshold = "The alert threshold must be lower than the desired quantity."
    }

    return { isValid, errors }
}

function getInventoryStatus(quantityOnHand, quantityOnOrder, quantityDesired, quantityLowAlertThreshold) {
    let quantity = parseFloat(quantityOnHand) + parseFloat(quantityOnOrder)

    if (parseFloat(quantityDesired) === 0) {
        return InventoryLevels.full
    }
    else if (quantity <= 0) {
        return InventoryLevels.depleted
    }
    else if (quantity <= parseFloat(quantityLowAlertThreshold)) {
        return InventoryLevels.low
    }
    else if (quantity < parseFloat(quantityDesired)) {
        return InventoryLevels.limited
    }
    else {
        return InventoryLevels.full
    }
}

export { getInventoryStatus, validateInventoryLevel }
