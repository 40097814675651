import { useInfiniteQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import fetchObjectListData from "@utils/fetchObjectListData"

import { ObjectListData, ObjectsData } from "@organisms/ObjectsView/ObjectsView.types"
import { UseTableDataProps } from "@organisms/Table/Table.types"

export default function useTableData<T = ObjectsData>(props: UseTableDataProps) {
    const { tabContentName, activeTab, searchKeywords, filters, sorting } = props

    const { data, error, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery<ObjectListData<T>>({
            queryKey: [
                "tableData",
                tabContentName,
                activeTab?.listEndpoint,
                activeTab?.endpointKwargs,
                searchKeywords,
                filters,
                sorting,
            ],
            queryFn: ({ pageParam }) =>
                fetchObjectListData<T>({
                    objectName: tabContentName,
                    endpoint: activeTab?.listEndpoint ?? null,
                    endpointKwargs: activeTab?.endpointKwargs || [],
                    searchKeywords,
                    filters,
                    sorting,
                    nextPage: pageParam as string,
                }),
            initialPageParam: "",
            staleTime: 60000,
            getNextPageParam: (lastPage) => lastPage.next,
            enabled: !!activeTab,
        })

    const currentData = useMemo(() => data?.pages.map((page) => page.results).flat(1) || [], [data])

    return {
        currentData,
        error,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        refetch,
        isEmpty: currentData?.length === 0 && !isLoading,
    }
}
