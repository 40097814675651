import HorizontalLayout from "@legacy/core/components/HorizontalLayout"
import PureComponentWrapper from "@legacy/core/components/PureComponentWrapper"
import BasicDisplayField from "@legacy/core/fields/BasicDisplayField"
import PriceField from "@legacy/core/fields/PriceField"
import SearchCreateSelectField from "@legacy/core/fields/SearchCreateSelectField"
import { valueIsDefined } from "@legacy/core/utils/utils"
import PriceBookItemSearchOrCreateSelect, {
    priceBookItemToPriceBookItemOption,
} from "@legacy/pricebook/inputs/PriceBookItemSearchOrCreateSelect"
import { Component, Fragment } from "react"

import { PriceBookItemTypes } from "@constants/choices"

import { PRICEBOOK_ITEM_TYPE_MAP } from "@constants/mappings"
import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import { AccessCheck } from "@particles/index"
import ButtonGroup from "../../core/buttons/ButtonGroup"
import ButtonGroupRow from "../../core/buttons/ButtonGroupRow"
import UniversalButton from "../../core/buttons/UniversalButton"

class InlinePriceOverrideForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedPriceBookItem: null,
            currencyCode: window.CURRENCY_CODE,
            languageCode: window.LANGUAGE_CODE,
        }

        window.onpopstate = (event) => {
            if (event.state !== null && Object.keys(event.state).length) {
                this.setState(event.state)
            }
        }
    }

    renderButtons = () => {
        const { item, isNewAddition, onFormDataChange, requestAction } = this.props

        return (
            <ButtonGroup>
                {isNewAddition ? (
                    <AccessCheck permissions={{
                        pricebook_edit_permission: PERMISSION_LEVEL.FULL
                    }}>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={(event) => requestAction("ITEM_ADD")} />
                        </ButtonGroupRow>
                    </AccessCheck>
                ) : (
                    <AccessCheck permissions={{
                        pricebook_edit_permission: PERMISSION_LEVEL.FULL
                    }}>
                        <ButtonGroupRow>
                            <UniversalButton type="primary" text="Save" handler={(event) => requestAction("ITEM_SAVE")} />
                        </ButtonGroupRow>
                    </AccessCheck>
                )}
                {isNewAddition ? (
                    <ButtonGroupRow>
                        <UniversalButton
                            type="secondary"
                            text="Cancel"
                            handler={(event) => requestAction("ITEM_CANCEL_ADD")}
                        />
                    </ButtonGroupRow>
                ) : (
                    <Fragment>
                        <ButtonGroupRow>
                            <UniversalButton
                                type="secondary"
                                text="Cancel Edits"
                                handler={(event) => requestAction("ITEM_CANCEL_EDITS")}
                            />
                        </ButtonGroupRow>
                        <AccessCheck permissions={{
                            pricebook_delete_permission: PERMISSION_LEVEL.FULL
                        }}>
                            <ButtonGroupRow>
                                <UniversalButton
                                    type="danger"
                                    text="Delete Override"
                                    handler={(event) => requestAction("ITEM_DELETE")}
                                />
                            </ButtonGroupRow>
                        </AccessCheck>
                    </Fragment>
                )}
            </ButtonGroup>
        )
    }

    handleSelectionChange = (selectedItem) => {
        const { onFormDataChange } = this.props

        onFormDataChange("pricebook_item", selectedItem?.object)
    }

    render() {
        const {
            item,
            isNewAddition,
            onFormDataChange,
            requestAction,
            formatCurrencyValue,
            currencySymbol,
            pricebookItemType,
            errors,
        } = this.props

        const itemErrors = item.errors || {}
        const inputLabel = PRICEBOOK_ITEM_TYPE_MAP[item.pricebook_item?.pricebook_item_type] || '';

        return (
            <div className="data-panel__list-select__form">
                <div className="data-panel__list-select" aria-label="Item Edit Form">
                    {itemErrors.non_field_error && (
                        <div className="data-panel__form__field__errors" aria-label="Non Field Error">
                            {itemErrors.non_field_error}
                        </div>
                    )}
                    {
                        isNewAddition ? (
                            <SearchCreateSelectField
                                fieldName="pricebook_item"
                                fieldLabel={inputLabel}
                                fieldValue={item.pricebook_item?.description || null}
                                inputComponent={
                                    <PureComponentWrapper
                                        wrappedComponent={PriceBookItemSearchOrCreateSelect}
                                        updateTrigger={(currentProps, nextProps) =>
                                            nextProps.defaultSelected?.value !== currentProps.defaultSelected?.value
                                        }
                                        onSelectionChange={(selectedOption) => this.handleSelectionChange(selectedOption)}
                                        defaultSelected={
                                            this.state.selectedPriceBookItem
                                                ? priceBookItemToPriceBookItemOption(this.state.selectedPriceBookItem)
                                                : item.pricebook_item?.description
                                                  ? { value: null, label: item.pricebook_item?.description }
                                                  : null
                                        }
                                        pricebookItemType={pricebookItemType}
                                        showCreateButton={false}
                                        creatable={false}
                                        disabled={
                                            window.CURRENT_USER?.permissions.pricebook_list_permission < PERMISSION_LEVEL.RESTRICTED ||
                                            window.CURRENT_USER?.permissions.pricebook_view_permission < PERMISSION_LEVEL.FULL
                                        }
                                    ></PureComponentWrapper>
                                }
                                showButton={false}
                                errors={{...errors, ...itemErrors}}
                            ></SearchCreateSelectField>
                            ) : (
                            <BasicDisplayField
                                fieldName="pricebook_item"
                                fieldLabel={inputLabel}
                                fieldValue={item.pricebook_item?.description || "\u2014"}
                            ></BasicDisplayField>
                        )
                    }

                    <HorizontalLayout>
                        <BasicDisplayField
                            fieldName="default_price"
                            fieldLabel="Default Price"
                            fieldValue={
                                valueIsDefined(item.pricebook_item?.default_price)
                                    ? item.pricebook_item?.pricebook_item_type === PriceBookItemTypes.discount
                                        ? formatCurrencyValue(item.pricebook_item?.default_price * -1)
                                        : formatCurrencyValue(item.pricebook_item?.default_price)
                                    : "\u2014"
                            }
                        ></BasicDisplayField>
                        <PriceField
                            fieldName="price"
                            fieldLabel="Override Price"
                            fieldValue={item.price}
                            fieldOnChange={(price) => onFormDataChange("price", price)}
                            currencySymbol={currencySymbol}
                            credit={pricebookItemType === PriceBookItemTypes.discount}
                            errors={{...errors, ...itemErrors}}
                            optional={false}
                            disabled={window.CURRENT_USER?.permissions.pricebook_edit_permission < PERMISSION_LEVEL.FULL}
                        ></PriceField>
                    </HorizontalLayout>
                    {this.renderButtons()}
                </div>
            </div>
        )
    }
}

export default InlinePriceOverrideForm
