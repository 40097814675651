import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"

import { AccessCheck } from "@particles/index"

import { NotificationTopicCard } from "@molecules/index"

import { useNotificationPreferencesQuery } from "@pages/Settings/SettingsNotifications/SettingsNotifications.store"

import { SETTINGS_ROUTES } from "@routes/settings"

import styles from "./NotificationTopicsCards.module.scss"

function calculateTopicStatuses(topics: object) {
    const uniqueTopics = new Set<string>()
    const uniqueEnabledTopics = new Set<string>()

    for (const key in topics) {
        const baseKey = key.replace(/_(email|sms)$/, "")
        uniqueTopics.add(baseKey)

        // @ts-expect-error we know that the key exists
        if (topics[`${baseKey}_email`] || topics[`${baseKey}_sms`]) {
            uniqueEnabledTopics.add(baseKey)
        }
    }

    return { enabled: uniqueEnabledTopics.size, total: uniqueTopics.size }
}

export default function NotificationTopicsCards() {
    const { allTopics } = useNotificationPreferencesQuery()

    const { enabled: enabledJobsAssignmentTopics, total: totalJobAssignmentTopics } = calculateTopicStatuses(
        allTopics.jobs.assignment,
    )
    const { enabled: enabledJobsManagerTopics, total: totalJobManagerTopics } = calculateTopicStatuses(
        allTopics.jobs.manager,
    )
    const { enabled: enabledEstimatesTopics, total: totalEstimatesTopics } = calculateTopicStatuses(
        allTopics.estimates,
    )
    const { enabled: enabledInventoryTopics, total: totalInventoryTopics } = calculateTopicStatuses(
        allTopics.inventory,
    )

    return (
        <div className={styles.base}>
            <AccessCheck entitlements={["entitlement_jobs_enabled"]}>
                <NotificationTopicCard
                    icon={icon({ name: "wrench-simple", style: "solid", family: "sharp" })}
                    title="Jobs"
                    notificationsInUse={enabledJobsAssignmentTopics + enabledJobsManagerTopics}
                    totalOfNotifications={totalJobAssignmentTopics + totalJobManagerTopics}
                    path={SETTINGS_ROUTES.notificationsJobs}
                />
            </AccessCheck>
            <AccessCheck entitlements={["entitlement_estimates_enabled"]}>
                <NotificationTopicCard
                    icon={icon({ name: "file-lines", style: "solid", family: "sharp" })}
                    title="Estimates"
                    notificationsInUse={enabledEstimatesTopics}
                    totalOfNotifications={totalEstimatesTopics}
                    path={SETTINGS_ROUTES.notificationsEstimates}
                />
            </AccessCheck>
            <NotificationTopicCard
                icon={icon({ name: "tag", style: "solid", family: "sharp" })}
                title="Inventory"
                notificationsInUse={enabledInventoryTopics}
                totalOfNotifications={totalInventoryTopics}
                path={SETTINGS_ROUTES.notificationsInventory}
            />
        </div>
    )
}
