import clsx from "clsx"
import { memo, useMemo } from "react"

import useHasAccess from "@hooks/useHasAccess"
import useNavigationLinks, { NavigationLink } from "@hooks/useNavigationLinks"
import useUser from "@hooks/useUser"

import filterUserLinks from "@utils/filterUserLinks"
import generateRandomKey from "@utils/generateRandomKey"

import AccessCheck from "@particles/AccessCheck/AccessCheck"

import { ScrollableContainer } from "@atoms"

import { NavigationItem, WorkspaceSelector } from "@molecules"

import { StackedNavigationItems, UserProfileButton } from "@organisms"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import HelpCenter from "./HelpCenter/HelpCenter"
import styles from "./MainSideBar.module.scss"
import SideBarCreateButton from "./SideBarCreateButton/SideBarCreateButton"
import SideBarSearchButton from "./SideBarSearchButton/SideBarSearchButton"
import WhatsNew from "./WhatsNew/WhatsNew"

function MainSideBar() {
    const { user } = useUser()

    const { home, calendar, estimates, jobs, invoices, purchase_orders, bills, vendors, clients, pricebook, reports } =
        useNavigationLinks()

    const links: NavigationLink[] = [
        home,
        calendar,
        estimates,
        jobs,
        invoices,
        purchase_orders,
        bills,
        vendors,
        clients,
        pricebook,
        reports,
    ]

    const filteredLinks = useMemo(() => filterUserLinks(links, user), [user])

    const { hasPermission } = useHasAccess()

    const showCreateButton = useMemo(() => {
        return (
            hasPermission("estimates_create_permission", PERMISSION_LEVEL.RESTRICTED) ||
            hasPermission("jobs_create_permission", PERMISSION_LEVEL.RESTRICTED) ||
            hasPermission("invoices_create_permission", PERMISSION_LEVEL.RESTRICTED) ||
            hasPermission("purchase_orders_create_permission", PERMISSION_LEVEL.RESTRICTED) ||
            hasPermission("bills_create_permission", PERMISSION_LEVEL.RESTRICTED) ||
            hasPermission("vendors_create_permission", PERMISSION_LEVEL.FULL) ||
            hasPermission("clients_create_permission", PERMISSION_LEVEL.FULL) ||
            hasPermission("pricebook_create_permission", PERMISSION_LEVEL.FULL)
        )
    }, [hasPermission])

    return (
        <nav className={clsx(styles.base, "jsMainSideBar")}>
            <div className={styles.header}>
                <WorkspaceSelector />

                <UserProfileButton />
            </div>
            <div className={styles.searchInput}>
                <AccessCheck
                    permissions={{
                        estimates_list_permission: PERMISSION_LEVEL.FULL,
                        jobs_list_permission: PERMISSION_LEVEL.FULL,
                        invoices_list_permission: PERMISSION_LEVEL.FULL,
                        purchase_orders_list_permission: PERMISSION_LEVEL.FULL,
                        bills_list_permission: PERMISSION_LEVEL.FULL,
                        vendors_list_permission: PERMISSION_LEVEL.FULL,
                        clients_list_permission: PERMISSION_LEVEL.FULL,
                        pricebook_list_permission: PERMISSION_LEVEL.FULL,
                    }}
                >
                    <SideBarSearchButton />
                </AccessCheck>
            </div>

            <ScrollableContainer
                className={clsx(styles.navigation, { [styles.withCreateButton]: showCreateButton })}
                scrollClassName={styles.scrolling}
            >
                <div className={styles.primaryNavigation}>
                    {filteredLinks.map((link) => {
                        const sublinkCount = filterUserLinks(link.subLinks, user).length
                        if (sublinkCount > 1) {
                            return (
                                <StackedNavigationItems
                                    key={generateRandomKey()}
                                    header={link}
                                    items={filterUserLinks(link.subLinks, user)}
                                />
                            )
                        } else if (sublinkCount === 1) {
                            const sublink = filterUserLinks(link.subLinks, user)[0]
                            return (
                                <NavigationItem
                                    key={generateRandomKey()}
                                    label={sublink.label}
                                    path={sublink.path}
                                    variant="primary"
                                    icon={link.icon}
                                    iconActive={link.iconActive}
                                />
                            )
                        } else {
                            return (
                                <NavigationItem
                                    key={generateRandomKey()}
                                    label={link.label}
                                    path={link.path}
                                    variant="primary"
                                    icon={link.icon}
                                    iconActive={link.iconActive}
                                />
                            )
                        }
                    })}
                </div>
                <div className={styles.secondaryNavigation}>
                    <HelpCenter />
                    <WhatsNew />
                </div>
            </ScrollableContainer>
            <AccessCheck
                permissions={{
                    estimates_create_permission: PERMISSION_LEVEL.RESTRICTED,
                    jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
                    invoices_create_permission: PERMISSION_LEVEL.RESTRICTED,
                    purchase_orders_create_permission: PERMISSION_LEVEL.RESTRICTED,
                    bills_create_permission: PERMISSION_LEVEL.RESTRICTED,
                    vendors_create_permission: PERMISSION_LEVEL.FULL,
                    clients_create_permission: PERMISSION_LEVEL.FULL,
                    pricebook_create_permission: PERMISSION_LEVEL.FULL,
                }}
            >
                <SideBarCreateButton />
            </AccessCheck>
        </nav>
    )
}

export default memo(MainSideBar)
