import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { useState } from "react"

import useSlugExtractor from "@hooks/useSlugExtractor"
import useUser from "@hooks/useUser"

import AccessCheck from "@particles/AccessCheck/AccessCheck"

import { Icon } from "@atoms"

import { BottomSheet } from "@organisms"

import { CLIENTS_ROUTES } from "@routes/clients"
import { ESTIMATES_ROUTES } from "@routes/estimates"
import { INVOICES_ROUTES } from "@routes/invoices"
import { JOBS_ROUTES } from "@routes/jobs"
import { PRICEBOOK_ITEMS_ROUTES } from "@routes/pricebook-items"
import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"
import { VENDOR_ROUTES } from "@routes/vendor"

import { APIDeckTaxStrategies, AccountingIntegrations } from "@constants/choices"
import { PERMISSION_LEVEL } from "@constants/permissionLevel"

import styles from "./BottomBarCreateButton.module.scss"
import { BottomBarCreateButtonProps } from "./BottomBarCreateButton.types"

export default function BottomBarCreateButton(props: BottomBarCreateButtonProps) {
    const { isDisabled } = props

    const { user } = useUser()

    const isUsingTaxRates =
        user?.service_company?.use_taxes &&
        !(
            user?.service_company?.accounting_integration === AccountingIntegrations.apideck &&
            user?.service_company?.apideck_tax_strategy !== APIDeckTaxStrategies.normal
        )

    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false)
    const [isSubItemsOpen, setIsSubItemsOpen] = useState<boolean>(false)

    const onCloseBottomSheet = () => {
        setIsBottomSheetOpen(false)
        setTimeout(() => {
            closeSubItems()
        }, 300)
    }

    const openBottomSheet = () => {
        setIsBottomSheetOpen(true)
    }

    const openSubItems = () => {
        setIsSubItemsOpen(true)
    }

    const closeSubItems = () => {
        setIsSubItemsOpen(false)
    }

    const [
        estimateCreateRoute,
        jobsCreateRoute,
        invoicesCreateRoute,
        purchaseOrdersCreateRoute,
        vendorsCreateRoute,
        clientsCreateRoute,
        pricebookServiceCreateRoute,
        pricebookPartOrMaterialCreateRoute,
        pricebookMiscellaneousCreateRoute,
        pricebookDiscountCreateRoute,
        pricebookTaxRatesCreateRoute,
    ] = useSlugExtractor([
        ESTIMATES_ROUTES.CREATE,
        JOBS_ROUTES.CREATE,
        INVOICES_ROUTES.CREATE,
        PURCHASE_ORDER_ROUTES.CREATE,
        VENDOR_ROUTES.CREATE,
        CLIENTS_ROUTES.CREATE,
        PRICEBOOK_ITEMS_ROUTES.SERVICE.CREATE,
        PRICEBOOK_ITEMS_ROUTES.PART_OR_MATERIAL.CREATE,
        PRICEBOOK_ITEMS_ROUTES.MISCELLANEOUS.CREATE,
        PRICEBOOK_ITEMS_ROUTES.DISCOUNT.CREATE,
        PRICEBOOK_ITEMS_ROUTES.TAX_RATES.CREATE,
    ])

    return (
        <>
            <div className={styles.base} onClickCapture={openBottomSheet}>
                <button
                    className={clsx(styles.trigger, {
                        [styles.isDisabled]: isDisabled,
                    })}
                    aria-disabled={isDisabled}
                    aria-label="Create Object"
                >
                    <Icon
                        icon={icon({
                            name: "plus",
                            style: "solid",
                            family: "sharp",
                        })}
                        size={16}
                    />
                </button>
            </div>
            <BottomSheet.Root isOpen={isBottomSheetOpen} onClose={onCloseBottomSheet}>
                <BottomSheet.Content
                    header={{
                        title: "Create Object",
                    }}
                    priority="primary"
                    isVisible={!isSubItemsOpen}
                    itemsClassName={styles.items}
                >
                    {(isTabbable) => (
                        <>
                            <AccessCheck
                                entitlements={["entitlement_estimates_enabled"]}
                                permissions={{
                                    estimates_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "file-lines", style: "light", family: "sharp" })}
                                    path={estimateCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Estimate"
                                    isTabbable={isTabbable}
                                />
                            </AccessCheck>
                            <AccessCheck
                                entitlements={["entitlement_jobs_enabled"]}
                                permissions={{
                                    jobs_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "wrench-simple", style: "light", family: "sharp" })}
                                    path={jobsCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Job"
                                    isTabbable={isTabbable}
                                />
                            </AccessCheck>
                            <AccessCheck
                                entitlements={["entitlement_invoices_enabled"]}
                                permissions={{
                                    invoices_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "circle-dollar", style: "light", family: "sharp" })}
                                    path={invoicesCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Invoice"
                                    isTabbable={isTabbable}
                                />
                            </AccessCheck>
                            <AccessCheck
                                entitlements={["entitlement_purchase_orders_enabled"]}
                                permissions={{
                                    purchase_orders_create_permission: PERMISSION_LEVEL.RESTRICTED,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "basket-shopping", style: "light", family: "sharp" })}
                                    path={purchaseOrdersCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Purchase Order"
                                    isTabbable={isTabbable}
                                />
                            </AccessCheck>
                            <AccessCheck
                                permissions={{
                                    vendors_create_permission: PERMISSION_LEVEL.FULL,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "store", style: "light", family: "sharp" })}
                                    path={vendorsCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Vendor"
                                    isTabbable={isTabbable}
                                />
                            </AccessCheck>
                            <AccessCheck
                                permissions={{
                                    clients_create_permission: PERMISSION_LEVEL.FULL,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "user-circle", style: "light", family: "sharp" })}
                                    path={clientsCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Client"
                                    isTabbable={isTabbable}
                                />
                            </AccessCheck>
                            <AccessCheck
                                permissions={{
                                    pricebook_create_permission: PERMISSION_LEVEL.FULL,
                                }}
                            >
                                <BottomSheet.Item
                                    icon={icon({ name: "tag", style: "light", family: "sharp" })}
                                    className={styles.bottomSheetItem}
                                    label="Pricebook Item"
                                    isTabbable={isTabbable}
                                    hasSubItems={true}
                                    onClick={openSubItems}
                                />
                            </AccessCheck>
                        </>
                    )}
                </BottomSheet.Content>
                <BottomSheet.Content
                    header={{
                        onBackClick: closeSubItems,
                        title: "Create Pricebook Item",
                    }}
                    itemsClassName={styles.items}
                    priority="secondary"
                    isVisible={isSubItemsOpen}
                >
                    {(isTabbable) => (
                        <>
                            <BottomSheet.Item
                                path={pricebookServiceCreateRoute}
                                className={styles.bottomSheetItem}
                                label="Service"
                                isTabbable={isTabbable}
                            />

                            <BottomSheet.Item
                                path={pricebookPartOrMaterialCreateRoute}
                                className={styles.bottomSheetItem}
                                label="Part or Material"
                                isTabbable={isTabbable}
                            />

                            <BottomSheet.Item
                                path={pricebookMiscellaneousCreateRoute}
                                className={styles.bottomSheetItem}
                                label="Miscellaneous"
                                isTabbable={isTabbable}
                            />

                            <BottomSheet.Item
                                path={pricebookDiscountCreateRoute}
                                className={styles.bottomSheetItem}
                                label="Discount"
                                isTabbable={isTabbable}
                            />

                            {isUsingTaxRates && (
                                <BottomSheet.Item
                                    path={pricebookTaxRatesCreateRoute}
                                    className={styles.bottomSheetItem}
                                    label="Tax Rate"
                                    isTabbable={isTabbable}
                                />
                            )}
                        </>
                    )}
                </BottomSheet.Content>
            </BottomSheet.Root>
        </>
    )
}
