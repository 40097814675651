// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimelineViewTimeFrameSelector-selectWidth__eYp5D{width:var(--size-44)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWidth": "TimelineViewTimeFrameSelector-selectWidth__eYp5D"
};
export default ___CSS_LOADER_EXPORT___;
