import { icon as iconMacro } from "@fortawesome/fontawesome-svg-core/import.macro"
import clsx from "clsx"
import { forwardRef, useMemo } from "react"

import debounce from "@utils/debounce"

import { CustomIcon, Icon } from "@atoms"
import buttonStyles from "@atoms/Button/Button.module.scss"
import { ButtonProps } from "@atoms/Button/Button.types"
import { IconSize } from "@atoms/Icon/Icon.types"

import styles from "./IconButton.module.scss"
import { IconButtonProps } from "./IconButton.types"

function IconButton(props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) {
    const {
        colorScheme,
        size,
        variant,
        type = "button",
        className,

        isDisabled = false,
        isLoading = false,
        _isFocused = false,
        _isHovered = false,
        isPressed = false,
        isTabbable = true,

        icon,
        iconOnActive = undefined,
        iconIsActive = false,
        iconClassName,
        iconRotation = undefined,

        useCustomIcon = false,
        customIcon = undefined,

        onClick,
        noDebounce,

        ...rest
    } = props

    const iconSize = useMemo(() => {
        const sizeMap: { [key in ButtonProps["size"]]: IconSize } = {
            xs: 12,
            sm: 14,
            md: 14,
            lg: 16,
            xl: 16,
        }
        return sizeMap[size]
    }, [size])

    return (
        <button
            className={clsx(
                buttonStyles.base,
                className,
                {
                    [buttonStyles.isLoading]: isLoading,
                    [buttonStyles.isFocused]: _isFocused,
                    [buttonStyles.isHovered]: _isHovered,
                    [buttonStyles.isPressed]: isPressed,
                },
                buttonStyles[`${colorScheme}ColorScheme`],
                buttonStyles[`${variant}Variant`],
                styles[`${size}Size`],
            )}
            type={type}
            onClick={
                noDebounce
                    ? onClick
                    : debounce((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick?.(event))
            }
            disabled={isDisabled || isLoading}
            aria-disabled={isDisabled}
            aria-busy={isLoading}
            aria-pressed={isPressed}
            tabIndex={isTabbable && !isDisabled ? 0 : -1}
            ref={ref}
            {...rest}
        >
            {
                <span className={clsx({ [buttonStyles.iconHidden]: isLoading })}>
                    {useCustomIcon ? (
                        customIcon && <CustomIcon icon={customIcon} size={iconSize} className={iconClassName} />
                    ) : (
                        <Icon
                            icon={icon}
                            iconOnActive={iconOnActive}
                            isActive={iconIsActive}
                            size={iconSize}
                            className={iconClassName}
                            rotation={iconRotation}
                        />
                    )}
                </span>
            }
            {isLoading && (
                <span className={buttonStyles.spinnerWrapper}>
                    <Icon icon={iconMacro({ name: "spinner-third", style: "regular" })} spin={true} size={iconSize} />
                </span>
            )}
        </button>
    )
}

export default forwardRef(IconButton)
