import { Breadcrumb } from "@organisms/index"

import { Settings } from "@templates/index"

import InventoryNotificationsForm from "@pages/Settings/SettingsNotifications/components/InventoryNotificationsForm/InventoryNotificationsForm"

import { SETTINGS_ROUTES } from "@routes/settings"

export default function SettingsNotificationsInventory() {
    return (
        <Settings.Wrapper title="Inventory" backDestination={SETTINGS_ROUTES.notifications}>
            <Settings.PageContainer>
                <Settings.Breadcrumbs>
                    <Breadcrumb.Item label="My account" showSlash={true} />
                    <Breadcrumb.Item
                        label="Notifications"
                        showSlash={true}
                        isActive={false}
                        path={SETTINGS_ROUTES.notifications}
                    />
                    <Breadcrumb.Item label="Inventory" path={SETTINGS_ROUTES.notificationsInventory} />
                </Settings.Breadcrumbs>
                <Settings.PageHeader
                    variant="page-heading"
                    title="Inventory"
                    subtitle="Select the topics for which you'd like to receive notifications."
                />

                <Settings.Divider />

                <Settings.Section>
                    <Settings.PageHeader
                        variant="section-heading"
                        title="Digest"
                        subtitle="Periodic digests regarding inventory management."
                    />
                    <InventoryNotificationsForm />
                </Settings.Section>
            </Settings.PageContainer>
        </Settings.Wrapper>
    )
}
