import PurchaseOrderListCard from "@legacy/purchase_orders/components/PurchaseOrderListCard"

import useHasAccess from "@hooks/useHasAccess"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { CardList, Table, TopBar } from "@organisms"
import TableViewPhase from "@organisms/Table/TableViewControls/TableViewPhase/TableViewPhase"
import TableViewPhases from "@organisms/Table/TableViewControls/TableViewPhases/TableViewPhases"

import { PurchaseOrderTableViewTabs } from "@pages/PurchaseOrders/PurchaseOrderList/views/PurchaseOrderTableView/PurchaseOrderTableViewTabs"

import { PURCHASE_ORDER_ROUTES } from "@routes/purchase-orders"

import { PERMISSION_LEVEL } from "@constants/permissionLevel"
import {
    PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB,
    PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH,
    PURCHASE_ORDERS_LIST_CONTROLS_ORDERING,
    PURCHASE_ORDERS_LIST_CONTROLS_PHASE,
} from "@constants/storage"

export default function PurchaseOrderCardView() {
    const [createRoute] = useSlugExtractor([PURCHASE_ORDER_ROUTES.CREATE])
    const { hasPermission } = useHasAccess()

    return (
        <Table.Provider
            activePhaseStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_PHASE}
            activeTabStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_ACTIVE_TAB}
            searchKeywordsStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_KEYWORD_SEARCH}
            orderingStorageKey={PURCHASE_ORDERS_LIST_CONTROLS_ORDERING}
            listTitle="Purchase Orders"
            objectType="purchase_order"
            objectIndexName="PurchaseOrder"
            objectName="Order"
            defaultActivePhase="open"
            objectCreateRoute={createRoute}
            isRowClickable={hasPermission("purchase_orders_view_permission", PERMISSION_LEVEL.FULL)}
            isEmptyStateCreateButtonVisible={hasPermission(
                "purchase_orders_create_permission",
                PERMISSION_LEVEL.RESTRICTED,
            )}
        >
            <TopBar.Portal>
                <TopBar.Body>
                    <TopBar.BodyStart>
                        <TableViewPhases>
                            <TableViewPhase phase="open" title="Open" />
                            <TableViewPhase phase="closed" title="Closed" />
                        </TableViewPhases>
                        <TopBar.VerticalDivider hasSideMargins={true} />
                        <PurchaseOrderTableViewTabs />
                    </TopBar.BodyStart>
                    <TopBar.BodyEnd>
                        <Table.KeywordSearch />
                    </TopBar.BodyEnd>
                </TopBar.Body>
            </TopBar.Portal>
            <CardList CardComponent={PurchaseOrderListCard} />
        </Table.Provider>
    )
}
