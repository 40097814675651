import generateRandomKey from "@utils/generateRandomKey"

import { TabItem } from "@atoms"

import { TabStack } from "@molecules"

import BottomSheet from "@organisms/BottomSheet/BottomSheet"
import useTable from "@organisms/Table/Table.context"

import styles from "./BottomSheetSortingColumns.module.scss"
import { BottomSheetSortingColumnsProps } from "./BottomSheetSortingColumns.types"

export default function BottomSheetSortingColumns(props: BottomSheetSortingColumnsProps) {
    const { isVisible } = props

    const {
        sortingEnabledColumns,
        activeSorting,
        activeSortingIcons,
        activeSortingLabels,
        setCurrentSortToAscending,
        setCurrentSortToDescending,
        onSortingItemSelection,
    } = useTable()

    return (
        <>
            <div className={styles.sortingColumns}>
                {sortingEnabledColumns.map((column) => (
                    <BottomSheet.Item
                        key={generateRandomKey()}
                        label={column.header as string}
                        onClick={() => onSortingItemSelection(column)}
                        isSelected={activeSorting?.id === column.accessorKey}
                        isTabbable={isVisible}
                    />
                ))}
            </div>
            <BottomSheet.Divider />
            <div className={styles.sortingOrderSelector}>
                <TabStack variant="attached" size="md">
                    <TabItem
                        icon={activeSortingIcons?.asc}
                        isPressed={!activeSorting?.desc}
                        isTabbable={isVisible}
                        onClick={setCurrentSortToAscending}
                    >
                        {activeSortingLabels?.asc}
                    </TabItem>
                    <TabItem
                        icon={activeSortingIcons?.desc}
                        isPressed={activeSorting?.desc}
                        isTabbable={isVisible}
                        onClick={setCurrentSortToDescending}
                    >
                        {activeSortingLabels?.desc}
                    </TabItem>
                </TabStack>
            </div>
        </>
    )
}
